import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { capitalize } from '../utils';
import { translate } from '../translate/i18n';
import { Button, Row, Container, DatePicker } from '../components';
import { func, bool } from 'prop-types';
import { updateLease } from './leases.actions';

const LeaseForm = (props) => {
  const { handleSubmit, submitting, submitSucceeded, t, onClose, timezone } = props;

  if (submitSucceeded) {
    onClose();
  }

  return (
    <Container column p>
      <form onSubmit={handleSubmit(updateLease)}>
        <Row>
          <Field
            name="moveoutDate"
            component={DatePicker}
            label={`${capitalize(t('MOVE_OUT_DATE'))}`}
            timezone={timezone}
            time={{ hours: 11 }}
          />
        </Row>
        <Row>
          <Field
            name="preferredMoveoutDate"
            component={DatePicker}
            label={`${capitalize(t('PREFERRED_MOVE_OUT_DATE'))}`}
            timezone={timezone}
            time={{ hours: 11 }}
          />
        </Row>

        <Row mb right>
          <div style={{ textAlign: 'right' }}>
            <Button clear size="md" name="cancel" disabled={submitting} onClick={onClose}>
              {capitalize(t('CANCEL'))}
            </Button>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button bg-primary size="md" name="submit" type="submit" disabled={submitting}>
              {capitalize(t('SAVE'))}
            </Button>
          </div>
        </Row>
      </form>
    </Container>
  );
};

LeaseForm.propTypes = {
  handleSubmit: func,
  submitting: bool,
  submitSucceeded: bool,
  t: func,
  onClose: func
};

export default compose(reduxForm({ form: 'LeaseForm' }), translate('leases'))(LeaseForm);
