import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';
import hash from 'object-hash';
import { compose, values, all, reduce, keys, pathOr } from 'ramda';
import { translate } from '../translate/i18n';
import { getTimeMask } from '../utils';
import { handleFilters } from '../utils';
import { Row, Col, SelectorHub, SelectorCreated, collapsible, Toggle } from '../components';
import { getStatusList } from './applications.utils';
import StatusSelect from './Application.statusSelect';

const ApplicationFilter = ({ t, onFilterSelect, withStatus }) => {
  const [createdMask, setCreatedMask] = useState();
  let [hashCode, setHashCode] = useState('');

  let [filters, setFilters] = useState({
    status: [],
    createdAt: null,
    showLost: false,
    hubId: []
  });

  let [loaded, setLoaded] = useState({
    status: !withStatus,
    createdAt: false,
    showLost: false,
    hubId: false
  });

  const handleChange = (filter) => (value) => {
    filters[filter] = value;

    setFilters({
      ...filters
    });
  };

  const onLoad =
    (filter) =>
    (val = true) => {
      if (loaded[filter] !== val) {
        loaded[filter] = val;
        setLoaded({
          ...loaded
        });
      }
    };

  useEffect(() => {
    const timeMask = getTimeMask(t);
    delete timeMask.ALL;
    setCreatedMask(timeMask);
  }, []);

  useEffect(() => {
    if (all((item) => item)(values(loaded))) {
      const newHashCode = hash(filters);
      if (newHashCode !== hashCode) {
        hashCode = newHashCode;
        setHashCode(newHashCode);
        onFilterSelect(
          reduce(
            (all, filter) => {
              if (filter === 'createdAt') {
                if (filters[filter]) {
                  return {
                    ...all,
                    [createdMask[filters[filter]].filter]: createdMask[filters[filter]].date
                  };
                }

                return all;
              }
              return handleFilters(all, filter, filters[filter]);
            },
            {},
            keys(filters)
          )
        );
      }
    }
  }, [filters, loaded]);

  return (
    <Row middle mb>
      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorCreated
          name="createdAt"
          onChange={handleChange('createdAt')}
          label={t('CREATED_AT')}
          placeholder={t('form.CHOOSE_A_DATE')}
          value={filters.createdAt}
          onLoad={onLoad('createdAt')}
          storeName="Applications"
          ready={!!createdMask}
          options={values(createdMask)}
          defaultValue={pathOr(null, [2, 'value'], values(createdMask))}
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorHub
          name="applicationsHubsFilter"
          onChange={handleChange('hubId')}
          isMulti
          label={t('HUB')}
          placeholder={t('form.TYPE_A_HUB_NAME')}
          value={filters.hubId}
          onLoad={onLoad('hubId')}
          storeName="Applications"
          storeType="const"
        />
      </Col>
      {withStatus && (
        <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
          <StatusSelect
            onChange={handleChange('status')}
            onLoad={onLoad('status')}
            options={getStatusList(t)}
            label={t('STATUS')}
            value={filters.status}
            isMulti
            name="applicationsStatusFilter"
            placeholder={t('form.CHOOSE_A_STATUS')}
            storeName="Applications"
          />
        </Col>
      )}
      <Col xl={3} lg={4} md={4} sm={6} pr-half>
        <Toggle
          label={t('form.SHOW_LOST')}
          input={{ checked: filters.showLost, onChange: () => null }}
          onCheck={handleChange('showLost')}
          onLoad={onLoad('showLost')}
          name="showLost"
          storeName="showLost"
        />
      </Col>
    </Row>
  );
};

ApplicationFilter.propTypes = {
  onFilterSelect: func,
  withStatus: bool,
  t: func
};
ApplicationFilter.defaultProps = {
  withStatus: false
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_FILTER',
    labelShow: 'default:SHOW_FILTER'
  }),
  translate(['applications', 'default'])
)(ApplicationFilter);
