import React, { useEffect, useState } from 'react';
import { array, bool, func, oneOfType, string } from 'prop-types';
import { compose, isEmpty } from 'ramda';

import { storableSelector } from '../storableDecorator';
import Selector from './Selector';
import { translate } from '../../translate/i18n';

const SelectOpeningDate = ({ t, onChange, name, value, isInForm, isDisabled, isMulti, isClearable, valueControl }) => {
  const handleChange = (val) => onChange(val === 'all' || !val ? null : val);

  const [options] = useState(() => [
    { label: t('default:ALL'), value: 'all' },
    { label: t('selectors:PRE_BOOKING'), value: 'prebooked' },
    { label: t('selectors:OPENING_SOON'), value: 'opening' },
    { label: t('selectors:OPENED'), value: 'opened' }
  ]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      label={t('form:OPENING_DATE')}
      name={name}
      placeholder={t('form:CHOOSE_A_DATE')}
      options={options}
      onChange={handleChange}
      isInForm={isInForm}
      value={value}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isMulti={isMulti}
    />
  );
};

SelectOpeningDate.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  t: func.isRequired,
  defaultValue: oneOfType([string, array])
};

SelectOpeningDate.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  value: null
};

export default compose(storableSelector, translate())(SelectOpeningDate);
