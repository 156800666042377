import { genState } from '../utils/reducer';
import { initCollection, GET_COLLECTIONS, SET_COLLECTIONS } from './collection.actionTypes';
import { propOr } from 'ramda';
import { emptyArray } from '../utils';

const collectionDef = (state) => ({
  [GET_COLLECTIONS]: () => ({
    ...state,
    isFetching: true
  }),
  [SET_COLLECTIONS]: (payload) => {
    const data = { rows: payload };
    return {
      isFetching: false,
      list: propOr(emptyArray, 'rows', data)
    };
  }
});

export const collectionReducer = (state = initCollection, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: collectionDef, type });
};
