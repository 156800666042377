import React, { useState } from 'react';
import { Button, Text, Row, Col, ConfirmModal, Container, Modal } from '../components';
import { translate } from '../translate/i18n';
import { func, array, string, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose, omit, pathOr } from 'ramda';

import { getApplicationStatus } from '../enum/enum.actions';
import { updateApplicantStatus } from './applications.actions';
import { capitalize } from '../utils';
import { getStatusList } from './applications.utils';
import { getLastApplicationFee } from './applications.service';

const loading = (Wrapped) => (props) => {
  return <Wrapped {...props} />;
};

const ActionButtons = ({ t, status, applicant, updateApplicantStatus, isUpdating }) => {
  const [showConfirm, toggleConfirm] = useState(false);
  const [currentStatus, toggleCurrentStatus] = useState('');
  const [applicationSet, toggleAddApplicationFee] = useState(false);
  const [tooManyRooms, toggleTooManyRooms] = useState(false);

  const statuses = getStatusList(t);
  const availableStatuses = statuses.find((statusValue) => statusValue.value === status);

  const showUpdateModal = (status) => {
    toggleCurrentStatus(status);
    toggleConfirm(true);
  };
  return (
    <Row>
      <Row mb>
        <Col lg={5} pt-half pr-half pb-half>
          <Text size="lg" weight={300} pr-half>
            Application status: {capitalize(status)}
          </Text>
        </Col>
      </Row>
      <Row middle>
        {availableStatuses &&
          availableStatuses.next.map((availableStatus) => (
            <Button
              key={availableStatus.value}
              disabled={isUpdating}
              mr
              mb
              name="validate"
              onClick={() => {
                if (
                  applicant.status === 'reviewing kyc' &&
                  availableStatus.value === 'sent' &&
                  pathOr(0, ['rooms', 'length'], applicant) !== 1
                ) {
                  toggleTooManyRooms(true);
                  return;
                }

                availableStatus.withModal
                  ? showUpdateModal(availableStatus.value)
                  : updateApplicantStatus({ ...omit(['houses'], applicant), status: availableStatus.value });
              }}>
              <Text grey weight={300}>
                {availableStatus.label}
              </Text>
            </Button>
          ))}

        <ConfirmModal
          title={t('APPLICATION_STATUS_CHANGE')}
          show={showConfirm}
          onClose={() => toggleConfirm(false)}
          onConfirm={async () => {
            toggleConfirm(false);
            let last;
            try {
              last = await getLastApplicationFee(applicant.id);
              // eslint-disable-next-line no-empty
            } catch (e) {}

            if (
              applicant.status === 'reviewing kyc' &&
              currentStatus === 'awaiting kyc' &&
              last &&
              +pathOr(0, ['payment', 'paid'], last)
            ) {
              toggleAddApplicationFee(true);
            } else {
              updateApplicantStatus({ ...omit(['houses'], applicant), status: currentStatus });
            }
          }}
          content={() => (
            <Col middle lg={12}>
              <Text left pb-quarter size="md">
                {t('APPLICATION_STATUS_CONFIRMATION')}
              </Text>
              <Text left weight={400} pb-quarter size="md">
                {capitalize(currentStatus)}
              </Text>
              <Text left pb-quarter size="md">
                {t('APPLICATION_STATUS_REQUISITES')}
              </Text>
            </Col>
          )}
        />

        <ConfirmModal
          title={t('payments:APPLICATION_FEE')}
          show={!!applicationSet}
          onClose={() => {
            toggleAddApplicationFee(null);
            updateApplicantStatus({ ...omit(['houses'], applicant), status: currentStatus });
          }}
          onConfirm={() => {
            toggleAddApplicationFee(null);
            updateApplicantStatus({ ...omit(['houses'], applicant), status: currentStatus, makeNewFee: true });
          }}
          content={() => (
            <Container p>
              <Text>{t('SHOULD_ADDITIONAL_APPLICATION_FEE_BE_CREATED')}</Text>
            </Container>
          )}
        />

        <Modal
          title={t('CANNOT_MOVE_TO_SENT')}
          show={tooManyRooms}
          onClose={() => toggleTooManyRooms(false)}
          content={() => (
            <Container p>
              <Row center>
                <Text weight={300} grey={500} size="lg" pb-double>
                  {t('TOO_MANY_ROOMS_ERROR')}
                </Text>
              </Row>
            </Container>
          )}
        />
      </Row>
    </Row>
  );
};

ActionButtons.propTypes = {
  t: func,
  statusList: array,
  getApplicationStatus: func,
  status: string,
  applicant: object,
  isFetchingStatus: bool,
  updateApplicantStatus: func,
  isUpdating: bool,
  inVerifications: bool
};

export default compose(
  translate('applications'),
  connect(
    (state) => ({
      applicant: pathOr({}, ['applications', 'current', 'data'], state),
      isUpdating: pathOr(false, ['applications', 'current', 'isUpdatingStatus'], state)
    }),
    { getApplicationStatus, updateApplicantStatus }
  ),
  loading
)(ActionButtons);
