import {
  ADD_HOUSE_AREAS,
  ADD_HOUSE_AREAS_SUCCESS,
  UPDATE_HOUSE_AREAS,
  UPDATE_HOUSE_AREAS_SUCCESS,
  REMOVE_HOUSE_AREAS,
  REMOVE_HOUSE_AREAS_SUCCESS,
  GET_HOUSE_AREAS,
  SET_HOUSE_AREAS,
  DELETE_HOUSE_LOCK,
  DELETE_HOUSE_LOCK_SUCCESS,
  UPSERT_HOUSE_LOCK,
  APPEND_HOUSES,
  APPEND_HOUSES_FAIL,
  APPEND_HOUSES_SUCCESS,
  GET_HOUSES,
  GET_HOUSES_SUCCESS,
  HOUSE_UPDATE,
  HOUSE_UPDATE_SUCCESS,
  GET_HOUSE_TRASHES,
  GET_HOUSE_TRASHES_FAIL,
  GET_HOUSE_TRASHES_SUCCESS,
  UPSERT_TRASH,
  UPSERT_UNIT,
  DELETE_TRASH,
  DELETE_TRASH_FAILURE,
  DELETE_TRASH_SUCCESS,
  DELETE_HOUSES,
  DELETE_HOUSES_FAILURE,
  DELETE_HOUSES_SUCCESS,
  UPSERT_SERVICE,
  GET_HOUSE_SERVICES,
  GET_HOUSE_SERVICES_FAILURE,
  GET_HOUSE_SERVICES_SUCCESS,
  DELETE_SERVICES,
  DELETE_SERVICES_FAILURE,
  DELETE_SERVICES_SUCCESS,
  DELETE_UNIT,
  DELETE_UNIT_FAILURE,
  DELETE_UNIT_SUCCESS
} from './houses.actionTypes';
import { createFormAction } from 'redux-form-saga';

const UPSERT_HOUSE = 'UPSERT_HOUSE';
export const upsertHouse = createFormAction(UPSERT_HOUSE);
export const upsertHouseLock = createFormAction(UPSERT_HOUSE_LOCK);

export const addHouseArea = (params = {}) => ({
  type: ADD_HOUSE_AREAS,
  payload: params
});

export const addHouseAreaSuccess = (params = {}) => ({
  type: ADD_HOUSE_AREAS_SUCCESS,
  payload: params
});

export const updateHouseArea = (params = {}) => ({
  type: UPDATE_HOUSE_AREAS,
  payload: params
});

export const updateHouseAreaSuccess = (params = {}) => ({
  type: UPDATE_HOUSE_AREAS_SUCCESS,
  payload: params
});

export const removeHouseArea = (params = {}) => ({
  type: REMOVE_HOUSE_AREAS,
  payload: params
});

export const removeHouseAreaSuccess = (params = {}) => ({
  type: REMOVE_HOUSE_AREAS_SUCCESS,
  payload: params
});

export const deleteHouseLockRequest = (payload) => ({
  type: DELETE_HOUSE_LOCK,
  payload
});

export const deleteHouseLockSuccess = (payload) => ({
  type: DELETE_HOUSE_LOCK_SUCCESS,
  payload
});

export const getHouses = (params = {}) => ({
  type: GET_HOUSES,
  payload: params
});

export const getHouseAreas = ({ id } = {}) => ({
  type: GET_HOUSE_AREAS,
  payload: id
});

export const setHouseAreas = (payload) => ({
  type: SET_HOUSE_AREAS,
  payload
});

export const setHouses = (houses) => ({
  type: GET_HOUSES_SUCCESS,
  payload: houses
});

export const appendHouses = (params = {}) => ({
  type: APPEND_HOUSES,
  payload: params
});

export const appendHousesSuccess = (houses) => ({
  type: APPEND_HOUSES_SUCCESS,
  payload: houses
});

export const appendHousesFail = (err) => ({
  type: APPEND_HOUSES_FAIL,
  payload: err
});

export const houseUpdate = (house) => ({
  type: HOUSE_UPDATE,
  payload: house
});

export const houseUpdateSuccess = (house) => ({
  type: HOUSE_UPDATE_SUCCESS,
  payload: house
});

export const FETCH_HOUSE_DETAILS = 'FETCH_HOUSE_DETAILS';
export const fetchHouseDetails = (houseId) => ({
  type: FETCH_HOUSE_DETAILS,
  payload: houseId
});

export const FETCH_HOUSE_DETAILS_SUCCESS = 'FETCH_HOUSE_DETAILS_SUCCESS';
export const fetchHouseDetailsSuccess = (house) => ({
  type: FETCH_HOUSE_DETAILS_SUCCESS,
  payload: house
});

export const FETCH_HOUSE_DETAILS_FAILURE = 'FETCH_HOUSE_DETAILS_FAILURE';
export const fetchHouseDetailsFailure = (error) => ({
  type: FETCH_HOUSE_DETAILS_FAILURE,
  payload: error
});

export const UPSERT_CATEGORY = 'UPSERT_CATEGORY';
export const upsertCategory = createFormAction(UPSERT_CATEGORY);
export const upsertUnit = createFormAction(UPSERT_UNIT);

export const DELETE_CATEGORIES = 'DELETE_CATEGORIES';
export const deleteCategories = (categories) => ({
  type: DELETE_CATEGORIES,
  payload: categories
});

export const DELETE_CATEGORIES_SUCCESS = 'DELETE_CATEGORIES_SUCCESS';
export const deleteCategoriesSuccess = (payload) => ({
  type: DELETE_CATEGORIES_SUCCESS,
  payload
});

export const DELETE_CATEGORIES_FAILURE = 'DELETE_CATEGORIES_FAILURE';
export const deleteCategoriesFailure = (error) => ({
  type: DELETE_CATEGORIES_FAILURE,
  payload: error
});

export const getHouseTrashes = (houseId) => ({
  type: GET_HOUSE_TRASHES,
  payload: houseId
});

export const getHouseTrashesSuccess = (trashes) => ({
  type: GET_HOUSE_TRASHES_SUCCESS,
  payload: trashes
});

export const getHouseTrashesFailure = () => ({
  type: GET_HOUSE_TRASHES_FAIL
});

export const upsertTrash = createFormAction(UPSERT_TRASH);

export const deleteTrash = (trashes) => ({
  type: DELETE_TRASH,
  payload: trashes
});

export const deleteTrashSuccess = (payload) => ({
  type: DELETE_TRASH_SUCCESS,
  payload
});

export const deleteTrashFailure = (error) => ({
  type: DELETE_TRASH_FAILURE,
  payload: error
});

export const deleteHouses = (houses) => ({
  type: DELETE_HOUSES,
  payload: houses
});

export const deleteHousesSuccess = (payload) => ({
  type: DELETE_HOUSES_SUCCESS,
  payload
});

export const deleteHousesFailure = (error) => ({
  type: DELETE_HOUSES_FAILURE,
  payload: error
});

export const getHouseServices = (houseId) => ({
  type: GET_HOUSE_SERVICES,
  payload: houseId
});

export const getHouseServicesSuccess = (trashes) => ({
  type: GET_HOUSE_SERVICES_SUCCESS,
  payload: trashes
});

export const getHouseServicesFailure = () => ({
  type: GET_HOUSE_SERVICES_FAILURE
});

export const upsertService = createFormAction(UPSERT_SERVICE);

export const deleteServices = (houses) => ({
  type: DELETE_SERVICES,
  payload: houses
});

export const deleteServicesSuccess = (payload) => ({
  type: DELETE_SERVICES_SUCCESS,
  payload
});

export const deleteServicesFailure = (error) => ({
  type: DELETE_SERVICES_FAILURE,
  payload: error
});
export const deleteUnit = (houses) => ({
  type: DELETE_UNIT,
  payload: houses
});

export const getHouseUnits = (houseId) => ({
  type: FETCH_HOUSE_DETAILS,
  payload: houseId
});
export const deleteUnitSuccess = (payload) => ({
  type: DELETE_UNIT_SUCCESS,
  payload
});

export const deleteUnitFailure = (error) => ({
  type: DELETE_UNIT_FAILURE,
  payload: error
});
