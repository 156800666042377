// Tips
export const GET_CONTENTS = 'GET_CONTENTS';
export const GET_CONTENTS_SUCCESS = 'GET_CONTENTS_SUCCESS';
export const GET_CONTENTS_FAILURE = 'GET_CONTENTS_FAILURE';

export const GET_CONTENT = 'GET_CONTENT';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILURE = 'GET_CONTENT_FAILURE';

export const APPEND_CONTENTS = 'APPEND_CONTENTS';
export const APPEND_CONTENTS_SUCCESS = 'APPEND_CONTENTS_SUCCESS';
export const APPEND_CONTENTS_FAILURE = 'APPEND_CONTENTS_FAILURE';

export const DELETE_CONTENTS = 'DELETE_CONTENTS';
export const DELETE_CONTENTS_SUCCESS = 'DELETE_CONTENTS_SUCCESS';
export const DELETE_CONTENTS_FAILURE = 'DELETE_CONTENTS_FAILURE';

export const UPSERT_CONTENT = 'UPSERT_CONTENT';
