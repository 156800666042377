import React from 'react';
import { array, string, object, oneOfType, func, bool, oneOf } from 'prop-types';
import styled from '@emotion/styled';
import { spacing } from './decorators';
import chroma from 'chroma-js';
import { compose } from 'ramda';
import { font } from '../theme/font';
import { bgColor } from '../theme/decorators';
import { actionSize } from './Button.action';

const StyledLink = styled('a')(({ color, theme, clear, spacing, width, fontSize, bgColor, actionBtn }) => {
  const backgroundColor = bgColor || theme.colors.lightGrey[400];

  const hoverBakcgroundColor = chroma(backgroundColor).darken(-0.2).hex();

  const size = actionBtn ? { width: actionSize, height: actionSize } : {};

  let textColor = 'white';
  if (clear) {
    textColor = theme.colors.grey[500];
  } else if (color) {
    textColor = color;
  }

  return {
    ...spacing,
    ...width,
    ...size,
    border: 'none',
    fontSize: theme.font.size[fontSize],
    position: 'relative',
    color: textColor,
    fontFamily: 'sans-serif',
    fontWeight: theme.font.weight.md,
    display: 'block',
    outline: 'none',
    textAlign: 'center',
    backgroundColor,
    cursor: 'pointer',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: hoverBakcgroundColor
    }
  };
});

const CustomLink = ({
  children,
  onClick,
  clear,
  spacing,
  name,
  size,
  type,
  disabled,
  danger,
  primary,
  title,
  bgColor,
  color,
  href
}) => {
  if (!spacing.padding) {
    spacing = { ...spacing, padding: '7px 25px' };
  }

  return (
    <StyledLink
      danger={danger}
      primary={primary}
      disabled={disabled}
      data-cy={`a-${name}`}
      clear={clear}
      href={href}
      onClick={onClick}
      spacing={spacing}
      alt={title}
      title={title}
      fontSize={size}
      bgColor={bgColor}
      color={color}
      type={type}>
      {children}
    </StyledLink>
  );
};

CustomLink.defaultProps = {
  clear: false,
  spacing: {},
  type: 'button',
  disabled: false,
  danger: false,
  primary: false,
  title: '',
  actionBtn: false,
  size: 'md',
  color: null
};

CustomLink.propTypes = {
  onClick: func,
  clear: bool,
  href: string.isRequired,
  children: oneOfType([array, string, object]),
  spacing: object,
  name: string.isRequired,
  type: string,
  disabled: bool,
  danger: bool,
  primary: bool,
  title: string,
  actionBtn: bool,
  bgColor: string,
  color: string,
  size: oneOf(Object.keys(font.size))
};

export default compose(bgColor(), spacing())(CustomLink);
