import { useEffect } from 'react';
import { compose, pathOr, propOr } from 'ramda';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';

import { show } from '../components';
import stream from './stream';
import { setCurrentChannel } from './chat.actions';
import { withRouter } from 'react-router-dom';

const ChatDeepLink = ({ match, setCurrentChannel, history }) => {
  useEffect(() => {
    const id = pathOr(null, ['params', 'id'], match);
    const type = pathOr(null, ['params', 'type'], match);
    if (propOr('', 'path', match) === '/chat/:type/id/:id') {
      stream.client
        .queryChannels(
          {
            id: { $eq: id },
            type: { $eq: type }
          },
          {},
          {}
        )
        .then((channels) => {
          const channel = propOr(null, 0, channels);

          if (channel) {
            setCurrentChannel(channel);
          }
        });

      history.push('/chat');
    }
  }, []);

  return null;
};

ChatDeepLink.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  setCurrentChannel: func.isRequired
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      connectStatus: pathOr(null, ['chat', 'connectStatus'], state)
    }),
    {
      setCurrentChannel
    }
  ),
  show((props) => pathOr(false, ['connectStatus'], props))
)(ChatDeepLink);
