import React from 'react';
import { spacing, alignement, border } from '../decorators';
import styled from '@emotion/styled';
import { oneOfType, object, array, string, bool } from 'prop-types';
import { pick, compose } from 'ramda';

const RowStyled = styled('div')(({ alignement, vertical, flexWrap, spacing, full, border }) => {
  const direction = vertical ? { flexDirection: 'column' } : {};

  return {
    ...spacing,
    ...alignement,
    ...direction,
    ...border,
    display: 'flex',
    width: '100%',
    flexWrap,
    height: full ? '100%' : 'auto'
  };
});

const AVAILABLE_ROW_ATTRIBUT = ['center', 'vertical', 'flexWrap'];
Object.freeze(AVAILABLE_ROW_ATTRIBUT);

const Row = (props) => {
  const { children, spacing, alignement, full, border } = props;
  const rowProps = pick(AVAILABLE_ROW_ATTRIBUT, props);
  return (
    <RowStyled alignement={alignement} full={full} spacing={spacing} border={border} {...rowProps}>
      {children}
    </RowStyled>
  );
};
Row.defaultProps = {
  vertical: false,
  flexWrap: 'wrap'
};
Row.propTypes = {
  center: bool,
  vertical: bool,
  children: oneOfType([string, array, object]),
  spacing: object,
  full: bool,
  border: object
};

export default compose(border(), spacing(), alignement())(Row);
