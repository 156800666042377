import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { compose, isEmpty, equals, pathOr } from 'ramda';

import { emptyArray, sortByCaseInsensitive, upperCase } from '../../utils';
import { getTipsCategories } from '../../enum/enum.actions';
import { storableSelector } from '../storableDecorator';
import Selector from './Selector';
import { translate } from '../../translate/i18n';

const SelectorTipsCategories = ({
  t,
  name,
  isMulti,
  label,
  list,
  isFetching,
  getTipsCategories,
  value,
  isInForm,
  isDisabled,
  isClearable,
  placeholder,
  onChange,
  valueControl
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    if (isEmpty(list)) {
      getTipsCategories();
    }
  }, []);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    const sorted = sortByCaseInsensitive('label')(
      list.map((option) => ({
        label: t(`TIPS_CATEGORIES.${upperCase(option)}`),
        value: option
      }))
    );

    if (!equals(sorted, options)) {
      setOptions(sorted);
    }
  }, [list, isFetching]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorTipsCategories.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  t: func,
  list: array.isRequired,
  isFetching: bool.isRequired,
  getTipsCategories: func.isRequired
};

SelectorTipsCategories.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null
};

export default compose(
  storableSelector,
  translate(['cms']),
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'tipsCategories', 'list'], state),
      isFetching: pathOr(false, ['enums', 'tipsCategories', 'isFetching'], state)
    }),
    { getTipsCategories }
  )
)(SelectorTipsCategories);
