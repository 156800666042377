import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as service from './paymentGateway.service';
import { GET_HUB_PAYMENT_GATEWAYS_REQUEST } from './paymentGateway.actionTypes';
import { fetchHubPaymentGatewaysSuccess } from './paymentGateway.actions';
import { handleErrorMessage } from '../toast/toast.actions';
import { types } from '../utils/sagas';

export function* getPaymentGateways({ payload } = {}) {
  try {
    const data = yield call(service.getPaymentGatewayList, payload);
    yield put(fetchHubPaymentGatewaysSuccess(data));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.HUB_PAYMENT_METHODS, error: err, data: payload }));
  }
}

export default function* hubSaga() {
  yield all([takeLatest(GET_HUB_PAYMENT_GATEWAYS_REQUEST, getPaymentGateways)]);
}
