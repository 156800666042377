import React from 'react';
import { object, func, string, bool } from 'prop-types';
import { TextArea } from '../';
import { InputContainer, StyledLabel, ErrorText } from './TextInput.styles';
import { formatInputProps } from './decorators';
import { compose } from 'ramda';
import { translate } from '../../translate/i18n';

const TextAreaForm = (props) => {
  const { meta, t, label, inputProps, placeholder, isDisabled } = props;
  const { touched, error, active } = meta;

  return (
    <InputContainer>
      <StyledLabel active={active}>{label}</StyledLabel>
      <TextArea {...inputProps} disabled={isDisabled} placeholder={placeholder} />
      {touched && error && <ErrorText>{t(`validation:${error}`)}</ErrorText>}
    </InputContainer>
  );
};
TextAreaForm.propTypes = {
  input: object,
  t: func,
  placeholder: string,
  isDisabled: bool
};

export default compose(formatInputProps(), translate('form'))(TextAreaForm);
