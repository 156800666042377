import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';

const StyledStripeLink = styled('a')(({ theme }) => ({
  backgroundColor: '#219cff',
  color: 'white',
  fontWeight: 'bold',
  padding: '0px 10px',
  borderRadius: '4px',
  display: 'inline-block',
  lineHeight: '38px',
  transition: 'all ease 400ms',
  fontFamily: theme.font.family,
  '&:hover': {
    backgroundColor: '#25ddf5'
  }
}));

export default withTheme(StyledStripeLink);
