import React from 'react';
import { string, number, oneOfType } from 'prop-types';
import { Text, Container } from './';
import { upperCase, capitalize } from '../utils';

const FormItem = ({ label, value }) => {
  return value ? (
    <Container column mb-half>
      <Text grey={400} size="xs" weight={300} pb-eighth labelSpacing>
        {upperCase(label)}
      </Text>
      <Text weight={300} grey={500}>
        {capitalize(value)}
      </Text>
    </Container>
  ) : null;
};

FormItem.propTypes = {
  label: string.isRequired,
  value: oneOfType([string, number])
};

export default FormItem;
