import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Row, Text, Button, Col } from '../components';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import * as Feather from 'react-feather';
import { translate } from '../translate/i18n';
import { updatePaymentProfile } from '../paymentProfile/paymentProfile.actions';
import SepaDebit from './paymentProfileMethod.sepa_debit';
import AchCredit from './paymentProfileMethod.ach_credit';
import SepaCredit from './paymentProfileMethod.sepa_credit';
import Card from './paymentProfileMethod.card';
import AchDebit from './paymentProfileMethod.ach_debit';
import SepaBankTransfer from './paymentProfileMethod.sepa_bank_transfer';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { capitalize } from '../utils';

const PaymentProfileMethodForm = ({
  member,
  currentHub,
  currentProfile,
  currentGateway,
  hubMethod,
  profileMethod,
  updatePaymentProfileCall,
  t
}) => (
  <Fragment>
    <Text weight={400} grey bb-lightGrey pb-quarter mb>
      <Row>
        <Col xl={6} lg={6} md={6} sm={6} center mt-half>
          <Row>
            {capitalize(hubMethod.payment_method.name)}

            {profileMethod && (
              <Feather.Check
                style={{
                  marginBottom: '-4px',
                  marginLeft: '10px'
                }}
                color="green"
                size={20}
                strokeWidth={4}
              />
            )}
          </Row>
        </Col>

        <Col xl={6} lg={6} md={6} sm={6} center right mt-half>
          <Row right>
            {currentProfile.preferredPaymentMethod === hubMethod.payment_method.type && (
              <div style={{ paddingTop: '5px', paddingBottom: '6px' }}>
                <Text size="md" grey={300} pr-half right>
                  {t('member:PREFERRED_PAYMENT_METHOD')}
                </Text>
              </div>
            )}

            {profileMethod &&
              currentProfile.preferredPaymentMethod !== hubMethod.payment_method.type &&
              hubMethod.payment_method.type !== 'card' && (
                <Button
                  size="md"
                  name="btn-save"
                  bg-primary
                  mr-half
                  onClick={() =>
                    updatePaymentProfileCall({
                      memberId: member.id,
                      hubId: currentHub.id,
                      preferredPaymentMethod: hubMethod.payment_method.type
                    })
                  }>
                  {t('member:MAKE_PREFERRED_METHOD')}
                </Button>
              )}
          </Row>
        </Col>
      </Row>
    </Text>

    {(() => {
      switch (hubMethod.payment_method.type) {
        case 'card':
          return (
            <Elements stripe={loadStripe(currentGateway.apiKey)}>
              <Card
                member={member}
                currentHub={currentHub}
                currentProfile={currentProfile}
                hubMethod={hubMethod}
                profileMethod={profileMethod}
              />
            </Elements>
          );

        case 'ach_debit':
          return (
            <AchDebit
              member={member}
              currentHub={currentHub}
              currentProfile={currentProfile}
              hubMethod={hubMethod}
              profileMethod={profileMethod}
              currentGateway={currentGateway}
            />
          );

        case 'sepa_debit':
          return (
            <SepaDebit
              member={member}
              currentHub={currentHub}
              currentProfile={currentProfile}
              hubMethod={hubMethod}
              profileMethod={profileMethod}
            />
          );

        case 'sepa_bank_transfer':
          return (
            <SepaBankTransfer
              member={member}
              currentHub={currentHub}
              currentProfile={currentProfile}
              hubMethod={hubMethod}
              profileMethod={profileMethod}
            />
          );

        case 'ach_credit':
          return (
            <AchCredit
              member={member}
              currentHub={currentHub}
              currentProfile={currentProfile}
              hubMethod={hubMethod}
              profileMethod={profileMethod}
            />
          );

        case 'sepa_credit':
          return (
            <SepaCredit
              member={member}
              currentHub={currentHub}
              currentProfile={currentProfile}
              hubMethod={hubMethod}
              profileMethod={profileMethod}
            />
          );

        default:
          return '';
      }
    })()}
    <br />
  </Fragment>
);

PaymentProfileMethodForm.propTypes = {
  t: func,
  member: object.isRequired,
  currentHub: object.isRequired,
  currentGateway: object.isRequired,
  currentProfile: object,
  hubMethod: object,
  profileMethod: object,
  updatePaymentProfileCall: func
};

export default compose(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        updatePaymentProfileCall: updatePaymentProfile.request
      },
      dispatch
    )
  ),
  translate()
)(PaymentProfileMethodForm);
