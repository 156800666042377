export const SET_ACCESS_RESTRICTED = 'SET_ACCESS_RESTRICTED';

export const GET_LEASES = 'GET_LEASES';
export const GET_LEASES_SUCCESS = 'GET_LEASES_SUCCESS';
export const GET_LEASES_FAIL = 'GET_LEASES_FAIL';

export const GET_LEASE_DOCUMENTS = 'GET_LEASE_DOCUMENTS';
export const GET_LEASE_DOCUMENTS_SUCCESS = 'GET_LEASE_DOCUMENTS_SUCCESS';
export const GET_LEASE_DOCUMENTS_FAIL = 'GET_LEASE_DOCUMENTS_FAIL';

export const GET_LEASE = 'GET_LEASE';
export const GET_LEASE_SUCCESS = 'GET_LEASE_SUCCESS';
export const GET_LEASE_FAIL = 'GET_LEASE_FAIL';

export const GET_HELLO_SIGN_LEASE = 'GET_HELLO_SIGN_LEASE';
export const GET_HELLO_SIGN_LEASE_SUCCESS = 'GET_HELLO_SIGN_LEASE_SUCCESS';

export const APPEND_LEASES = 'APPEND_LEASES';
export const APPEND_LEASES_SUCCESS = 'APPEND_LEASES_SUCCESS';
export const APPEND_LEASES_FAIL = 'APPEND_LEASES_FAIL';

export const UPDATE_LEASE = 'UPDATE_LEASE';
export const CANCEL_LEASE = 'CANCEL_LEASE';
