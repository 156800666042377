import React, { useState, useEffect, Fragment } from 'react';
import { func, array } from 'prop-types';
import { compose, keys, values } from 'ramda';
import { translate } from '../translate/i18n';
import { Row, Text, Col, Toggle } from '../components';
import { upperCase } from '../utils';

const inventoryChecklist = ({ t, list }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [checklist, setChecklist] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const view = list.map((item) => ({
      type: keys(item)[0],
      checked: values(item)[0]
    }));

    setChecklist(view);
  }, [list]);

  if (!checklist.length) {
    return null;
  }

  return (
    <Fragment>
      <Row middle mb>
        <Text weight={300} size="lg">
          {t('FORM.CHECKLIST_SECTION_TITLE')}
        </Text>
      </Row>
      <Row middle mb>
        {checklist.map((item, index) => (
          <Col xl={6} lg={6} md={12} sm={12} pr-half mb key={index}>
            <Toggle
              disabled
              input={{
                checked: item.checked
              }}
              right
              label={t(upperCase(`CHECKLIST.${item.type}`))}
            />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

inventoryChecklist.propTypes = {
  t: func,
  list: array
};

inventoryChecklist.defaultProps = {
  list: []
};

export default compose(translate('inventories'))(inventoryChecklist);
