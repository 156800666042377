import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { array, func, bool, string } from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';
import moment from 'moment-timezone';
import { compose, pathOr } from 'ramda';
import { Container, Loader, Text, Button, ConfirmModal, Col, Row } from '../components';
import { translate } from '../translate/i18n';
import { getLeases } from './member.actions';
import { cancelLease } from '../leases/leases.actions';
import { emptyArray, capitalize, formatLeaseLocationLabel, sortByDate } from '../utils';
import LeaseDiscountModal from '../discounts/discount/leaseDiscountModal';
import LeaseDiscountList from '../discounts/discount/leaseDiscountList';

const ProfileHeader = ({ displayName }) => (
  <Text size="lg" weight={300}>
    {displayName}
  </Text>
);
ProfileHeader.propTypes = {
  displayName: string
};

class LeasesProfile extends PureComponent {
  static propTypes = {
    cancelLease: func,
    getLeases: func,
    leases: array,
    t: func,
    isFetching: bool,
    memberId: string
  };

  state = {};

  componentDidMount() {
    const { getLeases, memberId } = this.props;

    getLeases(memberId);
  }

  renderLeaseActionButtons = ({ lease }) => {
    const { t } = this.props;
    const { startDate } = lease;

    const startDay = moment.utc(startDate).startOf('day');

    return (
      <Row right>
        <Col lg={6} pl-quarter pr-quarter pt-half>
          {startDay.diff(moment.utc()) <= 0 ? (
            <Tooltip className="discount-tooltip" content={t('leases:TOOLTIP_CANCEL_LEASE')} direction="down">
              <div className="disabled-placeholder">{capitalize(t('leases:CANCEL_LEASE'))}</div>
            </Tooltip>
          ) : (
            <Button primary bg-primary onClick={() => this.setState({ lease })} size="md" name="cancel" type="button">
              {capitalize(t('leases:CANCEL_LEASE'))}
            </Button>
          )}
        </Col>

        <LeaseDiscountModal lease={lease} />
      </Row>
    );
  };

  render() {
    const { leases, isFetching, t, cancelLease } = this.props;
    const { lease } = this.state;

    if (isFetching) {
      return <Loader />;
    }

    const leaseCurrencySymbol = ({ lease } = {}) => pathOr('', ['room', 'house', 'hub', 'currency', 'symbol'], lease);
    const sortedLeases = sortByDate('startDate', leases, 'ASC');

    return (
      <Container column pt>
        <Text size="lg" weight={300} grey bb-lightGrey mb-half>
          {t('profile.leaseTitle')}
        </Text>

        {sortedLeases.map((lease) => (
          <>
            <Row key={lease.id} middle center>
              <Col xl={6} lg={9} md={6} sm={9} pt-half>
                <Link to={`/leases/${lease.id}`}>
                  <Text cursor="pointer" left type="container" noWrap={false}>
                    {formatLeaseLocationLabel({ lease })}
                  </Text>
                </Link>
              </Col>

              <Col xl={3} lg={3} md={3} sm={3} middle center pt-half>
                <Text size="md" weight={300} grey>
                  {lease.status}
                </Text>
              </Col>

              <Col xl={3} lg={12} md={3} sm={12}>
                {this.renderLeaseActionButtons({ lease })}
              </Col>
            </Row>

            <Row mt mb>
              <LeaseDiscountList
                list={lease.discounts}
                incomingPrice={lease.rentAmount}
                currencySymbol={leaseCurrencySymbol({ lease })}
                leaseStartDate={lease.startDate}
              />
            </Row>
          </>
        ))}

        <ConfirmModal
          title={t('leases:CANCEL_LEASE')}
          show={!!lease}
          onClose={() => this.setState({ lease: null })}
          onConfirm={() => {
            cancelLease(lease);
            this.setState({ lease: null });
          }}
          content={() => (
            <Container p>
              <Text>{t('leases:CONFIRM_CANCEL_LEASE')}</Text>
            </Container>
          )}
        />
      </Container>
    );
  }
}

export default compose(
  translate('member'),
  connect(
    (state) => ({
      isFetching: pathOr(emptyArray, ['member', 'current', 'leases', 'isFetching'], state),
      leases: pathOr(emptyArray, ['member', 'current', 'leases', 'list'], state)
    }),
    { getLeases, cancelLease }
  )
)(LeasesProfile);
