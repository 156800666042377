import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { Row, Text, Col } from '../components';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import DeleteSource from './paymentProfileMethod.deleteSource';

const PaymentProfileMethodSepaBankTransfer = ({ member, currentHub, currentProfile, hubMethod, profileMethod, t }) => {
  return (
    <Fragment>
      {profileMethod && profileMethod.stripeData && (
        <Fragment>
          <Row>
            <Col xl={3} lg={3} md={3} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:profile.ACCOUNT_HOLDER_NAME')}
              </Text>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.accountHolderName}
              </Text>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:profile.BIC')}
              </Text>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.bic}
              </Text>
            </Col>
          </Row>

          <Row>
            <Fragment>
              <Col xl={3} lg={3} md={6} sm={6} mb>
                <Text size="md" grey={300} pr-half>
                  {t('form:IBAN')}
                </Text>
              </Col>

              <Col xl={3} lg={3} md={6} sm={6} mb>
                <Text size="md" weight={300} pr-half>
                  {profileMethod.stripeData.iban}
                </Text>
              </Col>
            </Fragment>

            <Col xl={6} lg={12} md={12} sm={12} mb>
              <Row right>
                <DeleteSource
                  member={member}
                  currentHub={currentHub}
                  currentProfile={currentProfile}
                  hubMethod={hubMethod}
                />
              </Row>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

PaymentProfileMethodSepaBankTransfer.propTypes = {
  t: func,
  member: object.isRequired,
  currentHub: object.isRequired,
  currentProfile: object,
  hubMethod: object,
  profileMethod: object
};

export default compose(translate())(PaymentProfileMethodSepaBankTransfer);
