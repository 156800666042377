import React, { useEffect } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { compose, isEmpty } from 'ramda';

import Selector from './Selector';
import { storableSelector } from '../storableDecorator';

const SelectorCreated = ({
  valueControl,
  onChange,
  name,
  label,
  value,
  isInForm,
  isDisabled,
  isClearable,
  placeholder,
  defaultValue,
  isMulti,
  options
}) => {
  const handleChange = (val) => onChange(val === 'all' || !val ? defaultValue : val);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorCreated.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  options: array.isRequired,
  defaultValue: oneOfType([string, array])
};

SelectorCreated.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null,
  defaultValue: null
};

export default compose(storableSelector)(SelectorCreated);
