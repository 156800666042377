import { propOr, pathOr } from 'ramda';
import {
  GET_ROOMS,
  GET_ROOMS_SUCCESS,
  APPEND_ROOMS,
  APPEND_ROOMS_FAIL,
  APPEND_ROOMS_SUCCESS,
  GET_ROOM,
  GET_ROOM_SUCCESS,
  GET_ROOM_FAILURE,
  DELETE_ROOMS_SUCCESS,
  DELETE_ROOM_LOCK_SUCCESS
} from './room.actionTypes';
import { upsertRoomLock } from './room.actions';
import {
  genState,
  setFetching,
  appendList,
  setList,
  appendListFailure,
  setFetchingCurrent,
  setCurrent,
  setCurrentFailure,
  excludeList
} from '../utils/reducer';
import { emptyObject } from '../utils';

const room = (state) => ({
  [GET_ROOMS]: setFetching(state, true),
  [GET_ROOMS_SUCCESS]: setList(state),
  [GET_ROOM]: setFetchingCurrent(state, true),
  [GET_ROOM_SUCCESS]: setCurrent(state),
  [GET_ROOM_FAILURE]: setCurrentFailure(state),
  [APPEND_ROOMS]: setFetching(state, true),
  [APPEND_ROOMS_SUCCESS]: appendList(state),
  [APPEND_ROOMS_FAIL]: appendListFailure(state),
  [DELETE_ROOMS_SUCCESS]: excludeList(state),
  [DELETE_ROOM_LOCK_SUCCESS]: () => {
    return {
      ...state,
      current: {
        ...propOr(emptyObject, 'current', state),
        data: {
          ...pathOr(emptyObject, ['current', 'data'], state),
          lock: null
        }
      }
    };
  },
  [upsertRoomLock.SUCCESS]: (payload) => {
    if (pathOr(null, ['current', 'data', 'id'], state) !== payload.roomId) {
      return state;
    }

    return {
      ...state,
      current: {
        ...propOr(emptyObject, 'current', state),
        data: {
          ...pathOr(emptyObject, ['current', 'data'], state),
          lock: payload.lock
        }
      }
    };
  }
});

export const roomReducer = (state = {}, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: room, type });
};
