import React, { useState } from 'react';
import { func, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propOr } from 'ramda';

import { translate } from '../translate/i18n';
import { appendMembers, getMembers } from './chat.actions';
import Filter from '../members/Member.filter';
import { capitalize, valueOrPlaceholder, isNotEmptyOrNil } from '../utils';
import { Container, ScrollableDataTable, Block } from '../components';
import stream from './stream';
import { toggleCreateChatChannel } from './chat.actions';

const getColumnDefs = (t) => [
  {
    headerName: capitalize(t('FIRST_NAME')),
    field: 'firstName',
    defaultActive: true,
    width: 120
  },
  {
    headerName: capitalize(t('LAST_NAME')),
    field: 'lastName',
    defaultActive: true,
    width: 120
  },
  {
    headerName: capitalize(t('HUB')),
    field: 'leases.0.room.house.hub.name',
    width: 120
  },
  {
    headerName: capitalize(t('HOUSE')),
    field: 'leases.0.room.house.name',
    width: 150
  },
  {
    headerName: capitalize(t('ROOM')),
    field: 'leases.0.room.number',
    width: 80
  }
];

const NewChannel = ({ t, members, appendMembers, getMembers, toggleCreateChatChannel }) => {
  const [filters, setFilters] = useState();

  return (
    <div className="new-channel-container-sizer">
      <Container pb pr pl column scrollable>
        <Block>
          <Filter onFilterSelect={(filter) => setFilters(filter)} activeOnly />

          <ScrollableDataTable
            onRowClicked={({ data }) => {
              stream.createChannel(data.id);
              toggleCreateChatChannel(false);
            }}
            filters={filters}
            ready={!!filters}
            columnDefs={getColumnDefs(t)}
            defaultColDef={{ valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(propOr('', 'value', col)) }}
            getMethod={getMembers}
            appendMethod={appendMembers}
            rows={members.list}
            rowCount={members.count}
            isFetching={members.isFetching}
            responseLength={members.responseLength}
            pageSize={15}
          />
        </Block>
      </Container>
    </div>
  );
};

NewChannel.propTypes = {
  t: func,
  appendMembers: func,
  getMembers: func,
  toggleCreateChatChannel: func,
  members: object
};

export default compose(
  translate(['member', 'chat']),
  connect(
    (state) => ({
      members: propOr([], 'chat', state)
    }),
    { appendMembers, getMembers, toggleCreateChatChannel }
  )
)(NewChannel);
