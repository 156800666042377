import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Text, Row, Button, Modal, Container, FormItem, sizing } from '../components';
import PersonalInfoForm from './Application.personalInfo.form';
import { object, func, bool, number } from 'prop-types';
import { formatDate, formatPhone, emptyObject } from '../utils';
import { propOr, pathOr, compose } from 'ramda';
import { translate } from '../translate/i18n';
import { spacing } from '../theme/spacing';

const initFormValues = (values) => ({
  ...values,
  phone: {
    number: propOr('', 'phoneNumber', values),
    prefix: {
      value: pathOr('', ['phonePrefix', 'code'], values),
      flag: pathOr('', ['phonePrefix', 'flag'], values),
      prefix: pathOr('', ['phonePrefix', 'prefix'], values)
    }
  }
});

const PersonalInfos = (props) => {
  const [show, setModal] = useState(false);
  const { t, user, windowWidth } = props;
  const formattedPhone = formatPhone(propOr('', 'phoneNumber', user), pathOr('', ['phonePrefix', 'prefix'], user));

  const form = propOr(emptyObject, 'form', props);
  useEffect(() => {
    if (form.submitSucceeded) setModal(false);
  }, [form]);
  const colView = windowWidth > spacing.breakpoints.lg;

  return (
    <Container scrollable main={colView} bgColor column p-half>
      <Row middle>
        <Row right mb>
          <Button ml name="edit" bg-secondary onClick={() => setModal(true)}>
            <Text weight={300} white>
              {t('default:EDIT')}
            </Text>
          </Button>{' '}
        </Row>
        <Row>
          <Row center m>
            <Text black size="lg" weight={300}>
              {t('details.PERSONAL_SECTION')}
            </Text>
          </Row>
          <Modal
            title={t('details.PERSONAL_SECTION')}
            show={show}
            onClose={() => setModal(false)}
            content={() => <PersonalInfoForm initialValues={initFormValues(user)} onClose={() => setModal(false)} />}
          />
        </Row>
        <FormItem label={t('FIRSTNAME')} value={user.firstName} />
        <FormItem label={t('LASTNAME')} value={user.lastName} />
        <FormItem label={t('BIRTHDATE')} value={formatDate(user.birthdate)} />
        <FormItem label={t('JOB')} value={user.job} />
        <FormItem label={t('EMAIL')} value={user.email} />
        <FormItem label={t('PHONE_NUMBER')} value={formattedPhone} />
      </Row>
      -
    </Container>
  );
};

PersonalInfos.propTypes = {
  t: func,
  user: object.isRequired,
  updateApplicantInfo: func,
  form: object,
  windowWidth: number,
  bgColor: bool
};

export default compose(
  translate('applications'),
  sizing(),
  connect((state) => ({
    user: pathOr(emptyObject, ['applications', 'current', 'data', 'user'], state),
    form: pathOr(emptyObject, ['form', 'PersonalInfoForm'], state)
  }))
)(PersonalInfos);
