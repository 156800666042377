import React, { PureComponent } from 'react';

import { func, bool, string, object, array } from 'prop-types';
import { Table, Container, Text } from '../components';
import { translate } from '../translate/i18n';

import { Link } from 'react-router-dom';
import { formatDate } from '../utils/format';

const ProfileHeader = ({ displayName }) => (
  <Container column>
    <Link to="/">
      <Text size="lg" weight={300}>
        {displayName}
      </Text>
    </Link>
  </Container>
);

ProfileHeader.propTypes = {
  displayName: string
};

class ActivityLog extends PureComponent {
  static propTypes = {
    fetchApplicant: func,
    applicant: object,
    t: func,
    match: object,
    isFetching: bool,
    logs: array
  };

  constructor(props) {
    super(props);
    const { t } = props;

    this.columnDefs = [
      {
        headerName: '',
        field: 'createdAt',
        width: 100,
        valueFormatter: (col) => {
          return formatDate(col.value);
        }
      },
      {
        headerName: '',
        field: 'creator.firstName',
        width: 125
      },
      {
        headerName: '',
        field: 'creator.lastName',
        width: 100
      },
      {
        headerName: '',
        field: 'newStatus',
        width: 300,
        valueFormatter: (col) => {
          return `${t('MOVE_APPLICATION_TO', { status: col.value })}`;
        }
      }
    ];
  }

  render() {
    const { logs } = this.props;
    const datatable = {
      columnDefs: this.columnDefs,
      rowData: logs,
      frameworkComponents: { agColumnHeader: ProfileHeader }
    };

    return (
      <Container column>
        <Table data={datatable} hasPagination={false} />
      </Container>
    );
  }
}

export default translate('applications')(ActivityLog);
