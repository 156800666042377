export const STREAM_CHAT_AUTH = 'STREAM_CHAT_AUTH';
export const STREAM_CHAT_LOGOUT = 'STREAM_CHAT_LOGOUT';

export const SET_STREAM_CHAT_CHANNEL = 'SET_STREAM_CHAT_CHANNEL';

export const STREAM_CHAT_CONNECTED = 'STREAM_CHAT_CONNECTED';
export const STREAM_CHAT_DISCONNECTED = 'STREAM_CHAT_DISCONNECTED';

export const STREAM_CHAT_UNREAD_COUNT = 'STREAM_CHAT_UNREAD_COUNT';
export const STREAM_CHAT_TOGGLE_MINI = 'STREAM_CHAT_TOGGLE_MINI';

export const GET_MEMBERS_FOR_CHAT = 'GET_MEMBERS_FOR_CHAT';
export const GET_MEMBERS_FOR_CHAT_SUCCESS = 'GET_MEMBERS_FOR_CHAT_SUCCESS';
export const GET_MEMBERS_FOR_CHAT_FAIL = 'GET_MEMBERS_FOR_CHAT_FAIL';

export const APPEND_MEMBERS_FOR_CHAT = 'APPEND_MEMBERS_FOR_CHAT';
export const APPEND_MEMBERS_FOR_CHAT_SUCCESS = 'APPEND_MEMBERS_FOR_CHAT_SUCCESS';
export const APPEND_MEMBERS_FOR_CHAT_FAIL = 'APPEND_MEMBERS_FOR_CHAT_FAIL';

export const SET_CHAT_FILTER = 'SET_CHAT_FILTER';
export const TOGGLE_CREATE_CHAT_CHANNEL = 'TOGGLE_CREATE_CHAT_CHANNEL';

export const initState = {
  list: [],
  search: {
    query: '',
    filter: {},
    type: null
  },
  showCreateChannelModal: false,
  toggleMini: { block: false, chat: false, maximise: false },
  unreadCount: 0,
  connectStatus: false,
  currentChannel: null
};
