import React, { PureComponent, Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { pathOr, propOr, prop } from 'ramda';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { func, array, number, bool, object } from 'prop-types';
import { createRoom, getRooms, editRoom, appendRooms, deleteRooms } from './room.actions';
import { translate } from '../translate/i18n';
import { formatDate, isNotEmptyOrNil, valueOrPlaceholder } from '../utils';
import {
  Block,
  Row,
  Col,
  Text,
  ScrollableDataTableWithColSelector,
  ActionButton,
  ActionSecondaryButton
} from '../components';
import Filter from './Room.filter';

const dayInMls = 24 * 3600 * 1000;
const currentTime = new Date();

class Rooms extends PureComponent {
  static propTypes = {
    t: func,
    history: object,
    createRoom: func,
    editRoom: func,
    roomsList: array,
    getRooms: func,
    roomsCount: number,
    pageNumber: number,
    isFetching: bool,
    appendRooms: func,
    responseLength: number,
    deleteRooms: func
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: null,
      showCreationModal: false,
      selectedRooms: [],
      formData: {},
      houseId: {},
      hubId: null,
      urgency: {},
      roomId: null
    };
  }

  componentDidMount() {
    const { t } = this.props;

    this.columnDefs = [
      {
        headerName: '',
        label: t('default:CHECKBOX'),
        width: 25,
        valueFormatter: () => null,
        headerComponentFramework: ({ api, column }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

          return (
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                isChecked ? api.deselectAll() : api.selectAll();
                setIsChecked(!isChecked);
                api.refreshCells({ columns: [column], force: true });
              }}
            />
          );
        },
        onCellClicked: () => null,

        cellRendererFramework: ({ node, api }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isChecked, setIsChecked] = useState(node.isSelected());
          return (
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked(!node.isSelected());
                node.setSelected(!node.isSelected());
                api.refreshHeader();
              }}
            />
          );
        }
      },
      {
        headerName: t('LOCATION'),
        defaultActive: true,
        field: 'hub',
        width: 90
      },
      { headerName: t('HOUSE'), defaultActive: true, field: 'house', width: 130 },
      { headerName: t('ROOM_NUMBER'), defaultActive: true, field: 'number', width: 60 },
      { headerName: t('DESCRIPTION'), field: 'description', width: 100, sortable: false },
      { headerName: t('SURFACE'), field: 'surface', width: 80, sortable: false },
      {
        headerName: t('LEASE_END'),
        field: 'moveoutDate',
        width: 120,
        valueFormatter: (col) => {
          const endDate = pathOr('', ['data', 'moveoutDate'], col);
          return valueOrPlaceholder(isNotEmptyOrNil, formatDate)(endDate, col.data.timezone);
        },
        tooltip: ({ data }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(data.endDate)
      },
      {
        headerName: t('LEASE_PREFERRED_END'),
        field: 'preferredMoveoutDate',
        width: 160,
        valueFormatter: (col) => {
          const preferedEndDate = pathOr('', ['data', 'preferredMoveoutDate'], col);
          return valueOrPlaceholder(isNotEmptyOrNil, formatDate)(preferedEndDate, col.data.timezone);
        },
        tooltip: ({ data }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(data.moveoutDate)
      },
      {
        headerName: t('EMPTY_IN'),
        field: 'emptyIn',
        width: 90,
        valueFormatter: (col) => {
          const moveoutDate = pathOr(undefined, ['data', 'moveoutDate'], col);
          const endDate = pathOr(undefined, ['data', 'endDate'], col);

          if (moveoutDate) {
            let timeInMls = new Date(moveoutDate).getTime() - currentTime.getTime();

            return `${Math.floor(timeInMls / dayInMls)} ${t('DAYS')}`;
          }

          if (endDate) {
            let timeInMls = new Date(endDate).getTime() - currentTime.getTime();
            let daysTillEndDate = `0 ${t('DAYS')}`;

            if (timeInMls > 0) {
              daysTillEndDate = `${Math.floor(timeInMls / dayInMls)} ${t('DAYS')}`;
            }
            return daysTillEndDate;
          }

          return '--';
        }
      },
      {
        headerName: t('URGENCY'),
        field: 'urgency',
        width: 180,
        valueFormatter: (col) => {
          const urgencyStatus = this.urgencyList.find((status) => status.id === col.value && status.name);
          return valueOrPlaceholder(isNotEmptyOrNil)(propOr('', 'name', urgencyStatus));
        }
      },
      {
        headerName: t('LEASE_RENT'),
        field: 'rentAmount',
        width: 80,
        valueFormatter: (col) => {
          const leaseRent = pathOr('', ['data', 'rentAmount'], col);
          return leaseRent ? parseInt(leaseRent) : '--';
        }
      },
      {
        headerName: t('ROOM_RENT'),
        field: 'rent',
        width: 80,
        sortable: false
      }
    ];

    this.urgencyList = [
      {
        id: 'all',
        name: t('ALL')
      },
      {
        id: 'critical',
        name: t('CRITICAL_FREE_NOW')
      },
      {
        id: 'veryHigh',
        name: t('VERY_HIGH')
      },
      {
        id: 'high',
        name: t('HIGH')
      },
      {
        id: 'medium',
        name: t('MEDIUM')
      },
      {
        id: 'low',
        name: t('LOW')
      }
    ];
  }

  goToRoom = (event) => {
    const { history } = this.props;
    const roomId = pathOr({}, ['data', 'id'], event);
    history.push(`/rooms/${roomId}`);
  };

  deleteSelection = () => {
    const { deleteRooms } = this.props;
    const { selectedRooms } = this.state;
    deleteRooms(selectedRooms);
    this.setState({ selectedRooms: [] });
  };

  onRowSelected = (event) => {
    this.setState({ selectedRooms: event.api.getSelectedRows() });
  };

  handleSubmit(formValues) {
    return formValues;
  }

  render() {
    const { t, getRooms, isFetching, appendRooms, roomsList, roomsCount, responseLength, history } = this.props;

    const { filters, selectedRooms } = this.state;

    return (
      <Fragment>
        <Block p>
          <Row mb>
            <Col lg={12}>
              <Row middle>
                <Text grey={500} size="xl" pr>
                  {t('ROOMS')}
                </Text>

                {!selectedRooms.length ? (
                  <ActionButton icon="Plus" name="addRoom" onClick={() => history.push('/rooms/create')} />
                ) : (
                  <ActionSecondaryButton name="deleteRoom" icon="Trash2" onClick={this.deleteSelection} danger />
                )}
              </Row>
            </Col>
          </Row>

          <Filter
            onFilterSelect={(filters) =>
              this.setState({
                filters
              })
            }
          />

          <ScrollableDataTableWithColSelector
            filters={filters}
            ready={!!filters}
            columnDefs={this.columnDefs}
            defaultColDef={{
              valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
              onCellClicked: this.goToRoom
            }}
            getMethod={getRooms}
            appendMethod={appendRooms}
            rows={roomsList}
            rowCount={roomsCount}
            isFetching={isFetching}
            responseLength={responseLength}
            onRowSelected={this.onRowSelected}
          />
        </Block>
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  translate(['rooms', 'default']),
  connect(
    (state) => ({
      roomsList: pathOr([], ['room', 'list'], state),
      roomsCount: pathOr(0, ['room', 'count'], state),
      responseLength: pathOr(0, ['room', 'responseLength'], state),
      pageNumber: pathOr(1, ['room', 'pageNumber'], state),
      isFetching: pathOr(false, ['room', 'isFetching'], state)
    }),
    (dispatch) => bindActionCreators({ createRoom, appendRooms, getRooms, editRoom, deleteRooms }, dispatch)
  )
)(Rooms);
