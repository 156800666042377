import React from 'react';
import SelectorGeoCountry from '../selector/Selector.geo.country';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';

const SelectGeoCountryForm = ({ t, input, isClearable, label, isDisabled, meta: { touched, error }, isMulti }) => (
  <InputContainer>
    <SelectorGeoCountry
      label={label}
      isClearable={isClearable}
      {...input}
      isMulti={isMulti}
      isInForm={true}
      isDisabled={isDisabled}
    />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectGeoCountryForm);
