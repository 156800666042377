import React, { useState } from 'react';
import {
  ScrollableDataTableWithColSelector,
  Text,
  ActionButton,
  Row,
  Modal,
  ActionSecondaryButton,
  Block
} from '../components';
import { translate } from '../translate/i18n';
import { compose, pathOr, propOr, prop, path, pathEq } from 'ramda';
import { func, bool, array, string, number } from 'prop-types';
import UserForm from './User.form';
import { connect } from 'react-redux';
import { fetchUsers, deleteUsers, appendUsers } from './user.actions';
import { onlyForAdmin } from '../components';
import { emptyArray, valueOrPlaceholder, isNotEmptyOrNil, formatPhone } from '../utils';

const isSelectable = (currentUserId) => (params) =>
  !pathEq(['data', 'id'], currentUserId, params) && !pathEq(['data', 'role'], 'admin', params);

const dataDef = (t, onSelect, currentUserId) => [
  {
    headerName: '',
    label: t('default:CHECKBOX'),
    checkboxSelection: isSelectable(currentUserId),
    width: 20,
    headerComponentFramework: ({ api, column }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            isChecked ? api.deselectAll() : api.selectAll();
            setIsChecked(!isChecked);
            api.refreshCells({ columns: [column], force: true });
          }}
        />
      );
    },
    onCellClicked: () => null,

    cellRendererFramework: ({ node, api, data }) => {
      if (isSelectable(currentUserId)({ data })) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isChecked, setIsChecked] = useState(node.isSelected());
        return (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => {
              setIsChecked(!node.isSelected());
              node.setSelected(!node.isSelected());
              api.refreshHeader();
            }}
          />
        );
      }
      return null;
    }
  },
  {
    headerName: t('FIRST_NAME'),
    field: 'firstName',
    defaultActive: true,
    onCellClicked: (event) => onSelect(prop('data', event))
  },
  {
    headerName: t('LAST_NAME'),
    field: 'lastName',
    defaultActive: true,
    onCellClicked: (event) => onSelect(prop('data', event))
  },
  {
    headerName: t('ROLE'),
    field: 'role',
    defaultActive: true,
    valueFormatter: (col) => t(`role:${col.value}`),
    onCellClicked: (event) => onSelect(prop('data', event))
  },
  {
    headerName: t('EMAIL'),
    field: 'email',
    onCellClicked: (event) => onSelect(prop('data', event))
  },
  {
    headerName: t('PHONE'),
    field: 'phoneNumber',
    onCellClicked: (event) => onSelect(prop('data', event)),
    valueFormatter: (col) => {
      const formattedPhone = formatPhone(
        pathOr('', ['data', 'phoneNumber'], col),
        pathOr('', ['data', 'phonePrefix', 'prefix'], col)
      );

      return valueOrPlaceholder(isNotEmptyOrNil)(formattedPhone);
    }
  }
];

const UserList = (props) => {
  const [show, setModal] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(emptyArray);

  const { t, usersList, isFetching, dispatch, currentUserId, isDeleting, usersCount, responseLength } = props;

  const handleClose = () => setModal(null);

  const onSelect = (user) => {
    setModal({
      id: prop('id', user),
      picture: prop('picture', user),
      role: prop('role', user),
      lastName: prop('lastName', user),
      firstName: prop('firstName', user),
      email: prop('email', user),
      phone: {
        number: propOr('', 'phoneNumber', user),
        prefix: {
          value: pathOr('', ['phonePrefix', 'code'], user),
          prefix: pathOr('', ['phonePrefix', 'prefix'], user)
        }
      }
    });
  };

  return (
    <Block p>
      <Row middle mb>
        <Text weight={200} size="xl" pr-half>
          {t('USERS')}
        </Text>
        <ActionButton icon="Plus" name="addUser" onClick={() => setModal({})} />

        {!!prop('length', selectedUsers) && (
          <ActionSecondaryButton
            danger
            disabled={isDeleting}
            name="remove-user"
            icon="Trash2"
            onClick={() => {
              dispatch(deleteUsers(selectedUsers));
              setSelectedUsers(emptyArray);
            }}
          />
        )}

        <Modal
          title={t('USER')}
          show={!!show}
          onClose={handleClose}
          content={() => <UserForm initialValues={show} onClose={handleClose} />}
        />
      </Row>

      <ScrollableDataTableWithColSelector
        columnDefs={dataDef(t, onSelect, currentUserId)}
        getMethod={(params = {}) => dispatch(fetchUsers(params))}
        appendMethod={(params = {}) => dispatch(appendUsers(params))}
        rows={usersList}
        rowCount={usersCount}
        isFetching={isFetching}
        responseLength={responseLength}
        onSelectionChanged={(event) => {
          setSelectedUsers(event.api.getSelectedRows());
        }}
        isRowSelectable={(rowNode) => {
          return !pathEq(['data', 'role'], 'admin', rowNode) && !pathEq(['data', 'id'], currentUserId, rowNode);
        }}
      />
    </Block>
  );
};

UserList.propTypes = {
  t: func,
  isSaving: bool,
  hasError: bool,
  show: bool,
  usersList: array,
  usersCount: number,
  dispatch: func,
  isFetching: bool,
  userList: array,
  currentUserId: string,
  isDeleting: bool,
  responseLength: number
};

export default compose(
  translate('user'),
  connect((state) => ({
    usersList: pathOr(emptyArray, ['user', 'list'], state),
    isFetching: pathOr(false, ['user', 'isFetching'], state),
    responseLength: pathOr(0, ['user', 'responseLength'], state),
    usersCount: pathOr(0, ['user', 'count'], state),
    currentUserId: path(['user', 'data', 'id'], state),
    isDeleting: path(['user', 'isDeleting'], state)
  })),
  onlyForAdmin
)(UserList);
