import * as api from '@cohabs/bo-api';
import { defaultPaginationLimit } from '../utils';

export const getUserLeases = ({ page = 1, limit = defaultPaginationLimit, criteria, order = [] }) =>
  api.post(`/leases/${page}/${limit}`, { criteria, order });

export const getHelloSignLease = (params) => api.get(`/lease/hellosign/sign/${params.bookingId}/${params.token}`);

export const getLease = (id) => api.get(`/lease/${id}`);
export const deleteLease = (id) => api.del(`/lease/${id}`);
export const cancelLease = (id) => api.put(`/lease/${id}/cancel`);

export const getLeaseDocuments = (id) => api.get(`/lease/${id}/documents`);

export const getLeasesList = ({ page = 1, limit = defaultPaginationLimit, criteria = {}, order = [] }) => {
  if (page === 0) page = 1;
  return api.post(`/leases/${page}/${limit}`, { criteria, order });
};

export const updateLease = (lease) =>
  api.patch(`/lease/${lease.id}`, { preferredMoveoutDate: lease.preferredMoveoutDate, moveoutDate: lease.moveoutDate });

export const updateLateFees = (leaseId, hasLateFees) => api.patch(`/lease/${leaseId}`, { hasLateFees });
