import { propOr } from 'ramda';
import {
  STREAM_CHAT_AUTH,
  STREAM_CHAT_LOGOUT,
  GET_MEMBERS_FOR_CHAT,
  APPEND_MEMBERS_FOR_CHAT
} from './chat.actionTypes';
import { getStreamToken } from './chat.service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import streamChat from './stream';
import { handleErrorMessage } from '../toast/toast.actions';
import { types } from '../utils/sagas';
import * as memberService from '../members/member.service';
import { appendMembersFail, appendMembersSuccess, getMembersFail, getMembersSuccess } from './chat.actions';

export function* streamChatAuth({ user: { id, firstName, lastName, picture } = {} } = {}) {
  try {
    const { token } = yield call(getStreamToken);
    streamChat.connect(
      {
        id,
        name: `${firstName} ${lastName}`,
        image: picture
      },
      token
    );
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.CHAT.LOGIN, error: err }));
  }
}

export function* streamChatDisconnect() {
  try {
    streamChat.disconnect();
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.CHAT.LOGOUT, error: err }));
  }
}

export function* getMembers(action) {
  const payload = propOr({}, 'payload', action);
  try {
    const members = yield call(memberService.getMembers, {
      criteria: { ...payload },
      order: payload.order,
      page: payload.page,
      limit: payload.limit
    });
    yield put(getMembersSuccess(members));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.MEMBER, error: err, data: payload }));
    yield put(getMembersFail(err));
  }
}

export function* appendMembers(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const members = yield call(memberService.getMembers, {
      criteria: { ...payload },
      order: payload.order,
      page: payload.page,
      limit: payload.limit
    });
    yield put(appendMembersSuccess({ ...members, limit: payload.limit }));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.MEMBER, error: err, data: action.payload }));
    yield put(appendMembersFail(err));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(APPEND_MEMBERS_FOR_CHAT, appendMembers),
    takeLatest(GET_MEMBERS_FOR_CHAT, getMembers),
    takeLatest(STREAM_CHAT_AUTH, streamChatAuth),
    takeLatest(STREAM_CHAT_LOGOUT, streamChatDisconnect)
  ]);
}
