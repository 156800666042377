import { createFormAction } from 'redux-form-saga';
import {
  SET_ACCESS_RESTRICTED,
  GET_LEASES,
  GET_LEASES_SUCCESS,
  GET_LEASES_FAIL,
  APPEND_LEASES,
  APPEND_LEASES_SUCCESS,
  APPEND_LEASES_FAIL,
  UPDATE_LEASE,
  GET_LEASE_DOCUMENTS,
  GET_LEASE_DOCUMENTS_SUCCESS,
  GET_LEASE_DOCUMENTS_FAIL,
  GET_LEASE,
  GET_LEASE_SUCCESS,
  GET_LEASE_FAIL,
  GET_HELLO_SIGN_LEASE,
  GET_HELLO_SIGN_LEASE_SUCCESS,
  CANCEL_LEASE
} from './leases.actionTypes';
import i18n from '../translate/i18n';
import { toast } from '../utils';

export const setAccessRestricted = (params = {}) => ({
  type: SET_ACCESS_RESTRICTED,
  payload: params
});

export const getHelloSignLease = (params = {}) => ({
  type: GET_HELLO_SIGN_LEASE,
  payload: params
});

export const setHelloSignLease = (lease) => ({
  type: GET_HELLO_SIGN_LEASE_SUCCESS,
  payload: lease
});

export const getLeases = (params = {}) => ({
  type: GET_LEASES,
  payload: params
});

export const setLeases = (leases) => ({
  type: GET_LEASES_SUCCESS,
  payload: leases
});

export const getLeasesFail = (err) => {
  const error = i18n.getResource(i18n.language, 'leases', 'GET_LEASES_ERROR');
  toast.error(error);

  return {
    type: GET_LEASES_FAIL,
    payload: err
  };
};

export const getLease = (id) => ({
  type: GET_LEASE,
  payload: id
});

export const setLease = (lease) => ({
  type: GET_LEASE_SUCCESS,
  payload: lease
});

export const getLeaseFail = (err) => {
  const error = i18n.getResource(i18n.language, 'leases', 'GET_LEASE_ERROR');
  toast.error(error);

  return {
    type: GET_LEASE_FAIL,
    payload: err
  };
};

export const getLeaseDocuments = (leaseId) => ({
  type: GET_LEASE_DOCUMENTS,
  payload: leaseId
});

export const getLeaseDocumentsSuccess = (list) => ({
  type: GET_LEASE_DOCUMENTS_SUCCESS,
  payload: list
});

export const getLeaseDocumentsFail = (err) => {
  const error = i18n.getResource(i18n.language, 'applications', 'GET_LEASE_DOCUMENTS_ERROR');
  toast.error(error);

  return {
    type: GET_LEASE_DOCUMENTS_FAIL,
    payload: err
  };
};

export const appendLeases = (params = {}) => ({
  type: APPEND_LEASES,
  payload: params
});

export const appendLeasesSucess = (leases) => ({
  type: APPEND_LEASES_SUCCESS,
  payload: leases
});

export const appendLeasesFail = (err) => ({
  type: APPEND_LEASES_FAIL,
  payload: err
});

export const cancelLease = (leaseId) => ({
  type: CANCEL_LEASE,
  payload: leaseId
});

export const updateLease = createFormAction(UPDATE_LEASE);
