import { gt, lt } from 'ramda';
import moment from 'moment-timezone';

export const discountBounds =
  ({ incomingPrice = 1, minimumPrice = 1 } = {}) =>
  (value, { type = 'fixed' }) => {
    if (type === 'fixed') {
      return lt(+value, minimumPrice) || gt(value, incomingPrice - minimumPrice)
        ? 'INVALID_DISCOUNT_AMOUNT'
        : undefined;
    }

    if (type === 'percent') {
      const result = (+incomingPrice * (+value).toFixed(2)) / 100;

      return lt(result.toFixed(2), minimumPrice) || gt(result.toFixed(2), incomingPrice - minimumPrice)
        ? 'INVALID_DISCOUNT_AMOUNT'
        : undefined;
    }
  };

export const isDiscountExpired = (moveInDate, redeemByDate) =>
  moment.utc(moveInDate).isAfter(moment.utc(redeemByDate), 'day');

export const limitMonthCount = (val) => (val >= 12 ? 12 : val);

export const limit100Percent = (type) => (val) => {
  if (type && type === 'percent') {
    return val >= 100 ? 100 : val;
  }

  return val;
};
