import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Col, Row, Text } from '../components';
import Button from './Button';
import { ChevronLeft, ChevronRight } from 'react-feather';

import { shape, array, bool, func, object, number } from 'prop-types';
import { emptyArray, emptyObject } from '../utils';

const autoResizeColumn = (params) => params.api.sizeColumnsToFit();

/**
 * Table Component
 * @param {data} eg:
 * columnDefs: [
    { headerName: 'Make', field: 'make' },
    { headerName: 'Model', field: 'model' },
    { headerName: 'Price', field: 'price' }
  ],
  rowData: [
    { make: 'Toyota', model: 'Celica', price: 35000 },
    { make: 'Ford', model: 'Mondeo', price: 32000 },
    { make: 'Porsche', model: 'Boxter', price: 72000 }
  ]
* @param {enableFilter} bool
* @param {enableSorting} bool
* @returns Table rendered
};
 */
const Table = ({
  data,
  enableFilter,
  enableSorting,
  hasPagination,
  onRowSelected,
  onReady,
  onResize,
  previousPage,
  nextPage,
  enableColResize,
  rowHeight,
  onSelectionChanged,
  isRowSelectable,
  onRowClicked,
  onCellClicked,
  onSortChanged
}) => {
  return (
    <div className="ag-theme-cohabs">
      <AgGridReact
        onGridReady={onReady}
        onGridSizeChanged={onResize}
        enableFilter={enableFilter}
        sortable={enableSorting}
        resizable={enableColResize}
        columnDefs={data.columnDefs}
        defaultColDef={data.defaultColDef}
        rowData={data.rowData}
        rowHeight={rowHeight}
        pagination={hasPagination}
        paginationPageSize
        suppressPaginationPanel
        frameworkComponents={data.frameworkComponents}
        rowSelection="multiple"
        rowMultiSelectWithClick
        domLayout="autoHeight"
        onRowSelected={onRowSelected}
        onCellClicked={onCellClicked}
        onSelectionChanged={onSelectionChanged}
        suppressRowClickSelection={true}
        isRowSelectable={isRowSelectable}
        onRowClicked={onRowClicked}
        onSortChanged={onSortChanged}
        tooltipShowDelay={0}
        enableBrowserTooltips={true}
        suppressRowTransform={true}
      />

      {hasPagination && (
        <Row right middle>
          <Col lg={1} right>
            <Button name="skipBack" onClick={previousPage} clear>
              <ChevronLeft size={15} />
            </Button>
          </Col>
          <Col lg={1} left>
            <Text>{data.currentPage}</Text>
          </Col>
          <Col lg={1} left>
            <Button name="skipForward" onClick={nextPage} clear>
              <ChevronRight size={15} />
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};
Table.defaultProps = {
  enableFilter: false,
  enableSorting: true,
  enableColResize: true,
  rowHeight: 40,
  data: {
    defaultColDef: emptyObject,
    columnDefs: emptyArray,
    rowData: emptyArray
  },
  hasPagination: false,
  onReady: autoResizeColumn,
  onResize: autoResizeColumn,
  onRowSelected: () => null,
  isRowSelectable: undefined,
  onRowClicked: undefined
};

Table.propTypes = {
  enableFilter: bool,
  enableSorting: bool,
  enableColResize: bool,
  data: shape({
    defaultColDef: object,
    columnDefs: array,
    rowData: array
  }).isRequired,
  previousPage: func,
  nextPage: func,
  hasPagination: bool,
  onRowSelected: func,
  onReady: func,
  onResize: func,
  rowHeight: number,
  onSelectionChanged: func,
  isRowSelectable: func,
  onRowClicked: func,
  onSortChanged: func
};

export default Table;
