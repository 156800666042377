import { GET_HUBS, SET_HUBS, GET_HUB, SET_HUB } from './hub.actionTypes';

export const fetchHubs = () => ({
  type: GET_HUBS
});

export const setHubs = (payload) => ({
  type: SET_HUBS,
  payload
});

export const fetchHub = (hubId) => ({
  type: GET_HUB,
  payload: { hubId }
});

export const setHub = (payload) => ({
  type: SET_HUB,
  payload
});
