import {
  GET_PAYMENTS,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAILURE,
  APPEND_PAYMENTS,
  APPEND_PAYMENTS_FAILURE,
  APPEND_PAYMENTS_SUCCESS,
  SET_PAYMENT_MEMBER
} from './payments.actionTypes';
import { genState, setFetching, setList, setListFailure, appendList, appendListFailure } from '../utils/reducer';
import { upsertComment } from './payments.actions';

const payments = (state) => ({
  [SET_PAYMENT_MEMBER]: (payload) => ({
    ...state,
    member: payload
  }),
  [GET_PAYMENTS]: setFetching(state, true),
  [GET_PAYMENTS_SUCCESS]: setList(state),
  [GET_PAYMENTS_FAILURE]: setListFailure(state),
  [APPEND_PAYMENTS]: setFetching(state, true),
  [APPEND_PAYMENTS_SUCCESS]: appendList(state),
  [APPEND_PAYMENTS_FAILURE]: appendListFailure(state),
  [upsertComment.SUCCESS]: (payload) => {
    const { id, comment } = payload;
    const payments = state.list.map((p) => {
      if (p.payment && p.payment.id === id) {
        return { ...p, payment: { ...p.payment, comment } };
      }

      return { ...p };
    });

    return { ...state, list: payments };
  }
});

export const paymentsReducer = (state = {}, action) => {
  const { type, payload } = action;

  return genState({ state, payload, stateDef: payments, type });
};
