import styled from '@emotion/styled';

export const UploadContainer = styled('div')(({ theme, size, backgroundColor }) => ({
  height: `${size}px`,
  width: `${size}px`,
  marginRight: theme.spacing.half,
  marginBottom: theme.spacing.half,
  backgroundColor: backgroundColor ? backgroundColor : theme.colors.lightGrey[300],
  position: 'relative'
}));

export const DeleteContainer = styled('div')(() => ({
  position: 'absolute',
  right: '-14px',
  top: '-14px',
  zIndex: 2
}));

export const ImageContainer = styled('div')(({ theme, size, margin }) => ({
  height: `${size}px`,
  width: `${size}px`,
  overflow: 'hidden',
  display: 'block',
  padding: '1px',
  backgroundColor: 'white',
  margin: margin ? '0 10px 10px 0' : 'none',
  border: `1px solid ${theme.colors.lightGrey[300]}`,
  cursor: 'pointer'
}));
