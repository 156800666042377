import React, { useEffect, useState } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { compose, isEmpty } from 'ramda';

import { storableSelector } from '../storableDecorator';
import Selector from './Selector';
import { translate } from '../../translate/i18n';

const SelectorLeaseStatus = ({
  t,
  name,
  label,
  value,
  isInForm,
  isDisabled,
  isClearable,
  placeholder,
  isMulti,
  onChange,
  valueControl
}) => {
  const [options, setOptions] = useState();

  const handleChange = (val) => onChange(val === 'all' || !val ? null : val);

  useEffect(() => {
    const options = [
      {
        value: 'signed',
        label: t('SIGNED')
      },
      {
        value: 'void',
        label: t('VOID')
      },
      {
        value: 'active',
        label: t('ACTIVE')
      },
      {
        value: 'cancelled',
        label: t('CANCELLED')
      },
      {
        value: 'finished',
        label: t('FINISHED')
      }
    ];

    if (!isMulti) {
      options.unshift({
        value: 'all',
        label: t('ALL')
      });
    }

    setOptions(options);
  }, [isMulti]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, handleChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      isMulti={isMulti}
      name={name}
      options={options}
      label={label}
      value={value}
      onChange={handleChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorLeaseStatus.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  t: func
};

SelectorLeaseStatus.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null
};

export default compose(storableSelector, translate(['leases', 'default']))(SelectorLeaseStatus);
