import { getToken } from '../user/user.service';

export const tokenRequestInterceptor = (config) => {
  const token = getToken();

  if (token) {
    config.headers['Authorization'] = `Bearer ${token || ''}`;
  }

  return config;
};
