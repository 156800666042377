import * as api from '@cohabs/bo-api';
import { defaultPaginationLimit } from '../utils';

export const getMembers = ({ page = 1, limit = defaultPaginationLimit, criteria = { role: 'user' }, order }) =>
  api.post(`/members/${page}/${limit}`, { criteria, order });

export const getMember = (id) => api.get(`/user/${id}`);

export const updateMember = (member) => api.put(`/user/${member.id}`, member);

export const patchMember = (member) => api.patch(`/user/${member.id}`, member);

export const resetMemberPin = (member) => api.patch(`/user/${member.id}/resetPin`);
