import React, { useState, useEffect } from 'react';
import { func, string } from 'prop-types';
import hash from 'object-hash';
import { compose, values, all, reduce, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { capitalize, handleFilters } from '../utils';
import {
  Row,
  Col,
  SelectorFaqCategory,
  SearchTextInput,
  SelectorFaqLocation,
  SelectorFaqSubcategory,
  collapsible
} from '../components';

const FaqFilter = ({ t, onFilterSelect, storeName }) => {
  let [hashCode, setHashCode] = useState('');

  let [filters, setFilters] = useState({
    search: '',
    category: [],
    subcategoryId: [],
    locations: []
  });

  let [loaded, setLoaded] = useState({
    subcategoryId: false,
    search: false,
    category: false,
    locations: false
  });

  const handleChange = (filter) => (value) => {
    filters[filter] = value;
    setFilters({
      ...filters
    });
  };

  const onLoad =
    (filter) =>
    (val = true) => {
      if (loaded[filter] !== val) {
        loaded[filter] = val;
        setLoaded({
          ...loaded
        });
      }
    };

  useEffect(() => {
    if (all((item) => item)(values(loaded))) {
      const newHashCode = hash(filters);
      if (newHashCode !== hashCode) {
        hashCode = newHashCode;
        setHashCode(newHashCode);

        onFilterSelect(reduce((all, filter) => handleFilters(all, filter, filters[filter]), filters, keys(filters)));
      }
    }
  }, [filters, loaded]);

  return (
    <Row middle mb>
      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorFaqCategory
          name="category"
          label={capitalize(t('CATEGORY'))}
          placeholder={t('default:ALL')}
          isMulti
          value={filters.category}
          onChange={handleChange('category')}
          onLoad={onLoad('category')}
          storeName={storeName}
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorFaqSubcategory
          name="subcategoryId"
          label={capitalize(t('SUBCATEGORY'))}
          placeholder={t('default:ALL')}
          isMulti
          category={filters.category}
          value={filters.subcategoryId}
          onChange={handleChange('subcategoryId')}
          onLoad={onLoad('subcategoryId')}
          storeName={storeName}
          ready={loaded.category}
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorFaqLocation
          name="locations"
          label={capitalize(t('LOCATION'))}
          placeholder={t('default:ALL')}
          isMulti
          value={filters.locations}
          onChange={handleChange('locations')}
          onLoad={onLoad('locations')}
          storeName={storeName}
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SearchTextInput
          label={capitalize(t('default:SEARCH'))}
          name="search"
          placeholder={t('default:SEARCH')}
          value={filters.search}
          onChange={handleChange('search')}
          onLoad={onLoad('search')}
          storeName={storeName}
        />
      </Col>
    </Row>
  );
};

FaqFilter.propTypes = {
  onFilterSelect: func,
  storeName: string,
  t: func
};

FaqFilter.defaultProps = {
  storeName: 'Faq'
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_FILTER',
    labelShow: 'default:SHOW_FILTER'
  }),
  translate(['cms', 'default'])
)(FaqFilter);
