import React, { Fragment } from 'react';
import { func, any, string } from 'prop-types';
import { Text } from '.';
import { StyledLabel } from '../components/form/TextInput.styles';
import { valueOrPlaceholder, isNotEmptyOrNil } from '../utils';

const RenderElement = ({ label, value, formatter }) => (
  <Fragment>
    <StyledLabel>{label}</StyledLabel>
    <Text weight={300} size="md" grey>
      {valueOrPlaceholder(isNotEmptyOrNil, formatter)(value)}
    </Text>
  </Fragment>
);

RenderElement.propTypes = {
  formatter: (value) => value
};

RenderElement.propTypes = {
  label: string.isRequired,
  value: any,
  formatter: func
};

export default RenderElement;
