import React, { PureComponent, useState } from 'react';
import { pathOr, compose } from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getHouses, appendHouses, deleteHouses } from './houses.actions';
import { translate } from '../translate/i18n';
import { func, array, number, PropTypes, bool, object } from 'prop-types';
import { formatDate, formatAddress } from '../utils';
import Filter from './Houses.filter';
import {
  Block,
  Row,
  ScrollableDataTableWithColSelector,
  Text,
  ActionButton,
  ActionSecondaryButton
} from '../components';

class Houses extends PureComponent {
  static propTypes = {
    t: func,
    getHouses: func,
    appendHouses: func,
    isFetching: bool,
    houseList: array,
    dispatch: func,
    houseCount: number,
    openingDate: PropTypes.instanceOf(Date),
    isCreating: bool,
    isUpdating: bool,
    history: object,
    responseLength: number,
    deleteHouses: func
  };

  constructor(props) {
    super(props);

    this.state = { filters: null, selectedHouses: [] };
  }

  componentDidMount() {
    const { t } = this.props;

    this.columnDefs = [
      {
        label: t('default:CHECKBOX'),
        headerName: '',
        width: 25,
        valueFormatter: () => null,
        headerComponentFramework: ({ api, column }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

          return (
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                isChecked ? api.deselectAll() : api.selectAll();
                setIsChecked(!isChecked);
                api.refreshCells({ columns: [column], force: true });
              }}
            />
          );
        },
        onCellClicked: () => null,

        cellRendererFramework: ({ node, api }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isChecked, setIsChecked] = useState(node.isSelected());
          return (
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked(!node.isSelected());
                node.setSelected(!node.isSelected());
                api.refreshHeader();
              }}
            />
          );
        }
      },
      {
        headerName: t('LOCATION'),
        defaultActive: true,
        field: 'hub.name',
        width: 100
      },
      { headerName: t('NAME'), defaultActive: true, field: 'name', width: 140 },
      { headerName: t('NB_ROOMS'), field: 'roomNumber', width: 160, sortable: false },
      {
        headerName: t('ADDRESS'),
        defaultActive: true,
        field: 'address',
        width: 240,
        comparator: (valueA, valueB) => {
          const addrA = formatAddress(valueA);
          const addrB = formatAddress(valueB);

          if (addrA < addrB) {
            return -1;
          } else if (addrA > addrB) {
            return 1;
          } else {
            return 0;
          }
        },
        sortable: false,
        valueFormatter: (col) => formatAddress(col.value)
      },
      {
        headerName: t('OPENING_DATE'),
        field: 'openingDate',
        valueFormatter: (col) => formatDate(col.value, col.data.timezone),
        tooltip: ({ data }) => data.timezone,
        width: 160
      },
      { headerName: t('LANDLORD'), field: 'landlord.contactName', width: 160 }
    ];
  }

  goToHouse = (event) => {
    const { history } = this.props;
    const houseId = pathOr({}, ['data', 'id'], event);
    history.push(`/houses/${houseId}`);
  };

  deleteSelection = () => {
    const { deleteHouses } = this.props;
    const { selectedHouses } = this.state;
    deleteHouses(selectedHouses);
    this.setState({ selectedHouses: [] });
  };

  onRowSelected = (event) => {
    this.setState({ selectedHouses: event.api.getSelectedRows() });
  };

  handleClose() {
    this.setState({
      showCreationModal: false,
      houseName: '',
      isEditing: false,
      logoUrlSelected: '',
      coverUrlSelected: ''
    });
  }

  handleDateChange(date) {
    this.setState({ openingDate: date });
  }

  render() {
    const { t, houseList, houseCount, appendHouses, getHouses, isFetching, history, responseLength } = this.props;
    const { filters, selectedHouses } = this.state;

    return (
      <Block p>
        <Row middle mb>
          <Text weight={200} size="xl" pr>
            {t('HOUSES')}
          </Text>

          {!selectedHouses.length ? (
            <ActionButton icon="Plus" name="addHouse" onClick={() => history.push('/houses/create')} />
          ) : (
            <ActionSecondaryButton name="deleteHouse" icon="Trash2" onClick={this.deleteSelection} danger />
          )}
        </Row>

        <Filter onFilterSelect={(filters) => this.setState({ filters })} />

        <ScrollableDataTableWithColSelector
          filters={filters}
          ready={!!filters}
          columnDefs={this.columnDefs}
          defaultColDef={{ onCellClicked: this.goToHouse }}
          getMethod={getHouses}
          appendMethod={appendHouses}
          rows={houseList}
          rowCount={houseCount}
          isFetching={isFetching}
          responseLength={responseLength}
          onRowSelected={this.onRowSelected}
        />
      </Block>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      houseList: pathOr([], ['houses', 'list'], state),
      houseCount: pathOr(0, ['houses', 'count'], state),
      responseLength: pathOr(0, ['houses', 'responseLength'], state),
      isFetching: pathOr(false, ['houses', 'isFetching'], state)
    }),
    (dispatch) => bindActionCreators({ getHouses, appendHouses, deleteHouses }, dispatch)
  ),
  translate('house')
)(Houses);
