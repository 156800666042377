import {
  GET_INVENTORY,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAIL,
  GET_INVENTORIES,
  GET_INVENTORIES_SUCCESS,
  GET_INVENTORIES_FAIL,
  GET_LAST_INVENTORY,
  GET_LAST_INVENTORY_FAIL,
  APPEND_INVENTORIES,
  APPEND_INVENTORIES_FAIL,
  APPEND_INVENTORIES_SUCCESS,
  COMPARE_INVENTORIES,
  COMPARE_INVENTORIES_FAIL,
  COMPARE_INVENTORIES_SUCCESS,
  CLEAR_INVENTORY
} from './inventories.actionTypes';
import {
  genState,
  setFetching,
  setList,
  setListFailure,
  setFetchingCurrent,
  setCurrent,
  setCurrentFailure,
  appendList,
  appendListFailure
} from '../utils/reducer';
import { merge } from 'ramda';
import { upsertInventory } from './inventories.actions';

const inventories = (state) => ({
  [GET_INVENTORIES]: setFetching(state, true),
  [GET_INVENTORIES_SUCCESS]: setList(state),
  [GET_INVENTORIES_FAIL]: setListFailure(state),
  [GET_INVENTORY]: setFetchingCurrent(state, true),
  [GET_INVENTORY_SUCCESS]: setCurrent(state),
  [GET_INVENTORY_FAIL]: setCurrentFailure(state),
  [CLEAR_INVENTORY]: () => {
    return { ...state, current: { ...state.current, data: null } };
  },
  [GET_LAST_INVENTORY]: setFetchingCurrent(state, true),
  [GET_LAST_INVENTORY_FAIL]: setCurrentFailure(state),
  [APPEND_INVENTORIES]: setFetching(state, true),
  [APPEND_INVENTORIES_SUCCESS]: appendList(state),
  [APPEND_INVENTORIES_FAIL]: appendListFailure(state),
  [COMPARE_INVENTORIES]: () => {
    return { ...state, error: null, isFetching: true };
  },
  [COMPARE_INVENTORIES_SUCCESS]: (payload) => {
    return {
      ...state,
      isFetching: false,
      compare: payload
    };
  },
  [COMPARE_INVENTORIES_FAIL]: (error) => ({ ...state, error, compare: null, isFetching: false }),
  [upsertInventory.SUCCESS]: (payload) => {
    const inventory = merge(state.current, payload);

    return { ...state, isFetching: false, current: inventory };
  },
  [upsertInventory.FAILURE]: setFetching(state, false)
});

export const inventoriesReducer = (state = {}, action) => {
  const { type, payload } = action;

  return genState({ state, payload, stateDef: inventories, type });
};
