import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import LoginForm from './Login.form';
import { connect } from 'react-redux';
import { login } from '../user.actions';
import { func, string } from 'prop-types';
import { translate } from '../../translate/i18n';
import { Container, Card, Col, Row, Text } from '../../components';
import Logo from '../../assets/logo.jpg';
import { getApiUrl } from '../../store/apiUrl';

const Login = ({ t, login }) => {
  const endpoint = getApiUrl();
  const backEnd = process.env.REACT_APP_RELEASE ? (
    ''
  ) : (
    <div style={{ margin: 0 }}>
      BE <Text brown={500}>{endpoint}</Text>
    </div>
  );
  return (
    <Container mt-double>
      <Row center mt-double>
        <Col lg={4} md={6}>
          <Link to="/">
            <img style={{ width: '40px', borderRadius: '10px' }} src={Logo} alt="logo" />
          </Link>
          <Card title={t('Back Office')} textCenter>
            {backEnd}
            <LoginForm onSubmit={(user) => login(user)} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Login.propTypes = {
  t: func,
  endpoint: string,
  login: func
};

export default compose(
  connect(() => ({}), { login }),
  translate('auth')
)(Login);
