import React, { useState } from 'react';
import { string, object, number, bool, array, func } from 'prop-types';
import { path, prop } from 'ramda';
import { checkFileType } from '../../utils';
import { StyledLabel, InputContainer } from './TextInput.styles';
import { SubmitError } from './Upload';
import { translate } from '../../translate/i18n';

const FileUpload = (props) => {
  // Props
  const { t, placeholder, label, input, accept, isDisabled, meta } = props;
  const { touched, error } = meta;

  // State
  const [typeError, setTypeError] = useState(null);

  // Functions
  const handleChange = (event) => {
    const onChange = prop('onChange', input);
    const tmpFile = path(['target', 'files', 0], event);
    const typeError = checkFileType(tmpFile, accept);

    if (!typeError) {
      onChange(tmpFile);
      setTypeError(null);
    } else {
      setTypeError(typeError);
    }
  };

  // Render
  return (
    <InputContainer>
      <StyledLabel>{label}</StyledLabel>
      <input
        multiple={false}
        placeholder={placeholder}
        accept={accept.join(',')}
        onChange={handleChange}
        type="file"
        disabled={isDisabled}
      />

      <SubmitError error={error} touched={touched} t={t} />
      <SubmitError error={typeError} touched t={t} />
    </InputContainer>
  );
};

FileUpload.defaultProps = {
  isDisabled: false
};

FileUpload.propTypes = {
  t: func,
  label: string,
  input: object,
  meta: object,
  size: number,
  accept: array.isRequired,
  isDisabled: bool,
  placeholder: string
};

export default translate('default')(FileUpload);
