import { createFormAction } from 'redux-form-saga';
import {
  GET_PACKS,
  GET_PACKS_SUCCESS,
  GET_PACKS_FAIL,
  APPEND_PACKS,
  APPEND_PACKS_SUCCESS,
  APPEND_PACKS_FAIL,
  GET_PACK,
  GET_PACK_FAIL,
  GET_PACK_SUCCESS,
  UPSERT_PACK,
  DUPLICATE_PACK,
  DUPLICATE_PACK_SUCCESS,
  DUPLICATE_PACK_FAIL
} from './packs.actionTypes';
import i18n from '../translate/i18n';
import { toast } from '../utils';

export const getPacks = (params = {}) => ({
  type: GET_PACKS,
  payload: params
});

export const getPacksSuccess = (packs) => ({
  type: GET_PACKS_SUCCESS,
  payload: packs
});

export const getPacksFail = (err) => {
  const error = i18n.getResource(i18n.language, 'packs', 'GET_PACKS_ERROR');
  toast.error(error);

  return {
    type: GET_PACKS_FAIL,
    payload: err
  };
};

export const appendPacks = (params = {}) => ({
  type: APPEND_PACKS,
  payload: params
});

export const appendPacksSuccess = (packs) => ({
  type: APPEND_PACKS_SUCCESS,
  payload: packs
});

export const appendPacksFail = (err) => ({
  type: APPEND_PACKS_FAIL,
  payload: err
});

export const getPack = (id) => ({
  type: GET_PACK,
  payload: id
});

export const getPackSuccess = (pack) => ({
  type: GET_PACK_SUCCESS,
  payload: pack
});

export const getPackFail = (err) => {
  const error = i18n.getResource(i18n.language, 'packs', 'GET_PACK_ERROR');
  toast.error(error);

  return {
    type: GET_PACK_FAIL,
    payload: err
  };
};

export const upsertPack = createFormAction(UPSERT_PACK);

export const duplicatePack = (pack) => ({
  type: DUPLICATE_PACK,
  payload: pack
});

export const duplicatePackSuccess = (pack) => ({
  type: DUPLICATE_PACK_SUCCESS,
  payload: pack
});

export const duplicatePackFail = (err) => ({
  type: DUPLICATE_PACK_FAIL,
  payload: err
});
