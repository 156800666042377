import React from 'react';
import { number, any, bool } from 'prop-types';
import { Loader as LoaderIcon } from 'react-feather';
import Styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const spin = keyframes`
  from { 
    transform: rotate(0deg); 
  } to { 
    transform: rotate(360deg); 
  }
`;

const LoaderContainer = Styled('div')(({ theme, size }) => ({
  height: `${size}px`,
  width: `${size}px`,
  animation: `${spin} 2s linear infinite`,
  color: theme.colors.grey[300]
}));

const Loader = ({ size, children, when }) => {
  if (!children)
    return (
      <LoaderContainer size={size}>
        <LoaderIcon size={size} />
      </LoaderContainer>
    );
  if (when)
    return (
      <LoaderContainer size={size}>
        <LoaderIcon size={size} />
      </LoaderContainer>
    );

  return children;
};

Loader.defaultProps = {
  size: 28
};

Loader.propTypes = {
  size: number,
  children: any,
  when: bool
};

export default Loader;
