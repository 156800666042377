import { omit } from 'ramda';
import { days } from '../utils';
import { hubInUtcFormatToLocalDate } from '../utils/date';

export const initValuesWithRecurrences = (object, house) => {
  const initialValues = { ...object, houseId: house.id, timezone: house.timezone, recurrences: days.map(() => null) };
  if (Array.isArray(object.recurrences)) {
    for (let rec of object.recurrences) {
      if (rec === null) {
        continue;
      }
      if (typeof rec.firstDate === 'string' || rec.firstDate instanceof String) {
        rec.firstDate = hubInUtcFormatToLocalDate(rec.firstDate, house.timezone);
      }
      initialValues.recurrences[rec.firstDate.getDay()] = omit(['id'], rec);
    }
  }

  return initialValues;
};
