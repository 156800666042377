import React from 'react';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';
import SelectorFeedsCategory from '../selector/Selector.FeedsCategories';

const SelectorFeedsCategoryForm = ({ t, input, label, isDisabled, placeholder, meta: { touched, error } }) => (
  <InputContainer>
    <SelectorFeedsCategory label={label} placeholder={placeholder} {...input} isInForm isDisabled={isDisabled} />

    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectorFeedsCategoryForm);
