import 'react-toastify/dist/ReactToastify.css';
import { toast as toastify } from 'react-toastify';
import { theme } from '../theme';
import { css } from '@emotion/css';

export const toast = {
  success: (message, options = {}) => {
    toastify.success(message, {
      ...options,
      className: css({
        fontFamily: theme.font.family,
        fontSize: theme.font.size.md,
        backgroundColor: theme.colors.green[500]
      })
    });
  },
  error: (message, options = {}) => {
    toastify.success(message, {
      ...options,
      className: css({
        fontFamily: theme.font.family,
        fontSize: theme.font.size.md,
        backgroundColor: theme.colors.red[500]
      })
    });
  },
  info: (message, options = {}) => {
    toastify.success(message, {
      ...options,
      className: css({
        fontFamily: theme.font.family,
        fontSize: theme.font.size.md,
        backgroundColor: theme.colors.blue[500]
      })
    });
  }
};
