import React from 'react';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';
import SelectorFaqSubcategory from '../selector/Selector.faqsubcategory';

const SelectorFaqSubcategoryForm = ({
  t,
  input,
  label,
  isDisabled,
  placeholder,
  category,
  meta: { touched, error }
}) => (
  <InputContainer>
    <SelectorFaqSubcategory
      category={category}
      label={label}
      placeholder={placeholder}
      {...input}
      isInForm
      isDisabled={isDisabled}
    />

    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectorFaqSubcategoryForm);
