import { createFormAction } from 'redux-form-saga';
import {
  UPSERT_DISCOUNT_PROMISE,
  DELETE_DISCOUNT_PROMISE,
  DELETE_DISCOUNT_PROMISE_SUCCESS,
  DELETE_DISCOUNT_PROMISE_FAIL
} from './discountPromise.actionTypes';

export const deleteDiscountPromise = ({ id, discountablePromiseId, discountablePromiseType } = {}) => ({
  type: DELETE_DISCOUNT_PROMISE,
  payload: {
    id,
    discountablePromiseId,
    discountablePromiseType
  }
});

export const deleteDiscountPromiseSuccess = (payload) => ({
  type: DELETE_DISCOUNT_PROMISE_SUCCESS,
  payload
});

export const deleteDiscountPromiseFail = (err) => ({
  type: DELETE_DISCOUNT_PROMISE_FAIL,
  payload: err
});

export const upsertDiscountPromise = createFormAction(UPSERT_DISCOUNT_PROMISE);
