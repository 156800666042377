import React, { Fragment, useEffect, useState } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose, path, pathOr, prop, propOr, differenceWith, innerJoin, sortBy, keys, values } from 'ramda';
import { withRouter } from 'react-router-dom';
import { translate } from '../translate/i18n';
import { Container, Block, Row, Text, Loader, Col, RenderElement, Thumbnail, Modal, Toggle } from '../components';
import { emptyObject, isNotEmptyOrNil, upperCase, formatFullname } from '../utils';
import { compareInventories } from './inventories.actions';
import Header from './Inventory.header';
import { ImageContainer, StyledImage } from './Inventory.style';

const diff = (a, b) => a.type === b.type;
const makeThumbnails = (onPictureClick) => (doc) => (
  <Thumbnail key={prop('id', doc)} link={prop('link', doc)} id={prop('id', doc)} onClick={onPictureClick} />
);

const Detail = translate('inventories')(({ t, onPictureClick, current, previous }) => {
  const currentPictures = propOr([], 'inventoryDetailDocuments', current).map(makeThumbnails(onPictureClick));
  const previousPictures = propOr([], 'inventoryDetailDocuments', previous).map(makeThumbnails(onPictureClick));

  return (
    <Row bt-lightGrey>
      <Col xl={4} lg={4} md={12} sm={12} p>
        {current ? (
          <Text weight={400}>{t(`DEFECTS.${upperCase(current.type)}`)}</Text>
        ) : (
          <Text weight={400}>{t(`DEFECTS.${upperCase(previous.type)}`)}</Text>
        )}
      </Col>

      <Col xl={4} lg={4} md={6} sm={6} pt pb-half pl pr bg-primary>
        <Row mb-half>
          <Text>{`${currentPictures.length} ${t('PICTURES_UPLOADED')}`}</Text>
        </Row>
        {current && <Row>{currentPictures}</Row>}
      </Col>

      <Col xl={4} lg={4} md={6} sm={6} pt pb-half pl pr>
        <Row mb-half>
          <Text>{`${previousPictures.length} ${t('PICTURES_UPLOADED')}`}</Text>
        </Row>
        {previous && <Row>{previousPictures}</Row>}
      </Col>
    </Row>
  );
});

Detail.propTypes = {
  t: func,
  current: object,
  previous: object
};

const Details = translate('inventories')(({ t, current, previous }) => {
  const [picture, selectPicture] = useState(null);

  const currentDetails = propOr([], 'inventoryDetails', current);
  const previousDetails = propOr([], 'inventoryDetails', previous);

  const detailsNotInCurrent = differenceWith(diff, previousDetails, currentDetails).map((detail) => ({
    type: prop('type', detail),
    current: null,
    previous: detail
  }));

  const detailsNotInPrevious = differenceWith(diff, currentDetails, previousDetails).map((detail) => ({
    type: prop('type', detail),
    current: detail,
    previous: null
  }));

  const commonDetails = innerJoin(diff, currentDetails, previousDetails).map((currentDetail) => ({
    type: prop('type', currentDetail),
    current: currentDetail,
    previous: previousDetails.find((d) => d.type === currentDetail.type)
  }));

  const mergedDetails = sortBy(prop('type'), commonDetails.concat(detailsNotInCurrent, detailsNotInPrevious));

  if (!mergedDetails.length) {
    return null;
  }

  return (
    <Fragment>
      <Row center bt-grey>
        <Text size="xl" weight={200}>
          {t('INVENTORY_DETAILS')}
        </Text>
      </Row>
      {mergedDetails.map((detail) => (
        <Detail
          key={detail.type}
          onPictureClick={(fileUrl) => {
            selectPicture(fileUrl);
          }}
          current={detail.current}
          previous={detail.previous}
        />
      ))}

      <Modal
        title={t('PICTURE')}
        isPicturePreview
        show={!!picture}
        onClose={() => selectPicture(null)}
        content={() => (
          <ImageContainer>
            <StyledImage src={picture} alt="picture" />
          </ImageContainer>
        )}
      />
    </Fragment>
  );
});

const Checklists = translate('inventories')(({ t, current, previous }) => {
  const toArray = (item) => ({
    type: keys(item)[0],
    checked: values(item)[0]
  });

  const currentChecklist = propOr([], 'checklist', current).map(toArray);
  const previousChecklist = propOr([], 'checklist', previous).map(toArray);

  const checklistsNotInCurrent = differenceWith(diff, previousChecklist, currentChecklist).map((checklist) => ({
    type: prop('type', checklist),
    current: null,
    previous: checklist
  }));

  const checklistsNotInPrevious = differenceWith(diff, currentChecklist, previousChecklist).map((checklist) => ({
    type: prop('type', checklist),
    current: checklist,
    previous: null
  }));

  const commonChecklists = innerJoin(diff, currentChecklist, previousChecklist).map((currentChecklist) => ({
    type: prop('type', currentChecklist),
    current: currentChecklist,
    previous: previousChecklist.find((d) => d.type === currentChecklist.type)
  }));

  const mergedChecklists = sortBy(
    prop('type'),
    commonChecklists.concat(checklistsNotInCurrent, checklistsNotInPrevious)
  );

  if (!mergedChecklists.length) {
    return null;
  }

  return (
    <Fragment>
      <Row center bt-grey>
        <Text size="xl" weight={200}>
          {t('FORM.CHECKLIST_SECTION_TITLE')}
        </Text>
      </Row>
      {mergedChecklists.map(({ type, current, previous }) => (
        <Row bt-lightGrey key={type}>
          <Col xl={4} lg={12} md={12} sm={12} p>
            {current ? (
              <Text weight={400}>{t(upperCase(`CHECKLIST.${current.type}`))}</Text>
            ) : (
              <Text weight={400}>{t(upperCase(`CHECKLIST.${previous.type}`))}</Text>
            )}
          </Col>

          <Col xl={0} lg={4} md={0} sm={0} />

          <Col xl={4} lg={4} md={6} sm={6} pt pl pr bg-primary p>
            {current && (
              <Row>
                <Toggle
                  disabled
                  input={{
                    checked: current.checked
                  }}
                />
              </Row>
            )}
          </Col>

          <Col xl={4} lg={4} md={6} sm={6} pt pl pr p>
            {previous && (
              <Row>
                <Row>
                  <Toggle
                    disabled
                    input={{
                      checked: previous.checked
                    }}
                  />
                </Row>
              </Row>
            )}
          </Col>
        </Row>
      ))}
    </Fragment>
  );
});

const CompareInventories = (props) => {
  const { match, t, error, current, previous, isFetching, compareInventories } = props;

  useEffect(() => {
    if (isNotEmptyOrNil(id) && !isFetching) {
      compareInventories(id);
    }
  }, []);

  if (isFetching) {
    return (
      <Container full center middle>
        <Loader />
      </Container>
    );
  }

  if (isNotEmptyOrNil(error)) {
    return <Redirect to={'/inventories'} />;
  }

  const id = path(['params', 'id'], match);

  return (
    <Fragment>
      <Block pt pl pr>
        <Header showCompare={false} label={'BACK_TO_INVENTORY'} link={`/inventories/${id}`} />

        <Row mb-double>
          <Text size="xl" weight={200}>
            {t('COMPARE_HISTORY')}
          </Text>
        </Row>

        <Row>
          <Col xl={4} lg={4} md={12} sm={12} />

          <Col xl={4} lg={4} md={6} sm={6} p bg-primary>
            <Text mb-half>{t('CURRENT')}</Text>
            <RenderElement label={t('MEMBER')} value={path(['lease', 'user'], current)} formatter={formatFullname} />
          </Col>

          <Col xl={4} lg={4} md={6} sm={6} p>
            <Text mb-half>{t('PREVIOUS')}</Text>
            <RenderElement label={t('MEMBER')} value={path(['lease', 'user'], previous)} formatter={formatFullname} />
          </Col>
        </Row>

        <Details current={current} previous={previous} />
        <Checklists current={current} previous={previous} />
      </Block>
    </Fragment>
  );
};

CompareInventories.propTypes = {
  t: func,
  match: object,
  user: object,
  current: object,
  previous: object,
  isFetching: bool,
  compareInventories: func,
  error: object
};

export default compose(
  withRouter,
  translate('inventories'),
  connect(
    (state) => ({
      error: pathOr(null, ['inventories', 'error'], state),
      isFetching: pathOr(false, ['inventories', 'isFetching'], state),
      current: pathOr(emptyObject, ['inventories', 'compare', 'current'], state),
      previous: pathOr(emptyObject, ['inventories', 'compare', 'previous'], state),
      user: pathOr(null, ['user', 'data'], state)
    }),
    { compareInventories }
  )
)(CompareInventories);
