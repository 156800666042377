import { TOGGLE_COLLAPSE_MENU } from './actionTypes';
import browserStore from '../utils/browserStore';

export const toggleCollapseMenu = (payload = true) => {
  browserStore.setItem('const', 'menu_collapse', payload);

  return {
    type: TOGGLE_COLLAPSE_MENU,
    payload
  };
};
