import React, { useState, useEffect } from 'react';
import Selector from './Selector';
import { translate } from '../../translate/i18n';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { compose, pathOr, map } from 'ramda';
import { emptyArray, makeOptions, upperCase } from '../../utils';
import { getApplicationSwitchTypes } from '../../enum/enum.actions';

const SelectorSwitchType = (props) => {
  const {
    t,
    name,
    list,
    getApplicationSwitchTypes,
    isMulti,
    label,
    value,
    isInForm,
    isDisabled,
    isClearable,
    placeholder,
    onChange
  } = props;

  const [options, setOptions] = useState();

  useEffect(() => {
    const enums = map((option) => ({ value: option, name: t(`SWITCH_TYPE.${upperCase(option)}`) }), list);
    enums.push({ value: null, name: t('SWITCH_TYPE.NOTSWITCH') });
    setOptions(makeOptions(enums, 'value', 'name', 'label'));
  }, [list]);

  useEffect(() => {
    getApplicationSwitchTypes();
  }, []);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorSwitchType.propTypes = {
  ready: bool,
  isInForm: bool,
  isFetching: bool,
  isClearable: bool,
  t: func,
  name: string.isRequired,
  list: array,
  getApplicationSwitchTypes: func,
  onChange: func,
  onLoad: func,
  isMulti: bool,
  autoSelect: bool,
  label: string,
  value: oneOfType([string, array]),
  storeType: string,
  storeName: string,
  isDisabled: bool,
  placeholder: string,
  updateApplicant: func
};

SelectorSwitchType.defaultProps = {
  onLoad: () => {},
  storeType: 'const',
  storeName: null,
  ready: true,
  isClearable: false,
  autoSelect: false,
  isInForm: false
};

export default compose(
  translate('applications'),
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'applicationSwitchTypes', 'list'], state),
      isFetching: pathOr(emptyArray, ['enums', 'applicationSwitchTypes', 'isFetching'], state)
    }),
    { getApplicationSwitchTypes }
  )
)(SelectorSwitchType);
