import React, { useState } from 'react';
import { func, number } from 'prop-types';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { Block, Row, Col, Text, Switch, sizing } from '../components';
import ApplicationKanban from './Application.kanban';
import ApplicationTable from './Application.table';

const Applications = ({ t, mobileView }) => {
  const [isKanban, setKanban] = useState(true);

  return (
    <Block p>
      <Row middle mb>
        <Col xl={2} lg={3} md={4} sm={12}>
          <Text size="xl" weight={200}>
            {t('APPLICATIONS')}
          </Text>
        </Col>
        {!mobileView && (
          <Col xl={2} lg={3} md={4}>
            <Switch
              items={[
                {
                  name: 'applicationList',
                  onClick: () => setKanban(false),
                  label: t('LIST'),
                  active: !isKanban
                },
                {
                  name: 'kanbanList',
                  onClick: () => setKanban(true),
                  label: t('KANBAN'),
                  active: isKanban
                }
              ]}
            />
          </Col>
        )}
      </Row>

      {isKanban && !mobileView ? <ApplicationKanban /> : <ApplicationTable />}
    </Block>
  );
};

Applications.propTypes = {
  t: func,
  windowWidth: number
};

export default compose(sizing(), translate(['applications', 'default']))(Applications);
