import React from 'react';
import SelectorCurrency from '../selector/Selector.currencies';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';

const SelectCurrencyForm = ({
  t,
  input,
  label,
  disabled,
  isDisabled,
  meta: { touched, error },
  paymentGatewayId,
  notEmpty
}) => (
  <InputContainer>
    <SelectorCurrency
      label={label}
      {...input}
      isInForm={true}
      isDisabled={isDisabled || disabled}
      paymentGatewayId={paymentGatewayId}
      notEmpty={notEmpty}
    />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectCurrencyForm);
