import * as api from '@cohabs/bo-api';
import { defaultPaginationLimit } from '../utils';

// Payments
export const getPaymentsByCategory =
  (category) =>
  ({ leaseId, page = 1, limit = defaultPaginationLimit, criteria = {}, order = [['createdAt', 'DESC']] }) => {
    if (page < 1) page = 1;
    return api.post(`/${category}/${page}/${limit}`, { criteria, order, leaseId });
  };

export const getPayments = ({
  page = 1,
  limit = defaultPaginationLimit,
  criteria = {},
  order = [['createdAt', 'DESC']]
}) => {
  if (page < 1) page = 1;
  return api.post(`/payments/${page}/${limit}`, { criteria, order });
};

export const getRents = getPaymentsByCategory('rents');
export const getApplicationFee = getPaymentsByCategory('application_fee');
export const getPrebookingFee = getPaymentsByCategory('prebooking_fee');
export const getPacks = getPaymentsByCategory('packPayments');
export const getDeposit = getPaymentsByCategory('damages');

export const upsertDamage = (damage) => api.post('/damage', damage);
export const updateDamage = (damage, id) => api.patch(`/damage/${id}`, damage);
export const upsertComment = ({ comment, id }) => api.patch(`/payment/${id}/comment`, { comment });
export const refund = (depositId) => api.post(`/deposit/${depositId}/refund`);
export const remindPayment = (paymentId) => api.post(`/reminder/payment/${paymentId}`);
export const remindUser = (leaseId) => api.post(`/reminder/user/${leaseId}`);
export const markAsPaid = (paymentId) => api.patch(`/payment/${paymentId}/paid`);
