import { propOr, pathOr, prop, differenceWith, findIndex, propEq } from 'ramda';
import {
  GET_HOUSE_AREAS,
  SET_HOUSE_AREAS,
  ADD_HOUSE_AREAS_SUCCESS,
  UPDATE_HOUSE_AREAS_SUCCESS,
  REMOVE_HOUSE_AREAS_SUCCESS,
  DELETE_HOUSE_LOCK_SUCCESS,
  initialHouses,
  GET_HOUSES,
  GET_HOUSES_SUCCESS,
  HOUSE_CREATION,
  HOUSE_CREATION_SUCCESS,
  HOUSE_UPDATE,
  HOUSE_UPDATE_SUCCESS,
  APPEND_HOUSES,
  APPEND_HOUSES_SUCCESS,
  APPEND_HOUSES_FAIL,
  GET_HOUSE_TRASHES,
  GET_HOUSE_TRASHES_FAIL,
  GET_HOUSE_TRASHES_SUCCESS,
  DELETE_TRASH_SUCCESS,
  DELETE_HOUSES_SUCCESS,
  GET_HOUSE_SERVICES_SUCCESS,
  GET_HOUSE_SERVICES_FAILURE,
  GET_HOUSE_SERVICES,
  DELETE_SERVICES_SUCCESS
} from './houses.actionTypes';
import {
  FETCH_HOUSE_DETAILS_SUCCESS,
  FETCH_HOUSE_DETAILS_FAILURE,
  FETCH_HOUSE_DETAILS,
  upsertCategory,
  upsertHouseLock,
  DELETE_CATEGORIES_SUCCESS,
  upsertTrash,
  upsertService
} from './houses.actions';
import {
  genState,
  setFetching,
  setList,
  setFetchingCurrent,
  setCurrent,
  setCurrentFailure,
  appendList,
  appendListFailure,
  excludeList
} from '../utils/reducer';
import { emptyArray, emptyObject, upsertListWithId } from '../utils';

const houses = (state) => ({
  [HOUSE_CREATION]: () => ({ ...state, isCreating: true }),
  [GET_HOUSE_AREAS]: (payload) => ({ ...state, areas: setFetching(state.areas, true)(payload) }),
  [SET_HOUSE_AREAS]: (payload) => ({ ...state, areas: setList(state.areas, true)(payload) }),
  [ADD_HOUSE_AREAS_SUCCESS]: (payload) => ({
    ...state,
    areas: {
      ...state.areas,
      list: [...state.areas.list, payload]
    }
  }),
  [REMOVE_HOUSE_AREAS_SUCCESS]: (payload) => ({
    ...state,
    areas: {
      ...state.areas,
      list: state.areas.list.filter((item) => item.id !== payload.id)
    }
  }),
  [UPDATE_HOUSE_AREAS_SUCCESS]: (payload) => ({
    ...state,
    areas: {
      ...state.areas,
      list: state.areas.list.map((item) => (item.id !== payload.id ? item : payload))
    }
  }),
  [GET_HOUSES]: setFetching(state, true),
  [GET_HOUSES_SUCCESS]: setList(state),
  [HOUSE_CREATION_SUCCESS]: () => ({ ...state, isCreating: false }),
  [HOUSE_UPDATE]: () => ({ ...state, isUpdating: true }),
  [HOUSE_UPDATE_SUCCESS]: (payload) => {
    const list = state.list.map((houseItem) => {
      if (houseItem.id === payload.id) return payload;
      return houseItem;
    });
    return { ...state, list, isUpdating: false };
  },
  [FETCH_HOUSE_DETAILS]: setFetchingCurrent(state, true),
  [FETCH_HOUSE_DETAILS_SUCCESS]: setCurrent(state),
  [FETCH_HOUSE_DETAILS_FAILURE]: setCurrentFailure(state),
  [DELETE_HOUSE_LOCK_SUCCESS]: ({ id } = {}) => {
    const locks = pathOr([], ['current', 'data', 'locks'], state);
    return {
      ...state,
      current: {
        ...propOr(emptyObject, 'current', state),
        data: {
          ...pathOr(emptyObject, ['current', 'data'], state),
          locks: locks.filter((item) => item.id !== id)
        }
      }
    };
  },
  [upsertHouseLock.SUCCESS]: (payload) => {
    if (pathOr(null, ['current', 'data', 'id'], state) !== payload.houseId) {
      return state;
    }

    const locks = [...pathOr([], ['current', 'data', 'locks'], state)];

    const index = findIndex(propEq('id', payload.lock.id), locks);

    if (~index) {
      locks[index] = payload.lock;
    } else {
      locks.push(payload.lock);
    }

    return {
      ...state,
      current: {
        ...propOr(emptyObject, 'current', state),
        data: {
          ...pathOr(emptyObject, ['current', 'data'], state),
          locks
        }
      }
    };
  },
  [upsertCategory.SUCCESS]: (category) => {
    const categories = pathOr(emptyArray, ['current', 'data', 'practicalInformations', prop('type', category)], state);

    const list = upsertListWithId(categories, category);

    return {
      ...state,
      current: {
        ...propOr(emptyObject, 'current', state),
        data: {
          ...pathOr(emptyObject, ['current', 'data'], state),
          practicalInformations: {
            ...pathOr(emptyObject, ['current', 'data', 'practicalInformations'], state),
            [prop('type', category)]: list
          }
        }
      }
    };
  },
  [DELETE_CATEGORIES_SUCCESS]: ({ section, list }) => ({
    ...state,
    current: {
      ...propOr(emptyObject, 'current', state),
      data: {
        ...pathOr(emptyObject, ['current', 'data'], state),
        practicalInformations: {
          ...pathOr(emptyObject, ['current', 'data', 'practicalInformations'], state),
          [section]: pathOr(emptyArray, ['current', 'data', 'practicalInformations', section], state).filter(
            (category) => !list.includes(category)
          )
        }
      }
    }
  }),
  [APPEND_HOUSES]: setFetching(state, true),
  [APPEND_HOUSES_SUCCESS]: appendList(state),
  [APPEND_HOUSES_FAIL]: appendListFailure(state),
  [GET_HOUSE_TRASHES]: () => {
    return { ...state, current: { ...state.current, isFetching: true } };
  },
  [GET_HOUSE_TRASHES_SUCCESS]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      isFetching: false,
      data: {
        ...state.current.data,
        trashes: prop('trashes', payload)
      }
    }
  }),
  [GET_HOUSE_TRASHES_FAIL]: () => ({ ...state, current: { ...state.current, isFetching: false } }),
  [upsertTrash.SUCCESS]: (trash) => {
    const trashes = pathOr(emptyArray, ['current', 'data', 'trashes'], state);

    return {
      ...state,
      current: {
        ...propOr(emptyObject, 'current', state),
        data: {
          ...pathOr(emptyObject, ['current', 'data'], state),
          trashes: upsertListWithId(trashes, trash)
        }
      }
    };
  },
  [DELETE_TRASH_SUCCESS]: (trashes) => ({
    ...state,
    current: {
      ...propOr(emptyObject, 'current', state),
      data: {
        ...pathOr(emptyObject, ['current', 'data'], state),
        trashes: pathOr(emptyArray, ['current', 'data', 'trashes'], state).filter((trash) => !trashes.includes(trash))
      }
    }
  }),
  [DELETE_HOUSES_SUCCESS]: excludeList(state),
  [GET_HOUSE_SERVICES]: setFetchingCurrent(state, true),
  [GET_HOUSE_SERVICES_FAILURE]: setFetchingCurrent(state, false),
  [GET_HOUSE_SERVICES_SUCCESS]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      isFetching: false,
      data: {
        ...state.current.data,
        services: payload
      }
    }
  }),
  [DELETE_SERVICES_SUCCESS]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      data: {
        ...state.current.data,
        services: differenceWith(
          (a, b) => prop('id', a) === prop('id', b),
          pathOr(emptyArray, ['current', 'data', 'services'], state),
          payload
        )
      }
    }
  }),
  [upsertService.SUCCESS]: (service) => {
    const services = pathOr(emptyArray, ['current', 'data', 'services'], state);

    return {
      ...state,
      current: {
        ...state.current,
        data: {
          ...state.current.data,
          services: upsertListWithId(services, service)
        }
      }
    };
  }
});

export const housesReducer = (state = initialHouses, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: houses, type });
};
