import { takeLatest, call, all, put } from 'redux-saga/effects';
import { propOr, prop, omit, isEmpty } from 'ramda';
import * as service from './inventories.service';
import {
  GET_INVENTORIES,
  GET_INVENTORY,
  APPEND_INVENTORIES,
  COMPARE_INVENTORIES,
  GET_LAST_INVENTORY
} from './inventories.actionTypes';
import {
  setInventories,
  setInventory,
  getInventoryFail,
  getInventoriesFail,
  getLastInventoryFail,
  appendInventoriesFail,
  appendInventoriesSucess,
  upsertInventory,
  createHouseIssue,
  compareInventoriesFail,
  compareInventoriesSucess
} from './inventories.actions';
import { types, defActionType } from '../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';

export function* getInventories(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const inventories = yield call(service.getInventories, {
      criteria: payload.criteria ? payload.criteria : omit(['order', 'page', 'limit'], payload),
      order: prop('order', payload),
      page: prop('page', payload),
      limit: prop('limit', payload)
    });
    yield put(setInventories(inventories));
  } catch (err) {
    yield put(getInventoriesFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.INVENTORY, error: err, data: action.payload }));
  }
}

export function* getInventory(action) {
  try {
    const inventory = yield call(service.getInventory, prop('payload', action));
    yield put(setInventory(inventory));
  } catch (err) {
    yield put(getInventoryFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.INVENTORY, error: err, data: action.payload }));
  }
}

export function* getLastInventory(action) {
  try {
    const inventory = yield call(service.getLastInventory, prop('payload', action));
    yield put(setInventory(inventory));
  } catch (err) {
    yield put(getLastInventoryFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.INVENTORY, error: err, data: action.payload }));
  }
}

export function* appendInventories(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const inventories = yield call(service.getInventories, {
      criteria: { ...payload },
      order: prop('order', payload),
      page: payload.page,
      limit: payload.limit
    });
    yield put(appendInventoriesSucess({ ...inventories, limit: payload.limit }));
  } catch (err) {
    yield put(appendInventoriesFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.INVENTORY, error: err, data: action.payload }));
  }
}

export function* compareInventories(action) {
  try {
    const id = propOr(null, 'payload', action);
    const inventories = yield call(service.compareInventories, id);
    yield put(compareInventoriesSucess(inventories));
  } catch (err) {
    yield put(compareInventoriesFail(err));

    if (err.status !== 404) {
      yield put(handleErrorMessage('COMPARE.FAILURE', { type: types.INVENTORY, error: err, data: action.payload }));
    }
  }
}

export function* _upsertInventory(action) {
  const payload = prop('payload', action);
  const key = defActionType(payload);

  try {
    payload.issues = propOr([], 'issues', payload).filter((item) => !isEmpty(item));
    yield call(service.upsertInventory, payload);
    yield put(upsertInventory.success());
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.INVENTORY }));
  } catch (err) {
    yield put(upsertInventory.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.INVENTORY, error: err, data: action.payload }));
  }
}

export function* _createHouseIssue(action) {
  const payload = prop('payload', action);
  const key = defActionType(payload);

  try {
    payload.issues = propOr([], 'issues', payload).filter((item) => !isEmpty(item));
    yield call(service.createHouseIssue, payload);
    yield put(createHouseIssue.success());
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.ISSUE }));
  } catch (err) {
    yield put(createHouseIssue.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.ISSUE, error: err, data: action.payload }));
  }
}

export default function* inventoriesSaga() {
  yield all([
    takeLatest(GET_INVENTORY, getInventory),
    takeLatest(GET_LAST_INVENTORY, getLastInventory),
    takeLatest(APPEND_INVENTORIES, appendInventories),
    takeLatest(COMPARE_INVENTORIES, compareInventories),
    takeLatest(GET_INVENTORIES, getInventories),
    takeLatest(upsertInventory.REQUEST, _upsertInventory),
    takeLatest(createHouseIssue.REQUEST, _createHouseIssue)
  ]);
}
