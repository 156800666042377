import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, prop, compose } from 'ramda';
import { Text, Row, Col, RenderElement } from '../components';
import { formatFullnameAndRole, upperCase, emptyObject } from '../utils';
import { translate } from '../translate/i18n';
import { DateFormItem } from '../components/date';

const InventoryInfo = (props) => {
  const { t, inventory } = props;

  return (
    <Fragment>
      <Row mb>
        <Text weight={300} size="lg">
          {t('INFORMATION')}
        </Text>
      </Row>

      <Row>
        <Col xl={2} lg={4} md={4} sm={6} mb-double>
          <RenderElement
            label={upperCase(t('LOCATION'))}
            value={pathOr('', ['room', 'house', 'hub', 'name'], inventory)}
          />
        </Col>
        <Col xl={2} lg={4} md={4} sm={6} mb-double>
          <RenderElement label={upperCase(t('HOUSE'))} value={pathOr('', ['room', 'house', 'name'], inventory)} />
        </Col>
        <Col xl={1} lg={4} md={4} sm={6} mb-double>
          <RenderElement label={upperCase(t('ROOM'))} value={pathOr('', ['room', 'number'], inventory)} />
        </Col>

        <Col xl={2} lg={4} md={4} sm={6} mb-double>
          <DateFormItem
            label={upperCase(t('CREATED_AT'))}
            date={inventory.createdAt}
            timezone={inventory.timezone}
            showTime={true}
          />
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} mb-double>
          <RenderElement
            label={upperCase(t('AUTHOR'))}
            value={prop('user', inventory)}
            formatter={formatFullnameAndRole}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

InventoryInfo.propTypes = {
  inventory: object,
  t: func
};

export default compose(
  translate('inventories'),
  connect((state) => ({
    inventory: pathOr(emptyObject, ['inventories', 'current', 'data'], state)
  }))
)(InventoryInfo);
