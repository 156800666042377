import { omit, prop, concat } from 'ramda';

export const types = {
  DISCOUNT: 'DISCOUNT',
  USER_FILE: 'USER_FILE',
  APPLICATION: 'APPLICATION',
  APPLICATION_VERIFICATION: 'APPLICATION_VERIFICATION',
  APPLICATION_FEE: 'APPLICATION_FEE',
  HUB_PAYMENT_METHODS: 'HUB_PAYMENT_METHODS',
  PROFILE_PAYMENT_METHODS: 'PROFILE_PAYMENT_METHODS',
  PAYMENT_PROFILE_METHOD: 'PAYMENT_PROFILE_METHOD',
  ROOM_AVAILABLE: 'ROOM_AVAILABLE',
  HOUSE: 'HOUSE',
  HOUSE_AREA: 'HOUSE_AREA',
  LOCK: 'LOCK',
  TRASH: 'TRASH',
  SERVICE: 'SERVICE',
  SUBSECTION: 'SUBSECTION',
  UNIT: 'UNIT',
  ROOM: 'ROOM',
  LEASE: 'LEASE',
  INVENTORY: 'INVENTORY',
  ISSUE: 'ISSUE',
  INCIDENT: 'INCIDENT',
  PACK: 'PACK',
  PACK_LOCATION: 'PACK_LOCATION',
  USER: 'USER',
  USER_DOCUMENT: 'USER DOCUMENT',
  MEMBER: 'MEMBER',
  PIN: 'PIN',
  DISCOUNT_PROMISE: 'DISCOUNT_PROMISE',
  CHAT: {
    SEARCH: 'CHAT_SEARCH',
    LOGIN: 'CHAT_LOGIN',
    LOGOUT: 'CHAT_LOGOUT'
  },
  CMS: {
    TIPS: 'TIP',
    FEEDS: 'FEED',
    FAQ: 'FAQ',
    HELPVIDEOS: 'VIDEO'
  },
  GEO: {
    PAYMENT_METHODS: 'PAYMENT_METHODS',
    COUNTRY: 'Country',
    CITY: 'City',
    HUB: 'Hub'
  },
  PAYMENT: {
    PAYMENTS: 'FOLLOW_UP',
    DEPOSIT: 'DEPOSIT',
    RENTS: 'RENT',
    PACKS: 'PACK_HISTORY',
    DAMAGE: 'DAMAGE'
  },
  PAYMENT_PROFILE: {
    PAYMENT_PROFILE: 'PAYMENT_PROFILE'
  }
};

export const defActionType = (obj) => (obj.id ? 'UPDATE' : 'CREATE');

export const paramsFromPayload = (payload, omitProps) => {
  const propsToOmit = concat(['order', 'page', 'limit'], omitProps);

  return {
    criteria: payload.criteria ? payload.criteria : omit(propsToOmit, payload),
    order: prop('order', payload),
    page: prop('page', payload),
    limit: prop('limit', payload),
    id: prop('id', payload)
  };
};
