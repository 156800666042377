import React, { useEffect, useState, useCallback } from 'react';
import { func, object, array } from 'prop-types';
import { compose, pathOr, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { Container, Block, Button, Row, Col, SectionTitle, Toggle } from '../components';
import { connect } from 'react-redux';
import { getHouseAreas, addHouseArea, removeHouseArea } from './houses.actions';
import ExtraCommonArea from './House.extraCommonArea';

const initList = {
  AN_AMERICAN_STYLE_FITTED_KITCHEN: false,
  A_LIVING_DINING_ROOM: false,
  A_PROJECTION_ROOM_EQUIPPED_WITH_A_HOME_CINEMA: false,
  A_LAUNDRY_AREA_WITH_WASHING_MACHINE_AND_DRYER: false,
  A_GYM: false,
  A_ROOF_TOP_WITH_GARDEN_FURNITURE: false
};

const CommonAreas = ({ t, getHouseAreas, addHouseArea, removeHouseArea, house, areas }) => {
  const [newArea, setNewArea] = useState(() => false);
  const [generalState, setGeneralState] = useState(() => ({ ...initList }));
  const [generalNames] = useState(() =>
    keys(initList).reduce(
      (all, item) => ({
        ...all,
        [t(item)]: true
      }),
      {}
    )
  );

  useEffect(() => {
    getHouseAreas(house);
  }, [house]);

  const check = useCallback(
    (key) => (status) => {
      generalState[key] = status;
      setGeneralState({ ...generalState });

      if (status) {
        addHouseArea({
          houseId: house.id,
          name: t(key)
        });
      } else {
        const area = areas.find((item) => item.name === t(key));
        if (area) {
          removeHouseArea(area);
        }
      }
    },
    [setGeneralState, generalState]
  );

  useEffect(() => {
    const mask = areas.reduce(
      (all, item) => ({
        ...all,
        [item.name]: true
      }),
      {}
    );

    setGeneralState(
      keys(initList).reduce(
        (all, key) => ({
          ...all,
          [key]: !!mask[t(key)]
        }),
        {}
      )
    );
  }, [areas]);

  return (
    <Block>
      <Container column pt-half pb-half pl pr>
        <SectionTitle translationKey="default:GENERAL" />

        {keys(initList).map((key) => (
          <Row key={key} pt>
            <Col xl={12} lg={12} md={12} sm={12} pr-half pl-half>
              <Toggle
                label={t(key)}
                input={{ checked: generalState[key], onChange: () => null }}
                onCheck={check(key)}
                name={key}
                right
              />
            </Col>
          </Row>
        ))}
      </Container>

      <Container column pt pl pr>
        <SectionTitle translationKey="default:EXTRAS" />

        {areas
          .filter((area) => !generalNames[area.name])
          .map((area) => (
            <ExtraCommonArea key={area.id} initialValues={area} form={`ExtraCommonArea_${area.id}`} />
          ))}

        {newArea ? (
          <ExtraCommonArea
            initialValues={{ houseId: house.id }}
            form={'ExtraCommonArea'}
            onClose={() => setNewArea(false)}
          />
        ) : (
          <Col xl={12} lg={12} md={12} sm={12} pr-half pl-half>
            <div>
              <Button name="ADD_MORE" type="button" size="md" bg-primary onClick={() => setNewArea(true)}>
                {t('form:ADD_MORE')}
              </Button>
            </div>
          </Col>
        )}
      </Container>
    </Block>
  );
};

CommonAreas.propTypes = {
  t: func,
  getHouseAreas: func,
  removeHouseArea: func,
  addHouseArea: func,
  house: object,
  areas: array
};

export default compose(
  translate('house'),
  connect(
    (state) => ({
      areas: pathOr([], ['houses', 'areas', 'list'], state)
    }),
    { getHouseAreas, addHouseArea, removeHouseArea }
  )
)(CommonAreas);
