import React, { useState, Fragment, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { func, object, string, bool } from 'prop-types';
import { reduxForm, Field, FieldArray, change, propTypes as reduxFormPropTypes } from 'redux-form';
import actions from 'redux-form/es/actions';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import {
  BottomContainer,
  Button,
  Row,
  Block,
  Col,
  Text,
  TextInput,
  TextAreaForm,
  ToggleForm,
  ActionButton,
  ActionSecondaryButton,
  Upload
} from '../components';
import StyledForm from '../components/form.styles';
import { StyledLabel } from '../components/form/TextInput.styles';
import ColorInput from '../components/form/ColorInput';
import { hexColor, maxLength, required } from '../utils';
import { upsertPack } from './packs.actions';

/**
 * Bullet points inputs
 * @param {*} param0
 */
const BulletPoints = ({ t, fields, title, placeholder, label, meta }) => {
  const { error } = meta;

  const bulletPoints = fields.map((field, index) => (
    <Row key={field} flexWrap={false}>
      <Field component={TextAreaForm} label={`${label} ${index + 1}`} validate={required} name={field} />

      <ActionSecondaryButton
        margin="20px 0 0 10px"
        onClick={() => fields.remove(index)}
        name="deleteContent"
        icon="Trash2"
        danger
      />
    </Row>
  ));

  return (
    <Row>
      <Col lg={12}>
        <Row middle mb>
          <Text weight={400} mr-half>
            {title}
          </Text>

          <ActionButton icon="Plus" name="addContent" onClick={() => fields.insert(bulletPoints.length)} />

          {error && (
            <Text red={500} weight={400} size="sm">
              {t(`validation:${error}`)}
            </Text>
          )}
        </Row>

        {bulletPoints.length ? (
          bulletPoints
        ) : (
          <Text size="md" weight={200}>
            {placeholder}
          </Text>
        )}
      </Col>
    </Row>
  );
};
const MAX_DESCRIPTION_LENGTH = 160;
const descriptionLength = maxLength(MAX_DESCRIPTION_LENGTH);
BulletPoints.propTypes = {
  t: func,
  fields: object,
  label: string,
  title: string,
  placeholder: string,
  meta: object
};

/**
 * Pack form
 * @param {*} props
 */
const PackForm = (props) => {
  // Props
  const { t, isNew, onCancel, submitting, handleSubmit, submitSucceeded, submitFailed, reset } = props;
  const [saveAsNew, setSaveAsNew] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const handleSaveAsNew = () => {
    props.change('id', null);
    setSaveAsNew(true);
  };
  const restrictToMaxLength = (event, f) => {
    if (f.length > MAX_DESCRIPTION_LENGTH) {
      event.preventDefault();
      props.touch('description');
      props.dispatch(change('PackForm', 'description', f.substring(0, MAX_DESCRIPTION_LENGTH)));
      props.dispatch(actions.updateSyncErrors('PackForm', { description: t('PACK_DESCRIPTION_TOO_LONG') }));
    }
  };
  useEffect(() => {
    if (!submitFailed && saveAsNew) {
      setSaveAsNew(false);
      reset();
    }
  }, [submitFailed]);

  useEffect(() => {
    if (submitSucceeded && (isNew || saveAsNew)) {
      setRedirect(true);
    }
  }, [submitSucceeded]);

  if (redirect) return <Redirect to="/packs" />;

  // Render
  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(upsertPack)}>
        <Block>
          <Row mb pr-half pl-half>
            <Field component={ToggleForm} label={t('ACTIVE')} name="active" type="checkbox" />
          </Row>

          <Row mb pr-half pl-half>
            <Field component={ToggleForm} label={t('FEATURED')} name="featured" type="checkbox" />
          </Row>

          <Row mb pr-half pl-half>
            <Field component={ToggleForm} label={t('ONE_SHOT')} name="oneOff" type="checkbox" />
          </Row>

          <Row>
            <Col xl={6} lg={6} md={6} sm={12} pr-half pl-half mb>
              <Row mb>
                <Text weight={400}>{t('form:GENERAL_INFO')}</Text>
              </Row>

              <Field component={TextInput} validate={required} label={t('NAME')} name="name" />

              <Field
                component={TextAreaForm}
                validate={[required, descriptionLength]}
                onChange={restrictToMaxLength}
                label={t('DESCRIPTION')}
                name="description"
              />

              <FieldArray
                component={BulletPoints}
                label={t('ITEM')}
                title={t('PACK_CONTENT')}
                placeholder={t('ADD_BULLET_POINT')}
                name="content"
                validate={required}
                t={t}
              />
            </Col>

            <Col xl={4} lg={6} md={6} sm={12} pr-half pl-half mb>
              <Row mb>
                <Text weight={400}>{t('MEDIA')}</Text>
              </Row>

              <Field
                placeholder="#ffffff"
                component={ColorInput}
                label={t('BACKGROUND_COLOR')}
                name="color"
                validate={[hexColor, required]}
              />

              <StyledLabel>{t('SVG_ICON')}</StyledLabel>
              <Field component={Upload} accept={['image/svg+xml']} size={80} name="icon" />
            </Col>
          </Row>
        </Block>

        <BottomContainer negativeMargin={true}>
          <Row right>
            <Button name="cancel" disabled={submitting} onClick={() => onCancel()} clear size="md" mb>
              {t('CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary mb>
              {t('SAVE')} {submitting ? '...' : ''}
            </Button>
            {!isNew && (
              <Button
                name="submit"
                disabled={submitting}
                onClick={handleSaveAsNew}
                type="submit"
                size="md"
                bg-primary
                mb>
                {t('SAVE_AS_NEW')} {submitting ? '...' : ''}
              </Button>
            )}
          </Row>
        </BottomContainer>
      </StyledForm>
    </Fragment>
  );
};

PackForm.propTypes = {
  t: func,
  isNew: bool,
  ...reduxFormPropTypes
};

export default compose(
  translate('packs'),
  reduxForm({ form: 'PackForm', enableReinitialize: true, destroyOnUnmount: true })
)(PackForm);
