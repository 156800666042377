import React, { Fragment } from 'react';
import { FieldArray, Field } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { Col, Row, Text, ToggleForm } from '../components';
import { capitalize } from '../utils';

export const checklist = [
  { key: 'LAMPS', item: 'CHECKLIST.LAMPS' },
  { key: 'BED_MATTRESS', item: 'CHECKLIST.BED_MATTRESS' },
  { key: 'BADGE', item: 'CHECKLIST.BADGE' },
  { key: 'HEATER', item: 'CHECKLIST.HEATER' },
  { key: 'HANGS', item: 'CHECKLIST.HANGS' },
  { key: 'PAPER_BIN', item: 'CHECKLIST.PAPER_BIN' },
  { key: 'LAUNDRY_BAG', item: 'CHECKLIST.LAUNDRY_BAG' },
  { key: 'CABLE', item: 'CHECKLIST.CABLE' },
  { key: 'MIRROR', item: 'CHECKLIST.MIRROR' }
];

const Checklist = ({ checklist } = {}) =>
  checklist.map((check, index) => (
    <Col xl={5} lg={5} md={6} sm={12} pr-half mb key={index}>
      <Row mb>
        <Field
          name={`checklist.${index}.${check.key}`}
          label={check.item}
          component={ToggleForm}
          type="checkbox"
          right={true}
        />
      </Row>
    </Col>
  ));

const ChecklistSection = ({ t } = {}) => (
  <Fragment>
    <Row mb-quarter>
      <Text size="lg" weight={300}>
        {capitalize(t('FORM.CHECKLIST_SECTION_TITLE'))}
      </Text>
    </Row>
    <Row pt>
      <FieldArray
        component={Checklist}
        isLocked={false}
        name="checklist"
        checklist={checklist.map((check) => ({
          ...check,
          item: t(check.item)
        }))}
      />
    </Row>
  </Fragment>
);

export default compose(translate('inventories'))(ChecklistSection);
