export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';

export const APPEND_PAYMENTS = 'APPEND_PAYMENTS';
export const APPEND_PAYMENTS_SUCCESS = 'APPEND_PAYMENTS_SUCCESS';
export const APPEND_PAYMENTS_FAILURE = 'APPEND_PAYMENTS_FAILURE';

export const UPSERT_DAMAGE = 'UPSERT_DAMAGE';
export const UPDATE_DAMAGE = 'UPDATE_DAMAGE';
export const UPSERT_COMMENT = 'UPSERT_COMMENT';

export const SET_PAYMENT_MEMBER = 'SET_PAYMENT_MEMBER';
