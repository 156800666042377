import React from 'react';
import Selector from './Selector';
import { translate } from '../../translate/i18n';
import { func, string, array, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose, pathOr, isEmpty } from 'ramda';
import { emptyArray } from '../../utils';
import { getRole } from '../../enum/enum.actions';
import { Loader } from '..';

export const customStyles = (theme) => ({
  placeholder: (base) => ({
    ...base,
    fontWeight: 300,
    fontFamily: theme.font.family,
    color: theme.colors.grey[500],
    fontSize: theme.font.size.sm
  }),
  control: (base) => ({
    ...base,
    outline: 'none',
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: theme.colors.grey[500],
    border: 0,
    fontFamily: theme.font.family,
    fontWeight: theme.font.weight['lg'],
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.colors.lightGrey[500]}`,
    paddingLeft: 0,
    marginLeft: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  option: (_, state) => ({
    backgroundColor: state.isSelected ? theme.colors.grey[500] : 'white',
    color: state.isSelected ? 'white' : theme.colors.lightGrey[500],
    padding: theme.spacing.half,
    fontFamily: theme.font.family,
    fontWeight: 300,
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.lightGrey[400],
      color: theme.colors.grey[500]
    }
  })
});

const handleChange = (onChange) => (value) => {
  if (isEmpty(value)) return onChange();
  return onChange(value);
};

const SelectorRole = (props) => {
  const { t, name, list, getRole, onChange, isMulti, label, value, isInForm, isDisabled, isFetching, theme } = props;

  if (!list.length) getRole();

  if (isFetching) return <Loader />;

  const options = list.map((role) => ({
    label: t(`role:${role}`),
    value: role
  }));

  return (
    <Selector
      styles={customStyles(theme)}
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange(onChange)}
      isDisabled={isDisabled}
    />
  );
};
SelectorRole.propTypes = {
  isInForm: bool,
  t: func,
  name: string.isRequired,
  list: array,
  getRole: func,
  isFetching: bool,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  theme: object
};

Selector.defaultValues = {
  isInForm: false
};

export default compose(
  translate(),
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'roles', 'list'], state),
      isFetching: pathOr(true, ['enums', 'roles', 'isFetching'], state)
    }),
    { getRole }
  )
)(SelectorRole);
