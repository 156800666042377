import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { func, array, object } from 'prop-types';
import { compose, pathOr, prop, propOr } from 'ramda';
import { translate } from '../translate/i18n';
import {
  emptyArray,
  capitalize,
  valueOrPlaceholder,
  isNotEmptyOrNil,
  formatAge,
  isEmptyOrNil,
  formatPhone,
  emptyObject
} from '../utils';
import { Text, Row, Col, ScrollableDataTableWithColSelector, Block } from '../components';
import { Redirect, withRouter } from 'react-router-dom';
import { appendMembers, getMembers } from './member.actions';
import Filter from './Member.filter';

class Members extends PureComponent {
  static defaultProps = {
    members: emptyArray
  };

  static propTypes = {
    members: object,
    t: func,
    getMembers: func,
    appendMembers: func,
    houseList: array,
    history: object
  };

  constructor(props) {
    super(props);

    this.debounce = null;
    this.state = { filters: null, houseId: {}, hubId: null, redirect: false, memberId: '' };
  }

  componentDidMount() {
    const { t } = this.props;

    this.columnDefs = [
      {
        headerName: capitalize(t('FIRST_NAME')),
        defaultActive: true,
        field: 'firstName',
        width: 120,
        onCellClicked: (event) => this.goToMemberDetail(event)
      },
      {
        headerName: capitalize(t('LAST_NAME')),
        defaultActive: true,
        field: 'lastName',
        width: 120,
        onCellClicked: (event) => this.goToMemberDetail(event)
      },
      {
        headerName: capitalize(t('AGE')),
        field: 'birthdate',
        width: 80,
        onCellClicked: (event) => this.goToMemberDetail(event),
        valueFormatter: (col) => {
          const birthdate = prop('value', col);

          return valueOrPlaceholder(isNotEmptyOrNil, formatAge)(birthdate);
        }
      },
      {
        headerName: capitalize(t('ACTIVE')),
        field: 'leases',
        width: 80,
        onCellClicked: (event) => this.goToMemberDetail(event),
        valueFormatter: (col) => {
          const leases = prop('value', col);
          const key = isEmptyOrNil(leases) ? 'default:NO' : 'default:YES';

          return capitalize(t(key));
        },
        sortable: false
      },
      {
        headerName: capitalize(t('LOCATION')),
        field: 'leases.0.room.house.hub.name',
        width: 120,
        onCellClicked: (event) => this.goToMemberDetail(event)
      },
      {
        headerName: capitalize(t('HOUSE')),
        field: 'leases.0.room.house.name',
        defaultActive: true,
        width: 150,
        onCellClicked: (event) => this.goToMemberDetail(event)
      },
      {
        headerName: capitalize(t('ROOM')),
        defaultActive: true,
        field: 'leases.0.room.number',
        width: 80,
        onCellClicked: (event) => this.goToMemberDetail(event)
      },
      {
        headerName: capitalize(t('PHONE')),
        field: 'phoneNumber',
        width: 100,
        cellRendererFramework: (event) => {
          const member = event.data;
          const formatedNumber = formatPhone(
            propOr('', 'phoneNumber', member),
            pathOr('', ['phonePrefix', 'prefix'], member)
          );

          if (isEmptyOrNil(formatedNumber)) {
            return <span>{valueOrPlaceholder(isNotEmptyOrNil)(formatedNumber)}</span>;
          }

          return <a href={`tel:${formatedNumber}`}>{formatedNumber}</a>;
        }
      },
      {
        headerName: capitalize(t('EMAIL')),
        field: 'email',
        width: 300,
        cellRendererFramework: (event) => {
          const member = event.data;
          return <a href={`mailto:${member.email}`}>{member.email}</a>;
        }
      }
    ];
  }

  goToMemberDetail(event) {
    const { history } = this.props;
    const member = event.data;
    history.push(`/members/${member.id}`);
  }

  render() {
    const { t, members, getMembers, appendMembers } = this.props;
    const { redirect, memberId, filters } = this.state;

    if (redirect) {
      return <Redirect to={`/members/${memberId}`} key={memberId} />;
    }

    return (
      <Block p>
        <Row mb>
          <Col lg={12}>
            <Text weight={200} size="xl">
              {t('MEMBERS')}
            </Text>
          </Col>
        </Row>

        <Filter
          onFilterSelect={(filters) =>
            this.setState({
              filters
            })
          }
        />

        <ScrollableDataTableWithColSelector
          filters={filters}
          ready={!!filters}
          columnDefs={this.columnDefs}
          defaultColDef={{
            valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col))
          }}
          getMethod={getMembers}
          appendMethod={appendMembers}
          rows={members.list}
          rowCount={members.count}
          isFetching={members.isFetching}
          responseLength={members.responseLength}
        />
      </Block>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      members: propOr(emptyObject, 'member', state),
      houseList: pathOr([], ['houses', 'list'], state)
    }),
    { getMembers, appendMembers }
  ),
  translate('member')
)(Members);
