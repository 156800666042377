import React from 'react';
import { equals, is, isEmpty } from 'ramda';
import browserStore from '../utils/browserStore';
import { array, bool, func, oneOfType, string } from 'prop-types';

export const storableSelector = (WrappedComponent) => {
  const decorate = (props) => {
    const { ready, value, onLoad, name, storeType, storeName, dafaultValue, autoSelect, isMulti, onChange } = props;

    const selectChange = (val) => {
      let data = val;
      if (isEmpty(val) || !val) {
        data = isMulti ? [] : null;
      }

      if (storeName) {
        browserStore.setItem(storeType, `${storeName}:${name}`, data);
      }

      onChange(data);
    };

    const noData = (preset) => !preset || isEmpty(preset);

    const makeMask = (options) =>
      is(Array, options)
        ? options.reduce(
            (all, item) => ({
              ...all,
              [item.value]: true
            }),
            {}
          )
        : {};

    const loadFromStore = () => {
      if (noData(value)) {
        const res = browserStore.getItem(storeType, `${storeName}:${name}`);
        if (!noData(res)) {
          return res;
        }

        if (!noData(dafaultValue)) {
          return dafaultValue;
        }
      }

      return value;
    };

    const filterNonExisting = (preset, options) => {
      const optionMask = makeMask(options);

      if (is(Array, preset)) {
        return preset.filter((item) => optionMask[item]);
      } else if (!optionMask[preset]) {
        return null;
      }

      return preset;
    };

    const checkForAutoSelect = (preset, options) => {
      let res = preset;

      if (noData(res) && autoSelect && !isEmpty(options)) {
        res = options[0].value;
        if (isMulti) {
          res = [res];
        }
      }

      return res;
    };

    const valueControl = (options, handleChange = selectChange) => {
      let preset = loadFromStore();

      preset = filterNonExisting(preset, options);

      preset = checkForAutoSelect(preset, options);

      if (!equals(preset, value)) {
        handleChange(preset);
      }

      onLoad();
    };

    if (!ready) {
      return null;
    }

    return <WrappedComponent {...props} onChange={selectChange} valueControl={valueControl} />;
  };

  decorate.propTypes = {
    ready: bool,
    name: string.isRequired,
    onChange: func.isRequired,
    onLoad: func,
    isMulti: bool,
    autoSelect: bool,
    value: oneOfType([string, array]),
    storeType: string,
    storeName: string
  };

  decorate.defaultProps = {
    onLoad: () => {},
    storeType: 'session',
    storeName: null,
    value: null,
    ready: true,
    autoSelect: false,
    isMulti: false
  };

  return decorate;
};
