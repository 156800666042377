import * as api from '@cohabs/bo-api';
import { path } from 'ramda';
import { emptyArray, defaultPaginationLimit } from '../utils';

export const getHouses = ({ page = 1, limit = defaultPaginationLimit, criteria, order }) =>
  api.post(`/houses/${page}/${limit}`, { criteria, order });

export const createHouse = (house) => api.post('/house', house);
export const updateHouse = (house) => api.patch(`/house/${house.id}`, house);
export const upsertHouse = (house) => (!house.id ? createHouse(house) : updateHouse(house));

export const fetchHouse = (id) => api.get(`/house/${id}`);

export const getHouseTrashes = (houseId) => api.get(`/trashes/house/${houseId}`);
export const createTrash = (trash) => api.post('/trash', trash);
export const updateTrash = (trash) => api.patch(`/trash/${trash.id}`, trash);
export const upsertTrash = (trash) => (!trash.id ? createTrash(trash) : updateTrash(trash));
export const deleteTrash = (list = emptyArray) => Promise.all(list.map((trash) => api.del(`/trash/${trash.id}`)));

export const getHouseUnits = (houseId) => api.get(`/house/${houseId}`);
export const createUnit = (unit) => api.post('/unit', unit);
export const updateUnit = (unit) => api.patch(`/unit/${unit.id}`, unit);
export const deleteUnits = (list = emptyArray) => Promise.all(list.map((unit) => api.del(`/unit/${unit.id}`)));
export const upsertUnit = (unit) => (!unit.id ? createUnit(unit) : updateUnit(unit));

export const getHouseServices = (houseId) => api.get(`/services/house/${houseId}`);
export const createService = (service) => api.post('/service', service);
export const updateService = (service) => api.patch(`/service/${service.id}`, service);
export const upsertService = (service) => (!service.id ? createService(service) : updateService(service));
export const deleteServices = (list = emptyArray) =>
  Promise.all(list.map((service) => api.del(`/service/${service.id}`)));

export const createCategory = (category) => api.post('/practical', category);
export const updateCategory = (category) => api.put(`/practical/${category.id}`, category);
export const upsertCategory = (category) => (!category.id ? createCategory(category) : updateCategory(category));

export const deleteCategories = (list = emptyArray) =>
  Promise.all(list.map((category) => api.del(`/practical/${category.id}`)));

export const deleteHouses = (list = emptyArray) =>
  Promise.all(list.map((house) => api.patch(`/house/${house.id}`, { id: house.id, deleted: true })));

export const getHouseNextRoom = (id) => api.get(`/house/${id}/nextRoom`);

export const connectHouseToStripe = (houseId, code) =>
  api.patch(`/house/${houseId}/stripe/connect`, {
    code
  });

export const clearHouseLocalStorage = (force = false) => {
  const now = new Date();
  const timestamp = localStorage.getItem('timestamp');
  const houseId = localStorage.getItem('house');

  if (force || (timestamp && houseId && timestamp + 20 * 60 * 1000 < now.getTime())) {
    localStorage.removeItem('house');
    localStorage.removeItem('timestamp');
  }
};

export const getHouseStripeUrl = (clientId) =>
  `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${path(
    ['location', 'origin'],
    window
  )}/houses/stripe`;

export const getHouseAreas = (houseId) => api.get(`/house/${houseId}/area`);
export const addHouseAreas = ({ houseId, name }) => api.post(`/house/${houseId}/area`, { name });
export const updateHouseAreas = ({ houseId, id, name }) => api.put(`/house/${houseId}/area/${id}`, { name });
export const removeHouseAreas = ({ houseId, id }) => api.del(`/house/${houseId}/area/${id}`);
