import { takeLatest, call, all, put } from 'redux-saga/effects';
import { prop } from 'ramda';
import { defActionType, types } from '../../utils/sagas';
import * as service from './discount.service';
import { addDiscountToExistingLease } from './discount.actions';
import { getLeases } from '../../members/member.actions';
import { handleErrorMessage, handleSuccessMessage } from '../../toast/toast.actions';

export function* _addDiscountToExistingLease(action) {
  const payload = prop('payload', action);
  const key = defActionType(payload);

  try {
    yield call(service.addDiscountToExistingLease, payload);
    yield put(addDiscountToExistingLease.success());
    yield put(getLeases(prop('userId', payload)));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.DISCOUNT }));
  } catch (err) {
    yield put(addDiscountToExistingLease.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.DISCOUNT, error: err, data: action.payload }));
  }
}

export default function* discountPromiseSaga() {
  yield all([takeLatest(addDiscountToExistingLease.REQUEST, _addDiscountToExistingLease)]);
}
