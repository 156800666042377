import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as service from './user.service';
import {
  LOGIN,
  INIT_USER,
  FORGOT_PASSWORD_FORM_SUBMIT,
  LOGOUT,
  FETCH_USERS,
  APPEND_USERS,
  SET_BE_ENDPOINT
} from './user.actionTypes';
import { startSubmit, stopSubmit, SubmissionError } from 'redux-form';
import {
  setUser,
  loginFailed,
  logoutSuccess,
  setCurrentUser,
  initUserFailed,
  forgotPasswordFormFailure,
  forgotPasswordFormSuccess,
  fetchUsersSuccess,
  fetchUsersFailure,
  upsertUser,
  resetPassword,
  DELETE_USERS,
  deleteUsersSuccess,
  deleteUsersFailure,
  appendUsersSuccess,
  appendUsersFail
} from './user.actions';
import { is, prop, propOr } from 'ramda';
import { emptyArray, toast } from '../utils';
import { compose, pathOr, omit } from 'ramda';
import { types, defActionType } from '../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import { streamChatAuth, streamChatLogout } from '../chat/chat.actions';
import { setApiUrl as updateStoreUrl } from '../store/apiUrl';
import { setApiUrl } from '@cohabs/bo-api';
import { segmentIdentify } from '../segment/segment.actions';
import { uploadFile } from '../service';

export function* loginUser(action) {
  try {
    const { email, password } = action.payload;
    yield put(startSubmit('LoginForm'));
    const user = yield call(service.login, { email, password });
    yield put(setUser(user));
    yield put(segmentIdentify(user));
    yield put(streamChatAuth(user));
    yield put(stopSubmit('LoginForm'));
  } catch (err) {
    yield put(loginFailed());
    yield put(stopSubmit('LoginForm', { _error: 'INVALID_CREDENTIALS' }));
  }
}

export function* logoutUser() {
  try {
    yield call(service.logout);
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutSuccess());
  }
  yield put(streamChatLogout());
}

export function* initUser() {
  try {
    const user = yield call(service.getCurrentUser);
    yield put(setCurrentUser(user));
    yield put(segmentIdentify(user));
    yield put(streamChatAuth(user));
  } catch (err) {
    yield call(service.logout);
    yield put(initUserFailed());
  }
}
export function changeBeEndPoint(action) {
  if (!process.env.REACT_APP_RELEASE) {
    updateStoreUrl(action.payload.url);
    setApiUrl(action.payload.url);
  }
}

export function* sendResetLink(action) {
  const payload = action.payload;
  try {
    yield call(service.sendResetLinkEmail, payload);
    yield put(forgotPasswordFormSuccess());
  } catch (err) {
    yield put(forgotPasswordFormFailure({ email: err.message }));
  }
}

export function* _resetPassword(action) {
  const payload = action.payload;
  try {
    yield call(service.updatePassword, payload);
    yield put(resetPassword.success());
    toast.success('Password saved');
  } catch (err) {
    const error = new SubmissionError({ _error: 'UNAUTHORIZED' });
    yield put(resetPassword.failure(error));
  }
}

export const handleNewUser = compose(
  omit(['phone']),
  (user) => ({
    ...user,
    phoneNumber: pathOr('', ['phone', 'number'], user),
    prefixId: pathOr('', ['phone', 'prefix', 'value'], user)
  }),
  prop('payload')
);

export function* _upsertUser(action) {
  const user = handleNewUser(action);
  const key = defActionType(user);

  try {
    if (is(Blob, user.picture)) {
      user.picture = yield uploadFile(user.picture);
    }

    const _user = yield call(service.upsertUser, user);

    const currentUser = yield call(service.getCurrentUser);
    if (currentUser.id === _user.id) yield put(segmentIdentify(_user));

    yield put(upsertUser.success(_user));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.USER }));
  } catch (err) {
    if (err.data) {
      const error = Object.keys(err.data).reduce((acc, key) => {
        if (key === 'phoneNumber' || key === 'prefixId') return { ...acc, phone: err.data[key] };
        return { ...acc, [key]: err.data[key] };
      }, {});
      const formError = new SubmissionError(error);
      yield put(upsertUser.failure(formError));
    } else {
      yield put(upsertUser.failure({}));
    }

    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.USER, error: err, data: user }));
  }
}

export function* fetchUserList(action) {
  try {
    const payload = propOr({}, 'payload', action);

    const response = yield call(service.fetchUsers, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(fetchUsersSuccess(response));
  } catch (err) {
    yield put(fetchUsersFailure(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.USER, error: err, data: action.payload }));
  }
}

export function* appendUsers(action) {
  try {
    const payload = propOr({}, 'payload', action);

    const users = yield call(service.fetchUsers, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(appendUsersSuccess(users));
  } catch (err) {
    yield put(appendUsersFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.USER, error: err, data: action.payload }));
  }
}

export function* _deleteUsers(action) {
  const users = propOr(emptyArray, 'payload', action);
  try {
    yield call(service.deleteUsers, users);
    yield put(deleteUsersSuccess(users));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.USER }));
  } catch (err) {
    yield put(deleteUsersFailure(err));
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.USER, error: err, data: action.payload }));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(LOGIN, loginUser),
    takeLatest(LOGOUT, logoutUser),
    takeLatest(INIT_USER, initUser),
    takeLatest(FORGOT_PASSWORD_FORM_SUBMIT, sendResetLink),
    takeLatest(SET_BE_ENDPOINT, changeBeEndPoint),
    takeLatest(resetPassword.REQUEST, _resetPassword),
    takeLatest(upsertUser.REQUEST, _upsertUser),
    takeLatest(FETCH_USERS, fetchUserList),
    takeLatest(DELETE_USERS, _deleteUsers),
    takeLatest(APPEND_USERS, appendUsers)
  ]);
}
