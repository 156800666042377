import React, { useState, useRef, useEffect } from 'react';
import { object, func } from 'prop-types';
import { compose, omit } from 'ramda';
import { translate } from '../translate/i18n';
import { Block, Button, Row, SectionTitle } from '../components';
import RoomLockForm from './Room.lockForm';

const RoomLockSection = (props) => {
  // Props
  const { t, room } = props;
  const lockRef = useRef();
  const [newLock, showNewLock] = useState(false);

  const lockList = room.lock ? [room.lock] : [];

  useEffect(() => {
    if (newLock && lockRef.current) {
      lockRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newLock, lockRef.current]);

  // Render
  return (
    <Block p>
      <div ref={lockRef} />
      <SectionTitle translationKey="lock:LOCK_INFO" />
      {lockList.map((item) => (
        <RoomLockForm
          key={item.id}
          room={room}
          initialValues={{
            roomId: room.id,
            lock: omit(['pin', 'iqSecret'], item)
          }}
          form={`room_lock_${item.id}`}
        />
      ))}

      {newLock && (
        <RoomLockForm
          room={room}
          initialValues={{
            roomId: room.id,
            lock: {}
          }}
          onCancel={() => showNewLock(!newLock)}
          form="room_lock_new"
        />
      )}

      {!newLock && !lockList.length && (
        <Row>
          <div className={lockList.length ? 'add-lock-button-block' : ''}>
            <Button name="addLock" size="md" bg-primary onClick={() => showNewLock(!newLock)}>
              {t('lock:ADD_LOCK')}
            </Button>
          </div>
        </Row>
      )}
    </Block>
  );
};

RoomLockSection.propTypes = {
  t: func,
  room: object
};

export default compose(translate('rooms'))(RoomLockSection);
