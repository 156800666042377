import React from 'react';
import { Text, Container, Row, Col, ActionButton, Loader } from '../components';
import { translate } from '../translate/i18n';
import { connect } from 'react-redux';
import { compose, pathOr, is, isEmpty, path, prop } from 'ramda';
import { func, string, shape, bool, array, object } from 'prop-types';
import {
  fetchCountries,
  fetchCities,
  fetchHubs,
  selectHub,
  initCountryForm,
  initCityForm,
  initHubForm
} from './geo.actions';
import { initComponentWithFetchedData } from '../components/decorators';
import Styled from '@emotion/styled';
import { emptyObject, emptyArray } from '../utils';
import { ChevronRight } from 'react-feather';
import chroma from 'chroma-js';
import FormHandler from './Geo.formHandler';
import { formTypesDef } from './geo.reducer';
import { destroy, initialize } from 'redux-form';

const SectionTitle = Styled('div')(({ theme }) => ({
  borderBottom: `2px solid ${theme.colors.lightGrey[400]}`,
  marginRight: theme.spacing.normal,
  width: '100%',
  paddingBottom: theme.spacing.pacman
}));

const Item = Styled('button')(({ theme, active }) => ({
  outline: 'none',
  padding: theme.spacing.half,
  cursor: 'pointer',
  textAlign: 'left',
  background: active ? theme.colors.grey[500] : chroma(theme.colors.lightGrey[400]).darken(-2).hex(),
  color: active ? 'white' : theme.colors.grey[400],
  border: 'none',
  fontWeight: theme.font.weight.md,
  fontSize: theme.font.size.md,
  fontFamily: theme.font.family,
  borderBottom: `1px solid ${theme.colors.lightGrey[500]}`,
  borderColor: theme.colors.grey[300],
  ':nth-child(2n)': {
    background: active ? theme.colors.grey[500] : theme.colors.lightGrey[400]
  },
  ':last-child': {
    borderColor: 'transparent'
  },
  ':hover': {
    background: theme.colors.lightGrey[500],
    color: 'white'
  }
}));

const handleSelect = (onSelect, active) => (item) => () => {
  if (active !== item) {
    if (is(Function, onSelect)) {
      onSelect(item);
    }
  }
};

const Section = ({ title, list, onSelect, isFetching, active, initForm, disabled }) => {
  const select = handleSelect(onSelect, active);
  return (
    <Container column pr>
      <SectionTitle>
        <Row middle>
          <Text size="xl" weight={300} grey={500} pr-half>
            {title}
          </Text>
          <ActionButton disabled={disabled} name={`add-${title}`} icon="Plus" onClick={initForm} />
        </Row>
      </SectionTitle>

      <Container column pt-half>
        <Loader when={isFetching}>
          {list.map((item, index) => (
            <Item key={item.id || index} active={active === item.id} onClick={select(item)}>
              <Row middle>
                <Col lg={11}>{item.name}</Col>
                <Col lg={1}>
                  <ChevronRight size={18} />
                </Col>
              </Row>
            </Item>
          ))}
        </Loader>
      </Container>
    </Container>
  );
};

Section.defaultProps = {
  list: emptyArray,
  title: '',
  isFetching: false,
  active: '',
  initForm: () => null,
  disabled: false
};

Section.propTypes = {
  title: string.isRequired,
  list: array,
  onSelect: func,
  isFetching: bool,
  active: string,
  initForm: func,
  disabled: bool
};

const Geo = ({
  t,
  country,
  city,
  fetchCities,
  hub,
  fetchHubs,
  selectHub,
  form,
  dispatch,
  initCountryForm,
  initCityForm,
  initHubForm
}) => {
  return (
    <Row>
      <Col lg={9} p>
        <Text grey={500} size="xl" weight={200}>
          {t('TITLE')}
        </Text>
        <Row pt-double>
          <Col lg={4}>
            <Section
              title={t('COUNTRY')}
              list={country.list}
              isFetching={country.isFetching}
              active={path(['active', 'id'], country)}
              onSelect={(_country) => {
                dispatch(initialize('CountryForm', _country));
                fetchCities(_country);
              }}
              initForm={() => {
                dispatch(destroy('CountryForm'));
                initCountryForm();
              }}
            />
          </Col>
          <Col lg={4}>
            <Section
              title={t('CITY')}
              disabled={!path(['active', 'id'], country)}
              list={city.list}
              isFetching={city.isFetching}
              onSelect={(_city) => {
                const isActiveCity = path(['active', 'id'], city) === prop('id', _city);
                if (!isActiveCity) dispatch(destroy('CityForm'));
                fetchHubs(_city);
              }}
              active={path(['active', 'id'], city)}
              initForm={() => {
                dispatch(initialize('CityForm', { country: prop('active', country) }));
                initCityForm();
              }}
            />
          </Col>
          <Col lg={4}>
            <Section
              title={t('HUB')}
              list={hub.list}
              isFetching={hub.isFetching}
              disabled={!path(['active', 'id'], city)}
              onSelect={(_hub) => {
                const isActiveHub = path(['active', 'id'], hub) === prop('id', _hub);
                if (!isActiveHub) dispatch(destroy('HubForm'));
                selectHub(_hub);
              }}
              active={path(['active', 'id'], hub)}
              initForm={() => {
                dispatch(initialize('HubForm', { city: prop('active', city) }));
                initHubForm();
              }}
            />
          </Col>
        </Row>
      </Col>
      {!isEmpty(form) && (
        <Col lg={3}>
          <Container bgColor scrollable main pl pr-half>
            <FormHandler form={form} />
          </Container>
        </Col>
      )}
    </Row>
  );
};

const itemDef = shape({
  list: array.isRequired,
  isFetching: bool.isRequired
});

Geo.propTypes = {
  t: func,
  country: itemDef,
  city: itemDef,
  hub: itemDef,
  fetchCountries: func,
  fetchCities: func,
  fetchHubs: func,
  selectHub: func,
  form: shape({
    type: formTypesDef,
    data: object
  }),
  dispatch: func,
  initCountryForm: func,
  initCityForm: func,
  initHubForm: func,
  bgColor: bool
};

export default compose(
  translate('geo'),
  connect(
    (state) => ({
      country: pathOr(emptyObject, ['geo', 'country'], state),
      city: pathOr(emptyObject, ['geo', 'city'], state),
      hub: pathOr(emptyObject, ['geo', 'hub'], state),
      form: pathOr(emptyObject, ['geo', 'form'], state)
    }),
    {
      fetchCountries,
      fetchCities,
      fetchHubs,
      selectHub,
      initCountryForm,
      initCityForm,
      initHubForm
    }
  ),
  initComponentWithFetchedData((props) => ({
    entity: props.country,
    action: props.fetchCountries
  }))
)(Geo);
