import * as api from '@cohabs/bo-api';
import { setStorage, getStorage, removeStorage } from '../utils/storage';
import { defaultPaginationLimit } from '../utils';
import { getRoles } from '../enum/enum.service';
import { without, prop } from 'ramda';

const tokenKey = 'TOKEN';

export const getToken = () => getStorage(tokenKey);

const handleToken = (token) => {
  api.addToken(token);
  return setStorage(tokenKey, token);
};

export const getCurrentUser = () => {
  const token = getStorage(tokenKey);
  if (!token) throw Error('no token found');
  handleToken(token);
  return getUser();
};

export const login = async (credentials) =>
  api.post('/login', credentials).then(({ token, user }) => {
    handleToken(token);
    return user;
  });

export const logout = () => {
  api.deleteToken();
  return removeStorage(tokenKey);
};

export const getUser = (id) => (id ? api.get(`/user/${id}`) : api.get('/user'));

export const sendResetLinkEmail = ({ email }) => api.get(`/user/resetPassword?email=${encodeURIComponent(email)}`);

export const updatePassword = (payload = {}) => {
  const token = `Bearer ${prop('token', payload)}`;
  delete payload.token;
  return api.post('/user/resetPassword', payload, { Authorization: token });
};

export const getStayInfo = (id) => api.get(`/user/${id}/stayinfo`);

export const updateUser = (user) => api.put(`/user/${user.id}`, user);

// insert or update
export const upsertUser = (user) => (user.id ? api.put(`/user/${user.id}`, user) : api.post('/user', user));

export const fetchUsers = ({ page = 1, limit = defaultPaginationLimit, criteria = {}, order = [] }) => {
  if (page < 1) page = 1;

  return getRoles().then((roles) =>
    api.post(`/users/${page}/${limit}`, {
      criteria: {
        ...criteria,
        role: without('user', roles)
      },
      order
    })
  );
};

export const getCM = ({ page = 1, limit = defaultPaginationLimit, criteria = {}, search = undefined, order = [] }) =>
  api.post(`/users/${page}/${limit}`, {
    criteria: {
      ...criteria,
      role: ['admin', 'communityManager', 'propertyManager'],
      search
    },
    order
  });

export const deleteUsers = (users) => {
  const promises = users.map((user) => api.del(`/user/${user.id}`));
  return Promise.all(promises);
};
