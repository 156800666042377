import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import hash from 'object-hash';
import { compose, values, all, reduce, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { handleFilters } from '../utils';
import { Row, Col, SelectorHub, collapsible } from '../components';

const PacksFilter = ({ t, onFilterSelect }) => {
  let [hashCode, setHashCode] = useState('');

  let [filters, setFilters] = useState({
    hubId: []
  });

  let [loaded, setLoaded] = useState({
    hubId: false
  });

  const handleChange = (filter) => (value) => {
    filters[filter] = value;
    setFilters({
      ...filters
    });
  };

  const onLoad =
    (filter) =>
    (val = true) => {
      if (loaded[filter] !== val) {
        loaded[filter] = val;
        setLoaded({
          ...loaded
        });
      }
    };

  useEffect(() => {
    if (all((item) => item)(values(loaded))) {
      const newHashCode = hash(filters);
      if (newHashCode !== hashCode) {
        hashCode = newHashCode;
        setHashCode(newHashCode);

        onFilterSelect(reduce((res, filter) => handleFilters(res, filter, filters[filter]), {}, keys(filters)));
      }
    }
  }, [filters, loaded]);

  return (
    <Row middle mb>
      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorHub
          name="hub"
          onChange={handleChange('hubId')}
          isMulti
          label={t('AVAILABILITY')}
          placeholder={t('form:TYPE_A_HUB_NAME')}
          value={filters.hubId}
          onLoad={onLoad('hubId')}
          storeName="Packs"
          storeType="const"
        />
      </Col>
    </Row>
  );
};

PacksFilter.propTypes = {
  onFilterSelect: func,
  t: func
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_FILTER',
    labelShow: 'default:SHOW_FILTER'
  }),
  translate(['packs', 'form'])
)(PacksFilter);
