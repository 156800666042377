import * as api from '@cohabs/bo-api';

export const getCountries = () => api.post('/countries/1/50');

export const getCities = async ({ criteria }) => api.post('/cities/1/50', { criteria });

export const getHubs = ({ criteria }) => api.post('/hubs/1/50', { criteria });

export const getAllPaymentMethods = () => api.get('/payment-methods');

export const upsertCountry = (country) =>
  country.id ? api.patch(`/country/${country.id}`, country) : api.post('/country', country);

export const upsertCity = (city) => (city.id ? api.patch(`/city/${city.id}`, city) : api.post('/city', city));

export const upsertHub = (hub) => (hub.id ? api.patch(`/hub/${hub.id}`, hub) : api.post('/hub', hub));
