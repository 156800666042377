import { injectGlobal } from 'emotion';

injectGlobal`
  .online-status {
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 8px;
  }

  .main-menu {
    max-width: 260px;
    width: 260px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 3;
    overflow-x: hidden;

    ul {
      overflow-x: hidden;
    }
    
    .menu-icon {
      display: flex;
      flex-direction: row;
      padding: 0 25px 0 4px;
      text-align: left;
    }

    @media (max-width: 1024px) {
      z-index: 3;
      position: absolute;
      left: 0;
      top: 0;
    }

    @media (max-width: 768px) {
      z-index: 10;
      max-width: 100%;
    }

    .logo-block {
      width: 27px;
      height: 27px;
      border-radius: 10px;
      overflow: hidden;
      
      .logo {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }

    .unread-count {
      border-radius: 10px;
      background-color: #E75D90;
      padding: 3px 10px;
      margin-left: auto;
      position: absolute;
      right: 16px;
    }

    .line-container {
      max-width: 260px;
      min-width: 260px;
      width: 260px;
      position: relative;
      
      &.no-line {
        li {
          border: 0;
        }
      }


      .collapse-menu {
        position: absolute;
        right: 14px;
        top: 1px;
        width: 27px;
        height: 27px;
        border-radius: 7px;
        padding: 1px;

        &:hover {
          opacity: 1;
          background: #63737C;
        }
      }
    }
  }

  .collapse_main_menu {
    .main-menu {
      max-width: 56px;
      width: 56px;

      .collapse-menu {
        opacity: 0;
        left: 15px;
        right: auto;
      }

      .unread-count {
        left: 25px;
        top: 10px;
        right: auto;
        padding: 2px 7px;

        div {
          font-size: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .collapse_main_menu {
      .main-menu {
        display: none;
      }
    }

    .main-menu {
      max-width: 100%;
      width: 260px;
    }
  }

  .main-menu-burger {
    display: none;
    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      background: #E8EBED;

      button {
        width: 100%;
      }
    }
  }
`;
