import React, { useState } from 'react';
import { func, object, number, bool, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from '../translate/i18n';
import { pathOr, prop, path, is } from 'ramda';
import { Eye } from 'react-feather';
import Filter from './Leases.filter';
import { initValues } from './Leases.utils';

import { getLeases, appendLeases } from './leases.actions';

import { capitalize, formatDate, valueOrPlaceholder, isNotEmptyOrNil, emptyArray } from '../utils';

import { Container, Row, Block, Text, ScrollableDataTableWithColSelector, Modal } from '../components';

import LeaseForm from './Lease.form';

const ViewIcon = () => (
  <Container center>
    <Eye size={16} opacity={0.2} />
  </Container>
);

/**
 * Returns static columns definition of leases table
 * @param {Function} t translation function
 */
const getColumnDefs = (t, openLease, setLease, toggleModal) => [
  {
    headerName: '',
    label: t('DOCUMENTS'),
    cellRendererFramework: ViewIcon,
    width: 50,
    onCellClicked: (event) => {
      const lease = prop('data', event);
      openLease(lease.id);
    }
  },
  //Client decides to hide ID column. Commented for eventual future use
  // {
  //   headerName: capitalize(t('ID')),
  //   field: 'name'
  // },
  {
    headerName: capitalize(t('Member')),
    field: 'user',
    defaultActive: true,
    comparator: (valueA, valueB) => {
      const lastNameA = prop(['lastName'], valueA);
      const lastNameB = prop(['lastName'], valueB);

      if (lastNameA < lastNameB) {
        return -1;
      } else if (lastNameA > lastNameB) {
        return 1;
      } else {
        return 0;
      }
    },
    valueFormatter: (col) => {
      const firstName = path(['value', 'firstName'], col);
      const lastName = path(['value', 'lastName'], col);

      return `${capitalize(lastName)} ${capitalize(firstName)}`;
    }
  },
  {
    headerName: capitalize(t('LOCATION')),
    field: 'room.house.hub.city.name'
  },
  {
    headerName: capitalize(t('HOUSE')),
    defaultActive: true,
    field: 'room.house.name'
  },
  {
    headerName: capitalize(t('ROOM')),
    defaultActive: true,
    field: 'room.number',
    width: 75
  },
  {
    headerName: capitalize(t('RENT')),
    field: 'rentAmount',
    width: 100,
    valueFormatter: (col) => {
      const rent = parseFloat(prop('value', col));

      return is(Number, rent) ? `${rent.toFixed(2)}` : '';
    }
  },
  {
    headerName: capitalize(t('MOVE_IN_DATE')),
    field: 'startDate',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDate)(prop('value', col), col.data.timezone),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value)
  },
  {
    headerName: capitalize(t('MOVE_OUT_DATE')),
    defaultActive: true,
    field: 'moveoutDate',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDate)(prop('value', col), col.data.timezone),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value),
    onCellClicked: (event) => {
      setLease(prop('data', event));
      toggleModal(true);
    }
  },
  {
    headerName: capitalize(t('PREFERRED_MOVE_OUT_DATE')),
    field: 'preferredMoveoutDate',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDate)(prop('value', col), col.data.timezone),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value),
    onCellClicked: (event) => {
      setLease(prop('data', event));
      toggleModal(true);
    }
  },
  {
    headerName: capitalize(t('STATUS')),
    field: 'status',
    width: 100,
    valueFormatter: ({ value }) => t(value.toUpperCase())
  }
];

/**
 * React component
 * @param {Object} props props
 */
const Leases = (props) => {
  const { t, leases, appendLeases, getLeases, history, leasesCount, isFetching, responseLength } = props;

  const [filters, setFilters] = useState();
  const [lease, setLease] = useState(null);
  const [modalIsOpen, toggleModal] = useState(false);

  const openLease = (leaseId) => history.push(`/leases/${leaseId}`);

  const handleRowSelected = (event) => openLease(pathOr({}, ['data', 'id'], event));

  const defaultColDef = {
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
    onCellClicked: handleRowSelected
  };

  return (
    <Block p>
      <Row mb>
        <Text weight={200} size="xl">
          {t('LEASES')}
        </Text>
      </Row>

      <Filter onFilterSelect={(filter) => setFilters(filter)} />

      <ScrollableDataTableWithColSelector
        filters={filters}
        ready={!!filters}
        columnDefs={getColumnDefs(t, openLease, setLease, toggleModal)}
        defaultColDef={defaultColDef}
        getMethod={getLeases}
        appendMethod={appendLeases}
        rows={leases}
        rowCount={leasesCount}
        isFetching={isFetching}
        responseLength={responseLength}
      />

      <Modal
        title={t('EDIT_PREFERRED_MOVE_OUT')}
        visibleOverflow={true}
        show={modalIsOpen}
        onClose={() => toggleModal(false)}
        content={() => (
          <LeaseForm initialValues={initValues(lease)} onClose={() => toggleModal(false)} timezone={lease.timezone} />
        )}
      />
    </Block>
  );
};

Leases.propTypes = {
  t: func,
  leases: array,
  leasesCount: number,
  isFetching: bool,
  responseLength: number,
  appendLeases: func,
  getHouses: func,
  fetchHubs: func,
  pageNumber: number,
  getLeases: func,
  history: object
};

export default compose(
  withRouter,
  translate(['leases', 'default', 'rooms', 'house']),
  connect(
    (state) => ({
      leases: pathOr(emptyArray, ['leases', 'list'], state),
      leasesCount: pathOr(0, ['leases', 'count'], state),
      isFetching: pathOr(false, ['leases', 'isFetching'], state),
      responseLength: pathOr(0, ['leases', 'responseLength'], state)
    }),
    { getLeases, appendLeases }
  )
)(Leases);
