import React from 'react';
import { compose, pathOr, values } from 'ramda';
import { connect } from 'react-redux';
import { useChatContext, ChannelHeader } from 'stream-chat-react';

import { object } from 'prop-types';

const ChannelHeaderWrap = ({ user }) => {
  const { channel } = useChatContext();
  let name = pathOr('', ['data', 'name'], channel);

  if (!name) {
    const otherMembers = values(pathOr([], ['state', 'members'], channel)).filter(
      (member) => member.user.id !== user.id
    );

    name = otherMembers
      .map((item) => pathOr('', ['user', 'name'], item))
      .filter((item) => item)
      .join(', ');
  }

  return <ChannelHeader title={name} />;
};

ChannelHeaderWrap.propTypes = {
  user: object.isRequired
};

export default compose(
  connect((state) => ({
    user: pathOr({}, ['user', 'data'], state)
  }))
)(ChannelHeaderWrap);
