import React from 'react';
import { path, pathEq, filter } from 'ramda';
import { array, func, string } from 'prop-types';
import { Table, Container, Text } from '../components';
import { translate } from '../translate/i18n';
import { Link } from 'react-router-dom';

const ProfileHeader = ({ displayName }) => (
  <Text size="lg" weight={300}>
    {displayName}
  </Text>
);
ProfileHeader.propTypes = {
  displayName: string
};

const createColumnDefs = (t) => [
  {
    headerName: t('profile.packTitle'),
    field: 'name',
    cellRendererFramework: (col) => (
      <Link to={`/packs/${path(['data', 'id'], col)}`}>
        <Text>{col.value}</Text>
      </Link>
    )
  }
];

const PacksProfile = ({ t, packs }) => {
  const data = filter(pathEq(['pack_subscription', 'active'], true), packs);

  return (
    <Container column pt>
      <Table
        data={{
          columnDefs: createColumnDefs(t),
          rowData: data,
          frameworkComponents: { agColumnHeader: ProfileHeader }
        }}
        hasPagination={false}
      />
    </Container>
  );
};

PacksProfile.propTypes = {
  t: func,
  packs: array
};

export default translate('member')(PacksProfile);
