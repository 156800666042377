import React, { useState, Fragment } from 'react';
import { StyledLabel, StyledTextInput } from './form/TextInput.styles';

const FreeTextInput = (props) => {
  const { label } = props;

  const [isActive, setActive] = useState(false);

  return (
    <Fragment>
      <StyledLabel active={isActive}>{label}</StyledLabel>
      <StyledTextInput {...props} onFocus={() => setActive(true)} onBlur={() => setActive(false)} />
    </Fragment>
  );
};

export default FreeTextInput;
