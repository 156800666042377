import * as api from '@cohabs/bo-api';
import { isEmpty } from 'ramda';

export const getGenderList = () => api.get('/enum/gender');
export const getPaymentMethodList = () => api.get('/enum/paymentMethods');

const fetchNationalities = ({
  page = 1,
  limit = 300,
  criteria = {
    search: '',
    attributes: ['nationality', 'code', 'prefix']
  },
  order = []
}) => api.post(`/geo/${page}/${limit}`, { criteria, order });

let countriesList = [];

export const getNationalities = async () => {
  if (isEmpty(countriesList)) {
    countriesList = await fetchNationalities({});
  }
  return countriesList;
};

export const getNationality = (code) => countriesList.find((country) => country.code === code);

export const getPhonePrefixes = (params = { page: 1, limit: 10 }) => fetchNationalities(params);

export const getIncidentStatus = () => api.get('/enum/incidentStatus');

export const getIncidentPriority = () => api.get('/enum/incidentPriority');

export const getApplicationStatus = () => api.get('/enum/status');

export const getRoles = () => api.get('/enum/role');

export const getInventoryStatus = () => api.get('/enum/inventoryStatus');

export const getInventoryGrades = () => api.get('/enum/inventoryState');

export const getInventoryDefects = () => api.get('/enum/inventoryCategory');

export const getRecurrenceUnits = () => api.get('/enum/recurrenceUnit');

export const getCities = ({ page = 1, limit = 300, criteria = {} }) =>
  api.post(`/cities/${page}/${limit}`, { criteria });

export const getCurrencies = ({ page = 1, limit = 300, criteria = {} }) =>
  api.post(`/currencies/${page}/${limit}`, { criteria });

export const getTipsCategories = () => api.get('/enum/tipsCategories');

export const getTipsPriorities = () => api.get('/enum/tipsPriority');

export const getFeedsCategories = () => api.get('/enum/feedCategories');

export const getFaqCategories = () => api.get('/enum/categories');
export const getFaqSubcategories = () => api.get('/enum/subcategories');

export const getRoomCategories = () => api.get('/enum/locations');

export const getServices = () => api.get('/enum/services');

export const getApplicationSwitchTypes = () => api.get('/enum/applicationSwitchType');
