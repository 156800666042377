import { genState } from '../utils/reducer';
import { initHub, GET_HUBS, SET_HUBS, GET_HUB, SET_HUB } from './hub.actionTypes';
import { propOr } from 'ramda';
import { emptyArray } from '../utils';

const hubDef = (state) => ({
  [GET_HUBS]: () => ({
    ...state,
    isFetching: true
  }),
  [SET_HUBS]: (payload) => ({
    isFetching: false,
    list: propOr(emptyArray, 'rows', payload)
  }),
  [GET_HUB]: () => ({
    ...state,
    current: {
      ...state.current,
      isFetching: true
    }
  }),
  [SET_HUB]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      isFetching: false,
      data: payload
    }
  })
});

export const hubReducer = (state = initHub, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: hubDef, type });
};
