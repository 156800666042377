export const ON_DROP = 'ON_DROP';
export const SET_CURRENT_DRAGGABLE = 'SET_CURRENT_DRAGGABLE';
export const GET_KANBAN_APPLICANTS = 'GET_KANBAN_APPLICANTS';
export const GET_KANBAN_APPLICANTS_SUCCESS = 'GET_KANBAN_APPLICANTS_SUCCESS';
export const GET_FILTERED_APPLICATIONS = 'GET_FILTERED_APPLICATIONS';
export const GET_FILTERED_APPLICATIONS_SUCCESS = 'GET_FILTERED_APPLICATIONS_SUCCESS';
export const GET_FILTERED_APPLICATIONS_FAILURE = 'GET_FILTERED_APPLICATIONS_FAILURE';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAILURE = 'GET_APPLICATIONS_FAILURE';
export const APPEND_APPLICATIONS = 'APPEND_APPLICATIONS';
export const APPEND_APPLICATIONS_SUCCESS = 'APPEND_APPLICATIONS_SUCCESS';
export const APPEND_APPLICATIONS_FAIL = 'APPEND_APPLICATIONS_FAIL';
export const FETCH_APPLICANT = 'FETCH_APPLICANT';
export const REFRESH_APPLICANT = 'REFRESH_APPLICANT';
export const FETCH_APPLICANT_SUCESS = 'FETCH_APPLICANT_SUCESS';
export const FETCH_APPLICANT_FAIL = 'FETCH_APPLICANT_FAIL';
export const UPDATE_APPLICANT = 'UPDATE_APPLICANT';
export const UPDATE_APPLICANT_SUCCESS = 'UPDATE_APPLICANT_SUCCESS';
export const UPDATE_APPLICANT_FAIL = 'UPDATE_APPLICANT_FAIL';
export const UPDATE_APPLICANT_STATUS = 'UPDATE_APPLICANT_STATUS';
export const UPDATE_APPLICANT_STATUS_SUCCESS = 'UPDATE_APPLICANT_STATUS_SUCCESS';
export const UPDATE_APPLICANT_STATUS_FAIL = 'UPDATE_APPLICANT_STATUS_FAIL';
export const UPDATE_APPLICANT_KYC_STATUS = 'UPDATE_APPLICANT_KYC_STATUS';
export const UPDATE_APPLICANT_KYC_STATUS_SUCCESS = 'UPDATE_APPLICANT_KYC_STATUS_SUCCESS';
export const UPDATE_APPLICANT_KYC_STATUS_FAIL = 'UPDATE_APPLICANT_KYC_STATUS_FAIL';
export const UPDATE_APPLICANT_CALL_STATUS = 'UPDATE_APPLICANT_CALL_STATUS';
export const UPDATE_APPLICANT_CALL_STATUS_SUCCESS = 'UPDATE_APPLICANT_CALL_STATUS_SUCCESS';
export const UPDATE_APPLICANT_CALL_STATUS_FAIL = 'UPDATE_APPLICANTCALLC_STATUS_FAIL';
export const DELETE_AVAILABLE_ROOMS = 'DELETE_AVAILABLE_ROOMS';
export const DELETE_AVAILABLE_ROOMS_SUCCESS = 'DELETE_AVAILABLE_ROOMS_SUCCESS';
export const DELETE_AVAILABLE_ROOMS_FAIL = 'DELETE_AVAILABLE_ROOMS_FAIL';
export const SEND_OFFERS_ROOM = 'SEND_OFFERS_ROOM';
export const SEND_OFFERS_ROOM_SUCCESS = 'SEND_OFFERS_ROOM_SUCCESS';
export const SEND_OFFERS_ROOM_FAIL = 'SEND_OFFERS_ROOM_FAIL';
export const SEND_REMINDERS = 'SEND_REMINDERS';
export const SEND_REMINDERS_SUCCESS = 'SEND_REMINDERS_SUCCESS';
export const SEND_REMINDERS_FAIL = 'SEND_REMINDERS_FAIL';
export const ADD_NEW_ROOM = 'ADD_NEW_ROOM';
export const ADD_NEW_ROOM_SUCCESS = 'ADD_NEW_ROOM_SUCCESS';
export const ADD_NEW_ROOM_FAIL = 'ADD_NEW_ROOM_FAIL';
export const UPDATE_APPLICANT_INFO = 'UPDATE_APPLICANT_INFO';
export const GET_APPLICATION_VERIFICATIONS = 'GET_APPLICATION_VERIFICATIONS';
export const GET_APPLICATION_VERIFICATIONS_SUCCESS = 'GET_APPLICATION_VERIFICATIONS_SUCCESS';
export const GET_APPLICATION_VERIFICATIONS_FAIL = 'GET_APPLICATION_VERIFICATIONS_FAIL';
export const GET_USER_DOCUMENTS = 'GET_USER_DOCUMENTS';
export const GET_USER_DOCUMENTS_SUCCESS = 'GET_USER_DOCUMENTS_SUCCESS';
export const GET_USER_DOCUMENTS_FAIL = 'GET_USER_DOCUMENTS_FAIL';
export const UPDATE_APPLICATION_VERIFICATION = 'UPDATE_APPLICATION_VERIFICATION';
export const UPDATE_APPLICATION_VERIFICATION_SUCCESS = 'UPDATE_APPLICATION_VERIFICATION_SUCCESS';
export const UPDATE_APPLICATION_VERIFICATION_FAIL = 'UPDATE_APPLICATION_VERIFICATION_FAIL';
export const DELETE_APPLICATION_VERIFICATION = 'DELETE_APPLICATION_VERIFICATION';
export const DELETE_APPLICATION_VERIFICATION_SUCCESS = 'DELETE_APPLICATION_VERIFICATION_SUCCESS';
export const DELETE_APPLICATION_VERIFICATION_FAIL = 'DELETE_APPLICATION_VERIFICATION_FAIL';
export const DELETE_USER_DOCUMENTS = 'DELETE_USER_DOCUMENTS';
export const DELETE_USER_DOCUMENTS_SUCCESS = 'DELETE_USER_DOCUMENTS_SUCCESS';
export const DELETE_USER_DOCUMENTS_FAIL = 'DELETE_USER_DOCUMENTS_FAIL';

export const GET_APPLICATION_FEE = 'GET_APPLICATION_FEE';
export const SET_APPLICATION_FEE = 'SET_APPLICATION_FEE';
export const GET_VALIDATE_APPLICATION_FEE = 'GET_VALIDATE_APPLICATION_FEE';
export const SET_VAILDATE_APPLICATION_FEE = 'SET_VAILDATE_APPLICATION_FEE';

const emptyArray = [];

export const initialApplications = {
  sections: {},
  list: emptyArray,
  currentDraggable: null,
  isFetching: false,
  currentFee: {
    isFetching: false,
    data: null
  },
  current: {
    isFetching: false,
    data: {},
    hasErrors: false,
    isCreating: false
  }
};
