import React, { useState } from 'react';
import { func, object, array } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { pathOr, sort, path, indexOf } from 'ramda';
import { translate } from '../../translate/i18n';
import { Row, Block, Text, ActionButton, Table } from '../../components';
import { formatCurrency } from '../../utils';
import Filter from '../Packs.filter';

/**
 * Returns this table column definition
 * @param {*} t translation function
 */
const getColumnDefs = (t) => [
  {
    headerName: t('Hubs'),
    field: 'hub',
    valueFormatter: (col) => pathOr('', ['value', 'name'], col),
    sortable: true
  },
  {
    headerName: t('City'),
    field: 'hub',
    valueFormatter: (col) => pathOr('', ['value', 'city', 'name'], col),
    sortable: true
  },
  {
    headerName: t('PRICE'),
    field: 'price',
    width: 100
  },
  {
    headerName: t('CURRENCY'),
    field: 'hub',
    width: 100,
    valueFormatter: (col) => formatCurrency(pathOr('', ['value', 'currency'], col)),
    sortable: true
  }
];

/**
 * React component
 * @param {*} props
 */
const PackLocations = ({ packLocations, t, history, match }) => {
  const [sortData, setSort] = useState();
  const [filter, setFilters] = useState();

  const id = path(['params', 'id'], match);
  const onSortChange = ([sortData]) => setSort(sortData);

  let rowData = packLocations;
  if (filter && filter.hubId) {
    rowData = packLocations.filter((location) => ~indexOf(location.hubId, filter.hubId));
  }

  if (sortData) {
    let getData;
    switch (sortData.colId) {
      case 'hub':
        getData = path(['hub', 'name']);
        break;

      case 'hub_1':
        getData = path(['hub', 'city', 'name']);
        break;

      default:
        getData = path(['price']);
    }

    rowData = sort((a, b) => {
      if (sortData.sort === 'asc') {
        return getData(a) < getData(b) ? -1 : 1;
      }

      return getData(b) < getData(a) ? -1 : 1;
    }, rowData);
  }

  const datatable = {
    columnDefs: getColumnDefs(t),
    rowData
  };

  return (
    <Block p>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {t('PACK_LOCATIONS')}
        </Text>

        <ActionButton icon="Plus" name="addPack" onClick={() => history.push(`/pack/${id}/location/create`)} />
      </Row>

      <Filter onFilterSelect={(filter) => setFilters(filter)} />

      <Table
        hasPagination={false}
        data={datatable}
        onSortChanged={(event) => onSortChange(event.api.getSortModel())}
        onRowClicked={({ data }) => history.push(`/pack/${data.packId}/location/${data.hubId}`)}
      />
    </Block>
  );
};

PackLocations.propTypes = {
  history: object,
  match: object,
  pack: object,
  t: func,
  packLocations: array
};

export default compose(withRouter, translate(['packs', 'form']))(PackLocations);
