import React, { useRef } from 'react';
import {
  MessageDeleted as DefaultMessageDeleted,
  MessageOptions as DefaultMessageOptions,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageText,
  MessageTimestamp as DefaultMessageTimestamp,
  messageHasAttachments,
  messageHasReactions,
  Avatar as DefaultAvatar,
  EditMessageForm as DefaultEditMessageForm,
  MessageInput,
  MML,
  Modal,
  ReactionsList as DefaultReactionList,
  ReactionSelector as DefaultReactionSelector,
  useComponentContext,
  useMessageContext
} from 'stream-chat-react';
import { compose, pathOr } from 'ramda';
import { Check } from 'react-feather';
import { sizing } from '../../components';
import { connect } from 'react-redux';

/**
 * This file is a copy of Stream Component MessageSimple adapted to our requirement.
 * Keep custom modifications to the minimum for future compatibility!
 * See more here: https://github.com/GetStream/stream-chat-react/blob/master/src/components/Message/MessageSimple.tsx
 */

const MessageSimpleWithContext = compose(
  sizing(),
  connect((state) => ({
    user: pathOr({}, ['user', 'data'], state)
  }))
)(
  // eslint-disable-next-line complexity
  ({
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleOpenThread,
    handleRetry,
    isMyMessage,
    isReactionEnabled,
    message,
    onUserClick,
    onUserHover,
    reactionSelectorRef,
    showDetailedReactions,
    threadList,
    lastReceivedId,
    user,
    readBy = []
  }) => {
    const {
      Attachment,
      Avatar = DefaultAvatar,
      EditMessageInput = DefaultEditMessageForm,
      MessageDeleted = DefaultMessageDeleted,
      MessageOptions = DefaultMessageOptions,
      MessageRepliesCountButton = DefaultMessageRepliesCountButton,
      MessageTimestamp = DefaultMessageTimestamp,
      ReactionSelector = DefaultReactionSelector,
      ReactionsList = DefaultReactionList
    } = useComponentContext();

    const messageWrapperRef = useRef(null);

    const hasAttachment = messageHasAttachments(message);
    const hasReactions = messageHasReactions(message);

    const messageClasses = isMyMessage()
      ? 'str-chat__message str-chat__message--me str-chat__message-simple str-chat__message-simple--me'
      : 'str-chat__message str-chat__message-simple';

    if (message.customType === 'message.date') {
      return null;
    }

    if (message.deleted_at || message.type === 'deleted') {
      return <MessageDeleted message={message} />;
    }

    const showRead =
      pathOr('', ['user', 'id'], message) === user.id &&
      !threadList &&
      !!readBy.map((item) => item.id).filter((item) => item !== user.id).length;

    const showSend =
      !showRead &&
      pathOr('', ['user', 'id'], message) === user.id &&
      message.status === 'received' &&
      message.id === lastReceivedId &&
      !threadList;

    return (
      <>
        {editing && (
          <Modal onClose={clearEditingState} open={editing}>
            <MessageInput
              clearEditingState={clearEditingState}
              Input={EditMessageInput}
              message={message}
              {...additionalMessageInputProps}
            />
          </Modal>
        )}
        {
          <div
            className={`
						${messageClasses}
						str-chat__message--${message.type}
						str-chat__message--${message.status}
						${message.text ? 'str-chat__message--has-text' : 'has-no-text'}
						${hasAttachment ? 'str-chat__message--has-attachment' : ''}
            ${hasReactions && isReactionEnabled ? 'str-chat__message--with-reactions' : ''}
            ${message.pinned ? 'pinned-message' : ''}
            ${groupedByUser ? 'str-chat__virtual-message__wrapper--group' : ''}
            ${firstOfGroup ? 'str-chat__virtual-message__wrapper--first' : ''}
            ${endOfGroup ? 'str-chat__virtual-message__wrapper--end' : ''}
					`.trim()}
            key={message.id}
            ref={messageWrapperRef}>
            {message.user && (
              <Avatar
                image={message.user.image}
                name={message.user.name || message.user.id}
                onClick={onUserClick}
                onMouseOver={onUserHover}
                user={message.user}
              />
            )}
            <div
              className="str-chat__message-inner"
              data-testid="message-inner"
              onClick={
                message.status === 'failed' && message.errorStatusCode !== 403 ? () => handleRetry(message) : undefined
              }>
              <>
                <MessageOptions messageWrapperRef={messageWrapperRef} />
                {hasReactions && !showDetailedReactions && isReactionEnabled && <ReactionsList reverse />}
                {showDetailedReactions && isReactionEnabled && <ReactionSelector ref={reactionSelectorRef} />}
              </>

              <MessageText message={message} />

              {pathOr(0, ['attachments', 'length'], message) && !message.quoted_message ? (
                <Attachment actionHandler={handleAction} attachments={message.attachments} />
              ) : null}

              {message.mml && (
                <MML actionHandler={handleAction} align={isMyMessage() ? 'right' : 'left'} source={message.mml} />
              )}

              {!threadList && !!message.reply_count && (
                <div className="str-chat__message-simple-reply-button">
                  <MessageRepliesCountButton onClick={handleOpenThread} reply_count={message.reply_count} />
                </div>
              )}
              {(!groupedByUser || endOfGroup) && (
                <div className={'str-chat__message-data str-chat__message-simple-data'}>
                  {!isMyMessage() && message.user ? (
                    <span className="str-chat__message-simple-name">{message.user.name || message.user.id}</span>
                  ) : null}
                  {showRead && (
                    <div className="reade-holder">
                      <Check size={16} color="#00AEA9" />
                      <Check size={16} color="#00AEA9" />
                    </div>
                  )}
                  {showSend && (
                    <div className="reade-holder">
                      <Check size={16} color="#666666" />
                    </div>
                  )}
                  <MessageTimestamp calendar customClass="str-chat__message-simple-timestamp" />
                </div>
              )}
            </div>
          </div>
        }
      </>
    );
  }
);

const MessageSimple = (props) => {
  const messageContext = useMessageContext();

  return <MessageSimpleWithContext {...messageContext} {...props} />;
};

export default MessageSimple;
