import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector, propTypes as reduxFormPropTypes, change } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, string } from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import { Info } from 'react-feather';
import { capitalize, required, positive } from '../../../utils';
import { translate } from '../../../translate/i18n';
import {
  Button,
  Container,
  Row,
  Col,
  StyledForm,
  TextInput,
  SelectorDiscountUnitTypeForm,
  Text
} from '../../../components';
import { discountBounds, limitMonthCount, limit100Percent } from '../../validation';
import { pathOr } from 'ramda';

const DiscountEditForm = ({ t, submiting, handleSubmit, submitSucceeded, type, amount, lease, onClose, dispatch }) => {
  const [discountValidate, setDiscountValidate] = useState([required]);

  const { rentAmount } = lease;
  const currencySymbol = pathOr('--', ['room', 'house', 'hub', 'currency', 'symbol'], lease);

  const limitPercentage = limit100Percent(type);

  useEffect(() => {
    setDiscountValidate([required, positive, discountBounds({ incomingPrice: +rentAmount })]);
  }, [lease]);
  useEffect(() => {
    if (type === 'percent' && amount >= 100) {
      dispatch(change('DiscountEditForm', 'amount', 99));
    }
  }, [type]);

  useEffect(() => {
    if (submitSucceeded) {
      onClose && onClose();
    }
  }, [submitSucceeded]);

  const [confirmed, setConfirmed] = useState(false);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Row middle mb left>
        <Col xl={3} lg={3} md={6} sm={12} pr-quarter pl-quarter>
          <Field
            validate={[required, positive]}
            name="duration"
            type="number"
            label={t('DURATION')}
            Icon={() => (
              <div style={{ height: '14px', marginBottom: '4px', paddingLeft: '4px' }}>
                <Tooltip content={t('TOOLTIP_NEXT_MONTH_START')} direction="up">
                  <Info size={14} />
                </Tooltip>
              </div>
            )}
            component={TextInput}
            normalize={limitMonthCount}
          />
        </Col>

        <Col xl={3} lg={3} md={6} sm={12} pr-quarter pl-quarter>
          <Field
            validate={discountValidate}
            name="amount"
            type="number"
            label={t('AMOUNT')}
            component={TextInput}
            normalize={limitPercentage}
          />
        </Col>

        <Col xl={3} lg={3} md={6} sm={12} pr-quarter pl-quarter>
          <Field name="type" component={SelectorDiscountUnitTypeForm} label={t('UNIT_TYPE')} />
        </Col>

        {type === 'percent' && (
          <Col xl={3} lg={3} md={6} sm={12} p-quarter>
            <Row middle left>
              <Text weight={300} grey={100} size="md" pl-half type="container" noWrap={false}>
                {((+rentAmount * +amount) / 100).toFixed(2)} {currencySymbol}
              </Text>
            </Row>
          </Col>
        )}
      </Row>

      <Row middle>
        <Col xl={9} lg={9} md={12} sm={12} mt>
          <Container middle>
            <input type="checkbox" checked={confirmed} onChange={() => setConfirmed(!confirmed)} />
            <Text center weight={300} size="md" pl-quarter pr-half type="container" noWrap={false}>
              {t('DISCOUNT_CREATE_CONFIRMATION')}
            </Text>
          </Container>
        </Col>

        <Col xl={3} lg={3} md={12} sm={12} mt={!confirmed}>
          {confirmed ? (
            <Button
              right
              primary
              bg-primary
              onClick={() => {}}
              size="md"
              name="save"
              type="submit"
              disabled={!confirmed || submiting}>
              {capitalize(t('default:SAVE'))}
            </Button>
          ) : (
            <Tooltip className="discount-tooltip" content={t('discounts:AGREE_WITH_TERMS')} direction="down">
              <div className="disabled-placeholder">{capitalize(t('default:SAVE'))}</div>
            </Tooltip>
          )}
        </Col>
      </Row>
    </StyledForm>
  );
};

DiscountEditForm.propTypes = {
  ...reduxFormPropTypes,
  incomingPrice: string,
  t: func
};

const selector = formValueSelector('DiscountEditForm');
export default compose(
  connect((state) => {
    const { type, amount } = selector(state, 'type', 'amount');

    return {
      type,
      amount
    };
  }),
  reduxForm({
    form: 'DiscountEditForm',
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  translate('discounts')
)(DiscountEditForm);
