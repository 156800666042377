import * as api from '@cohabs/bo-api';
import { defaultPaginationLimit } from '../utils';

export const getPacks = ({ page = 1, limit = defaultPaginationLimit, criteria = {}, order = [] }) => {
  if (page < 1) page = 1;

  return api.post(`/packs/location/${page}/${limit}`, { criteria: { ...criteria, activeSubscriptions: true }, order });
};

export const getPack = (id) => api.get(`/pack/${id}/locations`);

export const duplicatePack = (pack) => api.post('/pack/duplicate', pack);
export const createPack = (pack) => api.post('/pack', pack);
export const updatePack = (pack) => api.put(`/pack/${pack.id}`, pack);
export const upsertPack = (pack) => (!pack.id ? createPack(pack) : updatePack(pack));
