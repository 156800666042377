import React from 'react';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';
import SelectorTipsPriority from '../selector/Selector.tipspriority';

const SelectorTipsPriorityForm = ({ t, input, label, isDisabled, placeholder, meta: { touched, error } }) => (
  <InputContainer>
    <SelectorTipsPriority label={label} placeholder={placeholder} {...input} isInForm={true} isDisabled={isDisabled} />

    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectorTipsPriorityForm);
