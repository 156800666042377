import React, { useEffect, useState } from 'react';
import Selector from './Selector';
import { func, string, array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, compose, prop } from 'ramda';
import { emptyArray, makeOptions } from '../../utils';
import { getCurrencies } from '../../enum/enum.actions';
import config from '../../config';

const SelectorCurrency = (props) => {
  const {
    getCurrencies,
    list,
    name,
    onChange,
    isMulti,
    label,
    value,
    isInForm,
    isDisabled,
    placeholder,
    paymentGatewayId,
    notEmpty
  } = props;

  const [options, setOptions] = useState([]);

  const handleChange = (val) => {
    return onChange(val);
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  useEffect(() => {
    let enums = list.map((item) => ({
      code: prop('code', item),
      label: `${prop('code', item).toUpperCase()} (${prop('symbol', item)})`
    }));

    const currency = pathOr(null, ['paymentGateway', 'currency', paymentGatewayId], config);

    if (paymentGatewayId && currency) {
      enums = enums.filter((item) => currency.includes(item.code));
    }

    if (notEmpty && enums && enums.length) {
      handleChange(enums[0].code);
    }

    setOptions(makeOptions(enums, 'code', 'label'));
  }, [list, paymentGatewayId]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

SelectorCurrency.propTypes = {
  isInForm: bool,
  name: string.isRequired,
  list: array,
  getCurrencies: func,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  placeholder: string,
  isFetching: bool,
  t: func
};

SelectorCurrency.defaultValues = {
  isInForm: false
};

export default compose(
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'currencies', 'list'], state),
      isFetching: pathOr(false, ['enums', 'currencies', 'isFetching'], state)
    }),
    { getCurrencies }
  )
)(SelectorCurrency);
