/* eslint-disable complexity */
import React, { PureComponent } from 'react';
import {
  Text,
  Loader,
  Row,
  Back,
  Col,
  FormItem,
  Container,
  Button,
  Modal,
  SelectorSwitchType,
  sizing,
  Toggle
} from '../components';
import { func, object, bool, number } from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose, path, propOr, propEq, pathOr, prop } from 'ramda';
import { translate } from '../translate/i18n';
import { fetchApplicant, updateApplicant, getApplicationFee, getValidateApplicationFee } from './applications.actions';
import { connect } from 'react-redux';
import { PhoneCall } from 'react-feather';
import ActionButtons from './Application.actionButtons';
import ApplicationRoom from './Application.rooms';
import ApplicationVerification from './Application.verifications';
import { formatDate, formatPhone, emptyObject, upperCase, localDateToHubInUtcFormat } from '../utils';
import ActivityLog from './Application.activityLog';
import ApplicantForm from './Applicant.form';
import PersonalInfos from './Application.personalInfos';
import { DateFormItem } from '../components/date';
import { spacing } from '../theme/spacing';
import ApplicationCallStatusButtons from './Application.callStatus';
import styled from '@emotion/styled';

const LoaderContainer = styled('div')(() => ({
  margin: 'auto'
}));

class Application extends PureComponent {
  static propTypes = {
    t: func,
    windowWidth: number,
    match: object,
    fetchApplicant: func,
    getApplicationFee: func,
    applicationFee: object,
    applicant: object,
    isFetching: bool,
    updateApplicant: func,
    isUpdating: bool,
    hasError: bool,
    user: object,
    isUpdatingInfo: bool,
    errorInfo: bool,
    getValidateApplicationFee: func,
    optionalFee: object
  };

  state = { showModalApplication: false, feeAppliesToogleLoaded: false };

  componentDidMount() {
    const { fetchApplicant, getApplicationFee, getValidateApplicationFee } = this.props;
    const applicantId = path(['props', 'match', 'params', 'id'], this);

    fetchApplicant(applicantId);
    getApplicationFee(applicantId);
    getValidateApplicationFee(applicantId);
  }

  handleClose() {
    alert('close');
    this.setState({ showModalApplication: false });
  }

  showApplicationVerifications() {
    const availableInStatuses = ['reviewing', 'approved', 'partially_approved', 'rejected'];
    const { employment, residency } = this.props.user;
    const { kycStatus } = this.props.applicant;

    return employment && residency && availableInStatuses.includes(kycStatus);
  }

  referral = (referrer) => {
    const t = this.props.t;

    return (
      referrer && (
        <Link to={`/members/${referrer.id}`}>
          <FormItem label={t('REFERRAL')} value={referrer && referrer.firstName} />
        </Link>
      )
    );
  };

  handleFeeAppliesChange(checked) {
    const { updateApplicant, applicant } = this.props;

    const { feeAppliesToogleLoaded } = this.state;

    if (feeAppliesToogleLoaded) {
      updateApplicant({ ...applicant, feeApplies: checked });
    }
  }

  renderFee() {
    const { t, applicant, applicationFee, optionalFee } = this.props;

    if (!applicationFee || !applicationFee.total) {
      if (!optionalFee) {
        return null;
      }
      return (
        <Row middle>
          <Col xl={4} lg={4} md={4} sm={6} mb>
            <Container column mb-half>
              <Text grey={400} size="xs" weight={300} pb-eighth labelSpacing>
                {upperCase(t('details.APPLICATION_FEES'))}
              </Text>
              <div style={{ padding: '6px' }}>
                <Toggle
                  label={t('FEE_APPLIES')}
                  input={{ checked: applicant.feeApplies, onChange: () => null }}
                  onCheck={(checked) => this.handleFeeAppliesChange(checked)}
                  onLoad={() => this.setState({ feeAppliesToogleLoaded: true })}
                  name="feeAppies"
                  storeName="Applications"
                />
              </div>
            </Container>
          </Col>
        </Row>
      );
    }

    const { total } = applicationFee;

    let due = 0;
    if (applicationFee && applicationFee.payment) {
      due = (applicationFee.payment.amount - applicationFee.payment.paid).toFixed(2);
    }

    const currency = pathOr('', ['hub', 'currency', 'symbol'], applicant);

    return (
      <Row middle>
        <Col xl={4} lg={4} md={4} sm={6} mb>
          <Container column mb-half>
            <Text grey={400} size="xs" weight={300} pb-eighth labelSpacing>
              {upperCase(t('details.APPLICATION_FEES'))}
            </Text>
            <Text weight={300} grey={500}>
              {`${currency} ${total.toFixed(2)} `}(
              {due ? <span style={{ color: 'red' }}>{`${currency} ${due}`}</span> : 'paid'})
            </Text>
          </Container>
        </Col>
      </Row>
    );
  }

  render() {
    const { t, applicant, isFetching, updateApplicant, user, windowWidth } = this.props;

    const { showModalApplication } = this.state;
    if (isFetching) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    }
    const logs = propOr([], 'application_logs', applicant);
    const firstLog = logs[0] || {};

    const colView = windowWidth > spacing.breakpoints.lg;

    const formattedPhone = formatPhone(prop('phoneNumber', user), pathOr('', ['phonePrefix', 'prefix'], user));

    return (
      <Row>
        <Col xl={9} lg={8} md={12} sm={12} pl pr pt style={colView ? { height: '100vh', overflow: 'auto' } : {}}>
          <Row middle>
            <Col xl={4} lg={6} md={6} sm={6} pb>
              <Row middle left>
                <Back mb-double to="/applications" label={t('BACK_TO_APPLICATIONS_LIST')} />
              </Row>
            </Col>
            <Col xl={3} lg={6} md={6} sm={6} pb>
              <SelectorSwitchType
                name="applicationsSwitchType"
                onChange={(switchType) => {
                  if (switchType !== applicant.switchType) updateApplicant({ ...applicant, switchType });
                }}
                autoSelect
                isClearable={false}
                value={applicant.switchType}
                storeName="Applications"
              />
            </Col>
            <Col xl={5} lg={12} md={12} sm={12} pb>
              <Row middle right>
                <a href={formattedPhone ? `tel:${formattedPhone}` : null}>
                  <Button name="callApplicant" disabled={!formattedPhone} primary>
                    <Container middle>
                      <Text pr-half grey weight={300}>
                        {t('CALL_APPLICANT')}
                      </Text>
                      <PhoneCall size={14} />
                    </Container>
                  </Button>
                </a>
                <Button ml name="edit" bg-secondary onClick={() => this.setState({ showModalApplication: true })}>
                  <Text weight={300} white>
                    {t('default:EDIT')}
                  </Text>
                </Button>
              </Row>
            </Col>
          </Row>

          <Row mt mb>
            <Col lg={12}>
              <Row middle pb>
                <Text grey={500} size="lg" weight={300} pr>
                  {t('details.APPLICATION_SECTION')}
                </Text>
                <Modal
                  title={t('details.APPLICATION_SECTION')}
                  show={showModalApplication}
                  onClose={this.handleClose.bind(this)}
                  content={() => (
                    <ApplicantForm
                      initialValues={applicant}
                      onSubmit={(applicant) => {
                        updateApplicant({
                          ...applicant,
                          moveinDate: localDateToHubInUtcFormat(applicant.moveinDate, applicant.timezone),
                          moveoutDate: localDateToHubInUtcFormat(applicant.moveoutDate, applicant.timezone)
                        });
                        this.setState({ showModalApplication: false });
                      }}
                      onClose={this.handleClose.bind(this)}
                    />
                  )}
                />
              </Row>
              <Row middle>
                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <FormItem label={t('STATUS')} value={applicant.status} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <DateFormItem
                    label={t('APPLICATION_DATE')}
                    date={applicant.createdAt}
                    timezone={applicant.timezone}
                  />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <FormItem label={t('NUMBER_OF_PEOPLE')} value={applicant.peopleNumber} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <DateFormItem label={t('DATE_MOVE_IN')} date={applicant.moveinDate} timezone={applicant.timezone} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <DateFormItem label={t('DATE_MOVE_OUT')} date={applicant.moveoutDate} timezone={applicant.timezone} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <FormItem label={t('DURATION_STAY')} value={applicant.duration} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <FormItem label={t('SCORE')} value={applicant.score} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <FormItem label={t('APPLIED_FROM')} value={applicant.houseFrom} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <DateFormItem label={t('CALL')} date={applicant.callDate} showTime={true} />
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  {this.referral(applicant.referrer)}
                </Col>

                <Col xl={4} lg={4} md={4} sm={6} mb>
                  <FormItem label={t('COZINESS')} value={applicant.coziness && t(applicant.coziness.toUpperCase())} />
                </Col>

                <Col xl={8} lg={8} md={12} sm={12} mb>
                  <FormItem
                    label={t('HOUSES')}
                    value={Array.isArray(applicant.houses) && applicant.houses.map((h) => h.name).join(', ')}
                  />
                </Col>
              </Row>

              <Row middle>
                {propEq('newStatus', 'sent', firstLog) && (
                  <Col lg={4} mb>
                    <FormItem label={t('OFFER_SENT')} value={formatDate(firstLog.createdAt)} />
                  </Col>
                )}
              </Row>
              <Row middle>
                <FormItem label={t('COMMENTS')} value={applicant.comment} />
              </Row>
            </Col>
          </Row>
          {this.renderFee()}
          <Row>
            <Row mb left>
              <Col lg={5} pt-half pr-half pb-half>
                <Text size="lg" weight={300} pr-half>
                  Call status: {applicant.callStatus && t('CALL_STATUS_' + applicant.callStatus.toUpperCase())}
                </Text>
              </Col>
            </Row>
            <Row mb left>
              <Col lg={5} pt-half pr-half pb-half>
                <ApplicationCallStatusButtons status={applicant.callStatus} />
              </Col>
            </Row>
          </Row>
          <ActionButtons status={applicant.status} />
          {this.showApplicationVerifications() && (
            <ApplicationVerification
              application={applicant}
              applicationId={applicant.id}
              user={applicant.user}
              // disabled={applicant.status !== 'reviewing kyc'}
              status={applicant.status}
              applicant={applicant}
            />
          )}
          <ApplicationRoom />
          <Row>
            <Col lg={12} mt>
              <Text grey={500} size="lg" weight={300}>
                {t('details.ACTIVITY_SECTION')}
              </Text>
              <ActivityLog logs={logs} />
            </Col>
          </Row>
        </Col>

        <Col xl={3} lg={4} md={12} sm={12}>
          <PersonalInfos />
        </Col>
      </Row>
    );
  }
}

export default compose(
  withRouter,
  sizing(),
  translate('applications'),
  connect(
    (state) => ({
      applicationFee: path(['applications', 'currentFee', 'data'], state),
      applicant: path(['applications', 'current', 'data'], state),
      isFetching: !!path(['applications', 'current', 'isFetching'], state),
      isUpdating: !!path(['applications', 'current', 'isUpdating'], state),
      isUpdatingInfo: !!path(['applications', 'current', 'isUpdatingInfo'], state),
      errorInfo: !!path(['applications', 'current', 'errorInfo'], state),
      user: pathOr(emptyObject, ['applications', 'current', 'data', 'user'], state),
      optionalFee: path(['applications', 'optionalFee', 'data'], state)
    }),
    { fetchApplicant, updateApplicant, getApplicationFee, getValidateApplicationFee }
  )
)(Application);
