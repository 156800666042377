import React, { useEffect, useState, Fragment } from 'react';
import { object, func, array, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { pathOr, path, isNil, propOr, prop } from 'ramda';
import { toast, capitalize, valueOrPlaceholder, isNotEmptyOrNil, formatDate } from '../utils';
import { translate } from '../translate/i18n';
import { Container, Loader, Back, Text, Toggle, Row, ScrollableDataTable } from '../components';
import { getLease, getLeaseDocuments } from './leases.actions';
import { updateLateFees } from './leases.service';

const getColumnDefs = (t) => [
  {
    headerName: capitalize(t('Lease')),
    field: 'name'
  },
  {
    headerName: capitalize(t('Uploaded')),
    field: 'uploaded',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDate)(prop('value', col), col.data.timezone),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value)
  },
  {
    headerName: capitalize(t('Version')),
    field: 'version',
    width: 75
  }
];

const Lease = (props) => {
  const { match, lease, documents, getLease, getLeaseDocuments, isFetchingDocuments, isFetchingLease, t } = props;
  const [hasLateFees, setLateFees] = useState(false);
  const [documentSelectedIndex, setDocumentSelectedIndex] = useState(0);

  const handleToggle = (checked) => {
    setLateFees(checked);
    updateLateFees(lease.id, checked);
  };

  useEffect(() => {
    const id = path(['params', 'id'], match);
    if (!id) return;

    getLeaseDocuments(id);
  }, [match]);

  useEffect(() => {
    const id = path(['params', 'id'], match);
    if (!id) return toast.error(t('GET_LEASE_ERROR'));
    getLease(id);
  }, [match]);

  useEffect(() => {
    setLateFees(propOr(false, 'hasLateFees', lease));
  }, [lease.id]);

  const handleSelectDocument = (event) => setDocumentSelectedIndex(event.rowIndex);

  const renderContent = () => {
    if (isFetchingDocuments || isFetchingLease) {
      return <Loader />;
    }

    const emptyLeaseDocuments = isNil(documents) || documents.length === 0;

    if (emptyLeaseDocuments) {
      return <Text>{t('NO_LEASE_DOCUMENTS')}</Text>;
    }

    const document = propOr(null, documentSelectedIndex, documents);
    const leaseUrl = pathOr(null, ['file', 'url'], document);

    return (
      <Fragment>
        <Row middle pt pb>
          <Toggle
            label={t('HAS_LATE_FEES')}
            onCheck={handleToggle}
            input={{ checked: hasLateFees, onChange: () => null }}
          />
        </Row>

        <ScrollableDataTable
          columnDefs={getColumnDefs(t)}
          defaultColDef={{ onCellClicked: handleSelectDocument }}
          getMethod={() => {}}
          appendMethod={() => {}}
          rows={documents}
          rowCount={documents.length}
        />

        {document && leaseUrl && (
          <Fragment>
            <Row mt pt pb>
              <Text>{document.name}</Text>
            </Row>

            <object title="Lease" style={{ width: '100%', height: '100%' }} type="application/pdf" data={leaseUrl}>
              <Text>{t('NO_PDF_PLUGIN')}</Text>
              <a className="btn" href={leaseUrl} target="_blank" rel="noopener noreferrer">
                {t('OPEN_MY_LEASE')}
              </a>
            </object>
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Container column full p>
        <Container mb>
          <Back to="/leases" />
        </Container>

        {renderContent()}
      </Container>
    </Fragment>
  );
};

Lease.propTypes = {
  lease: object.isRequired,
  documents: array,
  match: object,
  getLease: func.isRequired,
  getLeaseDocuments: func.isRequired,
  isFetchingDocuments: bool.isRequired,
  isFetchingLease: bool.isRequired,
  t: func.isRequired
};

export default compose(
  translate(['leases']),
  connect(
    (state) => ({
      lease: pathOr({}, ['leases', 'current', 'data'], state),
      isFetchingLease: pathOr(false, ['leases', 'current', 'isFetching'], state),
      isFetchingDocuments: pathOr(false, ['leases', 'documents', 'isFetching'], state),
      documents: pathOr([], ['leases', 'documents', 'list'], state)
    }),
    { getLease, getLeaseDocuments }
  )
)(Lease);
