import { always, assoc, compose, has, ifElse, omit, path, pathOr, prop, propOr } from 'ramda';
import { SubmissionError } from 'redux-form';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as applicationsService from '../apis/applications';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import * as userService from '../user/user.service';
import { handleFormError } from '../utils';
import { localDateToHubInUtcFormat } from '../utils/date';
import { types } from '../utils/sagas';
import {
  addNewRoomFail,
  addNewRoomSuccess,
  appendListApplicationsFail,
  appendListApplicationsSuccess,
  deleteAvailableRoomsFail,
  deleteAvailableRoomsSuccess,
  fetchApplicantFail,
  fetchApplicantSuccess,
  getApplicationFee,
  getListApplicationsFailure,
  getUserDocuments as getUserDocumentsAction,
  getUserDocumentsFail,
  getUserDocumentsSuccess,
  getVerifications,
  getVerificationsFail,
  getVerificationsSuccess,
  refreshApplicant as refreshApplicantAction,
  sendRemindersRoomsFail,
  setApplicationFee,
  setFilteredApplicationsFailure,
  setFilteredApplicationsSuccess,
  setKanbanApplicants,
  setListApplications,
  updateApplicantFail,
  updateApplicantInfo,
  updateApplicantSuccess,
  updateApplicationVerificationFail,
  updateApplicationVerificationSuccess,
  setValidateApplicationFee
} from './applications.actions';
import {
  ADD_NEW_ROOM,
  APPEND_APPLICATIONS,
  DELETE_APPLICATION_VERIFICATION,
  DELETE_AVAILABLE_ROOMS,
  DELETE_USER_DOCUMENTS,
  FETCH_APPLICANT,
  GET_APPLICATIONS,
  GET_APPLICATION_FEE,
  GET_APPLICATION_VERIFICATIONS,
  GET_FILTERED_APPLICATIONS,
  GET_KANBAN_APPLICANTS,
  GET_USER_DOCUMENTS,
  ON_DROP,
  REFRESH_APPLICANT,
  SEND_OFFERS_ROOM,
  SEND_REMINDERS,
  UPDATE_APPLICANT,
  UPDATE_APPLICANT_CALL_STATUS,
  UPDATE_APPLICANT_KYC_STATUS,
  UPDATE_APPLICANT_STATUS,
  UPDATE_APPLICATION_VERIFICATION,
  GET_VALIDATE_APPLICATION_FEE
} from './applications.actionTypes';
import * as service from './applications.service';

export function* getKanbanApplicants(action) {
  const payload = propOr({}, 'payload', action);
  try {
    const applicants = yield call(service.getKanbanApplicants, { criteria: { ...payload } });
    yield put(setKanbanApplicants(applicants));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.APPLICATION, error: err, data: payload }));
  }
}

export function* getFilteredApplications(action) {
  const payload = propOr({}, 'payload', action);

  try {
    const applications = yield call(applicationsService.getFilteredApplications, payload);
    yield put(setFilteredApplicationsSuccess(applications));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.APPLICATION, error: err, data: payload }));
    yield put(setFilteredApplicationsFailure(err));
  }
}

export function* getListApplicants(action) {
  const payload = propOr({}, 'payload', action);
  try {
    const applications = yield call(service.getListApplicants, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(setListApplications(applications));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.APPLICATION, error: err, data: payload }));
    yield put(getListApplicationsFailure(err));
  }
}

export function* appendListApplicants(action) {
  const payload = propOr({}, 'payload', action);
  try {
    const applications = yield call(service.getListApplicants, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(appendListApplicationsSuccess(applications));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.APPLICATION, error: err, data: payload }));
    yield put(appendListApplicationsFail(err));
  }
}

export function* onDropApplicant(action) {
  const { item, dropSection } = action.payload;
  try {
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.APPLICATION }));
    yield call(service.updateAplicant, { ...item, status: dropSection });
  } catch (err) {
    yield put(handleErrorMessage('UPDATE.FAILURE', { type: types.APPLICATION, error: err, data: action.payload }));
  }
}

export function* fetchApplicationFee(action) {
  try {
    const applicantId = prop('payload', action);
    const applicantFee = yield call(service.getApplicationFee, applicantId);
    yield put(setApplicationFee(applicantFee));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.APPLICATION_FEE, error: err, data: action.payload }));
  }
}

export function* fetchApplicant(action) {
  try {
    const applicantId = prop('payload', action);
    const applicant = yield call(service.getApplicant, applicantId);
    yield put(fetchApplicantSuccess(applicant));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.APPLICATION, error: err, data: action.payload }));
    yield put(fetchApplicantFail(err));
  }
}

export function* refreshApplicant() {
  let applicantId;
  try {
    applicantId = yield select((state) => path(['applications', 'current', 'data', 'id'], state));
    const applicant = yield call(service.getApplicant, applicantId);
    yield put(fetchApplicantSuccess(applicant));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.APPLICATION, error: err, data: applicantId }));
    yield put(fetchApplicantFail(err));
  }
}

export function* updateApplicant(action) {
  const applicant = prop('payload', action);
  try {
    const _applicant = yield call(service.updateAplicant, applicant);
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.APPLICATION }));
    yield put(updateApplicantSuccess(_applicant));
    if (applicant.makeNewFee) {
      yield call(service.updateAplicant, applicant);
      put(getApplicationFee(_applicant.id));
    }
    try {
      const validateApplicantFee = yield call(service.getValidateApplicationFee, _applicant.id);
      yield put(setValidateApplicationFee(validateApplicantFee));
    } catch (err) {
      yield put(setValidateApplicationFee(false));
    }
  } catch (err) {
    yield put(handleErrorMessage('UPDATE.FAILURE', { type: types.APPLICATION, error: err, data: applicant }));
    yield put(updateApplicantFail(err));
  }
}

export function* sendReminders(action) {
  try {
    const params = propOr({}, 'payload', action);
    const applicationId = prop('applicationId', params);

    yield call(service.sendReminders, params);

    const application = yield call(service.getApplicant, applicationId);
    yield put(handleSuccessMessage('REMINDER.SUCCESS', { type: types.APPLICATION }));
    yield put(fetchApplicantSuccess(application));
  } catch (err) {
    yield put(handleErrorMessage('REMINDER.FAILURE', { type: types.APPLICATION, error: err, data: action.payload }));
    yield put(sendRemindersRoomsFail(err));
  }
}

export function* sendOffers(action) {
  try {
    const params = propOr({}, 'payload', action);
    const applicationId = prop('applicationId', params);

    yield call(service.sendOffers, params);

    const application = yield call(service.getApplicant, applicationId);
    yield put(handleSuccessMessage('OFFER.SUCCESS', { type: types.APPLICATION }));
    yield put(fetchApplicantSuccess(application));
  } catch (err) {
    yield put(handleErrorMessage('OFFER.FAILURE', { type: types.APPLICATION, error: err, data: action.payload }));
    yield put(fetchApplicantFail(err));
  }
}

export function* deleteAvailableRooms(action) {
  try {
    const params = propOr({}, 'payload', action);
    yield call(service.deleteRooms, params);
    const roomIds = propOr([], 'roomIds', params);
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.ROOM_AVAILABLE }));
    yield put(deleteAvailableRoomsSuccess(roomIds));
    yield put(refreshApplicantAction());
  } catch (err) {
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.ROOM_AVAILABLE, error: err, data: action.payload }));
    yield put(deleteAvailableRoomsFail(err));
  }
}

export function* addNewRoom(action) {
  try {
    const params = propOr({}, 'payload', action);
    const roomList = yield call(service.addNewRoom, params);
    yield put(
      handleSuccessMessage(!params.isUpdate ? 'ADD.SUCCESS' : 'UPDATE.SUCCESS', { type: types.ROOM_AVAILABLE })
    );
    yield put(addNewRoomSuccess(roomList));
    yield put(refreshApplicantAction());
  } catch (err) {
    yield put(
      handleErrorMessage(!propOr({}, 'payload', action).isUpdate ? 'ADD.FAILURE' : 'UPDATE.FAILURE', {
        type: types.ROOM_AVAILABLE,
        error: err,
        data: action.payload
      })
    );
    yield put(addNewRoomFail(err));
  }
}

export const handleUser = compose(omit(['phone']), (user) => ({
  ...user,
  phoneNumber: pathOr('', ['phone', 'number'], user),
  prefixId: pathOr('', ['phone', 'prefix', 'value'], user)
}));
export const handlePhoneError = (error) =>
  ifElse(
    has('phoneNumber'),
    compose(omit(['phoneNumber']), assoc('phone', prop('phoneNumber', error))),
    always(error)
  )(error);

export function* _updateApplicantInfo(action) {
  try {
    const params = propOr({}, 'payload', action);
    const user = handleUser(params);
    const _user = yield call(userService.updateUser, {
      ...user,
      birthdate: localDateToHubInUtcFormat(user.birthdate, 'UTC')
    });
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.APPLICATION }));
    yield put(updateApplicantInfo.success(_user));
  } catch (err) {
    const formError = new SubmissionError(handlePhoneError(handleFormError(err)));
    yield put(updateApplicantInfo.failure(formError));
    yield put(handleErrorMessage('UPDATE.FAILURE', { type: types.APPLICATION, error: err, data: action.payload }));
  }
}

export function* getApplicationVerifications(action) {
  try {
    const applicationId = prop('payload', action);
    const verifications = yield call(service.getApplicationVerifications, applicationId);
    yield put(getVerificationsSuccess(verifications));
  } catch (err) {
    yield put(
      handleErrorMessage('GET.FAILURE', { type: types.APPLICATION_VERIFICATION, error: err, data: action.payload })
    );
    yield put(getVerificationsFail(err));
  }
}

export function* getUserDocuments(action) {
  try {
    const userId = prop('payload', action);
    const userDocuments = yield call(service.getUserDocuments, userId);
    yield put(getUserDocumentsSuccess(userDocuments));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.USER_DOCUMENT, error: err }));
    yield put(getUserDocumentsFail(err));
  }
}

export function* updateApplicationVerification(action) {
  try {
    const { requisiteId, applicationId } = prop('payload', action);
    const applicationVerification = yield call(service.updateApplicationVerification, requisiteId, applicationId);
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.APPLICATION_VERIFICATION }));
    yield put(updateApplicationVerificationSuccess(applicationVerification));
  } catch (err) {
    yield put(handleErrorMessage('UPDATE.FAILURE', { type: types.APPLICATION_VERIFICATION, error: err }));
    yield put(updateApplicationVerificationFail(err));
  }
}

export function* deleteApplicationVerification(action) {
  try {
    const { requisiteId, applicationId } = prop('payload', action);
    const applicationVerification = yield call(service.deleteApplicationVerification, requisiteId, applicationId);
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.APPLICATION_VERIFICATION }));
    yield put(updateApplicationVerificationSuccess(applicationVerification));
  } catch (err) {
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.APPLICATION_VERIFICATION, error: err }));
    yield put(updateApplicationVerificationFail(err));
  }
}

export function* deleteUserFile(action) {
  try {
    const { fileId, applicationId, userId } = prop('payload', action);
    yield call(service.deleteUserFile, fileId, userId);

    yield put(getVerifications(applicationId));
    yield put(getUserDocumentsAction(userId));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.USER_FILE }));
  } catch (err) {
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.USER_FILE, error: err }));
    yield put(updateApplicationVerificationFail(err));
  }
}

export function* fetchApplicationFeeValidation(action) {
  try {
    const applicantId = prop('payload', action);
    const validateApplicantFee = yield call(service.getValidateApplicationFee, applicantId);
    yield put(setValidateApplicationFee(validateApplicantFee));
  } catch (err) {
    yield put(setValidateApplicationFee(false));
  }
}

export default function* applicationSaga() {
  yield all([
    takeLatest(GET_APPLICATION_FEE, fetchApplicationFee),
    takeLatest(DELETE_USER_DOCUMENTS, deleteUserFile),
    takeLatest(GET_KANBAN_APPLICANTS, getKanbanApplicants),
    takeLatest(GET_FILTERED_APPLICATIONS, getFilteredApplications),
    takeLatest(ON_DROP, onDropApplicant),
    takeLatest(GET_APPLICATIONS, getListApplicants),
    takeLatest(APPEND_APPLICATIONS, appendListApplicants),
    takeLatest(FETCH_APPLICANT, fetchApplicant),
    takeLatest(REFRESH_APPLICANT, refreshApplicant),
    takeLatest(UPDATE_APPLICANT, updateApplicant),
    takeLatest(UPDATE_APPLICANT_STATUS, updateApplicant),
    takeLatest(UPDATE_APPLICANT_KYC_STATUS, updateApplicant),
    takeLatest(UPDATE_APPLICANT_CALL_STATUS, updateApplicant),
    takeLatest(SEND_REMINDERS, sendReminders),
    takeLatest(SEND_OFFERS_ROOM, sendOffers),
    takeLatest(DELETE_AVAILABLE_ROOMS, deleteAvailableRooms),
    takeLatest(ADD_NEW_ROOM, addNewRoom),
    takeLatest(updateApplicantInfo.REQUEST, _updateApplicantInfo),
    takeLatest(GET_APPLICATION_VERIFICATIONS, getApplicationVerifications),
    takeLatest(GET_USER_DOCUMENTS, getUserDocuments),
    takeLatest(UPDATE_APPLICATION_VERIFICATION, updateApplicationVerification),
    takeLatest(DELETE_APPLICATION_VERIFICATION, deleteApplicationVerification),
    takeLatest(GET_VALIDATE_APPLICATION_FEE, fetchApplicationFeeValidation)
  ]);
}
