import { func, object } from 'prop-types';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { Field, FormSection, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { Block, BottomContainer, Button, Col, Container, Row, TextInput } from '../components';
import StyledForm from '../components/form.styles';
import { fetchHubs } from '../hub/hub.actions';
import { translate } from '../translate/i18n';
import { capitalize, positiveOrNull } from '../utils';
import { upsertHouse } from './houses.actions';

const SpecificInfoForm = ({ t, history, handleSubmit, submitting, initialValues }) => (
  <Block>
    <StyledForm onSubmit={handleSubmit(upsertHouse)}>
      <FormSection name="info">
        <Container column pt-half pb-half pl pr>
          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="building"
                disabled
                component={TextInput}
                type="text"
                label={`${capitalize(t('TYPE_OF_HOUSE'))}`}
                placeholder={t('BUILDING')}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="cadastralReference"
                component={TextInput}
                type="text"
                label={`${capitalize(t('CADASTRAL_REFERENCE'))}`}
                placeholder={t('CADASTRAL_REFERENCE')}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="district"
                component={TextInput}
                type="text"
                label={`${capitalize(t('DISTRICT'))}`}
                placeholder={t('DISTRICT')}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="constructionPeriod"
                component={TextInput}
                type="text"
                label={`${capitalize(t('CONSTRUCT_PERIOD'))}`}
                placeholder={t('CONSTRUCT_PERIOD')}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="totalSurface"
                component={TextInput}
                type="number"
                label={`${capitalize(t('TOTAL_HOUSE_SURFACE'))}`}
                placeholder={t('TOTAL_HOUSE_SURFACE')}
                validate={[positiveOrNull]}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="sharedSurfaceArea"
                component={TextInput}
                type="number"
                label={`${capitalize(t('SHARED_SURFACE_AREA'))}`}
                placeholder={t('SHARED_SURFACE_AREA')}
                validate={[positiveOrNull]}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="numberOfFloors"
                component={TextInput}
                type="number"
                label={`${capitalize(t('NUMBER_OF_FLOORS'))}`}
                placeholder={t('NUMBER_OF_FLOORS')}
                validate={[positiveOrNull]}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="numberOfMainRooms"
                component={TextInput}
                type="number"
                label={`${capitalize(t('NUMBER_OF_MAIN_ROOMS'))}`}
                placeholder={t('NUMBER_OF_MAIN_ROOMS')}
                validate={[positiveOrNull]}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="referenceRentOfZone"
                component={TextInput}
                type="number"
                label={`${capitalize(t('REFERENCE_RENT_OF_THE_ZONE'))}`}
                placeholder={t('REFERENCE_RENT_OF_THE_ZONE')}
                validate={[positiveOrNull]}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="energyClass"
                component={TextInput}
                type="number"
                label={`${capitalize(t('HOUSE_ENERGY_CLASS'))}`}
                placeholder={t('HOUSE_ENERGY_CLASS')}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
              <Field
                name="insurance"
                component={TextInput}
                type="number"
                label={`${capitalize(t('HOUSE_INSURANCE'))}`}
                placeholder={t('HOUSE_INSURANCE')}
              />
            </Col>
          </Row>
        </Container>
      </FormSection>

      <BottomContainer>
        <Row right>
          <Button
            name="submit"
            disabled={submitting}
            clear
            size="md"
            onClick={() => history.push(`/houses/${initialValues.id}`)}>
            {t('form:CANCEL')}
          </Button>
          <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
            {t('form:SAVE')} {submitting ? '...' : ''}
          </Button>
        </Row>
      </BottomContainer>
    </StyledForm>
  </Block>
);

SpecificInfoForm.propTypes = {
  t: func,
  history: object,
  ...reduxFormPropTypes
};

export default compose(
  reduxForm({ form: 'HouseSpecificInfoForm' }),
  translate('house'),
  connect(null, { fetchHubs })
)(SpecificInfoForm);
