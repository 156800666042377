import React, { useState, Fragment } from 'react';
import { func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Row, Text, FreeTextInput, Button, Col } from '../components';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { translate } from '../translate/i18n';
import { capitalize } from '../utils';
import { createPaymentProfileMethod, updatePaymentProfileMethod } from './paymentProfileMethod.actions';
import DeleteSource from './paymentProfileMethod.deleteSource';

const PaymentProfileMethodSepaDebit = ({
  member,
  currentHub,
  currentProfile,
  hubMethod,
  profileMethod,
  createPaymentProfileMethodCall,
  updatePaymentProfileMethodCall,
  t
}) => {
  const [iban, setIBAN] = useState('');

  const create = () => {
    createPaymentProfileMethodCall({
      userId: member.id,
      hubId: currentHub.id,
      type: hubMethod.payment_method.type,
      iban: iban || null
    });

    setIBAN('');
  };

  const update = () => {
    updatePaymentProfileMethodCall({
      userId: member.id,
      hubId: currentHub.id,
      type: hubMethod.payment_method.type,
      iban: iban || null
    });

    setIBAN('');
  };

  return (
    <Fragment>
      {profileMethod && profileMethod.stripeData && (
        <Fragment>
          <Row>
            <Col xl={3} lg={3} md={3} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:profile.COUNTRY_CODE')}
              </Text>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.country}
              </Text>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:profile.BANK_CODE')}
              </Text>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.bankCode}
              </Text>
            </Col>
          </Row>

          <Row>
            {profileMethod.stripeData.iban ? (
              <Fragment>
                <Col xl={3} lg={3} md={6} sm={6} mb>
                  <Text size="md" grey={300} pr-half>
                    {t('form:IBAN')}
                  </Text>
                </Col>

                <Col xl={3} lg={3} md={6} sm={6} mb>
                  <Text size="md" weight={300} pr-half>
                    {profileMethod.stripeData.iban}
                  </Text>
                </Col>
              </Fragment>
            ) : (
              <Fragment>
                <Col xl={3} lg={6} md={6} sm={6} mb>
                  <Text size="md" grey={300} pr-half>
                    {t('member:profile.IBAN_LAST_4')}
                  </Text>
                </Col>

                <Col xl={3} lg={6} md={6} sm={6} mb>
                  <Text size="md" weight={300} pr-half>
                    {profileMethod.stripeData.last4}
                  </Text>
                </Col>
              </Fragment>
            )}

            <Col xl={6} lg={12} md={12} sm={12} mb>
              <Row right>
                <DeleteSource
                  member={member}
                  currentHub={currentHub}
                  currentProfile={currentProfile}
                  hubMethod={hubMethod}
                />
              </Row>
            </Col>
          </Row>
        </Fragment>
      )}

      <Row mb>
        <FreeTextInput
          label={capitalize(t('form:IBAN'))}
          name="iban"
          placeholder={t('form:IBAN')}
          value={iban}
          onChange={(e) => setIBAN(e.target.value)}
        />
      </Row>
      {profileMethod ? (
        <Row mb right>
          <Button size="md" name="btn-save" bg-primary onClick={update} mr-half>
            {t('default:UPDATE')}
          </Button>

          {!profileMethod.stripeData && (
            <DeleteSource
              member={member}
              currentHub={currentHub}
              currentProfile={currentProfile}
              hubMethod={hubMethod}
            />
          )}
        </Row>
      ) : (
        <Row mb right>
          <Button size="md" type="submit" name="btn-save" bg-primary mr-half onClick={create}>
            {t('default:SAVE')}
          </Button>
        </Row>
      )}
    </Fragment>
  );
};

PaymentProfileMethodSepaDebit.propTypes = {
  t: func,
  member: object.isRequired,
  currentHub: object.isRequired,
  currentProfile: object,
  hubMethod: object,
  profileMethod: object,
  createPaymentProfileMethodCall: func,
  updatePaymentProfileMethodCall: func
};

export default compose(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        createPaymentProfileMethodCall: createPaymentProfileMethod.request,
        updatePaymentProfileMethodCall: updatePaymentProfileMethod.request
      },
      dispatch
    )
  ),
  translate()
)(PaymentProfileMethodSepaDebit);
