import { TOGGLE_COLLAPSE_MENU, initState } from './actionTypes';
import { genState } from '../utils/reducer';

const stateDef = (state) => ({
  [TOGGLE_COLLAPSE_MENU]: (collapse) => ({
    ...state,
    collapse
  })
});

export default (state = initState, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef, type });
};
