import { takeLatest, call, all, put } from 'redux-saga/effects';
import { propOr, prop } from 'ramda';
import * as service from './packLocations.service';
import { GET_PACK_LOCATION } from './packLocations.actionTypes';
import { getPackLocationSuccess, getPackLocationFail, upsertPackLocation } from './packLocations.actions';
import { handleFormErrors } from '../../utils';
import { types } from '../../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../../toast/toast.actions';

export function* getPackLocation(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const pack = yield call(service.getPackLocation, payload);
    yield put(getPackLocationSuccess(pack));
  } catch (err) {
    yield put(getPackLocationFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.PACK_LOCATION, error: err, data: action.payload }));
  }
}

export function* _upsertPackLocation(action) {
  const payload = prop('payload', action);
  const isNew = prop('isNew', payload);
  const key = isNew ? 'CREATE' : 'UPDATE';

  try {
    const savedPackLocation = yield call(service.upsertPackLocation, payload);

    yield put(upsertPackLocation.success(savedPackLocation));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.PACK_LOCATION }));
  } catch (err) {
    yield put(upsertPackLocation.failure(handleFormErrors(err)));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.PACK_LOCATION, error: err, data: action.payload }));
  }
}

export default function* packsSaga() {
  yield all([
    takeLatest(GET_PACK_LOCATION, getPackLocation),
    takeLatest(upsertPackLocation.REQUEST, _upsertPackLocation)
  ]);
}
