import React, { useState, useEffect, Fragment } from 'react';
import { func, array } from 'prop-types';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { Row, Text, Modal } from '../components';
import { ImageContainer, StyledImage } from './Inventory.style';
import { upperCase } from '../utils';

const InventoryDetails = ({ t, list }) => {
  const [files, setFiles] = useState([]);
  const [picture, selectPicture] = useState(null);

  useEffect(() => {
    const view = list.reduce(
      (all, detail) =>
        all.concat(
          detail.inventoryDetailDocuments.map((item, index) => {
            let label = t(upperCase(`DEFECTS.${detail.type}`));
            if (detail.inventoryDetailDocuments.length > 1) {
              label = `${label} (${index + 1}/${detail.inventoryDetailDocuments.length})`;
            }

            return {
              ...item,
              label
            };
          })
        ),
      []
    );

    setFiles(view);
  }, [list]);

  if (!files.length) {
    return null;
  }

  return (
    <Fragment>
      <Row middle mb>
        <Text weight={300} size="lg">
          {t('PICTURES')}
        </Text>
      </Row>
      <Row middle mb>
        <div className="inventory-details-grid">
          {files.map((item) => (
            <div className="grid-item" key={item.id}>
              <img
                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                alt={item.label}
                src={item.link}
                onClick={() => selectPicture(item.link)}
              />

              <Row center mb>
                <Text weight={300} size="md">
                  {item.label}
                </Text>
              </Row>
            </div>
          ))}
        </div>
      </Row>

      <Modal
        full
        title={t('PICTURE')}
        isPicturePreview
        show={!!picture}
        onClose={() => selectPicture(null)}
        content={() => (
          <ImageContainer>
            <StyledImage src={picture} alt="picture" />
          </ImageContainer>
        )}
      />
    </Fragment>
  );
};

InventoryDetails.propTypes = {
  t: func,
  list: array
};

InventoryDetails.defaultProps = {
  list: []
};

export default compose(translate('inventories'))(InventoryDetails);
