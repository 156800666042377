import React, { useState, Fragment } from 'react';
import { func, object, array, number, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pathOr, prop, path } from 'ramda';
import { translate } from '../translate/i18n';

import { getContents, appendContents, deleteContents } from './cms.actions';
import { capitalize, valueOrPlaceholder, isNotEmptyOrNil, emptyArray, formatLocations, upperCase } from '../utils';
import { Row, Text, ActionSecondaryButton, ScrollableDataTableWithColSelector, ActionButton } from '../components';

import Filter from './Faq.filter';

const getColumnDefs = (t) => [
  {
    headerName: '',
    label: t('default:CHECKBOX'),
    width: 25,
    valueFormatter: () => null,

    headerComponentFramework: ({ api, column }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            isChecked ? api.deselectAll() : api.selectAll();
            setIsChecked(!isChecked);
            api.refreshCells({ columns: [column], force: true });
          }}
        />
      );
    },
    onCellClicked: () => null,

    cellRendererFramework: ({ node, api }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(node.isSelected());
      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!node.isSelected());
            node.setSelected(!node.isSelected());
            api.refreshHeader();
          }}
        />
      );
    }
  },
  {
    headerName: capitalize(t('LOCATIONS')),
    field: 'locations',
    defaultActive: true,
    width: 125,
    valueFormatter: formatLocations,
    sortable: false
  },
  {
    headerName: capitalize(t('CATEGORY')),
    field: 'category',
    defaultActive: true,
    valueFormatter: ({ value }) => t(`FAQ_CATEGORIES.${upperCase(value)}`),
    width: 125
  },
  {
    headerName: capitalize(t('SUBCATEGORY')),
    field: 'subcategory.name',
    valueFormatter: ({ value }) => (value ? t(`FAQ_SUBCATEGORIES.${upperCase(value)}`) : ''),
    width: 125
  },
  {
    headerName: capitalize(t('QUESTION')),
    defaultActive: true,
    field: 'question',
    width: 125
  },
  {
    headerName: capitalize(t('ANSWER')),
    field: 'answer',
    width: 500
  }
];

const Faq = (props) => {
  //Props
  const { history, t, faqs, faqCount, isFetching, appendContents, getContents, deleteContents, responseLength } = props;
  //State
  const [selectedContents, selectContents] = useState(emptyArray);
  const [filters, setFilters] = useState();

  const getFaqList = (params) => getContents({ ...params, contentType: 'faq' });
  const appendFaqRow = (params) => appendContents({ ...params, contentType: 'faq' });
  const deleteFaq = (contents) => deleteContents({ list: contents, contentType: 'faq' });

  const goToFaq = (event) => {
    history.push(`/cms/faq/${path(['data', 'id'], event)}`);
  };

  const onRowSelected = (event) => selectContents(event.api.getSelectedRows());
  const deleteSelection = () => {
    deleteFaq(selectedContents);
    selectContents(emptyArray);
  };

  //render
  return (
    <Fragment>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {t('FAQ')}
        </Text>
        {!selectedContents.length ? (
          <ActionButton icon="Plus" name="addFaq" onClick={() => history.push('/cms/faq/create')} />
        ) : (
          <ActionSecondaryButton name="deleteFaq" icon="Trash2" onClick={deleteSelection} danger />
        )}
      </Row>

      <Filter onFilterSelect={(filter) => setFilters(filter)} />

      <ScrollableDataTableWithColSelector
        filters={filters}
        ready={!!filters}
        columnDefs={getColumnDefs(t)}
        defaultColDef={{
          valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
          onCellClicked: goToFaq
        }}
        getMethod={getFaqList}
        appendMethod={appendFaqRow}
        rows={faqs}
        rowCount={faqCount}
        isFetching={isFetching}
        onRowSelected={onRowSelected}
        responseLength={responseLength}
      />
    </Fragment>
  );
};

Faq.propTypes = {
  history: object,
  t: func,
  faqs: array,
  faqCount: number,
  isFetching: bool,
  appendContents: func,
  getContents: func,
  deleteContents: func,
  responseLength: number
};
export default compose(
  withRouter,
  translate(['cms']),
  connect(
    (state) => ({
      faqs: pathOr(emptyArray, ['cms', 'list'], state),
      faqCount: pathOr(0, ['cms', 'count'], state),
      isFetching: pathOr(false, ['cms', 'isFetching'], state)
    }),
    { getContents, appendContents, deleteContents }
  )
)(Faq);
