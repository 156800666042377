import { genState } from '../utils/reducer';
import {
  GET_PAYMENT_PROFILE_METHODS_REQUEST,
  GET_PAYMENT_PROFILE_METHODS_SUCCESS
} from './paymentProfileMethod.actionTypes';

import {
  createPaymentProfileMethod,
  updatePaymentProfileMethod,
  deletePaymentProfileMethod
} from './paymentProfileMethod.actions';
import { emptyArray, emptyObject } from '../utils';

const paymentProfileMethodDef = (state) => ({
  [GET_PAYMENT_PROFILE_METHODS_REQUEST]: ({ profileId }) => ({
    ...state,
    [profileId]: {
      list: emptyArray,
      isFetching: true,
      isResult: false
    }
  }),
  [deletePaymentProfileMethod.SUCCESS]: (method) => {
    const profile = state[method.paymentProfileId];

    if (profile) {
      const list = profile.list.filter((item) => item.type !== method.type);

      return {
        ...state,
        [method.paymentProfileId]: {
          ...profile,
          list
        }
      };
    }

    return state;
  },
  [updatePaymentProfileMethod.SUCCESS]: (method) => {
    const profile = state[method.paymentProfileId];

    if (profile) {
      const list = profile.list.map((item) => {
        if (item.type === method.type) {
          return method;
        }

        return item;
      });

      return {
        ...state,
        [method.paymentProfileId]: {
          ...profile,
          list
        }
      };
    }

    return state;
  },
  [createPaymentProfileMethod.SUCCESS]: (method) => {
    const profile = state[method.paymentProfileId];

    if (profile) {
      return {
        ...state,
        [method.paymentProfileId]: {
          ...profile,
          list: [...profile.list, method]
        }
      };
    }

    return state;
  },
  [GET_PAYMENT_PROFILE_METHODS_SUCCESS]: ({ paymentMethods, profileId }) => ({
    ...state,
    [profileId]: {
      list: paymentMethods,
      isFetching: false,
      isResult: true
    }
  })
});

export const paymentProfileMethodReducer = (state = emptyObject, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: paymentProfileMethodDef, type });
};
