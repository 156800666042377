import {
  GET_LEASE,
  GET_LEASE_SUCCESS,
  GET_LEASE_FAIL,
  GET_LEASES,
  GET_LEASES_SUCCESS,
  GET_LEASES_FAIL,
  GET_LEASE_DOCUMENTS,
  GET_LEASE_DOCUMENTS_SUCCESS,
  GET_LEASE_DOCUMENTS_FAIL,
  GET_HELLO_SIGN_LEASE,
  GET_HELLO_SIGN_LEASE_SUCCESS,
  SET_ACCESS_RESTRICTED,
  APPEND_LEASES,
  APPEND_LEASES_FAIL,
  APPEND_LEASES_SUCCESS
} from './leases.actionTypes';
import { updateLease } from './leases.actions';
import {
  genState,
  setFetching,
  setList,
  setListFailure,
  setFetchingCurrent,
  setCurrent,
  setCurrentFailure,
  appendList,
  appendListFailure
} from '../utils/reducer';

const leases = (state) => ({
  [SET_ACCESS_RESTRICTED]: () => {
    return { ...state, restricted: true };
  },
  [GET_HELLO_SIGN_LEASE]: setFetchingCurrent(state, true),
  [GET_HELLO_SIGN_LEASE_SUCCESS]: setCurrent(state),
  [GET_LEASES]: setFetching(state, true),
  [GET_LEASES_SUCCESS]: setList(state),
  [GET_LEASES_FAIL]: setListFailure(state),
  [GET_LEASE]: setFetchingCurrent(state, true),
  [GET_LEASE_SUCCESS]: setCurrent(state),
  [GET_LEASE_FAIL]: setCurrentFailure(state),
  [APPEND_LEASES]: setFetching(state, true),
  [APPEND_LEASES_SUCCESS]: appendList(state),
  [APPEND_LEASES_FAIL]: appendListFailure(state),
  [updateLease.SUCCESS]: (payload) => {
    const { id, moveoutDate, preferredMoveoutDate } = payload;
    const leases = state.list.map((lease) => {
      if (lease.id === id) {
        return { ...lease, moveoutDate, preferredMoveoutDate };
      }

      return { ...lease };
    });

    return { ...state, list: leases };
  },
  [GET_LEASE_DOCUMENTS]: () => ({
    ...state,
    documents: { ...state.documents, isFetching: true }
  }),
  [GET_LEASE_DOCUMENTS_SUCCESS]: (payload) => ({
    ...state,
    documents: { ...state.documents, isFetching: false, list: payload }
  }),
  [GET_LEASE_DOCUMENTS_FAIL]: () => ({
    ...state,
    documents: { ...state.documents, isFetching: false }
  })
});

export const leasesReducer = (state = {}, action) => {
  const { type, payload } = action;

  return genState({ state, payload, stateDef: leases, type });
};
