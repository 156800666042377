import React from 'react';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';
import SelectorUnit from '../selector/Selector.unit';

const SelectUnitForm = ({
  t,
  unitId,
  houseId,
  isClearable,
  input,
  label,
  isDisabled,
  placeholder,
  meta: { touched, error }
}) => (
  <InputContainer>
    <SelectorUnit
      label={label}
      placeholder={placeholder}
      unitId={unitId}
      houseId={houseId}
      {...input}
      isInForm={true}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectUnitForm);
