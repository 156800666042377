import { genState } from '../utils/reducer';
import { emptyArray, emptyObject, upsertListWithId } from '../utils';
import {
  SET_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAIL,
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAIL,
  FETCH_CITIES,
  FETCH_CITIES_ONLY,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAIL,
  FETCH_HUBS,
  FETCH_HUBS_ONLY,
  FETCH_HUBS_SUCCESS,
  FETCH_HUBS_FAIL,
  INIT_COUNTRY_FORM,
  INIT_CITY_FORM,
  INIT_HUB_FORM
} from './geo.actions';
import { propOr, pathOr, prop, path } from 'ramda';
import { oneOf } from 'prop-types';

export const formTypes = Object.freeze({
  COUNTRY: 'COUNTRY',
  CITY: 'CITY',
  HUB: 'HUB'
});

export const formTypesDef = oneOf(Object.keys(formTypes).map((type) => formTypes[type]));

const geo = (state) => ({
  // PAYMENT_METHODS
  [FETCH_PAYMENT_METHODS_REQUEST]: () => ({
    ...state,
    paymentMethods: {
      ...propOr(initState.paymentMethods, 'paymentMethods', state),
      isFetching: true
    }
  }),
  [FETCH_PAYMENT_METHODS_SUCCESS]: (payload) => ({
    ...state,
    paymentMethods: {
      ...propOr(initState.paymentMethods, 'paymentMethods', state),
      isFetching: false,
      list: payload || []
    }
  }),
  [FETCH_PAYMENT_METHODS_FAIL]: (payload) => ({
    ...state,
    paymentMethods: {
      ...propOr(initState.paymentMethods, 'paymentMethods', state),
      isFetching: false,
      error: payload
    }
  }),
  // COUNTRIES
  [FETCH_COUNTRIES]: () => ({
    ...state,
    country: {
      ...propOr(initState.country, 'country', state),
      isFetching: true
    },
    city: initState.city,
    hub: initState.hub
  }),
  [FETCH_COUNTRIES_SUCCESS]: (payload) => ({
    ...state,
    country: {
      ...propOr(initState.country, 'country', state),
      isFetching: false,
      list: propOr(emptyArray, 'rows', payload),
      count: propOr(0, 'count', payload)
    }
  }),
  [FETCH_COUNTRIES_FAIL]: (payload) => ({
    ...state,
    country: {
      ...propOr(initState.country, 'country', state),
      isFetching: false,
      error: payload
    }
  }),
  [INIT_COUNTRY_FORM]: () => ({
    ...state,
    form: {
      type: formTypes.COUNTRY,
      data: emptyObject
    }
  }),
  // CITIES
  [FETCH_CITIES]: (country) => ({
    ...state,
    city: {
      ...propOr(initState.city, 'city', state),
      isFetching: true
    },
    country: {
      ...state.country,
      active: {
        id: country.id,
        name: country.name
      }
    },
    hub: {
      ...state.hub,
      list: []
    },
    form: {
      type: formTypes.COUNTRY,
      data: country
    }
  }),
  [FETCH_CITIES_ONLY]: () => ({
    ...state,
    city: {
      ...propOr(initState.city, 'city', state),
      isFetching: true
    }
  }),
  [FETCH_CITIES_SUCCESS]: (payload) => ({
    ...state,
    city: {
      ...propOr(initState.city, 'city', state),
      isFetching: false,
      list: propOr(emptyArray, 'rows', payload),
      count: propOr(0, 'count', payload),
      active: emptyObject
    },
    hub: {
      ...state.hub,
      list: []
    }
  }),
  [FETCH_CITIES_FAIL]: (error) => ({
    ...state,
    city: {
      ...propOr(initState.city, 'city', state),
      isFetching: false,
      error
    },
    hub: {
      ...state.hub,
      list: []
    }
  }),
  [INIT_CITY_FORM]: (city = emptyObject) => ({
    ...state,
    city: {
      ...propOr(emptyObject, 'city', state),
      active: {
        id: prop('id', city),
        name: prop('name', city)
      }
    },
    hub: {
      ...state.hub,
      list: []
    },
    form: {
      type: formTypes.CITY,
      data: { ...city, country: pathOr(emptyObject, ['country', 'active'], state) }
    }
  }),
  // HUBS
  [FETCH_HUBS]: () => ({
    ...state,
    hub: {
      ...propOr(initState.hub, 'hub', state),
      isFetching: true
    }
  }),
  [FETCH_HUBS_ONLY]: () => ({
    ...state,
    hub: {
      ...propOr(initState.hub, 'hub', state),
      isFetching: true
    }
  }),
  [FETCH_HUBS_SUCCESS]: (payload) => ({
    ...state,
    hub: {
      ...propOr(initState.hub, 'hub', state),
      isFetching: false,
      list: propOr(emptyArray, 'rows', payload),
      count: propOr(0, 'count', payload)
    }
  }),
  [FETCH_HUBS_FAIL]: (error) => ({
    ...state,
    hub: {
      ...propOr(initState.hub, 'hub', state),
      isFetching: false,
      error
    }
  }),
  [INIT_HUB_FORM]: (hub = emptyObject) => ({
    ...state,
    form: {
      type: formTypes.HUB,
      data: { city: pathOr(emptyObject, ['city', 'active'], state), ...hub }
    },
    hub: {
      ...state.hub,
      active: {
        id: prop('id', hub),
        name: prop('name', hub)
      }
    }
  }),
  [SET_PAYMENT_METHODS_SUCCESS]: ({ hubId, paymentMethods }) => {
    const form = { ...prop('form', state) };

    if (path(['data', 'id'], form) === hubId) {
      form.data.paymentMethods = paymentMethods;
    }

    return {
      ...state,
      form
    };
  },
  //UPDATE
  UPSERT_COUNTRY_REQUEST: () => ({
    ...state,
    form: { ...state.form, isUpdating: false }
  }),
  UPSERT_COUNTRY_SUCCESS: (countryUpdated) => {
    const countryList = pathOr(emptyArray, ['country', 'list'], state);
    const countries = upsertListWithId(countryList, countryUpdated);
    return {
      ...state,
      country: { ...state.country, list: countries },
      form: { ...state.form, isUpdating: false, data: countryUpdated }
    };
  },
  UPSERT_COUNTRY_FAILURE: (error) => ({
    ...state,
    form: { ...state.form, isUpdating: false, error }
  }),
  // UPDATE CITY
  UPSERT_CITY_REQUEST: () => ({
    ...state,
    form: { ...state.form, isUpdating: false }
  }),
  UPSERT_CITY_SUCCESS: (city) => {
    const cities = pathOr(emptyArray, ['city', 'list'], state);
    const _cities = upsertListWithId(cities, city);
    return {
      ...state,
      city: { ...state.city, list: _cities },
      form: { ...state.form, isUpdating: false, data: city }
    };
  },
  UPSERT_CITY_FAILURE: (error) => ({
    ...state,
    form: { ...state.form, isUpdating: false, error }
  }),
  // UPDATE HUB
  UPSERT_HUB_REQUEST: () => ({
    ...state,
    form: { ...state.form, isUpdating: false }
  }),
  UPSERT_HUB_SUCCESS: (hub) => {
    const hubs = pathOr(emptyArray, ['hub', 'list'], state);
    const _hubs = upsertListWithId(hubs, hub);
    return {
      ...state,
      hub: { ...state.hub, list: _hubs },
      form: { ...state.form, isUpdating: false, data: hub }
    };
  },
  UPSERT_HUB_FAILURE: (error) => ({
    ...state,
    form: { ...state.form, isUpdating: false, error }
  })
});

const initState = {
  country: {
    isFetching: false,
    list: emptyArray,
    count: 0,
    error: undefined,
    active: undefined
  },
  city: {
    isFetching: false,
    list: emptyArray,
    count: 0,
    error: undefined,
    active: undefined
  },
  hub: {
    isFetching: false,
    list: emptyArray,
    count: 0,
    error: undefined,
    active: undefined
  },
  paymentMethods: {
    isFetching: false,
    list: emptyArray,
    error: undefined
  },
  form: { type: undefined, data: emptyObject, isUpdating: false }
};

export const geoReducer = (state = initState, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: geo, type });
};
