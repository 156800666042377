import React, { useState, useEffect } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { equals, compose, pathOr, isEmpty } from 'ramda';

import { emptyArray, sortByCaseInsensitive } from '../../utils';
import { getIncidentPriorities } from '../../enum/enum.actions';
import { connect } from 'react-redux';
import { storableSelector } from '../storableDecorator';
import Selector from './Selector';

const SelectorIncidentPriority = ({
  name,
  isMulti,
  label,
  value,
  isInForm,
  list,
  getIncidentPriorities,
  isDisabled,
  isClearable,
  placeholder,
  onChange,
  valueControl
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    getIncidentPriorities();
  }, []);

  useEffect(() => {
    const sorted = sortByCaseInsensitive('label')(
      list.map((option) => ({
        label: option,
        value: option
      }))
    );
    if (!equals(sorted, options)) {
      setOptions(sorted);
    }
  }, [list]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorIncidentPriority.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  list: array.isRequired,
  isFetching: bool.isRequired,
  getIncidentPriorities: func.isRequired
};

SelectorIncidentPriority.defaultProps = {
  onLoad: () => {},
  storeType: 'session',
  storeName: null,
  ready: true,
  isClearable: true,
  autoSelect: false,
  isInForm: false
};

export default compose(
  storableSelector,
  connect(
    (state) => ({
      state,
      list: pathOr(emptyArray, ['enums', 'incidentPriorities', 'list'], state),
      isFetching: pathOr(false, ['enums', 'incidentPriorities', 'isFetching'], state)
    }),
    { getIncidentPriorities }
  )
)(SelectorIncidentPriority);
