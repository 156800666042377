import React from 'react';
import { bool, string, func } from 'prop-types';
import { Field } from 'redux-form';
import {
  Row,
  Col,
  SelectorGeoCityForm,
  SelectorGeoHubForm,
  SelectorGeoCountryForm,
  SelectHouseForm
} from '../components';
import { isNotEmptyOrNil, isEmptyOrNil } from '../utils';

const AudienceForm = (props) => {
  const { t, countryId, cityId, hubId, houseId, includeHouse } = props;

  const disableCity = () => isNotEmptyOrNil(hubId) || (isEmptyOrNil(cityId) && isEmptyOrNil(countryId));
  const disableHub = () => isNotEmptyOrNil(houseId) || (isEmptyOrNil(hubId) && isEmptyOrNil(cityId));
  const disableHouse = () => isEmptyOrNil(houseId) && isEmptyOrNil(hubId);
  const disableCountry = () => isNotEmptyOrNil(cityId) || isNotEmptyOrNil(hubId) || isNotEmptyOrNil(houseId);

  return (
    <Row>
      <Col xl={3} lg={3} md={6} sm={6} pr-half pl-half>
        <Field
          component={SelectorGeoCountryForm}
          isDisabled={disableCountry()}
          label={t('FORM.COUNTRY')}
          name="countryId"
        />
      </Col>

      <Col xl={3} lg={3} md={6} sm={6} pr-half pl-half>
        <Field
          component={SelectorGeoCityForm}
          isDisabled={disableCity()}
          isClearable
          countryId={countryId}
          label={t('FORM.CITY')}
          name="cityId"
        />
      </Col>

      <Col xl={3} lg={3} md={6} sm={6} pr-half pl-half>
        <Field
          component={SelectorGeoHubForm}
          isDisabled={disableHub()}
          isClearable
          cityId={cityId}
          label={t('FORM.HUB')}
          name="hubId"
        />
      </Col>

      {includeHouse && (
        <Col xl={3} lg={3} md={6} sm={6} pr-half pl-half>
          <Field
            component={SelectHouseForm}
            isDisabled={disableHouse()}
            isClearable
            hubId={hubId}
            label={t('FORM.HOUSE')}
            name="houseId"
          />
        </Col>
      )}
    </Row>
  );
};

AudienceForm.propTypes = {
  t: func,
  countryId: string,
  cityId: string,
  hubId: string,
  houseId: string,
  includeHouse: bool,
  isNew: bool
};

export default AudienceForm;
