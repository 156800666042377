import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { propOr, compose } from 'ramda';
import { withRouter, Redirect } from 'react-router-dom';
import { Container, Loader, Text } from '../components';
import { connectHouseToStripe, clearHouseLocalStorage } from './houses.service';
import { parseQueryString } from '../utils';
import { translate } from '../translate/i18n';

const HouseStripe = (props) => {
  // Props
  const { t, location } = props;

  // Vars
  const houseId = localStorage.getItem('house');
  const queries = parseQueryString(propOr('', 'search', location));

  // State
  const [id, setId] = useState(null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);

  // Hooks
  useEffect(() => {
    setReady(false);

    clearHouseLocalStorage(true);

    if (houseId && queries.code) {
      connectHouseToStripe(houseId, queries.code)
        .then(() => {
          setId(houseId);
          setReady(true);
        })
        .catch((err) => {
          setError(t(`${err.message}`));
        });
    } else {
      setError(t('HOUSE_STRIPE_MISSING_PARAMS'));
    }
  }, []);

  // RENDER
  // On error
  if (error) {
    return (
      <Container full column center middle>
        <Text>{error}</Text>
      </Container>
    );
  }

  // Redirection on success
  if (ready) {
    return <Redirect to={`/houses/${id}`} />;
  }

  // Loading
  return (
    <Container full column center middle>
      <Text>{'Asking for Stripe credentials...'}</Text>
      <Loader />
    </Container>
  );
};

HouseStripe.propTypes = {
  t: func,
  location: object,
  match: object
};

export default compose(withRouter, translate('house'))(HouseStripe);
