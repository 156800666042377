import React, { useState } from 'react';
import { func, object, number, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, pathOr, prop, path } from 'ramda';
import { translate } from '../translate/i18n';
import { getPayments, appendPayments } from './payments.actions';
import { valueOrPlaceholder, isNotEmptyOrNil, emptyArray } from '../utils';
import { Block, Row, Text, ScrollableDataTableWithColSelector } from '../components';
import Filter from './Payment.filter';

const getColumnDefs = (t) => [
  {
    headerName: t('FIRSTNAME'),
    defaultActive: true,
    field: 'firstName'
  },
  {
    headerName: t('LASTNAME'),
    defaultActive: true,
    field: 'lastName'
  },
  {
    headerName: t('BALANCE'),
    defaultActive: true,
    field: 'balance',
    valueFormatter: ({ data }) => `${data.symbol} ${data.balance}`
  },
  {
    headerName: t('HUB'),
    field: 'leases.0.room.house.hub.name'
  },
  {
    headerName: t('HOUSE'),
    field: 'leases.0.room.house.name'
  },
  {
    headerName: t('ROOM'),
    field: 'leases.0.room.number'
  }
];

const Payments = (props) => {
  const { history, t, payments, paymentCount, isFetching, appendPayments, getPayments, responseLength } = props;

  const [filters, setFilters] = useState();
  const goToDetails = (event) => {
    history.push(`/payments/${path(['data', 'id'], event)}/hub/${filters.hubId}/rents`);
  };

  return (
    <Block p>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {t('PAYMENTS_FOLLOW_UP')}
        </Text>
      </Row>

      <Filter onFilterSelect={(filter) => setFilters(filter)} />

      <ScrollableDataTableWithColSelector
        filters={filters}
        ready={!!filters}
        columnDefs={getColumnDefs(t)}
        defaultColDef={{ valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)) }}
        getMethod={(params) => getPayments({ ...params, paymentType: 'payments' })}
        appendMethod={(params) => appendPayments({ ...params, paymentType: 'payments' })}
        rows={payments}
        rowCount={paymentCount}
        responseLength={responseLength}
        isFetching={isFetching}
        onRowClicked={goToDetails}
      />
    </Block>
  );
};

Payments.propTypes = {
  history: object,
  t: func,
  payments: array,
  paymentCount: number,
  responseLength: number,
  isFetching: bool,
  appendPayments: func,
  getPayments: func
};

export default compose(
  withRouter,
  translate(['payments']),
  connect(
    (state) => ({
      payments: pathOr(emptyArray, ['payments', 'list'], state),
      paymentCount: pathOr(0, ['payments', 'count'], state),
      responseLength: pathOr(0, ['payments', 'responseLength'], state),
      isFetching: pathOr(false, ['payments', 'isFetching'], state)
    }),
    { getPayments, appendPayments }
  )
)(Payments);
