import { SEGMENT_IDENTIFY, SEGMENT_PAGE } from './segment.actionTypes';
import { all, takeLatest } from 'redux-saga/effects';
import { call } from 'ramda';
import * as service from './segment.service';

export function* segmentIdentify({ user }) {
  yield call(service.segmentIdentify, user);
}
export function* segmentPage({ history }) {
  yield call(service.segmentPage, history);
}

export default function* segmentSaga() {
  yield all([takeLatest(SEGMENT_IDENTIFY, segmentIdentify), takeLatest(SEGMENT_PAGE, segmentPage)]);
}
