import React, { Fragment, useState, useEffect } from 'react';
import { oneOfType, string, array, object, func, bool, number } from 'prop-types';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { compose, path, pathOr, propOr, is } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Users,
  // Temporarily commented out. Restore when recreating the new Incidents page
  // Bell,
  Layers,
  Home,
  MapPin,
  Command,
  Archive,
  Repeat,
  LogIn,
  Lock,
  Map,
  FileText,
  UserPlus,
  Package
} from 'react-feather';

import { translate } from '../translate/i18n';
import { capitalize } from '../utils';
import { Text, Row, Button, sizing } from '../components';
import { toggleCollapseMenu } from './actions';
import { ROLES } from '../utils/authorizations';

const TabStyled = styled('li')(({ theme, active }) => ({
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: active ? theme.colors.brown[500] : 'transparent',
  borderLeft: 'none',
  borderRight: 'none',
  color: theme.colors.lightGrey[500],
  padding: theme.spacing.half,
  '&:hover': {
    cursor: 'pointer',
    background: theme.colors.brown[500],
    '*': {
      color: 'white !important'
    }
  }
}));

export const Tab = ({ children, active }) => <TabStyled active={active}>{children}</TabStyled>;
Tab.propTypes = {
  active: bool,
  children: oneOfType([string, object, array])
};

const makeTab = (label, route, IconComponent, authorization = undefined, forMobile = true) => ({
  label,
  route,
  icon: (color) => <IconComponent size={17} color={color} />,
  authorization,
  forMobile
});

const loggedTabs = [
  makeTab('APPLICATIONS', '/applications', Repeat, { exclude: [ROLES.CLEANER] }),
  makeTab('HOUSES', '/houses', MapPin, { exclude: [ROLES.CLEANER] }),
  makeTab('ROOMS', '/rooms', Home, { exclude: [ROLES.CLEANER] }),
  makeTab('MEMBERS', '/members', Users, { exclude: [ROLES.CLEANER] }),
  makeTab('LEASES', '/leases', FileText, { exclude: [ROLES.CLEANER] }),
  makeTab('PAYMENTS', '/payments', Layers, { exclude: [ROLES.CLEANER] }),
  makeTab('INVENTORIES', '/inventories', Archive, [ROLES.ADMIN, ROLES.CM]),
  makeTab('INVENTORY', '/inventory/create', Archive, [ROLES.CLEANER]),
  // Temporarily commented out. Restore when recreating the new Incidents page
  // makeTab('INCIDENTS', '/incidents', Bell, { exclude: [ROLES.CLEANER] }),
  makeTab('PACKS', '/packs', Package, { exclude: [ROLES.CLEANER] }),
  makeTab('CMS', '/cms/tips', Command, { exclude: [ROLES.CLEANER] }),
  makeTab('GEO', '/geo', Map, [ROLES.ADMIN], false),
  makeTab('USERS', '/users', UserPlus, [ROLES.ADMIN])
];

const unLoggedTabs = [makeTab('LOGIN', '/login', LogIn), makeTab('FORGOT_PASSWORD', '/forgotPassword', Lock)];

const ActiveText = ({ label }) => (
  <Text weight={300} white>
    {label}
  </Text>
);
ActiveText.propTypes = {
  label: string
};

const generateTabs = (isLogged, user, isMobile) => {
  const menu = isLogged ? loggedTabs : unLoggedTabs;
  return menu.filter((tab) => {
    const authorization = propOr([], 'authorization', tab);

    if (isMobile && !tab.forMobile) {
      return false;
    }

    if (is(Array, authorization) && authorization.length) {
      return authorization.includes(user.role);
    }

    if (is(Object, authorization)) {
      const exclude = propOr([], 'exclude', authorization);

      if (exclude.length) {
        return !exclude.includes(user.role);
      }
    }

    return true;
  });
};

const MainMenu = ({ t, history, theme, isLogged, user, toggleCollapseMenu, windowWidth, mobileView }) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs(generateTabs(isLogged, user, mobileView));
  }, [mobileView, isLogged, user]);

  const currentRoute = path(['location', 'pathname'], history);
  const current = currentRoute.split('/')[1];

  const autoCollapse = windowWidth <= 1024;

  return (
    <Fragment>
      {tabs.map((tab) => {
        const isActive = tab.route.split('/')[1] === current;
        const color = isActive ? 'white' : theme.colors.grey[200];

        return (
          <Button
            m-none
            p-none
            key={tab.route}
            name="add"
            onClick={() => {
              history.push(tab.route);
              if (autoCollapse) {
                toggleCollapseMenu(true);
              }
            }}
            clear
            size="md">
            <div className="line-container">
              <Tab active={isActive}>
                <Row middle p-quarter>
                  <div className="menu-icon">{tab.icon(color)}</div>

                  <div className="menu-label">
                    {!!isActive && <ActiveText label={capitalize(t(tab.label))} />}
                    {!isActive && (
                      <Text weight={300} grey={200} cursor="pointer">
                        {capitalize(t(tab.label))}
                      </Text>
                    )}
                  </div>
                </Row>
              </Tab>
            </div>
          </Button>
        );
      })}
    </Fragment>
  );
};

MainMenu.propTypes = {
  t: func,
  toggleCollapseMenu: func,
  windowWidth: number,
  history: object,
  theme: object,
  user: object,
  mobileView: bool,
  isLogged: bool
};

export default compose(
  sizing(),
  withRouter,
  translate(['menu', 'default']),
  connect(
    (state) => ({
      user: path(['user', 'data'], state),
      isLogged: pathOr(false, ['user', 'isLogged'], state)
    }),
    (dispatch) => bindActionCreators({ toggleCollapseMenu }, dispatch)
  ),
  withTheme
)(MainMenu);
