import { func, object } from 'prop-types';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { Field, FormSection, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { Block, BottomContainer, Button, Col, Container, DatePicker, Row, ToggleForm } from '../components';
import StyledForm from '../components/form.styles';
import SelectorBedbugsStatusForm from '../components/form/Selector.bedbugsStatus.form';
import { fetchHubs } from '../hub/hub.actions';
import { translate } from '../translate/i18n';
import { upsertHouse } from './houses.actions';

const SpecificInfoForm = ({ t, history, handleSubmit, submitting, initialValues }) => {
  return (
    <Block>
      <StyledForm onSubmit={handleSubmit(upsertHouse)}>
        <FormSection name="info">
          <Container column pt-half pb-half pl pr>
            <Row>
              <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
                <Field
                  name="bedbugsStatus"
                  label={t('BEDBUGS_STATUS')}
                  component={SelectorBedbugsStatusForm}
                  placeholder={t('SELECT_BEDBUGS_STATUS')}
                />
              </Col>
            </Row>

            <Row>
              <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
                <Field component={ToggleForm} label={t('HAS_SPRINKLER')} name="hasSprinkler" type="checkbox" />
              </Col>
            </Row>

            <Row mt>
              <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half>
                <Field
                  name="lastSprinklerInspectionDate"
                  component={DatePicker}
                  label={t('LAST_SPRINKLER_INSPECTION_DATE')}
                  placeholder={t('LAST_SPRINKLER_INSPECTION_DATE')}
                  timezone={initialValues.info.hub.timezone}
                />
              </Col>
            </Row>
          </Container>
        </FormSection>

        <BottomContainer>
          <Row right>
            <Button
              name="submit"
              disabled={submitting}
              clear
              size="md"
              onClick={() => history.push(`/houses/${initialValues.id}`)}>
              {t('form:CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('form:SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </BottomContainer>
      </StyledForm>
    </Block>
  );
};

SpecificInfoForm.propTypes = {
  t: func,
  history: object,
  ...reduxFormPropTypes
};

export default compose(
  reduxForm({ form: 'HouseSpecificInfoForm' }),
  translate('house'),
  connect(null, { fetchHubs })
)(SpecificInfoForm);
