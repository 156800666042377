import React, { useEffect, useState } from 'react';
import { compose, is, values } from 'ramda';
import { Col, collapsible, Row, Toggle } from '../index';
import { func, bool, array } from 'prop-types';

const ColSelector = ({ columnDefs, setSelectedColumnDefs, mobileView }) => {
  const [list, updateList] = useState();

  useEffect(() => {
    if (!is(Array, columnDefs)) {
      return;
    }

    const colSelect = {};

    columnDefs.map((val, index) => {
      const key = `${val.width}_${index}`;

      colSelect[key] = {
        ...val,
        key,
        label: val.label || val.headerName,
        active: !!val.defaultActive || !mobileView
      };
      return key;
    });
    updateList(colSelect);

    setColumn(colSelect);
  }, [columnDefs, mobileView]);

  const setColumn = (mask) => {
    setSelectedColumnDefs(values(mask).filter((item) => item.active));
  };

  const handleChange = (key) => {
    list[key].active = !list[key].active;
    updateList({ ...list });
    setColumn(list);
  };

  return (
    <Row middle mb>
      {values(list).map((item) => (
        <Col xl={3} lg={3} md={6} sm={6} pr-half mb key={item.key}>
          <Toggle
            input={{
              checked: item.active,
              onChange: () => null
            }}
            onCheck={() => handleChange(item.key)}
            right
            label={item.label}
          />
        </Col>
      ))}
    </Row>
  );
};

ColSelector.propTypes = {
  setSelectedColumnDefs: func,
  columnDefs: array,
  mobileView: bool
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_SELECTOR',
    labelShow: 'default:SHOW_SELECTOR',
    showOnFullScreen: false
  })
)(ColSelector);
