import { injectGlobal } from 'emotion';
import { colors } from './colors';
import { spacing } from './spacing';

injectGlobal`
  .discount-details {
    padding: ${spacing.normal}px;
    background-color: ${colors.lightGrey[50]};

    &.inactive {
      background-color: ${colors.lightGrey[100]};
    }

    .calculated-value {
      &.inactive {
        text-decoration: line-through;
      }
    }
    
    &.lease {
      padding-top: 0px;
      margin-bottom: ${spacing.half}px;
      border: 2px solid #ccc;
    }
  }
  
  .discount-tooltip {
    display: flex;
    align-items: center;
    text-align: center;
    &.disabled {
      svg.ico {
        &.info {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
      .react-tooltip-lite {
        color: ${colors.grey[500]};
        background-color: ${colors.lightGrey[400]};
      }
      .react-tooltip-lite-arrow {
        border-color: ${colors.lightGrey[400]};
      }
    }

    .disabled-placeholder {
      cursor: not-allowed;
      color: ${colors.lightGrey[500]};
      padding: ${spacing.quarter}px 8px;
      font-size: inherit;
      border: 1px solid #ccc;
      border-radius: ${spacing.normal}px;
      width: 100%;
    }
  }
`;
