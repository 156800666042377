import { takeLatest, call, all, put } from 'redux-saga/effects';
import { propOr, prop, is } from 'ramda';
import * as service from './packs.service';
import { GET_PACKS, APPEND_PACKS, GET_PACK, DUPLICATE_PACK } from './packs.actionTypes';
import {
  getPacksSuccess,
  getPacksFail,
  appendPacksFail,
  appendPacksSuccess,
  getPackSuccess,
  getPackFail,
  upsertPack,
  duplicatePackSuccess,
  duplicatePackFail
} from './packs.actions';
import { uploadFile } from '../service';
import { handleFormErrors } from '../utils';
import { types, defActionType } from '../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';

export function* getPacks(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const packs = yield call(service.getPacks, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(getPacksSuccess(packs));
  } catch (err) {
    yield put(getPacksFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.PACK, error: err, data: action.payload }));
  }
}

export function* appendPacks(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const packs = yield call(service.getPacks, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(appendPacksSuccess(packs));
  } catch (err) {
    yield put(appendPacksFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.PACK, error: err, data: action.payload }));
  }
}

export function* getPack(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const pack = yield call(service.getPack, payload);
    yield put(getPackSuccess(pack));
  } catch (err) {
    yield put(getPackFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.PACK, error: err, data: action.payload }));
  }
}

export function* _upsertPack(action) {
  const payload = prop('payload', action);
  const key = defActionType(payload);

  try {
    const isNew = prop('id', payload) === undefined || prop('id', payload) === null;
    const icon = propOr(null, 'icon', payload);

    if (is(Blob, icon)) {
      payload.icon = yield uploadFile(icon);
    }

    const savedPack = yield call(service.upsertPack, payload);

    if (!isNew) {
      const pack = yield call(service.getPack, prop('id', payload));
      yield put(upsertPack.success(pack));
    } else {
      yield put(upsertPack.success(savedPack));
    }

    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.PACK }));
  } catch (err) {
    yield put(upsertPack.failure(handleFormErrors(err)));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.PACK, error: err, data: action.payload }));
  }
}

export function* duplicatePack(action) {
  const payload = prop('payload', action);
  try {
    const savedPack = yield call(service.duplicatePack, payload);
    yield put(handleSuccessMessage('DUPLICATE.SUCCESS', { type: types.PACK }));
    yield put(duplicatePackSuccess(savedPack));
    try {
      const packs = yield call(service.getPacks, { page: 1, limit: 15, order: [['createdAt', 'DESC']] });
      yield put(getPacksSuccess(packs));
    } catch (err) {
      yield put(appendPacksFail(err));
      yield put(handleErrorMessage('GET.FAILURE', { type: types.PACK, error: err, data: action.payload }));
    }
  } catch (err) {
    yield put(duplicatePackFail(handleFormErrors(err)));
    yield put(handleErrorMessage('CREATE.FAILURE', { type: types.PACK, error: err, data: action.payload }));
  }
}

export default function* packsSaga() {
  yield all([
    takeLatest(APPEND_PACKS, appendPacks),
    takeLatest(GET_PACKS, getPacks),
    takeLatest(GET_PACK, getPack),
    takeLatest(upsertPack.REQUEST, _upsertPack),
    takeLatest(DUPLICATE_PACK, duplicatePack)
  ]);
}
