import React from 'react';
import { string, object, bool, func, number, oneOfType } from 'prop-types';
import { compose, pathOr, values } from 'ramda';
import { connect } from 'react-redux';

/**
 * This file is a copy of Stream Component ChannelPreviewMessenger adapted to our requirement.
 * Keep custom modifications to the minimum for future compatibility!
 * See more here: https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelPreview/ChannelPreviewMessenger.tsx
 */

const MessagingChannelItem = ({
  active,
  Avatar,
  channel,
  displayImage,
  displayTitle,
  latestMessage,
  setActiveChannel,
  unread,
  user
}) => {
  const activeClass = active ? 'str-chat__channel-preview-messenger--active' : '';
  const unreadClass = unread && unread >= 1 ? 'str-chat__channel-preview-messenger--unread' : '';
  let avatarName = displayTitle;

  if (!avatarName) {
    const otherMembers = channel ? values(channel.state.members).filter((member) => member.user.id !== user.id) : [];

    const newName = otherMembers
      .map((item) => pathOr('', ['user', 'name'], item))
      .filter((item) => item)
      .join(', ');

    avatarName = newName ? newName : avatarName;
  }

  return (
    <button
      className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
      data-testid="channel-preview-button"
      onClick={() => setActiveChannel(channel)}>
      <div className="str-chat__channel-preview-messenger--left">
        <Avatar image={displayImage} name={avatarName} size={40} />
      </div>
      <div className="str-chat__channel-preview-messenger--right">
        <div className="str-chat__channel-preview-messenger--name">
          <span>{displayTitle}</span>
        </div>
        <div className="str-chat__channel-preview-messenger--last-message">{latestMessage}</div>
      </div>
    </button>
  );
};

MessagingChannelItem.propTypes = {
  unread: number.isRequired,
  setActiveChannel: func.isRequired,
  active: bool.isRequired,
  displayImage: string,
  displayTitle: string,
  latestMessage: oneOfType([string, object]),
  Avatar: func.isRequired,
  channel: object.isRequired,
  user: object.isRequired
};

export default compose(
  connect((state) => ({
    user: pathOr({}, ['user', 'data'], state)
  }))
)(MessagingChannelItem);
