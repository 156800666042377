import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { compose, prop, map, path, pathOr, isEmpty } from 'ramda';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Block, Row, Text, TabsContainer, Tab, Col, Back, Loader } from '../components';
import { translate } from '../translate/i18n';
import Rents from './Payment.rents';
import Packs from './Payment.packs';
import Deposit from './Payment.deposit';
import ApplicationFee from './Payment.applicationFee';
import PrebookingFee from './Payment.prebookingFee';
import { emptyObject } from '../utils';
import { getMember } from '../members/member.actions';
import { fetchHub } from '../hub/hub.actions';

const generateTabs = (baseUrl) => [
  { label: 'RENTS', url: `${baseUrl}/rents`, component: Rents },
  { label: 'PACKS', url: `${baseUrl}/packs`, component: Packs },
  { label: 'DEPOSIT', url: `${baseUrl}/deposit`, component: Deposit },
  { label: 'APPLICATION_FEE', url: `${baseUrl}/application_fee`, component: ApplicationFee },
  { label: 'PREBOOKING_FEE', url: `${baseUrl}/prebooking_fee`, component: PrebookingFee }
];

const Payment = (props) => {
  // props
  const {
    t,
    match,
    location,

    member,
    getMember,

    fetchHub,
    hub
  } = props;
  // vars
  const url = prop('url', match);
  const tabs = generateTabs(url);
  const tabComponents = map((tab) => {
    const path = prop('url', tab);
    const label = prop('label', tab);

    return <Tab key={label} to={path} label={t(label)} disabled={prop('pathname', location) === path} />;
  }, tabs);

  useEffect(() => {
    if (!member || member.id !== match.params.userId) {
      getMember(match.params.userId);
    }
    if (!hub || hub.id !== match.params.hubId) {
      fetchHub(match.params.hubId);
    }
  }, []);

  if (!hub || !member || hub.id !== match.params.hubId || member.id !== match.params.userId) {
    return null;
  }

  if (isEmpty(hub) || isEmpty(member)) {
    return <Loader />;
  }

  const routeComponents = map((tab) => {
    const Component = prop('component', tab);
    const path = prop('url', tab);
    return <Route key={path} path={path} render={(props) => <Component {...props} member={member} hub={hub} />} />;
  }, tabs);

  return (
    <Block p>
      <Row mb-double>
        <Col lg={6} left>
          <Back to={'/payments'} label={t('BACK_TO_PAYMENTS_LIST')} />
        </Col>
      </Row>

      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {t('DETAILED_ACCOUNT_BALANCE', { user: member, hub })}
        </Text>
      </Row>

      <TabsContainer>{tabComponents}</TabsContainer>

      <Switch>
        {routeComponents}
        <Redirect to={path([0, 'url'], tabs)} />
      </Switch>
    </Block>
  );
};

Payment.propTypes = {
  t: func,
  location: object,
  match: object,

  member: object,
  getMember: func,

  hub: object,
  fetchHub: func
};

export default compose(
  withRouter,
  translate('payments'),
  connect(
    (state) => ({
      member: pathOr(emptyObject, ['member', 'current', 'data'], state),
      hub: pathOr(emptyObject, ['hub', 'current', 'data'], state)
    }),
    { getMember, fetchHub }
  )
)(Payment);
