import React, { useEffect, useRef, useState } from 'react';
import {
  ChannelListMessenger,
  useChannelDeletedListener,
  useChannelHiddenListener,
  useChannelTruncatedListener,
  useChannelUpdatedListener,
  useChannelVisibleListener,
  useConnectionRecoveredListener,
  useMessageNewListener,
  useMobileNavigation,
  useNotificationAddedToChannelListener,
  useNotificationMessageNewListener,
  useNotificationRemovedFromChannelListener,
  usePaginatedChannels,
  useUserPresenceChangedListener,
  MAX_QUERY_CHANNELS_LIMIT,
  ChatDown,
  EmptyStateIndicator as DefaultEmptyStateIndicator,
  LoadingChannels,
  withChatContext
} from 'stream-chat-react';
import { compose, propOr } from 'ramda';

import Search from '../search';
import Paginator from './Paginator';
import ChannelPreview from './ChannelPreview';

/**
 * This file is a copy of Stream Component ChannelList adapted to our requirement.
 * Keep custom modifications to the minimum for future compatibility!
 * See more here: https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelList/ChannelList.tsx
 */

const ChannelList = (props) => {
  const {
    additionalChannelSearchProps = {
      searchForChannels: true
    },
    allowNewMessagesFromUnfilteredChannels,
    ChannelSearch = Search,
    EmptyStateIndicator = DefaultEmptyStateIndicator,
    filters = {},
    LoadingErrorIndicator = ChatDown,
    LoadingIndicator = LoadingChannels,
    List = ChannelListMessenger,
    lockChannelOrder,
    onAddedToChannel,
    onChannelDeleted,
    onChannelHidden,
    onChannelTruncated,
    onChannelUpdated,
    onChannelVisible,
    onMessageNew,
    onRemovedFromChannel,
    options = {},
    sort = {},
    watchers = {},

    channel,
    client,
    closeMobileNav,
    customClasses,
    navOpen = false,
    setActiveChannel,
    theme,
    useImageFlagEmojisOnWindows
  } = props;

  const channelListRef = useRef(null);
  const [channelUpdateCount, setChannelUpdateCount] = useState(0);

  const activeChannelHandler = (channels) => {
    if (channels.length === 0 || channels.length > (options.limit || MAX_QUERY_CHANNELS_LIMIT)) {
      return;
    }

    if (!channel) {
      setActiveChannel(channels[0], watchers);
    }
  };

  const forceUpdate = () => setChannelUpdateCount((count) => count + 1);

  const { channels, hasNextPage, loadNextPage, setChannels, setOffset, status } = usePaginatedChannels(
    client,
    filters,
    sort,
    options,
    activeChannelHandler
  );

  useMobileNavigation(channelListRef, navOpen, closeMobileNav);
  useMessageNewListener(setChannels, lockChannelOrder, allowNewMessagesFromUnfilteredChannels);
  useNotificationMessageNewListener(setChannels, onMessageNew, setOffset);
  useNotificationAddedToChannelListener(setChannels, onAddedToChannel);
  useNotificationRemovedFromChannelListener(setChannels, onRemovedFromChannel);
  useChannelDeletedListener(setChannels, onChannelDeleted);
  useChannelHiddenListener(setChannels, onChannelHidden);
  useChannelVisibleListener(setChannels, onChannelVisible);
  useChannelTruncatedListener(setChannels, onChannelTruncated, forceUpdate);
  useChannelUpdatedListener(setChannels, onChannelUpdated, forceUpdate);
  useConnectionRecoveredListener(forceUpdate);
  useUserPresenceChangedListener(setChannels);

  useEffect(() => {
    const handleEvent = (event) => {
      if (setActiveChannel && event.cid === channel.cid) {
        setActiveChannel();
      }
    };

    client.on('channel.deleted', handleEvent);
    client.on('channel.hidden', handleEvent);

    return () => {
      client.off('channel.deleted', handleEvent);
      client.off('channel.hidden', handleEvent);
    };
  }, [channel]);

  const chatClass = customClasses ? customClasses.chat : 'str-chat';
  const channelListClass = customClasses ? customClasses.channelList : 'str-chat-channel-list';
  const navigationClass = navOpen ? 'str-chat-channel-list--open' : '';
  const windowsEmojiClass =
    useImageFlagEmojisOnWindows && navigator.userAgent.match(/Win/) ? 'str-chat--windows-flags' : '';

  const loadChannels = (channels || []).filter((item) => item);

  return (
    <div
      className={`${chatClass} ${channelListClass} ${theme} ${navigationClass} ${windowsEmojiClass}`}
      ref={channelListRef}>
      <ChannelSearch {...additionalChannelSearchProps} />

      <List
        error={status?.error}
        loading={status?.loadingChannels}
        LoadingErrorIndicator={LoadingErrorIndicator}
        LoadingIndicator={LoadingIndicator}>
        {propOr(0, 'length', loadChannels) ? (
          <Paginator hasNextPage={hasNextPage} loadNextPage={loadNextPage}>
            {loadChannels.map((item) => (
              <ChannelPreview key={item.cid} item={item} channelUpdateCount={channelUpdateCount} watchers={watchers} />
            ))}
          </Paginator>
        ) : (
          <EmptyStateIndicator listType="channel" />
        )}
      </List>
    </div>
  );
};

export default compose(withChatContext)(ChannelList);
