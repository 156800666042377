import React, { useState, Fragment } from 'react';
import { func, number, array, bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pathOr, path, prop } from 'ramda';
import { translate } from '../translate/i18n';
import { getContents, appendContents, deleteContents } from './cms.actions';
import { capitalize, valueOrPlaceholder, isNotEmptyOrNil, emptyArray, formatLocations, upperCase } from '../utils';
import { Row, Text, ActionButton, ActionSecondaryButton, ScrollableDataTableWithColSelector } from '../components';
import Filter from './Faq.filter';

const getColumnDefs = (t) => [
  {
    headerName: '',
    label: t('default:CHECKBOX'),
    width: 25,
    valueFormatter: () => null,

    headerComponentFramework: ({ api, column }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            isChecked ? api.deselectAll() : api.selectAll();
            setIsChecked(!isChecked);
            api.refreshCells({ columns: [column], force: true });
          }}
        />
      );
    },
    onCellClicked: () => null,

    cellRendererFramework: ({ node, api }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(node.isSelected());
      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!node.isSelected());
            node.setSelected(!node.isSelected());
            api.refreshHeader();
          }}
        />
      );
    }
  },
  {
    headerName: capitalize(t('LOCATIONS')),
    defaultActive: true,
    field: 'locations',
    width: 125,
    valueFormatter: formatLocations,
    sortable: false
  },
  {
    headerName: capitalize(t('CATEGORY')),
    defaultActive: true,
    field: 'category',
    valueFormatter: ({ value }) => t(`FAQ_CATEGORIES.${upperCase(value)}`),
    width: 125
  },
  {
    headerName: capitalize(t('SUBCATEGORY')),
    field: 'subcategory.name',
    valueFormatter: ({ value }) => (value ? t(`FAQ_SUBCATEGORIES.${upperCase(value)}`) : ''),
    width: 125
  },
  {
    headerName: capitalize(t('TITLE')),
    defaultActive: true,
    field: 'title',
    width: 125
  }
];

const Videos = (props) => {
  //Props
  const { history, t, videos, videoCount, isFetching, appendContents, getContents, deleteContents, responseLength } =
    props;
  //State
  const [selectedContents, selectContents] = useState(emptyArray);
  const [filters, setFilters] = useState();

  const getVideoList = (params) => getContents({ ...params, contentType: 'helpvideos' });
  const appendVideoRow = (params) => appendContents({ ...params, contentType: 'helpvideos' });
  const deleteVideo = (contents) => deleteContents({ list: contents, contentType: 'helpvideos' });

  const goToVideo = (event) => {
    history.push(`/cms/helpvideos/${path(['data', 'id'], event)}`);
  };

  const onRowSelected = (event) => selectContents(event.api.getSelectedRows());

  const deleteSelection = () => {
    deleteVideo(selectedContents);
    selectContents(emptyArray);
  };

  return (
    <Fragment>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {capitalize(t('HELP_VIDEOS'))}
        </Text>
        {!selectedContents.length ? (
          <ActionButton icon="Plus" name="addVideo" onClick={() => history.push('/cms/helpvideos/create')} />
        ) : (
          <ActionSecondaryButton name="deleteVideo" icon="Trash2" onClick={deleteSelection} danger />
        )}
      </Row>

      <Filter onFilterSelect={(filter) => setFilters(filter)} storeName="Videos" />

      <ScrollableDataTableWithColSelector
        filters={filters}
        ready={!!filters}
        columnDefs={getColumnDefs(t)}
        defaultColDef={{
          valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
          onCellClicked: goToVideo
        }}
        getMethod={getVideoList}
        appendMethod={appendVideoRow}
        rows={videos}
        rowCount={videoCount}
        isFetching={isFetching}
        onRowSelected={onRowSelected}
        responseLength={responseLength}
      />
    </Fragment>
  );
};

Videos.propTypes = {
  history: object,
  t: func,
  videos: array,
  videoCount: number,
  isFetching: bool,
  appendContents: func,
  getContents: func,
  deleteContents: func,
  responseLength: number
};

export default compose(
  withRouter,
  translate(['cms']),
  connect(
    (state) => ({
      videos: pathOr(emptyArray, ['cms', 'list'], state),
      videoCount: pathOr(0, ['cms', 'count'], state),
      responseLength: pathOr(0, ['cms', 'responseLength'], state),
      isFetching: pathOr(false, ['cms', 'isFetching'], state)
    }),
    { getContents, appendContents, deleteContents }
  )
)(Videos);
