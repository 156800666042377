import React, { PureComponent } from 'react';
import { string, bool } from 'prop-types';
import { pathOr, is } from 'ramda';

import { formatStyleProps } from './decorators';
import { Switch, Input, Slider, Container } from './Toggle.style';
import { Row, Col } from './index';
import browserStore from '../utils/browserStore';

class Toggle extends PureComponent {
  static propTypes = {
    label: string,
    right: bool
  };

  static defaultProps = {
    onLoad: () => {},
    storeType: 'session',
    storeName: null,
    loading: false
  };

  componentDidMount() {
    const { onLoad, onCheck, storeType, storeName, name } = this.props;
    if (storeType && storeName) {
      const res = browserStore.getItem(storeType, `${storeName}:${name}`);

      if (is(Boolean, res)) {
        onCheck(res);
      }
    }

    onLoad();
  }

  handleCheck = () => {
    const { onCheck, storeType, storeName, name, loading } = this.props;
    const checked = pathOr(false, ['props', 'input', 'checked'], this);

    if (storeType && storeName) {
      browserStore.setItem(storeType, `${storeName}:${name}`, !checked);
    }
    if (is(Function, onCheck) && !loading) onCheck(!checked);
  };

  line(input, checked, onClick) {
    const { label, right } = this.props;
    return (
      <Switch active={checked} onClick={onClick}>
        {right ? '' : label}
        <Container active={checked}>
          <Input type="checkbox" {...input} />
          <Slider active={checked} />
        </Container>
        {right ? label : ''}
      </Switch>
    );
  }

  block(input, checked, onClick) {
    const { label, right } = this.props;
    return (
      <Switch active={checked} onClick={onClick}>
        <Row>
          {right ? null : <Col lg={6}>{label}</Col>}

          <Col lg={6}>
            <Container active={checked}>
              <Input type="checkbox" {...input} />
              <Slider active={checked} />
            </Container>
          </Col>

          {right ? <Col lg={6}>{label}</Col> : null}
        </Row>
      </Switch>
    );
  }

  render() {
    const input = pathOr(false, ['props', 'input'], this);

    if (!input) {
      return null;
    }

    const { disabled, blockView } = this.props;
    const onClick = disabled ? () => {} : this.handleCheck;
    if (blockView) {
      return this.block(input, input.checked, onClick);
    }

    return this.line(input, input.checked, onClick);
  }
}

export default formatStyleProps()(Toggle);
