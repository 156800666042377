import { is, prop } from 'ramda';
import { SubmissionError } from 'redux-form';

// eslint-disable-next-line
Error.prototype.formatBody = function (err) {
  return err;
};

export const handleErrors = async (res) => {
  if (!res.ok) {
    try {
      const body = await res.json();
      throw Error().formatBody({ ...body, status: res.status });
    } catch (err) {
      throw Error().formatBody({ ...err, status: res.status });
    }
  }
  if ([204].includes(res.status)) return Promise.resolve({ ok: true, status: 204 });

  if (res.headers.get('content-type') === 'application/pdf') {
    return res.blob();
  }

  return res.json();
};

export const handleFormError = (err = {}) => {
  if (err.data) return err.data;

  try {
    const message = JSON.parse(err.message);
    const _error = is(Array, message) ? message[0] : message;
    return {
      _error
    };
  } catch (error) {
    return err;
  }
};

export const handleFormErrors = (err) => {
  const data = prop('data', err);

  if (data && is(Object, data)) {
    return new SubmissionError(data);
  }

  return err;
};
