import React, { useEffect, useState } from 'react';
import Selector from './Selector';
import { func, string, array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, isEmpty, compose, map } from 'ramda';
import { emptyArray, makeOptions, upperCase } from '../../utils';
import { translate } from '../../translate/i18n';

const SelectorGrade = (props) => {
  const { t, list, name, onChange, isMulti, label, value, isInForm, isDisabled, placeholder } = props;

  const [options, setOptions] = useState([]);

  const handleChange = (val) => {
    if (isEmpty(val)) return onChange();
    return onChange(val);
  };

  useEffect(() => {
    const enums = map((option) => ({ value: option, name: t(`GRADES.${t(upperCase(option))}`) }), list);
    setOptions(makeOptions(enums, 'value', 'name', 'label'));
  }, [list]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

SelectorGrade.propTypes = {
  isInForm: bool,
  name: string.isRequired,
  list: array,
  getGrades: func,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  placeholder: string,
  isFetching: bool,
  t: func
};

SelectorGrade.defaultValues = {
  isInForm: false
};

export default compose(
  translate('inventories'),
  connect((state) => ({
    list: pathOr(emptyArray, ['enums', 'grades', 'list'], state),
    isFetching: pathOr(false, ['enums', 'grades', 'isFetching'], state)
  }))
)(SelectorGrade);
