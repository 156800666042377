import React, { useEffect, useState } from 'react';
import { compose, pathOr, propOr } from 'ramda';
import { connect } from 'react-redux';
import { withChatContext } from 'stream-chat-react';
import { func, object, bool } from 'prop-types';

import ChannelList from './ChannelList';
import { emptyObject } from '../../../utils';
import streamChat from '../../stream';

const ChannelListWrap = ({
  user,
  currentChannel,
  setActiveChannel,
  mainFilter,
  channel,
  navOpen,
  openMobileNav,
  closeMobileNav
}) => {
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    if (!propOr(null, 'id', user)) {
      setFilters(null);
      return;
    }

    setFilters({
      members: { $in: [user.id] },
      member_count: { $gt: 1 },
      type: { $in: ['communityManager', 'messaging'] },
      ...mainFilter
    });
  }, [user, mainFilter]);

  useEffect(() => {
    const currentChannelId = pathOr(null, ['id'], currentChannel);
    const channelId = pathOr(null, ['id'], channel);

    if (!currentChannelId && !navOpen && channelId) {
      openMobileNav();
    }
  }, [currentChannel, channel, navOpen]);

  useEffect(() => {
    const currentChannelId = pathOr(null, ['id'], currentChannel);
    const currentChannelType = pathOr(null, ['type'], currentChannel);
    const channelType = pathOr(null, ['type'], channel);
    const channelId = pathOr(null, ['id'], channel);

    if (!currentChannelId || !channel) {
      return;
    }

    if (currentChannelId === channelId && currentChannelType === channelType) {
      closeMobileNav();
      return;
    }

    setActiveChannel(streamChat.getChannel(currentChannel), {});
    closeMobileNav();
  }, [currentChannel, channel]);

  if (!filters) {
    return null;
  }

  return <ChannelList filters={filters} />;
};

ChannelListWrap.propTypes = {
  user: object.isRequired,
  mainFilter: object.isRequired,
  setActiveChannel: func.isRequired,
  openMobileNav: func.isRequired,
  closeMobileNav: func.isRequired,
  navOpen: bool.isRequired,
  channel: object,
  currentChannel: object
};

ChannelListWrap.defaultProps = {
  channel: null,
  currentChannel: null
};

export default compose(
  withChatContext,
  connect((state) => ({
    user: pathOr(emptyObject, ['user', 'data'], state),
    currentChannel: pathOr(null, ['chat', 'currentChannel'], state),
    mainFilter: pathOr(null, ['chat', 'search', 'filter'], state)
  }))
)(ChannelListWrap);
