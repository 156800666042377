export const GET_PACKS = 'GET_PACKS';
export const GET_PACKS_SUCCESS = 'GET_PACKS_SUCCESS';
export const GET_PACKS_FAIL = 'GET_PACKS_FAIL';

export const APPEND_PACKS = 'APPEND_PACKS';
export const APPEND_PACKS_SUCCESS = 'APPEND_PACKS_SUCCESS';
export const APPEND_PACKS_FAIL = 'APPEND_PACKS_FAIL';

export const GET_PACK = 'GET_PACK';
export const GET_PACK_SUCCESS = 'GET_PACK_SUCCESS';
export const GET_PACK_FAIL = 'GET_PACK_FAIL';

export const UPSERT_PACK = 'UPSERT_PACK';

export const DUPLICATE_PACK = 'DUPLICATE_PACK';
export const DUPLICATE_PACK_SUCCESS = 'DUPLICATE_PACK_SUCCESS';
export const DUPLICATE_PACK_FAIL = 'DUPLICATE_PACK_FAIL';
