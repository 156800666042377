import { prop } from 'ramda';
import { hubInUtcFormatToLocalDate } from '../utils/date';

export const initValues = (lease) => {
  const moveoutDate = prop('moveoutDate', lease);
  const preferredMoveoutDate = prop('preferredMoveoutDate', lease);
  const timezone = prop('timezone', lease);

  return {
    id: prop('id', lease),
    timezone,
    moveoutDate: moveoutDate && timezone ? hubInUtcFormatToLocalDate(moveoutDate, timezone) : null,
    preferredMoveoutDate:
      preferredMoveoutDate && timezone ? hubInUtcFormatToLocalDate(preferredMoveoutDate, timezone) : null
  };
};
