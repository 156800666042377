import React from 'react';
import { ChannelPreview, Avatar, withChatContext } from 'stream-chat-react';
import { compose } from 'ramda';
import { func, object, number } from 'prop-types';

import Preview from './Preview';

/**
 * This file is a copy of Stream Component ChannelList adapted to our requirement.
 * Keep custom modifications to the minimum for future compatibility!
 * See more here: https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelList/ChannelList.tsx
 */

const ChannelPreviewWrap = ({ item, channel, channelUpdateCount, setActiveChannel, watchers }) => {
  if (!item) return null;

  const previewProps = {
    activeChannel: channel,
    Avatar,
    channel: item,
    channelUpdateCount,
    key: item.cid,
    Preview,
    setActiveChannel,
    watchers
  };

  return <ChannelPreview {...previewProps} />;
};

ChannelPreviewWrap.propTypes = {
  watchers: object,
  setActiveChannel: func.isRequired,
  channelUpdateCount: number.isRequired,
  item: object,
  channel: object
};

ChannelPreviewWrap.defaultProps = {
  watchers: {},
  item: null
};

export default compose(withChatContext)(ChannelPreviewWrap);
