import React, { useEffect, useState } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { compose, isEmpty } from 'ramda';

import { getStatusList } from './applications.utils';
import { storableSelector } from '../components';
import Selector from '../components/selector/Selector';
import { translate } from '../translate/i18n';

const ApplicationStatusSelect = ({
  t,
  name,
  label,
  value,
  isInForm,
  isDisabled,
  isClearable,
  placeholder,
  isMulti,
  onChange,
  valueControl
}) => {
  const handleChange = (val) => onChange(val === 'all' || !val ? null : val);

  const [options] = useState(() => getStatusList(t));

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

ApplicationStatusSelect.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  t: func.isRequired
};

ApplicationStatusSelect.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null
};

export default compose(storableSelector, translate(['applications', 'default']))(ApplicationStatusSelect);
