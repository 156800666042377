import { isEmpty } from 'ramda';

class BrowserStore {
  getItem(type, name) {
    if (type === 'const') {
      try {
        return JSON.parse(localStorage.getItem(name));
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return;
    }

    try {
      return JSON.parse(sessionStorage.getItem(name));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  setItem(type, name, value) {
    if (isEmpty(value) || (!value && value !== false)) {
      return this.removeItem(type, name);
    }

    if (type === 'const') {
      try {
        return localStorage.setItem(name, JSON.stringify(value));
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return;
    }

    try {
      return sessionStorage.setItem(name, JSON.stringify(value));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  removeItem(type, name) {
    if (type === 'const') {
      return localStorage.removeItem(name);
    }

    return sessionStorage.removeItem(name);
  }
}

export default new BrowserStore();
