import {
  GET_PACKS,
  GET_PACKS_SUCCESS,
  GET_PACKS_FAIL,
  APPEND_PACKS,
  APPEND_PACKS_FAIL,
  APPEND_PACKS_SUCCESS,
  GET_PACK,
  GET_PACK_FAIL,
  GET_PACK_SUCCESS,
  DUPLICATE_PACK,
  DUPLICATE_PACK_SUCCESS,
  DUPLICATE_PACK_FAIL
} from './packs.actionTypes';
import { upsertPack } from './packs.actions';
import {
  genState,
  setFetching,
  setCurrent,
  setCurrentFailure,
  appendList,
  setListFailure,
  setList,
  setFetchingCurrent,
  appendListFailure
} from '../utils/reducer';

const packs = (state) => ({
  [GET_PACKS]: setFetching(state, true),
  [GET_PACKS_SUCCESS]: setList(state),
  [GET_PACKS_FAIL]: setListFailure(state),
  [APPEND_PACKS]: setFetching(state, true),
  [APPEND_PACKS_SUCCESS]: appendList(state),
  [APPEND_PACKS_FAIL]: appendListFailure(state),
  [GET_PACK]: setFetchingCurrent(state, true),
  [GET_PACK_SUCCESS]: setCurrent(state),
  [GET_PACK_FAIL]: setCurrentFailure(state),
  [upsertPack.SUCCESS]: setCurrent(state),
  [upsertPack.FAILURE]: setFetching(state, false),
  [DUPLICATE_PACK]: () => setCurrent(state),
  [DUPLICATE_PACK_SUCCESS]: setCurrent(state),
  [DUPLICATE_PACK_FAIL]: setFetching(state, false)
});

export const packsReducer = (state = {}, action) => {
  const { type, payload } = action;

  return genState({ state, payload, stateDef: packs, type });
};
