import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as service from './room.service';
import * as lockService from '../lock/lock.service';
import { GET_ROOMS, APPEND_ROOMS, GET_ROOM, DELETE_ROOMS, DELETE_ROOM_LOCK } from './room.actionTypes';
import {
  setRooms,
  appendRoomsFail,
  appendRoomsSuccess,
  setRoom,
  getRoomFailure,
  upsertRoom,
  upsertRoomLock,
  deleteRoomsFailure,
  deleteRoomsSuccess,
  deleteRoomLockSuccess
} from './room.actions';
import { prop, propOr } from 'ramda';
import { types, defActionType } from '../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';

export function* getRoom(action) {
  try {
    const id = propOr(null, 'payload', action);
    const room = yield call(service.getRoom, id);

    yield put(setRoom(room));
  } catch (err) {
    yield put(getRoomFailure(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.ROOM, error: err, data: action.payload }));
  }
}

export function* getRooms(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const rooms = yield call(service.getRoomList, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(setRooms(rooms));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.ROOM, error: err, data: action.payload }));
  }
}

export function* appendRooms(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const rooms = yield call(service.getRoomList, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(appendRoomsSuccess(rooms));
  } catch (err) {
    yield put(appendRoomsFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.ROOM, error: err, data: action.payload }));
  }
}

export function* _upsertRoom(action) {
  const payload = prop('payload', action);
  const key = defActionType(payload);
  try {
    const roomData = { ...payload };

    const savedRoom = yield call(service.upsertRoom, roomData);
    yield put(upsertRoom.success(savedRoom));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.ROOM }));
  } catch (err) {
    yield put(upsertRoom.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.ROOM, error: err, data: action.payload }));
  }
}

export function* _upsertRoomLock(action) {
  const { lock, roomId } = action.payload;
  const key = lock.id ? 'UPDATE' : 'CREATE';
  const category = 'room';

  try {
    const _lock = yield call(lockService.upsertLock, lock.id ? lock : { ...lock, roomId, category });

    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.LOCK }));
    yield put(
      upsertRoomLock.success({
        roomId,
        lock: _lock
      })
    );
  } catch (error) {
    yield put(upsertRoomLock.failure(error));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.LOCK, data: action.payload }));
  }
}

export function* deleteRooms(action) {
  try {
    const rooms = propOr([], 'payload', action);
    yield call(service.deleteRooms, rooms);

    yield put(deleteRoomsSuccess(rooms));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.ROOM }));
  } catch (err) {
    yield put(deleteRoomsFailure(err));
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.ROOM, error: err, data: action.payload }));
  }
}

export function* deleteRoomLock({ payload }) {
  try {
    yield call(lockService.deleteLock, payload);
    yield put(deleteRoomLockSuccess(payload));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.LOCK }));
  } catch (err) {
    yield put(handleErrorMessage('DELETE.SUCCESS', { type: types.LOCK }));
  }
}

export default function* roomSaga() {
  yield all([
    takeLatest(APPEND_ROOMS, appendRooms),
    takeLatest(GET_ROOMS, getRooms),
    takeLatest(GET_ROOM, getRoom),
    takeLatest(upsertRoom.REQUEST, _upsertRoom),
    takeLatest(upsertRoomLock.REQUEST, _upsertRoomLock),
    takeLatest(DELETE_ROOMS, deleteRooms),
    takeLatest(DELETE_ROOM_LOCK, deleteRoomLock)
  ]);
}
