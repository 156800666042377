import React from 'react';
import { string, func } from 'prop-types';
import { capitalize } from '../utils';
import { translate } from '../translate/i18n';
import Text from './Text';

const SectionTitle = (props) => {
  const { translationKey, t } = props;
  return (
    <Text weight={400} grey bb-lightGrey pb-quarter mb>
      {capitalize(t(translationKey))}
    </Text>
  );
};

SectionTitle.propTypes = {
  translationKey: string.isRequired,
  t: func.isRequired
};

export default translate()(SectionTitle);
