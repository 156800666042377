import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';
import hash from 'object-hash';
import { compose, values, all, reduce, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { capitalize, substractDaysFromDate, addDaysToDate, handleFilters } from '../utils';
import {
  Row,
  Col,
  SelectorHub,
  SelectorCreated,
  SelectorHouse,
  SearchTextInput,
  Toggle,
  SelectorLeaseStatus,
  collapsible
} from '../components';

export const getTimeMask = (t) => {
  const currentDate = new Date();

  return {
    ALL: {
      label: t('ALL'),
      filter: 'createdAt',
      value: 'all',
      date: null
    },
    MOVE_IN_DATE_LT_60: {
      label: t('MOVE_IN_DATE_LT_60'),
      filter: 'startDate',
      value: 'MOVE_IN_DATE_LT_60',
      date: addDaysToDate(currentDate, 60)
    },
    MOVE_OUT_DATE_LT_60: {
      label: t('MOVE_OUT_DATE_LT_60'),
      filter: 'endDate',
      value: 'MOVE_OUT_DATE_LT_60',
      date: addDaysToDate(currentDate, 60)
    },
    '7_LAST_DAYS': {
      label: t('7_LAST_DAYS'),
      filter: 'createdAt',
      value: '7_LAST_DAYS',
      date: substractDaysFromDate(currentDate, 7)
    },
    LAST_MONTH: {
      label: t('LAST_MONTH'),
      filter: 'createdAt',
      value: 'LAST_MONTH',
      date: substractDaysFromDate(currentDate, 30)
    },
    '3_LAST_MONTHS': {
      label: t('3_LAST_MONTHS'),
      filter: 'createdAt',
      value: '3_LAST_MONTHS',
      date: substractDaysFromDate(currentDate, 90)
    },
    '6_LAST_MONTHS': {
      label: t('6_LAST_MONTHS'),
      filter: 'createdAt',
      value: '6_LAST_MONTHS',
      date: substractDaysFromDate(currentDate, 180)
    },
    '12_LAST_MONTHS': {
      label: t('12_LAST_MONTHS'),
      filter: 'createdAt',
      value: '12_LAST_MONTHS',
      date: substractDaysFromDate(currentDate, 365)
    }
  };
};

const LeasesFilter = ({ t, onFilterSelect }) => {
  const [createdMask, setCreatedMask] = useState({});
  let [hashCode, setHashCode] = useState('');

  let [filters, setFilters] = useState({
    search: '',
    date: null,
    hubId: [],
    validateExtension: false,
    status: '',
    houseId: []
  });

  let [loaded, setLoaded] = useState({
    search: false,
    date: false,
    hubId: false,
    houseId: false,
    validateExtension: false,
    status: false
  });

  const handleChange = (filter) => (value) => {
    filters[filter] = value;
    setFilters({
      ...filters
    });
  };

  const onLoad =
    (filter) =>
    (val = true) => {
      if (loaded[filter] !== val) {
        loaded[filter] = val;
        setLoaded({
          ...loaded
        });
      }
    };

  useEffect(() => {
    setCreatedMask(getTimeMask(t));
  }, []);

  useEffect(() => {
    if (all((item) => item)(values(loaded))) {
      const newHashCode = hash(filters);
      if (newHashCode !== hashCode) {
        hashCode = newHashCode;
        setHashCode(newHashCode);
        onFilterSelect(
          reduce(
            (all, filter) => {
              if (filter === 'date') {
                if (filters[filter]) {
                  return {
                    ...all,
                    [createdMask[filters[filter]].filter]: createdMask[filters[filter]].date
                  };
                }
                return all;
              }
              return handleFilters(all, filter, filters[filter]);
            },
            {},
            keys(filters)
          )
        );
      }
    }
  }, [filters, loaded]);

  return (
    <>
      <Row middle mb>
        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SearchTextInput
            label={capitalize(t('default:SEARCH'))}
            name="search"
            placeholder={t('default:SEARCH')}
            value={filters.search}
            onChange={handleChange('search')}
            onLoad={onLoad('search')}
            storeName="Leases"
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SelectorCreated
            name="date"
            onChange={handleChange('date')}
            value={filters.date}
            onLoad={onLoad('date')}
            storeName="Leases"
            ready={!!createdMask}
            options={values(createdMask)}
            label={t('DATE')}
            placeholder={t('form:CHOOSE_A_DATE')}
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SelectorLeaseStatus
            name="status"
            onChange={handleChange('status')}
            value={filters.status}
            onLoad={onLoad('status')}
            storeName="Leases"
            label={t('default:STATUS')}
            placeholder={t('default:STATUS')}
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SelectorHub
            name="hub"
            onChange={handleChange('hubId')}
            isMulti
            label={t('HUB')}
            placeholder={t('form:TYPE_A_HUB_NAME')}
            value={filters.hubId}
            onLoad={onLoad('hubId')}
            storeName="Leases"
            storeType="const"
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SelectorHouse
            name="house"
            label={t('HOUSE')}
            isMulti
            hubId={filters.hubId}
            onChange={handleChange('houseId')}
            isClearable
            ready={loaded.hubId}
            value={filters.houseId}
            onLoad={onLoad('houseId')}
            placeholder={t('form:CHOOSE_A_HOUSE')}
            storeName="Leases"
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pt pr-half mb>
          <Toggle
            label={t('WAITING_FOR_EXTENSION_VALIDATION')}
            input={{ checked: filters.validateExtension, onChange: () => null }}
            onCheck={handleChange('validateExtension')}
            onLoad={onLoad('validateExtension')}
            name="validateExtension"
            storeName="Leases"
          />
        </Col>
      </Row>
    </>
  );
};

LeasesFilter.propTypes = {
  onFilterSelect: func,
  withStatus: bool,
  t: func
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_FILTER',
    labelShow: 'default:SHOW_FILTER'
  }),
  translate(['leases', 'default', 'rooms', 'house'])
)(LeasesFilter);
