import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { reduxForm, Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose, path } from 'ramda';
import { translate } from '../../translate/i18n';
import { BottomContainer, Button, Row, Block, Col, Text, TextInput, SelectHubForm } from '../../components';
import StyledForm from '../../components/form.styles';
import { StyledLabel } from '../../components/form/TextInput.styles';
import { required } from '../../utils';
import { upsertPackLocation } from './packLocations.actions';

/**
 * Pack form
 * @param {*} props
 */
const PackLocationForm = ({ t, onCancel, submitting, handleSubmit, submitSucceeded, initialValues }) => {
  if (submitSucceeded) {
    return <Redirect to={`/packs/${initialValues.packId}`} />;
  }

  const packName = path(['pack', 'name'], initialValues);
  const hubName = path(['hub', 'name'], initialValues);

  // Render
  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(upsertPackLocation)}>
        <Block>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} pr-half pl-half>
              <Row mb>
                <Text weight={400}>{t('form:GENERAL_INFO')}</Text>
              </Row>

              <Row>
                <StyledLabel>{t('PACK')}</StyledLabel>
              </Row>

              <Row mb>
                <Text>{packName}</Text>
              </Row>

              <Row mb>
                {initialValues.isNew ? (
                  <Field component={SelectHubForm} validate={required} label={t('HUB')} name="hubId" />
                ) : (
                  <Fragment>
                    <Row>
                      <StyledLabel>{t('HUB')}</StyledLabel>
                    </Row>
                    <Row>
                      <Text>{hubName}</Text>
                    </Row>
                  </Fragment>
                )}
              </Row>
            </Col>

            <Col xl={4} lg={6} md={6} sm={12} pr-half pl-half>
              <Row mb>
                <Text weight={400}>{t('PRICING')}</Text>
              </Row>

              <Field component={TextInput} validate={required} label={t('PRICE')} name="price" />
            </Col>
          </Row>
        </Block>

        <BottomContainer negativeMargin={true}>
          <Row right>
            <Button name="cancel" disabled={submitting} onClick={() => onCancel()} clear size="md">
              {t('CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </BottomContainer>
      </StyledForm>
    </Fragment>
  );
};

PackLocationForm.propTypes = {
  t: func,
  isNew: bool,
  ...reduxFormPropTypes
};

export default compose(
  translate('packs'),
  reduxForm({ form: 'PackLocationForm', enableReinitialize: true, destroyOnUnmount: true })
)(PackLocationForm);
