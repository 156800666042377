import { createFormAction } from 'redux-form-saga';
import {
  CREATE_HOUSE_ISSUE,
  UPSERT_INVENTORY,
  GET_INVENTORIES,
  GET_INVENTORIES_SUCCESS,
  GET_INVENTORIES_FAIL,
  APPEND_INVENTORIES,
  APPEND_INVENTORIES_SUCCESS,
  APPEND_INVENTORIES_FAIL,
  GET_INVENTORY,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAIL,
  COMPARE_INVENTORIES,
  COMPARE_INVENTORIES_FAIL,
  COMPARE_INVENTORIES_SUCCESS,
  GET_LAST_INVENTORY,
  GET_LAST_INVENTORY_FAIL,
  CLEAR_INVENTORY
} from './inventories.actionTypes';

export const upsertInventory = createFormAction(UPSERT_INVENTORY);
export const createHouseIssue = createFormAction(CREATE_HOUSE_ISSUE);

export const getInventories = (params = {}) => ({
  type: GET_INVENTORIES,
  payload: params
});

export const setInventories = (inventories) => ({
  type: GET_INVENTORIES_SUCCESS,
  payload: inventories
});

export const getInventoriesFail = (err) => {
  return {
    type: GET_INVENTORIES_FAIL,
    payload: err
  };
};

export const clearCurrentInventory = () => ({
  type: CLEAR_INVENTORY
});

export const getInventory = (id) => ({
  type: GET_INVENTORY,
  payload: id
});

export const setInventory = (inventory) => ({
  type: GET_INVENTORY_SUCCESS,
  payload: inventory
});

export const getInventoryFail = (err) => {
  return {
    type: GET_INVENTORY_FAIL,
    payload: err
  };
};

export const getLastInventory = (id) => ({
  type: GET_LAST_INVENTORY,
  payload: id
});

export const getLastInventoryFail = (err) => {
  return {
    type: GET_LAST_INVENTORY_FAIL,
    payload: err
  };
};

export const appendInventories = (params = {}) => ({
  type: APPEND_INVENTORIES,
  payload: params
});

export const appendInventoriesSucess = (inventories) => ({
  type: APPEND_INVENTORIES_SUCCESS,
  payload: inventories
});

export const appendInventoriesFail = (err) => ({
  type: APPEND_INVENTORIES_FAIL,
  payload: err
});

export const compareInventories = (params = {}) => ({
  type: COMPARE_INVENTORIES,
  payload: params
});

export const compareInventoriesSucess = (inventories) => ({
  type: COMPARE_INVENTORIES_SUCCESS,
  payload: inventories
});

export const compareInventoriesFail = (err) => ({
  type: COMPARE_INVENTORIES_FAIL,
  payload: err
});
