import { createFormAction } from 'redux-form-saga';
import {
  GET_PAYMENTS,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAILURE,
  APPEND_PAYMENTS,
  APPEND_PAYMENTS_SUCCESS,
  APPEND_PAYMENTS_FAILURE,
  UPSERT_DAMAGE,
  UPDATE_DAMAGE,
  UPSERT_COMMENT,
  SET_PAYMENT_MEMBER
} from './payments.actionTypes';

export const upsertDamage = createFormAction(UPSERT_DAMAGE);
export const updateDamage = createFormAction(UPDATE_DAMAGE);
export const upsertComment = createFormAction(UPSERT_COMMENT);

export const setMember = (params = {}) => ({
  type: SET_PAYMENT_MEMBER,
  payload: params
});

export const getPayments = (params = {}) => ({
  type: GET_PAYMENTS,
  payload: params
});

export const setPayments = (payments) => ({
  type: GET_PAYMENTS_SUCCESS,
  payload: payments
});

export const getPaymentsFailure = (err) => {
  return {
    type: GET_PAYMENTS_FAILURE,
    payload: err
  };
};

export const appendPayments = (params = {}) => ({
  type: APPEND_PAYMENTS,
  payload: params
});

export const appendPaymentsSuccess = (payments) => ({
  type: APPEND_PAYMENTS_SUCCESS,
  payload: payments
});

export const appendPaymentsFailure = (err) => ({
  type: APPEND_PAYMENTS_FAILURE,
  payload: err
});
