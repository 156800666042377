import React, { Fragment } from 'react';
import { Field, formValues } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../../translate/i18n';
import { Col, Row, Text, SelectHouseForm, SelectorRoomForm } from '../../components';
import { capitalize } from '../../utils';

const HouseRoomSelection = ({ t, houseId, roomRequired } = {}) => {
  return (
    <Fragment>
      <Row mb-quarter>
        <Text size="lg" weight={300}>
          {capitalize(t('LOCATION'))}
        </Text>
      </Row>

      <Row mb>
        <Text>{t('FORM.ISSUE_SECTION_MESSAGE')}</Text>
      </Row>

      <Row>
        <Col xl={3} lg={4} md={6} sm={12} pr-half>
          <Field component={SelectHouseForm} label={`${t('FORM:HOUSE')}*`} name="houseId" />
        </Col>

        <Col xl={3} lg={4} md={6} sm={12} pr-half>
          <Field
            name="roomId"
            label={`${capitalize(t('ROOM'))}${roomRequired ? '*' : ''}`}
            placeholder={t('FORM.SELECT_A_ROOM')}
            houseId={houseId}
            isDisabled={!houseId}
            component={SelectorRoomForm}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default compose(translate('inventories'), formValues({ houseId: 'houseId' }))(HouseRoomSelection);
