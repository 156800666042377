import React, { Fragment, useState } from 'react';
import { func, object, number, bool, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PlusCircle, Download } from 'react-feather';
import { pathOr, prop } from 'ramda';
import { translate } from '../translate/i18n';
import { getPayments, appendPayments } from './payments.actions';
import {
  capitalize,
  valueOrPlaceholder,
  isNotEmptyOrNil,
  emptyArray,
  formatDatetime,
  toast,
  isEmptyOrNil,
  emptyObject
} from '../utils';
import { Row, Text, ScrollableDataTableWithColSelector, Button, Modal, ConfirmModal, Col, Toggle } from '../components';
import DamageForm from './Payment.damage.form';
import DamageCreateForm from './Payment.damageCreate.form';
import { refund } from './payments.service';
import { updateMemberInfo } from '../members/member.actions';

const DownloadLink = (props) => {
  const link = prop('value', props);

  if (isEmptyOrNil(link)) {
    return <div />;
  }

  return (
    <a href={link} title="Attachment">
      <Download size={16} />
    </a>
  );
};

const getColumnDefs = (t) => [
  {
    headerName: t('HOUSE'),
    defaultActive: true,
    field: 'id',
    cellClassRules: {
      'cell-span': 'true'
    },
    width: 80,
    rowSpan: (params) => (params.data.count ? params.data.count : 1),
    valueFormatter: ({ data }) => pathOr('', ['lease', 'room', 'house', 'name'], data)
  },
  {
    headerName: t('ROOM'),
    defaultActive: true,
    field: 'id',
    cellClassRules: {
      'cell-span': 'true'
    },
    width: 80,
    rowSpan: (params) => (params.data.count ? params.data.count : 1),
    valueFormatter: ({ data }) => pathOr('', ['lease', 'room', 'number'], data)
  },
  {
    headerName: t('DEPOSIT'),
    field: 'id',
    cellClassRules: {
      'cell-span': 'true'
    },
    width: 80,
    rowSpan: (params) => (params.data.count ? params.data.count : 1),
    valueFormatter: ({ data }) => {
      const depositSum = pathOr([], ['lease', 'deposits'], data).reduce(
        (sum, deposit) => sum + +pathOr(0, ['payment', 'amount'], deposit),
        0
      );

      return `${pathOr('', ['lease', 'room', 'house', 'hub', 'currency', 'symbol'], data)} ${depositSum.toFixed(2)}`;
    }
  },
  {
    headerName: t('RECEIVED_AMOUNT'),
    defaultActive: true,
    field: 'id',
    cellClassRules: {
      'cell-span': 'true'
    },
    width: 80,
    rowSpan: (params) => (params.data.count ? params.data.count : 1),
    valueFormatter: ({ data }) => {
      const deposits = pathOr([], ['lease', 'deposits'], data);

      if (deposits.some((deposit) => pathOr(false, ['payment', 'disputed'], deposit))) {
        return t('DISPUTED');
      }

      const depositSum = deposits.reduce((sum, deposit) => sum + +pathOr(0, ['payment', 'paid'], deposit), 0);

      return `${pathOr('', ['lease', 'room', 'house', 'hub', 'currency', 'symbol'], data)} ${depositSum.toFixed(2)}`;
    }
  },
  {
    headerName: t('MOVEMENT_DATE'),
    field: 'createdAt',
    width: 140,
    valueFormatter: ({ value }) => valueOrPlaceholder(isNotEmptyOrNil, formatDatetime)(value, 'UTC'),
    tooltip: ({ value }) => valueOrPlaceholder(isNotEmptyOrNil, () => 'UTC', '')(value)
  },
  {
    headerName: t('AMOUNT'),
    defaultActive: true,
    field: 'amount',
    width: 120,
    valueFormatter: ({ data, value }) =>
      `${pathOr('', ['lease', 'room', 'house', 'hub', 'currency', 'symbol'], data)} ${value}`
  },
  {
    headerName: t('DESCRIPTION'),
    field: 'description'
  },
  {
    headerName: t('BILL'),
    field: 'bill',
    width: 120,
    cellRendererFramework: DownloadLink
  },
  {
    headerName: t('PICTURE'),
    field: 'picture',
    width: 120,
    cellRendererFramework: DownloadLink
  },
  {
    headerName: t('REFUNDED_AMOUNT'),
    field: 'id',
    cellClassRules: {
      'cell-span': 'true'
    },
    width: 80,
    rowSpan: (params) => (params.data.count ? params.data.count : 1),
    valueFormatter: ({ data }) => {
      const depositSum = pathOr([], ['lease', 'deposits'], data).reduce(
        (sum, deposit) => sum + +pathOr(0, ['refundedAmount'], deposit),
        0
      );

      return `${pathOr('', ['lease', 'room', 'house', 'hub', 'currency', 'symbol'], data)} ${depositSum.toFixed(2)}`;
    }
  }
];

const Deposit = (props) => {
  // Props
  const {
    t,
    member,
    hub,
    payments,
    paymentCount,
    isFetching,
    appendPayments,
    getPayments,
    responseLength,
    updateMemberInfo,
    isUpdating
  } = props;

  // State
  const [showDamageModal, toggleDamageModal] = useState(false);
  const [showRefundModal, toggleRefundModal] = useState(false);
  const [autorefundToogleLoaded, setAutorefundToogleLoaded] = useState(false);
  const [damageData, setDamageData] = useState(null);

  // Functions
  const closeDamageModal = () => {
    toggleDamageModal(false);
    setDamageData(null);
  };
  const closeRefundModal = () => toggleRefundModal(false);

  const countRowSpan = (payments) => {
    const mask = {};
    payments.map((item) => {
      if (!mask[item.leaseId]) {
        mask[item.leaseId] = item;
        mask[item.leaseId].count = 1;
        return item;
      }

      mask[item.leaseId].count += 1;
      return item;
    });

    return payments;
  };

  const handleAutorefundChange = (checked) => {
    if (autorefundToogleLoaded) {
      updateMemberInfo({ ...member, autoRefundDeposit: checked });
    }
  };

  // Render
  return (
    <Fragment>
      <Row middle>
        <Col xl={7} lg={3} md={6} sm={12} mb>
          <Text weight={200} size="xl">
            {capitalize(t('DEPOSIT_HISTORY'))}
          </Text>
        </Col>

        <Col xl={5} lg={9} md={6} sm={12}>
          <Row right middle>
            <Col xl={4} lg={3} md={6} sm={4} mb>
              <Toggle
                label={t('AUTOREFUND')}
                input={{ checked: member.autoRefundDeposit, onChange: () => null }}
                onCheck={(checked) => handleAutorefundChange(checked)}
                onLoad={() => setAutorefundToogleLoaded(true)}
                loading={isUpdating}
                name="autoRefundDeposit"
                storeName="member"
              />
            </Col>

            <Col xl={5} lg={4} md={6} sm={5} pr-half mb>
              <Button
                size="md"
                name="addDamage"
                bg-secondary
                onClick={() => {
                  setDamageData({ damage: null, update: false });
                  toggleDamageModal(true);
                }}>
                <PlusCircle style={{ marginRight: '5px' }} size={14} /> {t('ADD_DAMAGE')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <ScrollableDataTableWithColSelector
        columnDefs={getColumnDefs(t)}
        defaultColDef={{
          valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
          sortable: false
        }}
        getMethod={(params) => getPayments({ ...params, userId: member.id, hubId: hub.id, paymentType: 'deposit' })}
        appendMethod={(params) =>
          appendPayments({ ...params, userId: member.id, hubId: hub.id, paymentType: 'deposit' })
        }
        rows={countRowSpan(payments)}
        rowCount={paymentCount}
        isFetching={isFetching}
        responseLength={responseLength}
        onCellClicked={({ data, colDef: { field } }) => {
          if (!['id', 'bill', 'picture'].includes(field)) {
            setDamageData({ damage: data, update: true });
            toggleDamageModal(true);
          }
        }}
      />

      <Modal
        title={t('ADD_DAMAGE')}
        show={showDamageModal}
        onClose={closeDamageModal}
        content={() =>
          damageData.update ? (
            <DamageForm
              initialValues={{
                leaseId: prop('leaseId', damageData.damage),
                userId: member.id,
                hubId: hub.id
              }}
              onCancel={closeDamageModal}
              lease={prop('lease', damageData.damage)}
              isUpdate
              damage={damageData.damage}
            />
          ) : (
            <DamageCreateForm
              initialValues={{
                userId: member.id,
                hubId: hub.id
              }}
              onCancel={closeDamageModal}
              member={member}
              hub={hub}
            />
          )
        }
      />

      <ConfirmModal
        title={t('REFUND_DEPOSIT')}
        show={showRefundModal}
        onClose={closeRefundModal}
        onConfirm={() => {
          refund(pathOr('', ['damage', 'lease', 'deposits', '0', 'id'], damageData))
            .then(() => {
              toast.success(t('REFUND SUCCESS'));
            })
            .catch(() => {
              toast.error(t('REFUND_ERROR'));
            });
          closeRefundModal();
        }}
        content={() => <Text>{t('REFUND_CONFIRM', { user: member })}</Text>}
      />
    </Fragment>
  );
};

Deposit.propTypes = {
  history: object,
  t: func,
  payments: array,
  paymentCount: number,
  responseLength: number,
  isFetching: bool,
  appendPayments: func,
  getPayments: func,
  member: object,
  hub: object,
  deposit: object,
  updateMemberInfo: func,
  isUpdating: bool
};

export default compose(
  translate(['payments']),
  connect(
    (state) => {
      return {
        payments: pathOr(emptyArray, ['payments', 'list'], state),
        paymentCount: pathOr(0, ['payments', 'count'], state),
        deposit: pathOr(emptyObject, ['payments', 'deposit'], state),
        responseLength: pathOr(0, ['payments', 'responseLength'], state),
        isFetching: pathOr(false, ['payments', 'isFetching'], state),
        member: pathOr(emptyObject, ['member', 'current', 'data'], state),
        isUpdating: pathOr(false, ['member', 'current', 'isUpdating'], state)
      };
    },
    { getPayments, appendPayments, updateMemberInfo }
  )
)(Deposit);
