import * as api from '@cohabs/bo-api';
import { defaultPaginationLimit } from '../utils';

export const getInventory = (id) => api.get(`/inventory/${id}`);

export const getLastInventory = (roomId) => api.get(`/inventory/last/room/${roomId}`);

export const compareInventories = (id) => api.get(`/inventory/${id}/compare`);

export const getInventories = ({
  page = 1,
  limit = defaultPaginationLimit,
  criteria = {},
  order = [['createdAt', 'DESC']]
}) => {
  if (page < 1) page = 1;
  if (limit < 1) limit = defaultPaginationLimit;
  return api.post(`/inventories/${page}/${limit}`, { criteria, order });
};

export const createInventory = (inventory) => api.post('/inventory', inventory);
export const createHouseIssue = (data) => api.post('/house-issue', data);
export const updateInventory = (inventory) => api.put(`/inventory/${inventory.id}`, inventory);
export const upsertInventory = (inventory) => (!inventory.id ? createInventory(inventory) : updateInventory(inventory));
