import { all } from 'redux-saga/effects';
import userSagas from '../user/user.sagas';
import roomSagas from '../rooms/room.sagas';
import applicationSagas from '../applications/applications.sagas';
import housesSaga from '../houses/houses.sagas';
import hubSaga from '../hub/hub.sagas';
import collectionSaga from '../collection/collection.sagas';
import incidentsSaga from '../incidents/incidents.sagas';
import memberSaga from '../members/member.sagas';
import enumSaga from '../enum/enum.sagas';
import geoSaga from '../geo/geo.sagas';
import formActionSaga from 'redux-form-saga';
import leasesSaga from '../leases/leases.sagas';
import inventoriesSaga from '../inventories/inventories.sagas';
import packsSaga from '../packs/packs.sagas';
import packLocationsSaga from '../packs/packLocations/packLocations.sagas';
import cmsSaga from '../cms/cms.sagas';
import paymentsSaga from '../payments/payments.sagas';
import toastSaga from '../toast/toast.sagas';
import paymentProfileSaga from '../paymentProfile/paymentProfile.sagas';
import hubPaymentMethodSaga from '../hubPaymentMethod/hubPaymentMethod.sagas';
import paymentProfileMethodSaga from '../paymentProfileMethod/paymentProfileMethod.sagas';
import paymentGatewaySaga from '../paymentGateway/paymentGateway.sagas';
import chatSaga from '../chat/chat.sagas';
import segmentSaga from '../segment/segment.sagas';
import discountPromiseSaga from '../discounts/discountPromise/discountPromise.sagas';
import discountSaga from '../discounts/discount/discount.sagas';

export default function* root() {
  yield all([
    discountSaga(),
    discountPromiseSaga(),
    paymentGatewaySaga(),
    paymentProfileMethodSaga(),
    hubPaymentMethodSaga(),
    paymentProfileSaga(),
    userSagas(),
    roomSagas(),
    applicationSagas(),
    housesSaga(),
    hubSaga(),
    collectionSaga(),
    incidentsSaga(),
    memberSaga(),
    enumSaga(),
    geoSaga(),
    formActionSaga(),
    leasesSaga(),
    inventoriesSaga(),
    packsSaga(),
    cmsSaga(),
    paymentsSaga(),
    toastSaga(),
    packLocationsSaga(),
    chatSaga(),
    segmentSaga()
  ]);
}
