import { takeLatest, call, all, put } from 'redux-saga/effects';
import { propOr, prop } from 'ramda';
import * as service from './leases.service';
import {
  GET_LEASES,
  GET_HELLO_SIGN_LEASE,
  GET_LEASE,
  APPEND_LEASES,
  CANCEL_LEASE,
  GET_LEASE_DOCUMENTS
} from './leases.actionTypes';
import {
  setLeases,
  setLease,
  setHelloSignLease,
  setAccessRestricted,
  getLeaseFail,
  getLeasesFail,
  appendLeasesFail,
  appendLeasesSucess,
  getLeaseDocumentsFail,
  getLeaseDocumentsSuccess,
  updateLease
} from './leases.actions';
import * as memberActions from '../members/member.actions';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import { types } from '../utils/sagas';
import { localDateToHubInUtcFormat } from '../utils/date';

export function* getLeases(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const leases = yield call(service.getLeasesList, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(setLeases(leases));
  } catch (err) {
    yield put(getLeasesFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.LEASE, error: err, data: action.payload }));
  }
}

export function* getLease(action) {
  try {
    const lease = yield call(service.getLease, prop('payload', action));
    yield put(setLease(lease));
  } catch (err) {
    yield put(getLeaseFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.LEASE, error: err, data: action.payload }));
  }
}

export function* getLeaseDocuments(action) {
  try {
    const response = yield call(service.getLeaseDocuments, prop('payload', action));
    yield put(getLeaseDocumentsSuccess(response.documents));
  } catch (err) {
    yield put(getLeaseDocumentsFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.LEASE, error: err, data: action.payload }));
  }
}

export function* _updateLease(action) {
  try {
    const payload = prop('payload', action);
    const lease = {
      ...payload,
      moveoutDate: localDateToHubInUtcFormat(payload.moveoutDate, payload.timezone),
      preferredMoveoutDate: localDateToHubInUtcFormat(payload.preferredMoveoutDate, payload.timezone)
    };
    yield call(service.updateLease, lease);
    yield put(updateLease.success(lease));
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.LEASE }));
  } catch (err) {
    yield put(updateLease.failure(err));
    yield put(handleErrorMessage('UPDATE.FAILURE', { type: types.LEASE, error: err, data: action.payload }));
  }
}

export function* getHelloSignLease(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const lease = yield call(service.getHelloSignLease, payload);
    yield put(setHelloSignLease(lease));
  } catch (err) {
    yield put(setAccessRestricted());
  }
}

export function* appendLeases(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const leases = yield call(service.getLeasesList, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(appendLeasesSucess({ ...leases, limit: payload.limit }));
  } catch (err) {
    yield put(appendLeasesFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.LEASE, error: err, data: action.payload }));
  }
}

export function* cancelLease(action) {
  try {
    const lease = prop('payload', action);
    yield call(service.cancelLease, lease.id);
    if (lease.userId) {
      yield put(memberActions.getLeases(lease.userId));
    }
    yield put(handleSuccessMessage('CANCEL.SUCCESS', { type: types.LEASE }));
  } catch (err) {
    yield put(handleErrorMessage('CANCEL.FAILURE', { type: types.LEASE, error: err }));
  }
}

export default function* leasesSaga() {
  yield all([
    takeLatest(GET_LEASE, getLease),
    takeLatest(GET_LEASE_DOCUMENTS, getLeaseDocuments),
    takeLatest(updateLease.REQUEST, _updateLease),
    takeLatest(APPEND_LEASES, appendLeases),
    takeLatest(GET_LEASES, getLeases),
    takeLatest(CANCEL_LEASE, cancelLease),
    takeLatest(GET_HELLO_SIGN_LEASE, getHelloSignLease)
  ]);
}
