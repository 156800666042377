import React, { useState } from 'react';
import { string, func, object, number, bool, array, oneOfType } from 'prop-types';
import { path, prop } from 'ramda';
import { UploadInput } from './Upload';
import { uploadFile, deleteRemoteFile } from '../../service';
import { toast, isNotEmptyOrNil, checkFileType } from '../../utils';
import { translate } from '../../translate/i18n';

const DirectUpload = (props) => {
  const { t, input, afterDelete, isDisabled, accept } = props;

  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeError, setTypeError] = useState(null);

  const file = path(['input', 'value'], props);

  const handleChange = (event) => {
    const onChange = prop('onChange', input);
    const tmpFile = path(['target', 'files', 0], event);
    const typeError = checkFileType(tmpFile, accept);

    if (!typeError) {
      setTypeError(null);
      setLoading(true);
      uploadFile(tmpFile)
        .then((fileUrl) => {
          setLoading(false);
          onChange(fileUrl);
        })
        .catch(() => {
          setLoading(false);
          onChange('');
          toast.error(t('form:ERR_UPLOAD_FILE'), { autoClose: 1600 });
        });
    } else {
      setTypeError(typeError);
    }
  };

  const handleDelete = () => {
    const onChange = prop('onChange', input);

    deleteRemoteFile(file)
      .then(() => {
        onChange(null);
        if (typeof afterDelete === 'function') {
          afterDelete();
        }
      })
      .catch(() => {
        onChange(null);
        if (typeof afterDelete === 'function') {
          afterDelete();
        }
        toast.error(t('form:ERR_DELETE_FILE'), { autoClose: 1600 });
      });
  };

  return (
    <UploadInput
      showDeleteButton={isNotEmptyOrNil(file) && hover && !loading && !isDisabled}
      onDelete={handleDelete}
      onChange={handleChange}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      file={file}
      loading={loading}
      typeError={typeError}
      {...props}
    />
  );
};

DirectUpload.defaultProps = {
  isDisabled: false
};

DirectUpload.propTypes = {
  label: string,
  file: oneOfType([object, string]),
  placeholder: string,
  input: object,
  size: number,
  onDelete: func,
  isDisabled: bool,
  accept: array.isRequired,
  t: func
};

export default translate('default')(DirectUpload);
