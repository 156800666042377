import { applyMiddleware, compose, createStore } from 'redux';
import { addFormSubmitSagaTo } from 'redux-form-submit-saga';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import allMySagas from './sagas';

export let store;

export default (initialState) => {
  const rootSaga = addFormSubmitSagaTo(allMySagas);
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  return store;
};
