import React from 'react';
import { string, shape, bool, func } from 'prop-types';
import { translate } from '../../translate/i18n';
import { compose } from 'ramda';
import { formatInputProps, inputPropTypes } from './decorators';
import { InputContainer, StyledLabel, StyledTextInput, ErrorText } from './TextInput.styles';
import { Row } from '../index';

const TextInput = ({ meta, t, label, inputProps, clear, Icon }) => {
  const { touched, error, active } = meta;

  return (
    <InputContainer>
      <Row middle>
        <StyledLabel active={active}>{label}</StyledLabel>
        {Icon && <Icon />}
      </Row>
      <StyledTextInput clear={clear} {...inputProps} />

      {touched && error && <ErrorText>{t(error)}</ErrorText>}
    </InputContainer>
  );
};
TextInput.propTypes = {
  label: string,
  Icon: func,
  meta: shape({
    touched: bool,
    error: string,
    action: bool
  }).isRequired,
  inputProps: inputPropTypes
};

export default compose(formatInputProps(), translate('validation'))(TextInput);
