import { compose, findIndex, pathOr, pathEq, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { connect } from 'react-redux';
import { emptyObject } from '../utils';
import { fetchPaymentMethods, setPaymentMethods } from './geo.actions';
import React, { useEffect, useState } from 'react';
import { Button, Container, Loader, Row, Toggle, Col } from '../components';
import { func, bool, array, object } from 'prop-types';
import config from '../config';

const HubPaymentMethodsForm = compose(
  translate('form'),
  connect(
    (state) => ({
      paymentMethods: pathOr(emptyObject, ['geo', 'paymentMethods', 'list'], state),
      isFetching: pathOr(false, ['geo', 'paymentMethods', 'isFetching'], state)
    }),
    {
      fetchPaymentMethods,
      setPaymentMethods
    }
  )
)(({ paymentMethods, t, data, onClose, fetchPaymentMethods, setPaymentMethods, isFetching }) => {
  const maskPaymentMethods = (paymentMethods) => {
    const filter = pathOr(null, ['paymentGateway', 'paymentMethods', data.paymentGatewayId], config);

    let list = paymentMethods;

    if (data.paymentGatewayId && filter) {
      list = list.filter((item) => filter.includes(item.type));
    }

    return list.reduce((mask, item) => {
      const index = findIndex(pathEq(['payment_method', 'id'], item.id), data.paymentMethods);

      return {
        ...mask,
        [item.id]: {
          ...item,
          active: !!~index,
          required: ~index ? data.paymentMethods[index].required : false,
          default: ~index ? data.paymentMethods[index].default : false
        }
      };
    }, {});
  };

  const [list, switchPaymentMethods] = useState(maskPaymentMethods(paymentMethods));

  useEffect(() => {
    if (!paymentMethods || !paymentMethods.length) {
      fetchPaymentMethods();
    }
  }, []);

  useEffect(() => {
    switchPaymentMethods(maskPaymentMethods(paymentMethods));
  }, [paymentMethods]);

  const onCheck = (id, status) => {
    if (list[id].default) {
      return;
    }
    if (!status) {
      list[id].required = false;
    }
    list[id].active = status;
    switchPaymentMethods({ ...list });
  };

  const onRequire = (id, status) => {
    if (list[id].default) {
      return;
    }
    list[id].required = status;

    if (status) {
      list[id].active = true;
    }
    switchPaymentMethods({ ...list });
  };

  const onDefault = (id, status) => {
    if (!status) {
      return;
    }
    keys(list).map((item) => (list[item].default = false));
    list[id].default = true;
    list[id].required = true;
    list[id].active = true;
    switchPaymentMethods({ ...list });
  };

  const onSave = () => {
    onClose();
    setPaymentMethods(
      data.id,
      Object.values(list)
        .filter((item) => item.active)
        .map((item) => ({
          type: item.type,
          required: item.required,
          default: item.default
        }))
    );
  };

  if (isFetching) {
    return (
      <Container full center middle>
        <Loader />
      </Container>
    );
  }

  return (
    <Container column p>
      {Object.values(list).map((item) => (
        <Row key={item.id} mb>
          <Col lg={4}>
            <Toggle
              label={item.name}
              input={{
                checked: item.active,
                onChange: () => null
              }}
              blockView
              onCheck={(status) => onCheck(item.id, status)}
              name={item.type}
            />
          </Col>
          <Col lg={4}>
            {item.canAutoCreated && (
              <Toggle
                label={t('REQUIRED')}
                input={{
                  checked: item.required,
                  onChange: () => null
                }}
                blockView
                onCheck={(status) => onRequire(item.id, status)}
                name={`${item.type}_required`}
              />
            )}
          </Col>
          <Col lg={4}>
            {item.canAutoCreated && (
              <Toggle
                label={t('DEFAULT')}
                input={{
                  checked: item.default,
                  onChange: () => null
                }}
                blockView
                onCheck={(status) => onDefault(item.id, status)}
                name={`${item.type}_default`}
              />
            )}
          </Col>
        </Row>
      ))}

      <Row right mb>
        <Button size="md" name="btn-cancel" onClick={() => onClose()} clear>
          {t('default:CANCEL')}
        </Button>
        <Button size="md" name="btn-save" bg-primary onClick={() => onSave()}>
          {t('default:SAVE')}
        </Button>
      </Row>
    </Container>
  );
});

HubPaymentMethodsForm.propTypes = {
  data: object,
  paymentMethods: array,
  t: func,
  fetchPaymentMethods: func,
  setPaymentMethods: func,
  onClose: func,
  isFetching: bool
};

export default HubPaymentMethodsForm;
