import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Row, Text, Button, Col } from '../components';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { translate } from '../translate/i18n';
import { createPaymentProfileMethod } from './paymentProfileMethod.actions';
import DeleteSource from './paymentProfileMethod.deleteSource';

const PaymentProfileMethodAchCredit = ({
  member,
  currentHub,
  currentProfile,
  hubMethod,
  profileMethod,
  createPaymentProfileMethodCall,
  t
}) => {
  const create = () => {
    createPaymentProfileMethodCall({
      userId: member.id,
      hubId: currentHub.id,
      type: hubMethod.payment_method.type
    });
  };

  if (profileMethod) {
    return (
      <Fragment>
        {profileMethod.stripeData && (
          <Fragment>
            <Row mb>
              <Col lg={6}>
                <Text size="md" grey={300} pr-half>
                  {t('member:profile.BANK_NAME')}
                </Text>
              </Col>

              <Col lg={6}>
                <Text size="md" weight={300} pr-half>
                  {profileMethod.stripeData.bankName}
                </Text>
              </Col>
            </Row>

            <Row mb>
              <Col lg={6}>
                <Text size="md" grey={300} pr-half>
                  {t('member:profile.SWIFT_CODE')}
                </Text>
              </Col>

              <Col lg={6}>
                <Text size="md" weight={300} pr-half>
                  {profileMethod.stripeData.swiftCode}
                </Text>
              </Col>
            </Row>

            <Row mb>
              <Col lg={6}>
                <Text size="md" grey={300} pr-half>
                  {t('member:ACCOUNT_NUMBER')}
                </Text>
              </Col>

              <Col lg={6}>
                <Text size="md" weight={300} pr-half>
                  {profileMethod.stripeData.accountNumber}
                </Text>
              </Col>
            </Row>

            <Row mb>
              <Col lg={6}>
                <Text size="md" grey={300} pr-half>
                  {t('member:ROUTING_NUMBER')}
                </Text>
              </Col>

              <Col lg={6}>
                <Text size="md" weight={300} pr-half>
                  {profileMethod.stripeData.routingNumber}
                </Text>
              </Col>
            </Row>
          </Fragment>
        )}

        <Row>
          <DeleteSource member={member} currentHub={currentHub} currentProfile={currentProfile} hubMethod={hubMethod} />
        </Row>
      </Fragment>
    );
  }

  return (
    <Row mb right>
      <Button size="md" type="submit" name="btn-save" bg-primary mr-half onClick={create}>
        {t('default:CREATE')}
      </Button>
    </Row>
  );
};

PaymentProfileMethodAchCredit.propTypes = {
  t: func,
  member: object.isRequired,
  currentHub: object.isRequired,
  currentProfile: object,
  hubMethod: object,
  profileMethod: object,
  createPaymentProfileMethodCall: func
};

export default compose(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        createPaymentProfileMethodCall: createPaymentProfileMethod.request
      },
      dispatch
    )
  ),
  translate()
)(PaymentProfileMethodAchCredit);
