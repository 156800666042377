export const CREATE_ROOM_FORM_SUBMIT = 'CREATE_ROOM_FORM_SUBMIT';
export const CREATE_ROOM_FORM_SUCCESS = 'CREATE_ROOM_FORM_SUCCESS';
export const CREATE_ROOM_FORM_FAILURE = 'CREATE_ROOM_FORM_FAILURE';

export const GET_ROOMS = 'GET_ROOMS';
export const GET_ROOMS_SUCCESS = 'GET_ROOMS_SUCCESS';

export const EDIT_ROOM_FORM_SUBMIT = 'EDIT_ROOM_FORM_SUBMIT';
export const EDIT_ROOM_FORM_SUCCESS = 'EDIT_ROOM_FORM_SUCCESS';
export const EDIT_ROOM_FORM_FAILURE = 'EDIT_ROOM_FORM_FAILURE';

export const APPEND_ROOMS = 'APPEND_ROOMS';
export const APPEND_ROOMS_SUCCESS = 'APPEND_ROOMS_SUCCESS';
export const APPEND_ROOMS_FAIL = 'APPEND_ROOMS_FAIL';

export const GET_ROOM = 'GET_ROOM';
export const GET_ROOM_SUCCESS = 'GET_ROOM_SUCCESS';
export const GET_ROOM_FAILURE = 'GET_ROOM_FAILURE';

export const UPSERT_ROOM = 'UPSERT_ROOM';

export const DELETE_ROOMS = 'DELETE_ROOMS';
export const DELETE_ROOMS_SUCCESS = 'DELETE_ROOMS_SUCCESS';
export const DELETE_ROOMS_FAILURE = 'DELETE_ROOMS_FAILURE';

export const UPSERT_ROOM_LOCK = 'UPSERT_ROOM_LOCK';
export const DELETE_ROOM_LOCK = 'DELETE_ROOM_LOCK';
export const DELETE_ROOM_LOCK_SUCCESS = 'DELETE_ROOM_LOCK_SUCCESS';
