import {
  GET_APPLICATION_FEE,
  SET_APPLICATION_FEE,
  initialApplications,
  ON_DROP,
  APPEND_APPLICATIONS,
  APPEND_APPLICATIONS_FAIL,
  APPEND_APPLICATIONS_SUCCESS,
  SET_CURRENT_DRAGGABLE,
  GET_APPLICATIONS,
  GET_APPLICATIONS_SUCCESS,
  FETCH_APPLICANT,
  FETCH_APPLICANT_SUCESS,
  UPDATE_APPLICANT,
  UPDATE_APPLICANT_SUCCESS,
  UPDATE_APPLICANT_FAIL,
  UPDATE_APPLICANT_STATUS,
  UPDATE_APPLICANT_KYC_STATUS,
  UPDATE_APPLICANT_CALL_STATUS,
  DELETE_AVAILABLE_ROOMS_SUCCESS,
  ADD_NEW_ROOM,
  ADD_NEW_ROOM_SUCCESS,
  ADD_NEW_ROOM_FAIL,
  GET_APPLICATIONS_FAILURE,
  GET_APPLICATION_VERIFICATIONS,
  GET_APPLICATION_VERIFICATIONS_SUCCESS,
  GET_APPLICATION_VERIFICATIONS_FAIL,
  GET_USER_DOCUMENTS,
  GET_USER_DOCUMENTS_SUCCESS,
  GET_USER_DOCUMENTS_FAIL,
  UPDATE_APPLICATION_VERIFICATION,
  UPDATE_APPLICATION_VERIFICATION_SUCCESS,
  UPDATE_APPLICATION_VERIFICATION_FAIL,
  DELETE_APPLICATION_VERIFICATION,
  DELETE_APPLICATION_VERIFICATION_SUCCESS,
  DELETE_APPLICATION_VERIFICATION_FAIL,
  GET_FILTERED_APPLICATIONS_SUCCESS,
  GET_FILTERED_APPLICATIONS,
  GET_FILTERED_APPLICATIONS_FAILURE,
  GET_VALIDATE_APPLICATION_FEE,
  SET_VAILDATE_APPLICATION_FEE
} from './applications.actionTypes';
import { updateApplicantInfo } from './applications.actions';
import { pathOr, propOr, uniqBy, prop, prepend } from 'ramda';
import {
  genState,
  setFetching,
  appendList,
  setCurrent,
  setFetchingCurrent,
  appendListFailure,
  setList,
  setListFailure
} from '../utils/reducer';
import { emptyArray, emptyObject } from '../utils';

const applications = (state) => ({
  [GET_APPLICATION_FEE]: () => ({
    ...state,
    currentFee: {
      isFetching: true,
      data: null
    }
  }),
  [SET_APPLICATION_FEE]: (payload) => ({
    ...state,
    currentFee: {
      isFetching: false,
      data: payload
    }
  }),
  [ON_DROP]: (payload) => {
    const section = propOr('', 'dropSection', payload);
    const item = propOr({}, 'item', payload);

    if (section === item.status) {
      return { ...state };
    }

    const sections = { ...state.sections };
    const application = { ...item, status: section };

    sections[section] = uniqBy(prop('id'))(prepend(application, sections[section]));
    sections[item.status] = state.sections[item.status].filter((obj) => obj.id !== application.id);

    return {
      ...state,
      sections
    };
  },
  [SET_CURRENT_DRAGGABLE]: (payload) => ({ ...state, currentDraggable: payload }),
  [GET_FILTERED_APPLICATIONS]: () => ({
    ...state,
    isFetching: true
  }),
  [GET_FILTERED_APPLICATIONS_SUCCESS]: (payload) => ({
    ...state,
    isFetching: false,
    sections: payload
  }),
  [GET_FILTERED_APPLICATIONS_FAILURE]: () => ({
    ...state,
    isFetching: false
  }),
  [GET_APPLICATIONS]: setFetching(state, true),
  [GET_APPLICATIONS_SUCCESS]: setList(state),
  [GET_APPLICATIONS_FAILURE]: setListFailure(state),
  [FETCH_APPLICANT]: setFetchingCurrent(state, true),
  [FETCH_APPLICANT_SUCESS]: setCurrent(state),
  [UPDATE_APPLICANT]: () => ({
    ...state,
    current: { ...state.current, isUpdating: true }
  }),
  [UPDATE_APPLICANT_SUCCESS]: (applicant) => ({
    ...state,
    current: {
      ...state.current,
      isUpdating: false,
      data: { ...state.current.data, ...applicant },
      errors: null,
      isUpdatingStatus: false
    }
  }),
  [UPDATE_APPLICANT_FAIL]: (err) => ({
    ...state,
    current: { ...state.current, isUpdating: false, errors: err, isUpdatingStatus: false }
  }),
  [UPDATE_APPLICANT_STATUS]: () => ({
    ...state,
    current: { ...state.current, isUpdatingStatus: true }
  }),
  [UPDATE_APPLICANT_KYC_STATUS]: () => ({
    ...state,
    current: { ...state.current, isUpdatingKYCStatus: true }
  }),
  [UPDATE_APPLICANT_CALL_STATUS]: () => ({
    ...state,
    current: { ...state.current, isUpdatingCallStatus: true }
  }),
  [DELETE_AVAILABLE_ROOMS_SUCCESS]: (roomIds = emptyArray) => {
    const rooms = pathOr(emptyObject, ['current', 'data', 'rooms'], state);
    const filteredRooms = rooms.filter((room) => !roomIds.includes(room.id));

    return {
      ...state,
      current: {
        ...propOr(emptyObject, 'current', state),
        data: {
          ...pathOr(emptyObject, ['current', 'data'], state),
          rooms: filteredRooms
        }
      }
    };
  },
  [ADD_NEW_ROOM]: () => ({
    ...state,
    current: {
      ...propOr(emptyObject, 'current', state),
      isCreating: true,
      hasErrors: false
    }
  }),
  [ADD_NEW_ROOM_SUCCESS]: (roomList = emptyArray) => ({
    ...state,
    current: {
      ...propOr(emptyObject, 'current', state),
      isCreating: false,
      hasErrors: false,
      data: {
        ...pathOr(emptyObject, ['current', 'data'], state),
        rooms: roomList
      }
    }
  }),
  [ADD_NEW_ROOM_FAIL]: (err) => ({
    ...state,
    current: {
      ...propOr(emptyObject, 'current', state),
      isCreating: false,
      hasErrors: err
    }
  }),
  [updateApplicantInfo.REQUEST]: () => ({
    ...state,
    current: {
      ...propOr(emptyObject, 'current', state),
      isUpdatingInfo: true
    }
  }),
  [updateApplicantInfo.SUCCESS]: (user) => ({
    ...state,
    current: {
      ...state.current,
      data: {
        ...state.current.data,
        user
      },
      isUpdatingInfo: false,
      errorInfo: null
    }
  }),
  [updateApplicantInfo.FAILURE]: (err) => ({
    ...state,
    current: {
      ...propOr(emptyObject, 'current', state),
      isUpdatingInfo: false,
      errorInfo: err
    }
  }),
  [APPEND_APPLICATIONS]: setFetching(state, true),
  [APPEND_APPLICATIONS_SUCCESS]: appendList(state),
  [APPEND_APPLICATIONS_FAIL]: appendListFailure(state),
  [GET_APPLICATION_VERIFICATIONS]: () => ({
    ...state,
    current: { ...state.current, verifications: { ...state.current.verifications, isFetching: true } }
  }),
  [GET_APPLICATION_VERIFICATIONS_SUCCESS]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      verifications: { ...state.current.verifications, isFetching: false, list: payload }
    }
  }),
  [GET_APPLICATION_VERIFICATIONS_FAIL]: () => ({
    ...state,
    current: {
      ...state.current,
      verifications: { ...state.current.verifications, isFetching: false }
    }
  }),
  [GET_USER_DOCUMENTS]: () => ({
    ...state,
    current: { ...state.current, documents: { ...state.current.documents, isFetching: true } }
  }),
  [GET_USER_DOCUMENTS_SUCCESS]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      documents: { ...state.current.documents, isFetching: false, list: payload }
    }
  }),
  [GET_USER_DOCUMENTS_FAIL]: () => ({
    ...state,
    current: {
      ...state.current,
      documents: { ...state.current.documents, isFetching: false }
    }
  }),
  [UPDATE_APPLICATION_VERIFICATION]: () => ({
    ...state,
    current: { ...state.current, isUpdatingVerification: true }
  }),
  [UPDATE_APPLICATION_VERIFICATION_SUCCESS]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      isUpdatingVerification: false,
      verification: { ...payload },
      updateVerificationErrors: null
    }
  }),
  [UPDATE_APPLICATION_VERIFICATION_FAIL]: (err) => ({
    ...state,
    current: { ...state.current, isUpdatingVerification: false, updateVerificationErrors: err, verification: null }
  }),
  [DELETE_APPLICATION_VERIFICATION]: () => ({
    ...state,
    current: { ...state.current, isUpdatingVerification: true }
  }),
  [DELETE_APPLICATION_VERIFICATION_SUCCESS]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      isUpdatingVerification: false,
      verification: { ...payload },
      updateVerificationErrors: null
    }
  }),
  [DELETE_APPLICATION_VERIFICATION_FAIL]: (err) => ({
    ...state,
    current: { ...state.current, isUpdatingVerification: false, updateVerificationErrors: err, verification: null }
  }),
  [GET_VALIDATE_APPLICATION_FEE]: () => ({
    ...state,
    optionalFee: {
      isFetching: true,
      data: null
    }
  }),
  [SET_VAILDATE_APPLICATION_FEE]: (payload) => ({
    ...state,
    optionalFee: {
      isFetching: false,
      data: payload
    }
  })
});

export const applicationsReducer = (state = initialApplications, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: applications, type });
};
