import { createInstance } from './instances.js';

let propertiesApiInstance;

const getInstance = () => {
  if (!propertiesApiInstance) {
    const baseURL = `${process.env.REACT_APP_APPLICATIONS_API_URL}`;
    propertiesApiInstance = createInstance(baseURL);
  }

  return propertiesApiInstance;
};

const parseGetFilteredApplications = (params) => {
  return {
    createdAt: params.createdAt || '',
    hubIds: (params.hubId || []).join(','),
    showLost: params?.showLost || false
  };
};

const buildEmptyResponse = () => {
  const applications = {
    validated: [],
    nurture: [],
    call: [],
    awaitingVirtualTour: [],
    virtualTourSent: [],
    booked: [],
    won: [],
    applications: []
  };

  return applications;
};

export const getFilteredApplications = async (params) => {
  const instance = getInstance();

  try {
    const { data } = await instance.get('/filter', {
      params: parseGetFilteredApplications(params)
    });
    return data.applications;
  } catch (error) {
    console.error('Error fetching filtered applications', { error });
    return buildEmptyResponse();
  }
};
