import React, { PureComponent } from 'react';

import { array, func, bool, string } from 'prop-types';
import { Table, Container, Text, Loader } from '../components';
import { translate } from '../translate/i18n';
import { connect } from 'react-redux';
import { pathOr, compose } from 'ramda';
import { emptyArray } from '../utils';

import { Link } from 'react-router-dom';
import { getIncidents } from './member.actions';

const ProfileHeader = ({ displayName }) => (
  <Container column>
    <Link to="/incidents">
      <Text size="lg" weight={300}>
        {displayName}
      </Text>
    </Link>
  </Container>
);

ProfileHeader.propTypes = {
  displayName: string
};

class IncidentsProfile extends PureComponent {
  static propTypes = {
    getIncidents: func,
    incidents: array,
    t: func,
    isFetching: bool,
    memberId: string
  };

  componentDidMount() {
    const { getIncidents, t, memberId } = this.props;
    getIncidents(memberId);

    this.columnDefs = [
      {
        headerName: t('profile.incidentsTitle'),
        field: 'zendeskId',
        width: 150,
        onCellClicked: (event) => {
          window.open(event.data.url, '_blank');
        },
        valueFormatter: (col) => `${t('profile.ticket')}${col.value}`
      },
      {
        headerName: '',
        field: 'priority',
        width: 150,
        onCellClicked: (event) => {
          window.open(event.data.url, '_blank');
        }
      }
    ];
  }

  render() {
    const { incidents, isFetching } = this.props;

    if (isFetching) return <Loader />;

    const datatable = {
      columnDefs: this.columnDefs,
      rowData: incidents,
      frameworkComponents: { agColumnHeader: ProfileHeader }
    };

    return (
      <Container column pt>
        <Table data={datatable} hasPagination={false} />
      </Container>
    );
  }
}

export default compose(
  translate('member'),
  connect(
    (state) => ({
      incidents: pathOr(emptyArray, ['member', 'current', 'incidents', 'list'], state)
    }),
    { getIncidents }
  )
)(IncidentsProfile);
