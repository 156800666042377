import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { func, array, number, string, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, compose, prop, path } from 'ramda';
import { Text, Row, Col, ScrollableDataTable } from '../components';
import {
  capitalize,
  emptyArray,
  valueOrPlaceholder,
  isNotEmptyOrNil,
  formatDatetime,
  formatNameTruncatedAndRole
} from '../utils';
import { translate } from '../translate/i18n';
import { nameComparator } from '../utils';
import Tooltip from 'react-tooltip-lite';
import { LogIn, LogOut } from 'react-feather';
import { appendInventories, getInventories } from './inventories.actions';

const getColumnDefs = (t) => [
  {
    headerName: capitalize(t('MEMBER')),
    field: 'lease.user',
    comparator: nameComparator,
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatNameTruncatedAndRole)(col.value)
  },
  {
    headerName: capitalize(t('AUTHOR')),
    field: 'user',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatNameTruncatedAndRole)(col.value)
  },
  {
    headerName: capitalize(t('DATE')),
    field: 'createdAt',
    valueFormatter: ({ value, data }) => valueOrPlaceholder(isNotEmptyOrNil, formatDatetime)(value, data.timezone),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value)
  },
  {
    headerName: '',
    field: 'type',
    width: 40,
    cellRendererFramework: ({ value }) => {
      switch (value) {
        case 'move-in': {
          return (
            <div className="ag-cell-react-control">
              <Tooltip content={t('MOVE_IN_INVENTORY')} direction="down">
                <LogIn size={17} />
              </Tooltip>
            </div>
          );
        }
        case 'move-out': {
          return (
            <div className="ag-cell-react-control">
              <Tooltip content={t('MOVE_OUT_INVENTORY')} direction="down">
                <LogOut size={17} />
              </Tooltip>
            </div>
          );
        }
        default: {
          return <div />;
        }
      }
    }
  }
];

const InventoryHistory = (props) => {
  const {
    t,
    history,
    inventories,
    inventoriesCount,
    roomId,
    appendInventories,
    getInventories,
    responseLength,
    isFetching
  } = props;

  const [filter, setFilter] = useState(null);

  useEffect(() => {
    setFilter({ roomId });
  }, [roomId]);

  if (!filter || !filter.roomId) {
    return null;
  }

  const goToInventory = (event) => {
    history.push(`/inventories/${path(['data', 'id'], event)}`);
  };

  return (
    <Fragment>
      <Row mb>
        <Text weight={300} size="lg">
          {t('HISTORY')}
        </Text>
      </Row>

      <Row mb-double>
        <Col xl={6} lg={9} md={12} sm={12}>
          <div
            className="scrollable"
            style={{
              maxHeight: '200px',
              overflowY: 'auto'
            }}>
            <div>
              <ScrollableDataTable
                filters={filter}
                columnDefs={getColumnDefs(t)}
                defaultColDef={{
                  valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col))
                }}
                getMethod={getInventories}
                appendMethod={appendInventories}
                rows={inventories}
                rowCount={inventoriesCount}
                isFetching={isFetching}
                isRowSelectable={() => true}
                responseLength={responseLength}
                onRowClicked={goToInventory}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

InventoryHistory.propTypes = {
  roomId: string,
  inventories: array,
  inventoriesCount: number,
  t: func,
  appendInventories: func,
  getInventories: func,
  responseLength: number,
  isFetching: bool,
  history: object
};

export default compose(
  withRouter,
  translate('inventories'),
  connect(
    (state) => ({
      count: pathOr(emptyArray, ['inventories'], state),
      inventories: pathOr(emptyArray, ['inventories', 'list'], state),
      inventoriesCount: pathOr(0, ['inventories', 'count'], state),
      isFetching: pathOr(false, ['inventories', 'isFetching'], state),
      responseLength: pathOr(0, ['inventories', 'responseLength'], state)
    }),
    { getInventories, appendInventories }
  )
)(InventoryHistory);
