import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Container, TabsContainer, TabButton } from '../components';
import { emptyObject } from '../utils';
import { compose, pathOr } from 'ramda';
import { fetchPaymentProfiles } from './paymentProfile.actions';
import { connect } from 'react-redux';
import { fetchHubPaymentGateways } from '../paymentGateway/paymentGateway.actions';
import { fetchHubs } from '../hub/hub.actions';
import PaymentProfileTab from './paymentProfile.tab';

const PaymentProfile = ({
  paymentGateway,
  member,
  hub,
  paymentProfile,
  fetchHubs,
  fetchPaymentProfiles,
  fetchHubPaymentGateways
}) => {
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    fetchHubs();
    if (!paymentGateway.isResult) {
      fetchHubPaymentGateways();
    }
    fetchPaymentProfiles(member.id);
  }, []);

  const onSelect = (hub) => {
    const profile = paymentProfile.list.find((item) => item.hubId === hub.id);
    const gateway = paymentGateway.list.find((item) => item.id === hub.paymentGatewayId);

    setCurrent({
      hub,
      profile,
      gateway
    });
  };

  useEffect(() => {
    if (paymentGateway.isResult && paymentProfile.isResult && hub.list.length) {
      onSelect(current ? current.hub : hub.list[0]);
    }
  }, [hub, paymentProfile, paymentGateway]);

  if (!current) {
    return null;
  }

  return (
    <Container p column>
      <TabsContainer>
        {hub.list.map((item) => (
          <TabButton
            key={item.id}
            active={item.id === current.hub.id}
            label={item.name}
            onSelect={() => onSelect(item)}
          />
        ))}
      </TabsContainer>

      <PaymentProfileTab
        currentHub={current.hub}
        currentProfile={current.profile}
        currentGateway={current.gateway}
        member={member}
      />
    </Container>
  );
};

PaymentProfile.propTypes = {
  member: object.isRequired,
  hub: object.isRequired,
  paymentGateway: object.isRequired,
  paymentProfile: object.isRequired,
  fetchHubs: func,
  fetchHubPaymentGateways: func,
  fetchPaymentProfiles: func
};

export default compose(
  connect(
    (state) => ({
      hub: pathOr(emptyObject, ['hub'], state),
      paymentGateway: pathOr(emptyObject, ['paymentGateway'], state),
      paymentProfile: pathOr(emptyObject, ['paymentProfile'], state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          fetchHubs,
          fetchPaymentProfiles,
          fetchHubPaymentGateways
        },
        dispatch
      )
  )
)(PaymentProfile);
