import { createFormAction } from 'redux-form-saga';
import {
  GET_PACK_LOCATION,
  GET_PACK_LOCATION_FAIL,
  GET_PACK_LOCATION_SUCCESS,
  UPSERT_PACK_LOCATION
} from './packLocations.actionTypes';
import i18n from '../../translate/i18n';
import { toast } from '../../utils';

export const getPackLocation = (packId, hubId) => ({
  type: GET_PACK_LOCATION,
  payload: {
    packId,
    hubId
  }
});

export const getPackLocationSuccess = (pack) => ({
  type: GET_PACK_LOCATION_SUCCESS,
  payload: pack
});

export const getPackLocationFail = (err) => {
  const error = i18n.getResource(i18n.language, 'packs', 'GET_PACK_LOCATION_ERROR');
  toast.error(error);

  return {
    type: GET_PACK_LOCATION_FAIL,
    payload: err
  };
};

export const upsertPackLocation = createFormAction(UPSERT_PACK_LOCATION);
