import { injectGlobal } from 'emotion';
import { colors } from './colors';
import { spacing } from './spacing';
import { font } from './font';

injectGlobal`

    .ag-theme-cohabs{
        
        //  
        //  As said on AG grid doc,
        //  Always wrap any AG grid into a parent set w/ 100% width & height
        width: 100%;
        height: 100%;

        .ag-root {
            font: 200 1em ${font.family}, Montserat;
    
            //
            //  No ugly outlines on WebKit-based engines
            & *:focus{
                box-shadow: 0 none;
                outline: none;
            }
    
            .ag-header {
                box-shadow: 0 2px 0 ${colors.lightGrey[400]};
                // height:35px!important;
    
                &-select-all {
                    padding-left: 3px;
                }

                &-row {
                    &:hover {
                        cursor:pointer;
                    }
                }
    π
                &-cell {
                    &-sortable{
    
                        &:hover{
                            cursor: pointer;
                        }
                    }
    
                    &-moving{
                        background: ${colors.lightGrey[400]};
    
                        & .ag-header-cell-text{
                            color: black;
                        }
                    }
    
                    &-sorted-asc,
                    &-sorted-desc{
    
                        & .ag-header-cell-text{
                            color: black;
                        }
                    }
    
                    &-label {
                        align-items: center;
                        color: ${colors.lightGrey[500]};
                        display: flex;
                        padding: 0 ${spacing.half}px;
                    }
                }
    
                &-cell-text {
                    font-weight: ${font.weight.md};
                    font-size: ${font.size.md + 1}px;
                    padding: ${spacing.quarter}px 3px;
                    color:${colors.lightGrey[500]};
                }
    
                &-icon{
                    margin-left: ${spacing.half}px;
                }

                &-viewport {
                    color: ${colors.grey[400]};
                }
            }

            .ag-body {
                font: ${font.weight.sm} ${font.size.md + 1}px ${font.family}, Arial, sans-serif;
                padding-top: 2px;
                display:flex;

                &-container{
                    min-width: 100%;
                }
            }

            .ag-row {
                border-bottom: .5px solid ${colors.lightGrey[300]};
                display:flex;
                font-weight:200;
               
                
                &:hover {
                    cursor:pointer;
                    background: ${colors.brown[300]};
                }

                &-odd{
                    background: ${colors.lightGrey[400]};
                }
                
                .cell-span {
                    background-color: #fff;
                    border-bottom: .5px solid ${colors.lightGrey[300]};
                    
                    &:hover {
                        cursor:pointer;
                        background-color: #EBEDEE;
                    }                
                }
                
                &-last{
                    border-bottom: none;
                    .cell-span {
                      border-bottom: none;             
                    }    
                }

                &.ag-row-selected{
                    background: ${colors.brown[300]};
                }

                .ag-react-container {
                    color:${colors.lightGrey[500]};
                    height: 100%;
                    display: flex;
                    align-items: center;

                    button {
                        padding:0!important;
                        font-size:inherit!important;
                    }

                    a {
                        color:${colors.grey[400]};
                    }
                    
                    .ag-cell-react-control {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        
                        button {
                            padding:5px 8px!important;
                            font-size:inherit!important;
                        }
                    }
                }
                .ag-cell {
                    display: flex;
                    align-items: center;
                    padding-left: 3px;
                    color:${colors.grey[400]};

                    &-wrapper {
                        height:auto;
                    }
                }
            }
        }
    }

    .css-hnhaxk {
        margin-top:0!important;
        position: absolute!important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width:40%!important;
        max-height:90%!important;
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
        border: 1px solid ${colors.lightGrey[400]}!important;
    }
`;
