import React from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';

import MessagingChannelItem from './Messaging';
import { setCurrentChannel } from '../../chat.actions';

const Preview = ({ setCurrentChannel, ...props }) => (
  <MessagingChannelItem
    {...props}
    setActiveChannel={(selected) =>
      setCurrentChannel({
        id: selected.id,
        type: selected.type
      })
    }
  />
);

// Commented out due to temporary lack of need
// Uncomment if necessary to display different types of channels

// const Preview = ({ setCurrentChannel, ...props }) => {
//   const newProps = {
//     ...props,
//     setActiveChannel: (selected) =>
//       setCurrentChannel({
//         id: selected.id,
//         type: selected.type
//       })
//   };
//
//   switch (props.channel.type) {
//     case 'communityManager':
//       return <CMChannelItem {...newProps} />;
//
//     case 'house':
//       return <HouseChannelItem {...newProps} />;
//
//     default:
//       return <MessagingChannelItem {...newProps} />;
//   }
// };

Preview.propTypes = {
  setActiveChannel: func.isRequired
};

export default connect(null, {
  setCurrentChannel
})(Preview);
