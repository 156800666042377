import React, { PureComponent, Fragment } from 'react';
import { array, func, string, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { translate } from '../translate/i18n';
import { compose, pathOr } from 'ramda';
import { emptyArray } from '../utils';
import { Container, Text, Row, Col, Loader, Modal, ConfirmModal } from '../components';
import { ImageContainer, StyledImage } from '../inventories/Inventory.style';
import {
  deleteUserDocuments,
  getVerifications,
  getUserDocuments,
  updateApplicationVerification,
  deleteApplicationVerification
} from './applications.actions';

import './context.css';
import ApplicationKYCButtons from './Application.kycStatus';

class ApplicationVerification extends PureComponent {
  static propTypes = {
    getVerifications: func,
    getUserDocuments: func,
    t: func,
    isFetching: bool,
    application: object,
    applicant: object,
    user: object,
    verifications: array,
    documents: array,
    updateApplicationVerification: func,
    deleteApplicationVerification: func,
    deleteUserDocuments: func,
    isUpdatingVerification: bool,
    verification: object,
    updateVerificationErrors: object,
    disabled: bool,
    status: string,
    depositMultiplier: string
  };

  state = {
    show: false,
    isFetching: false,
    isUpdatingVerification: false,
    verifications: this.props.verifications,
    documents: this.props.documents,
    verificationsWithDocuments: [],
    file: object,
    showFileModal: false,
    confirmDelete: null,
    depositMultiplier: null
  };

  closeModal = () => this.setState({ showFileModal: false });

  openModal = () => this.setState({ showFileModal: true });

  getFileType = (filename) => filename.split('.').pop();

  componentDidMount() {
    const { getVerifications, getUserDocuments, user, applicationId } = this.props;
    if (applicationId && user.id) {
      getVerifications(applicationId);
      getUserDocuments(user.id);
    }
  }

  handleFileClick = (fileUrl, name) => {
    this.setState({
      file: {
        url: fileUrl,
        type: this.getFileType(name)
      }
    });
    this.openModal();
  };

  assignDocuments(verifications, documents) {
    const verificationsWithDocuments = verifications.map((verification) => {
      const docs = documents.filter((document) => document.requisiteId === verification.id);

      return {
        ...verification,
        documents: docs.map((document) => (Array.isArray(document.values) ? [...document.values] : document.values))
      };
    });

    this.setState({
      verificationsWithDocuments
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.verifications !== prevProps.verifications) {
      this.setState({ verifications: this.props.verifications });
      this.assignDocuments(this.props.verifications, this.props.documents);
    }
  }

  changeCheckbox = (event) => {
    const { target } = event;
    const { deleteApplicationVerification, updateApplicationVerification, updateVerificationErrors, applicationId } =
      this.props;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { id } = target;
    const elementIndex = this.state.verificationsWithDocuments.findIndex((element) => element.id === id);
    const list = [...this.state.verificationsWithDocuments];
    if (!value) {
      deleteApplicationVerification(id, applicationId);
    } else {
      updateApplicationVerification(id, applicationId);
    }

    if (!updateVerificationErrors) {
      list[elementIndex] = {
        ...list[elementIndex],
        hasApplicationVerification: !list[elementIndex].hasApplicationVerification
      };
      this.setState({ verificationsWithDocuments: list });
    }
  };

  render() {
    const { t, isFetching, disabled, deleteUserDocuments, applicationId, user, application } = this.props;
    const { verificationsWithDocuments, showFileModal, file, confirmDelete } = this.state;

    if (isFetching) {
      return <Loader />;
    }

    return (
      <Container column mt-double>
        <Row middle>
          <Row mb left>
            <Col lg={5} pt-half pr-half pb-half>
              <Text size="lg" weight={300} pr-half>
                {t('APPLICATION_REQUISITES')}
              </Text>
            </Col>
            <Col lg={7} pt-half pr-half pb-half>
              <Text size="lg" weight={300} pr-half>
                {t('APPLICATION_DOCUMENTS')}
              </Text>
            </Col>
          </Row>
          {verificationsWithDocuments &&
            verificationsWithDocuments.map((element, index) => (
              <Row left key={element.name}>
                <Col lg={5} pt-half pr-half pb-half key={`${index}-${element.name}`}>
                  <Container>
                    <input
                      type="checkbox"
                      checked={element.hasApplicationVerification}
                      id={element.id}
                      disabled={element.documents.length === 0 || disabled}
                      onChange={(event) => this.changeCheckbox(event)}
                    />
                    <Text size="md" weight={300}>
                      {t(`requisites:${element.name}`)}
                    </Text>
                  </Container>
                </Col>
                <Col lg={7} pt-half pr-half pb-half key={`${index}_${element.name}`}>
                  {element.documents.map((document, index) => {
                    if (Array.isArray(document)) {
                      return document.map((doc, docIndex) => {
                        return (
                          <Fragment key={`link_${index}_${docIndex}`}>
                            <ContextMenuTrigger id={`document_context_menu_${doc.id}`}>
                              <Text
                                cursor={'pointer'}
                                blue
                                disabled={disabled}
                                onClick={this.handleFileClick.bind(this, doc.url, doc.name)}>
                                {doc.name}
                              </Text>
                            </ContextMenuTrigger>

                            <ContextMenu id={`document_context_menu_${doc.id}`} stype={{ backgroundColor: '#fff' }}>
                              <MenuItem
                                data={doc}
                                // eslint-disable-next-line no-unused-vars
                                onClick={(e, { target, ...data }) => this.setState({ confirmDelete: data })}>
                                <Text cursor={'pointer'}>{t('form:DELETE')}</Text>
                              </MenuItem>
                            </ContextMenu>
                          </Fragment>
                        );
                      });
                    } else {
                      return (
                        <Text key={`text_${index}`} size="md" weight={200}>
                          {document}
                        </Text>
                      );
                    }
                  })}
                </Col>
              </Row>
            ))}
          <Row mb left>
            <Col lg={5} pt-half pr-half pb-half>
              <Text size="lg" weight={300} pr-half>
                KYC Status: {t('KYC_STATUS_' + application.kycStatus.toUpperCase())}
              </Text>
            </Col>
          </Row>
          <Row mb left>
            <Col lg={5} pt-half pr-half pb-half>
              <ApplicationKYCButtons status={application.kycStatus} />
            </Col>
          </Row>
        </Row>

        <Modal
          full
          pdf
          title={t('requisites:DOCUMENT')}
          show={showFileModal}
          onClose={this.closeModal.bind()}
          content={() =>
            file.type === 'pdf' ? (
              <object
                title="Document"
                style={{ width: '100%', height: '100%' }}
                type="application/pdf"
                data={file.url}
              />
            ) : (
              <ImageContainer>
                <StyledImage src={file.url} alt="picture" />
              </ImageContainer>
            )
          }
        />

        <ConfirmModal
          title={t('DELETE_FILE')}
          show={!!confirmDelete}
          onClose={() => this.setState({ confirmDelete: null })}
          onConfirm={() => {
            this.setState({ confirmDelete: null });
            deleteUserDocuments({
              fileId: confirmDelete.id,
              applicationId,
              userId: user.id
            });
          }}
          content={() => (
            <Col middle lg={12}>
              <Text left pb-quarter size="md">
                {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_FILE')}
              </Text>
            </Col>
          )}
        />
      </Container>
    );
  }
}

export default compose(
  translate('applications'),
  connect(
    (state) => ({
      isFetching: pathOr(false, ['applications', 'current', 'verifications', 'isFetching'], state),
      verifications: pathOr(emptyArray, ['applications', 'current', 'verifications', 'list'], state),
      documents: pathOr(emptyArray, ['applications', 'current', 'documents', 'list'], state),
      isUpdatingVerification: pathOr(
        false,
        ['applications', 'current', 'verifications', 'isUpdatingVerification'],
        state
      ),
      updateVerificationErrors: pathOr(null, ['applications', 'current', 'updateVerificationErrors'], state),
      verification: pathOr(null, ['applications', 'current', 'verification'], state)
    }),
    {
      getVerifications,
      getUserDocuments,
      updateApplicationVerification,
      deleteApplicationVerification,
      deleteUserDocuments
    }
  )
)(ApplicationVerification);
