import { takeLatest, call, all, put } from 'redux-saga/effects';
import { omit, prop } from 'ramda';
import { defActionType, types } from '../../utils/sagas';
import * as service from './discountPromise.service';
import {
  upsertDiscountPromise,
  deleteDiscountPromiseSuccess,
  deleteDiscountPromiseFail
} from './discountPromise.actions';
import { handleErrorMessage, handleSuccessMessage } from '../../toast/toast.actions';
import { getRoom } from '../../rooms/room.actions';
import { DELETE_DISCOUNT_PROMISE } from './discountPromise.actionTypes';

export function* _upsertDiscountPromise(action) {
  const payload = prop('payload', action);
  const key = defActionType(payload);

  try {
    const result = yield call(service.upsertDiscountPromise, omit(['currency', 'timezone'], payload));

    yield put(getRoom(result.discountablePromiseId));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.DISCOUNT_PROMISE }));
  } catch (error) {
    yield put(upsertDiscountPromise.failure(error));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.DISCOUNT_PROMISE, data: action.payload }));
  }
}

export function* deleteDiscountPromise(action) {
  const payload = prop('payload', action);

  try {
    yield call(service.deleteDiscountPromise, prop('id', payload));
    yield put(deleteDiscountPromiseSuccess(payload));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.DISCOUNT_PROMISE }));
    yield put(getRoom(prop('discountablePromiseId', payload)));
  } catch (err) {
    yield put(deleteDiscountPromiseFail(err));
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.DISCOUNT_PROMISE, error: err, data: action.payload }));
  }
}

export default function* discountPromiseSaga() {
  yield all([
    takeLatest(upsertDiscountPromise.REQUEST, _upsertDiscountPromise),
    takeLatest(DELETE_DISCOUNT_PROMISE, deleteDiscountPromise)
  ]);
}
