import { takeLatest, call, all, put } from 'redux-saga/effects';
import { prop, propOr, is } from 'ramda';
import * as service from './payments.service';
import { GET_PAYMENTS, APPEND_PAYMENTS } from './payments.actionTypes';
import {
  setPayments,
  getPaymentsFailure,
  appendPaymentsSuccess,
  appendPaymentsFailure,
  upsertDamage,
  updateDamage,
  upsertComment,
  getPayments as getPaymentsAction
} from './payments.actions';
import { uploadFile } from '../service';
import { upperCase } from '../utils';
import { types, paramsFromPayload, defActionType } from '../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';

const fetch = {
  payments: service.getPayments,
  application_fee: service.getApplicationFee,
  prebooking_fee: service.getPrebookingFee,
  rents: service.getRents,
  packs: service.getPacks,
  deposit: service.getDeposit
};

export function* getPayments(action) {
  const payload = propOr({}, 'payload', action);
  const paymentType = prop('paymentType', payload);

  try {
    const payments = yield call(fetch[paymentType], paramsFromPayload(payload, ['paymentType', 'id']));

    yield put(setPayments(payments));
  } catch (err) {
    yield put(getPaymentsFailure(err));
    yield put(
      handleErrorMessage('GET.FAILURE', { type: types.PAYMENT[upperCase(paymentType)], error: err, data: payload })
    );
  }
}

export function* appendPayments(action) {
  const payload = propOr({}, 'payload', action);
  const paymentType = prop('paymentType', payload);

  try {
    const payments = yield call(fetch[paymentType], paramsFromPayload(payload, ['paymentType', 'id']));

    yield put(appendPaymentsSuccess(payments));
  } catch (err) {
    yield put(appendPaymentsFailure(err));
    yield put(
      handleErrorMessage('GET.FAILURE', { type: types.PAYMENT[upperCase(paymentType)], error: err, data: payload })
    );
  }
}

export function* _upsertDamage(action) {
  const payload = prop('payload', action);
  const key = defActionType(payload);

  try {
    const { hubId, userId, bill, picture } = payload;

    if (is(Blob, picture)) {
      payload.picture = yield uploadFile(picture);
    }

    if (is(Blob, bill)) {
      payload.bill = yield uploadFile(bill);
    }

    yield call(service.upsertDamage, payload);
    yield put(upsertDamage.success());
    yield put(getPaymentsAction({ hubId, userId, paymentType: 'deposit' }));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.PAYMENT.DAMAGE }));
  } catch (err) {
    yield put(upsertDamage.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.PAYMENT.DAMAGE, error: err, data: payload }));
  }
}

export function* _updateDamage(action) {
  const payload = prop('payload', action);
  const key = defActionType(payload);

  try {
    const { hubId, userId, bill, picture } = payload;

    if (is(Blob, picture)) {
      payload.picture = yield uploadFile(picture);
    }

    if (is(Blob, bill)) {
      payload.bill = yield uploadFile(bill);
    }

    yield call(service.updateDamage, payload, payload.id);
    yield put(updateDamage.success());
    yield put(getPaymentsAction({ hubId, userId, paymentType: 'deposit' }));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.PAYMENT.DAMAGE }));
  } catch (err) {
    yield put(updateDamage.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.PAYMENT.DAMAGE, error: err, data: payload }));
  }
}

export function* _upserComment(action) {
  const payload = prop('payload', action);
  const paymentType = prop('paymentType', payload);

  try {
    yield call(service.upsertComment, payload);
    yield put(upsertComment.success(payload));
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.PAYMENT[upperCase(paymentType)] }));
  } catch (err) {
    yield put(upsertComment.failure(err));
    yield put(
      handleErrorMessage('UPDATE.FAILURE', { type: types.PAYMENT[upperCase(paymentType)], error: err, data: payload })
    );
  }
}

export default function* paymentsSaga() {
  yield all([
    takeLatest(GET_PAYMENTS, getPayments),
    takeLatest(APPEND_PAYMENTS, appendPayments),
    takeLatest(upsertDamage.REQUEST, _upsertDamage),
    takeLatest(updateDamage.REQUEST, _updateDamage),
    takeLatest(upsertComment.REQUEST, _upserComment)
  ]);
}
