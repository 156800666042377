import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { func, string, object, bool, oneOfType, any } from 'prop-types';
import { Text, Row, Col } from '../components';
import { translate } from '../translate/i18n';
import { PhoneCall, PhoneForwarded, Send, Sun, UserX, Clock, User } from 'react-feather';
import { spacing, alignement } from '../components/decorators';
import { path, prop } from 'ramda';
import KanbanKycItem from './KanbanKycItem';
import KanbanCallItem from './KanbanCallItem';
import KanbanResourceItem from './KanbanResourceItem';

const StyledKanbanListItem = styled('div')(({ theme, bgColor }) => ({
  ...spacing,
  ...alignement,
  border: '1px solid',
  borderColor: theme.colors.lightGrey[300],
  borderRadius: bgColor ? '5px' : theme.borderRadius,
  padding: theme.spacing.half,
  cursor: 'move',
  backgroundColor: theme.colors.white[500],
  fontFamily: theme.font.family,
  marginBottom: '5px'
}));

const headers = {
  validated: (item) => <HeaderCard date={item.updatedAt} HeaderIcon={User} />,
  rejected: (item) => <HeaderCard date={item.updatedAt} HeaderIcon={UserX} />,
  call: (item) => <HeaderCard date={item.callDate} HeaderIcon={PhoneCall} />,
  'awaiting virtual tour': (item) => <HeaderCard date={item.updatedAt} HeaderIcon={User} />,
  'virtual tour sent': (item) => <HeaderCard date={item.updatedAt} HeaderIcon={User} />,
  sent: (item) => <HeaderCard date={item.updatedAt} HeaderIcon={Send} />,
  won: (item) => <HeaderCard date={item.updatedAt} HeaderIcon={Sun} />,
  nurture: (item) => {
    const date = new Date(prop('nextContact', item) || Date.now() + 1000 * 3600 * 24 * 60);
    return <HeaderCard date={date} HeaderIcon={PhoneForwarded} />;
  },
  booked: (item) => <HeaderCard date={item.updatedAt} HeaderIcon={Clock} />,
  lost: (item) => <HeaderCard date={item.updatedAt} HeaderIcon={UserX} />
};

const renderHeaderItem = (item) => {
  return headers[item.status] ? headers[item.status](item) : null;
};

const HeaderCard = ({ date, HeaderIcon }) => {
  return (
    <Fragment>
      <HeaderIcon size={16} />
      <Text pl-half size="xs" brown={500} weight={300}>
        {date ? new Date(date).toDateString() : null}
      </Text>
    </Fragment>
  );
};
HeaderCard.propTypes = {
  HeaderIcon: any,
  date: oneOfType([string, object])
};

const KanbanListItem = ({ item, draggable, onDragStart, onDoubleClick }) => {
  const status = path(['status'], item);
  const kycStatus = path(['kycStatus'], item);
  const callStatus = path(['callStatus'], item);

  const userName = `${path(['user', 'firstName'], item)} ${path(['user', 'lastName'], item)}`;

  return (
    <StyledKanbanListItem
      onDoubleClick={onDoubleClick}
      draggable={draggable}
      onDragStart={(e) => {
        onDragStart(e);
      }}
      bgColor>
      <Row>
        <Col lg={12}>
          <Text left pt-quarter pb-quarter size="sm" brown={500} weight={200}>
            <Row>{renderHeaderItem(item)}</Row>
          </Text>
          <Text left pt-quarter pb-eighth weight={400} size="sm">
            {userName}
          </Text>
          <Row pt-quarter pb-quarter>
            <KanbanKycItem status={status} kycStatus={kycStatus} />
          </Row>
          <Row pt-quarter pb-quarter>
            <KanbanCallItem status={status} callStatus={callStatus} />
          </Row>
          <Row pt-quarter pb-quarter>
            <KanbanResourceItem callResourceName={item.callResourceName} />
          </Row>
        </Col>
      </Row>
    </StyledKanbanListItem>
  );
};

KanbanListItem.propTypes = {
  item: oneOfType([string, object]),
  draggable: bool,
  onDragStart: func,
  t: func,
  onDoubleClick: func,
  bgColor: bool
};

KanbanListItem.defaultProps = {
  item: {},
  draggable: true
};

export default translate('applications')(KanbanListItem);
