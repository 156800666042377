import React, { Fragment, useState } from 'react';
import { array, string, func, object, bool, oneOfType, number } from 'prop-types';
import ReactSelect from 'react-select';
import { withTheme } from '@emotion/react';
import { is } from 'ramda';
import { StyledLabel } from '../form/TextInput.styles';
import { getSelectedValue } from '../../utils';

export const customStyles = (theme, clear = false) => ({
  valueContainer: (base) => ({
    ...base,
    paddingLeft: clear ? 0 : '8px',
    letterSpacing: '1px',
    paddingTop: '4px',
    flexWrap: 'nowrap'
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 300,
    fontFamily: theme.font.family,
    color: theme.colors.grey[500],
    fontSize: theme.font.size.sm,
    letterSpacing: '1px'
  }),
  control: (base, state) => ({
    ...base,
    outline: 'none',
    backgroundColor: state.isDisabled ? theme.colors.lightGrey[200] : 'transparent',
    color: theme.colors.grey[500],
    fontFamily: theme.font.family,
    fontWeight: theme.font.weight['lg'],
    fontSize: theme.font.size.sm,
    '&:hover': {
      cursor: 'pointer'
    },
    borderStyle: clear ? 'none' : 'solid',
    borerWidth: clear ? 'none' : '1px',
    borderRadius: clear ? 'none' : '2px',
    borderColor: state.isFocused ? theme.colors.brown[500] : theme.colors.lightGrey[300],
    boxShadow: state.isFocused ? `0 0 5px ${theme.colors.brown[400]}` : 'none'
  }),
  option: (_, state) => ({
    padding: theme.spacing.half,
    fontFamily: theme.font.family,
    fontWeight: 300,
    textAlign: 'left',
    backgroundColor: state.isFocused ? theme.colors.lightGrey[400] : 'white',
    color: theme.colors.grey[500],
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.lightGrey[400],
      color: theme.colors.grey[500]
    }
  }),
  multiValue: (base) => ({
    ...base,
    height: '20px',
    backgroundColor: theme.colors.lightGrey[400],
    color: theme.colors.grey[500],
    maxWidth: '100px',
    display: 'flex',
    alignItems: 'center'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: theme.colors.grey[500],
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center'
  }),
  clearIndicator: (base) => ({
    ...base,
    height: '20px',
    marginBottom: '15px'
  })
});

const Selector = (props) => {
  const {
    label,
    options,
    onChange,
    value,
    defaultValue,
    name,
    theme,
    isMulti,
    isDisabled,
    onInputChange,
    placeholder,
    clear,
    isClearable,
    getValue
  } = props;

  const [isActive, setActive] = useState(false);

  const optionToValue = (option) => {
    if (is(Array, option)) {
      return option.map((item) => item.value);
    }

    if (is(Object, option)) {
      return option.value;
    }

    return option;
  };

  const handleChange = (option) => {
    const res = optionToValue(option);

    onChange(res);
  };

  return (
    <Fragment>
      {!!label && <StyledLabel active={isActive}>{label}</StyledLabel>}
      <div onClick={(e) => e.nativeEvent.stopImmediatePropagation()}>
        <ReactSelect
          styles={customStyles(theme)}
          name={`list-${name}`}
          value={(getValue ? getValue(value, options) : getSelectedValue(value, options)) || null}
          onChange={handleChange}
          defaultValue={defaultValue}
          options={options}
          isMulti={isMulti}
          isDisabled={isDisabled}
          onInputChange={onInputChange}
          placeholder={placeholder}
          clear={clear}
          isClearable={isClearable}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        />
      </div>
    </Fragment>
  );
};

Selector.propTypes = {
  defaultValue: object,
  options: array,
  value: oneOfType([string, number, array]),
  onChange: func,
  name: string,
  theme: object,
  label: string,
  isMulti: bool,
  isInForm: bool,
  isDisabled: bool,
  onInputChange: func,
  placeholder: string,
  formatOptions: func,
  clear: bool,
  isClearable: bool,
  getValue: func
};

Selector.defaultProps = {
  value: null
};

export default withTheme(Selector);
