import React, { useState } from 'react';
import { func, object, number, bool, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import { pathOr, prop, slice, join, pluck, path } from 'ramda';
import { translate } from '../translate/i18n';
import { getPacks, appendPacks, duplicatePack } from './packs.actions';
import { valueOrPlaceholder, isNotEmptyOrNil, emptyArray } from '../utils';
import { Row, Block, Text, ScrollableDataTableWithColSelector, ActionButton, Button, Modal } from '../components';
import Filter from './Packs.filter';
import Subscriptions from './Packs.subscriptions';
import moment from 'moment-timezone';

const formatBool = (value, t) => (value ? t('YES') : t('NO'));
const formatDate = (value) => moment(value).tz(moment.tz.guess()).format('DD/MM/YYYY HH:mm');

const StyledCircle = styled('div')(({ theme, value }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: value,
  border: `0.5px solid ${theme.colors.lightGrey[300]}`
}));

const duplicatePackButton = (t) => {
  return (
    <Button name="duplicate" bg-primary>
      <Row middle p-quarter pr pl>
        {t('DUPLICATE')}
      </Row>
    </Button>
  );
};

/**
 * React component
 * @param {*} props
 */
const Packs = (props) => {
  const { history, t, packs, packCount, isFetching, appendPacks, getPacks, duplicatePack, responseLength } = props;

  const [filters, setFilters] = useState();
  const [modalIsOpen, toggleModal] = useState(false);
  const [pack, setPack] = useState(null);

  const goToPack = (event) => {
    history.push(`/packs/${path(['data', 'id'], event)}`);
  };

  const duplicate = (event) => {
    const pack = path(['data'], event);
    const content = pack.pack_contents.map((content) => content.point);
    const { active, color, description, featured, name, oneOff, icon, iconColor, iconWhite } = pack;
    duplicatePack({ active, color, content, description, featured, name, oneOff, icon, iconColor, iconWhite });
  };

  /**
   * Returns this table column definition
   * @param {*} t translation function
   */
  const getColumnDefs = () => [
    {
      headerName: t('COLOR'),
      field: 'color',
      cellRendererFramework: StyledCircle,
      width: 80,
      onCellClicked: goToPack
    },
    {
      headerName: t('NAME'),
      field: 'name',
      defaultActive: true,
      width: 200,
      onCellClicked: goToPack
    },
    {
      headerName: t('Hubs'),
      field: 'packLocations',
      valueFormatter: (col) => {
        const hubs = prop('value', col).map((item) => item.hub);

        const firstThreeHubs = compose(join(', '), pluck('name'), slice(0, 3))(hubs);

        return `${firstThreeHubs}${hubs.length > 3 ? ', ...' : ''}`;
      },
      sortable: false,
      onCellClicked: goToPack
    },
    {
      headerName: t('SUBSCRIPTIONS'),
      width: 160,
      defaultActive: true,
      field: 'subscribersCount',
      sortable: false,
      onCellClicked: (event) => {
        setPack(prop('data', event));
        toggleModal(true);
      }
    },
    {
      headerName: t('FEATURED'),
      field: 'featured',
      width: 130,
      valueFormatter: (col) => formatBool(prop('value', col), t),
      onCellClicked: goToPack
    },
    {
      headerName: t('ACTIVE'),
      field: 'active',
      width: 130,
      valueFormatter: (col) => formatBool(prop('value', col), t),
      onCellClicked: goToPack
    },
    {
      headerName: t('ONE_SHOT'),
      field: 'oneOff',
      width: 130,
      valueFormatter: (col) => formatBool(prop('value', col), t),
      onCellClicked: goToPack
    },
    {
      headerName: t('CREATED_AT'),
      field: 'createdAt',
      width: 170,
      valueFormatter: (col) => formatDate(prop('value', col)),
      onCellClicked: goToPack
    },
    {
      label: t('ACTIONS'),
      headerName: '',
      defaultActive: true,
      field: 'id',
      width: 150,
      sortable: false,
      cellRendererFramework: () => duplicatePackButton(t),
      onCellClicked: duplicate
    }
  ];

  return (
    <Block p>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {t('PACKS')}
        </Text>

        <ActionButton icon="Plus" name="addPack" onClick={() => history.push('/packs/create')} />
      </Row>

      <Filter onFilterSelect={(filter) => setFilters(filter)} />

      <ScrollableDataTableWithColSelector
        filters={filters}
        ready={!!filters}
        columnDefs={getColumnDefs()}
        defaultColDef={{ valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)) }}
        getMethod={getPacks}
        appendMethod={appendPacks}
        rows={packs}
        rowCount={packCount}
        isFetching={isFetching}
        responseLength={responseLength}
      />

      <Modal
        title={t('SUBSCRIPTIONS')}
        visibleOverflow={true}
        show={modalIsOpen}
        onClose={() => toggleModal(false)}
        content={() => <Subscriptions members={pack.users} />}
      />
    </Block>
  );
};

Packs.propTypes = {
  history: object,
  t: func,
  packs: array,
  packCount: number,
  responseLength: number,
  isFetching: bool,
  appendPacks: func,
  getPacks: func,
  duplicatePack: func
};

export default compose(
  withRouter,
  translate(['packs', 'form']),
  connect(
    (state) => ({
      packs: pathOr(emptyArray, ['packs', 'list'], state),
      packCount: pathOr(0, ['packs', 'count'], state),
      responseLength: pathOr(0, ['packs', 'responseLength'], state),
      isFetching: pathOr(false, ['packs', 'isFetching'], state)
    }),
    { getPacks, appendPacks, duplicatePack }
  )
)(Packs);
