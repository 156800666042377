import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { func } from 'prop-types';
import { reduxForm, Field, propTypes as reduxFormPropTypes, formValues } from 'redux-form';
import {
  Block,
  BottomContainer,
  Button,
  Col,
  Row,
  SelectorFaqCategoryForm,
  SelectorFaqLocationForm,
  TextInput,
  DirectUpload,
  TextAreaForm,
  SelectorFaqSubcategoryForm,
  sizing
} from '../components';
import StyledForm from '../components/form.styles';
import { upsertContent } from './cms.actions';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { required, maxLength } from '../utils';
import { StyledLabel, InputContainer } from '../components/form/TextInput.styles';
import { spacing, DEFAULT_VIDEO_SIZE } from '../theme/spacing';

const VideoForm = (props) => {
  const { t, isNew, submitSucceeded, handleSubmit, onCancel, submitting, category, windowWidth } = props;

  if (submitSucceeded && isNew) {
    return <Redirect to="/cms/helpvideos" />;
  }

  let maxSize = DEFAULT_VIDEO_SIZE;

  if (maxSize > windowWidth - spacing.normal * 2) {
    maxSize = windowWidth - spacing.normal * 2;
  }

  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(upsertContent)}>
        <Block>
          <Row>
            <Col xl={9} lg={9} md={9} sm={12}>
              <Field component={TextInput} validate={[required, maxLength(128)]} label={t('FORM.TITLE')} name="title" />

              <Field
                component={SelectorFaqCategoryForm}
                validate={required}
                label={t('FORM.CATEGORY')}
                name="category"
              />

              <Field
                component={SelectorFaqSubcategoryForm}
                validate={required}
                category={category}
                label={t('FORM.SUBCATEGORY')}
                name="subcategoryId"
              />

              <Field
                component={SelectorFaqLocationForm}
                validate={required}
                label={t('FORM.LOCATION')}
                name="locations"
              />

              <Field
                component={TextAreaForm}
                validate={maxLength(512)}
                label={t('FORM.DESCRIPTION')}
                name="description"
              />

              <Row>
                <Col xl={6} lg={9} md={9} sm={12}>
                  <InputContainer>
                    <StyledLabel>{t('FORM.VIDEO')}</StyledLabel>
                    <Field
                      component={DirectUpload}
                      validate={required}
                      name="url"
                      accept={['video/mp4']}
                      size={maxSize}
                    />
                  </InputContainer>
                </Col>

                <Col xl={6} lg={3} md={3} sm={12}>
                  <InputContainer>
                    <StyledLabel>{t('FORM.THUMBNAIL')}</StyledLabel>
                    <Field
                      component={DirectUpload}
                      validate={required}
                      name="thumbnail"
                      accept={['image/png', 'image/jpeg']}
                      size={128}
                    />
                  </InputContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Block>
        <BottomContainer negativeMargin={true}>
          <Row right>
            <Button name="cancel" disabled={submitting} onClick={() => onCancel()} clear size="md">
              {t('FORM.CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('FORM.SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </BottomContainer>
      </StyledForm>
    </Fragment>
  );
};

VideoForm.propTypes = {
  t: func,
  ...reduxFormPropTypes
};

export default compose(
  sizing(),
  translate('cms'),
  reduxForm({ form: 'VideoForm', enableReinitialize: true, destroyOnUnmount: false }),
  formValues({ category: 'category' })
)(VideoForm);
