export const getApiUrl = () => {
  try {
    if (!process.env.REACT_APP_RELEASE) {
      return localStorage.getItem('backend_endpoint');
    }
    return null;
  } catch (err) {
    return null;
  }
};
export const setApiUrl = (url) => {
  try {
    localStorage.setItem('backend_endpoint', url);
    return true;
  } catch (err) {
    return false;
  }
};
