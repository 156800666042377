import { createFormAction } from 'redux-form-saga';
import {
  CREATE_ROOM_FORM_SUBMIT,
  CREATE_ROOM_FORM_SUCCESS,
  CREATE_ROOM_FORM_FAILURE,
  GET_ROOMS,
  GET_ROOMS_SUCCESS,
  EDIT_ROOM_FORM_SUBMIT,
  EDIT_ROOM_FORM_SUCCESS,
  EDIT_ROOM_FORM_FAILURE,
  APPEND_ROOMS,
  APPEND_ROOMS_FAIL,
  APPEND_ROOMS_SUCCESS,
  GET_ROOM,
  GET_ROOM_FAILURE,
  GET_ROOM_SUCCESS,
  UPSERT_ROOM,
  DELETE_ROOMS,
  DELETE_ROOMS_SUCCESS,
  DELETE_ROOMS_FAILURE,
  UPSERT_ROOM_LOCK,
  DELETE_ROOM_LOCK,
  DELETE_ROOM_LOCK_SUCCESS
} from './room.actionTypes';

export const upsertRoom = createFormAction(UPSERT_ROOM);

export const createRoom = (room) => ({
  type: CREATE_ROOM_FORM_SUBMIT,
  payload: room
});

export const createRoomSuccess = () => ({
  type: CREATE_ROOM_FORM_SUCCESS
});

export const createRoomFailure = (error) => ({
  type: CREATE_ROOM_FORM_FAILURE,
  payload: error
});

export const getRooms = (params = {}) => ({
  type: GET_ROOMS,
  payload: params
});

export const setRooms = (rooms) => ({
  type: GET_ROOMS_SUCCESS,
  payload: rooms
});

export const editRoom = (room) => ({
  type: EDIT_ROOM_FORM_SUBMIT,
  payload: room
});

export const editRoomSuccess = (room) => ({
  type: EDIT_ROOM_FORM_SUCCESS,
  payload: room
});

export const editRoomFailure = (error) => ({
  type: EDIT_ROOM_FORM_FAILURE,
  payload: error
});

export const appendRooms = (params = {}) => ({
  type: APPEND_ROOMS,
  payload: params
});

export const appendRoomsSuccess = (rooms) => ({
  type: APPEND_ROOMS_SUCCESS,
  payload: rooms
});

export const appendRoomsFail = (err) => ({
  type: APPEND_ROOMS_FAIL,
  payload: err
});

export const getRoom = (params = {}) => ({
  type: GET_ROOM,
  payload: params
});

export const setRoom = (room) => ({
  type: GET_ROOM_SUCCESS,
  payload: room
});

export const getRoomFailure = (params = {}) => ({
  type: GET_ROOM_FAILURE,
  payload: params
});

export const deleteRooms = (rooms) => ({
  type: DELETE_ROOMS,
  payload: rooms
});

export const deleteRoomsSuccess = (rooms) => ({
  type: DELETE_ROOMS_SUCCESS,
  payload: rooms
});

export const deleteRoomsFailure = (error) => ({
  type: DELETE_ROOMS_FAILURE,
  payload: error
});

export const upsertRoomLock = createFormAction(UPSERT_ROOM_LOCK);

export const deleteRoomLockRequest = (payload) => ({
  type: DELETE_ROOM_LOCK,
  payload
});

export const deleteRoomLockSuccess = (payload) => ({
  type: DELETE_ROOM_LOCK_SUCCESS,
  payload
});
