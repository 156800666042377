import { theme } from '../theme';
import { pathOr, propOr, keys } from 'ramda';
const textDim = pathOr({}, ['font', 'size'], theme);
const colors = propOr({}, 'colors', theme);

export const availableColors = keys(colors);

/**
 * Get the default value of the value passed in parameter
 * @param {object} value
 * @returns {string} default value
 */
export const getDefaultValue = (value) => value[value.default];

// Default prop styles
let propStyles = {
  align: (value = 'left') => ({ align: value }),
  size: (value = 'md') => ({ size: value })
};

// Generate colors functions
for (const color in colors) {
  propStyles = {
    ...propStyles,
    [color]: (value = colors[color].default) => ({
      color: colors[color][value]
    })
  };
}

// Generate textSize functions
for (const dim in textDim) {
  propStyles = {
    ...propStyles,
    [dim]: () => ({
      fontSize: textDim[dim]
    })
  };
}

/**
 * Generates styles from component props
 * @param {object} props
 * @returns {object} style
 */
export const styleFromProps = (props) => {
  return Object.keys(props).reduce((acc, prop) => {
    if (propStyles.hasOwnProperty(prop) && props[prop]) {
      const value = typeof props[prop] === 'boolean' ? undefined : props[prop];
      return { ...acc, ...propStyles[prop](value) };
    }
    return acc;
  }, {});
};

/**
 * Get all prop style attributes
 */
export const getAvailableStyleAttributes = () => {
  return Object.keys(propStyles).map((prop) => {
    if (propStyles.hasOwnProperty(prop) && propStyles[prop]) return prop;
    return undefined;
  });
};
