import {
  LOGIN,
  LOGOUT,
  SET_USER,
  LOGOUT_USER,
  INIT_USER,
  LOGIN_FAILED,
  INIT_USER_FAILED,
  FORGOT_PASSWORD_FORM_FAILURE,
  FORGOT_PASSWORD_FORM_SUCCESS,
  FORGOT_PASSWORD_FORM_SUBMIT,
  RESET_PASSWORD,
  UPSERT_USER,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  APPEND_USERS,
  APPEND_USERS_FAIL,
  APPEND_USERS_SUCCESS,
  SET_BE_ENDPOINT
} from './user.actionTypes';
import { createFormAction } from 'redux-form-saga';

export const initCurrentUser = () => ({
  type: INIT_USER
});

export const setCurrentUser = (user) => ({
  type: SET_USER,
  payload: user
});
export const setBackend = (url) => ({
  type: SET_BE_ENDPOINT,
  payload: { url }
});

export const initUserFailed = () => ({
  type: INIT_USER_FAILED
});

export const login = (user) => ({
  type: LOGIN,
  payload: user
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user
});

export const logout = () => ({
  type: LOGOUT
});

export const logoutSuccess = () => ({
  type: LOGOUT_USER
});

export const forgotPasswordFormSubmit = (payload) => ({
  type: FORGOT_PASSWORD_FORM_SUBMIT,
  payload: payload
});

export const forgotPasswordFormFailure = (error) => ({
  type: FORGOT_PASSWORD_FORM_FAILURE,
  payload: error
});

export const forgotPasswordFormSuccess = () => ({
  type: FORGOT_PASSWORD_FORM_SUCCESS
});

export const loginFailed = (err) => ({
  type: LOGIN_FAILED,
  payload: err
});

export const resetPassword = createFormAction(RESET_PASSWORD);

export const upsertUser = createFormAction(UPSERT_USER);

export const fetchUsers = (params = {}) => ({
  type: FETCH_USERS,
  payload: params
});

export const fetchUsersSuccess = (response) => ({
  type: FETCH_USERS_SUCCESS,
  payload: response
});

export const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error
});

export const appendUsers = (params = {}) => ({
  type: APPEND_USERS,
  payload: params
});

export const appendUsersSuccess = (response) => ({
  type: APPEND_USERS_SUCCESS,
  payload: response
});

export const appendUsersFail = (error) => ({
  type: APPEND_USERS_FAIL,
  payload: error
});

export const DELETE_USERS = 'DELETE_USERS';
export const deleteUsers = (users) => ({
  type: DELETE_USERS,
  payload: users
});

export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const deleteUsersSuccess = (users) => ({
  type: DELETE_USERS_SUCCESS,
  payload: users
});

export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE';
export const deleteUsersFailure = (error) => ({
  type: DELETE_USERS_FAILURE,
  payload: error
});
