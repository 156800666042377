import React from 'react';
import Selector from './Selector';
import { translate } from '../../translate/i18n';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { compose } from 'ramda';
import { isEmptyOrNil } from '../../utils';

const SelectorDiscountUnitType = (props) => {
  const { t, name, label, value, isInForm, isDisabled, isClearable, placeholder, onChange, isMulti } = props;
  const handleChange = (val) => {
    if (isEmptyOrNil(val)) {
      onChange(null);
    } else {
      onChange(val);
    }
  };

  const options = [
    {
      value: 'fixed',
      label: t('default:FIXED')
    },
    {
      value: 'percent',
      label: '%'
    }
  ];

  return (
    <Selector
      isInForm={isInForm}
      isMulti={isMulti}
      name={name}
      options={options}
      label={label}
      value={value}
      onChange={handleChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorDiscountUnitType.propTypes = {
  ready: bool,
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  t: func,
  name: string.isRequired,
  onChange: func,
  onLoad: func,
  autoSelect: bool,
  label: string,
  value: oneOfType([string, array]),
  storeType: string,
  storeName: string,
  isDisabled: bool,
  placeholder: string
};

SelectorDiscountUnitType.defaultProps = {
  onLoad: () => {},
  storeType: 'session',
  storeName: null,
  ready: true,
  isClearable: true,
  autoSelect: false,
  isInForm: false,
  isMulti: false
};

export default compose(translate(['discounts', 'dafault']))(SelectorDiscountUnitType);
