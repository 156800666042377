import React from 'react';
import { func, array } from 'prop-types';
import { reduxForm, formValues, Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { Block, Row, Button, ActionsContainer, SelectorForm, Text, RecurrenceInput } from '../components';
import { upsertService } from './houses.actions';
import StyledForm from '../components/form.styles';
import { required, upperCase } from '../utils';
import { StyledLabel, InputContainer } from '../components/form/TextInput.styles';

const ServiceForm = (props) => {
  // Props
  const {
    t,
    initialValues,
    recurrences,
    change,
    submitting,
    handleSubmit,
    submitSucceeded,
    onClose,
    possibleServices,
    timezone
  } = props;

  if (submitSucceeded) {
    onClose();
  }

  const options = possibleServices.map((service) => ({
    label: t(`house:SERVICE_TYPES.${upperCase(service)}`),
    value: service
  }));

  // Render
  return (
    <StyledForm onSubmit={handleSubmit(upsertService)}>
      <Block pl pr pb>
        {initialValues.name ? (
          <InputContainer>
            <StyledLabel>{t('NAME')}</StyledLabel>
            <Text size="md" weight={500}>
              {t(`house:SERVICE_TYPES.${upperCase(initialValues.name)}`)}
            </Text>
          </InputContainer>
        ) : (
          <Field component={SelectorForm} validate={required} options={options} name="name" label={t('NAME')} />
        )}

        <RecurrenceInput change={change} recurrences={recurrences} timezone={timezone} />

        <ActionsContainer>
          <Row middle right mb mt pr>
            <Button clear mr-half size="md" onClick={onClose} name="cancel" disabled={submitting}>
              {t('CANCEL')}
            </Button>

            <Button bg-primary size="md" name="save" type="submit" disabled={submitting}>
              {t('SAVE')}
            </Button>
          </Row>
        </ActionsContainer>
      </Block>
    </StyledForm>
  );
};

ServiceForm.propTypes = {
  t: func,
  possibleServices: array,
  onClose: func,
  ...reduxFormPropTypes
};

export default compose(
  translate('form'),
  reduxForm({ form: 'ServiceForm', enableReinitialize: true, destroyOnUnmount: true }),
  formValues({ recurrences: 'recurrences', timezone: 'timezone' })
)(ServiceForm);
