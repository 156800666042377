/* eslint-disable complexity */

import React from 'react';
import styled from '@emotion/styled';
import { string, array, object, oneOfType } from 'prop-types';
import { spacing } from './decorators';
import { compose } from 'ramda';

const StyledBlock = styled('div')(({ spacing }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 0,
  width: '100%',
  ...spacing
}));

const Block = ({ children, spacing }) => <StyledBlock spacing={spacing}>{children}</StyledBlock>;

Block.propTypes = {
  children: oneOfType([string, array, object]),
  spacing: object
};

export default compose(spacing())(Block);
