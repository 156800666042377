import React, { Fragment, useState, useEffect } from 'react';
import { string, oneOfType, func, object, array, bool } from 'prop-types';
import { pathOr, path, map, prop, propOr } from 'ramda';
import { AsyncSelector, Row, Col, FreeTextInput, ActionButton } from '.';
import { getGoogleMapCDN } from '../address/address.service';
import { InputContainer } from './form/TextInput.styles';
import { emptyArray, isEmptyOrNil, isNotEmptyOrNil, formatAddress } from '../utils';

// Vars
const gmapId = 'gmap';
let debounce = null;

/**
 * Load Async options from Gmap
 * @param {string} input
 */
const loadOptions = (types) => (input) =>
  new Promise((resolve) => {
    if (isEmptyOrNil(input)) {
      resolve(emptyArray);
    } else {
      if (debounce) {
        clearTimeout(debounce);
      }

      debounce = setTimeout(() => {
        if (!window.google.maps.places) {
          resolve(emptyArray);
        } else {
          const autocomplete = new window.google.maps.places.AutocompleteService();

          autocomplete.getPlacePredictions({ input, types }, (predictions, status) => {
            if (status !== 'OK') {
              resolve(emptyArray);
            } else {
              resolve(
                map(
                  (prediction) => ({ label: prop('description', prediction), value: prop('description', prediction) }),
                  predictions
                )
              );
            }
          });
        }
      }, 800);
    }
  });

/**
 * Autocomplete Component
 * @param {*} props
 */
const AddressAutocomplete = (props) => {
  const { label, onChange, value, gpsCoordinatesOnly } = props;

  // State
  const [address, setAddress] = useState(gpsCoordinatesOnly ? { gpsCoordinates: value } : value);
  const [showCoords, toggleCoords] = useState(prop('gpsCoordinates', address) && !prop('streetName', address));

  // Vars
  let formattedAddress = formatAddress(address);
  let geocoder = null;
  let latitude = path(['gpsCoordinates', 'coordinates', 1], address);
  let longitude = path(['gpsCoordinates', 'coordinates', 0], address);
  const types = isNotEmptyOrNil(props.types) ? props.types : ['address'];

  // FUnctions
  const handleAddressSelect = (addr) => {
    const input = isEmptyOrNil(addr) ? undefined : addr;

    if (!input) {
      setAddress(input);
      onChange(input);
      return;
    }

    if (!window.google.maps.Geocoder) {
      return;
    }

    if (geocoder === null) {
      geocoder = new window.google.maps.Geocoder();
    }

    geocoder.geocode({ address: addr }, (results, status) => {
      if (status !== 'OK') {
        return;
      }

      // Get result object information
      const components = path([0, 'address_components'], results);
      const latLng = path([0, 'geometry', 'location'], results);

      // Create object that will host results according to db mobel
      const newAddress = { gpsCoordinates: { type: 'Point', coordinates: [latLng.lng(), latLng.lat()] } };

      // Set object attributes
      components.forEach((component) => {
        const elements = propOr(emptyArray, 'types', component);

        if (elements.includes('street_number')) {
          newAddress.streetNumber = prop('long_name', component);
        }

        if (elements.includes('route')) {
          newAddress.streetName = prop('long_name', component);
        }

        if (elements.includes('postal_code')) {
          newAddress.postalCode = prop('long_name', component);
        }

        if (elements.includes('locality')) {
          newAddress.town = prop('long_name', component);
        } else newAddress.town = null;
      });

      setAddress(newAddress);
      onChange(gpsCoordinatesOnly ? newAddress.gpsCoordinates : newAddress);
      toggleCoords(false);
      formattedAddress = formatAddress(newAddress);
    });
  };

  const handleLatLngChange = (type) => (event) => {
    const lng = pathOr(0, ['gpsCoordinates', 'coordinates', 0], address);
    const lat = pathOr(0, ['gpsCoordinates', 'coordinates', 1], address);
    const input = pathOr(0, ['target', 'value'], event);

    const newAddress = {
      ...address,
      gpsCoordinates: {
        type: 'Point',
        coordinates: type === 'lng' ? [input, lat] : [lng, input]
      }
    };

    setAddress(newAddress);
    onChange(gpsCoordinatesOnly ? newAddress.gpsCoordinates : newAddress);
    formattedAddress = formatAddress(newAddress);
  };

  // Effects
  useEffect(() => {
    // On mount append gmap API if it was not appended before
    if (document.getElementById(gmapId)) {
      return;
    }

    const script = document.createElement('script');

    script.id = gmapId;
    script.src = getGoogleMapCDN();
    script.async = true;

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    setAddress(gpsCoordinatesOnly ? { gpsCoordinates: value } : value);
  }, [value]);
  return (
    <Fragment>
      <Row>
        <Col lg={11}>
          <InputContainer>
            <AsyncSelector
              onChange={handleAddressSelect}
              placeholder="Type an address"
              label={label}
              isClearable={false}
              loadOptions={loadOptions(types)}
              defaultOptions={[{ label: formattedAddress, value: formattedAddress }]}
              value={prop('streetName', address) ? { label: formattedAddress, value: formattedAddress } : ''}
            />
          </InputContainer>
        </Col>
        <Col lg={1} pl-half pt>
          <ActionButton icon="MapPin" name="toggleCoordinates" onClick={() => toggleCoords(!showCoords)} />
        </Col>
      </Row>

      {showCoords && (
        <Row>
          <Col lg={6} pr-quarter>
            <FreeTextInput
              label="latitude"
              value={isNotEmptyOrNil(latitude) ? latitude : 0}
              onChange={handleLatLngChange('lat')}
              name="lat"
            />
          </Col>

          <Col lg={6} pl-quarter>
            <FreeTextInput
              label="longitude"
              value={isNotEmptyOrNil(longitude) ? longitude : 0}
              onChange={handleLatLngChange('lng')}
              name="lng"
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

AddressAutocomplete.defaultProps = {
  gpsCoordinatesOnly: false
};

AddressAutocomplete.propTypes = {
  label: string,
  types: array,
  value: oneOfType([object, string, array]),
  onChange: func.isRequired,
  gpsCoordinatesOnly: bool
};

export default AddressAutocomplete;
