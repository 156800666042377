import { createFormAction } from 'redux-form-saga';
import {
  UPSERT_CONTENT,
  GET_CONTENT,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_FAILURE,
  GET_CONTENTS,
  GET_CONTENTS_SUCCESS,
  GET_CONTENTS_FAILURE,
  APPEND_CONTENTS,
  APPEND_CONTENTS_SUCCESS,
  APPEND_CONTENTS_FAILURE,
  DELETE_CONTENTS,
  DELETE_CONTENTS_SUCCESS,
  DELETE_CONTENTS_FAILURE
} from './cms.actionTypes';

// Contents
export const upsertContent = createFormAction(UPSERT_CONTENT);

export const getContents = (params = {}) => ({
  type: GET_CONTENTS,
  payload: params
});

export const setContents = (contents) => ({
  type: GET_CONTENTS_SUCCESS,
  payload: contents
});

export const getContentsFailure = (err) => {
  return {
    type: GET_CONTENTS_FAILURE,
    payload: err
  };
};

export const getContent = (params = {}) => ({
  type: GET_CONTENT,
  payload: params
});

export const setContent = (content) => ({
  type: GET_CONTENT_SUCCESS,
  payload: content
});

export const getContentFailure = (err) => {
  return {
    type: GET_CONTENT_FAILURE,
    payload: err
  };
};

export const appendContents = (params = {}) => ({
  type: APPEND_CONTENTS,
  payload: params
});

export const appendContentsSuccess = (contents) => ({
  type: APPEND_CONTENTS_SUCCESS,
  payload: contents
});

export const appendContentsFailure = (err) => ({
  type: APPEND_CONTENTS_FAILURE,
  payload: err
});

export const deleteContents = (params = {}) => ({
  type: DELETE_CONTENTS,
  payload: params
});

export const deleteContentsSuccess = (contents) => ({
  type: DELETE_CONTENTS_SUCCESS,
  payload: contents
});

export const deleteContentsFailure = (err) => ({
  type: DELETE_CONTENTS_FAILURE,
  payload: err
});
