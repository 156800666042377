import styled from '@emotion/styled';
import { css } from '@emotion/css';

export const modalContainer = css({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1003,
  transform: 'scale(0)',
  background: 'transparent',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  transition: 'background .3s ease',
  '&.active': {
    transform: 'scale(1)',
    background: 'rgba(0,0,0, 0.8)'
  }
});

export const StyledModal = styled('div')(({ theme, fit, full, pdf }) => ({
  marginTop: full ? 0 : '5%',
  width: pdf ? '100%' : fit ? 'auto' : '60%',
  minHeight: full ? '100%' : '100px',
  maxHeight: full ? '100%' : '80%',
  maxWidth: '100%',
  background: 'white',
  borderRadius: theme.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: pdf && '100%'
}));

export const StyledHeader = styled('div')(() => ({
  position: 'relative'
}));

export const StyleCloseButton = styled('div')(() => ({
  position: 'absolute',
  top: '0',
  right: '0'
}));

export const StyledContent = styled('div')(({ hasActions, visibleOverflow, full }) => ({
  overflow: visibleOverflow ? 'visible' : 'auto',
  marginBottom: hasActions ? '48px' : 0,
  flex: full ? 1 : 'auto',
  display: full ? 'flex' : 'block'
}));
