import React, { useState, useEffect, Fragment } from 'react';
import { func, object, array } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, prop, pathOr } from 'ramda';
import { Table, Block, Row, Text, Modal, ActionButton, ActionSecondaryButton } from '../components';
import { translate } from '../translate/i18n';
import { emptyObject, emptyArray } from '../utils';
import { deleteUnit, getHouseUnits } from './houses.actions';
import UnitForm from './House.unit.form';
import { getRooms } from '../rooms/room.actions';

const getColumnDefs = (t) => [
  {
    headerName: '',
    field: '',
    width: 5,

    headerComponentFramework: ({ api, column }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            isChecked ? api.deselectAll() : api.selectAll();
            setIsChecked(!isChecked);
            api.refreshCells({ columns: [column], force: true });
          }}
        />
      );
    },
    onCellClicked: () => null,

    cellRendererFramework: ({ node, api }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(node.isSelected());
      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!node.isSelected());
            node.setSelected(!node.isSelected());
            api.refreshHeader();
          }}
        />
      );
    }
  },
  {
    headerName: t('NAME'),
    field: 'name',
    width: 50
  },
  {
    headerName: t('Rooms'),
    field: 'rooms',
    width: 50
  }
];

const Units = (props) => {
  // Props
  const { t, house, rooms, deleteUnit, getHouseUnits, getRooms } = props;

  // State
  const [showModal, toggleModal] = useState(false);
  const [selectedUnit, selectUnit] = useState(emptyObject);
  const [houseUnits, setHouseUnits] = useState([]);

  // Functions
  const closeModal = () => {
    toggleModal(false);
  };
  const openCreateModal = () => {
    selectUnit(emptyObject);
    toggleModal(true);
  };
  const deleteSelection = () => {
    deleteUnit(selectedUnit);
    selectUnit(emptyArray);
  };
  const onRowSelected = (event) => selectUnit(event.api.getSelectedRows());
  const onRowClicked = (event) => {
    selectUnit(prop('data', event));
    toggleModal(true);
  };

  // Hooks
  useEffect(() => {
    if (house.id) {
      getHouseUnits(house.id);
    }
  }, []);

  useEffect(() => {
    getRooms({ houseId: house.id });
  }, [house]);

  useEffect(() => {
    if (house.units) {
      setHouseUnits(house.units);
    }
  }, [house.units]);

  //map each room so that we can show already assigned rooms
  const roomsDefault = { rooms: {} };
  rooms.forEach((room) => {
    roomsDefault['rooms'][room.id] = room.unitId ? room.unitId === selectedUnit.id : undefined;
  });

  // Render
  const renderAddButton = () => <ActionButton name="add-unit" icon="Plus" onClick={openCreateModal} />;
  const renderDeleteButton = () =>
    selectedUnit.length ? (
      <ActionSecondaryButton name="removeUnit" icon="Trash2" onClick={deleteSelection} danger />
    ) : null;

  return (
    <Fragment>
      <Block p>
        <Row mb>
          <Text size="lg" mr-half>
            {t('UNITS')}
          </Text>

          {renderAddButton()}
          {renderDeleteButton()}
        </Row>

        <Row>
          <Table
            data={{
              columnDefs: getColumnDefs(t),
              rowData: houseUnits,
              defaultColDef: { onCellClicked: onRowClicked }
            }}
            onSelectionChanged={onRowSelected}
          />
        </Row>
      </Block>

      <Modal
        show={showModal}
        title={prop('id', selectedUnit) ? t('EDIT_UNIT') : t('ADD_UNIT')}
        onClose={closeModal}
        content={() => (
          <UnitForm
            currentUnit={selectedUnit}
            initialValues={{
              ...selectedUnit,
              ...roomsDefault,
              initialRooms: { ...Object.assign({}, roomsDefault.rooms) },
              houseId: house.id
            }}
            units={houseUnits}
            rooms={rooms}
            onClose={closeModal}
          />
        )}
      />
    </Fragment>
  );
};

Units.propTypes = {
  house: object,
  units: array,
  rooms: array,
  getHouseUnit: func,
  deleteUnit: func,
  getHouseUnits: func,
  getRooms: func,
  t: func
};

export default compose(
  withRouter,
  translate('house'),
  connect(
    (state) => ({
      house: pathOr(emptyObject, ['houses', 'current', 'data'], state),
      rooms: pathOr(emptyArray, ['room', 'list'], state)
    }),
    { deleteUnit, getHouseUnits, getRooms }
  )
)(Units);
