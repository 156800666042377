import React, { useEffect, useState } from 'react';
import { func, string, object, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pathOr, path, isEmpty } from 'ramda';

import HelloSign from 'hellosign-embedded';

import { getHelloSignLease } from './leases.actions';
import { translate } from '../translate/i18n';
import { Container, Text, Loader } from '../components';

import Logo from '../assets/cohabs.svg';

const CohabsLogo = () => (
  <Container center mb>
    <svg width={186} height={52}>
      <use xlinkHref={`${Logo}#cohabs-logo`} />
    </svg>
  </Container>
);

/**
 * Content to be displayed when the user sign succesfully
 * @param {Object} props
 */
const SuccessMessage = (props) => {
  const { t } = props;

  return (
    <Container column center p>
      <Text noWrap={false}>{t('SIGN_LEASE_SUCCESS_MESSAGE')}</Text>
    </Container>
  );
};

SuccessMessage.propTypes = {
  t: func.isRequired
};

/**
 * Main Frame
 * @param {Object} props
 */
const LeaseSignature = (props) => {
  const { t, isFetching, leaseUrl, getHelloSignLease, restricted } = props;
  const bookingId = path(['match', 'params', 'id'], props);
  const token = path(['match', 'params', 'token'], props);

  const [isSigned, setIsSigned] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openLease = () => {
    const client = new HelloSign({ clientId: process.env.REACT_APP_HELLO_SIGN });

    client.open(leaseUrl, { skipDomainVerification: true });
    client.on('close', () => setIsLoading(false));
    client.on('sign', () => setIsSigned(true));
  };

  useEffect(() => {
    if (!isEmpty(leaseUrl)) {
      openLease();
    }
  }, [leaseUrl]);

  const signMessage = () => {
    setIsLoading(true);
    getHelloSignLease({ bookingId, token });
  };

  if (restricted) {
    return (
      <Container full center column>
        <Container center row>
          <Text
            html={t('AN_ERROR_OCCURED_LEASE_SIGNED_html')}
            aditionalStyle={{
              maxWidth: '640px',
              padding: '20px'
            }}
            noWrap={false}
            type="container"
          />
        </Container>
      </Container>
    );
  }

  if (isFetching) {
    return (
      <Container full center column>
        <Text weight={400}>{t('LOADING')}</Text>
      </Container>
    );
  }

  return (
    <Container center>
      <Container column center>
        <CohabsLogo />

        <Text weight={100} size="xl">
          {`${t('MY')} ${t('LEASE')}`}
        </Text>
        {isSigned ? (
          <SuccessMessage {...props} />
        ) : !isLoading ? (
          signMessage()
        ) : (
          <Container center>
            <Loader />
          </Container>
        )}
      </Container>
    </Container>
  );
};

LeaseSignature.propTypes = {
  history: object,
  leaseUrl: string,
  getHelloSignLease: func,
  t: func,
  firstname: string,
  lastname: string,
  resticted: bool,
  isFetching: bool
};

export default compose(
  withRouter,
  translate(['leases', 'default']),
  connect(
    (state) => ({
      firstname: pathOr('', ['leases', 'current', 'data', 'firstName'], state),
      lastname: pathOr('', ['leases', 'current', 'data', 'lastName'], state),
      restricted: pathOr('', ['leases', 'restricted'], state),
      leaseUrl: pathOr('', ['leases', 'current', 'data', 'url'], state),
      isFetching: pathOr(false, ['leases', 'current', 'data', 'isFetching'], state)
    }),
    { getHelloSignLease }
  )
)(LeaseSignature);
