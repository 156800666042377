import Selector from './Selector';
import React from 'react';
import { compose, isEmpty } from 'ramda';
import { array, bool, func, string } from 'prop-types';
import { translate } from '../../translate/i18n';

const handleChange = (onChange) => (value) => {
  if (isEmpty(value)) return onChange();
  return onChange(value);
};

const SelectorLockCategory = (props) => {
  const { name, onChange, isMulti, label, value, isInForm, isDisabled, placeholder, t } = props;
  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={[
        {
          label: t('HOUSE'),
          value: 'house'
        },
        {
          label: t('BASEMENT'),
          value: 'basement'
        },
        {
          label: t('ROOFTOP'),
          value: 'rooftop'
        }
      ]}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange(onChange)}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

SelectorLockCategory.propTypes = {
  isInForm: bool,
  t: func,
  name: string.isRequired,
  list: array,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  placeholder: string
};

Selector.defaultValues = {
  isInForm: false
};

export default compose(translate('lock'))(SelectorLockCategory);
