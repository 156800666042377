import React, { Fragment, useState } from 'react';
import { number, bool, func, array, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, pathOr, prop, path } from 'ramda';
import { translate } from '../translate/i18n';
import { valueOrPlaceholder, isNotEmptyOrNil, formatDatetime, formatDate, formatAge } from '../utils';
import { getListApplications, appendListApplications } from './applications.actions';
import { ScrollableDataTableWithColSelector } from '../components';
import Filter from './Application.filter';

const getColumnDefs = (t) => [
  {
    headerName: t('APPLICATION_DATE'),
    field: 'createdAt',
    width: 150,
    valueFormatter: (col) => formatDate(col.value),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value)
  },
  {
    headerName: t('FIRST_NAME'),
    field: 'user.firstName',
    defaultActive: true,
    width: 150
  },
  {
    headerName: t('LAST_NAME'),
    field: 'user.lastName',
    defaultActive: true,
    width: 150
  },
  {
    headerName: t('AGE'),
    field: 'user.birthdate',
    width: 50,
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatAge)(prop('value', col))
  },
  {
    headerName: t('DATE_MOVE_IN'),
    field: 'moveinDate',
    defaultActive: true,
    width: 140,
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDate)(col.value),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value)
  },
  {
    headerName: t('DURATION_STAY'),
    field: 'duration',
    width: 140,
    valueFormatter: (col) => (col.value ? t('MONTH_FORMAT', { value: col.value }) : undefined)
  },
  {
    headerName: t('STATUS'),
    field: 'status',
    width: 100
  },
  {
    headerName: t('CALL'),
    field: 'callDate',
    width: 120,
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDatetime)(col.value),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value)
  }
];

const ApplicationTable = (props) => {
  // Props
  const {
    t,
    history,
    applicationsList,
    applicationsCount,
    responseLength,
    isFetching,
    getListApplications,
    appendListApplications
  } = props;

  // State
  const [filters, setFilters] = useState(null);

  const goToApplication = (event) => {
    history.push(`/applications/${path(['data', 'id'], event)}`);
  };

  return (
    <Fragment>
      <Filter onFilterSelect={(filters) => setFilters(filters)} withStatus />

      <ScrollableDataTableWithColSelector
        filters={filters}
        ready={!!filters}
        columnDefs={getColumnDefs(t)}
        defaultColDef={{ valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)) }}
        getMethod={getListApplications}
        appendMethod={appendListApplications}
        rows={applicationsList}
        rowCount={applicationsCount}
        isFetching={isFetching}
        responseLength={responseLength}
        onRowClicked={goToApplication}
      />
    </Fragment>
  );
};

ApplicationTable.propTypes = {
  getListApplications: func,
  appendListApplications: func,
  applicationsList: array,
  applicationsCount: number,
  isFetching: bool,
  responseLength: number,
  t: func,
  history: object
};

export default compose(
  withRouter,
  translate(['applications', 'default']),
  connect(
    (state) => ({
      applicationsList: pathOr([], ['applications', 'list'], state),
      applicationsCount: pathOr(0, ['applications', 'count'], state),
      responseLength: pathOr(0, ['applications', 'responseLength'], state),
      isFetching: pathOr(0, ['applications', 'isFetching'], state)
    }),
    {
      getListApplications,
      appendListApplications
    }
  )
)(ApplicationTable);
