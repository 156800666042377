import React from 'react';
import { func, object, string, number, bool } from 'prop-types';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { Block, Text, Row, Col } from '../components';
import { formatDate, formatPrice } from '../utils';
import { discountCalculator } from './discountCalculator';
import { isDiscountExpired } from './validation';

const renderDiscountInfo = ({ t, timezone, currencySymbol, incomingPrice, redeemBy, duration, amount, type }) => {
  return (
    <Row>
      <Col left>
        <Row mb>
          <Text size="sm" weight={400}>
            {t('REDEEM_BY')}
          </Text>
        </Row>
        <Row>
          <Text size="sm" weight={200}>
            {formatDate(redeemBy, timezone)}
          </Text>
        </Row>
      </Col>

      <Col>
        <Row mb>
          <Text size="sm" weight={400}>
            {t('DURATION')}
          </Text>
        </Row>
        <Row>
          <Text size="sm" weight={200}>
            {duration}
          </Text>
        </Row>
      </Col>

      <Col>
        <Row mb>
          <Text size="sm" weight={400}>
            {t('AMOUNT')}
          </Text>
        </Row>
        <Row>
          {type === 'fixed' ? (
            <Text size="sm" weight={200}>
              {`${formatPrice(amount)} ${currencySymbol}`}
            </Text>
          ) : (
            <Text size="sm" weight={200}>
              {`${formatPrice(amount)}% (${discountCalculator({
                priceValue: +incomingPrice,
                discountValue: +amount,
                discountType: type
              })} ${currencySymbol})`}
            </Text>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const DiscountPreview = ({ t, timezone, currencySymbol, discount, incomingPrice, moveInDate, enabled }) => {
  if (!discount) {
    return null;
  }

  const expired = isDiscountExpired(moveInDate, discount.redeemBy);
  const disabledByUser = !enabled && !expired;

  return (
    <Block>
      <Row>
        <Col mb>
          <Row left>
            {disabledByUser && (
              <Text noWrap={false} type="container" size="sm" weight={300} pt-half>
                {t('DISABLED_BY_USER')}
              </Text>
            )}
          </Row>
          <Row left>
            {expired && (
              <Text noWrap={false} type="container" size="sm" weight={300} pt-half>
                {t('NO_LONGER_AVAILABLE')}
              </Text>
            )}
          </Row>
        </Col>
      </Row>

      {!expired &&
        !disabledByUser &&
        renderDiscountInfo({
          t,
          timezone,
          currencySymbol,
          incomingPrice,
          moveInDate,
          ...discount
        })}
    </Block>
  );
};

DiscountPreview.propTypes = {
  t: func,
  discount: object,
  timezone: string,
  currencySymbol: string,
  incomingPrice: number,
  moveInDate: string,
  enabled: bool
};

export default compose(translate('discounts'))(DiscountPreview);
