/* eslint-disable complexity */

import React from 'react';
import styled from '@emotion/styled';
import { string, array, object, bool, oneOfType } from 'prop-types';
import { spacing, alignement } from './decorators';
import { bgColor } from '../theme/decorators';
import { compose, assoc, isEmpty } from 'ramda';

const StyledContainer = styled('div')((props) => {
  const { column, spacing, full, main, alignement, theme, bgColor, maxWidth, scrollable, relative } = props;

  let properties = {
    width: '100%',
    ...spacing,
    ...alignement,
    display: 'flex',
    height: main ? '100vh' : full ? '100%' : 'auto',
    flexDirection: column ? 'column' : 'row',
    color: theme.colors.grey[500],
    borderRight: 'none',
    overflow: scrollable ? 'auto' : 'visible',
    backgroundColor: bgColor ? theme.colors.lightOrange[500] : 'tranparent',
    label: scrollable ? 'scrollable' : '',
    position: relative ? 'relative' : undefined
  };

  if (!isEmpty(maxWidth)) {
    properties = assoc('maxWidth', maxWidth, properties);
  }

  return properties;
});

const Container = ({ main, full, children, column, spacing, alignement, bgColor, maxWidth, scrollable, relative }) => (
  <StyledContainer
    maxWidth={maxWidth}
    main={main}
    full={full}
    spacing={spacing}
    column={column}
    alignement={alignement}
    bgColor={bgColor}
    scrollable={scrollable}
    relative={relative}>
    {children}
  </StyledContainer>
);
Container.propTypes = {
  children: oneOfType([string, array, object]),
  column: bool,
  spacing: object,
  alignement: object,
  full: bool,
  main: bool,
  bgColor: bool,
  maxWidth: string,
  scrollable: bool,
  relative: bool
};
Container.defaultProps = {
  column: false,
  full: false,
  relative: false
};

export default compose(bgColor(), spacing(), alignement())(Container);
