import React, { Fragment } from 'react';
import { compose, pathOr, propOr } from 'ramda';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';

import { Modal, show } from '../components';
import Chat from './Chat';
import NewChannel from './newChannel';
import { translate } from '../translate/i18n';
import { toggleCreateChatChannel } from './chat.actions';

const ChatPage = ({ t, chat, toggleCreateChatChannel }) => {
  return (
    <Fragment>
      <Chat />

      <Modal
        title={t('NEW_CONVERSATION')}
        show={chat.showCreateChannelModal}
        onClose={() => toggleCreateChatChannel(false)}
        content={() => <NewChannel />}
      />
    </Fragment>
  );
};

ChatPage.propTypes = {
  chat: object.isRequired,
  t: func.isRequired,
  toggleCreateChatChannel: func.isRequired
};

export default compose(
  translate('chat'),
  connect(
    (state) => ({
      chat: propOr({}, ['chat'], state)
    }),
    {
      toggleCreateChatChannel
    }
  ),
  show((props) => pathOr(false, ['chat', 'connectStatus'], props))
)(ChatPage);
