import React from 'react';
import { func, bool } from 'prop-types';
import {
  Button,
  ActionsContainer,
  Row,
  TextInput,
  Container,
  Col,
  DatePicker,
  SelectorGenderForm,
  SelectorNationalityForm,
  TextAreaForm,
  PhoneForm,
  ToggleForm
} from '../components';
import { translate } from '../translate/i18n';
import { capitalize, required, name, email, maxLength, hasPhonePrefix } from '../utils';
import { compose } from 'ramda';
import { reduxForm, Field } from 'redux-form';
import { updateMember } from './member.actions';
import SectionTitle from '../components/SectionTitle';

const max512 = maxLength(512);

const MemberForm = (props) => {
  const { onClose, t, handleSubmit, submitting, submitSucceeded, showToggleManager } = props;

  if (submitSucceeded) {
    onClose();
  }

  return (
    <Container p column>
      <form onSubmit={handleSubmit(updateMember)}>
        {showToggleManager && (
          <Container column>
            <SectionTitle translationKey="default:HOUSE" />
            <Row>
              <Col lg={12} pt-half pb-half pr-half>
                <Field name="houseManager" label={`${t('SET_HOUSE_MANAGER')}`} component={ToggleForm} type="checkbox" />
              </Col>
            </Row>
          </Container>
        )}
        <SectionTitle translationKey="default:PERSONAL_INFO" />
        <Row>
          <Col xl={6} lg={6} md={12} sm={6} pt-half pb-half pr-half>
            <Field
              name="firstName"
              label={`${t('FIRST_NAME')}*`}
              component={TextInput}
              validate={[required, name]}
              placeholder={t('FIRST_NAME')}
            />
            <Field
              name="email"
              label={`${t('EMAIL')}*`}
              component={TextInput}
              validate={[required, email]}
              placeholder={t('EMAIL')}
            />
            <Field
              name="nickName"
              label={t('NICKNAME')}
              component={TextInput}
              validate={name}
              placeholder={t('NICKNAME')}
            />
            <Field name="gender" label={t('GENDER')} component={SelectorGenderForm} placeholder={t('GENDER')} />
          </Col>
          <Col xl={6} lg={6} md={12} sm={6} pt-half pr-half pb-half>
            <Field
              name="lastName"
              label={`${t('LAST_NAME')}*`}
              component={TextInput}
              validate={[required, name]}
              placeholder={t('LAST_NAME')}
            />
            <Field name="birthdate" label={t('BIRTHDATE')} component={DatePicker} placeholder={t('BIRTHDATE')} />
            <Field
              name="phone"
              label={t('PHONE_NUMBER')}
              type="tel"
              component={PhoneForm}
              validate={[required, hasPhonePrefix]}
              placeholder={t('PHONE_NUMBER')}
            />
            <Field
              name="nationalityId"
              label={t('NATIONALITY')}
              component={SelectorNationalityForm}
              placeholder={t('NATIONALITY')}
            />
          </Col>
        </Row>
        <SectionTitle translationKey="default:SOCIAL_MEDIA" />
        <Row>
          <Col xl={6} lg={6} md={12} sm={6} pt-half pb-half pr-half>
            <Field name="facebook" label={t('FACEBOOK')} component={TextInput} />
            <Field name="instagram" label={t('INSTAGRAM')} component={TextInput} />
          </Col>
          <Col xl={6} lg={6} md={12} sm={6} pt-half pr-half pb-half>
            <Field name="twitter" label={t('TWITTER')} component={TextInput} />
          </Col>
        </Row>
        <SectionTitle translationKey="default:OTHER" />
        <Row>
          <Col lg={12} pt-half pr-half>
            <Field name="about" label={t('ABOUT')} component={TextAreaForm} validate={max512} />
          </Col>
        </Row>
        <ActionsContainer>
          <Row middle p-half right>
            <Button clear mr-half onClick={onClose} size="md" name="cancel" disabled={submitting}>
              {capitalize(t('default:CANCEL'))}
            </Button>
            <Button primary name="save" type="submit" size="md" bg-primary disabled={submitting}>
              {capitalize(t('default:UPDATE'))}
            </Button>
          </Row>
        </ActionsContainer>
      </form>
    </Container>
  );
};
MemberForm.propTypes = {
  onClose: func,
  t: func,
  handleSubmit: func,
  showToggleManager: bool,
  submitting: bool,
  submitSucceeded: bool
};

export default compose(
  reduxForm({
    form: 'MemberForm'
  }),
  translate('form')
)(MemberForm);
