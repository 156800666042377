import React from 'react';
import { prop, propOr, pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { translate } from '../translate/i18n';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { emptyObject } from '.';

export const STATUS = Object.freeze({
  IS_LOGGED: 'isLogged',
  NOT_LOGGED: 'notLogged'
});

export const ROLES = Object.freeze({
  ADMIN: 'admin',
  CM: 'communityManager',
  PM: 'propertyManager',
  CLEANER: 'cleaning',
  INTERN: 'intern',
  USER: 'user'
});

const emptyArray = [];

const getRules = (rules) => ({
  roles: rules.roles || emptyArray,
  status: rules.status || emptyArray
});

export const hasRole = (roles, user) => {
  const userRole = propOr([], 'role', user);

  return roles.includes(userRole);
};

const auth = (rules) => (WrappedComponent) => (props) => {
  const { user, isLogged } = props;
  const { status, roles } = getRules(rules);

  if ((status.includes(STATUS.IS_LOGGED) && !isLogged) || (status.includes(STATUS.NOT_LOGGED) && isLogged)) {
    return null;
  }

  if (!roles.length || (roles.length && roles.includes(prop('role', user)))) {
    return <WrappedComponent {...props} />;
  }

  return null;
};

auth.propTypes = {
  user: PropTypes.object,
  rules: PropTypes.object,
  t: PropTypes.func
};

export const Authorization = (rules = {}) =>
  compose(
    translate(),
    connect((state) => ({
      user: pathOr(emptyObject, ['user', 'data'], state),
      isLogged: pathOr(emptyObject, ['user', 'isLogged'], state)
    })),
    auth(rules)
  );

export const IsLogged = Authorization({ status: [STATUS.IS_LOGGED] });
export const NotLogged = Authorization({ status: [STATUS.NOT_LOGGED] });
export const isAdmin = Authorization({ status: [STATUS.IS_LOGGED], roles: [ROLES.ADMIN] });
export const isAdminOrCM = Authorization({ status: [STATUS.IS_LOGGED], roles: [ROLES.ADMIN, ROLES.CM, ROLES.PM] });
