import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as service from './hubPaymentMethod.service';
import { GET_HUB_PAYMENT_METHODS_REQUEST } from './hubPaymentMethod.actionTypes';
import { fetchHubPaymentMethodSuccess } from './hubPaymentMethod.actions';
import { handleErrorMessage } from '../toast/toast.actions';
import { types } from '../utils/sagas';

export function* getHubPaymentMethods({ payload } = {}) {
  try {
    const hubs = yield call(service.getHubPaymentMethods, payload);
    yield put(fetchHubPaymentMethodSuccess({ paymentMethods: hubs, hubId: payload }));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.HUB_PAYMENT_METHODS, error: err, data: payload }));
  }
}

export default function* hubSaga() {
  yield all([takeLatest(GET_HUB_PAYMENT_METHODS_REQUEST, getHubPaymentMethods)]);
}
