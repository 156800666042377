import { GET_HUB_PAYMENT_GATEWAYS_REQUEST, GET_HUB_PAYMENT_GATEWAYS_SUCCESS } from './paymentGateway.actionTypes';

export const fetchHubPaymentGateways = () => ({
  type: GET_HUB_PAYMENT_GATEWAYS_REQUEST
});

export const fetchHubPaymentGatewaysSuccess = ({ rows }) => ({
  type: GET_HUB_PAYMENT_GATEWAYS_SUCCESS,
  payload: { list: rows }
});
