import React, { useEffect, useState } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { equals, isEmpty, path } from 'ramda';

import { FreeTextInput } from './index';
import browserStore from '../utils/browserStore';

const SearchTextInput = (props) => {
  const { name, label, value, placeholder, ready, onChange, storeType, storeName, defaultValue, onLoad } = props;

  const [preset, setPreset] = useState(value || defaultValue);
  let [debounce, setDebounce] = useState(value || defaultValue);

  const handleChange = (event) => {
    if (debounce) {
      clearTimeout(debounce);
    }

    const search = path(['target', 'value'], event);

    debounce = setTimeout(() => {
      browserStore.setItem(storeType, `${storeName}:${name}`, search);
      onChange(search);
    }, 600);

    setDebounce(debounce);
    setPreset(search);
  };

  const noData = (preset) => !preset || isEmpty(preset);

  useEffect(() => {
    let preset = value;
    if (ready && noData(value)) {
      const res = browserStore.getItem(storeType, `${storeName}:${name}`);

      if (!noData(res)) {
        preset = res;
      } else if (!noData(defaultValue)) {
        preset = res;
      }
    }

    if (!equals(preset, value)) {
      onChange(preset);
      setPreset(preset);
    }

    onLoad();
  }, [ready]);

  return <FreeTextInput label={label} name={name} placeholder={placeholder} value={preset} onChange={handleChange} />;
};

SearchTextInput.propTypes = {
  ready: bool,
  t: func,
  name: string.isRequired,
  onChange: func,
  onLoad: func,
  label: string,
  value: oneOfType([string, array]),
  storeType: string,
  storeName: string,
  defaultValue: string,
  placeholder: string
};

SearchTextInput.defaultProps = {
  onLoad: () => {},
  onChange: () => {},
  defaultValue: '',
  storeType: 'session',
  storeName: null,
  ready: true
};

export default SearchTextInput;
