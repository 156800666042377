import * as api from '@cohabs/bo-api';

export const getPackLocation = ({ packId, hubId }) => api.get(`/pack-location/${packId}/hub/${hubId}`);

export const createPackLocation = (packLocation) =>
  api.post(`/pack-location/${packLocation.packId}/hub/${packLocation.hubId}`, packLocation);
export const updatePackLocation = (packLocation) =>
  api.patch(`/pack-location/${packLocation.packId}/hub/${packLocation.hubId}`, packLocation);
export const upsertPackLocation = (packLocation) =>
  packLocation.isNew ? createPackLocation(packLocation) : updatePackLocation(packLocation);
