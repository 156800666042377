import React, { PureComponent } from 'react';
import { bool, string, func, object } from 'prop-types';
import { Link } from 'react-router-dom';
import { is } from 'ramda';
import { X } from 'react-feather';
import { modalContainer, StyledModal, StyledContent, StyledHeader, StyleCloseButton } from './Modal.styles';
import { Row, Col, Text, Button, sizing } from '../';

class Modal extends PureComponent {
  static propTypes = {
    show: bool,
    title: string,
    subTitle: object,
    onClose: func.isRequired,
    content: func,
    hasActions: bool,
    visibleOverflow: bool,
    isPicturePreview: bool,
    onInit: func,
    full: bool,
    mobileView: bool.isRequired,
    pdf: bool
  };

  static defaultProps = {
    active: false,
    onClose: null,
    hasActions: false,
    isPicturePreview: false,
    full: false,
    pdf: false
  };

  componentDidMount() {
    const { onInit } = this.props;
    if (is(Function, onInit)) onInit();
  }

  render() {
    const {
      title,
      subTitle,
      show,
      onClose,
      content,
      hasActions,
      visibleOverflow,
      isPicturePreview,
      full,
      pdf,
      mobileView
    } = this.props;

    return (
      <div className={`${modalContainer} ${show && 'active'}`}>
        <StyledModal fit={isPicturePreview} full={full || mobileView} pdf={pdf || mobileView}>
          <StyledHeader>
            <Row middle pt-quarter pb-quarter pl-quarter left>
              <Col lg={11} p>
                <Text grey={500} weight={200} size="xl">
                  {title}
                </Text>
                {subTitle && (
                  <Row>
                    <Text grey={400} weight={200} size="sm" mt-half mr-quarter>
                      {subTitle.text}{' '}
                    </Text>
                    {subTitle.link ? (
                      <Link to={subTitle.link.to}>
                        <Text blue weight={200} size="sm" mt-half cursor="pointer">
                          {subTitle.link.text}
                        </Text>
                      </Link>
                    ) : null}
                  </Row>
                )}
              </Col>
            </Row>
            <StyleCloseButton>
              <Button p-quarter name="closeModal" clear onClick={() => onClose()}>
                <X />
              </Button>
            </StyleCloseButton>
          </StyledHeader>
          <StyledContent hasActions={hasActions} visibleOverflow={visibleOverflow} full={full}>
            {show && content()}
          </StyledContent>
        </StyledModal>
      </div>
    );
  }
}

export default sizing()(Modal);
