import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { func } from 'prop-types';
import { reduxForm, Field, propTypes as reduxFormPropTypes, formValues } from 'redux-form';
import {
  BottomContainer,
  Button,
  Col,
  Row,
  Block,
  TextAreaForm,
  SelectorFaqCategoryForm,
  SelectorFaqLocationForm,
  SelectorFaqSubcategoryForm
} from '../components';
import StyledForm from '../components/form.styles';
import { upsertContent } from './cms.actions';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { required, maxLength } from '../utils';

const FaqForm = (props) => {
  const { t, isNew, duplicate, submitSucceeded, handleSubmit, onCancel, submitting, category } = props;

  if (submitSucceeded && (isNew || duplicate)) {
    return <Redirect to="/cms/faq" />;
  }

  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(upsertContent)}>
        <Block>
          <Col xl={9} lg={9} md={9} sm={12}>
            <Row>
              <Field
                component={SelectorFaqCategoryForm}
                validate={required}
                label={t('FORM.CATEGORY')}
                name="category"
              />
            </Row>
            <Row>
              <Field
                component={SelectorFaqSubcategoryForm}
                validate={required}
                category={category}
                label={t('FORM.SUBCATEGORY')}
                name="subcategoryId"
              />
            </Row>
            <Row>
              <Field
                component={SelectorFaqLocationForm}
                validate={required}
                label={t('FORM.LOCATION')}
                name="locations"
              />
            </Row>
            <Field
              component={TextAreaForm}
              validate={[required, maxLength(256)]}
              label={t('FORM.QUESTION')}
              name="question"
            />
            <Field component={TextAreaForm} validate={required} label={t('FORM.ANSWER')} name="answer" />
          </Col>
        </Block>
        <BottomContainer negativeMargin={true}>
          <Row right>
            <Button name="cancel" disabled={submitting} onClick={() => onCancel()} clear size="md">
              {t('FORM.CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('FORM.SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </BottomContainer>
      </StyledForm>
    </Fragment>
  );
};

FaqForm.propTypes = {
  t: func,
  ...reduxFormPropTypes
};

export default compose(
  translate('cms'),
  reduxForm({ form: 'FaqForm', enableReinitialize: true, destroyOnUnmount: false }),
  formValues({ category: 'category' })
)(FaqForm);
