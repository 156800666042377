import { injectGlobal } from 'emotion';

import fbwoff2 from '../assets/fonts/Montserrat-Bold.woff2';
import fbwoff from '../assets/fonts/Montserrat-Bold.woff';
import fbsvg from '../assets/fonts/Montserrat-Bold.svg';

import flwoff2 from '../assets/fonts/Montserrat-Thin.woff2';
import flwoff from '../assets/fonts/Montserrat-Thin.woff';
import flsvg from '../assets/fonts/Montserrat-Thin.svg';

import fmwoff2 from '../assets/fonts/Montserrat-Medium.woff2';
import fmwoff from '../assets/fonts/Montserrat-Medium.woff';
import fmsvg from '../assets/fonts/Montserrat-Medium.svg';

import frwoff2 from '../assets/fonts/Montserrat-Regular.woff2';
import frwoff from '../assets/fonts/Montserrat-Regular.woff';
import frsvg from '../assets/fonts/Montserrat-Regular.svg';

injectGlobal`
    body {
        margin: 0;
        padding: 0;
        line-height: 1.4285;
        font-size: 1.4rem;
    }

    html {
        font-size: 62.5%;
    }

    a {
        text-decoration: none;
    }

    * {
        box-sizing: border-box;
    }

    img {
        width: 100%;
    }

    @font-face {
        font-family: 'Montserat';
        src: url(${fbwoff2}) format('woff2'),
                url(${fbwoff}) format('woff'),
                url(${fbsvg}) format('svg');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserat';
        src: url(${fmwoff2}) format('woff2'),
             url(${fmwoff}) format('woff'),
             url(${fmsvg}) format('svg');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserat';
        src: url(${flwoff2}) format('woff2'),
             url(${flwoff}) format('woff'),
             url(${flsvg}) format('svg');
        font-weight: 100;
        font-style: normal;
    
    }
    
    @font-face {
        font-family: 'Montserat';
        src: url(${frwoff2}) format('woff2'),
             url(${frwoff}) format('woff'),
             url(${frsvg}) format('svg');
        font-weight: 200;
        font-style: normal;
    
    }

    .page-wrap {
      width: 100%;
    }

    .main-container {
      position: relative;
      max-width: calc(100% - 260px);
      width: calc(100% - 260px);
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;

      @media (max-width: 1024px) {
        max-width: calc(100% - 56px);
        width: calc(100% - 56px);
        margin-left: 56px;
      }
    }
    
    .collapse_main_menu {
      .main-container {
        max-width: calc(100% - 56px);
        width: calc(100% - 56px);
      }
    }
    
    .react-tooltip-lite {
      z-index: 1003!important;
    }

    .react-tooltip-lite-arrow {
      z-index: 1003!important;
    }

    @media (max-width: 768px) {
      .collapse_main_menu {
        .main-container {
          max-width: 100%;
          width: 100%;
          margin-left: 0px;
        }
      }

      .main-container {
        max-width: 100%;
        width: 100%;
        margin-left: 0px;
        max-height: calc(100vh - 46px);
      }
    }
    
    .mde-text{
      font-family: Montserat;
      font-weight: 300;
    }
    
    .react-tooltip-lite {
      border-radius: 5px;
      background: #333;
      color: white;    
      font-family: Montserat;
      font-weight: 300;
    }

    .react-tooltip-lite-arrow {
      border-color: #333;
    }

    .mde_wrapper {
      position: relative;
  
      .mde-header {
        padding-right: 90px;
      }
      
      .counter {
        position: absolute;
        right: 0;
        top: 0px;
        padding: 12px;
      }
      
      .mde_preview {
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #87929766;
      }
    }

    .text-container {
      font-weight: 300;
      font-family: Montserat;
      
      bold {
        font-weight: 400;
      }
    }
`;
