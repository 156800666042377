import React from 'react';
import { prop } from 'ramda';
import { AddressAutocomplete } from '../';
import { ErrorText, InputContainer } from '../form/TextInput.styles';
import { string, array, object, func, bool } from 'prop-types';
import { translate } from '../../translate/i18n';

const AddressAutocompleteForm = (props) => {
  const { t, meta, input, label, gpsCoordinatesOnly } = props;
  const { error, touched } = meta;

  return (
    <InputContainer>
      <AddressAutocomplete
        gpsCoordinatesOnly={gpsCoordinatesOnly}
        label={label}
        value={prop('value', input)}
        onChange={prop('onChange', input)}
      />
      {touched && error && <ErrorText>{t(error)}</ErrorText>}
    </InputContainer>
  );
};

AddressAutocompleteForm.defaultProps = {
  clear: false,
  gpsCoordinatesOnly: false,
  meta: { error: '', touched: false }
};

AddressAutocompleteForm.propTypes = {
  label: string,
  types: array,
  t: func,
  meta: object,
  input: object,
  gpsCoordinatesOnly: bool
};

export default translate('validation')(AddressAutocompleteForm);
