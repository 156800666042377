import React, { useEffect, useState } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, compose, map, isEmpty, is, difference } from 'ramda';

import { emptyArray, makeOptions, upperCase } from '../../utils';
import { translate } from '../../translate/i18n';
import { getFaqSubcategories } from '../../enum/enum.actions';
import { storableSelector } from '../storableDecorator';
import Selector from './Selector';

const SelectorFaqSubcategory = ({
  t,
  list,
  name,
  getFaqSubcategories,
  isMulti,
  label,
  value,
  isInForm,
  isDisabled,
  placeholder,
  category,
  onChange,
  valueControl
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    if (isEmpty(list)) {
      getFaqSubcategories();
    }
  }, []);

  useEffect(() => {
    const categoryList = is(Array, category) ? category : [category];

    const enums = map(
      (option) => ({ value: option.id, name: t(`FAQ_SUBCATEGORIES.${upperCase(option.name)}`) }),
      categoryList.length
        ? list.filter((item) => {
            const filterList = (item.categories || []).map((category) => category.name);
            return filterList.length !== difference(filterList, categoryList).length;
          })
        : list
    );

    setOptions(makeOptions(enums, 'value', 'name', 'label'));
  }, [category, list]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

SelectorFaqSubcategory.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  list: array.isRequired,
  isFetching: bool.isRequired,
  getFaqSubcategories: func.isRequired,
  t: func,
  category: oneOfType([string, array])
};

SelectorFaqSubcategory.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null,
  category: []
};

export default compose(
  storableSelector,
  translate('cms'),
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'faqSubcategories', 'list'], state),
      isFetching: pathOr(false, ['enums', 'faqSubcategories', 'isFetching'], state)
    }),
    { getFaqSubcategories }
  )
)(SelectorFaqSubcategory);
