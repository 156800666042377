import chroma from 'chroma-js';

const grey500 = '#384A52';
const brown500 = '#AE8A66';
const lightGrey500 = '#879297';
const lightBrown500 = '#f7f3ef';

export const colors = {
  brown: {
    default: 500,
    300: chroma(brown500).alpha(0.1).hex(),
    400: chroma(brown500).alpha(0.5).hex(),
    500: brown500
  },
  lightBrown: {
    default: 500,
    500: lightBrown500
  },
  white: { default: 500, 500: '#ffffff' },
  lightGrey: {
    default: 500,
    50: chroma(lightGrey500).alpha(0.05).hex(),
    100: chroma(lightGrey500).alpha(0.1).hex(),
    200: chroma(lightGrey500).alpha(0.2).hex(),
    300: chroma(lightGrey500).alpha(0.4).hex(),
    400: '#EBEDEE',
    500: '#879297'
  },
  grey: {
    default: 500,
    300: chroma(grey500).darken(-2).hex(),
    400: chroma(grey500).darken(-1).hex(),
    500: grey500
  },
  lightOrange: { default: 500, 500: '#F3EFEA' },
  black: { default: 500, 500: '#000000' },
  red: { default: 500, 500: '#D0021B', 200: '#f4cdcd' },
  yellow: { default: 200, 200: '#fff0c0' },
  green: { default: 500, 500: '#9ac51a', 200: '#d2eed1' },
  blue: { default: 500, 500: '#0000EE' },
  tableGray: { default: 500, 500: '#63767e' }
};
