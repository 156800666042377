import React from 'react';
import { string, func } from 'prop-types';
import { ImageContainer } from './form/Upload.styles';
import styled from '@emotion/styled';

const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  cursor: 'pointer'
}));

const Thumbnail = ({ id, link, onClick }) => (
  <ImageContainer size={80} margin={true}>
    <StyledImage src={link} alt={id} onClick={() => onClick(link)} />
  </ImageContainer>
);

Thumbnail.propTypes = {
  id: string,
  link: string,
  onClick: func
};

export default Thumbnail;
