import React from 'react';
import { arrayOf, shape, func, string, bool } from 'prop-types';
import Styled from '@emotion/styled';
import { upperCase } from '../utils';

const SwitchContainer = Styled('div')(({ theme }) => ({
  borderColor: theme.colors.lightGrey[400],
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: theme.borderRadius,
  display: 'flex',
  width: '100%',
  height: '28px',
  marginLeft: '10px'
}));

const SwitchItem = Styled('button')(({ theme, active }) => ({
  padding: theme.spacing.quarter,
  outline: 'none',
  border: 'none',
  flex: 1,
  backgroundColor: active ? theme.colors.grey[500] : 'white',
  color: active ? 'white' : theme.colors.lightGrey[500],
  fontSize: theme.font.size.sm,
  fontWeight: active ? theme.font.weight.md : theme.font.weight.lg,
  fontFamily: theme.font.family,
  cursor: 'pointer',
  letterSpacing: '1px'
}));

const Switch = ({ items }) => (
  <SwitchContainer>
    {items.map(({ active, label, onClick, name }, index) => (
      <SwitchItem active={active} key={index} onClick={onClick} data-cy={`btn-${name}`}>
        {upperCase(label)}
      </SwitchItem>
    ))}
  </SwitchContainer>
);

Switch.propTypes = {
  items: arrayOf(
    shape({
      name: string.isRequired,
      label: string.isRequired,
      onClick: func.isRequired,
      active: bool
    }).isRequired
  )
};

export default Switch;
