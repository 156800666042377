import React, { useState, useEffect } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { compose, pathOr, isEmpty, equals } from 'ramda';

import Selector from './Selector';
import { emptyArray, sortByCaseInsensitive } from '../../utils';
import { fetchCollections } from '../../collection/collection.actions';
import { bindActionCreators } from 'redux';
import { storableSelector } from '../storableDecorator';

const SelectorCollection = ({
  name,
  list,
  fetchCollections,
  isMulti,
  label,
  value,
  isInForm,
  isDisabled,
  isClearable,
  placeholder,
  isFetching,
  onChange,
  valueControl
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    if (isEmpty(list)) {
      fetchCollections();
    }
  }, []);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    const sorted = sortByCaseInsensitive('label')(
      list.map((option) => ({
        label: option.name,
        value: option.id
      }))
    );
    if (!equals(sorted, options || [])) {
      setOptions(sorted);
    }
  }, [list, isFetching]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorCollection.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  list: array.isRequired,
  isFetching: bool.isRequired,
  fetchCollections: func.isRequired
};

SelectorCollection.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null
};

export default compose(
  storableSelector,
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['collection', 'list'], state),
      isFetching: pathOr(emptyArray, ['collection', 'isFetching'], state)
    }),
    (dispatch) => bindActionCreators({ fetchCollections }, dispatch)
  )
)(SelectorCollection);
