import React from 'react';
import SelectorGeoCity from '../selector/Selector.geo.city';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';

const SelectGeoCityForm = ({
  t,
  countryId,
  isClearable,
  input,
  label,
  isDisabled,
  meta: { touched, error },
  isMulti
}) => (
  <InputContainer>
    <SelectorGeoCity
      label={label}
      countryId={countryId}
      {...input}
      isMulti={isMulti}
      isInForm={true}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectGeoCityForm);
