import styled from '@emotion/styled';

export const PhoneContainer = styled('div')(() => ({
  position: 'relative'
}));

export const PhoneInputContainer = styled('div')(({ theme, active }) => ({
  height: '38px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: active ? theme.colors.brown[500] : theme.colors.lightGrey[300],
  boxShadow: active ? `0 0 5px ${theme.colors.brown[400]}` : 'none',
  borderRadius: theme.borderRadius,
  display: 'flex'
}));

export const ButtonSelectCountries = styled('button')(({ theme }) => ({
  outline: 'none',
  height: '100%',
  minWidth: '50px',
  background: 'transparent',
  cursor: 'pointer',
  boxShadow: 'none',
  border: 'none',
  borderRightStyle: 'solid',
  borderRightWidth: '1px',
  borderRightColor: theme.colors.lightGrey[500]
}));

export const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  outline: 'none',
  border: 'none',
  fontFamily: theme.font.family,
  fontWeight: theme.font.weight.md,
  fontSize: theme.font.size.md,
  paddingLeft: theme.spacing.half
}));

export const CountriesContainer = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  background: 'white',
  zIndex: 1000
}));

export const StyledOption = styled('button')(() => ({
  width: '100%',
  outline: 'none',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}));
