export const DEFAULT_VIDEO_SIZE = 396;
export const DEFAULT_SPACING = 20;
export const spacing = {
  none: 0,
  eighth: DEFAULT_SPACING / 8,
  quarter: DEFAULT_SPACING / 4,
  half: DEFAULT_SPACING / 2,
  normal: DEFAULT_SPACING,
  pacman: DEFAULT_SPACING * 0.75,
  sesqui: DEFAULT_SPACING * 1.5,
  double: DEFAULT_SPACING * 2,
  breakpoints: {
    sm: 420,
    md: 768,
    lg: 960,
    xl: 1199
  }
};
