import React, { useEffect, useState } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { compose, isEmpty } from 'ramda';

import Selector from './Selector';
import { translate } from '../../translate/i18n';
import { storableSelector } from '../storableDecorator';

const SelectorInventoryCreator = ({
  t,
  name,
  isMulti,
  label,
  value,
  isInForm,
  isDisabled,
  isClearable,
  placeholder,
  onChange,
  valueControl
}) => {
  const [options] = useState(() => [
    {
      value: 'member',
      label: t('MEMBER')
    },
    {
      value: 'staff',
      label: t('STAFF')
    }
  ]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorInventoryCreator.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  t: func
};

SelectorInventoryCreator.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null
};

export default compose(storableSelector, translate(['inventories', 'default']))(SelectorInventoryCreator);
