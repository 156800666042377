import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import hash from 'object-hash';
import { compose, values, all, reduce, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { capitalize, handleFilters } from '../utils';
import {
  Row,
  Col,
  SelectorTipsCategories,
  Toggle,
  SearchTextInput,
  SelectorGeoCountry,
  SelectorGeoCity,
  SelectorGeoHub,
  collapsible
} from '../components';

const TipFilter = ({ t, onFilterSelect }) => {
  let [hashCode, setHashCode] = useState('');

  let [filters, setFilters] = useState({
    countryId: null,
    cityId: null,
    hubId: null,
    search: '',
    active: true,
    category: []
  });

  let [loaded, setLoaded] = useState({
    countryId: false,
    cityId: false,
    hubId: false,
    search: false,
    category: false,
    active: false
  });

  const handleChange = (filter) => (value) => {
    filters[filter] = value;
    switch (filter) {
      case 'countryId': {
        onLoad('cityId')(false);
        break;
      }
      case 'cityId': {
        onLoad('hubId')(false);
        break;
      }
      default:
        break;
    }

    setFilters({
      ...filters
    });
  };

  const onLoad =
    (filter) =>
    (val = true) => {
      if (loaded[filter] !== val) {
        loaded[filter] = val;
        setLoaded({
          ...loaded
        });
      }
    };

  useEffect(() => {
    if (all((item) => item)(values(loaded))) {
      const newHashCode = hash(filters);
      if (newHashCode !== hashCode) {
        hashCode = newHashCode;
        setHashCode(newHashCode);

        onFilterSelect(reduce((all, filter) => handleFilters(all, filter, filters[filter]), filters, keys(filters)));
      }
    }
  }, [filters, loaded]);

  return (
    <>
      <Row middle mb>
        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SelectorTipsCategories
            name="category"
            label={capitalize(t('CATEGORY'))}
            placeholder={t('default:ALL')}
            isMulti
            value={filters.category}
            onChange={handleChange('category')}
            onLoad={onLoad('category')}
            storeName="Tips"
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SearchTextInput
            label={capitalize(t('default:SEARCH'))}
            name="search"
            placeholder={t('default:SEARCH')}
            value={filters.search}
            onChange={handleChange('search')}
            onLoad={onLoad('search')}
            storeName="Tips"
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb pt>
          <Toggle
            label={t('FORM.SHOW_ACTIVE')}
            input={{ checked: filters.active, onChange: () => null }}
            onCheck={handleChange('active')}
            onLoad={onLoad('active')}
            name="active"
            storeName="Tips"
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SelectorGeoCountry
            name="country"
            label={t('COUNTRY')}
            onChange={handleChange('countryId')}
            onLoad={onLoad('countryId')}
            value={filters.countryId}
            isClearable
            isDisabled={!!filters.cityId}
            placeholder={t('FORM.SELECT_A_COUNTRY')}
            storeName="Tips"
            storeType="const"
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SelectorGeoCity
            name="city"
            label={t('CITY')}
            onChange={handleChange('cityId')}
            onLoad={onLoad('cityId')}
            countryId={filters.countryId}
            ready={loaded.countryId}
            value={filters.cityId}
            isClearable
            isDisabled={!!(!filters.countryId || (filters.hubId && filters.cityId))}
            placeholder={t('FORM.SELECT_A_CITY')}
            storeName="Tips"
            storeType="const"
          />
        </Col>

        <Col xl={4} lg={4} md={4} sm={6} pr-half mb>
          <SelectorGeoHub
            name="hub"
            label={t('HUB')}
            onChange={handleChange('hubId')}
            onLoad={onLoad('hubId')}
            isClearable
            isDisabled={!!(!filters.cityId || (filters.houseId && filters.hubId))}
            cityId={filters.cityId}
            placeholder={t('FORM.SELECT_A_HUB')}
            ready={loaded.cityId}
            value={filters.hubId}
            storeName="Tips"
            storeType="const"
          />
        </Col>
      </Row>
    </>
  );
};

TipFilter.propTypes = {
  onFilterSelect: func,
  t: func
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_FILTER',
    labelShow: 'default:SHOW_FILTER'
  }),
  translate(['cms', 'default'])
)(TipFilter);
