import {
  APPEND_INCIDENTS,
  APPEND_INCIDENTS_SUCCESS,
  APPEND_INCIDENTS_FAIL,
  GET_INCIDENTS,
  GET_INCIDENTS_SUCCESS
} from './incidents.actionTypes';

export const getIncidents = (params = {}) => ({
  type: GET_INCIDENTS,
  payload: params
});

export const setIncidents = (incidents) => ({
  type: GET_INCIDENTS_SUCCESS,
  payload: incidents
});

export const appendIncidents = (params = {}) => ({
  type: APPEND_INCIDENTS,
  payload: params
});

export const appendIncidentsSuccess = (incidents) => ({
  type: APPEND_INCIDENTS_SUCCESS,
  payload: incidents
});

export const appendIncidentsFail = (err) => ({
  type: APPEND_INCIDENTS_FAIL,
  payload: err
});
