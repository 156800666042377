import React from 'react';
import { string, number, oneOfType, bool } from 'prop-types';
import { Text, Container } from '../';
import { upperCase } from '../../utils';
import DateItem from './DateItem';

const DateFormItem = ({ label, date, timezone, showTime }) => {
  return date ? (
    <Container column mb-half>
      <Text grey={400} size="xs" weight={300} pb-eighth labelSpacing>
        {upperCase(label)}
      </Text>
      <DateItem date={date} timezone={timezone} grey={500} showTime={showTime} />
    </Container>
  ) : null;
};

DateFormItem.propTypes = {
  label: string.isRequired,
  date: oneOfType([string, number]).isRequired,
  timezone: string,
  showTime: bool
};

export default DateFormItem;
