/* eslint-disable max-lines */
import { genState } from '../utils/reducer';
import {
  GET_FAQ_SUBCATEGORIES,
  GET_FAQ_SUBCATEGORIES_SUCCESS,
  GET_FAQ_SUBCATEGORIES_FAIL,
  GET_GRADES,
  GET_GRADES_FAIL,
  GET_GRADES_SUCCESS,
  GET_DEFECTS,
  GET_DEFECTS_FAIL,
  GET_DEFECTS_SUCCESS,
  GET_GENDER_LIST,
  GET_GENDER_LIST_SUCESS,
  GET_GENDER_LIST_FAIL,
  GET_PAYMENT_METHOD_LIST,
  GET_PAYMENT_METHOD_LIST_SUCESS,
  GET_PAYMENT_METHOD_LIST_FAIL,
  GET_NATIONALITIES_LIST,
  GET_NATIONALITIES_LIST_SUCESS,
  GET_NATIONALITIES_LIST_FAIL,
  GET_APPLICATION_STATUS,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_APPLICATION_STATUS_FAIL,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  GET_CITIES,
  GET_CITIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_FAIL,
  GET_CURRENCIES_SUCCESS,
  GET_TIPS_CATEGORIES,
  GET_TIPS_CATEGORIES_SUCCESS,
  GET_TIPS_CATEGORIES_FAIL,
  GET_TIPS_PRIORITIES,
  GET_TIPS_PRIORITIES_SUCCESS,
  GET_TIPS_PRIORITIES_FAIL,
  GET_FEEDS_CATEGORIES,
  GET_FEEDS_CATEGORIES_SUCCESS,
  GET_FEEDS_CATEGORIES_FAIL,
  GET_FAQ_CATEGORIES,
  GET_FAQ_CATEGORIES_SUCCESS,
  GET_FAQ_CATEGORIES_FAIL,
  GET_ROOM_CATEGORIES,
  GET_ROOM_CATEGORIES_SUCCESS,
  GET_ROOM_CATEGORIES_FAIL,
  GET_APPLICATION_SWITCH_TYPES,
  GET_APPLICATION_SWITCH_TYPES_SUCCESS,
  GET_APPLICATION_SWITCH_TYPES_FAIL,
  GET_INCIDENT_PRIORITIES,
  GET_INCIDENT_PRIORITIES_SUCCESS,
  GET_INCIDENT_PRIORITIES_FAIL,
  GET_INCIDENT_STATUS,
  GET_INCIDENT_STATUS_SUCCESS,
  GET_INCIDENT_STATUS_FAIL,
  GET_INVENTORY_STATUS,
  GET_INVENTORY_STATUS_SUCCESS,
  GET_INVENTORY_STATUS_FAIL
} from './enum.actions';
import { isEmpty, propOr } from 'ramda';
import { emptyArray, capitalize } from '../utils';

const formatNationalityOption = (geo) => {
  const nationality = capitalize(propOr('', 'nationality', geo));
  const country = capitalize(propOr('', 'country', geo));

  if (isEmpty(nationality)) {
    return country;
  }

  return `${nationality} (${country.replace(/[()]/gi, '')})`;
};

const handleList = (payload) =>
  payload
    .map((option) => ({
      label: formatNationalityOption(option),
      value: option.code,
      prefix: option.prefix,
      flag: option.flag,
      country: option.country
    }))
    .filter((option) => option.label);

const enums = (state) => ({
  [GET_GENDER_LIST]: () => ({
    ...state,
    gender: { ...state.gender, isFetching: true }
  }),
  [GET_GENDER_LIST_SUCESS]: (payload) => ({
    ...state,
    gender: { ...state.gender, isFetching: false, list: payload }
  }),
  [GET_GENDER_LIST_FAIL]: () => ({
    ...state,
    gender: { ...state.gender, isFetching: false, list: emptyArray }
  }),
  [GET_PAYMENT_METHOD_LIST]: () => ({
    ...state,
    paymentMethod: { ...state.paymentMethod, isFetching: true }
  }),
  [GET_PAYMENT_METHOD_LIST_SUCESS]: (payload) => ({
    ...state,
    paymentMethod: { ...state.paymentMethod, isFetching: false, list: payload }
  }),
  [GET_PAYMENT_METHOD_LIST_FAIL]: () => ({
    ...state,
    paymentMethod: { ...state.paymentMethod, isFetching: false, list: emptyArray }
  }),
  [GET_GRADES]: () => ({
    ...state,
    grades: { ...state.grades, isFetching: true }
  }),
  [GET_GRADES_SUCCESS]: (payload) => ({
    ...state,
    grades: { ...state.grades, isFetching: false, list: payload }
  }),
  [GET_GRADES_FAIL]: () => ({
    ...state,
    grades: { ...state.grades, isFetching: false, list: emptyArray }
  }),
  [GET_DEFECTS]: () => ({
    ...state,
    defects: { ...state.defects, isFetching: true }
  }),
  [GET_DEFECTS_SUCCESS]: (payload) => ({
    ...state,
    defects: { ...state.defects, isFetching: false, list: payload }
  }),
  [GET_DEFECTS_FAIL]: () => ({
    ...state,
    defects: { ...state.defects, isFetching: false, list: emptyArray }
  }),
  [GET_NATIONALITIES_LIST]: () => ({
    ...state,
    geo: { isFetching: true, ...state.geo }
  }),
  [GET_NATIONALITIES_LIST_SUCESS]: (payload) => {
    return {
      ...state,
      geo: { ...state.gender, isFetching: false, list: handleList(payload) }
    };
  },
  [GET_NATIONALITIES_LIST_FAIL]: () => ({
    ...state,
    geo: { ...state.gender, isFetching: false, list: emptyArray }
  }),
  [GET_APPLICATION_STATUS]: () => ({
    ...state,
    status: { ...state.status, isFetching: true }
  }),
  [GET_APPLICATION_STATUS_SUCCESS]: (list) => ({
    ...state,
    status: { ...state.status, isFetching: false, list }
  }),
  [GET_APPLICATION_STATUS_FAIL]: () => ({
    ...state,
    status: { ...state.status, isFetching: false }
  }),
  [GET_ROLE]: () => ({
    ...state,
    roles: {
      ...state.roles,
      isFetching: true
    }
  }),
  [GET_ROLE_SUCCESS]: (list) => ({
    ...state,
    roles: {
      list,
      isFetching: false
    }
  }),
  [GET_ROLE_FAIL]: () => ({
    ...state,
    roles: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_CITIES]: () => ({
    ...state,
    cities: {
      ...state.cities,
      isFetching: true
    }
  }),
  [GET_CITIES_SUCCESS]: (list) => ({
    ...state,
    cities: {
      list: propOr(emptyArray, 'rows', list),
      isFetching: false
    }
  }),
  [GET_CITIES_FAIL]: () => ({
    ...state,
    cities: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_CURRENCIES]: () => ({
    ...state,
    currencies: {
      ...state.currencies,
      isFetching: true
    }
  }),
  [GET_CURRENCIES_SUCCESS]: (list) => ({
    ...state,
    currencies: {
      list,
      isFetching: false
    }
  }),
  [GET_CURRENCIES_FAIL]: () => ({
    ...state,
    currencies: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_TIPS_CATEGORIES]: () => ({
    ...state,
    tipsCategories: {
      ...state.tipsCategories,
      isFetching: true
    }
  }),
  [GET_TIPS_CATEGORIES_SUCCESS]: (list) => ({
    ...state,
    tipsCategories: {
      list,
      isFetching: false
    }
  }),
  [GET_TIPS_CATEGORIES_FAIL]: () => ({
    ...state,
    tipsCategories: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_TIPS_PRIORITIES]: () => ({
    ...state,
    tipsPriorities: {
      ...state.tipsPriorities,
      isFetching: true
    }
  }),
  [GET_TIPS_PRIORITIES_SUCCESS]: (list) => ({
    ...state,
    tipsPriorities: {
      list,
      isFetching: false
    }
  }),
  [GET_TIPS_PRIORITIES_FAIL]: () => ({
    ...state,
    tipsPriorities: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_FEEDS_CATEGORIES]: () => ({
    ...state,
    feedsCategories: {
      ...state.feedsCategories,
      isFetching: true
    }
  }),
  [GET_FEEDS_CATEGORIES_SUCCESS]: (list) => ({
    ...state,
    feedsCategories: {
      list,
      isFetching: false
    }
  }),
  [GET_FEEDS_CATEGORIES_FAIL]: () => ({
    ...state,
    feedsCategories: {
      list: emptyArray,
      isFetching: false
    }
  }),

  [GET_FAQ_CATEGORIES]: () => ({
    ...state,
    faqCategories: {
      ...state.faqCategories,
      isFetching: true
    }
  }),
  [GET_FAQ_CATEGORIES_SUCCESS]: (list) => ({
    ...state,
    faqCategories: {
      list,
      isFetching: false
    }
  }),
  [GET_FAQ_CATEGORIES_FAIL]: () => ({
    ...state,
    faqCategories: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_INCIDENT_PRIORITIES]: () => ({
    ...state,
    incidentPriorities: {
      ...state.incidentPriorities,
      isFetching: true
    }
  }),
  [GET_INCIDENT_PRIORITIES_SUCCESS]: (list) => ({
    ...state,
    incidentPriorities: {
      list,
      isFetching: false
    }
  }),
  [GET_FAQ_SUBCATEGORIES]: () => ({
    ...state,
    faqSubcategories: {
      ...state.faqSubcategories,
      isFetching: true
    }
  }),
  [GET_FAQ_SUBCATEGORIES_SUCCESS]: (list) => ({
    ...state,
    faqSubcategories: {
      list,
      isFetching: false
    }
  }),
  [GET_INCIDENT_PRIORITIES_FAIL]: () => ({
    ...state,
    incidentPriorities: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_INCIDENT_STATUS]: () => ({
    ...state,
    incidentStatus: {
      ...state.incidentStatus,
      isFetching: true
    }
  }),
  [GET_INCIDENT_STATUS_SUCCESS]: (list) => ({
    ...state,
    incidentStatus: {
      list,
      isFetching: false
    }
  }),
  [GET_INCIDENT_STATUS_FAIL]: () => ({
    ...state,
    incidentStatus: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_INVENTORY_STATUS]: () => ({
    ...state,
    inventoryStatus: {
      ...state.inventoryStatus,
      isFetching: true
    }
  }),
  [GET_INVENTORY_STATUS_SUCCESS]: (list) => ({
    ...state,
    inventoryStatus: {
      list,
      isFetching: false
    }
  }),
  [GET_INVENTORY_STATUS_FAIL]: () => ({
    ...state,
    inventoryStatus: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_FAQ_SUBCATEGORIES_FAIL]: () => ({
    ...state,
    faqSubcategories: {
      list: [],
      isFetching: false
    }
  }),

  [GET_ROOM_CATEGORIES]: () => ({
    ...state,
    roomCategories: {
      ...state.roomCategories,
      isFetching: true
    }
  }),
  [GET_ROOM_CATEGORIES_SUCCESS]: (list) => ({
    ...state,
    roomCategories: {
      list,
      isFetching: false
    }
  }),
  [GET_ROOM_CATEGORIES_FAIL]: () => ({
    ...state,
    roomCategories: {
      list: emptyArray,
      isFetching: false
    }
  }),
  [GET_APPLICATION_SWITCH_TYPES]: () => ({
    ...state,
    applicationSwitchTypes: {
      ...state.applicationSwitchTypes,
      isFetching: true
    }
  }),
  [GET_APPLICATION_SWITCH_TYPES_SUCCESS]: (list) => ({
    ...state,
    applicationSwitchTypes: {
      list,
      isFetching: false
    }
  }),
  [GET_APPLICATION_SWITCH_TYPES_FAIL]: () => ({
    ...state,
    applicationSwitchTypes: {
      list: emptyArray,
      isFetching: false
    }
  })
});

const initEnums = {
  gender: {
    isFetching: true,
    list: emptyArray
  },
  paymentMethod: {
    isFetching: true,
    list: emptyArray
  },
  geo: {
    isFetching: true,
    list: emptyArray
  },
  status: {
    isFetching: false,
    list: emptyArray
  },
  roles: {
    isFetching: false,
    list: emptyArray
  },
  currencies: {
    isFetching: false,
    list: emptyArray
  },
  tipsCategories: {
    isFetching: false,
    list: emptyArray
  },
  tipsPriorities: {
    isFetching: false,
    list: emptyArray
  },
  incidentPriorities: {
    isFetching: false,
    list: emptyArray
  },
  inventoryStatus: {
    isFetching: false,
    list: emptyArray
  },
  incidentStatus: {
    isFetching: false,
    list: emptyArray
  },
  feedsCategories: {
    isFetching: false,
    list: emptyArray
  },
  faqsCategories: {
    isFetching: false,
    list: emptyArray
  },
  faqsSubcategories: {
    isFetching: false,
    list: []
  },
  roomCategories: {
    isFetching: false,
    list: emptyArray
  }
};

export const enumReducer = (state = initEnums, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: enums, type });
};
