import React, { useEffect, useState } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, equals } from 'ramda';

import { emptyArray, makeOptions } from '../../utils';
import { getHouseUnits } from '../../houses/houses.actions';
import Selector from './Selector';

const SelectorUnit = ({
  houseId,
  name,
  list,
  getHouseUnits,
  isMulti,
  label,
  value,
  isInForm,
  isFetching,
  isDisabled,
  placeholder,
  isClearable,
  addEmpty,
  onChange
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    houseId && getHouseUnits(houseId);
  }, [houseId]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    const sorted = [
      ...(addEmpty
        ? [
            {
              label: '--',
              value: 'none'
            }
          ]
        : []),
      ...makeOptions(list, 'id', 'name', 'label')
    ];
    if (!equals(sorted, options || [])) {
      setOptions(sorted);
    }
  }, [list, isFetching]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorUnit.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,

  list: array.isRequired,
  isFetching: bool.isRequired,
  getHouseUnits: func.isRequired,
  addEmpty: bool,
  houseId: oneOfType([string, array])
};

SelectorUnit.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  addEmpty: false,
  placeholder: '',
  houseId: [],
  value: null
};

export default connect(
  (state) => ({
    list: pathOr(emptyArray, ['houses', 'current', 'data', 'units'], state),
    isFetching: pathOr(true, ['houses', 'current', 'isFetching'], state)
  }),
  { getHouseUnits }
)(SelectorUnit);
