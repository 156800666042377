import {
  GET_INCIDENTS,
  GET_INCIDENTS_SUCCESS,
  APPEND_INCIDENTS,
  APPEND_INCIDENTS_FAIL,
  APPEND_INCIDENTS_SUCCESS
} from './incidents.actionTypes';
import { genState, setFetching, setList, appendList, appendListFailure } from '../utils/reducer';

const incidents = (state) => ({
  [GET_INCIDENTS]: setFetching(state, true),
  [GET_INCIDENTS_SUCCESS]: setList(state, -1),
  [APPEND_INCIDENTS]: setFetching(state, true),
  [APPEND_INCIDENTS_SUCCESS]: appendList(state, -1),
  [APPEND_INCIDENTS_FAIL]: appendListFailure(state)
});

export const incidentsReducer = (state = null, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: incidents, type });
};
