import {
  SET_CHAT_FILTER,
  TOGGLE_CREATE_CHAT_CHANNEL,
  SET_STREAM_CHAT_CHANNEL,
  GET_MEMBERS_FOR_CHAT,
  GET_MEMBERS_FOR_CHAT_SUCCESS,
  GET_MEMBERS_FOR_CHAT_FAIL,
  APPEND_MEMBERS_FOR_CHAT,
  APPEND_MEMBERS_FOR_CHAT_SUCCESS,
  APPEND_MEMBERS_FOR_CHAT_FAIL,
  STREAM_CHAT_AUTH,
  STREAM_CHAT_LOGOUT,
  STREAM_CHAT_CONNECTED,
  STREAM_CHAT_DISCONNECTED,
  STREAM_CHAT_UNREAD_COUNT,
  STREAM_CHAT_TOGGLE_MINI
} from './chat.actionTypes';

export const streamChatAuth = (user) => ({
  type: STREAM_CHAT_AUTH,
  user
});

export const streamChatLogout = () => ({
  type: STREAM_CHAT_LOGOUT
});

export const setStreamConnected = () => ({
  type: STREAM_CHAT_CONNECTED
});

export const setStreamDisconnected = () => ({
  type: STREAM_CHAT_DISCONNECTED
});

export const setStreamUnreadCount = (payload) => ({
  type: STREAM_CHAT_UNREAD_COUNT,
  payload
});

export const toggleStreamMini = (payload) => ({
  type: STREAM_CHAT_TOGGLE_MINI,
  payload
});

export const getMembers = (params = {}) => ({
  type: GET_MEMBERS_FOR_CHAT,
  payload: params
});

export const getMembersSuccess = (members) => ({
  type: GET_MEMBERS_FOR_CHAT_SUCCESS,
  payload: members
});

export const getMembersFail = (err) => ({
  type: GET_MEMBERS_FOR_CHAT_FAIL,
  payload: err
});

export const appendMembers = (params = {}) => ({
  type: APPEND_MEMBERS_FOR_CHAT,
  payload: params
});

export const appendMembersSuccess = (members) => ({
  type: APPEND_MEMBERS_FOR_CHAT_SUCCESS,
  payload: members
});

export const appendMembersFail = (err) => ({
  type: APPEND_MEMBERS_FOR_CHAT_FAIL,
  payload: err
});

export const setCurrentChannel = (payload) => ({
  type: SET_STREAM_CHAT_CHANNEL,
  payload
});

export const setChatFilter = (payload) => ({
  type: SET_CHAT_FILTER,
  payload
});

export const toggleCreateChatChannel = (payload) => ({
  type: TOGGLE_CREATE_CHAT_CHANNEL,
  payload
});
