import React from 'react';
import SelectorLockCategory from '../selector/Selector.lockCategory';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';

const SelectorLockCategoryForm = ({ t, input, label, isDisabled, meta: { touched, error }, placeholder }) => (
  <InputContainer>
    <SelectorLockCategory label={label} {...input} isInForm={true} isDisabled={isDisabled} placeholder={placeholder} />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectorLockCategoryForm);
