import React, { useEffect, useState } from 'react';
import Selector from './Selector';
import { func, string, array, bool, oneOfType, number } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, isEmpty, compose } from 'ramda';
import { emptyArray, makeOptions } from '../../utils';
import { getCities } from '../../enum/enum.actions';

const SelectorCity = (props) => {
  const { getCities, list, name, onChange, isMulti, label, value, isInForm, isDisabled, placeholder } = props;

  const [options, setOptions] = useState([]);

  const handleChange = (val) => {
    if (isEmpty(val)) return onChange([]);
    return onChange(val);
  };

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    setOptions(makeOptions(list, 'id', 'name', 'label'));
  }, [list]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

SelectorCity.propTypes = {
  isInForm: bool,
  name: string.isRequired,
  list: array,
  getCities: func,
  onChange: func,
  isMulti: bool,
  label: string,
  value: oneOfType([string, array, number]),
  isDisabled: bool,
  placeholder: string,
  isFetching: bool,
  t: func
};

SelectorCity.defaultValues = {
  isInForm: false
};

export default compose(
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'cities', 'list'], state),
      isFetching: pathOr(false, ['enums', 'cities', 'isFetching'], state)
    }),
    { getCities }
  )
)(SelectorCity);
