import React, { useState } from 'react';
import { func } from 'prop-types';
import { reduxForm, propTypes as reduxFormPropTypes, Field } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import StyledForm from '../components/form.styles';
import { required } from '../utils';
import { TextInput, Button, Row, Col, ConfirmModal, Container, Text } from '../components';
import { connect } from 'react-redux';
import { updateHouseArea, addHouseArea, removeHouseArea } from './houses.actions';

const ExtraCommonArea = ({
  t,
  submitSucceeded,
  onClose,
  handleSubmit,
  submitting,
  initialValues,
  removeHouseArea,
  updateHouseArea,
  addHouseArea
}) => {
  const [confirmDelete, setConfirmDelete] = useState(() => false);
  if (submitSucceeded && !initialValues.id) {
    onClose();
  }

  return (
    <StyledForm onSubmit={handleSubmit(initialValues.id ? updateHouseArea : addHouseArea)}>
      <Row>
        <Col xl={8} lg={8} md={8} sm={12} pr-half pl-half>
          <Field name="name" component={TextInput} type="text" placeholder={t('NEW_AREA')} validate={required} />
        </Col>

        <Col xl={4} lg={4} md={4} sm={12} pr-half pl-half>
          <Row right>
            <Button
              name="submit"
              disabled={submitting}
              clear
              size="md"
              onClick={() => {
                if (initialValues.id) {
                  setConfirmDelete(true);
                } else {
                  onClose();
                }
              }}>
              {initialValues.id ? t('form:DELETE') : t('form:CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('form:SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </Col>
      </Row>

      <ConfirmModal
        title={t('WARNING')}
        show={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => {
          removeHouseArea(initialValues);
        }}
        content={() => (
          <Container column>
            <Text>{t('DELETE_AREA')}</Text>
          </Container>
        )}
      />
    </StyledForm>
  );
};

ExtraCommonArea.propTypes = {
  t: func,
  removeHouseArea: func,
  updateHouseArea: func,
  addHouseArea: func,
  onClose: func,
  ...reduxFormPropTypes
};

ExtraCommonArea.defaultProps = {
  onClose: () => {}
};

export default compose(
  reduxForm({}),
  translate('house'),
  connect(null, { removeHouseArea, updateHouseArea, addHouseArea })
)(ExtraCommonArea);
