import React from 'react';
import styled from '@emotion/styled';
import { oneOfType, string, array, object, bool } from 'prop-types';

const StyledCard = styled('div')(({ theme }) => ({
  borderRadius: theme.borderRadius
}));

const StyledCardBody = styled('div')(({ theme }) => ({
  padding: theme.spacing.normal
}));

const StyledCardTitle = styled('p')(({ theme, center }) => ({
  marginBottom: theme.spacing.quarter,
  textAlign: center ? 'center' : 'left',
  fontSize: theme.font.size.lg,
  color: theme.colors.grey[500],
  fontFamily: theme.font.family,
  fontWeight: 200
}));

const Card = ({ title, textCenter, children }) => (
  <StyledCard>
    {title ? (
      <StyledCardTitle center={textCenter} weight={100}>
        {title}
      </StyledCardTitle>
    ) : null}
    <StyledCardBody>{children}</StyledCardBody>
  </StyledCard>
);
Card.propTypes = {
  children: oneOfType([string, array, object]),
  title: string,
  textCenter: bool
};

Card.defaultProps = {
  title: '',
  textCenter: false
};

export default Card;
