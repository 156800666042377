import {
  GET_PAYMENT_PROFILE_METHODS_REQUEST,
  GET_PAYMENT_PROFILE_METHODS_SUCCESS,
  SET_PAYMENT_PROFILE_METHODS,
  UPDATE_PAYMENT_PROFILE_METHODS,
  DELETE_PAYMENT_PROFILE_METHODS
} from './paymentProfileMethod.actionTypes';
import { createFormAction } from 'redux-form-saga';

export const fetchPaymentProfileMethodRequest = ({ hubId, userId, profileId }) => ({
  type: GET_PAYMENT_PROFILE_METHODS_REQUEST,
  payload: { hubId, userId, profileId }
});

export const fetchPaymentProfileMethodSuccess = ({ paymentMethods, profileId }) => ({
  type: GET_PAYMENT_PROFILE_METHODS_SUCCESS,
  payload: { paymentMethods, profileId }
});

export const createPaymentProfileMethod = createFormAction(SET_PAYMENT_PROFILE_METHODS);
export const updatePaymentProfileMethod = createFormAction(UPDATE_PAYMENT_PROFILE_METHODS);
export const deletePaymentProfileMethod = createFormAction(DELETE_PAYMENT_PROFILE_METHODS);
