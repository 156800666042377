import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { func, string } from 'prop-types';
import { reduxForm, formValues, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose, propOr } from 'ramda';
import { translate } from '../translate/i18n';
import { BottomContainer, Button, Row } from '../components';
import { isEmptyOrNil } from '../utils';
import { upsertInventory } from './inventories.actions';
import StyledForm from '../components/form.styles';
import ChecklistSection, { checklist } from './inventory.form.checklist';
import Issues from './issues/inventory.form.issues';
import HouseRoomSelection from './inventory.form.houseRoomSelection';
import issueValidate from './issues/issue.validate';

const InventoryForm = ({ t, onCancel, submitSucceeded, handleSubmit, submitting, roomId, form }) => {
  if (submitSucceeded) {
    return <Redirect to="/inventories" />;
  }

  return (
    <StyledForm onSubmit={handleSubmit(upsertInventory)}>
      <HouseRoomSelection roomRequired />

      {!isEmptyOrNil(roomId) && (
        <Fragment>
          <ChecklistSection formName={form} />
          <Issues formName={form} />

          <BottomContainer negativeMargin={true}>
            <Row right>
              <Button name="cancel" disabled={submitting} onClick={onCancel} clear size="md">
                {t('form:CANCEL')}
              </Button>
              <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
                {t('form:SAVE')} {submitting ? '...' : ''}
              </Button>
            </Row>
          </BottomContainer>
        </Fragment>
      )}
    </StyledForm>
  );
};

const validate = (values) => ({
  issues: issueValidate(propOr([], 'issues', values))
});

InventoryForm.propTypes = {
  t: func,
  onCancel: func,
  roomId: string,
  ...reduxFormPropTypes
};

export default compose(
  translate('inventories'),
  reduxForm({
    form: 'InventoryCreateForm',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: true,
    initialValues: {
      roomId: null,
      checklist: checklist.map((check) => ({
        [check.key]: false
      })),
      issues: []
    }
  }),
  formValues({ roomId: 'roomId' })
)(InventoryForm);
