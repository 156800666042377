import { isEmptyOrNil, emptyArray, isNotEmptyOrNil } from '../utils';
import { getMembers } from '../members/member.service';

let debounce;
export const formatMember = (member) => {
  if (isNotEmptyOrNil(member)) {
    return { label: `${member.firstName} ${member.lastName}`, value: member };
  }

  return undefined;
};

export const loadMemberOptions = (criteria) => (input) =>
  new Promise((resolve) => {
    if (isEmptyOrNil(input)) {
      resolve(emptyArray);
    } else {
      if (debounce) {
        clearTimeout(debounce);
      }
      debounce = setTimeout(() => {
        if (criteria.countryId || criteria.cityId || criteria.hubId || criteria.houseId) {
          getMembers({ page: 1, limit: 50, criteria: { role: 'user', search: input, ...criteria } }).then((members) => {
            if (members.rows) {
              resolve(
                members.rows.map((member) => ({ label: `${member.firstName} ${member.lastName}`, value: member }))
              );
            } else {
              resolve(emptyArray);
            }
          });
        } else {
          resolve(emptyArray);
        }
      }, 800);
    }
  });
