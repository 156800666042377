import React from 'react';
import Modal from './Modal';
import { translate } from '../../translate/i18n';
import { func } from 'prop-types';

const RoomCreationModal = (props) => {
  const { content } = props;
  return <Modal {...props} content={() => content()} />;
};

RoomCreationModal.propTypes = { t: func };

RoomCreationModal.defaultProps = {
  show: true
};

export default translate('default')(RoomCreationModal);
