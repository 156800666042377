import React, { useState, useEffect, Fragment } from 'react';
import { func, object, array } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, prop, pathOr, difference, pluck } from 'ramda';
import { Table, Block, Row, Text, Modal, ActionButton, ActionSecondaryButton } from '../components';
import { translate } from '../translate/i18n';
import { emptyObject, emptyArray, upperCase } from '../utils';
import { initValuesWithRecurrences } from './houses.utils';
import { deleteServices, getHouseServices } from './houses.actions';
import ServiceForm from './House.service.form';
import { getServices } from '../enum/enum.service';

const getColumnDefs = (t) => [
  {
    headerName: '',
    field: '',
    width: 5,

    headerComponentFramework: ({ api, column }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            isChecked ? api.deselectAll() : api.selectAll();
            setIsChecked(!isChecked);
            api.refreshCells({ columns: [column], force: true });
          }}
        />
      );
    },
    onCellClicked: () => null,

    cellRendererFramework: ({ node, api }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(node.isSelected());
      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!node.isSelected());
            node.setSelected(!node.isSelected());
            api.refreshHeader();
          }}
        />
      );
    }
  },
  {
    headerName: t('NAME'),
    field: 'name',
    width: 50,
    valueFormatter: (col) => (col.value ? t(`SERVICE_TYPES.${upperCase(col.value)}`) : '--')
  }
];

const Services = (props) => {
  // Props
  const { t, house, deleteServices, getHouseServices } = props;

  // State
  const [showModal, toggleModal] = useState(false);
  const [selectedService, selectService] = useState(emptyObject);
  const [selectedServices, selectServices] = useState([]);
  const [enumServices, setEnumServices] = useState([]);
  const [houseServices, setHouseServices] = useState([]);

  // Vars
  const possibleServices = difference(enumServices, houseServices);

  // Functions
  const closeModal = () => {
    toggleModal(false);
  };
  const openCreateModal = () => {
    selectService(emptyObject);
    toggleModal(true);
  };
  const deleteSelection = () => {
    deleteServices(selectedServices);
    selectServices(emptyArray);
  };
  const onRowSelected = (event) => selectServices(event.api.getSelectedRows());
  const onRowClicked = (event) => {
    selectService(prop('data', event));
    toggleModal(true);
  };

  // Hooks
  useEffect(() => {
    if (house.id) {
      getHouseServices(house.id);
      getServices().then((services) => setEnumServices(services));
    }
  }, []);

  useEffect(() => {
    if (house.services) {
      setHouseServices(pluck('name')(house.services));
    }
  }, [house.services]);

  // Render
  const renderAddButton = () =>
    !selectedServices.length && possibleServices.length ? (
      <ActionButton name="add-service" icon="Plus" onClick={openCreateModal} />
    ) : null;

  const renderDeleteButton = () =>
    selectedServices.length ? (
      <ActionSecondaryButton name="removeService" icon="Trash2" onClick={deleteSelection} danger />
    ) : null;

  return (
    <Fragment>
      <Block p>
        <Row mb>
          <Text size="lg" mr-half>
            {t('SERVICES')}
          </Text>

          {renderAddButton()}
          {renderDeleteButton()}
        </Row>

        <Row>
          <Table
            data={{
              columnDefs: getColumnDefs(t),
              rowData: house.services,
              defaultColDef: { onCellClicked: onRowClicked }
            }}
            onSelectionChanged={onRowSelected}
          />
        </Row>
      </Block>

      <Modal
        show={showModal}
        title={prop('id', selectedService) ? t('EDIT_SERVICE') : t('ADD_SERVICE')}
        onClose={closeModal}
        content={() => (
          <ServiceForm
            initialValues={initValuesWithRecurrences(selectedService, house)}
            possibleServices={possibleServices}
            onClose={closeModal}
          />
        )}
      />
    </Fragment>
  );
};

Services.propTypes = {
  house: object,
  services: array,
  getHouseService: func,
  deleteServices: func,
  getHouseServices: func,
  t: func
};

export default compose(
  withRouter,
  translate('house'),
  connect(
    (state) => ({
      house: pathOr(emptyObject, ['houses', 'current', 'data'], state)
    }),
    { deleteServices, getHouseServices }
  )
)(Services);
