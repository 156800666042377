import { genState } from '../utils/reducer';
import { GET_HUB_PAYMENT_GATEWAYS_REQUEST, GET_HUB_PAYMENT_GATEWAYS_SUCCESS } from './paymentGateway.actionTypes';
import { emptyArray } from '../utils';

const hubPaymentMethodDef = (state) => ({
  [GET_HUB_PAYMENT_GATEWAYS_REQUEST]: () => ({
    ...state,
    list: emptyArray,
    isFetching: true,
    isResult: false
  }),
  [GET_HUB_PAYMENT_GATEWAYS_SUCCESS]: ({ list }) => ({
    ...state,
    list,
    isFetching: false,
    isResult: true
  })
});

export const paymentGatewayReducer = (
  state = {
    list: emptyArray,
    isFetching: false,
    isResult: false
  },
  action
) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: hubPaymentMethodDef, type });
};
