import React, { Fragment, useState } from 'react';
import { func, object, number, bool, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { pathOr, prop, path } from 'ramda';
import { translate } from '../translate/i18n';
import { getPayments, appendPayments } from './payments.actions';
import { capitalize, valueOrPlaceholder, isNotEmptyOrNil, emptyArray, formatDatetime, formatDate } from '../utils';
import { Row, Text, ScrollableDataTableWithColSelector, Modal } from '../components';
import PaymentDetailsForm from './Payment.details.form';

const getColumnDefs = (t) => [
  {
    headerName: t('HOUSE'),
    defaultActive: true,
    field: 'lease.roomId',
    cellClassRules: {
      'cell-span': 'true'
    },
    rowSpan: (params) => (params.data.count ? params.data.count : 1),
    valueFormatter: ({ data }) => pathOr('', ['lease', 'room', 'house', 'name'], data)
  },
  {
    headerName: t('ROOM'),
    defaultActive: true,
    field: 'lease.roomId',
    cellClassRules: {
      'cell-span': 'true'
    },
    rowSpan: (params) => (params.data.count ? params.data.count : 1),
    valueFormatter: ({ data }) => pathOr('', ['lease', 'room', 'number'], data)
  },
  {
    headerName: t('PACK_NAME'),
    defaultActive: true,
    field: 'pack.name'
  },
  {
    headerName: t('DUE_DATE'),
    field: 'payment.dueDate',
    valueFormatter: ({ value, data }) => valueOrPlaceholder(isNotEmptyOrNil, formatDate)(value, data.payment && 'UTC'),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.payment && 'UTC', '')(value)
  },
  {
    headerName: t('EXPECTED_AMOUNT'),
    field: 'payment.amount',
    valueFormatter: ({ data, value }) => `${pathOr('', ['payment', 'hub', 'currency', 'symbol'], data)} ${value}`
  },
  {
    headerName: t('RECEIVED_AMOUNT'),
    defaultActive: true,
    field: 'payment.paid',
    valueFormatter: ({ data, value }) => {
      if (pathOr(false, ['payment', 'pending'], data)) {
        return t('PENDING');
      }
      if (pathOr(false, ['payment', 'disputed'], data)) {
        return t('DISPUTED');
      }
      return `${pathOr('', ['payment', 'hub', 'currency', 'symbol'], data)} ${value}`;
    }
  },
  {
    headerName: t('PAYMENT_DATE'),
    field: 'payment.paymentDate',
    valueFormatter: ({ data, value }) =>
      valueOrPlaceholder(isNotEmptyOrNil, formatDatetime)(value, data.payment && 'UTC'),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.payment && 'UTC', '')(value)
  }
];

const Packs = (props) => {
  // Props
  const { t, payments, paymentCount, isFetching, appendPayments, getPayments, responseLength, member, hub } = props;

  // State
  const [selectedPayment, selectPayment] = useState(null);
  const [showModal, toggleModal] = useState(false);

  // Functions
  const showDetailedPayment = (event) => {
    selectPayment(prop('data', event));
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
    selectPayment(null);
  };

  const countRowSpan = (payments) => {
    const mask = {};
    payments.map((item) => {
      const roomId = path(['lease', 'roomId'], item);
      if (!mask[roomId]) {
        mask[roomId] = item;
        mask[roomId].count = 1;
        return item;
      }

      mask[roomId].count += 1;
      return item;
    });

    return payments;
  };

  // Render
  return (
    <Fragment>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {capitalize(t('PACKS_HISTORY'))}
        </Text>
      </Row>

      <ScrollableDataTableWithColSelector
        columnDefs={getColumnDefs(t)}
        defaultColDef={{
          valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
          sortable: false
        }}
        getMethod={(params) => getPayments({ ...params, userId: member.id, hubId: hub.id, paymentType: 'packs' })}
        appendMethod={(params) => appendPayments({ ...params, userId: member.id, hubId: hub.id, paymentType: 'packs' })}
        rows={countRowSpan(payments)}
        rowCount={paymentCount}
        responseLength={responseLength}
        isFetching={isFetching}
        onCellClicked={({ data, colDef: { field } }) => {
          if (field !== 'lease.roomId') {
            showDetailedPayment({ data });
          }
        }}
      />

      <Modal
        title={t('PAYMENT_DETAILS')}
        show={showModal}
        onClose={closeModal}
        content={() => (
          <PaymentDetailsForm
            initialValues={{
              comment: path(['payment', 'comment'], selectedPayment),
              id: path(['payment', 'id'], selectedPayment),
              paymentType: 'packs'
            }}
            onCancel={closeModal}
            payment={selectedPayment}
          />
        )}
      />
    </Fragment>
  );
};

Packs.propTypes = {
  history: object,
  t: func,
  payments: array,
  paymentCount: number,
  isFetching: bool,
  appendPayments: func,
  getPayments: func,
  responseLength: number,
  member: object,
  hub: object
};

export default compose(
  translate(['payments']),
  connect(
    (state) => ({
      payments: pathOr(emptyArray, ['payments', 'list'], state),
      paymentCount: pathOr(0, ['payments', 'count'], state),
      responseLength: pathOr(0, ['payments', 'responseLength'], state),
      isFetching: pathOr(false, ['payments', 'isFetching'], state)
    }),
    { getPayments, appendPayments }
  )
)(Packs);
