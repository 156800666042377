import React from 'react';
import { func, bool, object } from 'prop-types';
import { Field, FormSection } from 'redux-form';
import { isEmpty } from 'ramda';
import { Row, Col, TextInput, SelectLockCategoryForm } from '../components';
import { translate } from '../translate/i18n';
import { isNotEmptyOrNil, required } from '../utils';

const LockFormSection = (props) => {
  const { t, lock, forHouse } = props;

  const isNew = isEmpty(lock);

  return (
    <FormSection name="lock">
      <Row>
        <Col xl={6} lg={6} md={6} sm={12} pr-half pl-half>
          <Field
            name="name"
            label={t('form:NAME')}
            component={TextInput}
            placeholder={t('form:NAME')}
            validate={required}
          />
        </Col>

        {forHouse && (
          <Col xl={6} lg={6} md={6} sm={12} pr-half pl-half>
            <Field
              name="category"
              label={t('lock:CATEGORY')}
              component={SelectLockCategoryForm}
              placeholder={t('SELECT_LOCK_CATEGORY')}
              validate={required}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col xl={6} lg={6} md={6} sm={12} pr-half pl-half>
          <Field
            name="iqSecret"
            component={TextInput}
            type="text"
            label={`${t('IQ_SECRET')}`}
            placeholder={!!lock && isNotEmptyOrNil(lock.iqSecret) ? '*'.repeat(16) : t('IQ_SECRET')}
            validate={[...(isNew ? [required] : [])]}
          />
        </Col>

        <Col xl={6} lg={6} md={6} sm={12} pr-half pl-half>
          <Field
            name="saltoId"
            component={TextInput}
            type="text"
            label={`${t('SALTO_ID')}`}
            placeholder={t('SALTO_ID')}
            validate={required}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={6} lg={6} md={6} sm={12} pr-half pl-half>
          <Field
            name="pin"
            component={TextInput}
            type="number"
            label={`${t('PIN')}`}
            placeholder={!!lock && isNotEmptyOrNil(lock.pin) ? '*'.repeat(4) : t('PIN')}
            validate={[...(isNew ? [required] : [])]}
          />
        </Col>

        <Col xl={6} lg={6} md={6} sm={12} pr-half pl-half>
          <Field
            name="siteId"
            component={TextInput}
            type="text"
            label={`${t('SITE_ID')}`}
            placeholder={t('SITE_ID')}
            validate={required}
          />
        </Col>
      </Row>
    </FormSection>
  );
};

LockFormSection.propTypes = {
  t: func,
  isNew: bool,
  forHouse: bool,
  lock: object
};

export default translate('lock')(LockFormSection);
