import React from 'react';
import { Chat, Channel, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
import { compose, pathOr, propOr } from 'ramda';
import { connect } from 'react-redux';
import './chat.css';

import { spacing } from '../theme/spacing';
import streamChat from './stream';
import { show, sizing } from '../components';

import './style';
import { uploadFile, uploadVideoFile } from '../service';
import ChannelHeader from './chatComponents/ChannelHeader';
import ChannelList from './chatComponents/channelList';
import Message from './chatComponents/Message';
import { bool, number } from 'prop-types';

const theme = 'messaging white';

const sendFile = async (file) => {
  if (file.type.match(/^video\//) || file.type === 'application/x-mpegURL') {
    return uploadVideoFile(file);
  }

  return { file: await uploadFile(file) };
};

const ChatWrap = ({ windowWidth, maximised }) => (
  <Chat client={streamChat.client} theme={theme} initialNavOpen={false}>
    <div className="chat-scale">
      <ChannelList />
      <Channel Message={Message}>
        <Window>
          {(windowWidth <= spacing.breakpoints.lg || !maximised) && <ChannelHeader />}
          <MessageList />
          <MessageInput
            doFileUploadRequest={(file) => sendFile(file)}
            doImageUploadRequest={(file) => sendFile(file)}
          />
        </Window>
        <Thread />
      </Channel>
    </div>
  </Chat>
);

ChatWrap.propTypes = {
  windowWidth: number.isRequired,
  maximised: bool.isRequired
};

ChatWrap.defaultProps = {
  maximised: true
};

export default compose(
  sizing(),
  connect((state) => ({
    isLogged: pathOr(false, ['user', 'isLogged'], state),
    connectStatus: pathOr(null, ['chat', 'connectStatus'], state)
  })),
  show((props) => propOr(false, 'isLogged', props) && pathOr(false, ['connectStatus'], props))
)(ChatWrap);
