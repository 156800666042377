import React, { PureComponent, Fragment } from 'react';
import { func, object, bool, number } from 'prop-types';
import { withTheme } from '@emotion/react';
import { compose, pathOr, path, propOr } from 'ramda';
import { connect } from 'react-redux';
import { translate } from '../translate/i18n';
import { emptyObject, emptyArray } from '../utils';
import { getMember, resetCurrentMember, updateMemberAccess } from './member.actions';
import { resetMemberPin } from './member.service';
import { Row, Loader, Col, Back, Button, ConfirmModal, Text, sizing } from '../components';
import Profile from './Member.profile';
import Leases from './Member.leases';
import Incidents from './Member.incidents';
import StayInfo from './Member.stayInfo';
import Packs from './Member.packs';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import { types } from '../utils/sagas';
import { spacing } from '../theme/spacing';

class Member extends PureComponent {
  static propTypes = {
    getMember: func,
    member: object,
    match: object,
    t: func,
    theme: object,
    resetCurrentMember: func,
    updateMemberAccess: func,
    handleErrorMessage: func,
    handleSuccessMessage: func,
    isFetching: bool,
    windowWidth: number,
    isUpdating: bool
  };

  constructor(props) {
    super(props);

    this.state = {
      showConfirmResetPin: false
    };

    this.openResetPinModal = this.openResetPinModal.bind(this);
    this.closeResetPinModal = this.closeResetPinModal.bind(this);
    this.sendNewPin = this.sendNewPin.bind(this);
  }

  componentDidMount() {
    const { match, getMember } = this.props;

    const id = path(['params', 'id'], match);

    getMember(id);
  }

  componentWillUnmount() {
    this.props.resetCurrentMember();
  }

  openResetPinModal() {
    this.setState({ showConfirmResetPin: true });
  }

  closeResetPinModal() {
    this.setState({ showConfirmResetPin: false });
  }

  sendNewPin() {
    const { member, handleErrorMessage, handleSuccessMessage } = this.props;

    resetMemberPin(member)
      .then(() => {
        handleSuccessMessage('RESET.SUCCESS', { type: types.PIN });
      })
      .catch((err) => {
        handleErrorMessage('RESET.FAILURE', { type: types.PIN, error: err, data: member });
      });

    this.closeResetPinModal();
  }

  render() {
    const { member, isFetching, isUpdating, t, updateMemberAccess, windowWidth } = this.props;
    const { showConfirmResetPin } = this.state;

    if (isFetching || !member.id) return <Loader />;

    const colView = windowWidth > spacing.breakpoints.lg;

    const toggleAccess = () => {
      updateMemberAccess({ id: member.id, banned: !member.banned });
    };

    return (
      <Fragment>
        <Row>
          <Col xl={9} lg={8} md={12} sm={12} p style={colView ? { height: '100vh', overflow: 'auto' } : {}}>
            <Row middle>
              <Col xl={5} lg={5} md={5} sm={4}>
                <Back to="/members" mt-half />
              </Col>

              <Col xl={7} lg={7} md={7} sm={8}>
                <Row right>
                  <Button bg-secondary size="md" name="resetPin" onClick={this.openResetPinModal} mr-half mt-half>
                    {t('RESET_PIN')}
                  </Button>

                  <Button
                    bg-secondary
                    size="md"
                    name="revokeAccess"
                    onClick={toggleAccess}
                    disabled={isUpdating}
                    mt-half>
                    {member.banned ? t('GRANT_ACCESS') : t('REVOKE_ACCESS')}
                  </Button>
                </Row>
              </Col>
            </Row>

            <StayInfo memberId={member.id} />

            <Row>
              <Col xl={12} lg={12} md={12} sm={12} pr-half pl-half>
                <Leases memberId={member.id} />
              </Col>

              <Col xl={6} lg={12} md={12} sm={12} pr-half pl-half>
                <Incidents memberId={member.id} />
              </Col>

              <Col xl={6} lg={12} md={12} sm={12} pr-half pl-half>
                <Packs packs={propOr(emptyArray, 'packs', member)} />
              </Col>
            </Row>
          </Col>

          <Col xl={3} lg={4} md={12} sm={12}>
            <Profile member={member} />
          </Col>
        </Row>

        <ConfirmModal
          title={t('RESET_PIN')}
          show={showConfirmResetPin}
          onClose={this.closeResetPinModal}
          onConfirm={this.sendNewPin}
          content={() => <Text>{t('RESET_PIN_CONFIRM', { user: member })}</Text>}
        />
      </Fragment>
    );
  }
}

export default compose(
  withTheme,
  sizing(),
  translate('member'),
  connect(
    (state) => ({
      member: pathOr(emptyObject, ['member', 'current', 'data'], state),
      isFetching: pathOr(emptyObject, ['member', 'current', 'isFetching'], state),
      isUpdating: pathOr(false, ['member', 'current', 'isUpdating'], state)
    }),
    { getMember, resetCurrentMember, updateMemberAccess, handleErrorMessage, handleSuccessMessage }
  )
)(Member);
