import i18n from 'i18next';
import { initReactI18next, withTranslation } from 'react-i18next';
import en from './en.json';

const resources = { en };

i18n.use(initReactI18next).init({
  defaultNS: ['default'],
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
});

export default i18n;

export { withTranslation as translate };
