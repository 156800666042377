import styled from '@emotion/styled';

const BottomContainer = styled('div')(({ theme, negativeMargin }) => ({
  position: 'sticky',
  left: '0',
  right: '0',
  bottom: '0',
  backgroundColor: theme.colors.white[500],
  padding: '20px',
  overflow: 'hidden!important',
  marginLeft: negativeMargin ? '-20px' : '0',
  marginRight: negativeMargin ? '-20px' : '0'
}));

export default BottomContainer;
