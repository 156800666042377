import {
  SET_CHAT_FILTER,
  TOGGLE_CREATE_CHAT_CHANNEL,
  SET_STREAM_CHAT_CHANNEL,
  GET_MEMBERS_FOR_CHAT,
  GET_MEMBERS_FOR_CHAT_SUCCESS,
  GET_MEMBERS_FOR_CHAT_FAIL,
  APPEND_MEMBERS_FOR_CHAT,
  APPEND_MEMBERS_FOR_CHAT_SUCCESS,
  APPEND_MEMBERS_FOR_CHAT_FAIL,
  STREAM_CHAT_CONNECTED,
  STREAM_CHAT_DISCONNECTED,
  STREAM_CHAT_UNREAD_COUNT,
  STREAM_CHAT_TOGGLE_MINI,
  initState
} from './chat.actionTypes';

import { appendList, appendListFailure, genState, setFetching, setList, setListFailure } from '../utils/reducer';

const user = (state) => ({
  [SET_STREAM_CHAT_CHANNEL]: (channel) => ({
    ...state,
    currentChannel: channel
  }),
  [SET_CHAT_FILTER]: (search) => ({
    ...state,
    search
  }),
  [TOGGLE_CREATE_CHAT_CHANNEL]: (showCreateChannelModal) => ({
    ...state,
    showCreateChannelModal
  }),
  [STREAM_CHAT_CONNECTED]: () => ({
    ...state,
    connectStatus: true
  }),
  [STREAM_CHAT_DISCONNECTED]: () => ({
    ...state,
    connectStatus: false
  }),
  [STREAM_CHAT_UNREAD_COUNT]: (count) => ({
    ...state,
    unreadCount: count
  }),
  [STREAM_CHAT_TOGGLE_MINI]: (status) => ({
    ...state,
    toggleMini: {
      ...state.toggleMini,
      ...status
    }
  }),
  [APPEND_MEMBERS_FOR_CHAT]: setFetching(state, true),
  [APPEND_MEMBERS_FOR_CHAT_SUCCESS]: appendList(state),
  [APPEND_MEMBERS_FOR_CHAT_FAIL]: appendListFailure(state),
  [GET_MEMBERS_FOR_CHAT]: setFetching(state, true),
  [GET_MEMBERS_FOR_CHAT_SUCCESS]: setList(state),
  [GET_MEMBERS_FOR_CHAT_FAIL]: setListFailure(state)
});

export default (state = initState, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: user, type });
};
