import axios from 'axios';
import { tokenRequestInterceptor } from './interceptors';

export const createInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });

  instance.interceptors.request.use(tokenRequestInterceptor, (error) => {
    console.error(`An error occurend while calling properties API ${error}`);
    return Promise.reject(error);
  });

  return instance;
};
