import React from 'react';
import { object, func } from 'prop-types';
import { compose, pathOr } from 'ramda';
import { withRouter } from 'react-router-dom';
import { translate } from '../../translate/i18n';
import { Block, Button, Col, Row, Text } from '../../components';
import HouseIssueForm from './houseIssue.form';
import Header from '../Inventory.header';
import { hasRole, isAdminOrCM, ROLES } from '../../utils/authorizations';
import { connect } from 'react-redux';
import { emptyObject } from '../../utils';

const InventoryCreateIssue = ({ user, history, t }) => {
  const AuthorizedHeader = isAdminOrCM(Header);
  return (
    <Block pt pl pr>
      <AuthorizedHeader
        createInventoryLink
        showCompareButton={false}
        label={'BACK_TO_INVENTORIES_LIST'}
        link="/inventories"
      />

      <Row>
        <Col lg={6} mb>
          <Text size="xl" weight={200}>
            {t('REPORT_AN_ISSUE')}
          </Text>
        </Col>

        {hasRole([ROLES.CLEANER], user) && (
          <Col lg={6} mb>
            <Row right>
              <Button size="md" bg-primary onClick={() => history.push('/inventories/create')}>
                {t('NEW_INVENTORY')}
              </Button>
            </Row>
          </Col>
        )}
      </Row>

      <HouseIssueForm
        onCancel={() => {
          history.push('/inventories');
        }}
      />
    </Block>
  );
};

InventoryCreateIssue.propTypes = {
  t: func,
  history: object
};

export default compose(
  withRouter,
  connect((state) => ({
    user: pathOr(emptyObject, ['user', 'data'], state)
  })),
  translate('inventories')
)(InventoryCreateIssue);
