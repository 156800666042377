import React, { useState } from 'react';
import { object, func, string, bool } from 'prop-types';
import { compose, pathOr } from 'ramda';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import q from 'q';
import OutsideClickHandler from 'react-outside-click-handler';

import { Text } from '../index';
import { translate } from '../../translate/i18n';
import { InputContainer, StyledLabel, ErrorText } from './TextInput.styles';
import { formatInputProps } from './decorators';
import mdeConvert from '../../utils/mde';

const maxCount = 2048;

const MDEAreaForm = (props) => {
  const { meta, t, label, inputProps, isDisabled } = props;
  const { touched, error, active } = meta;

  const [blocker, setBlocker] = useState(null);
  const [emojiRef, setEmojiRef] = useState(null);
  const [boxRef, setBoxRef] = useState(null);

  const commands = {
    emoji: {
      name: 'custom-emoji',
      icon: () => (
        <span ref={setEmojiRef} role="img" aria-label="emoji">
          😊
        </span>
      ),
      execute: (opts) => {
        const deferred = q.defer();

        setBlocker({ deferred, opts });

        return deferred.promise;
      }
    }
  };

  const emojiClose = () => {
    if (pathOr(blocker, ['deferred', 'resolve'], null)) {
      blocker.deferred.resolve();
    }

    setBlocker(null);
  };

  const onEmojiSelect = (data) => {
    blocker.opts.textApi.replaceSelection(data.native);
    emojiClose();
  };

  return (
    <InputContainer>
      <StyledLabel active={active}>{label}</StyledLabel>
      {blocker && (
        <OutsideClickHandler onOutsideClick={emojiClose}>
          <Picker
            style={{
              position: 'absolute',
              top: `${emojiRef.getBoundingClientRect().top - boxRef.getBoundingClientRect().top + 20}px`,
              left: `${emojiRef.getBoundingClientRect().left - boxRef.getBoundingClientRect().left}px`,
              zIndex: 9
            }}
            onSelect={onEmojiSelect}
          />
        </OutsideClickHandler>
      )}
      <div className="mde_wrapper" style={{ position: 'relative' }} ref={setBoxRef}>
        <div ref={setEmojiRef} role="img" className="counter">
          <Text weight={300}>
            {inputProps.value.length} / {maxCount}
          </Text>
        </div>

        <ReactMde
          commands={commands}
          toolbarCommands={[
            ['header', 'bold', 'italic', 'strikethrough'],
            ['link', 'quote', 'code', 'image'],
            ['unordered-list', 'ordered-list'],
            ['emoji']
          ]}
          value={inputProps.value}
          onChange={inputProps.onChange}
          readOnly={isDisabled || !!blocker}
          disablePreview
        />
        {touched && error && <ErrorText>{t(`validation:${error}`)}</ErrorText>}

        <div
          className="mde_preview"
          dangerouslySetInnerHTML={{
            __html: mdeConvert(inputProps.value)
          }}
        />
      </div>
    </InputContainer>
  );
};
MDEAreaForm.propTypes = {
  label: string,
  t: func,
  inputProps: object,
  meta: object,
  placeholder: string,
  active: bool,
  isDisabled: bool
};

export default compose(formatInputProps(), translate('form'))(MDEAreaForm);
