import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ForgotPasswordForm from './ForgotPassword.form';
import { func } from 'prop-types';
import { translate } from '../../translate/i18n';
import { Container, Card, Col, Row } from '../../components';

const ForgotPassword = ({ t }) => {
  return (
    <Container center pt-double>
      <Row center mt-double>
        <Col lg={4}>
          <Card title={t('RESET_PASSWORD_TITLE')} textCenter>
            <ForgotPasswordForm />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ForgotPassword.propTypes = {
  t: func
};
export default compose(connect(), translate('auth'))(ForgotPassword);
