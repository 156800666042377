import React from 'react';
import Selector from './Selector';
import { translate } from '../../translate/i18n';
import { func, string, array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose, pathOr, isEmpty } from 'ramda';
import { emptyArray } from '../../utils';
import { getPaymentMethodList } from '../../enum/enum.actions';
import { Loader } from '../';
import { upperCase } from '../../utils';

const handleChange = (onChange) => (value) => {
  if (isEmpty(value)) return onChange();
  return onChange(value);
};

const SelectorPaymentMethod = (props) => {
  const { name, isFetching, list, t, getPaymentMethodList, onChange, label, value, isInForm, isDisabled, placeholder } =
    props;
  if (isEmpty(list)) getPaymentMethodList();

  if (isFetching) return <Loader />;

  const options = list.map((option) => ({
    label: t(upperCase(option)),
    value: option
  }));

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulit={false}
      onChange={handleChange(onChange)}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};
SelectorPaymentMethod.propTypes = {
  isInForm: bool,
  t: func,
  name: string.isRequired,
  list: array,
  getPaymentMethodList: func,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  isFetching: bool,
  placeholder: string
};

Selector.defaultValues = {
  isInForm: false
};

export default compose(
  translate('form'),
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'paymentMethod', 'list'], state),
      isFetching: pathOr(false, ['enums', 'paymentMethod', 'isFetching'], state)
    }),
    { getPaymentMethodList }
  )
)(SelectorPaymentMethod);
