import * as api from '@cohabs/bo-api';
import { defaultPaginationLimit, emptyArray } from '../utils';

export const getRoomList = ({ page = 1, limit = defaultPaginationLimit, criteria = {}, order = [] }) => {
  if (page < 1) page = 1;
  return api.post(`/rooms/${page}/${limit}`, { criteria, order });
};

export const getRoom = (id) => {
  return api.get(`/room/${id}`);
};

export const createRoom = (room) => api.post('/room', room);
export const updateRoom = (room) => api.patch(`/room/${room.id}`, room);
export const upsertRoom = (room) => (!room.id ? createRoom(room) : updateRoom(room));

export const deleteRooms = (list = emptyArray) =>
  Promise.all(
    list.map((room) => {
      return api.patch(`/room/${room.id}`, { id: room.id, deleted: true });
    })
  );
