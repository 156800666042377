import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { userReducer as user } from '../user/user.reducer';
import { roomReducer as room } from '../rooms/room.reducer';
import { applicationsReducer as applications } from '../applications/applications.reducer';
import { housesReducer as houses } from '../houses/houses.reducer';
import { hubReducer as hub } from '../hub/hub.reducer';
import { collectionReducer as collection } from '../collection/collection.reducer';
import { incidentsReducer as incidents } from '../incidents/incidents.reducer';
import { memberReducer as member } from '../members/member.reducer';
import { enumReducer as enums } from '../enum/enum.reducer';
import { geoReducer as geo } from '../geo/geo.reducer';
import { leasesReducer as leases } from '../leases/leases.reducer';
import { inventoriesReducer as inventories } from '../inventories/inventories.reducer';
import { packsReducer as packs } from '../packs/packs.reducer';
import { packLocationsReducer as packLocations } from '../packs/packLocations/packLocations.reducer';
import { cmsReducer as cms } from '../cms/cms.reducer';
import { paymentsReducer as payments } from '../payments/payments.reducer';
import { paymentProfileReducer as paymentProfile } from '../paymentProfile/paymentProfile.reducer';
import { hubPaymentMethodReducer as hubPaymentMethod } from '../hubPaymentMethod/hubPaymentMethod.reducer';
import { paymentProfileMethodReducer as paymentProfileMethod } from '../paymentProfileMethod/paymentProfileMethod.reducer';
import { paymentGatewayReducer as paymentGateway } from '../paymentGateway/paymentGateway.reducer';
import chat from '../chat/chat.reducer';
import menu from '../menu/reducer';

export default combineReducers({
  menu,
  chat,
  form: formReducer,
  paymentGateway,
  paymentProfileMethod,
  paymentProfile,
  hubPaymentMethod,
  room,
  user,
  applications,
  houses,
  hub,
  collection,
  incidents,
  member,
  enums,
  geo,
  leases,
  inventories,
  packs,
  packLocations,
  cms,
  payments
});
