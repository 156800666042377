import React from 'react';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';
import SelectorTipsCategory from '../selector/Selector.tipscategory';

const SelectorTipsCategoryForm = ({ t, input, label, isDisabled, placeholder, meta: { touched, error } }) => (
  <InputContainer>
    <SelectorTipsCategory label={label} placeholder={placeholder} {...input} isInForm={true} isDisabled={isDisabled} />

    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectorTipsCategoryForm);
