import React, { useState, useEffect, Fragment } from 'react';
import { func, object, array, bool } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, prop, pathOr } from 'ramda';
import styled from '@emotion/styled';
import { Table, Block, Row, Text, Loader, Modal, ActionButton, ActionSecondaryButton } from '../components';
import { translate } from '../translate/i18n';
import { emptyObject, emptyArray, capitalize } from '../utils';
import { deleteTrash, getHouseTrashes } from './houses.actions';
import TrashForm from './House.trash.form';
import { initValuesWithRecurrences } from './houses.utils';

const StyledRect = styled('div')(({ theme, value }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: value,
  border: `0.5px solid ${theme.colors.lightGrey[300]}`
}));

const getColumnDefs = (t) => [
  {
    headerName: '',
    field: '',
    width: 20,
    headerComponentFramework: ({ api, column }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            isChecked ? api.deselectAll() : api.selectAll();
            setIsChecked(!isChecked);
            api.refreshCells({ columns: [column], force: true });
          }}
        />
      );
    },
    onCellClicked: () => null,

    cellRendererFramework: ({ node, api }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(node.isSelected());
      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!node.isSelected());
            node.setSelected(!node.isSelected());
            api.refreshHeader();
          }}
        />
      );
    }
  },
  {
    headerName: capitalize(t('COLOR')),
    field: 'hexa',
    cellRendererFramework: StyledRect,
    width: 20
  },
  {
    headerName: capitalize(t('TITLE')),
    field: 'title',
    width: 100
  },
  {
    headerName: capitalize(t('DESCRIPTION')),
    field: 'description'
  }
];

const Trashes = (props) => {
  // Props
  const { t, house, trashes, isFetching, deleteTrash, getHouseTrashes } = props;

  // State
  const [showModal, toggleModal] = useState(false);
  const [selectedTrash, selectTrash] = useState(emptyObject);
  const [selectedTrashes, selectTrashes] = useState([]);

  // Functions
  const closeModal = () => toggleModal(false);
  const openCreateModal = () => {
    selectTrash(emptyObject);
    toggleModal(true);
  };
  const deleteTrashes = () => {
    deleteTrash(selectedTrashes);
    selectTrashes(emptyArray);
  };
  const onRowSelected = (event) => selectTrashes(event.api.getSelectedRows());
  const onRowClicked = (event) => {
    selectTrash(prop('data', event));
    toggleModal(true);
  };

  useEffect(() => {
    if (house.id) {
      getHouseTrashes(house.id);
    }
  }, []);

  // Render
  return (
    <Fragment>
      <Block p>
        <Row mb>
          <Text size="lg" mr-half>
            {t('TRASHES')}
          </Text>

          {!selectedTrashes.length ? (
            <ActionButton name="add-trash" icon="Plus" onClick={openCreateModal} />
          ) : (
            <ActionSecondaryButton name="remove-trash" icon="Trash2" onClick={deleteTrashes} danger />
          )}
        </Row>

        {isFetching ? (
          <Row center middle>
            <Loader />
          </Row>
        ) : (
          <Row>
            <Table
              data={{
                columnDefs: getColumnDefs(t),
                rowData: trashes,
                defaultColDef: { onCellClicked: onRowClicked }
              }}
              onSelectionChanged={onRowSelected}
            />
          </Row>
        )}
      </Block>

      <Modal
        show={showModal}
        title={t('ADD_TRASH')}
        onClose={closeModal}
        content={() => (
          <TrashForm initialValues={initValuesWithRecurrences(selectedTrash, house)} onClose={closeModal} />
        )}
      />
    </Fragment>
  );
};

Trashes.propTypes = {
  house: object,
  trashes: array,
  isFetching: bool,
  getHouseTrashes: func,
  deleteTrash: func,
  t: func
};

export default compose(
  withRouter,
  translate('house'),
  connect(
    (state) => ({
      house: pathOr(emptyObject, ['houses', 'current', 'data'], state),
      trashes: pathOr(emptyArray, ['houses', 'current', 'data', 'trashes'], state),
      isFetching: pathOr(false, ['houses', 'current', 'isFetching'], state)
    }),
    { deleteTrash, getHouseTrashes }
  )
)(Trashes);
