import * as api from '@cohabs/bo-api';

export const createLock = (lock) => api.post('/lock', lock);

export const updateLock = (lock) => api.put(`/lock/${lock.id}`, lock);

export const upsertLock = (lock) => (!lock.id ? createLock(lock) : updateLock(lock));

export const deleteLock = (lock) => api.del(`/lock/${lock.id}`);

export const getLock = (id) => api.get(`/lock/${id}`);
