import styled from '@emotion/styled';

export const AvatarContainer = styled('div')(({ theme }) => ({
  height: '140px',
  width: '140px',
  margin: 'auto',
  position: 'relative',
  marginBottom: theme.spacing.half,
  backgroundColor: theme.colors.grey[500],
  borderRadius: '50%'
}));

export const Avatar = styled('div')(() => ({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  overflow: 'hidden',
  width: '140px',
  height: '140px'
}));

export const EditProfileContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));

export const StyledStatus = styled('div')(({ theme, banned }) => ({
  borderRadius: '50px',
  backgroundColor: banned ? theme.colors.red[500] : theme.colors.green[500],
  color: 'white',
  zIndex: 1,
  width: '42px',
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '800',
  position: 'absolute',
  top: '0',
  right: '0'
}));
