import React, { PureComponent } from 'react';
import { get } from '@cohabs/bo-api';
import styled from '@emotion/styled';
import { Loader } from 'react-feather';
import { propOr, prop } from 'ramda';
import { show } from './decorators';
import packageJson from '../../package.json';

const EnvContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing.half,
  left: theme.spacing.half,
  zIndex: 2000,
  background: 'white',
  borderRadius: '2',
  padding: `${theme.spacing.quarter}px ${theme.spacing.normal}px`,
  boxShadow: `0 0 6px ${theme.colors.grey[500]}`
}));

class Env extends PureComponent {
  state = { env: '', isLoading: true };
  async componentDidMount() {
    try {
      const envInfo = await get('/ping/env');
      this.setState({ envInfo, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }
  render() {
    const { envInfo, isLoading, hide } = this.state;

    if (hide) return null;
    if (isLoading) return <Loader />;

    const env = propOr('no env...', 'env', envInfo);
    const version = propOr('', 'version', envInfo);

    return (
      <EnvContainer onClick={() => this.setState({ hide: true })}>
        <pre>
          <table>
            <tbody>
              <tr>
                <td>
                  <b>Back</b>
                </td>
                <td>- {env}</td>
                <td>
                  <b>v{version}</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Front</b>
                </td>
                <td>- {prop('REACT_APP_ENV', process.env)}</td>
                <td>
                  <b>v{prop('version', packageJson)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </pre>
      </EnvContainer>
    );
  }
}

export default show(() => process.env.REACT_APP_ENV !== 'production')(Env);
