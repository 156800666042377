import React from 'react';
import styled from '@emotion/styled';
import { object, number, bool, any } from 'prop-types';
import { spacing, alignement, sizing } from '../decorators';
import { bgColor } from '../../theme/decorators';
import { compose } from 'ramda';

const ColStyled = styled('div')(({ theme, alignement, size, spacing, bgColor, noFlex }) => ({
  ...alignement,
  ...spacing,
  backgroundColor: bgColor ? theme.colors.lightOrange[500] : 'tranparent',
  flexGrow: 1,
  width: `${(size / 12) * 100}%`,
  maxWidth: `${(size / 12) * 100}%`,
  display: noFlex ? '' : 'flex',
  flexDirection: 'column'
}));

const Col = ({ children, size, center, right, spacing, alignement, bgColor, ...other }) => (
  <ColStyled
    spacing={spacing}
    center={center}
    right={right}
    size={size}
    bgColor={bgColor}
    alignement={alignement}
    {...other}>
    {children}
  </ColStyled>
);
Col.defaultProps = {
  size: 12,
  center: false,
  right: false
};
Col.propTypes = {
  children: any,
  size: number,
  center: bool,
  right: bool,
  spacing: object,
  alignement: object
};

export default compose(bgColor(), alignement(), spacing(), sizing())(Col);
