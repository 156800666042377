import React, { useEffect, Fragment } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, pathOr } from 'ramda';
import { Row, Text, ActionButton } from '../components';
import { translate } from '../translate/i18n';
import { emptyObject } from '../utils';
import { createPaymentProfile } from './paymentProfile.actions';
import { fetchPaymentProfileMethodRequest } from '../paymentProfileMethod/paymentProfileMethod.actions';
import { fetchHubPaymentMethodRequest } from '../hubPaymentMethod/hubPaymentMethod.actions';
import PaymentProfileMethod from '../paymentProfileMethod/paymentProfileMethod.form';

const PaymentProfileTab = ({
  t,
  member,
  currentHub,
  currentProfile,
  currentGateway,
  hubPaymentMethod,
  paymentProfileMethod,
  createPaymentProfileCall,
  fetchHubPaymentMethod,
  fetchPaymentProfileMethod
}) => {
  useEffect(() => {
    if (!hubPaymentMethod[currentHub.id]) {
      fetchHubPaymentMethod(currentHub.id);
    }

    if (currentProfile && !paymentProfileMethod[currentProfile.id]) {
      fetchPaymentProfileMethod({
        hubId: currentHub.id,
        userId: member.id,
        profileId: currentProfile.id
      });
    }
  }, [currentHub, currentProfile]);

  if (!hubPaymentMethod[currentHub.id] || !hubPaymentMethod[currentHub.id].isResult) {
    return null;
  }

  let maskProfilePaymentMethods = {};

  if (
    currentProfile &&
    paymentProfileMethod[currentProfile.id] &&
    paymentProfileMethod[currentProfile.id].list.length
  ) {
    maskProfilePaymentMethods = paymentProfileMethod[currentProfile.id].list.reduce(
      (mask, item) => ({
        ...mask,
        [item.type]: item
      }),
      {}
    );
  }

  if (!hubPaymentMethod[currentHub.id].list.length) {
    return (
      <Row middle mb>
        <Text size="xl" grey={500} pr-half>
          {t('member:NO_PAYMENT_METHODS_FOR_HUB')}
        </Text>
      </Row>
    );
  }

  if (!currentProfile) {
    return (
      <Row middle>
        <ActionButton
          name="create-profile"
          icon="Plus"
          onClick={() =>
            createPaymentProfileCall({
              memberId: member.id,
              hubId: currentHub.id
            })
          }
        />
        <Text size="xl" grey={500} pr-half>
          {t('default:CREATE')}
        </Text>
        <Text size="xl" grey={500} pr-half>
          {t('member:PAYMENT_PROFILE')}
        </Text>
      </Row>
    );
  }

  return hubPaymentMethod[currentHub.id].list.map((item) => (
    <Fragment key={`${item.payment_method.type}_${currentHub.id}`}>
      <PaymentProfileMethod
        currentHub={currentHub}
        currentProfile={currentProfile}
        currentGateway={currentGateway}
        hubMethod={item}
        profileMethod={maskProfilePaymentMethods[item.payment_method.type]}
        member={member}
      />
    </Fragment>
  ));
};

PaymentProfileTab.propTypes = {
  t: func,
  member: object.isRequired,
  currentHub: object.isRequired,
  currentProfile: object,
  currentGateway: object,

  hubPaymentMethod: object.isRequired,
  paymentProfileMethod: object.isRequired,

  createPaymentProfileCall: func,
  fetchHubPaymentMethod: func,
  fetchPaymentProfileMethod: func
};

export default compose(
  connect(
    (state) => ({
      hubPaymentMethod: pathOr(emptyObject, ['hubPaymentMethod'], state),
      paymentProfileMethod: pathOr(emptyObject, ['paymentProfileMethod'], state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          createPaymentProfileCall: createPaymentProfile.request,
          fetchHubPaymentMethod: fetchHubPaymentMethodRequest,
          fetchPaymentProfileMethod: fetchPaymentProfileMethodRequest
        },
        dispatch
      )
  ),
  translate()
)(PaymentProfileTab);
