import React, { useEffect, useState } from 'react';
import Selector from './Selector';
import { func, string, array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, compose, map } from 'ramda';
import { emptyArray, makeOptions, upperCase, isEmptyOrNil } from '../../utils';
import { translate } from '../../translate/i18n';
import { getTipsPriorities } from '../../enum/enum.actions';

const SelectorTipsPriority = (props) => {
  const { t, list, name, getTipsPriorities, onChange, isMulti, label, value, isInForm, isDisabled, placeholder } =
    props;

  const [options, setOptions] = useState([]);

  const handleChange = (val) => {
    if (isEmptyOrNil(val)) {
      onChange(null);
    } else {
      onChange(val);
    }
  };

  useEffect(() => {
    getTipsPriorities();
  }, []);

  useEffect(() => {
    const enums = map((option) => ({ value: option, name: t(`TIPS_PRIORITIES.${upperCase(option)}`) }), list);
    setOptions(makeOptions(enums, 'value', 'name', 'label'));
  }, [list]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

SelectorTipsPriority.propTypes = {
  isInForm: bool,
  name: string.isRequired,
  list: array,
  getTipsPriorities: func,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  placeholder: string,
  isFetching: bool,
  t: func
};

SelectorTipsPriority.defaultValues = {
  isInForm: false
};

export default compose(
  translate('cms'),
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'tipsPriorities', 'list'], state),
      isFetching: pathOr(false, ['enums', 'tipsPriorities', 'isFetching'], state)
    }),
    { getTipsPriorities }
  )
)(SelectorTipsPriority);
