export const LOGIN = 'LOGIN';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER = 'SET_USER';
export const SET_BE_ENDPOINT = 'SET_BE_ENDPOINT';
export const GET_BE_ENDPOINT = 'GET_BE_ENDPOINT';
export const SUBMIT_BE_ENDPOINT = 'SUBMIT_BE_ENDPOINT';
export const INIT_USER = 'INIT_USER';
export const INIT_USER_FAILED = 'INIT_USER_FAILED';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD_FORM_SUBMIT = 'FORGOT_PASSWORD_FORM_SUBMIT';
export const FORGOT_PASSWORD_FORM_FAILURE = 'FORGOT_PASSWORD_FORM_FAILURE';
export const FORGOT_PASSWORD_FORM_SUCCESS = 'FORGOT_PASSWORD_FORM_SUCCESS';
export const UPSERT_USER = 'UPSERT_USER';
export const UPSERT_USER_SUCCESS = 'UPSERT_USER_SUCCESS';
export const UPSERT_USER_FAIL = 'UPSERT_USER_FAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const APPEND_USERS = 'APPEND_USERS';
export const APPEND_USERS_SUCCESS = 'APPEND_USERS_SUCCESS';
export const APPEND_USERS_FAIL = 'APPEND_USERS_FAIL';

export const initialUser = {
  data: {},
  fetching: true,
  isLogged: false,
  isInit: false,
  isSaving: false,
  error: null,
  hasResetPassword: false,
  userList: []
};
