import { takeLatest, call, all, put } from 'redux-saga/effects';
import { propOr } from 'ramda';
import * as service from './incidents.service';
import { GET_INCIDENTS, APPEND_INCIDENTS } from './incidents.actionTypes';
import { setIncidents, appendIncidentsSuccess, appendIncidentsFail } from './incidents.actions';
import { types } from '../utils/sagas';
import { handleErrorMessage } from '../toast/toast.actions';

export function* getIncidents(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const incidents = yield call(service.getIncidentsList, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(setIncidents(incidents));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.INCIDENT, error: err, data: action.payload }));
  }
}

export function* appendIncidents(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const incidents = yield call(service.getIncidentsList, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(appendIncidentsSuccess(incidents));
  } catch (err) {
    yield put(appendIncidentsFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.INCIDENT, error: err, data: action.payload }));
  }
}

export default function* incidentsSaga() {
  yield all([takeLatest(APPEND_INCIDENTS, appendIncidents), takeLatest(GET_INCIDENTS, getIncidents)]);
}
