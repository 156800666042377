import React, { useState, Fragment, useRef, useEffect } from 'react';

import HouseForm from './house.form';
import { compose, omit, pathOr, propOr } from 'ramda';
import { connect } from 'react-redux';
import { emptyObject, isEmptyOrNil } from '../utils';
import { translate } from '../translate/i18n';
import { bool, object, shape, string } from 'prop-types';
import { Button, Container, Row, SectionTitle } from '../components';
import HouseLockForm from './House.lockForm';

import './houseLockForm.css';

const prepareInitialValues = (isNew = true, house) => {
  return isNew
    ? {}
    : {
        info: {
          ...omit(['landlord'], house),
          ...(isEmptyOrNil(house.serviceVouchersAmount) && { serviceVouchersAmount: '' }),
          hub: pathOr(null, ['hub', 'id'], house)
        },
        ...(house && { landlord: house.landlord })
      };
};

const HouseDetails = (props) => {
  const { house, id, t } = props;
  const lockRef = useRef();
  const [newLock, showNewLock] = useState(false);
  const isNew = id === 'create';
  const initialValues = prepareInitialValues(isNew, house);
  const lockList = propOr([], 'locks', house);

  useEffect(() => {
    if (newLock && lockRef.current) {
      lockRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newLock, lockRef.current]);

  return (
    <Fragment>
      <HouseForm {...props} house={house} initialValues={initialValues} isNew={isNew} />

      {!isNew && (
        <Container column p>
          <div ref={lockRef} />
          <SectionTitle translationKey="lock:LOCK_INFO" />
          {lockList.map((item) => (
            <HouseLockForm
              key={item.id}
              house={house}
              initialValues={{
                houseId: house.id,
                lock: omit(['pin', 'iqSecret'], item)
              }}
              form={`house_lock_${item.id}`}
            />
          ))}

          {newLock ? (
            <HouseLockForm
              house={house}
              initialValues={{
                houseId: house.id,
                lock: {}
              }}
              onCancel={() => showNewLock(!newLock)}
              form="house_lock_new"
            />
          ) : (
            <Row className="ggg">
              <div className={lockList.length ? 'add-lock-button-block' : ''}>
                <Button name="addLock" size="md" bg-primary onClick={() => showNewLock(!newLock)}>
                  {t('lock:ADD_LOCK')}
                </Button>
              </div>
            </Row>
          )}
        </Container>
      )}
    </Fragment>
  );
};

HouseDetails.propTypes = {
  id: string,
  house: shape({
    isFetching: bool,
    data: object
  }).isRequired
};

export default compose(
  connect((state) => ({
    house: pathOr(emptyObject, ['houses', 'current', 'data'], state)
  })),
  translate('house')
)(HouseDetails);
