import { SEGMENT_IDENTIFY, SEGMENT_PAGE } from './segment.actionTypes';

export const segmentIdentify = (user) => ({
  type: SEGMENT_IDENTIFY,
  user
});
export const segmentPage = (history) => ({
  type: SEGMENT_PAGE,
  history
});
