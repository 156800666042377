import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { func, string } from 'prop-types';
import { reduxForm, formValues, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose, propOr } from 'ramda';
import { translate } from '../../translate/i18n';
import { BottomContainer, Button, Row } from '../../components';
import { isEmptyOrNil } from '../../utils';
import { createHouseIssue } from '../inventories.actions';
import StyledForm from '../../components/form.styles';
import Issues from './inventory.form.issues';
import HouseSelection from './houseIssue.form.houseSelection';
import issueValidate from './issue.validate';

const HouseIssueForm = ({ t, onCancel, submitSucceeded, handleSubmit, submitting, houseId, form }) => {
  if (submitSucceeded) {
    return <Redirect to="/inventories" />;
  }

  return (
    <StyledForm onSubmit={handleSubmit(createHouseIssue)}>
      <HouseSelection />

      {!isEmptyOrNil(houseId) && (
        <Fragment>
          <Issues formName={form} notEmpty />

          <BottomContainer negativeMargin={true}>
            <Row right>
              <Button name="cancel" disabled={submitting} onClick={onCancel} clear size="md">
                {t('form:CANCEL')}
              </Button>
              <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
                {t('form:SAVE')} {submitting ? '...' : ''}
              </Button>
            </Row>
          </BottomContainer>
        </Fragment>
      )}
    </StyledForm>
  );
};

const validate = (values) => ({
  issues: issueValidate(propOr([], 'issues', values))
});

HouseIssueForm.propTypes = {
  t: func,
  onCancel: func,
  houseId: string,
  ...reduxFormPropTypes
};

export default compose(
  translate('inventories'),
  reduxForm({
    form: 'InventoryCreateForm',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: true,
    initialValues: {
      houseId: null,
      issues: []
    }
  }),
  formValues({ houseId: 'houseId' })
)(HouseIssueForm);
