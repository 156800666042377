export const segmentIdentify = (user) =>
  window.analytics.identify(user.id, {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role
  });

export const segmentPage = (history) => {
  let prevPath = null;
  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname;
      window.analytics.page();
    }
  });
};
