import React, { useState } from 'react';
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { required, positive, maxLength } from '../utils';
import { Field, reduxForm, propTypes as reduxFormPropTypes, FormSection } from 'redux-form';
import { translate } from '../translate/i18n';
import {
  Container,
  Button,
  TextInput,
  Row,
  Col,
  SelectHouseForm,
  SelectUnitForm,
  SelectHubForm,
  StyledForm,
  Block,
  SectionTitle,
  ToggleForm,
  BottomContainer
} from '../components';
import { upsertRoom } from './room.actions';
import { getHouseNextRoom } from '../houses/houses.service';
import { pathOr } from 'ramda';

const max50 = maxLength(50);

const RoomForm = (props) => {
  const { change, handleSubmit, room, houses, submitting, t, isNew, onCancel, submitSucceeded } = props;
  const [selectedHub, selectHub] = useState(null);
  const [selectedHouse, selectHouse] = useState(pathOr(null, ['houseId'], room));
  const onHubChange = (_, hubId) => selectHub(hubId);
  const onHouseChange = (_, houseId) => {
    if (isNew) {
      selectHouse(houseId);
      getHouseNextRoom(houseId).then((roomNumber) => {
        change('number', roomNumber);
      });
    }
  };

  const showBaseRent = React.useMemo(() => {
    const _selectedHouse = houses.find((h) => h.id === selectedHouse);
    return pathOr(null, ['hub', 'name'], _selectedHouse) === 'Paris';
  }, [selectedHouse, houses]);

  if (submitSucceeded && isNew) {
    return <Redirect to="/rooms" />;
  }

  return (
    <Block>
      <StyledForm onSubmit={handleSubmit(upsertRoom)}>
        <FormSection name="">
          <Container column pt-half pb-half pl pr>
            <Col lg={12}>
              <SectionTitle translationKey="form:GENERAL_INFO" />
            </Col>
            <Row mb>
              <Col lg={12} pr-half pl-half>
                <Field component={ToggleForm} label={t('form:ACTIVE')} name="active" type="checkbox" />
              </Col>
            </Row>
            <Row mb>
              <Col xl={9} lg={9} md={9} sm={12} pr-half pl-half>
                {isNew && (
                  <Field
                    label={`${t('LOCATION')}*`}
                    name="hub"
                    validate={required}
                    isMulti={false}
                    component={SelectHubForm}
                    onChange={onHubChange}
                  />
                )}

                <Field
                  label={`${t('HOUSE')}*`}
                  name="houseId"
                  validate={required}
                  isMulti={false}
                  component={SelectHouseForm}
                  isDisabled={!isNew || !selectedHub}
                  hubId={selectedHub}
                  placeholder={t('SELECT_LOCATION_FIRST')}
                  onChange={onHouseChange}
                />

                <Field
                  label={`${t('UNIT')}`}
                  name="unitId"
                  isMulti={false}
                  component={SelectUnitForm}
                  isDisabled={!!room.unitId || !selectedHouse}
                  houseId={selectedHouse}
                  placeholder={t('SELECT_UNIT')}
                />

                <Field
                  label={`${t('ROOM_FLOOR')}*`}
                  name="floor"
                  type="number"
                  component={TextInput}
                  validate={[required, positive]}
                />

                <Field
                  label={`${t('ROOM_NUMBER')}*`}
                  name="number"
                  type="number"
                  component={TextInput}
                  validate={[required, positive]}
                />

                <Field label={`${t('CANONICAL_NAME')}`} name="canonicalName" type="text" component={TextInput} />

                <Field
                  label={`${t('ROOM_LOCATION')}*`}
                  name="location"
                  component={TextInput}
                  validate={[required, max50]}
                  type="text"
                />

                <Field label={`${t('SURFACE')}*`} name="surface" component={TextInput} validate={[required]} />

                <Field
                  label={`${t('RENT')}*`}
                  name="rent"
                  component={TextInput}
                  validate={[required, positive]}
                  type="number"
                />

                {showBaseRent && (
                  <Field
                    label={`${t('ROOM_BASE_RENT')}*`}
                    name="baseRent"
                    component={TextInput}
                    validate={[required, positive]}
                    type="number"
                  />
                )}

                <Field label={`${t('DEPOSIT')}`} name="deposit" component={TextInput} type="number" />

                <Field
                  label={`${t('DESCRIPTION')}*`}
                  name="description"
                  component={TextInput}
                  validate={required}
                  type="text"
                />
              </Col>
            </Row>
          </Container>
        </FormSection>

        <BottomContainer>
          <Row right>
            <Button name="cancel" disabled={submitting} onClick={() => onCancel()} clear size="md">
              {t('CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </BottomContainer>
      </StyledForm>
    </Block>
  );
};

RoomForm.propTypes = {
  t: func,
  onCancel: func,
  isNew: bool,
  room: object,
  ...reduxFormPropTypes
};

export default compose(
  reduxForm({ form: 'RoomForm' }),
  connect((state) => ({ houses: pathOr([], ['houses', 'list'], state) })),
  translate(['form', 'rooms'])
)(RoomForm);
