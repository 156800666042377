import styled from '@emotion/styled';
import chroma from 'chroma-js';

export const Switch = styled('div')(({ theme, active }) => ({
  position: 'relative',
  fontFamily: theme.font.family,
  fontWeight: theme.font.weight.lg,
  color: active ? theme.colors.green[500] : theme.colors.lightGrey[500],
  display: 'flex',
  cursor: 'pointer',
  transition: 'color 0.3s ease',
  paddingRight: '5px'
}));

const containerSize = 15;
export const Container = styled('div')(({ theme }) => ({
  width: theme.spacing.sesqui,
  paddingRight: containerSize,
  position: 'relative',
  marginLeft: theme.spacing.half,
  marginRight: theme.spacing.half
}));

export const Input = styled('input')(() => ({
  opacity: 0,
  width: 0,
  height: 0
}));

const size = 18;
export const Slider = styled('div')(({ active, theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '13px',
  background: active
    ? chroma(theme.colors.green[500]).darken(1).alpha(0.3).hex()
    : chroma(theme.colors.lightGrey[500]).darken(1).alpha(0.3).hex(),
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  borderRadius: '1000px',
  transition: 'background 0.3s ease',

  '&:before': {
    content: '""',
    zIndex: 1,
    position: 'absolute',
    width: size,
    height: size,
    background: active ? theme.colors.green[500] : theme.colors.lightGrey[500],
    borderRadius: '100%',
    top: '50%',
    left: active ? containerSize : 0,
    transform: 'translateY(-50%)',
    transition: 'left 0.3s ease'
  }
}));
