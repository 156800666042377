import { propOr, pathOr } from 'ramda';

import {
  initialUser,
  LOGIN,
  LOGOUT_USER,
  LOGIN_FAILED,
  SET_USER,
  INIT_USER_FAILED,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  APPEND_USERS,
  APPEND_USERS_FAIL,
  APPEND_USERS_SUCCESS,
  SET_BE_ENDPOINT
} from './user.actionTypes';

import { upsertUser, DELETE_USERS, DELETE_USERS_SUCCESS, DELETE_USERS_FAILURE } from './user.actions';

import { genState, setFetching, appendList, setList, appendListFailure } from '../utils/reducer';
import { emptyArray } from '../utils';

const user = (state) => ({
  [INIT_USER_FAILED]: () => ({
    ...state,
    isFetching: false,
    isLogged: false,
    isInit: true
  }),
  [SET_BE_ENDPOINT]: (payload) => ({ ...state, backendApiEndPoint: payload.url }),
  [LOGIN]: () => ({ ...state, isFetching: true }),
  [LOGIN_FAILED]: () => ({ ...state, isFetching: false }),
  [LOGOUT_USER]: () => ({ ...initialUser, isInit: true }),
  [SET_USER]: (payload) => ({
    ...state,
    data: payload,
    isLogged: true,
    isFetching: false,
    isInit: true
  }),
  [upsertUser.SUCCESS]: (user) => {
    const users = propOr(emptyArray, 'list', state);
    const hasUser = users.find((_user) => _user.id === user.id);
    const res = {
      list: hasUser ? users.map((_user) => (_user.id === user.id ? user : _user)) : users.concat([user])
    };

    if (pathOr(null, ['data', 'id'], state) === user.id) {
      res.data = {
        ...state.data,
        ...user
      };
    }

    return { ...state, ...res };
  },
  [FETCH_USERS]: setFetching(state, true),
  [FETCH_USERS_SUCCESS]: setList(state),
  [FETCH_USERS_FAILURE]: setFetching(state, false),
  [DELETE_USERS]: () => ({
    ...state,
    isDeleting: true
  }),
  [DELETE_USERS_SUCCESS]: (users) => ({
    ...state,
    isDeleting: false,
    list: propOr(emptyArray, 'list', state).filter((user) => !users.includes(user))
  }),
  [DELETE_USERS_FAILURE]: () => ({
    ...state,
    isDeleting: false
  }),
  [APPEND_USERS]: setFetching(state, true),
  [APPEND_USERS_SUCCESS]: appendList(state),
  [APPEND_USERS_FAIL]: appendListFailure(state)
});

export const userReducer = (state = initialUser, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: user, type });
};
