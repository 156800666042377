import React, { useState } from 'react';
import { func, object, number, array, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pathOr, prop, path } from 'ramda';
import { LogIn, LogOut } from 'react-feather';

import Tooltip from 'react-tooltip-lite';

import { translate } from '../translate/i18n';
import { getInventories, appendInventories } from './inventories.actions';

import {
  capitalize,
  valueOrPlaceholder,
  isNotEmptyOrNil,
  formatDatetime,
  formatNameTruncated,
  formatNameTruncatedAndRole,
  emptyArray
} from '../utils';

import { Col, Row, Block, Text, ScrollableDataTableWithColSelector, ActionButton, Button } from '../components';
import Filter from './Inventories.filter';

const getColumnDefs = (t) => [
  {
    headerName: capitalize(t('Location')),
    field: 'room.house.hub.name',
    width: 125
  },
  {
    headerName: capitalize(t('HOUSE')),
    defaultActive: true,
    field: 'room.house.name'
  },
  {
    headerName: capitalize(t('ROOM_NUMBER')),
    defaultActive: true,
    field: 'room.number',
    width: 100
  },
  {
    headerName: capitalize(t('MEMBER')),
    defaultActive: true,
    field: 'lease.user',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatNameTruncated)(col.value)
  },
  {
    headerName: '',
    field: 'type',
    width: 40,
    cellRendererFramework: ({ value }) => {
      switch (value) {
        case 'move-in': {
          return (
            <div className="ag-cell-react-control">
              <Tooltip content={t('MOVE_IN_INVENTORY')} direction="down">
                <LogIn size={17} />
              </Tooltip>
            </div>
          );
        }
        case 'move-out': {
          return (
            <div className="ag-cell-react-control">
              <Tooltip content={t('MOVE_OUT_INVENTORY')} direction="down">
                <LogOut size={17} />
              </Tooltip>
            </div>
          );
        }
        default: {
          return <div />;
        }
      }
    }
  },
  {
    headerName: capitalize(t('DATE')),
    field: 'createdAt',
    valueFormatter: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, formatDatetime)(value, data.timezone),
    tooltip: ({ data, value }) => valueOrPlaceholder(isNotEmptyOrNil, () => data.timezone, '')(value)
  },
  {
    headerName: capitalize(t('AUTHOR')),
    defaultActive: true,
    field: 'user',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatNameTruncatedAndRole)(col.value)
  }
];

const Inventories = ({
  t,
  inventories,
  inventoriesCount,
  appendInventories,
  getInventories,
  history,
  responseLength,
  isFetching
}) => {
  const [columnDefs] = useState(() => getColumnDefs(t));
  const [filters, setFilters] = useState();
  const goToInventory = (event) => {
    history.push(`/inventories/${path(['data', 'id'], event)}`);
  };

  return (
    <Block p>
      <Row mb middle>
        <Col lg={6}>
          <Row>
            <Text weight={200} size="xl" mr-half>
              {capitalize(t('INVENTORIES'))}
            </Text>

            <ActionButton icon="Plus" name="addInventory" onClick={() => history.push('/inventories/create')} />
          </Row>
        </Col>
        <Col lg={6}>
          <Row right>
            <Button size="md" bg-primary onClick={() => history.push('/house-issue/create')}>
              {t('REPORT_AN_ISSUE')}
            </Button>
          </Row>
        </Col>
      </Row>

      <Filter onFilterSelect={(filter) => setFilters(filter)} />

      <ScrollableDataTableWithColSelector
        filters={filters}
        ready={!!filters}
        columnDefs={columnDefs}
        defaultColDef={{
          valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
          onCellClicked: goToInventory
        }}
        getMethod={getInventories}
        appendMethod={appendInventories}
        rows={inventories}
        rowCount={inventoriesCount}
        isFetching={isFetching}
        responseLength={responseLength}
      />
    </Block>
  );
};

Inventories.propTypes = {
  t: func,
  inventories: array,
  inventoriesCount: number,
  appendInventories: func,
  getInventories: func,
  history: object,
  responseLength: number,
  isFetching: bool
};

export default compose(
  withRouter,
  translate(['inventories', 'default']),
  connect(
    (state) => ({
      inventories: pathOr(emptyArray, ['inventories', 'list'], state),
      inventoriesCount: pathOr(0, ['inventories', 'count'], state),
      isFetching: pathOr(false, ['inventories', 'isFetching'], state),
      responseLength: pathOr(0, ['inventories', 'responseLength'], state)
    }),
    { getInventories, appendInventories }
  )
)(Inventories);
