import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import hash from 'object-hash';
import { compose, values, all, reduce, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { capitalize, handleFilters } from '../utils';
import { Row, Col, SelectorHub, SelectorHouse, Toggle, SearchTextInput, collapsible } from '../components';

const PaymentFilter = ({ t, onFilterSelect }) => {
  let [hashCode, setHashCode] = useState('');

  let [filters, setFilters] = useState({
    search: '',
    hubId: null,
    houseId: null,
    zeroBalance: true
  });

  let [loaded, setLoaded] = useState({
    search: false,
    hubId: false,
    houseId: false,
    zeroBalance: false
  });

  const handleChange = (filter) => (value) => {
    filters[filter] = value;
    if (filter === 'hubId') {
      onLoad('houseId')(false);
    }

    setFilters({
      ...filters
    });
  };

  const onLoad =
    (filter) =>
    (val = true) => {
      if (loaded[filter] !== val) {
        loaded[filter] = val;
        setLoaded({
          ...loaded
        });
      }
    };

  useEffect(() => {
    if (all((item) => item)(values(loaded))) {
      const newHashCode = hash(filters);
      if (newHashCode !== hashCode) {
        hashCode = newHashCode;
        setHashCode(newHashCode);

        onFilterSelect(
          reduce(
            (all, filter) => {
              if (filter === 'zeroBalance' && filters[filter]) {
                return all;
              }

              return handleFilters(all, filter, filters[filter]);
            },
            {},
            keys(filters)
          )
        );
      }
    }
  }, [filters, loaded]);

  return (
    <Row middle mb>
      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SearchTextInput
          label={capitalize(t('default:SEARCH'))}
          name="search"
          placeholder={t('default:SEARCH')}
          value={filters.search}
          onChange={handleChange('search')}
          onLoad={onLoad('search')}
          storeName="Payments"
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorHub
          name="hub"
          onChange={handleChange('hubId')}
          autoSelect
          isClearable={false}
          label={t('HUB')}
          placeholder={t('FORM.SELECT_A_HUB')}
          value={filters.hubId}
          onLoad={onLoad('hubId')}
          storeName="Payments"
          storeType="const"
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorHouse
          name="house"
          label={t('HOUSE')}
          addEmpty
          hubId={filters.hubId}
          onChange={handleChange('houseId')}
          isClearable
          ready={loaded.hubId}
          value={filters.houseId}
          onLoad={onLoad('houseId')}
          placeholder={t('FORM.SELECT_A_HOUSE')}
          storeName="Payments"
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb pt>
        <Toggle
          label={t('NEGATIVE_BALANCE')}
          input={{ checked: filters.zeroBalance, onChange: () => null }}
          onCheck={handleChange('zeroBalance')}
          onLoad={onLoad('zeroBalance')}
          name="zeroBalance"
          storeName="Payments"
        />
      </Col>
    </Row>
  );
};

PaymentFilter.propTypes = {
  onFilterSelect: func,
  t: func
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_FILTER',
    labelShow: 'default:SHOW_FILTER'
  }),
  translate(['payments', 'default'])
)(PaymentFilter);
