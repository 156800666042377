import { injectGlobal } from 'emotion';

injectGlobal`
  .inventory-details-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 8%;
    row-gap: 30px;
    margin-bottom: 20px;

    .grid-item {
      min-width: 175px;
    }

    @media (max-width: 1199px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 960px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
`;
