import { emptyArray, emptyObject } from '../utils';

export const initCollection = {
  isFetching: false,
  list: emptyArray,
  current: {
    item: emptyObject,
    isFetching: false
  }
};

export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const SET_COLLECTIONS = 'SET_COLLECTIONS';
