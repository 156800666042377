import React from 'react';
import { func, array } from 'prop-types';
import { reduxForm, Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose, filter, propOr } from 'ramda';
import { translate } from '../translate/i18n';
import { Block, Row, Button, ActionsContainer, TextInput, Text } from '../components';
import { upsertUnit } from './houses.actions';
import StyledForm from '../components/form.styles';
import { capitalize, required } from '../utils';

const unitForm = (props) => {
  // Props
  const { t, rooms, units, submitting, handleSubmit, submitSucceeded, onClose } = props;

  if (submitSucceeded) {
    onClose();
  }

  const describeUnit = (unitId) => {
    const unit = filter((u) => u.id === unitId, units)[0];
    return propOr(t('NONE'), 'name')(unit);
  };

  const roomList = rooms
    .map((room) => {
      room.formId = `rooms[${room.id}]`;
      return room;
    })
    .map((room) => (
      <tr key={room.id}>
        <td>
          <Text size="md" mr-half weight={200}>
            {t('ROOM')} {`${room.number}`}
          </Text>
        </td>
        <td>
          <Text size="md" mr-half weight={200}>
            {describeUnit(room.unitId)}
          </Text>
        </td>
        <td>
          <Field name={room.formId} component="input" type="checkbox" disabled={room.unitId} />
        </td>
      </tr>
    ));

  // Render
  return (
    <StyledForm onSubmit={handleSubmit(upsertUnit)}>
      <Block pl pr pb>
        <Field
          name="name"
          component={TextInput}
          type="text"
          label={`${capitalize(t('NAME'))}*`}
          placeholder={t('NAME')}
          validate={required}
        />
        <table>
          <thead>
            <tr>
              <td>
                <Text size="md" mr-half weight={300}>
                  {t('ROOMS')}
                </Text>
              </td>
              <td>
                <Text size="md" mr-half weight={300}>
                  {t('CURRENT_UNIT')}
                </Text>
              </td>
              <td>
                <Text size="md" mr-half weight={300}>
                  {t('ASSIGN_THIS_UNIT')}
                </Text>
              </td>
            </tr>
          </thead>
          <tbody>{roomList}</tbody>
        </table>

        <ActionsContainer>
          <Row middle right mb mt pr>
            <Button clear mr-half size="md" onClick={onClose} name="cancel" disabled={submitting}>
              {t('CANCEL')}
            </Button>

            <Button bg-primary size="md" name="save" type="submit" disabled={submitting}>
              {t('SAVE')}
            </Button>
          </Row>
        </ActionsContainer>
      </Block>
    </StyledForm>
  );
};

unitForm.propTypes = {
  t: func,
  rooms: array,
  units: array,
  onClose: func,
  ...reduxFormPropTypes
};

export default compose(
  translate('form'),
  reduxForm({ form: 'UnitForm', enableReinitialize: true, destroyOnUnmount: true })
)(unitForm);
