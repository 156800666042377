import styled from '@emotion/styled';

export const ImageContainer = styled('div')(() => ({
  overflow: 'hidden',
  marginBottom: 0,
  flex: 1,
  display: 'flex',
  justiftContent: 'center'
}));

export const StyledImage = styled('img')(() => ({
  objectFit: 'contain',
  display: 'block',
  flex: 1,
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '100%',
  margin: 'auto'
}));
