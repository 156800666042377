import { is } from 'ramda';

export const prefix = 'COHABS_';

export const genKey = (key) => `${prefix}${key}`;

export const mustDefinedAndString = (value) => {
  if (!value) throw Error('Key must be defined');
  if (!is(String, value)) throw Error('Key must be a string');
  return value;
};

export const getStorage = (key) => {
  mustDefinedAndString(key);
  const value = localStorage.getItem(genKey(key));
  return JSON.parse(value);
};

export const setStorage = (key, value) => {
  mustDefinedAndString(key);
  return localStorage.setItem(genKey(key), JSON.stringify(value));
};

export const removeStorage = (key) => {
  mustDefinedAndString(key);
  return localStorage.removeItem(genKey(key));
};
