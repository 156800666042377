import { colors } from './colors';
import { spacing } from './spacing';
import { font } from './font';
import './global';
import './table';
import './datePicker';
import './inventory';
import './discount';

export const theme = {
  colors,
  spacing,
  borderRadius: 2,
  font
};
