import React from 'react';
import { withTheme } from '@emotion/react';
import { compose, keys, intersection, ifElse, length, nth, prop } from 'ramda';
import { colors } from './colors';
import chroma from 'chroma-js';

/**
 * BACKGROUND COLOR
 */
const colorTypesEnum = {
  PRIMARY: {
    color: colors.brown[500]
  },
  PRIMARY_LIGHT: {
    color: colors.brown[400]
  },
  PRIMARY_LIGHTEST: {
    color: colors.brown[300]
  },
  SECONDARY: {
    color: colors.grey[500]
  },
  NORMAL: {
    color: colors.lightGrey[400]
  },
  DANGER: {
    color: colors.red[500]
  }
};
const makeBgColorAttributs = Object.keys(colorTypesEnum).map((type) => `bg-${type.toLowerCase()}`);

const getcolorTypeFromAttr = (attr) => colorTypesEnum[attr.substring(3, attr.length).toUpperCase()];

const findColorType = compose(
  ifElse(length, getcolorTypeFromAttr, () => undefined),
  nth(0),
  intersection(makeBgColorAttributs),
  keys
);

const bgColorWithTheme = (Wrapped) => (props) => {
  const bgColorType = findColorType(props);

  if (prop('clear', props) || prop('actionBtn', props)) {
    return <Wrapped bgColor="rgba(0,0,0,0)" {...props} />;
  }

  if (prop('bg-color', props)) {
    const color = prop('bg-color', props);
    try {
      return <Wrapped bgColor={chroma(color).hex()} {...props} />;
    } catch (err) {
      //eslint-disable-next-line
      console.info(`${prop('name', props)} NOT VALID COLOR`);
    }
  }

  if (bgColorType) {
    return <Wrapped bgColor={bgColorType.color} {...props} />;
  }

  return <Wrapped {...props} />;
};

export const bgColor = () => compose(withTheme, bgColorWithTheme);
