import React from 'react';
import { Button } from './';
import * as Feather from 'react-feather';
import { withTheme } from '@emotion/react';
import { object, string, bool } from 'prop-types';
import chroma from 'chroma-js';
import styled from '@emotion/styled';

export const actionSize = '24px';

const ButtonContainer = styled('div')(({ borderColor, needBorder, theme }) => ({
  position: 'relative',
  borderColor,
  borderWidth: 2,
  borderStyle: needBorder ? 'solid' : 'none',
  borderRadius: '50%',
  height: needBorder ? actionSize : '28px',
  width: needBorder ? actionSize : '28px',
  margin: '0 7px 0 0',
  backgroundColor: needBorder ? 'transparent' : theme.colors.lightGrey[400]
}));

const ActionButton = (props) => {
  const { icon, theme, danger, disabled } = props;
  const Icon = Feather[icon];
  const color = danger ? theme.colors.red[500] : theme.colors.brown[500];
  const disabledColor = chroma(color).alpha(0.3).hex();
  const needBorder = icon === 'Plus';

  return (
    <ButtonContainer needBorder={icon === 'Plus'} borderColor={disabled ? disabledColor : color}>
      <Icon
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        color={disabled && needBorder ? disabledColor : color}
        size={needBorder ? 18 : 20}
      />
      <Button actionBtn {...props} />
    </ButtonContainer>
  );
};

ActionButton.defaultProps = {
  danger: false,
  disabled: false
};

ActionButton.proppTypes = {
  theme: object,
  icon: string.isRequired,
  danger: bool,
  disabled: bool,
  mainIcon: bool
};

export default withTheme(ActionButton);
