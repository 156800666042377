import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as service from './paymentProfile.service';
import { GET_PAYMENT_PROFILES, GET_PAYMENT_PROFILE } from './paymentProfile.actionTypes';
import {
  setPaymentProfiles,
  createPaymentProfile,
  updatePaymentProfile,
  setPaymentProfile
} from './paymentProfile.actions';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import { types } from '../utils/sagas';

export function* getPaymentProfiles({ memberId } = {}) {
  try {
    const paymentProfiles = yield call(service.getAllProfiles, { memberId });
    yield put(setPaymentProfiles(paymentProfiles));
  } catch (err) {
    yield put(
      handleErrorMessage('GET.FAILURE', {
        type: types.PAYMENT_PROFILE.PAYMENT_PROFILE,
        error: err,
        data: {
          memberId
        }
      })
    );
  }
}

export function* getPaymentProfile({ payload } = {}) {
  try {
    const paymentProfiles = yield call(service.getProfile, payload);
    yield put(setPaymentProfile(paymentProfiles));
  } catch (err) {
    yield put(
      handleErrorMessage('GET.FAILURE', { type: types.PAYMENT_PROFILE.PAYMENT_PROFILE, error: err, data: payload })
    );
  }
}

export function* createPaymentProfiles({ payload } = {}) {
  try {
    const paymentProfiles = yield call(service.createProfile, payload);
    yield put(createPaymentProfile.success(paymentProfiles));
    yield put(handleSuccessMessage('CREATE.SUCCESS', { type: types.PAYMENT_PROFILE.PAYMENT_PROFILE }));
  } catch (err) {
    yield put(createPaymentProfile.failure(err));
    yield put(
      handleErrorMessage('CREATE.FAILURE', { type: types.PAYMENT_PROFILE.PAYMENT_PROFILE, error: err, data: payload })
    );
  }
}

export function* updatePaymentProfiles({ payload } = {}) {
  try {
    const paymentProfiles = yield call(service.updateProfile, payload);
    yield put(updatePaymentProfile.success(paymentProfiles));
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.PAYMENT_PROFILE.PAYMENT_PROFILE }));
  } catch (err) {
    yield put(updatePaymentProfile.failure(err));
    yield put(
      handleErrorMessage('UPDATE.FAILURE', { type: types.PAYMENT_PROFILE.PAYMENT_PROFILE, error: err, data: payload })
    );
  }
}

export default function* hubSaga() {
  yield all([
    takeLatest(GET_PAYMENT_PROFILES, getPaymentProfiles),
    takeLatest(createPaymentProfile.REQUEST, createPaymentProfiles),
    takeLatest(updatePaymentProfile.REQUEST, updatePaymentProfiles),
    takeLatest(GET_PAYMENT_PROFILE, getPaymentProfile)
  ]);
}
