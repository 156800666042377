/* eslint-disable no-dupe-class-members */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change, getFormValues } from 'redux-form';
import { func, bool, string, object } from 'prop-types';
import { compose, isEmpty, sortBy, prop, map, find, propEq, propOr, pathOr } from 'ramda';
import {
  TextInput,
  Container,
  Text,
  Row,
  Col,
  ActionsContainer,
  Button,
  Selector,
  DatePicker,
  ToggleForm
} from '../components';
import { translate } from '../translate/i18n';
import { getRoomsAvailability } from './applications.service';
import { emptyArray, capitalize, required, upperCase } from '../utils';
import DiscountPreview from '../discounts/discountPreview';
import { isDiscountExpired } from '../discounts/validation';

class RoomForm extends PureComponent {
  static propTypes = {
    handleSubmit: func,
    t: func,
    submitting: bool,
    onClose: func,
    applicationId: string,
    form: string,
    dispatch: func,
    application: object,
    room: object,
    values: object
  };

  constructor(props) {
    super(props);
    const { applicationId } = props;
    this.getAvailability = (params) => getRoomsAvailability(applicationId, params);
    this.state = {
      hubOptions: emptyArray,
      houseOptions: emptyArray,
      roomOptions: emptyArray,
      roomId: undefined,
      rooms: emptyArray
    };
  }

  async componentDidMount() {
    const { dispatch, room } = this.props;
    const hubs = await this.getAvailability();
    const hubOptions = hubs.map((hub) => ({ value: hub.id, label: hub.name }));
    this.setState({ hubOptions });
    if (room) {
      const discountPromise = pathOr({}, ['discount_promise'], room);
      const discountApples =
        room.application_rooms.discountApplies &&
        !isDiscountExpired(room.application_rooms.moveInDate, discountPromise.redeemBy);

      dispatch(change('addRoomForm', 'rent', room.application_rooms.rent));
      dispatch(change('addRoomForm', 'discountApplies', discountApples));
      dispatch(change('addRoomForm', 'roomId', room.id));
      !!room.application_rooms.moveInDate &&
        dispatch(change('addRoomForm', 'moveInDate', room.application_rooms.moveInDate));
    }
  }

  handleHubChange = this.handleHubChange.bind(this);
  async handleHubChange(hubId) {
    const houses = await this.getAvailability({ hubId });
    const houseOptions = houses.map((house) => ({ value: house.id, label: house.name }));
    this.setState({ houseOptions, roomOptions: emptyArray, hubId });

    const { dispatch } = this.props;
    dispatch(change('addRoomForm', 'roomId', 8));
  }

  handleHouseChange = this.handleHouseChange.bind(this);
  async handleHouseChange(houseId) {
    const rooms = await this.getAvailability({ houseId });
    const roomOptions = compose(
      sortBy(prop('label')),
      map((room) => ({ label: room.number, value: room.id }))
    )(rooms);

    this.setState({ roomOptions, rooms, houseId });
  }

  handleRoomChange = this.handleRoomChange.bind(this);
  async handleRoomChange(roomId) {
    this.setState({ roomId });
    const { rooms } = this.state;
    const { dispatch, application } = this.props;
    const currentRoom = find(propEq('id', roomId))(rooms);
    dispatch(change('addRoomForm', 'roomId', roomId));
    dispatch(change('addRoomForm', 'rent', propOr('', 'rent', currentRoom)));
    if (currentRoom.hasOwnProperty('availableOn')) {
      const availableOnDate = new Date(currentRoom.availableOn);
      const applicationMoveInDate = new Date(application.moveinDate);
      if (availableOnDate > applicationMoveInDate) {
        dispatch(change('addRoomForm', 'moveInDate', propOr('', 'availableOn', currentRoom)));
      } else if (availableOnDate < applicationMoveInDate) {
        dispatch(change('addRoomForm', 'moveInDate', propOr('', 'moveinDate', application)));
      } else {
        dispatch(change('addRoomForm', 'moveInDate', propOr('', 'availableOn', currentRoom)));
      }
    } else {
      dispatch(change('addRoomForm', 'moveInDate', propOr('', 'moveinDate', application)));
    }
  }

  render() {
    const { handleSubmit, t, submitting, onClose, room, application, values } = this.props;
    const { hubOptions, houseOptions, roomOptions, hubId, houseId, roomId } = this.state;
    const timezone = room ? room.timezone : '';
    const renderDiscountForm = room && room.discount_promise && values;

    const isActiveDiscount = () => {
      if (!room || !values) {
        return null;
      }

      const discountApplies = pathOr(true, ['discountApplies'], values);

      if (!discountApplies) {
        return 'inactive';
      }

      const redeemBy = pathOr(null, ['discount_promise', 'redeemBy'], room);
      const moveInDate = pathOr(null, ['application_rooms', 'moveInDate'], room);

      if (!redeemBy && !moveInDate) {
        return 'inactive';
      }

      if (isDiscountExpired(moveInDate, redeemBy)) {
        return 'inactive';
      }

      return 'active';
    };

    if (isEmpty(hubOptions)) {
      return (
        <Container center p>
          <Text noWrap={false}>{t('ALL_AVAILABLE_ROOM_SUGGESTED')}</Text>
        </Container>
      );
    }

    return (
      <Container column p>
        <form onSubmit={handleSubmit}>
          {!room && (
            <>
              <Row center>
                <Col lg={10} mr mb>
                  <Selector
                    name="hub"
                    value={hubId}
                    isDisabled={!hubOptions.length}
                    options={hubOptions}
                    onChange={this.handleHubChange}
                    label={t('form.HUB')}
                    placeholder={t('SELECT_HUB')}
                    isInForm
                  />
                </Col>
              </Row>

              <Row center>
                <Col lg={10} mr mb>
                  <Selector
                    disable
                    name="house"
                    value={houseId}
                    isDisabled={!houseOptions.length}
                    options={houseOptions}
                    onChange={this.handleHouseChange}
                    label={t('form.HOUSE')}
                    placeholder={t('SELECT_HOUSE')}
                    isInForm
                  />
                </Col>
              </Row>

              <Row center>
                <Col lg={10} mr mb>
                  <Selector
                    name="room"
                    value={roomId}
                    isDisabled={!roomOptions.length}
                    options={roomOptions}
                    onChange={this.handleRoomChange}
                    label={t('form.ROOM_NUMBER')}
                    placeholder={t('SELECT_ROOM')}
                    isInForm
                  />
                </Col>
              </Row>
            </>
          )}

          <Row center>
            <Col lg={10} mr>
              <Field validate={required} name="rent" type="number" label={`${t('form.RENT')}*`} component={TextInput} />
            </Col>
          </Row>

          <Row center>
            <Col lg={10} mr>
              <Field
                validate={required}
                label={`${t('form.MOVE_IN_DATE')}*`}
                name="moveInDate"
                component={DatePicker}
                timezone={timezone}
              />
            </Col>
          </Row>

          {renderDiscountForm && (
            <Row center>
              <Col lg={10} mr mt>
                <div className={`discount-details ${isActiveDiscount()}`}>
                  <Field
                    disabled={isDiscountExpired(room.application_rooms.moveInDate, room.discount_promise.redeemBy)}
                    component={ToggleForm}
                    label={upperCase(t('discounts:TITLE'))}
                    name="discountApplies"
                    type="checkbox"
                  />
                  <DiscountPreview
                    discount={pathOr(null, ['discount_promise'], room)}
                    enabled={values.discountApplies}
                    currencySymbol={pathOr('', ['hub', 'currency', 'symbol'], application)}
                    incomingPrice={values.rent}
                    moveInDate={pathOr(null, ['application_rooms', 'moveInDate'], room)}
                    timezone={application.timezone}
                  />
                </div>
              </Col>
            </Row>
          )}

          <ActionsContainer>
            <Row middle p-half right>
              <Button clear size="md" mr-half type="button" onClick={onClose} name="cancel" disabled={submitting}>
                {capitalize(t('default:CANCEL'))}
              </Button>
              <Button bg-primary size="md" name="save" type="submit" disabled={submitting}>
                {capitalize(t('default:SAVE'))}
              </Button>
            </Row>
          </ActionsContainer>
        </form>
      </Container>
    );
  }
}
export default compose(
  reduxForm({
    form: 'addRoomForm'
  }),
  connect((state) => ({ values: getFormValues('addRoomForm')(state) })),
  translate('applications')
)(RoomForm);
