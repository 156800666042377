import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { setApiUrl, setHandleErrorFunc } from '@cohabs/bo-api';
import { handleErrors } from './utils/errors';

import { createBrowserHistory as createHistory } from 'history';
import { Router } from 'react-router-dom';

import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';

import { segmentPage } from './segment/segment.actions';
import { initCurrentUser } from './user/user.actions';

import { Provider } from 'react-redux';
import useSegmentScript from './segment/useSegmentScript';
import { getApiUrl } from './store/apiUrl';
import configureStore from './store/configureStore';

import { createRoot } from 'react-dom/client';

const history = createHistory();
const store = configureStore();
const endPoint = getApiUrl() || process.env.REACT_APP_API;

setApiUrl(endPoint);
setHandleErrorFunc(handleErrors);
store.dispatch(segmentPage(history));
store.dispatch(initCurrentUser());

const Init = () => {
  useSegmentScript();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Init />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
