import React, { Fragment, useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose, path, pathOr } from 'ramda';
import { withRouter } from 'react-router-dom';

import { translate } from '../../translate/i18n';
import { Container, Block, Col, Back, Row, Text, Loader } from '../../components';
import { emptyObject, isNotEmptyOrNil } from '../../utils';
import { getPack } from '../packs.actions';
import { getPackLocation } from './packLocations.actions';
import PackLocationForm from './PackLocation.form';

/**
 * Populate initial form values
 * @param {*} isNew
 * @param {*} packId
 * @param {*} hubId
 * @param {*} packLocation
 * @param {*} pack
 */
const initialValuesFrom = (isNew, packId, hubId, packLocation, pack) => {
  return isNew
    ? {
        isNew,
        packId,
        pack
      }
    : {
        isNew,
        packId,
        pack: packLocation.pack,
        hub: packLocation.hub,
        hubId,
        ...packLocation
      };
};

/**
 * Pack page details or new
 * @param {*} props
 */
const Pack = ({ history, match, t, pack, packLocation, getPack, getPackLocation, isFetching }) => {
  // variables
  const packId = path(['params', 'packId'], match);
  const hubId = path(['params', 'hubId'], match);
  const isNew = hubId === 'create';

  const initialValues = initialValuesFrom(isNew, packId, hubId, packLocation, pack);
  const showForm = isNotEmptyOrNil(packLocation) || isNotEmptyOrNil(pack);

  // Hooks
  useEffect(() => {
    if (isNew) {
      getPack(packId);
    } else {
      getPackLocation(packId, hubId);
    }
  }, []);

  // Render
  return (
    <Fragment>
      {isFetching ? (
        <Container full center middle>
          <Loader />
        </Container>
      ) : (
        <Block pt pl pr>
          <Row mb-double>
            <Col lg={6} left>
              <Back to={`/packs/${packId}`} label={t('BACK_TO_PACK_DETAILS')} />
            </Col>
          </Row>

          <Row mb-double>
            <Text size="xl" weight={200}>
              {isNew ? t('NEW_PACK_LOCATION') : t('PACK_LOCATION_DETAILS')}
            </Text>
          </Row>

          {showForm && <PackLocationForm onCancel={() => history.goBack()} initialValues={initialValues} />}
        </Block>
      )}
    </Fragment>
  );
};

Pack.propTypes = {
  t: func,
  match: object,
  pack: object,
  isFetching: bool,
  getPack: func,
  getPackLocation: func,
  history: object,
  packLocation: object
};

export default compose(
  withRouter,
  translate('packs'),
  connect(
    (state) => ({
      isFetching:
        pathOr(false, ['packs', 'current', 'isFetching'], state) ||
        pathOr(false, ['packLocations', 'current', 'isFetching'], state),
      pack: pathOr(emptyObject, ['packs', 'current', 'data'], state),
      packLocation: pathOr(emptyObject, ['packLocations', 'current', 'data'], state)
    }),
    { getPack, getPackLocation }
  )
)(Pack);
