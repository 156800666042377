import React, { useState, useEffect } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { equals, compose, pathOr, isEmpty } from 'ramda';

import { emptyArray, sortByCaseInsensitive } from '../../utils';
import { connect } from 'react-redux';
import { getIncidentStatus } from '../../enum/enum.actions';
import { storableSelector } from '../storableDecorator';
import Selector from './Selector';

const SelectorIncidentStatus = ({
  name,
  isMulti,
  label,
  value,
  isInForm,
  list,
  getIncidentStatus,
  isDisabled,
  isClearable,
  placeholder,
  onChange,
  valueControl
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    getIncidentStatus();
  }, []);

  useEffect(() => {
    const sorted = sortByCaseInsensitive('label')(
      list.map((option) => ({
        label: option,
        value: option
      }))
    );
    if (!equals(sorted, options)) {
      setOptions(sorted);
    }
  }, [list]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorIncidentStatus.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  list: array.isRequired,
  isFetching: bool.isRequired,
  getIncidentStatus: func.isRequired
};

SelectorIncidentStatus.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null
};

export default compose(
  storableSelector,
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'incidentStatus', 'list'], state),
      isFetching: pathOr(false, ['enums', 'incidentStatus', 'isFetching'], state)
    }),
    { getIncidentStatus }
  )
)(SelectorIncidentStatus);
