import React, { Fragment, useState } from 'react';
import { func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button, ConfirmModal, Container, Text, Row } from '../components';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { translate } from '../translate/i18n';
import { deletePaymentProfileMethod } from './paymentProfileMethod.actions';

import './stripe.css';

const PaymentProfileMethodCard = ({
  member,
  currentHub,
  currentProfile,
  hubMethod,
  deletePaymentProfileMethodCall,
  t
}) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const confirm = () => {
    deletePaymentProfileMethodCall({
      userId: member.id,
      hubId: currentHub.id,
      type: hubMethod.payment_method.type,
      paymentProfileId: currentProfile.id
    });

    setShowConfirm(false);
  };

  if (hubMethod.required) {
    return null;
  }

  return (
    <Fragment>
      <ConfirmModal
        show={showConfirm}
        onClose={() => setShowConfirm(false)}
        content={() => (
          <Row center>
            <Container column>
              <Text weight={300} grey={500}>
                {t('member:CONFIRM_SOURCE_SHOULD_BE_DELETED')}
              </Text>
            </Container>
          </Row>
        )}
        onConfirm={confirm}
      />
      <Button size="md" name="btn-save" bg-primary mr-half onClick={() => setShowConfirm(true)}>
        {t('default:DELETE')}
      </Button>
    </Fragment>
  );
};

PaymentProfileMethodCard.propTypes = {
  t: func,
  member: object.isRequired,
  currentHub: object.isRequired,
  currentProfile: object,
  hubMethod: object,
  deletePaymentProfileMethodCall: func
};

export default compose(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        deletePaymentProfileMethodCall: deletePaymentProfileMethod.request
      },
      dispatch
    )
  ),
  translate()
)(PaymentProfileMethodCard);
