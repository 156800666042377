import React, { useState, useEffect, Fragment } from 'react';
import { compose, pathOr, propOr, prop, path } from 'ramda';
import { connect } from 'react-redux';
import { translate } from '../translate/i18n';
import { object, func, array, bool } from 'prop-types';
import {
  Block,
  Text,
  Container,
  Row,
  Col,
  ActionButton,
  Modal,
  TextInput,
  TextAreaForm,
  ActionsContainer,
  Button,
  Table,
  ActionSecondaryButton
} from '../components';
import { emptyObject, capitalize, emptyArray, required } from '../utils';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, initialize } from 'redux-form';
import { upsertCategory, deleteCategories } from './houses.actions';

const UpsertCategory = reduxForm({ form: 'CategoryForm' })((props) => {
  const { t, handleSubmit, onClose, submitting, submitSucceeded } = props;
  if (submitSucceeded) onClose();

  return (
    <form onSubmit={handleSubmit(upsertCategory)} style={{ width: '100%' }}>
      <Container column pr pl-half>
        <Col lg={12}>
          <Field name="type" label={t('RELATED_SECTION')} validate={required} component={TextInput} disabled />
        </Col>
        <Col lg={12}>
          <Field name="title" label={t('form:NAME')} validate={required} component={TextInput} />
        </Col>
        <Col lg={12}>
          <Field name="text" label={t('form:DESCRIPTION')} validate={required} component={TextAreaForm} />
        </Col>
      </Container>
      <ActionsContainer>
        <Row middle right mb mt pr>
          <Button clear mr-half size="md" onClick={onClose} name="cancel" disabled={submitting}>
            {capitalize(t('default:CANCEL'))}
          </Button>
          <Button bg-primary size="md" name="save" type="submit" disabled={submitting}>
            {capitalize(t('default:SAVE'))}
          </Button>
        </Row>
      </ActionsContainer>
    </form>
  );
});

const dataDef = (onSelect, showCheckboxes = false, showSelectAll = false) => [
  {
    headerName: '',
    checkboxSelection: showCheckboxes,
    headerCheckboxSelection: showSelectAll,
    width: 5
  },
  {
    headerName: '',
    field: 'title',
    width: 40,
    onCellClicked: (event) => onSelect(prop('data', event))
  },
  {
    headerName: '',
    field: 'text',
    onCellClicked: (event) => onSelect(prop('data', event))
  }
];

const Sections = ({ list = [], showSelectAll, showCheckboxes, onSelectionChanged, onSelect }) => {
  if (!list.length) return null;

  const subs = {
    columnDefs: dataDef(onSelect, showCheckboxes, showSelectAll && list.length > 1),
    rowData: list
  };

  return (
    <Table
      data={subs}
      hasPagination={false}
      onSelectionChanged={(event) => onSelectionChanged(event.api.getSelectedRows())}
    />
  );
};
Sections.propTypes = {
  list: array,
  showSelectAll: bool,
  showCheckboxes: bool,
  onSelectionChanged: func,
  onSelect: func
};

const HouseSections = (props) => {
  const [show, setShowModal] = useState(false);
  const [currentSection, setCurrentSection] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(emptyObject);
  const [sectionsKeys, setSectionsKeys] = useState([]);

  const { t, house, dispatch } = props;
  const sections = pathOr(emptyObject, ['house', 'practicalInformations'], props);

  useEffect(() => {
    setSectionsKeys(Object.keys(pathOr(emptyObject, ['house', 'practicalInformations'], props)));
  }, []);

  const handleShowModal = (show, currentSection) => () => {
    setShowModal(show);
    setCurrentSection(currentSection);
  };

  const handleUpdate = (section) => (data) => {
    const formData = {
      type: section,
      id: prop('id', data),
      houseId: prop('id', house),
      title: prop('title', data),
      text: prop('text', data)
    };
    setShowModal(true);
    dispatch(initialize('CategoryForm', formData));
  };

  const handleSelectionCategories = (section) => (list) => {
    setSelectedCategories({ ...selectedCategories, [section]: list });
  };

  const isTrashSection = (section) => section === 'trash';

  return (
    <Fragment>
      <Block pl>
        {sectionsKeys.map((section) => (
          <Container key={section} column>
            <Text size="lg" weight={200} pt pb-half>
              {t(section)}
            </Text>

            {!isTrashSection(section) && (
              <Row middle pl-double>
                <Text grey={500} pr-half weight={300}>
                  {t('SUB_SECTION')}
                </Text>

                {!path([section, 'length'], selectedCategories) && (
                  <ActionButton name={`add-${section}-category`} icon="Plus" onClick={handleShowModal(true, section)} />
                )}

                {!!path([section, 'length'], selectedCategories) && (
                  <ActionSecondaryButton
                    name={`remove-${section}-category`}
                    icon="Trash2"
                    onClick={() => {
                      dispatch(deleteCategories({ section, list: selectedCategories[section] }));
                      setSelectedCategories({ ...selectedCategories, [section]: emptyObject });
                    }}
                    danger
                  />
                )}
              </Row>
            )}

            <Container column pb>
              <Sections
                t={t}
                showSelectAll={!isTrashSection(section)}
                showCheckboxes={!isTrashSection(section)}
                list={propOr(emptyArray, section, sections)}
                onSelect={handleUpdate(section)}
                onSelectionChanged={handleSelectionCategories(section)}
              />
            </Container>
          </Container>
        ))}
      </Block>

      <Modal
        show={show}
        title={t('ADD_HOUSE_SUB_SECTION')}
        onClose={handleShowModal(false)}
        content={() => (
          <Container p>
            <UpsertCategory
              t={t}
              initialValues={{ type: currentSection, houseId: prop('id', house) }}
              onClose={handleShowModal(false)}
            />
          </Container>
        )}
      />
    </Fragment>
  );
};

HouseSections.propTypes = {
  dispatch: func.isRequired,
  house: object,
  t: func
};

export default compose(
  withRouter,
  translate('house'),
  connect((state) => ({
    house: pathOr(emptyObject, ['houses', 'current', 'data'], state)
  }))
)(HouseSections);
