import React, { useState } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose, path } from 'ramda';
import { translate } from '../translate/i18n';
import StyledForm from '../components/form.styles';
import { Container, TextAreaForm, Row, Button, CustomLink, ConfirmModal } from '../components';
import { Phone, Mail, Bell, Check } from 'react-feather';
import { upsertComment, getPayments } from './payments.actions';
import { UserInfo } from './followup.actions';
import { formatPhone, upperCase } from '../utils';
import { types } from '../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import { remindPayment, markAsPaid } from './payments.service';

const PaymentDetailsForm = (props) => {
  // Props
  const {
    t,
    handleSubmit,
    submitSucceeded,
    submitting,
    onCancel,
    payment,
    initialValues,
    handleErrorMessage,
    handleSuccessMessage,
    getPayments
  } = props;

  if (submitSucceeded) {
    onCancel();
  }

  // State
  const [showModal, toggleModal] = useState(false);

  // Vars
  const paymentType = upperCase(initialValues.paymentType);
  const paymentId = path(['payment', 'id'], payment);

  // Functions
  const sendReminder = () => {
    remindPayment(paymentId)
      .then(() => {
        handleSuccessMessage('REMINDER.SUCCESS', { type: types.PAYMENT[paymentType] });
      })
      .catch((err) => {
        handleErrorMessage('REMINDER.FAILURE', { type: types.PAYMENT[paymentType], error: err, data: { paymentId } });
      });

    onCancel();
  };

  const handleConfirmMarkAsPaid = async () => {
    await markAsPaid(payment.payment.id);
    getPayments({
      paymentType: initialValues.paymentType,
      userId: payment.payment.userId,
      hubId: payment.payment.hubId
    });
    toggleModal(false);
    onCancel();
  };

  // Render
  return (
    <Container column p>
      <UserInfo t={t} member={path(['payment', 'user'], payment)} room={path(['payment', 'lease', 'room'], payment)} />

      <Row mb>
        <CustomLink
          mr-half
          size="md"
          name="callMember"
          bg-secondary
          href={`tel:${formatPhone(
            path(['payment', 'user', 'phoneNumber'], payment),
            path(['payment', 'user', 'phonePrefix', 'prefix'], payment)
          )}`}>
          <Phone size={14} /> {t('CALL_MEMBER')}
        </CustomLink>

        <CustomLink
          mr-half
          size="md"
          name="emailMember"
          bg-secondary
          href={`mailto:${path(['payment', 'user', 'email'], payment)}`}>
          <Mail size={14} /> {t('EMAIL_MEMBER')}
        </CustomLink>

        <Button mr-half size="md" name="sendReminder" bg-secondary onClick={sendReminder}>
          <Bell size={14} /> {t('SEND_REMINDER')}
        </Button>

        {path(['payment', 'amount'], payment) > path(['payment', 'paid'], payment) && (
          <Button size="md" name="sendReminder" bg-secondary onClick={() => toggleModal(true)}>
            <Check size={14} /> {t('MARK_AS_PAID')}
          </Button>
        )}
      </Row>

      <StyledForm onSubmit={handleSubmit(upsertComment)}>
        <Field name="comment" component={TextAreaForm} label={`${t('COMMENT')}`} defaultValue={''} />

        <Row mb right>
          <Button clear size="md" name="cancel" disabled={submitting} onClick={onCancel}>
            {t('CANCEL')}
          </Button>
          <Button bg-primary size="md" name="submit" type="submit" disabled={submitting}>
            {t('SAVE')}
          </Button>
        </Row>
      </StyledForm>

      <ConfirmModal
        show={showModal}
        onClose={() => toggleModal(false)}
        content={() => t('MARK_AS_PAID_CONFIRM')}
        onConfirm={handleConfirmMarkAsPaid}
      />
    </Container>
  );
};

PaymentDetailsForm.propTypes = {
  t: func,
  handleSubmit: func,
  onCancel: func,
  submitting: bool,
  payment: object,
  ...reduxFormPropTypes
};

export default compose(
  reduxForm({ form: 'PaymentForm' }),
  translate('payments'),
  connect(null, { handleErrorMessage, handleSuccessMessage, getPayments })
)(PaymentDetailsForm);
