import { post, postFile } from '@cohabs/bo-api';

export const uploadFile = (file, oldPath) => {
  const formData = new FormData();
  formData.append('file', file);
  if (oldPath) formData.append('oldPath', oldPath);
  return postFile('/upload', formData);
};

export const uploadVideoFile = (file, oldPath) => {
  const formData = new FormData();
  formData.append('file', file);
  if (oldPath) formData.append('oldPath', oldPath);
  return postFile('/upload/video', formData);
};

export const deleteRemoteFile = (fileUrl) => post('/upload/delete', { path: fileUrl });
