import React from 'react';
import { Button } from './';
import * as Feather from 'react-feather';
import { withTheme } from '@emotion/react';
import { object, string, bool } from 'prop-types';
import chroma from 'chroma-js';
import styled from '@emotion/styled';

export const actionSize = '24px';

const ButtonContainer = styled('div')(({ borderColor, needBorder, theme, danger, margin }) => ({
  position: 'relative',
  borderColor,
  borderWidth: 2,
  borderStyle: needBorder ? 'solid' : 'none',
  borderRadius: '50%',
  height: needBorder ? actionSize : '28px',
  width: needBorder ? actionSize : '28px',
  margin: !margin ? '0 7px 0 0' : margin,
  backgroundColor: danger ? theme.colors.red[500] : theme.colors.lightGrey[400]
}));

const ActionSecondaryButton = (props) => {
  const { icon, theme, danger, disabled, margin } = props;
  const Icon = Feather[icon];
  const color = danger ? theme.colors.white[500] : theme.colors.grey[500];
  const disabledColor = chroma(color).alpha(0.3).hex();
  const needBorder = icon === 'Plus';

  return (
    <ButtonContainer
      needBorder={icon === 'Plus'}
      borderColor={disabled ? disabledColor : color}
      margin={margin}
      danger={danger}>
      <Icon
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        color={disabled && needBorder ? disabledColor : color}
        size={18}
      />
      <Button actionBtn {...props} />
    </ButtonContainer>
  );
};

ActionSecondaryButton.defaultProps = {
  danger: false,
  disabled: false
};

ActionSecondaryButton.proppTypes = {
  theme: object,
  icon: string.isRequired,
  danger: bool,
  disabled: bool,
  mainIcon: bool,
  margin: string
};

export default withTheme(ActionSecondaryButton);
