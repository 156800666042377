export const GET_GENDER_LIST = 'GET_GENDER_LIST';
export const GET_GENDER_LIST_SUCESS = 'GET_GENDER_LIST_SUCESS';
export const GET_GENDER_LIST_FAIL = 'GET_GENDER_LIST_FAIL';

export const GET_GRADES = 'GET_GRADES';
export const GET_GRADES_SUCCESS = 'GET_GRADES_SUCCESS';
export const GET_GRADES_FAIL = 'GET_GRADES_FAIL';

export const GET_DEFECTS = 'GET_DEFECTS';
export const GET_DEFECTS_SUCCESS = 'GET_DEFECTS_SUCCESS';
export const GET_DEFECTS_FAIL = 'GET_DEFECTS_FAIL';

export const GET_NATIONALITIES_LIST = 'GET_NATIONALITIES_LIST';
export const GET_NATIONALITIES_LIST_SUCESS = 'GET_NATIONALITIES_LIST_SUCESS';
export const GET_NATIONALITIES_LIST_FAIL = 'GET_NATIONALITIES_LIST_FAIL';

export const GET_PAYMENT_METHOD_LIST = 'GET_PAYMENT_METHOD_LIST';
export const GET_PAYMENT_METHOD_LIST_SUCESS = 'GET_PAYMENT_METHOD_LIST_SUCESS';
export const GET_PAYMENT_METHOD_LIST_FAIL = 'GET_PAYMENT_METHOD_LIST_FAIL';

export const GET_APPLICATION_STATUS = 'GET_APPLICATION_STATUS';
export const GET_APPLICATION_STATUS_SUCCESS = 'GET_APPLICATION_STATUS_SUCCESS';
export const GET_APPLICATION_STATUS_FAIL = 'GET_APPLICATION_STATUS_FAIL';

export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAIL = 'GET_CITIES_FAIL';

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAIL = 'GET_CURRENCIES_FAIL';

export const GET_TIPS_CATEGORIES = 'GET_TIPS_CATEGORIES';
export const GET_TIPS_CATEGORIES_SUCCESS = 'GET_TIPS_CATEGORIES_SUCCESS';
export const GET_TIPS_CATEGORIES_FAIL = 'GET_TIPS_CATEGORIES_FAIL';

export const GET_INCIDENT_PRIORITIES = 'GET_INCIDENT_PRIORITIES';
export const GET_INCIDENT_PRIORITIES_SUCCESS = 'GET_INCIDENT_PRIORITIES_SUCCESS';
export const GET_INCIDENT_PRIORITIES_FAIL = 'GET_INCIDENT_PRIORITIES_FAIL';

export const GET_INCIDENT_STATUS = 'GET_INCIDENT_STATUS';
export const GET_INCIDENT_STATUS_SUCCESS = 'GET_INCIDENT_STATUS_SUCCESS';
export const GET_INCIDENT_STATUS_FAIL = 'GET_INCIDENT_STATUS_FAIL';

export const GET_INVENTORY_STATUS = 'GET_INVENTORY_STATUS';
export const GET_INVENTORY_STATUS_SUCCESS = 'GET_INVENTORY_STATUS_SUCCESS';
export const GET_INVENTORY_STATUS_FAIL = 'GET_INVENTORY_STATUS_FAIL';

export const GET_TIPS_PRIORITIES = 'GET_TIPS_PRIORITIES';
export const GET_TIPS_PRIORITIES_SUCCESS = 'GET_TIPS_PRIORITIES_SUCCESS';
export const GET_TIPS_PRIORITIES_FAIL = 'GET_TIPS_PRIORITIES_FAIL';

export const GET_FEEDS_CATEGORIES = 'GET_FEEDS_CATEGORIES';
export const GET_FEEDS_CATEGORIES_SUCCESS = 'GET_FEEDS_CATEGORIES_SUCCESS';
export const GET_FEEDS_CATEGORIES_FAIL = 'GET_FEEDS_CATEGORIES_FAIL';

export const GET_FAQ_CATEGORIES = 'GET_FAQ_CATEGORIES';
export const GET_FAQ_CATEGORIES_SUCCESS = 'GET_FAQ_CATEGORIES_SUCCESS';
export const GET_FAQ_CATEGORIES_FAIL = 'GET_FAQ_CATEGORIES_FAIL';

export const GET_ROOM_CATEGORIES = 'GET_ROOM_CATEGORIES';
export const GET_ROOM_CATEGORIES_SUCCESS = 'GET_ROOM_CATEGORIES_SUCCESS';
export const GET_ROOM_CATEGORIES_FAIL = 'GET_ROOM_CATEGORIES_FAIL';

export const GET_APPLICATION_SWITCH_TYPES = 'GET_APPLICATION_SWITCH_TYPES';
export const GET_APPLICATION_SWITCH_TYPES_SUCCESS = 'GET_APPLICATION_SWITCH_TYPES_SUCCESS';
export const GET_APPLICATION_SWITCH_TYPES_FAIL = 'GET_APPLICATION_SWITCH_TYPES_FAIL';

export const GET_FAQ_SUBCATEGORIES = 'GET_FAQ_SUBCATEGORIES';
export const GET_FAQ_SUBCATEGORIES_SUCCESS = 'GET_FAQ_SUBCATEGORIES_SUCCESS';
export const GET_FAQ_SUBCATEGORIES_FAIL = 'GET_FAQ_SUBCATEGORIES_FAIL';

export const getGenderList = () => ({
  type: GET_GENDER_LIST
});

export const getGenderListSuccess = (list) => ({
  type: GET_GENDER_LIST_SUCESS,
  payload: list
});

export const getGenderListFail = (err) => ({
  type: GET_GENDER_LIST_FAIL,
  payload: err
});

export const getPaymentMethodList = () => ({
  type: GET_PAYMENT_METHOD_LIST
});

export const getPaymentMethodListSuccess = (list) => ({
  type: GET_PAYMENT_METHOD_LIST_SUCESS,
  payload: list
});

export const getPaymentMethodListFail = (err) => ({
  type: GET_PAYMENT_METHOD_LIST_FAIL,
  payload: err
});

export const getGrades = () => ({
  type: GET_GRADES
});

export const getGradesSuccess = (list) => ({
  type: GET_GRADES_SUCCESS,
  payload: list
});

export const getGradesFail = (err) => ({
  type: GET_GRADES_FAIL,
  payload: err
});

export const getDefects = () => ({
  type: GET_DEFECTS
});

export const getDefectsSuccess = (list) => ({
  type: GET_DEFECTS_SUCCESS,
  payload: list
});

export const getDefectsFail = (err) => ({
  type: GET_DEFECTS_FAIL,
  payload: err
});

export const getInventoryStatus = () => ({
  type: GET_INVENTORY_STATUS
});

export const getInventoryStatusSuccess = (list) => ({
  type: GET_INVENTORY_STATUS_FAIL,
  payload: list
});

export const getInventoryStatusFail = (err) => ({
  type: GET_INCIDENT_STATUS_FAIL,
  payload: err
});

export const getIncidentStatus = () => ({
  type: GET_INCIDENT_STATUS
});

export const getIncidentStatusSuccess = (list) => ({
  type: GET_INCIDENT_STATUS_SUCCESS,
  payload: list
});

export const getIncidentStatusFail = (err) => ({
  type: GET_INCIDENT_STATUS_FAIL,
  payload: err
});

export const getIncidentPriorities = () => ({
  type: GET_INCIDENT_PRIORITIES
});

export const getIncidentPrioritiesSuccess = (list) => ({
  type: GET_INCIDENT_PRIORITIES_SUCCESS,
  payload: list
});

export const getIncidentPrioritiesFail = (err) => ({
  type: GET_INCIDENT_PRIORITIES_FAIL,
  payload: err
});

export const getCountries = (search) => ({
  type: GET_NATIONALITIES_LIST,
  payload: search
});

export const getCountriesSuccess = (list) => ({
  type: GET_NATIONALITIES_LIST_SUCESS,
  payload: list
});

export const getCountriesFail = (err) => ({
  type: GET_NATIONALITIES_LIST_FAIL,
  payload: err
});

export const getApplicationStatus = () => ({
  type: GET_APPLICATION_STATUS
});

export const getApplicationStatusSuccess = (list) => ({
  type: GET_APPLICATION_STATUS_SUCCESS,
  payload: list
});

export const getApplicationStatusFail = (err) => ({
  type: GET_APPLICATION_STATUS_FAIL,
  payload: err
});

export const getRole = () => ({
  type: GET_ROLE
});

export const getRoleSuccess = (roles) => ({
  type: GET_ROLE_SUCCESS,
  payload: roles
});
export const getRoleFail = (err) => ({
  type: GET_ROLE_FAIL,
  payload: err
});

export const getCities = () => ({
  type: GET_CITIES
});

export const getCitiesSuccess = (cities) => ({
  type: GET_CITIES_SUCCESS,
  payload: cities
});
export const getCitiesFail = (err) => ({
  type: GET_CITIES_FAIL,
  payload: err
});

export const getCurrencies = () => ({
  type: GET_CURRENCIES
});

export const getCurrenciesSuccess = (cities) => ({
  type: GET_CURRENCIES_SUCCESS,
  payload: cities
});
export const getCurrenciesFail = (err) => ({
  type: GET_CURRENCIES_FAIL,
  payload: err
});

export const getTipsCategories = () => ({
  type: GET_TIPS_CATEGORIES
});

export const getTipsCategoriesSuccess = (categories) => ({
  type: GET_TIPS_CATEGORIES_SUCCESS,
  payload: categories
});

export const getTipsCategoriesFail = (err) => ({
  type: GET_TIPS_CATEGORIES_FAIL,
  payload: err
});

export const getTipsPriorities = () => ({
  type: GET_TIPS_PRIORITIES
});

export const getTipsPrioritiesSuccess = (priorities) => ({
  type: GET_TIPS_PRIORITIES_SUCCESS,
  payload: priorities
});

export const getTipsPrioritiesFail = (err) => ({
  type: GET_TIPS_PRIORITIES_FAIL,
  payload: err
});

export const getFeedsCategories = () => ({
  type: GET_FEEDS_CATEGORIES
});

export const getFeedsCategoriesSuccess = (categories) => ({
  type: GET_FEEDS_CATEGORIES_SUCCESS,
  payload: categories
});

export const getFeedsCategoriesFail = (err) => ({
  type: GET_FEEDS_CATEGORIES_FAIL,
  payload: err
});

export const getFaqCategories = () => ({
  type: GET_FAQ_CATEGORIES
});

export const getFaqCategoriesSuccess = (categories) => ({
  type: GET_FAQ_CATEGORIES_SUCCESS,
  payload: categories
});

export const getFaqCategoriesFail = (err) => ({
  type: GET_FAQ_CATEGORIES_FAIL,
  payload: err
});

export const getFaqSubcategories = () => ({
  type: GET_FAQ_SUBCATEGORIES
});

export const getFaqSubcategoriesSuccess = (categories) => ({
  type: GET_FAQ_SUBCATEGORIES_SUCCESS,
  payload: categories
});

export const getFaqSubcategoriesFail = (err) => ({
  type: GET_FAQ_SUBCATEGORIES_FAIL,
  payload: err
});

export const getRoomCategories = () => ({
  type: GET_ROOM_CATEGORIES
});

export const getRoomCategoriesSuccess = (categories) => ({
  type: GET_ROOM_CATEGORIES_SUCCESS,
  payload: categories
});

export const getRoomCategoriesFail = (err) => ({
  type: GET_ROOM_CATEGORIES_FAIL,
  payload: err
});

export const getApplicationSwitchTypes = () => ({
  type: GET_APPLICATION_SWITCH_TYPES
});

export const getApplicationSwitchTypesSuccess = (switchTypes) => ({
  type: GET_APPLICATION_SWITCH_TYPES_SUCCESS,
  payload: switchTypes
});

export const getApplicationSwitchTypesFail = (err) => ({
  type: GET_APPLICATION_SWITCH_TYPES_FAIL,
  payload: err
});
