import React from 'react';
import { object, func, string } from 'prop-types';
import { Phone } from '..';
import { InputContainer, StyledLabel, ErrorText } from './TextInput.styles';
import { formatInputProps } from './decorators';
import { compose } from 'ramda';
import { translate } from '../../translate/i18n';

const PhoneForm = (props) => {
  const { meta, t, label, input, inputProps } = props;
  const { touched, error, active } = meta;

  const { value, onChange } = inputProps;

  return (
    <InputContainer>
      <StyledLabel active={active}>{label}</StyledLabel>
      <Phone active={active} value={value} onChange={onChange} onFocus={(e) => input.onFocus(e)} />
      {touched && error && <ErrorText>{t(error)}</ErrorText>}
    </InputContainer>
  );
};
PhoneForm.propTypes = {
  input: object,
  t: func,
  meta: object,
  label: string,
  inputProps: object
};

export default compose(formatInputProps(), translate('validation'))(PhoneForm);
