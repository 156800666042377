import { insert, is, map, prop, isNil, isEmpty, ifElse, innerJoin, omit, sortBy, compose, toLower } from 'ramda';
import { upperCase } from './format';
import { useMemo } from 'react';

export * from './errors';
export * from './format';
export * from './date';
export * from './storage';
export * from './validation';
export * from './styles';
export * from './toaster';

export const defaultSelectLimit = 200;
export const defaultPaginationLimit = 30;
export const emptyArray = [];
export const emptyObject = {};
export const emptyFunction = () => null;
export const isEmptyOrNil = (value) => isEmpty(value) || isNil(value);
export const isNotEmptyOrNil = (value) => !isEmptyOrNil(value);

export const sortByCaseInsensitive = (propToSort) => sortBy(compose(toLower, prop(propToSort)));

export const upsertListWithId = (list = [], item = {}) => {
  const index = list.findIndex((_item) => _item.id === item.id);

  if (index > -1) {
    list.splice(index, 1);
    return insert(index, item, list);
  }

  return list.concat([item]);
};

export const makeOptions = (list = [], propValue = 'value', propLabel = 'name', propToSort = undefined) => {
  if (is(Array, list)) {
    if (isNotEmptyOrNil(propToSort)) {
      return compose(
        sortByCaseInsensitive(propToSort),
        map((item) => ({ label: `${prop(propLabel, item)}`, value: prop(propValue, item) }))
      )(list);
    }

    return map((item) => ({ label: `${prop(propLabel, item)}`, value: prop(propValue, item) }), list);
  }

  return emptyArray;
};

export const valueOrPlaceholder = (checkFunc, formatFunc = (value) => value, placeholder = '--') =>
  ifElse(checkFunc, formatFunc, () => placeholder);

export const makeTimeFilter = (name, targetProp, date) => ({
  name,
  value: {
    target: targetProp,
    date
  }
});

export const getSelectedValue = (value, options = emptyArray) => {
  if (!is(Array, value)) {
    return options.find((option) => option.value === value);
  }

  return innerJoin((a, b) => a.value === b, options, value);
};

export const disable = (checkFunction, values = emptyArray) => {
  for (let i = 0; i < values.length; i++) {
    if (checkFunction(values[i])) {
      return true;
    }
  }

  return false;
};

export const loadOptionsAsync = (t, request, itemPrefix = '') => {
  return request().then((items) =>
    sortByCaseInsensitive('label')(items.map((item) => ({ label: t(`${itemPrefix}.${upperCase(item)}`), value: item })))
  );
};

export const handleFilters = (filters, filterType, filter) => {
  let newFilters = { ...filters };

  newFilters[filterType] = filter;
  return isEmpty(filter) || isNil(filter) ? omit([filterType], newFilters) : newFilters;
};

export const nameComparator = (valueA, valueB, targetProp = 'lastName') => {
  const nameA = prop(targetProp, valueA);
  const nameB = prop(targetProp, valueB);

  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  }

  if (targetProp === 'firstName') {
    return 0;
  }

  return nameComparator(valueA, valueB, 'firstName');
};

export const parseQueryString = (queryString) => {
  const queries = {};
  let keyValues = queryString.substring(1).split('&');

  for (let keyValue of keyValues) {
    const split = keyValue.split('=');

    if (split.length === 2) {
      queries[split[0]] = split[1];
    }
  }

  return queries;
};

export const Throttle = (callback, limit) => {
  return useMemo(() => {
    let timer = null;

    return function (...arg) {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        callback.apply(this, arg);
        timer = null;
      }, limit);
    };
  }, []);
};
