import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { func, bool, string } from 'prop-types';
import { reduxForm, formValues, Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import {
  BottomContainer,
  Button,
  Row,
  Col,
  Block,
  TextInput,
  TextAreaForm,
  Upload,
  SelectorTipsCategoryForm,
  SelectorTipsPriorityForm,
  ToggleForm
} from '../components';
import StyledForm from '../components/form.styles';
import AudienceForm from './cms.audience.form';
import { upsertContent } from './cms.actions';
import { required, maxLength } from '../utils';
import { StyledLabel, InputContainer } from '../components/form/TextInput.styles';

const TipForm = (props) => {
  // Props
  const { t, isNew, onCancel, submitting, handleSubmit, submitSucceeded, countryId, cityId, hubId } = props;

  if (submitSucceeded && isNew) {
    return <Redirect to="/cms/tips" />;
  }

  // Render
  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(upsertContent)}>
        <Block>
          <Row mb>
            <Col lg={12} pr-half pl-half>
              <Field component={ToggleForm} label={t('FORM.ACTIVE')} name="active" type="checkbox" />
            </Col>
          </Row>

          <Row>
            <Col xl={9} lg={9} md={9} sm={12}>
              <AudienceForm
                t={t}
                countryId={countryId}
                cityId={cityId}
                hubId={hubId}
                includeHouse={false}
                isNew={isNew}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={9} lg={9} md={9} sm={12} pr-half pl-half>
              <Field component={TextInput} validate={[required, maxLength(100)]} label={t('FORM.TITLE')} name="title" />

              <Field
                component={SelectorTipsCategoryForm}
                validate={required}
                label={t('FORM.CATEGORY')}
                name="category"
              />

              <Field
                component={SelectorTipsPriorityForm}
                validate={required}
                label={t('FORM.PRIORITY')}
                name="priority"
              />

              <Field
                component={TextAreaForm}
                validate={[required, maxLength(250)]}
                label={t('FORM.PREVIEW')}
                name="preview"
              />

              <Field component={TextAreaForm} validate={required} label={t('FORM.TEXT')} name="text" />

              <InputContainer>
                <StyledLabel>{t('FORM.COVER')}</StyledLabel>
                <Field
                  component={Upload}
                  name="cover"
                  accept={['image/png', 'image/jpeg']}
                  size={128}
                  validate={required}
                />
              </InputContainer>
            </Col>
          </Row>
        </Block>

        <BottomContainer negativeMargin={true}>
          <Row right>
            <Button name="cancel" disabled={submitting} onClick={() => onCancel()} clear size="md">
              {t('FORM.CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('FORM.SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </BottomContainer>
      </StyledForm>
    </Fragment>
  );
};

const validate = (values = {}) => {
  const errors = {};

  // Check audience
  if (!values.countryId && !values.cityId && !values.hubId) {
    errors.countryId = 'ERR_TIP_MISSING_AUDIENCE';
  }

  return errors;
};

TipForm.propTypes = {
  t: func,
  change: func,
  isNew: bool,
  countryId: string,
  cityId: string,
  ...reduxFormPropTypes
};

export default compose(
  translate('cms'),
  reduxForm({ form: 'TipForm', validate, enableReinitialize: true, destroyOnUnmount: false }),
  formValues({ countryId: 'countryId', cityId: 'cityId', hubId: 'hubId' })
)(TipForm);
