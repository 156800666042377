import React, { useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { Field, reduxForm, propTypes as reduxFormPropTypes, change } from 'redux-form';
import { compose, path, prop, pathOr } from 'ramda';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { translate } from '../translate/i18n';
import { upsertDamage } from './payments.actions';
import StyledForm from '../components/form.styles';
import { Block, Col, TextAreaForm, Row, Button, Upload, TextInput, Container, RenderElement } from '../components';
import { StyledLabel } from '../components/form/TextInput.styles';
import FileUpload from '../components/form/FileUpload';
import { getLeases } from '../leases/leases.actions';
import { required, positive, formatFullname, formatPhone, formatLeaseLocationLabel, sortByDate } from '../utils';
import { SelectorForm } from '../components';

const DamageForm = (props) => {
  // Props
  const { t, handleSubmit, submitting, onCancel, submitSucceeded, hub, member, getLeases, leases, dispatch } = props;
  const [leaseOptions, serLeaseOptions] = useState([]);

  useEffect(() => {
    getLeases({
      limit: 1000,
      userId: member.id,
      hubId: hub.id
    });
  }, [hub, member]);

  useEffect(() => {
    const sortedLeases = sortByDate('startDate', leases, 'ASC');

    serLeaseOptions(
      sortedLeases.map((lease) => {
        const start = pathOr(null, ['startDate'], lease);
        const moveout = pathOr(null, ['moveoutDate'], lease);

        if (moment(start).isBefore(moment()) && moment(moveout).isAfter(moment())) {
          dispatch(change('DamageFormCreate', 'leaseId', lease.id));
        }

        return {
          value: lease.id,
          label: formatLeaseLocationLabel({ lease })
        };
      })
    );
  }, [leases]);

  useEffect(() => {
    if (submitSucceeded) {
      onCancel();
    }
  }, [submitSucceeded]);

  // Render
  return (
    <StyledForm onSubmit={handleSubmit(upsertDamage)}>
      <Block p>
        <Container column mb>
          <Row>
            <Col xl={4} lg={4} md={6} sm={12} pr-half pl-half mb>
              <RenderElement value={formatFullname(member)} label={t('MEMBER')} />
            </Col>

            <Col xl={4} lg={4} md={6} sm={12} pr-half pl-half mb>
              <RenderElement value={prop('email', member)} label={t('EMAIL')} />
            </Col>

            <Col xl={4} lg={4} md={6} sm={12} pr-half pl-half mb>
              <RenderElement
                label={t('PHONE')}
                value={formatPhone(prop('phoneNumber', member), path(['phonePrefix', 'prefix'], member))}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={4} lg={4} md={12} sm={12} pr-half pl-half mb>
              <RenderElement value={path(['city', 'name'], hub)} label={t('CITY')} />
            </Col>

            <Col xl={8} lg={8} md={12} sm={12} pr-half pl-half mb>
              <Field
                name="leaseId"
                validate={[required]}
                component={SelectorForm}
                options={leaseOptions}
                label={t('leases:LEASE')}
              />
            </Col>
          </Row>
        </Container>

        <Field name="amount" validate={[required, positive]} component={TextInput} label={t('AMOUNT')} />

        <Field name="description" validate={required} component={TextAreaForm} label={t('DAMAGE')} />

        <Row>
          <Col xl={2} lg={3} md={6} sm={12} pr-half pl-half mb>
            <StyledLabel>{'Picture'}</StyledLabel>
            <Field name="picture" component={Upload} size={96} accept={['image/png', 'image/jpeg']} />
          </Col>

          <Col xl={10} lg={9} md={6} sm={12} pr-half pl-half mb>
            <Field
              name="bill"
              accept={['application/pdf', 'image/png', 'image/svg+xml', 'image/jpeg', 'image/jpg']}
              component={FileUpload}
              label={t('BILL_ATTACHMENT')}
            />
          </Col>
        </Row>

        <Row mb right>
          <Button clear size="md" name="cancel" disabled={submitting} onClick={onCancel}>
            {t('CANCEL')}
          </Button>
          <Button bg-primary size="md" name="submit" type="submit" disabled={submitting}>
            {t('SAVE')}
          </Button>
        </Row>
      </Block>
    </StyledForm>
  );
};

DamageForm.propTypes = {
  t: func,
  getLeases: func,
  handleSubmit: func,
  onCancel: func,
  submitting: bool,
  payment: object,
  member: object,
  hub: object,
  ...reduxFormPropTypes
};

DamageForm.defaultProps = {
  leases: []
};

export default compose(
  translate('payments'),
  reduxForm({ form: 'DamageFormCreate' }),
  connect(
    (state) => ({
      leases: pathOr([], ['leases', 'list'], state)
    }),
    {
      getLeases
    }
  )
)(DamageForm);
