import { takeLatest, call, all, put } from 'redux-saga/effects';
import {
  SET_PAYMENT_METHODS_REQUEST,
  setPaymentMethodsSuccess,
  FETCH_PAYMENT_METHODS_REQUEST,
  fetchPaymentMethodsSuccess,
  fetchPaymentMethodsFail,
  FETCH_COUNTRIES,
  fetchCountriesSuccess,
  fetchCountriesFail,
  FETCH_CITIES,
  fetchCitiesSuccess,
  fetchCitiesFail,
  FETCH_HUBS,
  FETCH_HUBS_ONLY,
  fetchHubsSuccess,
  fetchHubsFail,
  upsertCity,
  upsertHub,
  upsertCountry,
  initCityForm,
  SELECT_HUB,
  initHubForm,
  FETCH_CITIES_ONLY
} from './geo.actions';
import * as geoService from './geo.service';
import * as paymentGatewayService from '../paymentGateway/paymentGateway.service';
import * as hubPaymentMethodService from '../hubPaymentMethod/hubPaymentMethod.service';
import { prop, path } from 'ramda';
import { emptyObject } from '../utils';
import { initialize, reset, destroy } from 'redux-form';
import { getUser } from '../user/user.service';
import { types, defActionType } from '../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';

export function* fetchCountries() {
  try {
    const countries = yield call(geoService.getCountries);
    yield put(fetchCountriesSuccess(countries));
  } catch (err) {
    yield put(fetchCountriesFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.GEO.COUNTRY, error: err }));
  }
}

export function* fetchPaymentMethods() {
  try {
    const cities = yield call(geoService.getAllPaymentMethods);
    yield put(fetchPaymentMethodsSuccess(cities));
  } catch (err) {
    yield put(fetchPaymentMethodsFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.GEO.PAYMENT_METHODS, error: err }));
  }
}

export function* setPaymentMethods(action) {
  const hubId = path(['payload', 'id'], action);
  const paymentMethods = path(['payload', 'list'], action);
  try {
    yield call(hubPaymentMethodService.setHubPaymentMethods, { hubId, paymentMethods });
    const res = yield call(hubPaymentMethodService.getHubPaymentMethods, hubId);
    yield put(
      setPaymentMethodsSuccess({
        hubId,
        paymentMethods: res
      })
    );
  } catch (err) {
    yield put(
      handleErrorMessage('UPDATE.FAILURE', { type: types.GEO.PAYMENT_METHODS, error: err, data: action.payload })
    );
  }
}

export function* fetchCities(action) {
  try {
    const country = prop('payload', action);
    const cities = yield call(geoService.getCities, { criteria: { countryId: prop('id', country) } });
    yield put(fetchCitiesSuccess(cities));
  } catch (err) {
    yield put(fetchCitiesFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.GEO.CITY, error: err, data: action.payload }));
  }
}

export function* fetchHubsOnly(action) {
  try {
    const cityId = prop('payload', action);
    const criteria = cityId ? { criteria: { cityId } } : emptyObject;
    const hubs = yield call(geoService.getHubs, criteria);
    yield put(fetchHubsSuccess(hubs));
  } catch (err) {
    yield put(fetchHubsFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.GEO.HUB, error: err, data: action.payload }));
  }
}

export function* fetchCitiesOnly(action) {
  try {
    const countryId = prop('payload', action);
    const criteria = countryId ? { criteria: { countryId } } : emptyObject;
    const cities = yield call(geoService.getCities, criteria);
    yield put(fetchCitiesSuccess(cities));
  } catch (err) {
    yield put(fetchCitiesFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.GEO.CITY, error: err, data: action.payload }));
  }
}

export function* fetchHubs(action) {
  try {
    const city = prop('payload', action);

    yield put(initCityForm({ ...city }));

    const hubs = yield call(geoService.getHubs, { criteria: { cityId: city.id } });
    yield put(fetchHubsSuccess(hubs));
  } catch (err) {
    yield put(fetchHubsFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.GEO.HUB, error: err, data: action.payload }));
  }
}

export function* _upsertCountry(action) {
  const country = prop('payload', action);
  const key = defActionType(country);

  try {
    const _country = yield call(geoService.upsertCountry, country);
    yield put(upsertCountry.success(_country));

    const isNew = !country.id;

    yield put(destroy('CountryForm'));
    isNew ? yield put(reset('CountryForm')) : yield put(initialize('CountryForm', _country));

    const key = isNew ? 'UPDATED' : 'SAVED';
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.GEO.COUNTRY }));
  } catch (err) {
    yield put(upsertCountry.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.GEO.COUNTRY, error: err, data: action.payload }));
  }
}

export function* _upsertCity(action) {
  const city = prop('payload', action);
  const key = defActionType(city);

  try {
    const _city = yield call(geoService.upsertCity, { ...city, countryId: path(['country', 'id'], city) });
    yield put(upsertCity.success(_city));

    const isNew = !city.id;

    yield put(destroy('CityForm'));
    isNew ? yield put(reset('CityForm')) : yield put(initialize('CityForm', { ...city, id: _city.id }));

    const key = isNew ? 'UPDATED' : 'SAVED';
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.GEO.CITY }));
  } catch (err) {
    yield put(upsertCity.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.GEO.CITY, error: err, data: action.payload }));
  }
}

export function* _upsertHub(action) {
  const hub = prop('payload', action);
  const key = defActionType(hub);

  try {
    const _hub = yield call(geoService.upsertHub, {
      ...hub,
      paymentGatewayId: path(['paymentGateway', 'id'], hub),
      managerId: path(['manager', 'id'], hub),
      propertyManagerId: path(['propertyManager', 'id'], hub),
      hubManagerId: path(['hubManager', 'id'], hub),
      cityId: path(['city', 'id'], hub)
    });
    yield put(upsertHub.success({ ...hub, id: _hub.id, city: hub.city, ..._hub }));

    const isNew = !hub.id;

    isNew ? yield put(reset('HubForm')) : yield put(initialize('HubForm', { ...hub, id: _hub.id }));

    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.GEO.HUB }));
  } catch (err) {
    yield put(upsertHub.failure(err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.GEO.HUB, error: err, data: action.payload }));
  }
}

export function* _fetchHubData(action) {
  const hub = prop('payload', action);
  try {
    const manager = yield call(getUser, prop('managerId', hub));
    const propertyManager = yield call(getUser, prop('propertyManagerId', hub));
    const paymentGatewayId = prop('paymentGatewayId', hub);
    let paymentGateway;
    if (paymentGatewayId) {
      paymentGateway = yield call(paymentGatewayService.getPaymentGateway, paymentGatewayId);
    }

    const paymentMethods = yield call(hubPaymentMethodService.getHubPaymentMethods, prop('id', hub));
    const hubManager = yield call(getUser, prop('hubManagerId', hub));
    yield put(initHubForm({ ...hub, manager, propertyManager, paymentGateway, paymentMethods, hubManager }));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.GEO.HUB, error: err, data: action.payload }));
  }
}

export default function* geoSaga() {
  yield all([
    takeLatest(SET_PAYMENT_METHODS_REQUEST, setPaymentMethods),
    takeLatest(FETCH_PAYMENT_METHODS_REQUEST, fetchPaymentMethods),
    takeLatest(FETCH_COUNTRIES, fetchCountries),
    takeLatest(FETCH_CITIES, fetchCities),
    takeLatest(FETCH_CITIES_ONLY, fetchCitiesOnly),
    takeLatest(FETCH_HUBS, fetchHubs),
    takeLatest(FETCH_HUBS_ONLY, fetchHubsOnly),
    takeLatest(upsertCountry.REQUEST, _upsertCountry),
    takeLatest(upsertCity.REQUEST, _upsertCity),
    takeLatest(upsertHub.REQUEST, _upsertHub),
    takeLatest(SELECT_HUB, _fetchHubData)
  ]);
}
