import React from 'react';
import { string, object, func, bool, number } from 'prop-types';
import { withTheme } from '@emotion/react';
import { compose, path, pathOr } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MessageCircle } from 'react-feather';
import { withRouter } from 'react-router-dom';

import { translate } from '../translate/i18n';
import { capitalize } from '../utils';
import { Text, Row, Col, sizing } from '../components';
import { toggleStreamMini } from '../chat/chat.actions';
import { ROLES } from '../utils/authorizations';
import './style';
import { Tab } from './menu';

const ChatBar = ({ t, currentRoute, user, chat, toggleStreamMini, theme, onSelect, mobileView, history }) => {
  const current = currentRoute.split('/')[1];

  if (![ROLES.ADMIN, ROLES.CM, ROLES.PM].includes(user.role)) {
    return null;
  }

  const isActive = chat.toggleMini.block || current === 'chat';
  const color = isActive ? 'white' : theme.colors.grey[200];

  return (
    <div className="line-container no-line">
      <Row mb mt-half>
        <Col
          lg={12}
          onClick={() => {
            if (current !== 'chat') {
              if (mobileView) {
                history.push('/chat');
              } else {
                toggleStreamMini({
                  block: !chat.toggleMini.block,
                  chat: true
                });
              }
            }

            onSelect && onSelect();
          }}>
          <Tab active={isActive}>
            <Row middle p-quarter>
              <div className="menu-icon">
                <MessageCircle size={17} color={color} />
              </div>

              <div className="menu-label">
                {isActive ? (
                  <Text weight={300} white>
                    {capitalize(t('CHAT'))}
                  </Text>
                ) : (
                  <Text weight={300} grey={200} cursor="pointer">
                    {capitalize(t('CHAT'))}
                  </Text>
                )}
              </div>

              {!!chat.unreadCount && (
                <div className="unread-count">
                  <Text weight={300} size="sm" center white>
                    {chat.unreadCount}
                  </Text>
                </div>
              )}
            </Row>
          </Tab>
        </Col>
      </Row>
    </div>
  );
};

ChatBar.propTypes = {
  t: func,
  toggleStreamMini: func,
  onSelect: func,
  currentRoute: string,
  theme: object,
  user: object,
  isLogged: bool,
  mobileView: bool,
  chat: object,
  history: object,
  logout: func,
  windowWidth: number,
  collapsed: bool
};

export default compose(
  sizing(),
  withRouter,
  translate(['menu', 'default', 'chat']),
  connect(
    (state) => ({
      user: path(['user', 'data'], state),
      isLogged: pathOr(false, ['user', 'isLogged'], state),
      chat: pathOr({}, ['chat'], state)
    }),
    (dispatch) => bindActionCreators({ toggleStreamMini }, dispatch)
  ),
  withTheme
)(ChatBar);
