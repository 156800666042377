import React from 'react';
import { func, object } from 'prop-types';
import { compose, prop, propEq } from 'ramda';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Block, Row, Text, TabsContainer, Tab } from '../components';
import { translate } from '../translate/i18n';
import Tips from './Tips';
import Feeds from './Feeds';
import Faq from './Faq';
import Videos from './Videos';

const Choose = () => (
  <Row full middle center>
    <Text>{'Choose a type of content to manage.'}</Text>
  </Row>
);

const Cms = (props) => {
  // props
  const { t, match, location } = props;

  // vars
  const url = prop('url', match);

  return (
    <Block p>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {t('CMS')}
        </Text>
      </Row>

      <TabsContainer>
        <Tab to={`${url}/tips`} label={t('TIP_OF_THE_DAY')} disabled={propEq('pathname', `${url}/tips`, location)} />
        <Tab to={`${url}/feeds`} label={t('DAILY_LIFE_FEED')} disabled={propEq('pathname', `${url}/feeds`, location)} />
        <Tab to={`${url}/faq`} label={t('FAQ')} disabled={propEq('pathname', `${url}/faq`, location)} />
        <Tab
          to={`${url}/helpvideos`}
          label={t('HELP_VIDEOS')}
          disabled={propEq('pathname', `${url}/helpvideos`, location)}
        />
      </TabsContainer>

      <Switch>
        <Route path={`${url}/tips`} component={Tips} />
        <Route path={`${url}/feeds`} component={Feeds} />
        <Route path={`${url}/faq`} component={Faq} />
        <Route path={`${url}/helpvideos`} component={Videos} />
        <Route component={Choose} />
      </Switch>
    </Block>
  );
};

Cms.propTypes = {
  t: func,
  location: object,
  match: object
};

export default compose(withRouter, translate('cms'))(Cms);
