import React from 'react';
import { object } from 'prop-types';
import styled from '@emotion/styled';

const StyledTextArea = styled('textarea')(({ theme }) => ({
  color: theme.colors.dark,
  padding: theme.spacing.half,
  marginTop: theme.spacing.quarter,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.lightGrey['300'],
  borderRadius: theme.borderRadius,
  fontSize: theme.font.size.sm,
  fontFamily: theme.font.family,
  letterSpacing: '1px',
  fontWeight: 200,
  width: '100%',
  minHeight: '100px',
  outline: 'none',
  '&:focus': {
    borderColor: theme.colors.brown['500']
  },
  '&:disabled': {
    background: theme.colors.lightGrey['200']
  },
  '::placeholder': {
    fontWeight: 200,
    fontSize: theme.font.size['sm'],
    color: theme.colors.grey[300]
  }
}));

const TextArea = (props) => <StyledTextArea {...props} />;
TextArea.propTypes = {
  input: object
};

export default TextArea;
