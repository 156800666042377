import React, { Fragment, useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose, path, pathOr, omit, pluck } from 'ramda';
import { withRouter } from 'react-router-dom';
import { translate } from '../translate/i18n';
import { Block, Col, Back, Row, Text } from '../components';
import { emptyObject, isNotEmptyOrNil, upperCase } from '../utils';
import { getContent } from './cms.actions';
import TipForm from './Tip.form';
import FeedForm from './Feed.form';
import FaqForm from './Faq.form';
import VideoForm from './Video.form';
import { getMember } from '../members/member.actions';

const components = {
  tips: TipForm,
  feeds: FeedForm,
  faq: FaqForm,
  helpvideos: VideoForm
};

const renderForm = (isNew, content, id) => {
  return isNew || (!isNew && isNotEmptyOrNil(content) && content.id === id);
};

const Content = (props) => {
  // Props
  const { history, match, t, content = {}, getContent, member, getMember } = props;

  // variables
  const id = path(['params', 'id'], match);
  const contentType = path(['params', 'type'], match);
  const action = pathOr(null, ['params', 'action'], match);
  const isNew = id === 'create';
  const duplicate = action === 'duplicate';
  const FormComponent = components[contentType];
  const showForm = renderForm(isNew, content, id);
  let title = isNew ? t(`NEW_${upperCase(contentType)}`) : t(`${upperCase(contentType)}_DETAILS`);
  let initialValues = { contentType };

  if (!isNew) {
    initialValues = { ...content, contentType };

    if (initialValues.memberId) {
      initialValues.member = member;
    }

    // Map locations by name for form data
    if (content.locations) {
      initialValues.locations = pluck('name', content.locations);
    }

    // If we duplicate, let's just retrieve every info except id
    if (duplicate) {
      initialValues = omit(['id'], initialValues);
      title = t(`DUPLICATE_${upperCase(contentType)}`);
    }
  }

  // Hooks
  useEffect(() => {
    if (!isNew) {
      getContent({ contentType, id });
    }
  }, []);

  useEffect(() => {
    if (content.memberId) {
      getMember(content.memberId);
    }
  }, [content.memberId]);

  // Render
  return (
    <Fragment>
      <Block pt pl pr>
        <Row mb-double>
          <Col lg={6} left>
            <Back to={`/cms/${contentType}`} label={t(`BACK_TO_${upperCase(contentType)}_LIST`)} />
          </Col>
        </Row>

        <Row mb-double>
          <Text size="xl" weight={200}>
            {title}
          </Text>
        </Row>

        {showForm && (
          <FormComponent
            duplicate={duplicate}
            isNew={isNew}
            initialValues={initialValues}
            onCancel={() => history.push(`/cms/${contentType}`)}
          />
        )}
      </Block>
    </Fragment>
  );
};

Content.propTypes = {
  t: func,
  match: object,
  content: object,
  isFetching: bool,
  getContent: func,
  history: object,
  member: object,
  getMember: func
};

export default compose(
  withRouter,
  translate('cms'),
  connect(
    (state) => ({
      isFetching: pathOr(false, ['cms', 'current', 'isFetching'], state),
      content: pathOr(emptyObject, ['cms', 'current', 'data'], state),
      member: pathOr(emptyObject, ['member', 'current', 'data'], state)
    }),
    { getContent, getMember }
  )
)(Content);
