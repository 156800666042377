import React from 'react';
import { string } from 'prop-types';
import styled from '@emotion/styled';
import { Text } from '../components';
import { translate } from '../translate/i18n';

const ResourceContainer = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.lightGrey[300],
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px'
}));

const KanbanResourceItem = ({ callResourceName }) => {
  if (!callResourceName) {
    return null;
  }

  return (
    <ResourceContainer>
      <Text left pt-half size="md" brown={500} weight={200}>
        {callResourceName}
      </Text>
    </ResourceContainer>
  );
};

KanbanResourceItem.propTypes = {
  callResourceName: string
};

export default translate('applications')(KanbanResourceItem);
