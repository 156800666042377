import React from 'react';
import { Button, Text, Row } from '../components';
import { translate } from '../translate/i18n';
import { func, array, string, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose, omit, pathOr } from 'ramda';

import { getApplicationStatus } from '../enum/enum.actions';
import { updateApplicantStatus } from './applications.actions';
import { getCallStatuses } from './applications.utils';

const loading = (Wrapped) => (props) => {
  return <Wrapped {...props} />;
};

const ApplicationCallStatusButtons = ({ t, status, applicant, updateApplicantStatus, isUpdating }) => {
  const statuses = getCallStatuses(t);
  const availableStatuses = statuses.find((statusValue) => statusValue.value === status);

  return (
    <Row middle>
      {availableStatuses &&
        availableStatuses.next.map((availableStatus) => (
          <Button
            key={availableStatus.value}
            disabled={isUpdating}
            mr
            mb
            name="callStatus"
            onClick={() => {
              updateApplicantStatus({ ...omit(['houses'], applicant), callStatus: availableStatus.value });
            }}>
            <Text grey weight={300}>
              {availableStatus.label}
            </Text>
          </Button>
        ))}
    </Row>
  );
};

ApplicationCallStatusButtons.propTypes = {
  t: func,
  statusList: array,
  getApplicationStatus: func,
  status: string,
  applicant: object,
  isFetchingStatus: bool,
  updateApplicantStatus: func,
  isUpdating: bool,
  inVerifications: bool
};

export default compose(
  translate('applications'),
  connect(
    (state) => ({
      applicant: pathOr({}, ['applications', 'current', 'data'], state),
      isUpdating: pathOr(false, ['applications', 'current', 'isUpdatingStatus'], state)
    }),
    { getApplicationStatus, updateApplicantStatus }
  ),
  loading
)(ApplicationCallStatusButtons);
