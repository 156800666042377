/* eslint-disable no-dupe-class-members */
import React, { Component, useState, useEffect, Fragment } from 'react';
import { is, pick, reverse, omit, pathOr, propOr, compose } from 'ramda';
import Loader from './Loader';
import { getAvailableStyleAttributes, emptyArray } from '../utils';
import { any } from 'prop-types';
import { spacing as themeSpacing, DEFAULT_SPACING } from '../theme/spacing';
import { colors } from '../theme/colors';
import { connect } from 'react-redux';
import { ROLES } from '../utils/authorizations';
import { translate } from '../translate/i18n';
import { Row, Col, Button } from '../components';

const breakpoints = themeSpacing.breakpoints;

const generateKeys = (spacing, prefix, key = '') => {
  if (spacing === 'normal') return `${prefix}${key}`;
  return `${prefix}${key}-${spacing}`;
};

/**
 * @function
 * @returns Object: all borders attributs eg {bb-primary: {borderBottom: '1px solid primary'}}
 */
const makeBorderAttr = () => {
  const colorValue = 300;
  const availableColors = Object.keys(colors);
  const directions = { l: 'Left', r: 'Right', t: 'Top', b: 'Bottom' };

  const initValues = availableColors.reduce(
    (acc, color) => ({
      ...acc,
      [`b-${color}`]: { border: `1px solid ${colors[color][colorValue]}` }
    }),
    {}
  );

  return availableColors.reduce(
    (acc, color) => {
      const propList = Object.keys(directions).reduce(
        (acc, key) => ({
          ...acc,
          [generateKeys(color, 'b', key)]: {
            [`border${directions[key]}`]: `1px solid ${colors[color][colorValue]}`
          }
        }),
        {}
      );
      return { ...acc, ...propList };
    },
    { ...initValues }
  );
};

const borderAttrs = makeBorderAttr();
const borderAttrsKeys = Object.keys(borderAttrs);

export const border = () => (WrappedComponent) => {
  const Sub = (props) => {
    const borderAttr = Object.keys(props).reduce((acc, key) => {
      if (borderAttrsKeys.includes(key)) return { ...acc, ...borderAttrs[key] };
      return acc;
    }, {});

    return (
      <WrappedComponent border={borderAttr} {...props}>
        {props.children}
      </WrappedComponent>
    );
  };

  Sub.propTypes = {
    children: any
  };

  return Sub;
};

/**
 * @function
 * @returns Object: all spacing attributs eg {ml: {marginLeft: 20px}}
 */
const makeSpacingAttr = () => {
  const availableSpacing = ['none', 'half', 'quarter', 'double', 'sesqui', 'pacman', 'normal'];
  const availableValues = pick(availableSpacing, themeSpacing);
  const directions = { l: 'Left', r: 'Right', t: 'Top', b: 'Bottom' };

  const initValues = availableSpacing.reduce(
    (acc, spacing) => ({
      ...acc,
      [`p-${spacing}`]: { padding: `${availableValues[spacing]}px` },
      [`m-${spacing}`]: { margin: `${availableValues[spacing]}px` }
    }),
    { m: { margin: `${DEFAULT_SPACING}px` }, p: { padding: `${DEFAULT_SPACING}px` } }
  );

  return availableSpacing.reduce(
    (acc, spacing) => {
      const propList = Object.keys(directions).reduce(
        (acc, key) => ({
          ...acc,
          [generateKeys(spacing, 'm', key)]: {
            [`margin${directions[key]}`]: `${availableValues[spacing]}px`
          },
          [generateKeys(spacing, 'p', key)]: {
            [`padding${directions[key]}`]: `${availableValues[spacing]}px`
          }
        }),
        {}
      );
      return { ...acc, ...propList };
    },
    { ...initValues }
  );
};

const spacingAttrs = makeSpacingAttr();
const spacingAttrsKeys = Object.keys(spacingAttrs);

/**
 * HOC: add spacing to WrappedComponent
 * @function
 * @returns WrappedComponent with spacing attributs
 */
export const spacing = () => (WrappedComponent) => {
  const Sub = (props) => {
    const spacingAttr = Object.keys(props).reduce((acc, key) => {
      if (spacingAttrsKeys.includes(key)) return { ...acc, ...spacingAttrs[key] };
      return acc;
    }, {});

    return (
      <WrappedComponent spacing={spacingAttr} {...props}>
        {props.children}
      </WrappedComponent>
    );
  };

  Sub.propTypes = {
    children: any
  };

  return Sub;
};

/**
 * Decorator that injects styleProps in component props
 */
export const formatStyleProps = () => (WrappedComponent) => (props) => (
  <WrappedComponent styleProps={getStyleProps(props)} {...props} />
);

/**
 * Returns only props specified in available style attributes
 * @param {object} props
 * @returns {object} styleProps
 */
export const getStyleProps = (props) => {
  if (!props) throw Error('props must be defined');
  return pick(getAvailableStyleAttributes(), props);
};

export const loading = (callback) => (Wrapped) => (props) => {
  const isLoading = is(Function, callback) ? callback(props) : false;

  if (isLoading) return <Loader />;

  return <Wrapped {...props} />;
};

export const show = (callback) => (Wrapped) => (props) => {
  const canShow = is(Function, callback) ? callback(props) : true;

  if (!canShow) return null;

  return <Wrapped {...props} />;
};

const alignementAvailable = {
  center: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  middle: {
    alignItems: 'center'
  },
  left: {
    textAlign: 'left',
    justifyContent: 'flex-start'
  },
  right: {
    textAlign: 'right',
    justifyContent: 'flex-end'
  },
  between: {
    justifyContent: 'space-between'
  },
  evenly: {
    justifyContent: 'space-evenly'
  }
};
const alignementAvailableKeys = Object.keys(alignementAvailable);
export const alignement = () => (Wrapped) => {
  const Sub = (props) => {
    const alignementProps = Object.keys(props).reduce((acc, key) => {
      if (alignementAvailableKeys.includes(key)) return { ...acc, ...alignementAvailable[key] };
      return acc;
    }, {});
    return <Wrapped alignement={alignementProps} {...props} />;
  };

  return Sub;
};

const breakpointsKeys = Object.keys(breakpoints);
const breakpointsKeysInverse = reverse(breakpointsKeys);
export const sizing = () => (WrappedComponent) => {
  class Sub extends Component {
    constructor(props) {
      super(props);
      this.sizes = omit(['prevIndex', 'hasNoValue'], this.populateSizes(props));
      this.state = this.getSize();
    }

    populateSizes(props) {
      const filteredProps = Object.keys(props).filter((prop) => breakpointsKeys.includes(prop));

      return breakpointsKeys.reduce(
        (acc, point) => {
          if (filteredProps.includes(point)) {
            const value = props[point];

            const populatePreviewEmptyValues = acc.hasNoValue.reduce(
              (accHasNovaLue, current) => ({
                ...accHasNovaLue,
                [current]: value
              }),
              {}
            );
            return { ...acc, [point]: value, ...populatePreviewEmptyValues };
          }

          const prevValue = acc[acc.prevIndex];

          const currentValue = prevValue ? { [point]: prevValue } : { [point]: point };

          return { ...acc, ...currentValue, hasNoValue: [...acc.hasNoValue, point], prevIndex: point };
        },
        { hasNoValue: [] }
      );
    }

    getSize() {
      const windowWidth = window.innerWidth;
      const windowSize = breakpointsKeysInverse.find((key) => windowWidth > breakpoints[key]);

      return {
        windowWidth,
        windowSize,
        mobileView: windowWidth <= themeSpacing.breakpoints.md
      };
    }

    handleResize = this.handleResize.bind(this);
    handleResize() {
      this.setState(this.getSize());
    }

    componentDidMount() {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    render() {
      const { windowSize, windowWidth, mobileView } = this.state;
      return (
        <WrappedComponent
          size={this.sizes[windowSize]}
          windowWidth={windowWidth}
          mobileView={mobileView}
          {...this.props}
        />
      );
    }
  }

  return Sub;
};

export const onlyForRoles =
  (roles = []) =>
  (Wrapped) => {
    const Sub = (props) => {
      const role = propOr('', 'role', props);
      return roles.includes(role) ? <Wrapped {...props} /> : null;
    };

    return connect((state) => ({ role: pathOr('', ['user', 'data', 'role'], state) }))(Sub);
  };

export const onlyForAdmin = onlyForRoles([ROLES.ADMIN]);

export const initComponentWithFetchedData = (callback) => (Wrapped) => (props) => {
  const { entity, action } = callback(props);
  const [hasFetchData, setFetchData] = useState(false);

  if (entity.error) return <Wrapped {...props} error={entity.error} />;

  if (entity.isFetching) return <Loader />;

  if (!pathOr(emptyArray, ['list', 'length'], entity) && !hasFetchData) {
    action();
    setFetchData(true);
    return <Loader />;
  }

  return <Wrapped {...props} />;
};

export const collapsible =
  ({ labelHide = 'default:HIDE', labelShow = 'default:SHOW', showOnFullScreen = true } = {}) =>
  (Wrapped) =>
    compose(
      translate(),
      sizing()
    )((props) => {
      const { t, mobileView } = props;
      const [collapse, toggleCollapse] = useState(true);

      useEffect(() => {
        toggleCollapse(true);
      }, [mobileView]);

      const style =
        (mobileView && collapse) || (!showOnFullScreen && !mobileView)
          ? {
              display: 'none'
            }
          : {};

      return (
        <Fragment>
          {mobileView && (
            <Row mb>
              <Col lg={12}>
                <Button
                  bg-secondary
                  name={`${labelShow}_${labelHide}`}
                  size="md"
                  onClick={() => toggleCollapse(!collapse)}>
                  {collapse ? t(labelShow) : t(labelHide)}
                </Button>
              </Col>
            </Row>
          )}
          <div style={style}>
            <Wrapped {...props} mobileView={mobileView} />
          </div>
        </Fragment>
      );
    });
