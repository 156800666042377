import React, { useState } from 'react';
import { func, object } from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'ramda';
import { translate } from '../../translate/i18n';
import { Container, Card, Col, TextInput, Row, Button, Text } from '../../components';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { capitalize, isUrl } from '../../utils';
import { connect } from 'react-redux';
import { setBackend } from '../user.actions';
import { getApiUrl } from '../../store/apiUrl';

const FORM_NAME = 'ChangeBackend';

const RenderSuccessMessage = translate('auth')(({ t }) => (
  <Container column pt>
    <Text align="right">{capitalize(t('BO_ENDPOINT_CHANGED'))}</Text>
    <Link to="/login">
      <Text align="right" blue={500}>
        {t('GO_LOGIN')}
      </Text>
    </Link>
  </Container>
));

RenderSuccessMessage.propTypes = {
  t: func
};
const ChangeBackend = ({ t, endpoint, setBackend, initialValues }) => {
  const handleSubmit = () => {
    setBackend(endpoint);
    setBoUrlChanged(true);
  };
  const [boUrlChanged, setBoUrlChanged] = useState(false);
  return (
    <Container center pt-double>
      <Col lg={3} md={5}>
        {boUrlChanged && <RenderSuccessMessage />}
        <Card title={t('SWAP_BE_TITLE')} textCenter>
          <form>
            <Field
              name="endpoint"
              component={TextInput}
              placeholder={initialValues.endpoint}
              type="text"
              label={`${capitalize(t('BACKEND_API_ENDPOINT'))}*`}
            />
            <div style={{ textAlign: 'center' }}>
              <Row middle between>
                <Button bg-primary size="md" onClick={handleSubmit} name="changeBackend">
                  {capitalize(t('CHANGE'))}
                </Button>
                <Link to="/login">
                  <Text lightGrey>Back</Text>
                </Link>
              </Row>
            </div>
          </form>
        </Card>
      </Col>
    </Container>
  );
};

ChangeBackend.propTypes = {
  setBackend: func,
  t: func,
  state: object
};
const validate = (values) => {
  const errors = {};

  if (!values.url) {
    errors.url = 'REQUIRED';
  } else if (!isUrl(values.url)) {
    errors.url = 'INVALID_HTTP_ENDPOINT';
  }

  return errors;
};
const selectValue = formValueSelector(FORM_NAME);
export default compose(
  connect(
    (state) => ({
      endpoint: selectValue(state, 'endpoint'),
      initialValues: {
        endpoint: getApiUrl()
      }
    }),
    { setBackend }
  ),
  reduxForm({
    form: FORM_NAME,
    validate
  }),
  translate('auth')
)(ChangeBackend);
