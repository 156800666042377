import React, { useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import { withTheme } from '@emotion/react';
import { compose, path, pathOr } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChevronsLeft, ChevronsRight } from 'react-feather';
import { LogOut } from 'react-feather';

import browserStore from '../utils/browserStore';
import { translate } from '../translate/i18n';
import { Text, Row, Container, Button } from '../components';
import Logo from '../assets/logo.jpg';
import { logout } from '../user/user.actions';
import { toggleCollapseMenu } from './actions';

const Header = ({ user, theme, logout, menu, toggleCollapseMenu, setUserUpdateModal }) => {
  useEffect(() => {
    const collapse = !!browserStore.getItem('const', 'menu_collapse');
    if (collapse !== menu.collapse) {
      toggleCollapseMenu(collapse);
    }
  }, []);

  const Arrow = menu.collapse ? ChevronsRight : ChevronsLeft;

  return (
    <Container pt>
      <div className="line-container">
        <Row left middle>
          <div onClick={() => setUserUpdateModal(true)}>
            <Row left middle>
              <div style={{ margin: '0 18px 0 15px', cursor: 'pointer' }}>
                <Row left middle>
                  <div className="logo-block">
                    <img className="logo" src={pathOr(Logo, ['picture'], user)} alt="logo" />
                  </div>
                </Row>
              </div>

              <Text pr white weight={300} cursor="pointer">
                {`${user.firstName} ${user.lastName}`}
              </Text>
            </Row>
          </div>

          <Button m-none p-none clear onClick={() => logout()} name="logout">
            <LogOut size={16} color={theme.colors.lightGrey[500]} />
          </Button>

          <div className="collapse-menu">
            <Arrow size={24} color="#fff" onClick={() => toggleCollapseMenu(!menu.collapse)} />
          </div>
        </Row>
      </div>
    </Container>
  );
};

Header.propTypes = {
  theme: object,
  user: object,
  menu: object,
  isLogged: bool,
  setUserUpdateModal: func,
  toggleCollapseMenu: func,
  logout: func
};

export default compose(
  translate(['user']),
  connect(
    (state) => ({
      user: path(['user', 'data'], state),
      menu: path(['menu'], state)
    }),
    (dispatch) => bindActionCreators({ logout, toggleCollapseMenu }, dispatch)
  ),
  withTheme
)(Header);
