import * as api from '@cohabs/bo-api';
import { prop } from 'ramda';
import { defaultPaginationLimit, emptyArray } from '../utils';

// Tips
export const getTip = (id) => api.get(`/tip/${id}`);

export const getTips = ({
  page = 1,
  limit = defaultPaginationLimit,
  criteria = {},
  order = [['createdAt', 'DESC']]
}) => {
  if (page < 1) page = 1;
  return api.post(`/tips/${page}/${limit}`, { criteria, order });
};

export const deleteTips = (tips = emptyArray) => Promise.all(tips.map((tip) => api.del(`/tip/${prop('id', tip)}`)));

export const createTip = (tip) => api.post('/tip', tip);
export const updateTip = (tip) => api.put(`/tip/${tip.id}`, tip);
export const upsertTip = (tip) => (!tip.id ? createTip(tip) : updateTip(tip));

// Feed
export const getFeed = (id) => api.get(`/feed/${id}`);

export const getFeeds = ({
  page = 1,
  limit = defaultPaginationLimit,
  criteria = {},
  order = [['createdAt', 'DESC']]
}) => {
  if (page < 1) page = 1;
  return api.post(`/feeds/${page}/${limit}`, { criteria, order });
};

export const deleteFeeds = (feeds = emptyArray) =>
  Promise.all(feeds.map((feed) => api.del(`/feed/${prop('id', feed)}`)));

export const createFeed = (feed) => api.post('/feed', feed);
export const updateFeed = (feed) => api.put(`/feed/${feed.id}`, feed);
export const upsertFeed = (feed) => (!feed.id ? createFeed(feed) : updateFeed(feed));

//Faq

export const getFaq = (id) => api.get(`/question/${id}`);

export const getFaqs = ({
  page = 1,
  limit = defaultPaginationLimit,
  criteria = {},
  order = [['createdAt', 'DESC']]
}) => {
  if (page < 1) page = 1;
  return api.post(`/questions/${page}/${limit}`, { criteria, order });
};

export const deleteFaqs = (faqs = emptyArray) =>
  Promise.all(faqs.map((faq) => api.del(`/question/${prop('id', faq)}`)));

export const createFaq = (faq) => api.post('/question', faq);
export const updateFaq = (faq) => api.put(`/question/${faq.id}`, faq);
export const upsertFaq = (faq) => (!faq.id ? createFaq(faq) : updateFaq(faq));

//helpVideos

export const getHelpVideo = (id) => api.get(`/video/${id}`);

export const getVideos = ({
  page = 1,
  limit = defaultPaginationLimit,
  criteria = {},
  order = [['createdAt', 'DESC']]
}) => {
  if (page < 1) page = 1;
  return api.post(`/videos/${page}/${limit}`, { criteria, order });
};

export const deleteVideos = (videos = emptyArray) =>
  Promise.all(videos.map((video) => api.del(`/video/${prop('id', video)}`)));

export const createHelpvideo = (video) => api.post('/video', video);
export const updateVideo = (video) => api.put(`/video/${video.id}`, video);
export const upsertVideo = (video) => (!video.id ? createHelpvideo(video) : updateVideo(video));
