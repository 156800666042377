/* eslint-disable max-lines */
import React, { PureComponent, Fragment, useEffect } from 'react';
import { array, func, string, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose, pathOr, propOr, keys, filter } from 'ramda';
import copy from 'copy-to-clipboard';
import { Edit } from 'react-feather';
import { translate } from '../translate/i18n';
import { formatDate, capitalize, toast, isNotEmptyOrNil } from '../utils';
import { Table, Container, Text, Row, Modal, ActionButton, ActionSecondaryButton, Loader } from '../components';
import RoomForm from './Application.room.form';
import { sendOfferRooms, sendRemindersRooms, deleteAvailableRooms, addRoom } from './applications.actions';
import { getSignatureLink, refreshApplicationsRooms, getKYCWizardLink } from './applications.service';
import { localDateToHubInUtcFormat } from '../utils/date';
import DiscountToolTip from '../discounts/discountTooltip';
import { isDiscountExpired } from '../discounts/validation';

const cellRendererFrameworkIsSelected =
  (handleSelectedRow) =>
  ({ api, data }) => {
    useEffect(() => {
      api.refreshHeader();
    }, [data]);

    return <input type="checkbox" checked={data.isSelected} onChange={() => handleSelectedRow(data.id)} />;
  };

const getColumnDefs = (t, application, state, handleSelectedRow, handleSelectAll) => (onUpdateCellClicked) => [
  {
    headerName: '',
    field: 'isSelected',
    width: 40,
    headerComponentFramework: ({ agGridReact }) => {
      const checked =
        isNotEmptyOrNil(agGridReact.gridOptions.rowData) &&
        agGridReact.gridOptions.rowData.every((item) => item.isSelected);

      return (
        <input
          type="checkbox"
          checked={checked}
          onChange={() => {
            handleSelectAll();
          }}
        />
      );
    },
    cellRendererFramework: cellRendererFrameworkIsSelected(handleSelectedRow)
  },
  {
    headerName: capitalize(t('HOUSE')),
    field: 'house.name',
    width: 150
  },
  {
    headerName: capitalize(t('ROOM')),
    field: 'number',
    width: 150,
    valueFormatter: (col) => col.value
  },
  {
    headerName: capitalize(t('AVAILABLE_ON')),
    field: 'availableOn',
    width: 150,
    valueFormatter: (col) => formatDate(col.value ? col.value : application.createdAt, application.timezone),
    tooltip: ({ data }) => data.timezone
  },
  {
    headerName: capitalize(t('PROPOSED_START')),
    field: 'application_rooms.moveInDate',
    width: 150,
    valueFormatter: (col) => formatDate(col.value ? col.value : application.createdAt, application.timezone),
    tooltip: ({ data }) => data.timezone
  },
  {
    headerName: capitalize(t('RENT')),
    field: 'application_rooms.rent',
    width: 100,
    cellRendererFramework: ({ data }) => {
      const {
        discount_promise,
        application_rooms: { discountApplies, moveInDate, rent }
      } = data;
      const currencySymbol = pathOr('-', ['hub', 'currency', 'symbol'], application);

      return (
        <Row middle>
          <Text tableGray pr-quarter>
            {rent}
          </Text>
          {discount_promise && !isDiscountExpired(moveInDate, discount_promise.redeemBy) && (
            <DiscountToolTip
              enabled={discountApplies}
              translationKey="discounts:APPLICATION_ROOM_RENT"
              incomingPrice={rent}
              currencySymbol={currencySymbol}
              discount={discount_promise}
            />
          )}
        </Row>
      );
    }
  },
  {
    headerName: capitalize(t('SURFACE')),
    field: 'surface',
    width: 70
  },
  {
    headerName: capitalize(t('DESCRIPTION')),
    field: 'description',
    width: 250
  },
  {
    headerName: t('LINK'),
    field: 'application_rooms.cmsId',
    hide: !(
      application.status === 'awaiting kyc' ||
      application.status === 'reviewing kyc' ||
      (application.status === 'sent' && application.bookingType === 'pre-booking')
    ),
    valueFormatter: ({ data }) => {
      if (!data.application_rooms.cmsId) {
        return '';
      }

      if (application.status === 'awaiting kyc' || application.status === 'reviewing kyc') {
        return t('KYC');
      }

      return t('PAY_PREBOOKING');
    },
    onCellClicked: async (event) => {
      const { value } = event;

      if (!value) {
        toast.error('Cannot fetch URL without cmsId');
      } else {
        const url = await getKYCWizardLink(value);

        copy(url);
        toast.success('Link copied to the clipboard');
      }
    }
  },
  {
    headerName: t('LINK'),
    field: 'id',
    hide: !(
      application.status === 'booked' ||
      application.status === 'won' ||
      (application.status === 'sent' && application.bookingType !== 'pre-booking')
    ),
    cellRendererFramework: ({ data }) => {
      if (data.application_rooms.cmsId) {
        return t('SIGN_LEASE');
      }

      return '';
    },
    onCellClicked: ({ value, data }) => {
      if (!state.isFetching && data.application_rooms.cmsId) {
        getSignatureLink(application.id, value)
          .then((link) => {
            copy(link);
            toast.success('Link copied to the clipboard');
          })
          .catch((err) => {
            if (err.status !== 400) toast.error(`Unable to fetch signature link. ${err.message}`);
          });
      }
    }
  },
  {
    headerName: '',
    field: 'id',
    width: 50,
    cellRendererFramework: () => <Edit size={16} />,
    onCellClicked: onUpdateCellClicked
  }
];

class ApplicationRoom extends PureComponent {
  static propTypes = {
    rooms: array,
    t: func,
    sendOfferRooms: func,
    sendRemindersRooms: func,
    deleteAvailableRooms: func,
    addRoom: func,
    application: object,
    applicationId: string,
    isCreating: bool,
    pendingRefresh: bool
  };

  constructor(props) {
    super(props);
    const { t, applicationId, rooms } = props;

    this.roomActions = [
      { label: t('SEND_OFFERS'), value: 'offer' },
      { label: t('SEND_REMINDERS'), value: 'reminder' },
      { label: t('REMOVE_ROOMS'), value: 'remove' }
    ];

    this.handleClose = this.handleClose.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);

    this.refreshRooms = () => refreshApplicationsRooms(applicationId);

    const selectedMask = {};

    this.state = {
      show: false,
      isFetching: false,
      pendingRefresh: false,
      rooms: rooms.map((item) => {
        selectedMask[item.id] = item.isInCurrentOffer;
        return {
          ...item,
          isSelected: item.isInCurrentOffer
        };
      }),
      updatedRoom: null,
      selectedMask,
      selectAllFlag: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.rooms !== prevProps.rooms) {
      const { selectedMask } = this.state;

      this.setState({
        rooms: this.props.rooms.map((item) => ({
          ...item,
          isSelected: !!selectedMask[item.id]
        }))
      });
    }
  }

  componentDidMount() {
    const rooms = this.props.rooms || [];

    if (!rooms.length) {
      this.handleRefresh();
    }
  }

  handleClose() {
    this.setState({ show: false, showSignLinkModal: false, updatedRoom: null });
  }

  handleSelectedRow = (roomId) => {
    const { selectedMask, rooms = [] } = this.state;

    const res = {
      selectedMask: {
        ...selectedMask,
        [roomId]: !selectedMask[roomId]
      }
    };

    res.rooms = rooms.map((item) => ({
      ...item,
      isSelected: !!res.selectedMask[item.id]
    }));

    res.selectAllFlag = res.rooms.every((item) => item.isSelected);

    this.setState(res);
  };

  handleSelectAll = () => {
    const { rooms = [], selectAllFlag } = this.state;
    const res = {
      selectedMask: {}
    };

    res.rooms = rooms.map((item) => {
      res.selectedMask[item.id] = !selectAllFlag;
      return {
        ...item,
        isSelected: !selectAllFlag
      };
    });

    this.setState({ selectAllFlag: !selectAllFlag, ...res });
  };

  resetRoomsSelection = ({ roomIds = [], shouldDelete = false } = {}) => {
    const { rooms } = this.state;

    const res = {
      rooms: [...rooms],
      selectedMask: {},
      selectAllFlag: false
    };

    if (shouldDelete) {
      res.rooms = rooms.filter((room) => !roomIds.includes(room.id));
      return this.setState(res);
    }

    res.rooms = rooms.map((room) => ({
      ...room,
      isSelected: false
    }));

    this.setState(res);
  };

  handleSendOffer = (roomIds) => {
    const { applicationId, sendOfferRooms } = this.props;

    sendOfferRooms({ applicationId, roomIds });
    //this.resetRoomsSelection({ roomIds });
  };

  handleDeleteRooms = (roomIds) => {
    const { applicationId, deleteAvailableRooms } = this.props;
    deleteAvailableRooms({ applicationId, roomIds });
    this.resetRoomsSelection({ roomIds, shouldDelete: true });
  };

  handleRefresh = async () => {
    this.setState({ pendingRefresh: true });
    const rooms = await this.refreshRooms();
    this.setState({ rooms, pendingRefresh: false });
  };

  render() {
    const { t, addRoom, applicationId, isCreating, application } = this.props;
    const { show, updatedRoom, rooms, selectedMask } = this.state;

    const datatable = {
      columnDefs: getColumnDefs(
        t,
        application,
        this.state,
        this.handleSelectedRow,
        this.handleSelectAll
      )(({ data }) => this.setState({ show: true, updatedRoom: data })),
      rowData: rooms
    };

    const roomIds = keys(filter((isSelected) => isSelected, selectedMask));
    const disableApplicationRoomActions = ['booked', 'won', 'lost'].includes(application.status);

    return (
      <Container column mt-double>
        <Row middle>
          <Row mb middle>
            <Text size="lg" weight={300} pr-half>
              {t('ROOMS_AVAILABLE')}
            </Text>

            {propOr(0, 'length', roomIds) ? (
              <Fragment>
                <ActionSecondaryButton
                  icon="Send"
                  actionBtn
                  title={t('SEND_OFFERS')}
                  name="add_room"
                  disabled={disableApplicationRoomActions}
                  onClick={() => this.handleSendOffer(roomIds)}
                />

                <ActionSecondaryButton
                  danger
                  icon="Trash2"
                  title={t('REMOVE_ROOMS')}
                  name="add_room"
                  disabled={disableApplicationRoomActions}
                  onClick={() => this.handleDeleteRooms(roomIds)}
                />
              </Fragment>
            ) : (
              <Fragment>
                <ActionButton
                  icon="Plus"
                  actionBtn
                  title={t('ADD_ROOM')}
                  name="add_room"
                  onClick={() => this.setState({ show: true })}
                />
                {(this.state.pendingRefresh && (
                  <div>
                    <Loader />
                  </div>
                )) || (
                  <ActionButton
                    icon="RefreshCw"
                    actionBtn
                    title={t('REFRESH_ROOMS')}
                    name="refresh_room"
                    onClick={this.handleRefresh}
                  />
                )}
              </Fragment>
            )}
          </Row>

          <Table hasPagination={false} data={datatable} isRowSelectable={() => true} />

          <Modal
            title={
              !updatedRoom
                ? t('ADD_ROOM')
                : t('UPDATE_ROOM', {
                    houseName: updatedRoom.house.name,
                    roomNumber: updatedRoom.number
                  })
            }
            subTitle={
              updatedRoom && {
                text: t('discounts:EDIT_ROOM_DISCOUNT_TITLE'),
                link: {
                  to: `/rooms/${updatedRoom.id}`,
                  text: t('discounts:EDIT_ROOM_DISCOUNT_LINK')
                }
              }
            }
            hasActions
            show={show || isCreating}
            onClose={() => this.handleClose()}
            content={() => (
              <RoomForm
                room={updatedRoom}
                application={application}
                applicationId={applicationId}
                onSubmit={(values) => {
                  this.setState({ show: false });
                  addRoom({
                    isUpdate: !!updatedRoom,
                    applicationId,
                    ...values,
                    moveinDate: localDateToHubInUtcFormat(values.moveInDate, application.timezone)
                  });
                  this.setState({ updatedRoom: null });
                }}
                onClose={() => this.handleClose()}
              />
            )}
          />
        </Row>
      </Container>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      isFetching: pathOr(true, ['applications', 'current', 'isFetching'], state),
      rooms: pathOr([], ['applications', 'current', 'data', 'rooms'], state),
      applicationId: pathOr('', ['applications', 'current', 'data', 'id'], state),
      application: pathOr({}, ['applications', 'current', 'data'], state),
      isCreating: pathOr(false, ['applications', 'current', 'isCreating'], state)
    }),
    { sendOfferRooms, sendRemindersRooms, deleteAvailableRooms, addRoom }
  ),
  translate(['applications', 'discounts'])
)(ApplicationRoom);
