import React, { Fragment, useState, useEffect } from 'react';
import { Text } from './';
import { translate } from '../translate/i18n';
import { object, func, string } from 'prop-types';
import { capitalize, emptyArray, emptyFunction, formatAddress, emptyObject } from '../utils';
import { pathOr, prop, propOr, path, isEmpty, is } from 'ramda';
import { AddressContainer, SuggestionAddress, SuggestionAddressContainer } from './Address.styles';
import { InputContainer, ErrorText, StyledLabel, StyledTextInput } from './form/TextInput.styles';
import { fetchAdress } from '../address/address.service';

const Address = (props) => {
  const [list, setList] = useState(emptyArray);
  const inputValue = path(['input', 'value'], props);

  const [value, setValue] = useState(inputValue);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  let debounce;
  const handleChange = (value) => {
    if (debounce) clearTimeout(this.debounce);

    setValue(value);

    debounce = setTimeout(async () => {
      const list = value ? await fetchAdress(value) : emptyArray;
      setList(list);
    }, 500);
  };

  const getAddressItem = (address) => {
    const context = propOr(emptyObject, 'context', address);

    return (search) =>
      Object.keys(context)
        .map((_item) => {
          const itemId = propOr({}, _item, context);
          try {
            if (itemId.id.includes(search)) return itemId.text;
            return undefined;
          } catch (err) {
            return undefined;
          }
        })
        .find((item) => !!item);
  };

  const onChange = pathOr(emptyFunction, ['input', 'onChange'], props);
  const handleSelect = (address) => {
    setValue(prop('place_name', address));
    setList(emptyArray);

    const _getAddressItem = getAddressItem(address);
    const postalCode = _getAddressItem('postcode');
    const town = _getAddressItem('locality');

    const changes = {
      streetNumber: prop('address', address),
      streetName: prop('text', address),
      gpsCoordinates: prop('geometry', address),
      postalCode,
      town
    };

    onChange(changes);
  };

  const formatValue = (value) => {
    return is(Object, value) ? formatAddress(value) : value;
  };

  const { t, input, meta, label, placeholder } = props;
  const { touched, error, active } = meta;

  return (
    <Fragment>
      <input type="hidden" {...input} />
      <AddressContainer>
        <InputContainer>
          <StyledLabel active={active}>{capitalize(label ? label : t('default:ADDRESS'))}</StyledLabel>
          <StyledTextInput
            value={formatValue(value)}
            onChange={(event) => {
              const value = event.target.value;

              handleChange(value);

              if (isEmpty(value)) {
                onChange('');
              }
            }}
            placeholder={placeholder}
            onFocus={(e) => input.onFocus(e)}
          />

          {touched && error && <ErrorText>{t(error)}</ErrorText>}
        </InputContainer>
        {!!list.length && (
          <SuggestionAddressContainer>
            {list.map((address, index) => (
              <SuggestionAddress key={index} onClick={() => handleSelect(address)}>
                <Text>{address.place_name}</Text>
              </SuggestionAddress>
            ))}
          </SuggestionAddressContainer>
        )}
      </AddressContainer>
    </Fragment>
  );
};

Address.propTypes = {
  t: func,
  input: object,
  meta: object,
  label: string,
  placeholder: string
};

export default translate('validation')(Address);
