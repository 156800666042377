import React from 'react';
import { func, object, array } from 'prop-types';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { withRouter } from 'react-router-dom';
import { Container, Table } from '../components';
import { capitalize } from '../utils';

const PacksSubscriptions = ({ t, members, history }) => {
  const goToMemberDetail = (event) => {
    const member = event.data;
    history.push(`/members/${member.id}`);
  };

  const getColumnDefs = (t) => [
    {
      headerName: capitalize(t('FIRST_NAME')),
      field: 'firstName',
      width: 120,
      onCellClicked: (event) => goToMemberDetail(event)
    },
    {
      headerName: capitalize(t('LAST_NAME')),
      field: 'lastName',
      width: 120,
      onCellClicked: (event) => goToMemberDetail(event)
    }
  ];

  const datatable = {
    columnDefs: getColumnDefs(t),
    rowData: members
  };

  return (
    <Container column p-double>
      <Table data={datatable} hasPagination={false} />
    </Container>
  );
};

PacksSubscriptions.propTypes = {
  t: func,
  history: object,
  members: array
};

export default compose(withRouter, translate(['member']))(PacksSubscriptions);
