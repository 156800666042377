import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func, object, string, bool } from 'prop-types';
import { compose, prop, pathOr, propOr } from 'ramda';
import { Row, Col, Button, Back } from '../components';
import { translate } from '../translate/i18n';
import { colors } from '../theme/colors';
import { emptyObject, isNotEmptyOrNil } from '../utils';

const Header = (props) => {
  const { showCompare, history, t, inventory, inventories, link, label } = props;

  const currentInventory = propOr(inventory, 'current', inventories);
  const previousInventory = propOr(null, 'previous', inventories);

  const showCompareButton =
    showCompare && isNotEmptyOrNil(previousInventory) && prop('id', previousInventory) !== prop('id', currentInventory);

  return (
    <Row>
      <Col lg={6} left mb>
        <Back to={link} label={t(label)} />
      </Col>

      {showCompareButton && (
        <Col lg={6} mb>
          <Row right>
            <Button
              name="compare"
              size="md"
              bg-lightGrey
              mr-half
              color={colors.grey[500]}
              onClick={() => history.push(`/inventories/${prop('id', inventory)}/compare`)}>
              {t('COMPARE_HISTORY')}
            </Button>
          </Row>
        </Col>
      )}
    </Row>
  );
};

Header.propTypes = {
  inventory: object,
  history: object,
  t: func,
  link: string,
  label: string,
  inventories: object,
  showCompare: bool
};

export default compose(
  withRouter,
  translate('inventories'),
  connect((state) => ({
    inventory: pathOr(emptyObject, ['inventories', 'current', 'data'], state),
    inventories: pathOr(emptyObject, ['inventories', 'compare'], state)
  }))
)(Header);
