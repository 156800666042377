import { GET_HUB_PAYMENT_METHODS_REQUEST, GET_HUB_PAYMENT_METHODS_SUCCESS } from './hubPaymentMethod.actionTypes';

export const fetchHubPaymentMethodRequest = (hubId) => ({
  type: GET_HUB_PAYMENT_METHODS_REQUEST,
  payload: hubId
});

export const fetchHubPaymentMethodSuccess = ({ paymentMethods, hubId }) => ({
  type: GET_HUB_PAYMENT_METHODS_SUCCESS,
  payload: { paymentMethods, hubId }
});
