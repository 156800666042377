/* eslint max-lines:off */

import { takeLatest, takeEvery, call, all, put, select } from 'redux-saga/effects';
import * as service from './houses.service';
import * as landlordService from '../landlord/landlord.service';
import * as lockService from '../lock/lock.service';
import {
  ADD_HOUSE_AREAS,
  UPDATE_HOUSE_AREAS,
  REMOVE_HOUSE_AREAS,
  GET_HOUSE_AREAS,
  GET_HOUSES,
  APPEND_HOUSES,
  GET_HOUSE_TRASHES,
  GET_HOUSE_SERVICES,
  DELETE_TRASH,
  DELETE_UNIT,
  DELETE_HOUSES,
  DELETE_SERVICES,
  DELETE_HOUSE_LOCK
} from './houses.actionTypes';
import {
  addHouseAreaSuccess,
  updateHouseAreaSuccess,
  removeHouseAreaSuccess,
  setHouseAreas,
  deleteHouseLockSuccess,
  setHouses,
  upsertHouse,
  upsertHouseLock,
  FETCH_HOUSE_DETAILS,
  fetchHouseDetailsSuccess,
  fetchHouseDetailsFailure,
  upsertCategory,
  DELETE_CATEGORIES,
  deleteCategoriesSuccess,
  deleteCategoriesFailure,
  appendHousesSuccess,
  appendHousesFail,
  getHouseTrashesFailure,
  getHouseTrashesSuccess,
  upsertTrash,
  deleteTrashSuccess,
  deleteTrashFailure,
  deleteHousesSuccess,
  deleteHousesFailure,
  upsertService,
  upsertUnit,
  getHouseServicesSuccess,
  getHouseServicesFailure,
  deleteServicesSuccess,
  deleteServicesFailure,
  fetchHouseDetails,
  deleteUnitSuccess,
  deleteUnitFailure
} from './houses.actions';
import { propOr, prop, is, pathOr } from 'ramda';
import { hubTimezoneSelector } from './houses.selectors';
import { handleFormError, emptyObject, emptyArray, isEmptyOrNil } from '../utils';
import { SubmissionError } from 'redux-form';
import { uploadFile } from '../service';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import { types } from '../utils/sagas';
import { localDateToHubInUtcFormat } from '../utils';
import moment from 'moment-timezone';
import { getRooms } from '../rooms/room.actions';
import { upsertRoom } from '../rooms/room.service';

export function* getHouses(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const houses = yield call(service.getHouses, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(setHouses(houses));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.HOUSE, data: action.payload }));
  }
}

export function* getHouseAreas(action) {
  try {
    const houseId = propOr(null, 'payload', action);
    const res = yield call(service.getHouseAreas, houseId);
    yield put(setHouseAreas(res));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.HOUSE_AREA, data: action.payload }));
  }
}

export function* addHouseAreas(action) {
  try {
    const data = propOr({}, 'payload', action);
    const res = yield call(service.addHouseAreas, data);
    yield put(addHouseAreaSuccess(res));
  } catch (err) {
    yield put(handleErrorMessage('ADD.FAILURE', { type: types.HOUSE_AREA, data: action.payload }));
  }
}

export function* updateHouseAreas(action) {
  try {
    const data = propOr({}, 'payload', action);
    const res = yield call(service.updateHouseAreas, data);
    yield put(updateHouseAreaSuccess(res));
  } catch (err) {
    yield put(handleErrorMessage('ADD.FAILURE', { type: types.HOUSE_AREA, data: action.payload }));
  }
}

export function* removeHouseArea(action) {
  try {
    const data = propOr({}, 'payload', action);
    yield call(service.removeHouseAreas, data);
    yield put(removeHouseAreaSuccess(data));
  } catch (err) {
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.HOUSE_AREA, data: action.payload }));
  }
}

export function* appendHouses(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const houses = yield call(service.getHouses, {
      criteria: { ...payload },
      page: payload.page,
      limit: payload.limit,
      order: payload.order
    });
    yield put(appendHousesSuccess(houses));
  } catch (err) {
    yield put(appendHousesFail(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.HOUSE, data: action.payload }));
  }
}

export function* deleteHouses(action) {
  try {
    const houses = propOr([], 'payload', action);
    yield call(service.deleteHouses, houses);
    yield put(deleteHousesSuccess(houses));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.HOUSE }));
  } catch (err) {
    yield put(deleteHousesFailure(err));
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.HOUSE, data: action.payload }));
  }
}

const handleHouseError = (err) => {
  const data = prop('data', err);

  if (data && is(Object, data)) {
    const errors = Object.keys(data).reduce(
      (acc, err) => {
        const _err = data[err];

        if (_err.split('_')[1] === 'LANDLORD') return { ...acc, landlord: { ...acc.landlord, [err]: _err } };
        return { ...acc, info: { ...acc.info, [err]: _err } };
      },
      { info: {}, landlord: {} }
    );

    return new SubmissionError(errors);
  }
  return err;
};

export function* _upsertHouseLock(action) {
  const { lock, houseId } = action.payload;
  const key = lock.id ? 'UPDATE' : 'CREATE';

  try {
    const _lock = yield call(lockService.upsertLock, lock.id ? lock : { ...lock, houseId });

    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.LOCK }));
    yield put(
      upsertHouseLock.success({
        houseId,
        lock: _lock
      })
    );
  } catch (error) {
    yield put(upsertHouseLock.failure(error));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.LOCK, data: action.payload }));
  }
}

// eslint-disable-next-line complexity
export function* _upsertHouse(action) {
  const { landlord, info } = action.payload;
  const key = info.id ? 'UPDATE' : 'CREATE';

  try {
    let _landlord;

    if (landlord) {
      _landlord = yield call(landlordService.upsertLandlord, landlord);
    }

    const house = {
      ...info,
      ...(_landlord ? { landlordId: _landlord.id } : {}),
      hubId: prop('hub', info),
      openingDate: prop('openingDate', info)
    };

    const cover = propOr(null, 'cover', info);
    const logo = propOr(null, 'logo', info);

    if (is(Blob, cover)) {
      house.cover = yield uploadFile(cover);
    }

    if (is(Blob, logo)) {
      house.logo = yield uploadFile(logo);
    }

    // When creating a new house, use the hub's timezone
    const houseTimezone = house.timezone
      ? house.timezone
      : yield select((state) => hubTimezoneSelector(state, info.hub));
    let openingDate = localDateToHubInUtcFormat(house.openingDate, houseTimezone);

    const _house = yield call(service.upsertHouse, {
      ...house,
      openingDate
    });

    yield put(upsertHouse.success(_house));

    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.HOUSE }));
  } catch (err) {
    const { landlord, info } = action.payload;

    // Cleanup orphan landlord/lock on house creation
    if (isEmptyOrNil(info.id)) {
      if (isEmptyOrNil(landlord.id)) {
        yield call(landlordService.deleteLandlord, landlord);
      }
    }

    const error = handleHouseError(err);
    yield put(upsertHouse.failure(error));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.HOUSE, data: action.payload }));
  }
}

export function* _fetchHouse(action) {
  const houseId = prop('payload', action);
  try {
    const house = yield call(service.fetchHouse, houseId);
    yield put(fetchHouseDetailsSuccess(house));
  } catch (err) {
    yield put(fetchHouseDetailsFailure(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.HOUSE, data: action.payload }));
  }
}

export function* _upsertCategory(action) {
  const subSection = prop('payload', action);
  const key = subSection.id ? 'UPDATE' : 'CREATE';

  try {
    const _subSection = yield call(service.upsertCategory, subSection);
    yield put(upsertCategory.success(_subSection));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.SUBSECTION }));
  } catch (err) {
    const error = handleFormError(err);
    const formError = new SubmissionError(error);
    yield put(upsertCategory.failure(formError));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.SUBSECTION, data: action.payload }));
  }
}

export function* _upsertUnit(action) {
  const _unit = prop('payload', action);
  const _rooms = pathOr(emptyObject, ['payload', 'rooms'], action);
  const initialRooms = pathOr(emptyObject, ['payload', 'initialRooms'], action);
  const key = _unit.id ? 'UPDATE' : 'CREATE';
  try {
    const unit = yield call(service.upsertUnit, _unit);
    const roomsIds = Object.keys(_rooms);

    for (let i = 0; i < roomsIds.length; i++) {
      //checked rooms that have units assigned
      if (propOr(false, roomsIds[i], _rooms)) {
        //check if room never had unit so we can skip already added rooms
        if (!propOr(false, roomsIds[i], initialRooms)) {
          let room = { id: roomsIds[i], unitId: unit.id, houseId: _unit.houseId };
          yield call(upsertRoom, room);
        }
      }
    }

    yield put(upsertUnit.success(unit));
    yield put(fetchHouseDetails(_unit.houseId));
    yield put(getRooms(_unit.houseId));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.UNIT }));
  } catch (err) {
    const error = handleFormError(err);
    const formError = new SubmissionError(error);
    yield put(upsertUnit.failure(formError));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.UNIT, data: action.payload }));
  }
}

export function* _deleteCategories(action) {
  const payload = propOr(emptyObject, 'payload', action);

  try {
    yield call(service.deleteCategories, payload.list);
    yield put(deleteCategoriesSuccess(payload));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.SUBSECTION }));
  } catch (err) {
    yield put(deleteCategoriesFailure(err));
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.SUBSECTION, data: action.payload }));
  }
}

export function* getHouseTrashes(action) {
  const houseId = prop('payload', action);

  try {
    const trashes = yield call(service.getHouseTrashes, houseId);
    yield put(getHouseTrashesSuccess(trashes));
  } catch (err) {
    yield put(getHouseTrashesFailure(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.TRASH }));
  }
}

export function* _upsertTrash(action) {
  const payload = prop('payload', action);
  const key = payload.id ? 'UPDATE' : 'CREATE';

  try {
    const trash = yield call(service.upsertTrash, {
      ...payload,
      recurrences: payload.recurrences.filter((rec) => {
        if (rec !== null) {
          rec.firstDate = moment
            .tz(moment(rec.firstDate).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', payload.timezone)
            .utc()
            .format();
          return rec;
        }
        return null;
      })
    });
    yield put(upsertTrash.success({ ...trash, ...payload }));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.TRASH }));
  } catch (err) {
    const data = prop('data', err);
    yield put(upsertTrash.failure(data ? new SubmissionError(data) : err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.TRASH }));
  }
}

export function* _deleteTrash(action) {
  const payload = propOr(emptyArray, 'payload', action);

  try {
    yield call(service.deleteTrash, payload);
    yield put(deleteTrashSuccess(payload));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.TRASH }));
  } catch (err) {
    yield put(deleteTrashFailure(err));
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.TRASH }));
  }
}

export function* _deleteUnit(action) {
  const payload = propOr(emptyArray, 'payload', action);
  try {
    yield call(service.deleteUnits, payload);
    yield put(deleteUnitSuccess(payload));
    yield put(fetchHouseDetails(payload[0].houseId));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.UNIT }));
  } catch (err) {
    yield put(deleteUnitFailure(err));
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.UNIT }));
  }
}

export function* getHouseServices(action) {
  const houseId = prop('payload', action);

  try {
    const services = yield call(service.getHouseServices, houseId);
    yield put(getHouseServicesSuccess(services));
  } catch (err) {
    yield put(getHouseServicesFailure(err));
    yield put(handleErrorMessage('GET.FAILURE', { type: types.SERVICE }));
  }
}

export function* _upsertService(action) {
  const payload = prop('payload', action);
  const key = payload.id ? 'UPDATE' : 'CREATE';

  try {
    const response = yield call(service.upsertService, {
      ...payload,
      recurrences: payload.recurrences.filter((rec) => rec !== null)
    });

    yield put(upsertService.success({ ...payload, ...response }));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.SERVICE }));
  } catch (err) {
    const data = prop('data', err);
    yield put(upsertService.failure(data ? new SubmissionError(data) : err));
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.SERVICE }));
  }
}

export function* deleteServices(action) {
  try {
    const payload = propOr(emptyArray, 'payload', action);
    yield call(service.deleteServices, payload);
    yield put(deleteServicesSuccess(payload));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.SERVICE }));
  } catch (err) {
    yield put(deleteServicesFailure(err));
    yield put(handleErrorMessage('DELETE.SUCCESS', { type: types.SERVICE }));
  }
}

export function* deleteHouseLock({ payload }) {
  try {
    yield call(lockService.deleteLock, payload);
    yield put(deleteHouseLockSuccess(payload));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.LOCK }));
  } catch (err) {
    yield put(handleErrorMessage('DELETE.SUCCESS', { type: types.LOCK }));
  }
}

export default function* housesSaga() {
  yield all([
    takeLatest(GET_HOUSE_AREAS, getHouseAreas),
    takeEvery(ADD_HOUSE_AREAS, addHouseAreas),
    takeEvery(UPDATE_HOUSE_AREAS, updateHouseAreas),
    takeEvery(REMOVE_HOUSE_AREAS, removeHouseArea),
    takeLatest(DELETE_HOUSE_LOCK, deleteHouseLock),
    takeLatest(GET_HOUSES, getHouses),
    takeLatest(APPEND_HOUSES, appendHouses),
    takeLatest(upsertHouse.REQUEST, _upsertHouse),
    takeLatest(upsertHouseLock.REQUEST, _upsertHouseLock),
    takeLatest(FETCH_HOUSE_DETAILS, _fetchHouse),
    takeLatest(upsertCategory.REQUEST, _upsertCategory),
    takeLatest(upsertUnit.REQUEST, _upsertUnit),
    takeLatest(DELETE_CATEGORIES, _deleteCategories),
    takeLatest(GET_HOUSE_TRASHES, getHouseTrashes),
    takeLatest(upsertTrash.REQUEST, _upsertTrash),
    takeLatest(DELETE_TRASH, _deleteTrash),
    takeLatest(DELETE_UNIT, _deleteUnit),
    takeLatest(DELETE_HOUSES, deleteHouses),
    takeLatest(GET_HOUSE_SERVICES, getHouseServices),
    takeLatest(upsertService.REQUEST, _upsertService),
    takeLatest(DELETE_SERVICES, deleteServices)
  ]);
}
