import { GET_COLLECTIONS, SET_COLLECTIONS } from './collection.actionTypes';

export const fetchCollections = () => ({
  type: GET_COLLECTIONS
});

export const setCollections = (payload) => ({
  type: SET_COLLECTIONS,
  payload
});
