import React from 'react';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';
import SelectorRoom from '../selector/Selector.room';

const SelectRoomForm = ({ t, houseId, input, label, isDisabled, placeholder, meta: { touched, error } }) => (
  <InputContainer>
    <SelectorRoom
      label={label}
      placeholder={placeholder}
      houseId={houseId}
      {...input}
      isInForm={true}
      isDisabled={isDisabled}
    />

    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectRoomForm);
