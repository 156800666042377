import * as api from '@cohabs/bo-api';
import { defaultPaginationLimit } from '../utils';

export const getIncidentsList = ({ page = 1, limit = defaultPaginationLimit, criteria = {}, order = [] }) => {
  if (page === 0) page = 1;
  return api.post(`/incidents/${page}/${limit}`, { criteria, order });
};

export const getUserIncidents = ({ page = 1, limit = 5, criteria, order = [] }) =>
  api.post(`/incidents/${page}/${limit}`, { criteria, order });
