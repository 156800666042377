import { is, prop, path } from 'ramda';
import { isNotEmptyOrNil } from './index';

export const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
export const isUrl = (url) =>
  /(([\w]+:)\/\/)(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/i.test(
    url
  );
export const isStrictlyPositive = (value) => !isNaN(value) && Number(value) > 0;
export const isPositive = (value) => !isNaN(value) && Number(value) >= 0;
export const isHexColor = (color) => /^#[0-9abcdefABCDEF]{6}$/i.test(color);
export const isValidName = (value) => {
  if (!value) return true;

  return /^[a-z-áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'. ]+$/i.test(value);
};
export const isGenericText = (value) => {
  if (!value) return true;

  return /^[a-z0-9-_áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœẑÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒẐ'’"&#@ ,.:;<>°!|/\\()+?=\r\n]*$/i.test(
    value
  );
};

export const isPhone = (value) => /^[+]*[0-9]{0,3}[-\s./]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i.test(value);

/**
 * Form validation function
 */
export const required = (value) => (isNotEmptyOrNil(value) ? undefined : 'REQUIRED');
export const streetAddress = (value) =>
  value.streetNumber && value.streetName && value.postalCode ? undefined : 'INVALID_STREET_ADDRESS';
export const email = (value) => (isEmail(value) ? undefined : 'INVALID_EMAIL_ADDRESS');
export const positive = (value) => (isStrictlyPositive(value) ? undefined : 'NOT_A_POSITIVE_NUMBER');
export const positiveOrNull = (value) => (isPositive(value) ? undefined : 'NOT_A_POSITIVE_OR_NULL_NUMBER');
export const name = (value) => (isValidName(value) ? undefined : 'INVALID_NAME');
export const genericText = (value) => (isGenericText(value) ? undefined : 'INVALID_TEXT');
export const maxLength = (max) => (value) => (value && value.length > max ? 'MAX_LENGTH_EXCEEDED' : undefined);
export const hexColor = (value) => (isHexColor(value) ? undefined : 'INVALID_HEX_COLOR');
export const hasPhonePrefix = (value) =>
  isNotEmptyOrNil(path(['prefix', 'value'], value)) ? undefined : 'INVALID_PHONE_PREFIX';

export const checkFileType = (file, mimeTypes) => {
  if (is(Array, mimeTypes) && !mimeTypes.includes(prop('type', file))) {
    return 'INVALID_FILE_TYPE';
  }

  return undefined;
};
