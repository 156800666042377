import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as service from './collection.service';
import { GET_COLLECTIONS } from './collection.actionTypes';
import { setCollections } from './collection.actions';

export function* getCollections() {
  try {
    const collections = yield call(service.getCollections, {});
    yield put(setCollections(collections));
  } catch (err) {
    yield err;
  }
}

export default function* collectionSaga() {
  yield all([takeLatest(GET_COLLECTIONS, getCollections)]);
}
