import { genState } from '../utils/reducer';
import { GET_HUB_PAYMENT_METHODS_REQUEST, GET_HUB_PAYMENT_METHODS_SUCCESS } from './hubPaymentMethod.actionTypes';
import { emptyArray, emptyObject } from '../utils';
import { SET_PAYMENT_METHODS_SUCCESS } from '../geo/geo.actions';

const setPaymentMethods = ({ paymentMethods, hubId }, state) => ({
  ...state,
  [hubId]: {
    list: paymentMethods.sort((a, b) => (a.payment_method.type < b.payment_method.type ? -1 : 1)),
    isFetching: false,
    isResult: true
  }
});

const hubPaymentMethodDef = (state) => ({
  [GET_HUB_PAYMENT_METHODS_REQUEST]: (hubId) => ({
    ...state,
    [hubId]: {
      list: emptyArray,
      isFetching: true,
      isResult: false
    }
  }),
  [SET_PAYMENT_METHODS_SUCCESS]: (payload) => setPaymentMethods(payload, state),
  [GET_HUB_PAYMENT_METHODS_SUCCESS]: (payload) => setPaymentMethods(payload, state)
});

export const hubPaymentMethodReducer = (state = emptyObject, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: hubPaymentMethodDef, type });
};
