import React from 'react';
import SelectorTimezone from '../selector/Selector.timezone';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';

const SelectorTimezoneForm = ({ t, input, label, isDisabled, meta: { touched, error } }) => (
  <InputContainer>
    <SelectorTimezone label={label} {...input} isInForm={true} isDisabled={isDisabled} />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectorTimezoneForm);
