import { takeLatest, call, all, put, select } from 'redux-saga/effects';
import * as service from './paymentProfileMethod.service';
import { GET_PAYMENT_PROFILE_METHODS_REQUEST } from './paymentProfileMethod.actionTypes';
import {
  fetchPaymentProfileMethodSuccess,
  createPaymentProfileMethod,
  updatePaymentProfileMethod,
  deletePaymentProfileMethod
} from './paymentProfileMethod.actions';

import { fetchPaymentProfile } from '../paymentProfile/paymentProfile.actions';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import { types } from '../utils/sagas';

export function* getPaymentProfileMethods({ payload: { hubId, userId, profileId } = {} } = {}) {
  try {
    const paymentMethods = yield call(service.getPaymentProfileMethod, { hubId, userId });
    yield put(fetchPaymentProfileMethodSuccess({ paymentMethods, profileId }));
  } catch (err) {
    yield put(
      handleErrorMessage('GET.FAILURE', {
        type: types.PROFILE_PAYMENT_METHODS,
        error: err,
        data: { hubId, userId, profileId }
      })
    );
  }
}

export function* createPaymentProfileMethodRequest({ payload } = {}) {
  try {
    const data = yield call(service.setPaymentProfileMethod, payload);
    yield put(createPaymentProfileMethod.success(data));
    yield put(handleSuccessMessage('CREATE.SUCCESS', { type: types.PAYMENT_PROFILE_METHOD }));
  } catch (err) {
    yield put(createPaymentProfileMethod.failure(err));
    yield put(handleErrorMessage('CREATE.FAILURE', { type: types.PAYMENT_PROFILE_METHOD, error: err, data: payload }));
  }
}

export function* updatePaymentProfileMethodRequest({ payload } = {}) {
  try {
    const data = yield call(service.updatePaymentProfileMethod, payload);
    yield put(updatePaymentProfileMethod.success(data));
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.PAYMENT_PROFILE_METHOD }));
  } catch (err) {
    yield put(updatePaymentProfileMethod.failure(err));
    yield put(handleErrorMessage('UPDATE.FAILURE', { type: types.PAYMENT_PROFILE_METHOD, error: err, data: payload }));
  }
}

export function* removePaymentProfileMethodRequest({ payload } = {}) {
  try {
    yield call(service.deletePaymentProfileMethod, payload);
    yield put(deletePaymentProfileMethod.success(payload));

    const state = yield select();
    const profile = state.paymentProfile.list.find(
      (item) => item.userId === payload.userId && item.hubId === payload.hubId
    );
    if (profile && profile.preferredPaymentMethod === payload.type) {
      yield put(
        fetchPaymentProfile({
          memberId: payload.userId,
          hubId: payload.hubId
        })
      );
    }

    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.PAYMENT_PROFILE_METHOD }));
  } catch (err) {
    yield put(deletePaymentProfileMethod.failure(err));
    yield put(handleErrorMessage('DELETE.FAILURE', { type: types.PAYMENT_PROFILE_METHOD, error: err, data: payload }));
  }
}

export default function* saga() {
  yield all([
    takeLatest(GET_PAYMENT_PROFILE_METHODS_REQUEST, getPaymentProfileMethods),
    takeLatest(createPaymentProfileMethod.REQUEST, createPaymentProfileMethodRequest),
    takeLatest(updatePaymentProfileMethod.REQUEST, updatePaymentProfileMethodRequest),
    takeLatest(deletePaymentProfileMethod.REQUEST, removePaymentProfileMethodRequest)
  ]);
}
