import React from 'react';
import { func, bool, string, object } from 'prop-types';
import { Field } from 'redux-form';
import { StyledLabel, InputContainer } from './TextInput.styles';
import { translate } from '../../translate/i18n';
import { loadRecurrenceUnits, positive, required, days } from '../../utils';
import { WrappedDatePicker } from './DatePicker';
import { SelectorForm, TextInput, Text, Col } from '..';

const DayRecurrence = ({ t, fields, timezone }) => {
  return fields.map((field, i) => {
    if (!fields.get(i)) {
      return null;
    }

    return (
      <Col key={field} lg={4} pr-half>
        <Text size="md" weight={500}>
          {t(`time:${days[i].name}`)}
        </Text>

        <InputContainer>
          <StyledLabel>{t('form:REFERENCE_DATE')}</StyledLabel>
          <Field component={WrappedDatePicker} name={`${field}.firstDate`} dayOnly={i} timezone={timezone} />
        </InputContainer>

        <Field
          component={SelectorForm}
          options={loadRecurrenceUnits(t)}
          validate={required}
          label={t('form:EVERY')}
          name={`${field}.unit`}
        />

        <Field
          component={TextInput}
          label={t('form:FREQUENCY')}
          type="number"
          validate={positive}
          name={`${field}.timelapse`}
        />
      </Col>
    );
  });
};

DayRecurrence.propTypes = {
  t: func,
  day: object,
  onChange: func,
  checked: bool,
  onCheck: func,
  value: string
};

export default translate('validation')(DayRecurrence);
