import { genState } from '../utils/reducer';
import { GET_PAYMENT_PROFILES, SET_PAYMENT_PROFILES, SET_PAYMENT_PROFILE } from './paymentProfile.actionTypes';
import { createPaymentProfile, updatePaymentProfile } from './paymentProfile.actions';
import { emptyArray, emptyObject } from '../utils';

const initPaymentProfile = {
  isFetching: false,
  isResult: false,
  list: emptyArray,
  paymentProfileMethods: emptyObject
};

const updateProfileInList = (payload, state) => {
  const list = state.list.map((item) => {
    if (item.id === payload.id) {
      return payload;
    }

    return item;
  });

  return {
    ...state,
    list: list
  };
};

const paymentProfileDef = (state) => ({
  [GET_PAYMENT_PROFILES]: () => ({
    ...state,
    isFetching: true
  }),
  [SET_PAYMENT_PROFILES]: (list) => ({
    ...state,
    isFetching: false,
    isResult: true,
    list
  }),
  [createPaymentProfile.SUCCESS]: (payload) => ({
    ...state,
    list: [...state.list, payload]
  }),
  [SET_PAYMENT_PROFILE]: (payload) => updateProfileInList(payload, state),
  [updatePaymentProfile.SUCCESS]: (payload) => updateProfileInList(payload, state)
});

export const paymentProfileReducer = (state = initPaymentProfile, action) => {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: paymentProfileDef, type });
};
