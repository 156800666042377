import { takeLatest, call, all, put } from 'redux-saga/effects';
import {
  GET_GRADES,
  getGradesSuccess,
  getGradesFail,
  GET_DEFECTS,
  getDefectsSuccess,
  getDefectsFail,
  GET_GENDER_LIST,
  getGenderListSuccess,
  getGenderListFail,
  GET_PAYMENT_METHOD_LIST,
  getPaymentMethodListSuccess,
  getPaymentMethodListFail,
  GET_NATIONALITIES_LIST,
  getCountriesSuccess,
  getCountriesFail,
  GET_APPLICATION_STATUS,
  getApplicationStatusSuccess,
  getApplicationStatusFail,
  GET_ROLE,
  getRoleSuccess,
  getRoleFail,
  GET_CITIES,
  getCitiesSuccess,
  getCitiesFail,
  GET_CURRENCIES,
  getCurrenciesSuccess,
  getCurrenciesFail,
  getTipsCategoriesSuccess,
  getTipsCategoriesFail,
  GET_TIPS_CATEGORIES,
  getTipsPrioritiesSuccess,
  getTipsPrioritiesFail,
  GET_TIPS_PRIORITIES,
  getFeedsCategoriesSuccess,
  getFeedsCategoriesFail,
  GET_FEEDS_CATEGORIES,
  getFaqCategoriesSuccess,
  getFaqCategoriesFail,
  GET_FAQ_CATEGORIES,
  getFaqSubcategoriesSuccess,
  getFaqSubcategoriesFail,
  GET_FAQ_SUBCATEGORIES,
  getRoomCategoriesSuccess,
  getRoomCategoriesFail,
  GET_ROOM_CATEGORIES,
  getApplicationSwitchTypesSuccess,
  getApplicationSwitchTypesFail,
  GET_APPLICATION_SWITCH_TYPES,
  GET_INVENTORY_STATUS,
  GET_INCIDENT_STATUS,
  GET_INCIDENT_PRIORITIES,
  getIncidentStatusFail,
  getInventoryStatusSuccess,
  getInventoryStatusFail,
  getIncidentPrioritiesSuccess,
  getIncidentPrioritiesFail,
  getIncidentStatusSuccess
} from './enum.actions';
import * as service from './enum.service';
import { without } from 'ramda';

export function* getGenderList() {
  try {
    const genders = yield call(service.getGenderList);
    yield put(getGenderListSuccess(genders));
  } catch (err) {
    yield put(getGenderListFail(err));
  }
}

export function* getPaymentMethodList() {
  try {
    const paymentMethods = yield call(service.getPaymentMethodList);
    yield put(getPaymentMethodListSuccess(paymentMethods));
  } catch (err) {
    yield put(getPaymentMethodListFail(err));
  }
}

export function* getGrades() {
  try {
    const grades = yield call(service.getInventoryGrades);
    yield put(getGradesSuccess(grades));
  } catch (err) {
    yield put(getGradesFail(err));
  }
}

export function* getDefects() {
  try {
    const defects = yield call(service.getInventoryDefects);
    yield put(getDefectsSuccess(defects));
  } catch (err) {
    yield put(getDefectsFail(err));
  }
}

export function* getNationalitiesList(action) {
  try {
    const list = yield call(service.getNationalities, {
      criteria: { search: action.payload, attributes: ['code', 'country'] }
    });
    yield put(getCountriesSuccess(list));
  } catch (err) {
    yield put(getCountriesFail(err));
  }
}

export function* getApplicationStatus() {
  try {
    const status = yield call(service.getApplicationStatus);
    yield put(getApplicationStatusSuccess(status));
  } catch (err) {
    yield put(getApplicationStatusFail(err));
  }
}

export function* getInventoryStatus() {
  try {
    const status = yield call(service.getInventoryStatus);
    yield put(getInventoryStatusSuccess(status));
  } catch (err) {
    yield put(getInventoryStatusFail(err));
  }
}

export function* getIncidentStatus() {
  try {
    const status = yield call(service.getIncidentStatus);
    yield put(getIncidentStatusSuccess(status));
  } catch (err) {
    yield put(getIncidentStatusFail(err));
  }
}

export function* getIncidentPriorities() {
  try {
    const list = yield call(service.getIncidentPriority);
    yield put(getIncidentPrioritiesSuccess(list));
  } catch (err) {
    yield put(getIncidentPrioritiesFail(err));
  }
}

export function* getRoles() {
  try {
    const roles = yield call(service.getRoles);
    yield put(getRoleSuccess(without(['user'], roles)));
  } catch (err) {
    yield put(getRoleFail(err));
  }
}

export function* getCities() {
  try {
    const cities = yield call(service.getCities, {});
    yield put(getCitiesSuccess(cities));
  } catch (err) {
    yield put(getCitiesFail(err));
  }
}

export function* getCurrencies() {
  try {
    const currencies = yield call(service.getCurrencies, {});
    yield put(getCurrenciesSuccess(currencies));
  } catch (err) {
    yield put(getCurrenciesFail(err));
  }
}

export function* getTipsCategories() {
  try {
    const categories = yield call(service.getTipsCategories, {});
    yield put(getTipsCategoriesSuccess(categories));
  } catch (err) {
    yield put(getTipsCategoriesFail(err));
  }
}

export function* getTipsPriorities() {
  try {
    const priorities = yield call(service.getTipsPriorities, {});
    yield put(getTipsPrioritiesSuccess(priorities));
  } catch (err) {
    yield put(getTipsPrioritiesFail(err));
  }
}

export function* getFeedsCategories() {
  try {
    const categories = yield call(service.getFeedsCategories, {});
    yield put(getFeedsCategoriesSuccess(categories));
  } catch (err) {
    yield put(getFeedsCategoriesFail(err));
  }
}

export function* getFaqCategories() {
  try {
    const categories = yield call(service.getFaqCategories, {});
    yield put(getFaqCategoriesSuccess(categories));
  } catch (err) {
    yield put(getFaqCategoriesFail(err));
  }
}

export function* getFaqSubcategories() {
  try {
    const categories = yield call(service.getFaqSubcategories, {});
    yield put(getFaqSubcategoriesSuccess(categories));
  } catch (err) {
    yield put(getFaqSubcategoriesFail(err));
  }
}

export function* getRoomCategories() {
  try {
    const categories = yield call(service.getRoomCategories, {});
    yield put(getRoomCategoriesSuccess(categories));
  } catch (err) {
    yield put(getRoomCategoriesFail(err));
  }
}

export function* getApplicationSwitchTypes() {
  try {
    const switchTypes = yield call(service.getApplicationSwitchTypes, {});
    yield put(getApplicationSwitchTypesSuccess(switchTypes));
  } catch (err) {
    yield put(getApplicationSwitchTypesFail(err));
  }
}

export default function* enumSaga() {
  yield all([
    takeLatest(GET_GENDER_LIST, getGenderList),
    takeLatest(GET_PAYMENT_METHOD_LIST, getPaymentMethodList),
    takeLatest(GET_NATIONALITIES_LIST, getNationalitiesList),
    takeLatest(GET_APPLICATION_STATUS, getApplicationStatus),
    takeLatest(GET_ROLE, getRoles),
    takeLatest(GET_GRADES, getGrades),
    takeLatest(GET_DEFECTS, getDefects),
    takeLatest(GET_CITIES, getCities),
    takeLatest(GET_CURRENCIES, getCurrencies),
    takeLatest(GET_INVENTORY_STATUS, getInventoryStatus),
    takeLatest(GET_INCIDENT_STATUS, getIncidentStatus),
    takeLatest(GET_INCIDENT_PRIORITIES, getIncidentPriorities),
    takeLatest(GET_TIPS_CATEGORIES, getTipsCategories),
    takeLatest(GET_TIPS_PRIORITIES, getTipsPriorities),
    takeLatest(GET_FEEDS_CATEGORIES, getFeedsCategories),
    takeLatest(GET_FAQ_CATEGORIES, getFaqCategories),
    takeLatest(GET_FAQ_SUBCATEGORIES, getFaqSubcategories),
    takeLatest(GET_ROOM_CATEGORIES, getRoomCategories),
    takeLatest(GET_APPLICATION_SWITCH_TYPES, getApplicationSwitchTypes)
  ]);
}
