import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { translate } from '../../translate/i18n';
import { func, bool, string } from 'prop-types';
import { Button, TextInput, Text } from '../../components';
import { capitalize, required } from '../../utils';
import { resetPassword } from '../user.actions';

const ResetPasswordForm = (props) => {
  const { handleSubmit, submitting, t, error, formError, submitSucceeded } = props;

  return (
    <form onSubmit={handleSubmit(resetPassword)}>
      <Field
        disabled={submitting || submitSucceeded}
        validate={required}
        name="password"
        component={TextInput}
        type="password"
        placeholder={t('PASSWORD')}
        label={`${capitalize(t('NEW_PASSWORD'))}*`}
      />
      <Field
        disabled={submitting || submitSucceeded}
        validate={required}
        name="confirmPassword"
        component={TextInput}
        type="password"
        placeholder={t('PASSWORD')}
        label={`${capitalize(t('CONFIRM_PASSWORD'))}*`}
      />
      <Button
        mb
        bg-primary
        size="md"
        type="submit"
        disabled={submitting || submitSucceeded || formError}
        name="resetPassword">
        {capitalize(t('default:SAVE'))}
      </Button>
      {!!error && <Text red={500}>{t(`validation:${error}`)}</Text>}
      {!!formError && <Text red={500}>{t('validation:INVALID_RESET_PASSWORD_URL')}</Text>}
    </form>
  );
};

ResetPasswordForm.defaultProps = {
  formError: false
};

ResetPasswordForm.propTypes = {
  handleSubmit: func.isRequired,
  submitting: bool,
  t: func,
  submitSucceeded: bool,
  error: string,
  formError: bool
};

const validate = (values) => {
  const errors = {};
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'DIFFERENT_PASSWORDS';
  }

  return errors;
};

export default compose(
  reduxForm({
    form: 'ResetPasswordForm',
    validate
  }),
  translate(['auth', 'validation'])
)(ResetPasswordForm);
