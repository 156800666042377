import React from 'react';
import { func, string, array } from 'prop-types';
import moment from 'moment-timezone';
import { compose } from 'ramda';
import { translate } from '../../translate/i18n';
import { isEmptyOrNil } from '../../utils';
import { discountCalculator } from '../discountCalculator';
import { Container, Row, Text, Col } from '../../components';

const LeaseDiscountList = ({ t, list, incomingPrice, currencySymbol, leaseStartDate }) => {
  if (isEmptyOrNil(list)) {
    return null;
  }
  const formattedAmount = ({ amount, type, startDate }) => {
    return type === 'percent' ? (
      <Row>
        <Text size="sm" weight={300} pr-double>
          {`${amount} %`}
        </Text>
        <Text size="sm">
          (
          {discountCalculator({
            priceValue: +incomingPrice,
            discountValue: +amount,
            discountType: type,
            leaseStartDate: new Date(leaseStartDate),
            discountStartDate: new Date(startDate)
          })}
          {currencySymbol})
        </Text>
      </Row>
    ) : (
      <Text size="sm" weight={300}>{`${amount} ${currencySymbol}`}</Text>
    );
  };

  const formatDate = (date) => moment.utc(date).format('MMM YYYY');

  return (
    <Container column mt-quarter>
      <Row left>
        <Text size="lg" weight={300} grey pb-quarter>
          {t('DISCOUNT_LIST_TITLE')}
        </Text>
      </Row>

      {list.map((discount) => (
        <div className="discount-details lease" key={discount.id}>
          <Row>
            <Col lg={4} mt>
              <Row>
                <Col lg={12}>
                  <Text size="md" weight={300} lightGray pb-quarter>
                    {t('START_DATE')}
                  </Text>
                </Col>

                <Col lg={12} mt-quarter>
                  <Text size="sm" weight={300} grey pb-quarter>
                    {formatDate(discount.startDate)}
                  </Text>
                </Col>
              </Row>
            </Col>

            <Col lg={4} mt>
              <Row>
                <Col lg={12}>
                  <Text size="md" weight={300} lightGray pb-quarter>
                    {t('LEASE_ITEM_END_DATE')}
                  </Text>
                </Col>

                <Col lg={12} mt-quarter>
                  <Text size="sm" weight={300} grey>
                    {formatDate(discount.endDate)}
                  </Text>
                </Col>
              </Row>
            </Col>

            <Col lg={4} mt>
              <Row>
                <Col lg={12}>
                  <Text size="md" weight={300} lightGray pb-quarter>
                    {t('AMOUNT')}
                  </Text>
                </Col>

                <Col lg={12} mt-quarter>
                  <Text size="sm" weight={300} grey>
                    {formattedAmount(discount)}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  );
};

LeaseDiscountList.propTypes = {
  t: func,
  list: array,
  timezone: string,
  incomingPrice: string,
  currencySymbol: string
};

export default compose(translate('discounts'))(LeaseDiscountList);
