import React, { Fragment, useState } from 'react';
import { func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { loadStripe } from '@stripe/stripe-js';
import { Row, Text, Button, FreeTextInput, Selector, Col } from '../components';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { translate } from '../translate/i18n';
import { createPaymentProfileMethod, updatePaymentProfileMethod } from './paymentProfileMethod.actions';

import './stripe.css';
import { capitalize } from '../utils';
import DeleteSource from './paymentProfileMethod.deleteSource';

const PaymentProfileMethodCard = ({
  member,
  currentHub,
  currentProfile,
  hubMethod,
  profileMethod,
  currentGateway,
  createPaymentProfileMethodCall,
  updatePaymentProfileMethodCall,
  t
}) => {
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountHolderType, setAccountHolderType] = useState('');

  const [error, setError] = useState(null);

  const makeToken = async () => {
    const stripe = await loadStripe(currentGateway.apiKey);

    const { token, error } = await stripe.createToken('bank_account', {
      routing_number: routingNumber,
      account_number: accountNumber,
      account_holder_name: accountHolderName,
      account_holder_type: accountHolderType,
      currency: currentHub.currencyCode,
      country: currentHub.city.country.abbreviation
    });

    if (error) {
      setError(error.message);
      return;
    }

    return token.id;
  };

  const create = async () => {
    const token = await makeToken();

    if (token) {
      createPaymentProfileMethodCall({
        userId: member.id,
        hubId: currentHub.id,
        type: hubMethod.payment_method.type,
        token
      });
    }
  };

  const update = async () => {
    const token = await makeToken();

    if (token) {
      updatePaymentProfileMethodCall({
        userId: member.id,
        hubId: currentHub.id,
        type: hubMethod.payment_method.type,
        token
      });
    }
  };

  return (
    <Fragment>
      {profileMethod && profileMethod.stripeData && (
        <Fragment>
          <Row>
            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:profile.BANK_NAME')}
              </Text>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.bankName}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:ROUTING_NUMBER')}
              </Text>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.routingNumber}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:ACCOUNT_NUMBER')}
              </Text>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                ****
                {profileMethod.stripeData.last4}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:ACCOUNT_HOLDER_NAME')}
              </Text>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.accountHolderName}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:ACCOUNT_HOLDER_TYPE')}
              </Text>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.accountHolderType}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Row right>
                <DeleteSource
                  member={member}
                  currentHub={currentHub}
                  currentProfile={currentProfile}
                  hubMethod={hubMethod}
                />
              </Row>
            </Col>
          </Row>
        </Fragment>
      )}

      <Row mb>
        <FreeTextInput
          label={capitalize(t('member:ROUTING_NUMBER'))}
          name="routingNumber"
          placeholder={t('member:ROUTING_NUMBER')}
          value={routingNumber}
          onChange={(e) => setRoutingNumber(e.target.value)}
        />
      </Row>

      <Row mb>
        <FreeTextInput
          label={capitalize(t('member:ACCOUNT_NUMBER'))}
          name="accountNumber"
          placeholder={t('member:ACCOUNT_NUMBER')}
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
        />
      </Row>

      <Row mb>
        <FreeTextInput
          label={capitalize(t('member:ACCOUNT_HOLDER_NAME'))}
          name="accountHolderName"
          placeholder={t('member:ACCOUNT_HOLDER_NAME')}
          value={accountHolderName}
          onChange={(e) => setAccountHolderName(e.target.value)}
        />
      </Row>

      <Row mb>
        <Col lg={12}>
          <Selector
            label={capitalize(t('member:ACCOUNT_HOLDER_TYPE'))}
            name="accountHolderType"
            placeholder={t('member:ACCOUNT_HOLDER_TYPE')}
            value={accountHolderType}
            onChange={(e) => setAccountHolderType(e)}
            options={[
              {
                label: t('member:INDIVIDUAL'),
                value: 'individual'
              },
              {
                label: t('member:COMPANY'),
                value: 'company'
              }
            ]}
          />
        </Col>
      </Row>

      <div className="card-errors" role="alert">
        {error}
      </div>
      {profileMethod ? (
        <Row mb right>
          <Button size="md" onClick={update} name="btn-save" bg-primary mr-half>
            {t('default:UPDATE')}
          </Button>
          {!profileMethod.stripeData && (
            <DeleteSource
              member={member}
              currentHub={currentHub}
              currentProfile={currentProfile}
              hubMethod={hubMethod}
            />
          )}
        </Row>
      ) : (
        <Row mb right>
          <Button size="md" type="submit" name="btn-save" bg-primary mr-half onClick={create}>
            {t('default:SAVE')}
          </Button>
        </Row>
      )}
    </Fragment>
  );
};

PaymentProfileMethodCard.propTypes = {
  t: func,
  member: object.isRequired,
  currentHub: object.isRequired,
  currentProfile: object,
  hubMethod: object,
  profileMethod: object,
  currentGateway: object,
  createPaymentProfileMethodCall: func,
  updatePaymentProfileMethodCall: func
};

export default compose(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        createPaymentProfileMethodCall: createPaymentProfileMethod.request,
        updatePaymentProfileMethodCall: updatePaymentProfileMethod.request
      },
      dispatch
    )
  ),
  translate()
)(PaymentProfileMethodCard);
