import { omit } from 'ramda';
import * as api from '@cohabs/bo-api';
import { defaultPaginationLimit } from '../utils';

export const getKanbanApplicants = ({ criteria = {} }) => api.post('/applications/kanban', { criteria });

export const updateAplicant = (applicant) => api.patch(`/application/${applicant.id}`, omit(['houses'], applicant));

export const getListApplicants = ({ page = 1, limit = defaultPaginationLimit, criteria = {}, order = [] }) =>
  api.post(`/applications/${page}/${limit}`, { criteria, order });

export const getApplicant = (id) => {
  if (!id) throw Error('Applicant id is not defined');
  return api.get(`/application/${id}`);
};

export const sendReminders = ({ applicationId, roomIds }) =>
  api.post(`/application/${applicationId}/send/reminder`, { roomIds });

export const sendOffers = ({ applicationId, roomIds }) =>
  api.post(`/application/${applicationId}/send/offer`, { roomIds });

export const deleteRooms = ({ applicationId, roomIds }) => api.del(`/application/${applicationId}/rooms`, { roomIds });

export const getRoomsAvailability = (applicationId, params = {}) =>
  api.post(`/application/${applicationId}/availability`, params);

export const refreshApplicationsRooms = (applicationId) => api.patch(`/application/${applicationId}/rooms`);

export const addNewRoom = ({ applicationId, roomId, rent, moveInDate, discountApplies }) =>
  api.post(`/application/${applicationId}/room`, { roomId, rent, moveInDate, discountApplies });

export const getSignatureLink = (applicationId, roomId) =>
  api.get(`/application/${applicationId}/room/${roomId}/signaturelink`);

export const getApplicationVerifications = (applicationId) => api.get(`/applications/${applicationId}/verifications`);

export const getUserDocuments = (userId) => api.get(`/user/${userId}/documents`);

export const updateApplicationVerification = (requisiteId, applicationId) =>
  api.post('/applications/verifications', { requisiteId, applicationId });

export const deleteApplicationVerification = (requisiteId, applicationId) =>
  api.del(`/applications/${applicationId}/verifications/${requisiteId}`);

export const getApplicationFee = (applicationId) => api.put(`/application/${applicationId}/fee`);

export const deleteUserFile = (fileId, userId) => api.del(`/file/${fileId}/user/${userId}`);

export const getKYCWizardLink = (cmsId) => api.get(`/application/booking/${cmsId}`);

export const getLastApplicationFee = (applicationId) => api.get(`/application/${applicationId}/fee/last`);

export const getApplicationsRooms = (applicationId) => api.get(`/application/${applicationId}/rooms`);

export const getValidateApplicationFee = (applicationId) => api.get(`/application/${applicationId}/fee/isValid`);
