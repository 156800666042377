export const HANDLE_ERROR_MESSAGE = 'HANDLE_ERROR_MESSAGE';
export const HANDLE_SUCCESS_MESSAGE = 'HANDLE_SUCCESS_MESSAGE';

export const handleErrorMessage = (key, vars) => {
  return {
    type: HANDLE_ERROR_MESSAGE,
    payload: { key, vars }
  };
};

export const handleSuccessMessage = (key, vars) => ({
  type: HANDLE_SUCCESS_MESSAGE,
  payload: { key, vars }
});
