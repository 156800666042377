import { string } from 'prop-types';
import styled from '@emotion/styled';
import { Phone } from 'react-feather';
import { Text } from '../components';
import { translate } from '../translate/i18n';
import React from 'react';

const defaultContainerProps = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  width: '90%',
  border: '1px solid',
  borderRadius: '30px',
  cursor: 'move',
  marginTop: '5px',
  padding: '2px 10px 2px 10px'
};

const ScheduledCallContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.lightGrey[50],
  fontFamily: theme.font.family
}));

const MissedCallContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.lightOrange[500],
  fontFamily: theme.font.family
}));

const CompletedCallContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.green[200],
  fontFamily: theme.font.family
}));

const SkippedCallContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.white,
  fontFamily: theme.font.family
}));

const DefaultCallContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.red[200],
  fontFamily: theme.font.family
}));

const formatStatus = (callStatus) => {
  const statusWithDefault = callStatus || 'cancelled';
  const formattedStatus = statusWithDefault.slice(0, 1).toUpperCase() + statusWithDefault.slice(1).replace(/_/g, ' ');

  return `Call: ${formattedStatus}`;
};

const getCallContainer = (status) => {
  switch (status) {
    case 'scheduled':
      return ScheduledCallContainer;
    case 'missed':
      return MissedCallContainer;
    case 'completed':
      return CompletedCallContainer;
    case 'skipped':
      return SkippedCallContainer;
    default:
      return DefaultCallContainer;
  }
};

const KanbanCallItem = ({ status, callStatus }) => {
  if (status !== 'call') {
    return null;
  }

  const formattedStatus = formatStatus(callStatus);
  const CallContainer = getCallContainer(callStatus);

  return (
    <CallContainer>
      <Phone size={16} />
      <Text left pt-quarter pb-quarter size="sm" weight={200}>
        {formattedStatus}
      </Text>
    </CallContainer>
  );
};

KanbanCallItem.propTypes = {
  status: string,
  callStatus: string
};

export default translate('applications')(KanbanCallItem);
