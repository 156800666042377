import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, pathOr, prop } from 'ramda';
import { translate } from '../translate/i18n';
import { object, func, string, bool, oneOfType, number } from 'prop-types';
import { Container, Row, Col, Text, Loader } from '../components';
import { getStayInfo } from './member.actions';
import { emptyObject, upperCase, valueOrPlaceholder, isNotEmptyOrNil } from '../utils';
import { DateFormItem } from '../components/date';

const RenderElement = ({ label, value, formatter }) => (
  <Container column>
    <Text grey={400} weight={300} size="xs" label>
      {label}
    </Text>
    <Text weight={300} size="md" grey>
      {valueOrPlaceholder(isNotEmptyOrNil, formatter)(value)}
    </Text>
  </Container>
);

RenderElement.propTypes = {
  formatter: (value) => value
};

RenderElement.propTypes = {
  label: string.isRequired,
  value: oneOfType([string, number]),
  formatter: func
};

class StayInfo extends PureComponent {
  static propTypes = {
    data: object,
    t: func,
    getStayInfo: func,
    memberId: string,
    isFetching: bool
  };

  componentDidMount() {
    const { getStayInfo, memberId } = this.props;
    getStayInfo(memberId);
  }

  render() {
    const { t, data, isFetching } = this.props;

    if (isFetching) return <Loader />;
    return (
      <Fragment>
        <Text pt-double weight={300} size="lg">
          {t('stayInfo.TITLE')}
        </Text>

        <Row mt>
          <Col xl={4} lg={4} md={6} sm={6} mt>
            <RenderElement
              label={upperCase(t('stayInfo.LOCATION'))}
              value={pathOr('', ['room', 'house', 'hub', 'name'], data)}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} mt>
            <RenderElement label={upperCase(t('stayInfo.HOUSE'))} value={pathOr('', ['room', 'house', 'name'], data)} />
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} mt>
            <RenderElement label={upperCase(t('stayInfo.ROOM'))} value={pathOr('', ['room', 'number'], data)} />
          </Col>
        </Row>

        <Row mt>
          <Col xl={4} lg={4} md={6} sm={6} mt>
            <DateFormItem
              label={upperCase(t('stayInfo.ENTRY_DATE'))}
              date={prop('startDate', data)}
              timezone={prop('timezone', data)}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={6} mt>
            <DateFormItem
              label={upperCase(t('stayInfo.MOVE_OUT_DATE'))}
              date={prop('moveoutDate', data) ? prop('moveoutDate', data) : prop('endDate', data)}
              timezone={prop('timezone', data)}
            />
          </Col>
          {data?.preferredMoveoutDate && (
            <Col xl={4} lg={4} md={6} sm={6} mt>
              <DateFormItem
                label={upperCase(t('stayInfo.PREFERRED_MOVE_OUT_DATE'))}
                date={prop('preferredMoveoutDate', data)}
                timezone={prop('timezone', data)}
              />
            </Col>
          )}
        </Row>
      </Fragment>
    );
  }
}

export default compose(
  translate('member'),
  connect(
    (state) => ({
      data: pathOr(emptyObject, ['member', 'current', 'stayInfo', 'data'], state),
      isFetching: pathOr(emptyObject, ['member', 'current', 'stayInfo', 'isFetching'], state)
    }),
    { getStayInfo }
  )
)(StayInfo);
