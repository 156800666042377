import React, { Fragment, useState } from 'react';
import { object, func, number } from 'prop-types';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { compose, path, pathOr, prop, propOr } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Menu } from 'react-feather';

import { Row, Env, Button, sizing, Modal } from '../components';
import { toggleCollapseMenu } from './actions';

import ChatBar from './chatBar';
import Header from './header';
import MainMenu from './menu';
import UserForm from '../user/User.form';
import { translate } from '../translate/i18n';

const TabContainer = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  height: '100vh',
  borderRight: `2px solid ${theme.colors.grey[100]}`,
  background: theme.colors.grey[500],
  position: 'relative',
  overflow: 'auto',
  zIndex: 2
}));

const MainMenuContainer = ({ t, menu, history, toggleCollapseMenu, windowWidth, user }) => {
  const [show, setModal] = useState(false);
  const currentRoute = path(['location', 'pathname'], history);

  const autoCollapse = windowWidth <= 1024;

  return (
    <Fragment>
      <Modal
        title={t('USER')}
        show={!!show}
        onClose={() => setModal(false)}
        content={() => (
          <UserForm
            initialValues={{
              id: prop('id', user),
              picture: prop('picture', user),
              role: prop('role', user),
              lastName: prop('lastName', user),
              firstName: prop('firstName', user),
              email: prop('email', user),
              phone: {
                number: propOr('', 'phoneNumber', user),
                prefix: {
                  value: pathOr('', ['phonePrefix', 'code'], user),
                  prefix: pathOr('', ['phonePrefix', 'prefix'], user)
                }
              }
            }}
            onClose={() => setModal(false)}
          />
        )}
      />

      <div className="main-menu-burger">
        <Button name="add" onClick={() => toggleCollapseMenu(!menu.collapse)} clear size="md">
          <Row middle center>
            <Menu size={32} />
          </Row>
        </Button>
      </div>

      <div className="main-menu">
        <TabContainer>
          <Header currentRoute={currentRoute} setUserUpdateModal={() => setModal(true)} />
          <ChatBar
            currentRoute={currentRoute}
            onSelect={() => {
              if (autoCollapse) {
                toggleCollapseMenu(true);
              }
            }}
          />

          <MainMenu />
        </TabContainer>
      </div>

      <Env />
    </Fragment>
  );
};

MainMenuContainer.propTypes = {
  toggleCollapseMenu: func,
  windowWidth: number,
  menu: object,
  user: object,
  history: object
};

export default compose(
  translate(['user']),
  sizing(),
  withRouter,
  connect(
    (state) => ({
      user: path(['user', 'data'], state),
      menu: path(['menu'], state)
    }),
    (dispatch) => bindActionCreators({ toggleCollapseMenu }, dispatch)
  )
)(MainMenuContainer);
