/* eslint-disable max-lines */
/**
 * The current file contains more than 400 lines,
 * because this component need to be refactored and divided several smaller components
 */
import React, { useState, useEffect } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import { func, object, bool } from 'prop-types';
import { reduxForm, propTypes as reduxFormPropTypes, Field, FormSection, getFormValues } from 'redux-form';
import {
  compose,
  isEmpty,
  pathOr,
  prop,
  propOr,
  filter,
  omit,
  equals,
  reject,
  pipe,
  either,
  isNil,
  map,
  when,
  is
} from 'ramda';
import Tooltip from 'react-tooltip-lite';
import { translate } from '../translate/i18n';
import { upsertHouse } from './houses.actions';
import StyledForm from '../components/form.styles';
import {
  isEmptyOrNil,
  isNotEmptyOrNil,
  formatFullname,
  capitalize,
  positiveOrNull,
  required,
  streetAddress,
  emptyObject,
  emptyArray
} from '../utils';
import {
  Text,
  TextInput,
  Container,
  Block,
  Button,
  Row,
  Col,
  BottomContainer,
  Upload,
  DatePicker,
  SelectHubForm,
  SelectCollectionForm,
  AddressAutocompleteForm,
  SectionTitle,
  ConfirmModal,
  ToggleForm
} from '../components';
import LandlordFormSection from '../landlord/landlord.form';
import { getHouseStripeUrl, clearHouseLocalStorage } from './houses.service';
import StyledStripeLink from '../components/StripeLink.styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchHubPaymentGateways } from '../paymentGateway/paymentGateway.actions';
import { fetchHubs } from '../hub/hub.actions';
import moment from 'moment-timezone';
import { Info } from 'react-feather';

const clean = (o) => o && pipe(reject(either(isNil, isEmpty)), map(when(is(Object), clean)))(o);

const formHasNoChanges = ({ info, landlord } = {}) => isEmptyOrNil(clean(info) || isEmptyOrNil(clean(landlord)));

const findHouseManager = (house) => {
  return propOr({}, 'manager', house);
};

const initShowStripe = (house) => prop('id', house) && isEmptyOrNil(localStorage.getItem('house'));

const ConnectToStripeButton = compose(
  translate('form'),
  connect(
    (state) => ({
      paymentGateway: pathOr(emptyObject, ['paymentGateway'], state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          fetchHubPaymentGateways
        },
        dispatch
      )
  )
)(({ onClick, house, t, paymentGateway, fetchHubPaymentGateways }) => {
  useEffect(() => {
    if (!paymentGateway.isResult) {
      fetchHubPaymentGateways();
    }
  }, []);

  const gateway = paymentGateway.list.find((item) => item.id === house.hub.paymentGatewayId);

  if (!gateway) {
    return null;
  }

  const stripeUrl = getHouseStripeUrl(gateway.clientId);

  return (
    <StyledStripeLink href={stripeUrl} title={t('CONNECT_TO_STRIPE')} onClick={onClick}>
      {t('CONNECT_TO_STRIPE')}
    </StyledStripeLink>
  );
});

const renderStripeButton = (t, house, showStripe, onAbort, onClick) => {
  if (prop('stripeAccountId', house)) {
    return <Text mt-half>{t('HOUSE_STRIPE_CONNECTED')}</Text>;
  } else if (showStripe) {
    return <ConnectToStripeButton onClick={onClick} house={house} />;
  }

  return (
    <Row>
      <Text noWrap={false} mr>
        {t('HOUSE_STRIPE_IN_PROGRESS')}
      </Text>
      <Button bg-secondary name="abortStripeConnect" size="xs" onClick={onAbort}>
        {t('ABORT')}
      </Button>
    </Row>
  );
};

const HouseForm = (props) => {
  const { t, history, isNew, handleSubmit, submitting, submitSucceeded, house, hubs } = props;

  // State
  const [showConfirmModal, toggleConfirmModal] = useState(false);
  const [showStripe, toggleStripe] = useState(initShowStripe(house));
  const [hub, setHub] = useState(propOr({ currencyCode: 'EUR' }, 'hub', house));
  const [prompt, showPrompt] = useState(false);

  // state updates
  const setCurrentHub = (_, hubId) => {
    hubId && setHub(filter((hub) => hub.id === hubId, hubs)[0]);
  };
  let timezone = pathOr(moment.tz.guess(), ['timezone'], hub);

  useEffect(() => {
    if (isEmpty(hubs)) {
      fetchHubs();
    }
  }, []);
  useEffect(() => {
    house.hub && setHub(house.hub);
  }, [house]);

  if (submitSucceeded && isNew) {
    return <Redirect to="/houses" />;
  }

  const houseManager = findHouseManager(house);

  // Functions
  const beforeAbort = () => toggleConfirmModal(true);
  const storeHouseAndRedirect = () => {
    if (showStripe) {
      const timestamp = new Date();

      localStorage.setItem('house', prop('id', house));
      localStorage.setItem('timestamp', timestamp.getTime());

      return true;
    }

    return false;
  };

  const checkFormChanges = async (event) => {
    event.preventDefault();

    const initialValues = {
      ...props.initialValues,
      info: {
        ...omit(['openingDate'], props.initialValues.info)
      }
    };

    const actualFormValues = {
      ...props.formValues,
      info: {
        ...omit(['openingDate'], props.formValues.info)
      }
    };

    if (isNew && formHasNoChanges(actualFormValues)) {
      return showPrompt(false);
    }

    const noDifference = !!equals(JSON.stringify(actualFormValues), JSON.stringify(initialValues));

    if (noDifference) {
      return showPrompt(false);
    }

    return showPrompt(true);
  };

  return (
    <Block>
      {!isNew && house.id && (
        <Row column pt-half pb-half pl pr>
          <Col lg={12}>
            <SectionTitle translationKey="form:STRIPE_INFO" />

            <div>{renderStripeButton(t, house, showStripe, beforeAbort, storeHouseAndRedirect)}</div>
          </Col>
        </Row>
      )}

      <StyledForm onSubmit={handleSubmit(upsertHouse)} onBlur={checkFormChanges}>
        <FormSection name="info">
          <Container column pt-half pb-half pl pr>
            <SectionTitle translationKey="form:GENERAL_INFO" />

            {isNotEmptyOrNil(houseManager) && (
              <Container mb column bb-lightGrey>
                <Row>
                  <Text size="md" mr-half weight={200}>
                    {`${t('CURRENT_MANAGER')}:`}
                  </Text>
                </Row>

                <Row pb-half>
                  <Text size="md" weight={400}>
                    {formatFullname(houseManager)}
                  </Text>
                </Row>
              </Container>
            )}

            <Row mb>
              <Col lg={12} pr-half pl-half>
                <Field component={ToggleForm} label={t('form:ACTIVE')} name="active" type="checkbox" />
              </Col>
            </Row>

            <Row>
              <Col lg={4} pr-half pl-half>
                <Field
                  name="name"
                  component={TextInput}
                  type="text"
                  label={`${capitalize(t('NAME'))}*`}
                  placeholder={t('NAME')}
                  validate={required}
                />
              </Col>
              <Col lg={4} pr-half pl-half>
                <Field
                  name="abbreviation"
                  component={TextInput}
                  label={`${capitalize(t('ABBREVIATION'))}*`}
                  placeholder={t('ABBREVIATION')}
                  validate={required}
                />
              </Col>
              <Col lg={4} center pr-half pl-half>
                <Row center middle>
                  <Field
                    name="cover"
                    accept={['image/png', 'image/jpeg']}
                    size={100}
                    isFix={true}
                    component={Upload}
                    placeholder={t('ADD_COVER')}
                    validate={required}
                  />
                </Row>
              </Col>
            </Row>

            <Row top mb>
              <Col lg={4} mt-half pr-half pl-half>
                <Field
                  name="collectionId"
                  label={`${capitalize(t('house:COLLECTION'))}*`}
                  component={SelectCollectionForm}
                  placeholder={t('TYPE_A_COLLECTION_NAME')}
                  validate={required}
                />
              </Col>
              <Col lg={4} mt-half pr-half pl-half>
                <Field
                  name="hub"
                  label={`${capitalize(t('house:HUB'))}*`}
                  component={SelectHubForm}
                  placeholder={t('TYPE_A_HUB_NAME')}
                  onChange={setCurrentHub}
                  validate={required}
                />
              </Col>
            </Row>
            <Row top mb>
              <Col lg={4} pt-half pr-half pl-half>
                <Field
                  name="address"
                  component={AddressAutocompleteForm}
                  label={`${capitalize(t('ADDRESS'))}*`}
                  validate={[required, streetAddress]}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} pr-half pl-half>
                <Field
                  name="openingDate"
                  component={DatePicker}
                  label={`${capitalize(t('OPENING_DATE'))}*`}
                  validate={required}
                  timezone={timezone}
                />
              </Col>
              <Col lg={4} pr-half pl-half>
                <Field
                  name="theme"
                  component={TextInput}
                  label={`${capitalize(t('THEME'))}*`}
                  placeholder={t('THEME')}
                  validate={required}
                />
              </Col>
              <Col lg={4} center pr-half pl-half>
                <Row center middle>
                  <Field
                    name="logo"
                    size={100}
                    isFix={true}
                    accept={['image/png', 'image/jpeg']}
                    component={Upload}
                    placeholder={t('ADD_LOGO')}
                    validate={required}
                  />
                </Row>
              </Col>
            </Row>
            <Row mb>
              <Col lg={4} pr-half pl-half>
                <Field
                  name="roomNumber"
                  component={TextInput}
                  disabled={!isNew}
                  type="number"
                  label={`${capitalize(t('NB_ROOMS'))}*`}
                  placeholder={t('NB_ROOMS')}
                  validate={[required, positiveOrNull]}
                />
              </Col>
              <Col lg={4} pr-half pl-half>
                <Field
                  name="inventoryCost"
                  component={TextInput}
                  type="number"
                  min="0"
                  step="0.01"
                  label={`${capitalize(`${t('INVENTORY_COST')} (${hub.currencyCode})`)}*`}
                  placeholder={'e.g. 100'}
                />
              </Col>
            </Row>
            <Row mb>
              <Col lg={4} pr-half pl-half>
                <Field
                  name="cleaningCost"
                  component={TextInput}
                  type="number"
                  min="0"
                  step="0.01"
                  label={`${capitalize(`${t('CLEANING_COST')} (${hub.currencyCode})`)}*`}
                  placeholder={'e.g. 100'}
                  validate={[required]}
                />
              </Col>
              <Col lg={4} pr-half pl-half>
                <Field
                  name="rentalCharges"
                  component={TextInput}
                  type="number"
                  min="0"
                  step="0.01"
                  label={`${capitalize(`${t('BILLS_PER_ROOM')} (${hub.currencyCode})`)}*`}
                  Icon={() => (
                    <div style={{ height: '14px', marginBottom: '4px', paddingLeft: '4px' }}>
                      <Tooltip content={t('TOOLTIP_BILLS_PER_ROOM')} direction="up">
                        <Info size={14} />
                      </Tooltip>
                    </div>
                  )}
                  placeholder={'e.g. 100'}
                  validate={[required]}
                />
              </Col>
            </Row>
          </Container>
        </FormSection>

        <Container column p>
          <LandlordFormSection />
        </Container>

        <BottomContainer>
          <Row right>
            <Button name="submit" disabled={submitting} clear size="md" onClick={() => history.push('/houses')}>
              {t('CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </BottomContainer>
      </StyledForm>

      <Prompt when={prompt && !submitSucceeded} message={() => t('default:UNSAVED_CHANGES')} />

      <ConfirmModal
        title={capitalize(t('WARNING'))}
        show={showConfirmModal}
        onClose={() => toggleConfirmModal(false)}
        onConfirm={() => {
          clearHouseLocalStorage(true);
          toggleStripe(true);
          toggleConfirmModal(false);
        }}
        content={() => (
          <Container column>
            <Text>{t('HOUSE_STRIPE_WARNING_MSG')}</Text>
          </Container>
        )}
      />
    </Block>
  );
};

HouseForm.propTypes = {
  t: func,
  match: object,
  isNew: bool,
  ...reduxFormPropTypes
};

export default compose(
  reduxForm({ form: 'HouseForm', enableReinitialize: true }),
  translate('form'),
  connect(
    (state) => ({
      hubs: pathOr(emptyArray, ['hub', 'list'], state),
      isFetching: pathOr(emptyArray, ['hub', 'isFetching'], state),
      formValues: getFormValues('HouseForm')(state)
    }),
    { fetchHubs }
  )
)(HouseForm);
