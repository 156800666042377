import React, { useState, Fragment } from 'react';
import { func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Row, Text, Button, Col } from '../components';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { translate } from '../translate/i18n';
import { createPaymentProfileMethod, updatePaymentProfileMethod } from './paymentProfileMethod.actions';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { getSetupIntents } from './paymentProfileMethod.service';

import DeleteSource from './paymentProfileMethod.deleteSource';

import './stripe.css';

const PaymentProfileMethodCard = ({
  member,
  currentHub,
  currentProfile,
  hubMethod,
  profileMethod,
  createPaymentProfileMethodCall,
  updatePaymentProfileMethodCall,
  t
}) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const { clientSecret } = await getSetupIntents({
      userId: member.id,
      hubId: currentHub.id,
      type: hubMethod.payment_method.type
    });

    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card
      }
    });

    if (error) {
      setError(error.message);
    } else {
      setError(null);

      const data = {
        userId: member.id,
        hubId: currentHub.id,
        type: hubMethod.payment_method.type,
        stripePaymentMethodId: setupIntent.payment_method
      };

      if (profileMethod) {
        updatePaymentProfileMethodCall(data);
        return;
      }

      createPaymentProfileMethodCall(data);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {profileMethod && profileMethod.stripeData && (
        <Fragment>
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:profile.CARD')}
              </Text>
            </Col>

            <Col xl={4} lg={4} md={4} sm={4} mb>
              <Text size="md" weight={300} pr-half>
                **** **** **** {profileMethod.stripeData.last4}
              </Text>
            </Col>
          </Row>

          <Row mb>
            <Col xl={4} lg={4} md={4} sm={4} mb>
              <Text size="md" grey={300} pr-half>
                {t('member:profile.EXPIRE')}
              </Text>
            </Col>

            <Col xl={4} lg={4} md={4} sm={4} mb>
              <Text size="md" weight={300} pr-half>
                {profileMethod.stripeData.expMonth}/{profileMethod.stripeData.expYear}
              </Text>
            </Col>

            <Col xl={4} lg={4} md={4} sm={4}>
              <Row right>
                <DeleteSource
                  member={member}
                  currentHub={currentHub}
                  currentProfile={currentProfile}
                  hubMethod={hubMethod}
                />
              </Row>
            </Col>
          </Row>
        </Fragment>
      )}

      <CardElement
        id="card-element"
        options={{
          style: {
            base: {
              color: '#32325d',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4'
              }
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a'
            }
          }
        }}
        onChange={handleChange}
      />
      <div className="card-errors" role="alert">
        {error}
      </div>
      {profileMethod ? (
        <Row mb right mt-half>
          <Button size="md" type="submit" name="btn-save" bg-primary mr-half>
            {t('default:UPDATE')}
          </Button>
          {!profileMethod.stripeData && (
            <DeleteSource
              member={member}
              currentHub={currentHub}
              currentProfile={currentProfile}
              hubMethod={hubMethod}
            />
          )}
        </Row>
      ) : (
        <Row mb mt-half right>
          <Button size="md" type="submit" name="btn-save" bg-primary mr-half>
            {t('default:SAVE')}
          </Button>
        </Row>
      )}
    </form>
  );
};

PaymentProfileMethodCard.propTypes = {
  t: func,
  member: object.isRequired,
  currentHub: object.isRequired,
  currentProfile: object,
  hubMethod: object,
  profileMethod: object,
  createPaymentProfileMethodCall: func,
  updatePaymentProfileMethodCall: func
};

export default compose(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        createPaymentProfileMethodCall: createPaymentProfileMethod.request,
        updatePaymentProfileMethodCall: updatePaymentProfileMethod.request
      },
      dispatch
    )
  ),
  translate()
)(PaymentProfileMethodCard);
