import React from 'react';
import { string, func, number, object, bool } from 'prop-types';
import moment from 'moment-timezone';
import { Info } from 'react-feather';
import Tooltip from 'react-tooltip-lite';
import { translate } from '../translate/i18n';
import { discountCalculator } from './discountCalculator';
import { formatDate, formatPrice } from '../utils';

const DiscountToolTip = ({
  t,
  direction = 'up',
  translationKey,
  incomingPrice,
  currencySymbol,
  enabled = true,
  discount: { type, amount, duration, redeemBy } = {}
}) => {
  const discountDuration = moment.duration(duration, 'month').humanize();
  const unitPrefix = type === 'percent' ? '%' : currencySymbol;
  const discountValue = `${formatPrice(amount)}${unitPrefix}`;
  const discountCalculatedValue = `(${discountCalculator({
    priceValue: incomingPrice,
    discountValue: amount,
    discountType: type
  })}${currencySymbol})`;

  return enabled ? (
    <Tooltip
      className="discount-tooltip"
      content={t(translationKey, {
        discountValue,
        ...(type === 'percent' && { calculatedValue: discountCalculatedValue }),
        duration: discountDuration,
        redeemBy: formatDate(redeemBy)
      })}
      direction={direction}>
      <Info size={14} color="#333" className="ico info" />
    </Tooltip>
  ) : (
    <Tooltip className="discount-tooltip disabled" content={t('discounts:TOOLTIP_DISABLED')} direction={direction}>
      <Info size={14} className="ico info" />
    </Tooltip>
  );
};

DiscountToolTip.propTypes = {
  t: func.isRequired,
  discount: object.isRequired,
  translationKey: string.isRequired,
  currencySymbol: string.isRequired,
  incomingPrice: number.isRequired,
  enabled: bool
};

export default translate()(DiscountToolTip);
