import React, { Fragment } from 'react';
import { func, string } from 'prop-types';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { Row, Text } from '../components';

const inventoryComment = ({ t, comment }) => {
  if (!comment) {
    return null;
  }

  return (
    <Fragment>
      <Row middle mb>
        <Text weight={300} size="lg">
          {t('COMMENT')}
        </Text>
      </Row>
      <Row middle mb>
        <Text noWrap={false} size="md" weight={200} type="container" html={comment.replace(/\n/gm, '<br>')} />
      </Row>
    </Fragment>
  );
};

inventoryComment.propTypes = {
  t: func,
  comment: string
};

inventoryComment.defaultProps = {
  comment: ''
};

export default compose(translate('inventories'))(inventoryComment);
