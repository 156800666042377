import {
  DELETE_USER_DOCUMENTS,
  DELETE_USER_DOCUMENTS_FAIL,
  DELETE_USER_DOCUMENTS_SUCCESS,
  GET_APPLICATION_FEE,
  SET_APPLICATION_FEE,
  ON_DROP,
  APPEND_APPLICATIONS,
  APPEND_APPLICATIONS_FAIL,
  APPEND_APPLICATIONS_SUCCESS,
  SET_CURRENT_DRAGGABLE,
  GET_KANBAN_APPLICANTS,
  GET_KANBAN_APPLICANTS_SUCCESS,
  GET_APPLICATIONS,
  GET_APPLICATIONS_SUCCESS,
  FETCH_APPLICANT,
  REFRESH_APPLICANT,
  FETCH_APPLICANT_SUCESS,
  FETCH_APPLICANT_FAIL,
  UPDATE_APPLICANT,
  UPDATE_APPLICANT_SUCCESS,
  UPDATE_APPLICANT_FAIL,
  DELETE_AVAILABLE_ROOMS,
  DELETE_AVAILABLE_ROOMS_SUCCESS,
  DELETE_AVAILABLE_ROOMS_FAIL,
  SEND_OFFERS_ROOM,
  SEND_OFFERS_ROOM_FAIL,
  SEND_OFFERS_ROOM_SUCCESS,
  SEND_REMINDERS,
  SEND_REMINDERS_FAIL,
  SEND_REMINDERS_SUCCESS,
  ADD_NEW_ROOM,
  ADD_NEW_ROOM_SUCCESS,
  ADD_NEW_ROOM_FAIL,
  UPDATE_APPLICANT_INFO,
  UPDATE_APPLICANT_STATUS,
  UPDATE_APPLICANT_STATUS_SUCCESS,
  UPDATE_APPLICANT_STATUS_FAIL,
  UPDATE_APPLICANT_KYC_STATUS,
  UPDATE_APPLICANT_KYC_STATUS_SUCCESS,
  UPDATE_APPLICANT_KYC_STATUS_FAIL,
  UPDATE_APPLICANT_CALL_STATUS,
  UPDATE_APPLICANT_CALL_STATUS_SUCCESS,
  UPDATE_APPLICANT_CALL_STATUS_FAIL,
  GET_APPLICATIONS_FAILURE,
  GET_APPLICATION_VERIFICATIONS,
  GET_APPLICATION_VERIFICATIONS_SUCCESS,
  GET_APPLICATION_VERIFICATIONS_FAIL,
  GET_USER_DOCUMENTS,
  GET_USER_DOCUMENTS_SUCCESS,
  GET_USER_DOCUMENTS_FAIL,
  UPDATE_APPLICATION_VERIFICATION,
  UPDATE_APPLICATION_VERIFICATION_SUCCESS,
  UPDATE_APPLICATION_VERIFICATION_FAIL,
  DELETE_APPLICATION_VERIFICATION,
  DELETE_APPLICATION_VERIFICATION_SUCCESS,
  DELETE_APPLICATION_VERIFICATION_FAIL,
  GET_FILTERED_APPLICATIONS_SUCCESS,
  GET_FILTERED_APPLICATIONS,
  GET_FILTERED_APPLICATIONS_FAILURE,
  GET_VALIDATE_APPLICATION_FEE,
  SET_VAILDATE_APPLICATION_FEE
} from './applications.actionTypes';
import { createFormAction } from 'redux-form-saga';
import i18n from '../translate/i18n';
import { toast } from '../utils';

export const getApplicationFee = (payload) => ({
  type: GET_APPLICATION_FEE,
  payload
});

export const setApplicationFee = (payload) => ({
  type: SET_APPLICATION_FEE,
  payload
});

export const setCurrentDraggable = (item) => ({
  type: SET_CURRENT_DRAGGABLE,
  payload: item
});

export const onItemDrop = (item, dropSection) => ({
  type: ON_DROP,
  payload: { item, dropSection }
});

export const getKanbanApplicants = (params = {}) => ({
  type: GET_KANBAN_APPLICANTS,
  payload: params
});

export const setKanbanApplicants = (applicants) => ({
  type: GET_KANBAN_APPLICANTS_SUCCESS,
  payload: applicants
});

export const getFilteredApplications = (params = {}) => ({
  type: GET_FILTERED_APPLICATIONS,
  payload: params
});

export const setFilteredApplicationsSuccess = (applications) => ({
  type: GET_FILTERED_APPLICATIONS_SUCCESS,
  payload: applications
});

export const setFilteredApplicationsFailure = (params = {}) => ({
  type: GET_FILTERED_APPLICATIONS_FAILURE,
  payload: params
});

export const getListApplications = (params = {}) => ({
  type: GET_APPLICATIONS,
  payload: params
});

export const setListApplications = (applications) => ({
  type: GET_APPLICATIONS_SUCCESS,
  payload: applications
});

export const getListApplicationsFailure = (params = {}) => ({
  type: GET_APPLICATIONS_FAILURE,
  payload: params
});

export const appendListApplications = (params = {}) => ({
  type: APPEND_APPLICATIONS,
  payload: params
});

export const appendListApplicationsSuccess = (params = {}) => ({
  type: APPEND_APPLICATIONS_SUCCESS,
  payload: params
});

export const appendListApplicationsFail = (params = {}) => ({
  type: APPEND_APPLICATIONS_FAIL,
  payload: params
});

export const fetchApplicant = (applicantId) => ({
  type: FETCH_APPLICANT,
  payload: applicantId
});

export const refreshApplicant = () => ({
  type: REFRESH_APPLICANT
});

export const fetchApplicantSuccess = (applicant) => ({
  type: FETCH_APPLICANT_SUCESS,
  payload: applicant
});

export const fetchApplicantFail = (err) => ({
  type: FETCH_APPLICANT_FAIL,
  payload: err
});

export const updateApplicant = (applicant) => ({
  type: UPDATE_APPLICANT,
  payload: applicant
});

export const updateApplicantSuccess = (applicant) => ({
  type: UPDATE_APPLICANT_SUCCESS,
  payload: applicant
});

export const updateApplicantFail = (err) => ({
  type: UPDATE_APPLICANT_FAIL,
  payload: err
});

export const updateApplicantStatus = (applicant) => ({
  type: UPDATE_APPLICANT_STATUS,
  payload: applicant
});

export const updateApplicantStatusSuccess = (applicant) => ({
  type: UPDATE_APPLICANT_STATUS_SUCCESS,
  payload: applicant
});

export const updateApplicantStatusFail = (err) => ({
  type: UPDATE_APPLICANT_STATUS_FAIL,
  payload: err
});

export const updateApplicantKYCStatus = (applicant) => ({
  type: UPDATE_APPLICANT_KYC_STATUS,
  payload: applicant
});

export const updateApplicantKYCStatusSuccess = (applicant) => ({
  type: UPDATE_APPLICANT_KYC_STATUS_SUCCESS,
  payload: applicant
});

export const updateApplicantKYCStatusFail = (err) => ({
  type: UPDATE_APPLICANT_KYC_STATUS_FAIL,
  payload: err
});

export const updateApplicantCallStatus = (applicant) => ({
  type: UPDATE_APPLICANT_CALL_STATUS,
  payload: applicant
});

export const updateApplicantCallStatusSuccess = (applicant) => ({
  type: UPDATE_APPLICANT_CALL_STATUS_SUCCESS,
  payload: applicant
});

export const updateApplicantCallStatusFail = (err) => ({
  type: UPDATE_APPLICANT_CALL_STATUS_FAIL,
  payload: err
});

export const deleteAvailableRooms = (params) => ({
  type: DELETE_AVAILABLE_ROOMS,
  payload: params
});

export const deleteAvailableRoomsSuccess = (roomIds) => ({
  type: DELETE_AVAILABLE_ROOMS_SUCCESS,
  payload: roomIds
});

export const deleteAvailableRoomsFail = (err) => ({
  type: DELETE_AVAILABLE_ROOMS_FAIL,
  payload: err
});

export const sendOfferRooms = (params) => ({
  type: SEND_OFFERS_ROOM,
  payload: params
});

export const sendOfferRoomsSuccess = (roomId) => ({
  type: SEND_OFFERS_ROOM_SUCCESS,
  payload: roomId
});

export const sendOfferRoomsFail = (err) => ({
  type: SEND_OFFERS_ROOM_FAIL,
  payload: err
});

export const sendRemindersRooms = (params) => ({
  type: SEND_REMINDERS,
  payload: params
});

export const sendRemindersRoomsSuccess = (roomId) => ({
  type: SEND_REMINDERS_SUCCESS,
  payload: roomId
});

export const sendRemindersRoomsFail = (err) => ({
  type: SEND_REMINDERS_FAIL,
  payload: err
});

export const addRoom = (room) => ({
  type: ADD_NEW_ROOM,
  payload: room
});

export const addNewRoomSuccess = (roomList) => ({
  type: ADD_NEW_ROOM_SUCCESS,
  payload: roomList
});

export const addNewRoomFail = (err) => ({
  type: ADD_NEW_ROOM_FAIL,
  payload: err
});

export const updateApplicantInfo = createFormAction(UPDATE_APPLICANT_INFO);

export const getVerifications = (applicationId) => ({
  type: GET_APPLICATION_VERIFICATIONS,
  payload: applicationId
});

export const getVerificationsSuccess = (list) => ({
  type: GET_APPLICATION_VERIFICATIONS_SUCCESS,
  payload: list
});

export const getVerificationsFail = (err) => {
  const error = i18n.getResource(i18n.language, 'applications', 'GET_APPLICATION_VERIFICATIONS_ERROR');
  toast.error(error);

  return {
    type: GET_APPLICATION_VERIFICATIONS_FAIL,
    payload: err
  };
};

export const getUserDocuments = (userId) => ({
  type: GET_USER_DOCUMENTS,
  payload: userId
});

export const getUserDocumentsSuccess = (list) => ({
  type: GET_USER_DOCUMENTS_SUCCESS,
  payload: list
});

export const getUserDocumentsFail = (err) => {
  const error = i18n.getResource(i18n.language, 'applications', 'GET_USER_DOCUMENTS_ERROR');
  toast.error(error);

  return {
    type: GET_USER_DOCUMENTS_FAIL,
    payload: err
  };
};

export const updateApplicationVerification = (requisiteId, applicationId) => ({
  type: UPDATE_APPLICATION_VERIFICATION,
  payload: { requisiteId, applicationId }
});

export const updateApplicationVerificationSuccess = (payload) => ({
  type: UPDATE_APPLICATION_VERIFICATION_SUCCESS,
  payload
});

export const updateApplicationVerificationFail = (err) => ({
  type: UPDATE_APPLICATION_VERIFICATION_FAIL,
  payload: err
});

export const deleteApplicationVerification = (requisiteId, applicationId) => ({
  type: DELETE_APPLICATION_VERIFICATION,
  payload: { requisiteId, applicationId }
});

export const deleteApplicationVerificationSuccess = (payload) => ({
  type: DELETE_APPLICATION_VERIFICATION_SUCCESS,
  payload
});

export const deleteApplicationVerificationFail = (err) => ({
  type: DELETE_APPLICATION_VERIFICATION_FAIL,
  payload: err
});

export const deleteUserDocuments = (payload) => ({
  type: DELETE_USER_DOCUMENTS,
  payload
});

export const deleteUserDocumentsSuccess = (list) => ({
  type: DELETE_USER_DOCUMENTS_SUCCESS,
  payload: list
});

export const deleteUserDocumentsFail = (err) => {
  return {
    type: DELETE_USER_DOCUMENTS_FAIL,
    payload: err
  };
};

export const getValidateApplicationFee = (payload) => ({
  type: GET_VALIDATE_APPLICATION_FEE,
  payload
});

export const setValidateApplicationFee = (payload) => ({
  type: SET_VAILDATE_APPLICATION_FEE,
  payload
});
