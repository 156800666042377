import mapBoxSdk from '@mapbox/mapbox-sdk';
import geocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { pathOr } from 'ramda';
import { emptyArray } from '../utils';

const mapboxClient = mapBoxSdk({ accessToken: process.env.REACT_APP_MAP_BOX });

export const getGoogleMapCDN = () =>
  `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAP_API_KEY}&libraries=geocode,places`;

export const geocodingClient = geocoding(mapboxClient);

export const fetchAdress = (query = '') =>
  geocodingClient
    .forwardGeocode({
      query,
      limit: 5
    })
    .send()
    .then((response) => pathOr(emptyArray, ['body', 'features'], response));

export const fetchPlaceFromGps = ({ coordinates = [], limit = 1 }) =>
  geocodingClient
    .reverseGeocode({
      query: coordinates,
      types: ['place'],
      limit
    })
    .send()
    .then(pathOr('', ['body', 'features', 0]));
