import React from 'react';
import Selector from './Selector';
import { translate } from '../../translate/i18n';
import { func, string, bool, oneOfType, number, object } from 'prop-types';
import { isEmpty } from 'ramda';
import { customStyles } from './Selector';

const handleChange = (onChange) => {
  return (value) => {
    if (!onChange) return;
    if (isEmpty(value)) return onChange();
    return onChange(value);
  };
};

const SelectorDurationStay = (props) => {
  const { name, onChange, label, value, isInForm, isDisabled, t, theme } = props;

  const list = [
    {
      value: 3,
      label: 3
    },
    {
      value: 6,
      label: 6
    },
    {
      value: 9,
      label: 9
    },
    {
      value: 12,
      label: 12
    },
    {
      value: Infinity,
      label: t('default:INFINITY')
    }
  ];

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={list}
      label={label}
      value={value}
      isMulit={false}
      onChange={handleChange(onChange)}
      isDisabled={isDisabled}
      styles={customStyles(theme)}
    />
  );
};
SelectorDurationStay.propTypes = {
  isInForm: bool,
  t: func,
  name: string.isRequired,
  onChange: func,
  label: string,
  value: oneOfType([string, number]),
  isDisabled: bool,
  theme: object
};

SelectorDurationStay.defaultValues = {
  isInForm: false
};

export default translate('form')(SelectorDurationStay);
