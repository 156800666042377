import React, { useEffect, useState } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, compose, equals, isEmpty } from 'ramda';

import { emptyArray, makeOptions } from '../../utils';
import { fetchHubsOnly } from '../../geo/geo.actions';
import { storableSelector } from '../storableDecorator';
import Selector from './Selector';

const SelectorGeoHub = ({
  fetchHubsOnly,
  cityId,
  list,
  name,
  isMulti,
  label,
  value,
  isInForm,
  isDisabled,
  placeholder,
  isFetching,
  isClearable,
  onChange,
  valueControl
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    fetchHubsOnly(cityId);
  }, [cityId]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    const nextOptions = makeOptions(list, 'id', 'name', 'label');

    if (!equals(nextOptions, options || [])) {
      setOptions(nextOptions);
    }
  }, [list, isFetching]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isClearable={isClearable}
    />
  );
};

SelectorGeoHub.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  list: array.isRequired,
  isFetching: bool.isRequired,
  fetchHubsOnly: func.isRequired,
  cityId: string
};

SelectorGeoHub.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  cityId: null,
  value: null
};

export default compose(
  storableSelector,
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['geo', 'hub', 'list'], state),
      isFetching: pathOr(emptyArray, ['geo', 'hub', 'isFetching'], state)
    }),
    { fetchHubsOnly }
  )
)(SelectorGeoHub);
