import React from 'react';
import SelectorPaymentMethod from '../selector/Selector.paymentmethod';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';

const SelectPaymentMethodForm = ({ t, input, label, isDisabled, meta: { touched, error, placeholder } }) => (
  <InputContainer>
    <SelectorPaymentMethod label={label} {...input} isInForm={true} isDisabled={isDisabled} placeholder={placeholder} />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectPaymentMethodForm);
