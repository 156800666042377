import React from 'react';
import SelectorHub from '../selector/Selector.hub';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';

const SelectHubForm = ({ t, input, label, isDisabled, meta: { touched, error }, placeholder }) => (
  <InputContainer>
    <SelectorHub label={label} {...input} isInForm={true} isDisabled={isDisabled} placeholder={placeholder} />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectHubForm);
