import React, { Fragment, useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose, path, pathOr, omit } from 'ramda';
import { withRouter } from 'react-router-dom';
import { translate } from '../translate/i18n';
import { getRoom } from './room.actions';
import { Container, Block, Col, Back, Row, Text, Loader, SectionTitle } from '../components';
import { emptyObject, isNotEmptyOrNil } from '../utils';
import RoomForm from './Room.form';
import RoomLockSection from './Room.lockSection';
import DiscountPromiseForm from '../discounts/discountPromise/form';

const prepareInitialValues = (isNew = true, room) => {
  return isNew ? {} : { ...room, locks: [omit(['pin', 'iqSecret'], room.lock)] };
};

const Room = (props) => {
  // Props
  const { match, t, room, getRoom, isFetching, history } = props;

  // variables
  const id = path(['params', 'id'], match);
  const isNew = id === 'create';
  const initialValues = prepareInitialValues(isNew, room);
  const showForm = isNew || isNotEmptyOrNil(room);
  const showDiscountForm = showForm && !isNew;

  // Hooks
  useEffect(() => {
    if (!isNew) {
      getRoom(id);
    }
  }, []);

  // Render
  return (
    <Fragment>
      {isFetching ? (
        <Container full center middle>
          <Loader />
        </Container>
      ) : (
        <Container column>
          <Block>
            <Row p>
              <Back to={'/rooms'} label={t('BACK_TO_ROOM_LIST')} />
            </Row>

            <Row pl>
              <Text size="lg" weight={200}>
                {isNew ? t('NEW_ROOM') : t('EDIT_ROOM')}
              </Text>
            </Row>
          </Block>
          {showForm && (
            <RoomForm isNew={isNew} room={room} initialValues={initialValues} onCancel={() => history.push('/rooms')} />
          )}
          {/* </Block> */}
          {!isNew && (
            <Col xl={9} lg={9} md={9} sm={12}>
              <RoomLockSection room={room} />
            </Col>
          )}

          {showDiscountForm && (
            <Block p>
              <SectionTitle translationKey="discounts:TITLE" />
              <Row>
                <Col lg={12}>
                  <DiscountPromiseForm
                    discountablePromiseType="room"
                    discountablePromiseId={room.id}
                    discount={pathOr(null, ['discount_promise'], room)}
                    incomingPrice={pathOr(0, ['rent'], room)}
                    currency={pathOr(emptyObject, ['house', 'hub', 'currency'], room)}
                    timezone={pathOr('', ['house', 'hub', 'timezone'], room)}
                  />
                </Col>
              </Row>
            </Block>
          )}
        </Container>
      )}
    </Fragment>
  );
};

Room.propTypes = {
  t: func,
  match: object,
  room: object,
  isFetching: bool,
  getRoom: func,
  history: object
};

export default compose(
  withRouter,
  translate('rooms'),
  connect(
    (state) => ({
      isFetching: pathOr(false, ['room', 'current', 'isFetching'], state),
      room: pathOr(emptyObject, ['room', 'current', 'data'], state)
    }),
    { getRoom }
  )
)(Room);
