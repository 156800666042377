import React from 'react';
import { compose, pathOr } from 'ramda';
import { reduxForm, Field, propTypes as reduxFormPropTypes, getFormValues } from 'redux-form';
import { translate } from '../translate/i18n';
import { func, bool, any, object } from 'prop-types';
import {
  TextInput,
  Container,
  ActionsContainer,
  Row,
  Button,
  Col,
  SelectRoleForm,
  Text,
  PhoneForm,
  Upload
} from '../components';
import { capitalize, required, email, hasPhonePrefix, emptyObject } from '../utils';
import { upsertUser } from './user.actions';
import { connect } from 'react-redux';
import { User } from 'react-feather';

import { AvatarContainer, Avatar } from '../members/Member.profile.style';

const UserForm = ({ t, handleSubmit, onClose, submitting, submitSucceeded, error, user }) => {
  if (submitSucceeded) {
    onClose();
  }

  return (
    <Container p column>
      <form onSubmit={handleSubmit(upsertUser)}>
        <Row center pb>
          <Col lg={12}>
            <Row center>
              <Field
                component={Upload}
                name="picture"
                size={170}
                accept={['image/png', 'image/jpeg']}
                isFix={true}
                backgroundColor="transparent"
                replaceable
                ContentView={({ file, handleClick }) => (
                  <Row center>
                    <Col lg={12}>
                      <AvatarContainer>
                        <Avatar>
                          {file ? (
                            <img
                              style={{ minWidth: '100%', minHeight: '100%', objectFit: 'cover' }}
                              alt="profile"
                              src={file}
                            />
                          ) : (
                            <User size={50} color="white" />
                          )}
                        </Avatar>
                      </AvatarContainer>
                    </Col>
                    <Col lg={12}>
                      <Button m-none p-none name="view-user-picture" onClick={handleClick} clear>
                        <Text cursor="pointer" underline>
                          {t('UPLOAD_PICTURE')}
                        </Text>
                      </Button>
                    </Col>
                  </Row>
                )}
              />
            </Row>
          </Col>

          <Col lg={12} mr>
            {user.role === 'admin' && (
              <Field validate={required} label={`${t('form:ROLE')}*`} name="role" component={SelectRoleForm} />
            )}
            <Field validate={required} label={`${t('form:FIRST_NAME')}*`} name="firstName" component={TextInput} />
            <Field validate={required} label={`${t('form:LAST_NAME')}*`} name="lastName" component={TextInput} />
            <Field
              validate={[required, email]}
              label={`${t('form:EMAIL')}*`}
              name="email"
              type="email"
              component={TextInput}
            />
            <Field
              name="phone"
              label={t('PHONE_NUMBER')}
              type="tel"
              component={PhoneForm}
              validate={[required, hasPhonePrefix]}
              placeholder={t('PHONE_NUMBER')}
            />
          </Col>
        </Row>

        <Col lg={12}>{error && <Text red={500}>{t(`validation:${error}`)}</Text>}</Col>
        <ActionsContainer>
          <Row middle right mb mt>
            <Button clear mr-half size="md" onClick={onClose} name="cancel" disabled={submitting}>
              {capitalize(t('default:CANCEL'))}
            </Button>
            <Button bg-primary size="md" name="save" type="submit" disabled={submitting}>
              {capitalize(t('default:SAVE'))}
            </Button>
          </Row>
        </ActionsContainer>
      </form>
    </Container>
  );
};
UserForm.propTypes = {
  t: func,
  user: object,
  handleSubmit: func,
  onClose: func,
  submitting: bool,
  error: any,
  ...reduxFormPropTypes
};

export default compose(
  reduxForm({
    form: 'UserForm'
  }),
  connect((state) => ({
    user: pathOr(emptyObject, ['user', 'data'], state),
    values: getFormValues('UserForm')(state)
  })),
  translate('user')
)(UserForm);
