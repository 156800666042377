import React, { Fragment, useState } from 'react';
import { func, object, number, bool, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { pathOr, prop, path } from 'ramda';
import { translate } from '../translate/i18n';
import { getPayments, appendPayments } from './payments.actions';
import {
  capitalize,
  valueOrPlaceholder,
  formatMonth,
  isNotEmptyOrNil,
  emptyArray,
  formatDatetime,
  formatDate
} from '../utils';
import { Row, Text, ScrollableDataTableWithColSelector, Modal } from '../components';
import PaymentDetailsForm from './Payment.details.form';

const getColumnDefs = (t) => [
  {
    headerName: t('MONTH'),
    field: 'payment.dueDate',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatMonth)(col.value, 'UTC')
  },
  {
    headerName: t('DUE_DATE'),
    field: 'payment.dueDate',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDate)(col.value, 'UTC'),
    tooltip: ({ value }) => valueOrPlaceholder(isNotEmptyOrNil, () => 'UTC', '')(value)
  },
  {
    headerName: t('EXPECTED_AMOUNT'),
    field: 'payment.amount',
    valueFormatter: ({ data, value }) => `${pathOr('', ['payment', 'hub', 'currency', 'symbol'], data)} ${value}`
  },
  {
    headerName: t('RECEIVED_AMOUNT'),
    defaultActive: true,
    field: 'payment.paid',
    valueFormatter: ({ data, value }) => {
      if (pathOr(false, ['payment', 'pending'], data)) {
        return t('PENDING');
      }
      if (pathOr(false, ['payment', 'disputed'], data)) {
        return t('DISPUTED');
      }
      return `${pathOr('', ['payment', 'hub', 'currency', 'symbol'], data)} ${value}`;
    }
  },
  {
    headerName: t('PAYMENT_DATE'),
    defaultActive: true,
    field: 'payment.paymentDate',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDatetime)(col.value, 'UTC'),
    tooltip: ({ value }) => valueOrPlaceholder(isNotEmptyOrNil, () => 'UTC', '')(value)
  }
];

const PrebookingFee = (props) => {
  // Props
  const { t, payments, paymentCount, isFetching, appendPayments, getPayments, responseLength, member, hub } = props;

  // State
  const [selectedPayment, selectPayment] = useState(null);
  const [showModal, toggleModal] = useState(false);

  // Functions
  const showDetailedPayment = (event) => {
    selectPayment(prop('data', event));
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
    selectPayment(null);
  };

  // Render
  return (
    <Fragment>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {capitalize(t('PREBOOKING_FEE_HISTORY'))}
        </Text>
      </Row>

      <ScrollableDataTableWithColSelector
        columnDefs={getColumnDefs(t)}
        defaultColDef={{
          valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
          sortable: false
        }}
        getMethod={(params) =>
          getPayments({ ...params, userId: member.id, hubId: hub.id, paymentType: 'prebooking_fee' })
        }
        appendMethod={(params) =>
          appendPayments({ ...params, userId: member.id, hubId: hub.id, paymentType: 'prebooking_fee' })
        }
        rows={payments}
        rowCount={paymentCount}
        responseLength={responseLength}
        isFetching={isFetching}
        onCellClicked={({ data }) => {
          showDetailedPayment({ data });
        }}
      />

      <Modal
        title={t('PAYMENT_DETAILS')}
        show={showModal}
        onClose={closeModal}
        content={() => (
          <PaymentDetailsForm
            initialValues={{
              comment: path(['payment', 'comment'], selectedPayment),
              id: path(['payment', 'id'], selectedPayment),
              paymentType: 'prebooking_fee'
            }}
            onCancel={closeModal}
            payment={selectedPayment}
          />
        )}
      />
    </Fragment>
  );
};

PrebookingFee.propTypes = {
  history: object,
  t: func,
  payments: array,
  paymentCount: number,
  isFetching: bool,
  appendPayments: func,
  getPayments: func,
  responseLength: number,
  member: object,
  hub: object
};

export default compose(
  translate(['payments']),
  connect(
    (state) => ({
      payments: pathOr(emptyArray, ['payments', 'list'], state),
      paymentCount: pathOr(0, ['payments', 'count'], state),
      responseLength: pathOr(0, ['payments', 'responseLength'], state),
      isFetching: pathOr(false, ['payments', 'isFetching'], state)
    }),
    { getPayments, appendPayments }
  )
)(PrebookingFee);
