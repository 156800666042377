import { createFormAction } from 'redux-form-saga';

import {
  GET_PAYMENT_PROFILES,
  SET_PAYMENT_PROFILES,
  GET_PAYMENT_PROFILE,
  SET_PAYMENT_PROFILE,
  CREATE_PAYMENT_PROFILE,
  UPDATE_PAYMENT_PROFILE
} from './paymentProfile.actionTypes';

export const fetchPaymentProfiles = (memberId) => ({
  type: GET_PAYMENT_PROFILES,
  memberId
});

export const setPaymentProfiles = (payload) => ({
  type: SET_PAYMENT_PROFILES,
  payload
});

export const fetchPaymentProfile = ({ memberId, hubId }) => ({
  type: GET_PAYMENT_PROFILE,
  payload: { memberId, hubId }
});

export const setPaymentProfile = (payload) => ({
  type: SET_PAYMENT_PROFILE,
  payload
});

export const createPaymentProfile = createFormAction(CREATE_PAYMENT_PROFILE);
export const updatePaymentProfile = createFormAction(UPDATE_PAYMENT_PROFILE);
