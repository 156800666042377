import React, { Fragment } from 'react';
import { array, func, string } from 'prop-types';
import { FieldArray } from 'redux-form';
import { translate } from '../../translate/i18n';
import { Selector, Row, DayRecurrence } from '..';
import { StyledLabel, InputContainer } from './TextInput.styles';
import { makeRecurrence, days } from '../../utils';

const filterRecurrences = (recurrences) =>
  recurrences.reduce((acc, rec) => {
    if (rec !== null) {
      const date = new Date(rec.firstDate);

      acc.push(date.getDay());
    }

    return acc;
  }, []);

const RecurrenceInput = (props) => {
  const { t, recurrences, change, timezone } = props;

  const daysOptions = days.map((day) => ({ label: t(`time:${day.name}`), value: day.number }));

  return (
    <Fragment>
      <InputContainer>
        <StyledLabel>{t('SELECT_WEEKDAY')}</StyledLabel>
        <Selector
          isMulti
          options={daysOptions}
          value={filterRecurrences(recurrences)}
          onChange={(days) => {
            const newRecurrences = recurrences.slice();

            for (let i = 0; i < newRecurrences.length; i++) {
              // Add selected days if not exist
              if (!newRecurrences[i] && days.includes(i)) {
                newRecurrences[i] = makeRecurrence(i);
              }

              // Removes not selected days
              if (newRecurrences[i] && !days.includes(i)) {
                newRecurrences[i] = null;
              }
            }

            change('recurrences', newRecurrences);
          }}
        />
      </InputContainer>

      <Row>
        <FieldArray component={DayRecurrence} name="recurrences" timezone={timezone} />
      </Row>
    </Fragment>
  );
};

RecurrenceInput.propTypes = {
  t: func,
  recurrences: array,
  change: func,
  timezone: string
};

export default translate('form')(RecurrenceInput);
