import React from 'react';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';
import SelectorFaqCategory from '../selector/Selector.faqcategory';

const SelectorFaqCategoryForm = ({ t, input, label, isDisabled, placeholder, meta: { touched, error } }) => (
  <InputContainer>
    <SelectorFaqCategory label={label} placeholder={placeholder} {...input} isInForm isDisabled={isDisabled} />

    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectorFaqCategoryForm);
