export const GET_INVENTORIES = 'GET_INVENTORIES';
export const GET_INVENTORIES_SUCCESS = 'GET_INVENTORIES_SUCCESS';
export const GET_INVENTORIES_FAIL = 'GET_INVENTORIES_FAIL';

export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_FAIL = 'GET_INVENTORY_FAIL';

export const GET_LAST_INVENTORY = 'GET_LAST_INVENTORY';
export const GET_LAST_INVENTORY_FAIL = 'GET_LAST_INVENTORY_FAIL';

export const COMPARE_INVENTORIES = 'COMPARE_INVENTORIES';
export const COMPARE_INVENTORIES_SUCCESS = 'COMPARE_INVENTORIES_SUCCESS';
export const COMPARE_INVENTORIES_FAIL = 'COMPARE_INVENTORIES_FAIL';

export const APPEND_INVENTORIES = 'APPEND_INVENTORIES';
export const APPEND_INVENTORIES_SUCCESS = 'APPEND_INVENTORIES_SUCCESS';
export const APPEND_INVENTORIES_FAIL = 'APPEND_INVENTORIES_FAIL';

export const UPSERT_INVENTORY = 'UPSERT_INVENTORY';
export const CREATE_HOUSE_ISSUE = 'CREATE_HOUSE_ISSUE';

export const CLEAR_INVENTORY = 'CLEAR_INVENTORY';
