import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { func, bool } from 'prop-types';
import { Container, Row, Col, DatePicker, TextInput, ActionsContainer, Button, PhoneForm } from '../components';
import { translate } from '../translate/i18n';
import { compose, propOr, pathOr } from 'ramda';
import { capitalize, required, email } from '../utils';
import { updateApplicantInfo } from './applications.actions';

const validatePhone = (value) => {
  return !!propOr(false, 'number', value) && !!pathOr(false, ['prefix', 'prefix'], value) ? undefined : 'REQUIRED';
};

const PersonalInfoForm = ({ handleSubmit, t, submitting, onClose }) => {
  return (
    <Container column>
      <form onSubmit={handleSubmit(updateApplicantInfo)}>
        <Row center pl pr pb-double>
          <Col lg={12}>
            <Field validate={required} label={`${t('BIRTHDATE')}*`} name="birthdate" component={DatePicker} />
            <Field validate={validatePhone} type="tel" label={`${t('PHONE')}*`} name="phone" component={PhoneForm} />
            <Field
              validate={[required, email]}
              label={`${t('EMAIL')}`}
              name="email"
              type="email"
              component={TextInput}
            />
            <Field label={t('JOB')} name="job" component={TextInput} />
          </Col>
        </Row>
        <ActionsContainer>
          <Row middle p-half right>
            <Button mr-half size="md" type="button" clear onClick={onClose} name="cancel" disabled={submitting}>
              {capitalize(t('default:CANCEL'))}
            </Button>
            <Button name="save" bg-primary size="md" type="submit" disabled={submitting}>
              {capitalize(t('default:SAVE'))}
            </Button>
          </Row>
        </ActionsContainer>
      </form>
    </Container>
  );
};

PersonalInfoForm.propTypes = {
  handleSubmit: func,
  t: func,
  submitting: bool,
  onClose: func
};

export default compose(
  reduxForm({
    form: 'PersonalInfoForm'
  }),
  translate('applications')
)(PersonalInfoForm);
