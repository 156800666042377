/* eslint-disable max-lines */
import { injectGlobal } from '@emotion/css';

import { spacing } from '../theme/spacing';

injectGlobal`
  .str-chat {
    --primary-color: #AE9C74;
    --accent-blue: #AE9C74;
  }
  
  .reade-holder {
    margin-right: 5px;
    
    svg:nth-child(even) {
      margin-left: -11px;
    }
  }

  .str-chat__message-text-inner, .str-chat__message-simple-text-inner {
    font-size: inherit;
    overflow-x: hidden;
  }

  .str-chat__message-text-inner--is-emoji, .str-chat__message-simple-text-inner--is-emoji {
    font-size: 32px;
  }

  .new-channel-container-sizer {
    >div {
      min-height: 400px;
      max-height: 520px;
    }
  }
  
  .str-chat__avatar-fallback {
    background-color: #AE9C74;
  }

  .str-chat__message-simple-text-inner a {
    font-weight: 500;
    text-decoration: underline;
    color: #ffffff;
  }
  
  .chat-scale {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    position: relative;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
  }

  .str-chat__message--deleted-inner {
    background: #ddd;
    color: #777
  }

  .str-chat__input-flat {
    .str-chat__textarea {
      .str-chat__textarea__textarea {
        background: rgba(60, 73, 81, 0.1);
        
        &::placeholder {
          color: #969EA3;
        }
      }
    }
  }
  
  .str-chat__input-flat-emojiselect {
    svg {
      fill: #5D6E79;
    }
  }
  .str-chat__input-flat {
    .rfu-file-upload-button {
      svg {
        fill: #5D6E79;
      }
    }
  }

  .str-chat__message-inner {    
    .str-chat__message-data {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
    .str-chat__message-text-inner {
      color: #ffffff;
      background: #00AEA9;

      .quoted-message-inner {
        color: #7D8C95;
      }
    }
  }

  .str-chat__li--top, .str-chat__li--middle {
    .str-chat__message-data {
      display: none;
    }
  }

  .str-chat__message-simple-status {
    left: 100%;
    right: unset;
    bottom: 0;
  }
  
  .str-chat-channel {
    width: calc(100% - 300px);
    @media (max-width: ${spacing.breakpoints.lg}px) {
      width: 100%;
    }
    
    max-height: 100%;
    min-height: 100%;
    height: 100%;
    
    &.messaging {

      .str-chat__main-panel {
        border-radius: 0;
        padding: 0;
      }

      .str-chat__list {
        padding: 0 10px 0 10px;
        background: #E8EBED;
      }
    }
  }

  .str-chat {
    &.str-chat-channel-list {
      background-color: #fff;
      width: 300px;
    }
    &.str-chat-channel-list.messaging {
      @media (max-width: ${spacing.breakpoints.lg}px) {
        position: absolute;
        z-index: 999;
      }

      @media (max-width: 768px) {
        max-height: calc(100vh - 46px);
        min-height: calc(100vh - 46px);
      }
    }
    
    &.messaging {
      .str-chat__header-livestream {
        border-radius: 0;
        padding: 10px 25px;
      }

      .str-chat__thread {
        background: #E8EBED;
        margin: 20px 10px 0 10px;
        
        .str-chat__thread-header {
          background: #E8EBED;
        }
      }
      
      .str-chat__message--me {
        &.str-chat__message--deleted {
          margin: 0;
        }
      }
    }
    
    .str-chat__gallery {
      max-width: 302px;
    }
  }

  .str-chat__channel-preview-messenger {
    box-shadow: none;
    max-width: 100%;
    display: flex;
    width: 100%;
    border: none;
    padding: 8px;
    padding: var(--xs-p);
    align-items: center;
    background: transparent;
    box-shadow: 0 1px 0 0 #00000014;
    box-shadow: 0 1px 0 0 var(--border);
    text-align: left;
    margin: 8px 0;
    margin: var(--xs-m) 0;
    color: #000000;
    color: var(--black);
    
    .str-chat__channel-preview-messenger--left {
      width: 48px;
      max-width: 48px;
    }
    
    .str-chat__channel-preview-messenger--right {
      width: calc(100% - 48px);
      max-width: calc(100% - 48px);
    }
  }

  .str-chat__channel-preview-messenger--active, .str-chat__channel-preview-messenger:hover {
    background: rgba(125, 140, 149, 0.05);
    border-radius: 8px;
  }

  .str-chat__channel-search {
    background-color: #fff;
    flex-direction: column;
    padding: 0px 10px;
    
    input {
      padding: 11px 10px;
      background: #E8EBED;
      border-radius: 8px;
      
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;

    }

    input:focus {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .str-chat__message--me {
    .str-chat__message-simple-text-inner a {
      color: #AE9C74;
    }
    
    .str-chat__avatar {
      display: none;
    }
  }
  
  .str-chat__date-separator {
    flex-direction: column;
    padding: 15px 0 0 0;

    .str-chat__date-separator-line {
      display: none;
    }
    .str-chat__date-separator-date {
      font-size: 12px;
      color: #7D8C95;
    }
  }


  .str-chat__channel-list-messenger {
    background-color: #fff;
    height: calc(100% - 96px);
    max-height: calc(100% - 96px);

    .str-chat__channel-list-messenger__main {
      padding: 0 10px;
      height: 100%;
      max-height: 100%;
      overflow: auto;
    }
  }

  .str-chat *, .str-chat *::after, .str-chat *::before {
    font-family: 'Montserat';
    font-style: normal;
    font-weight: 300;
  }
  
  .str-chat__channel-preview-messenger--name {
    color: #3C4951;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .str-chat__channel-preview-messenger--last-message {
    font-size: 12px;
  }

  .str-chat__channel-preview-messenger--unread {
    .str-chat__channel-preview-messenger--last-message {
      color: #00AEA9;
      font-style: normal;
      font-weight: 300;
    }
  }

  .new-conversation {
    background: #fff;
    width: 100%;
    
    button {
      width: 100%;
      padding: 6px 25px;
    }
  }

  .str-chat__input-flat {
    background: #E8EBED;
  }

  .str-chat__message--me .str-chat__message-text-inner, .str-chat__message-simple--me .str-chat__message-text-inner {
    background: #fff;
    color: #7D8C95;
  }

  .chat-list-item-wrap {
    border-radius: 10px;
    position: relative;
    
    &.CM {
      background: #e8ffff
    }
    
    &.house {
      background: #c4ffe8
    }
    
    .chat-list-item-wrap-mark {
      position: absolute;
      top: 2px;
      right: 4px;
    }
  }

  .str-chat__container {
    background: #fff;
  }


  .str-chat__header-livestream-left--title {
    color: #000000;
  }

  .str-chat__header-livestream-left--members {
    color: #7D8C95;
  }

  .chat-bar {
    position: absolute;
    z-index: 2;
    overflow: hidden;
    background: #DFE6ED;
    max-width: 100vw;
    max-height: 100vh;

    @media (max-width: 768px) {
      max-height: calc(100vh - 46px);
    }

    .chat-bar-header {
      height: 44px;
      background: #3C4951;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: row;

        svg {
          margin: 0 11px;
        }
      }
    }

    &.full-page {
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;

      .chat-mini-holder {
        width: 100%;
        height: calc(100% - 44px);
        max-height: calc(100% - 44px);

        .str-chat-channel-list {
          width: 300px;
        }
      }
    }

    &.corner {
      bottom: 0;
      right: 50px;
      width: 373px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0px 2px 5px #ccc;

      @media (max-width: 768px) {
        right: 0px;
      }   
      
      .str-chat__message-text {
        max-width: 240px;
      }
  
      .chat-mini-holder {
        width: 100%;
        height: 600px;

        max-height: calc(100vh - 44px);

        @media (max-width: 690px) {
          max-height: calc(100vh - 90px);
        }

        .new-conversation {
          width: 100%;
          border-radius: 0;
        }
    
        .str-chat__header-hamburger--line {
          background: #7D8C95;
        }
  
        .str-chat__container {
          position: relative;
        }
  
        .str-chat {
          &.messaging {
            background-color: #fff;
            .str-chat__header-livestream {
              border-radius: 0;
              padding: 10px 25px;
            }
            
            .str-chat__thread {
              position: absolute;
              width: 100%;
              max-width: 100%;
              padding: 0;
              margin: 0;
              top: 70px;
              left: 0;
              z-index: 1;
              height: calc(100% - 70px);
            }
          }
  
          .str-chat__gallery {
            max-width: 152px;
          }
        }
  
        .str-chat__channel-list-team__main {
          width: 100%;
        }
  
        .str-chat__input-flat {
          padding: 10px;
        }
  
        .str-chat__message-team {
          padding: 0 20px 0 10px;
        }
  
  
        .str-chat-channel {
          width: 100%;
  
          &.messaging {
  
            .str-chat__main-panel {
              padding: 0;
            }
  
            .str-chat__list {
              padding: 0 10px 0 10px;
              background: #E8EBED;
            }
          }
        }
  
        .str-chat__header-hamburger {
          display: flex;
        }
  
        .str-chat-channel-list-burger {
          display: flex;
        }
  
        .str-chat-channel-list {
          background-color: #fff;
          position: absolute;
          left: -390px;
          top: 0;
          z-index: 999;
          max-height: 100%;
          height: 100%;
          overflow-y: auto;
          width: 100%;
          box-shadow: 7px 0 9px 0 rgba(0, 0, 0, 0.03), 1px 0 0 0 rgba(0, 0, 0, 0.03);
          transition: left 200ms ease-in-out;
  
          .str-chat__channel-list-messenger {
            padding: 0;
  
            height: calc(100% - 96px);
            max-height: calc(100% - 96px);
          }
        }
  
        .str-chat-channel-list--open {
          left: 0px;
        }
      }
    }
  }
`;
