import React from 'react';
import Modal from './Modal';
import { Container, Button, Row } from '../';
import { translate } from '../../translate/i18n';
import { func } from 'prop-types';

const ConfirmModal = (props) => {
  const { t, content, onConfirm, onClose } = props;
  return (
    <Modal
      {...props}
      content={() => (
        <Container column p>
          {content()}

          <Row p right>
            <Button right size="md" name="cancelModal" mr-half onClick={onClose} clear>
              {t('CANCEL')}
            </Button>
            <Button right size="md" name="confirmModal" onClick={onConfirm} bg-primary>
              {t('CONFIRM')}
            </Button>
          </Row>
        </Container>
      )}
    />
  );
};

ConfirmModal.propTypes = { t: func, onConfirm: func.isRequired };

export default translate('default')(ConfirmModal);
