import timezones from 'timezones-list';
import moment from 'moment-timezone';
import { sort, ascend, descend, prop } from 'ramda';
import { makeTimeFilter } from './index';
import { twoDigit } from './format';

export const days = [
  { number: 0, name: 'SUNDAY' },
  { number: 1, name: 'MONDAY' },
  { number: 2, name: 'TUESDAY' },
  { number: 3, name: 'WEDNESDAY' },
  { number: 4, name: 'THURSDAY' },
  { number: 5, name: 'FRIDAY' },
  { number: 6, name: 'SATURDAY' }
];

export const daysToMilliseconds = (days) => 24 * days * 3600 * 1000;
export const addDaysToDate = (date, days) => new Date(date.getTime() + daysToMilliseconds(days));
export const substractDaysFromDate = (date, days) => new Date(date.getTime() - daysToMilliseconds(days));

export const loadRecurrenceUnits = (t) => [
  { label: t('time:WEEK'), value: 'week' },
  { label: t('time:MONTH'), value: 'month' }
];

export const parseDateWithoutTz = (date) =>
  date ? `${date.getFullYear()}/${twoDigit(date.getMonth() + 1)}/${twoDigit(date.getDate())}` : undefined;

export const getTimeList = (t) => {
  const currentDate = new Date();

  return [
    makeTimeFilter(t('ALL'), 'createdAt', ''),
    makeTimeFilter(t('7_LAST_DAYS'), 'createdAt', substractDaysFromDate(currentDate, 7)),
    makeTimeFilter(t('LAST_MONTH'), 'createdAt', substractDaysFromDate(currentDate, 30)),
    makeTimeFilter(t('3_LAST_MONTHS'), 'createdAt', substractDaysFromDate(currentDate, 90)),
    makeTimeFilter(t('6_LAST_MONTHS'), 'createdAt', substractDaysFromDate(currentDate, 180)),
    makeTimeFilter(t('12_LAST_MONTHS'), 'createdAt', substractDaysFromDate(currentDate, 365)),
    makeTimeFilter(t('YEAR_TO_DATE'), 'createdAt', new Date(currentDate.getFullYear(), 0, 1))
  ];
};

export const getTimeMask = (t) => {
  const currentDate = new Date();

  return {
    ALL: {
      label: t('ALL'),
      filter: 'createdAt',
      value: 'all',
      date: null
    },
    '7_LAST_DAYS': {
      label: t('7_LAST_DAYS'),
      filter: 'createdAt',
      value: '7_LAST_DAYS',
      date: substractDaysFromDate(currentDate, 7)
    },
    LAST_MONTH: {
      label: t('LAST_MONTH'),
      filter: 'createdAt',
      value: 'LAST_MONTH',
      date: substractDaysFromDate(currentDate, 30)
    },
    '3_LAST_MONTHS': {
      label: t('3_LAST_MONTHS'),
      filter: 'createdAt',
      value: '3_LAST_MONTHS',
      date: substractDaysFromDate(currentDate, 90)
    },
    '6_LAST_MONTHS': {
      label: t('6_LAST_MONTHS'),
      filter: 'createdAt',
      value: '6_LAST_MONTHS',
      date: substractDaysFromDate(currentDate, 180)
    },
    '12_LAST_MONTHS': {
      label: t('12_LAST_MONTHS'),
      filter: 'createdAt',
      value: '12_LAST_MONTHS',
      date: substractDaysFromDate(currentDate, 365)
    },
    YEAR_TO_DATE: {
      label: t('YEAR_TO_DATE'),
      filter: 'createdAt',
      value: 'YEAR_TO_DATE',
      date: new Date(currentDate.getFullYear(), 0, 1)
    }
  };
};

export const getTimezoneList = () => {
  return timezones.map((timezone) => {
    return {
      label: timezone.tzCode,
      value: timezone.tzCode
    };
  });
};

export const makeRecurrences = () => {
  return days.map((day) => makeRecurrence(day.number));
};

export const makeRecurrence = (dayNumber) => {
  const date = new Date();
  const today = date.getDay();
  const sunday = date.getDate() - today;

  return {
    unit: 'week',
    timelapse: 1,
    firstDate: new Date(date.getFullYear(), date.getMonth(), sunday + dayNumber)
  };
};

export const hubInUtcFormatToLocalDate = (date, timezone) => {
  if (!date) return;
  const hub = moment(date).tz(timezone);
  const local = hub.tz(moment.tz.guess(), true);
  return local.toDate();
};

export const localDateToHubInUtcFormat = (date, timezone) => {
  if (!date) return;
  const local = moment(date);
  const hub = local.tz(timezone, true);
  return hub.utc().format();
};

export const sortByDate = (dateProp, list = [], order = 'ASC') => {
  const sortOrder = order === 'ASC' ? ascend : descend;
  const byDate = sortOrder(prop(dateProp));

  return sort(byDate, list);
};
