import styled from '@emotion/styled';

export const StyledTextInput = styled('input')(({ theme, clear }) => ({
  fontFamily: theme.font.family,
  fontWeight: theme.font.weight.lg,
  paddingBottom: '5px',
  paddingTop: '7px',
  borderTop: clear ? 'none' : `0.51px solid ${theme.colors.lightGrey[300]}`,
  borderLeft: clear ? 'none' : `0.51px solid ${theme.colors.lightGrey[300]}`,
  borderRight: clear ? 'none' : `0.51px solid ${theme.colors.lightGrey[300]}`,
  borderBottom: `1px solid ${theme.colors.lightGrey[300]}`,
  fontSize: theme.font.size.sm,
  width: '100%',
  outline: 'none',
  background: 'transparent',
  color: theme.colors.grey[500],
  paddingLeft: clear ? 0 : theme.spacing.half,
  borderRadius: clear ? 'none' : '2px',
  letterSpacing: '1px',
  height: '38px',
  '&:focus': {
    borderColor: theme.colors.brown['500'],
    boxShadow: `0 0 5px ${theme.colors.brown[400]}`
  },
  '&:disabled': {
    background: theme.colors.lightGrey['400']
  },
  '::placeholder': {
    fontWeight: theme.font.weight['lg'],
    fontSize: theme.font.size['sm'],
    color: theme.colors.grey[300]
  },
  ':disabled': {
    cursor: 'not-allowed',
    border: 'none',
    background: 'transparent',
    padding: 0
  }
}));

export const ErrorText = styled('span')(({ theme }) => ({
  color: theme.colors.red['500'],
  fontSize: theme.font.size.sm,
  position: 'absolute',
  bottom: -(theme.spacing.half + 5),
  left: 0,
  fontFamily: theme.font.family
}));

export const InputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing.normal,
  width: '100%'
}));

export const StyledLabel = styled('label')(({ theme, active, inline }) => ({
  display: inline ? 'inline-block' : 'block',
  fontSize: theme.font.size.xs,
  fontFamily: theme.font.family,
  fontWeight: 300,
  textAlign: 'left',
  color: active ? theme.colors.brown['500'] : theme.colors.grey['400'],
  paddingBottom: '4px',
  letterSpacing: '1px',
  textTransform: 'uppercase'
}));
