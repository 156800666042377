import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { compose, path, pathOr, propOr } from 'ramda';
import { withRouter } from 'react-router-dom';

import { translate } from '../translate/i18n';
import { Block, Container, Loader, Row, Text } from '../components';
import { emptyObject } from '../utils';
import { getInventory, compareInventories } from './inventories.actions';
import InventoryDetails from './inventoryDetails';
import InventoryChecklist from './inventoryChecklist';
import InventoryComment from './inventoryComment';
import Header from './Inventory.header';
import InventoryHistory from './Inventory.history';
import InventoryInfo from './inventory.info';
import { isAdminOrCM } from '../utils/authorizations';

const Inventory = (props) => {
  const { compareInventories, match, t, inventory, getInventory } = props;

  const id = path(['params', 'id'], match);
  const AuthorizedHeader = isAdminOrCM(Header);

  useEffect(() => {
    getInventory(id);
    compareInventories(id);
  }, []);

  if (propOr(null, 'id', inventory) !== id) {
    return (
      <Container full center middle>
        <Loader />
      </Container>
    );
  }

  return (
    <Block pt pl pr>
      <AuthorizedHeader showCompare label={'BACK_TO_INVENTORIES_LIST'} link="/inventories" />

      <Row mb-double>
        <Text size="xl" weight={200}>
          {t('INVENTORY_DETAILS')}
        </Text>
      </Row>

      <InventoryInfo />
      <InventoryHistory roomId={propOr(null, 'roomId', inventory)} />
      <InventoryDetails list={propOr([], 'inventoryDetails', inventory)} />
      <InventoryChecklist list={propOr([], 'checklist', inventory)} />
      <InventoryComment comment={propOr('', 'comment', inventory)} />
    </Block>
  );
};

Inventory.propTypes = {
  t: func,
  match: object,
  inventory: object,
  getInventory: func,
  compareInventories: func
};

export default compose(
  withRouter,
  translate('inventories'),
  connect(
    (state) => ({
      inventory: pathOr(emptyObject, ['inventories', 'current', 'data'], state)
    }),
    {
      getInventory,
      compareInventories
    }
  )
)(Inventory);
