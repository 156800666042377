export default {
  currency: {
    brussels_lfd_FOxiGZ2H57VecBuDOsg: ['eur'],
    new_york_lfd_FOxiGZ2H57VecBuDOsg: ['usd'],
    paris_lfd_FOxiGZ2H57VecBuDOsg: ['eur'],
    madrid_raZMtxGepLMoFvoOnTmaoTcZO: ['eur'],
    luxembourg_9g3KULHjs0w4oE5Bf5uht: ['eur'],
    london_XKM04UBQ6hApESdg6T2MvtE7B: ['gbp'],
    milan_m8qSczH1emqgMkjN0FQFlxafne: ['eur']
  },
  paymentMethods: {
    brussels_lfd_FOxiGZ2H57VecBuDOsg: ['sepa_debit', 'sepa_credit', 'card'],
    new_york_lfd_FOxiGZ2H57VecBuDOsg: ['ach_debit', 'ach_credit', 'card'],
    paris_lfd_FOxiGZ2H57VecBuDOsg: ['card', 'sepa_debit', 'sepa_bank_transfer'],
    madrid_raZMtxGepLMoFvoOnTmaoTcZO: ['card', 'sepa_debit', 'sepa_bank_transfer'],
    luxembourg_9g3KULHjs0w4oE5Bf5uht: ['card', 'sepa_debit', 'sepa_bank_transfer'],
    london_XKM04UBQ6hApESdg6T2MvtE7B: ['card', 'sepa_debit', 'sepa_bank_transfer'],
    milan_m8qSczH1emqgMkjN0FQFlxafne: ['card', 'sepa_debit', 'sepa_bank_transfer']
  }
};
