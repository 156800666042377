import React, { useState, useEffect } from 'react';
import { func, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import moment from 'moment-timezone';
import Tooltip from 'react-tooltip-lite';
import { translate } from '../../translate/i18n';
import { Container, Button, Row, Col, Modal, Text } from '../../components';
import DiscountForm from './form/Form.edit';
import { isEmptyOrNil, formatLeaseLocationLabel, upperCase, formatPrice, toUTC00 } from '../../utils';
import { addDiscountToExistingLease } from './discount.actions';

const LeaseDiscountModal = (props) => {
  const { lease, t, addDiscountToExistingLease } = props;
  const [discountModal, toggleDiscountModal] = useState(false);
  const [discountBlocker, setDiscountBlocker] = useState(false);
  const [discountHide, setDiscountHide] = useState(false);
  const { timezone, rentAmount } = lease;
  const currencySymbol = pathOr('--', ['room', 'house', 'hub', 'currency', 'symbol'], lease);

  useEffect(() => {
    const { timezone, stripeSubscriptionId } = lease;
    const discounts = pathOr([], ['discounts'], lease);

    const thisMonthEnds = moment.utc().endOf('M');
    const moveout = pathOr(null, ['moveoutDate'], lease);

    // if lease finishing the current month
    if (thisMonthEnds.isAfter(toUTC00(moveout, timezone))) {
      return setDiscountHide(true);
    }

    if (!stripeSubscriptionId) {
      return setDiscountBlocker(t('TOOLTIP_LEASE_NO_SUBSCRIPTION'));
    }

    const start = pathOr(null, ['startDate'], lease);
    if (moment.utc().endOf('M').add(1, 'M').isBefore(toUTC00(start, timezone))) {
      return setDiscountBlocker(t('TOOLTIP_MONTH_IS_NOT_STARTED'));
    }

    // if lease already has discount on future month
    const discountExist = discounts.some(({ endDate }) => moment.utc().endOf('M').isBefore(endDate, 'day'));

    if (discountExist) {
      return setDiscountBlocker(t('TOOLTIP_MONTH_HAS_ONGOING_DISCOUNT'));
    }

    setDiscountBlocker(null);
  }, [lease]);

  if (isEmptyOrNil(lease) || discountHide) {
    return null;
  }

  const closeDiscountModal = () => {
    toggleDiscountModal(false);
  };

  const handleSubmit = (val) => {
    addDiscountToExistingLease({
      userId: lease.userId,
      discountableId: lease.id,
      discountableType: 'lease',
      redeemBy: moment
        .utc()
        .add(1 + val.duration, 'M')
        .toDate(),
      ...val
    });
  };

  if (discountBlocker) {
    return (
      <Col lg={6} pl-quarter pr-quarter pt-half>
        <Tooltip className="discount-tooltip" content={discountBlocker} direction="down">
          <div className="disabled-placeholder">{t('ADD_DISCOUNT')}</div>
        </Tooltip>
      </Col>
    );
  }

  return (
    <Col lg={6} pl-quarter pr-quarter pt-half>
      <Button name="addDiscount" size="md" primary bg-primary onClick={() => toggleDiscountModal(true)}>
        {t('ADD_DISCOUNT')}
      </Button>

      <Modal
        title={t('ADD_DISCOUNT')}
        show={discountModal}
        hasActions
        onClose={closeDiscountModal}
        content={() => (
          <Container column pl pr>
            <Row pl-quarter pb>
              <Col lg={12} left>
                <Text weight={300} grey pb-quarter>
                  {upperCase(t('leases:LEASE'))}
                </Text>
                <Text size="md" grey={400} weight={200} type="container" noWrap={false}>
                  {formatLeaseLocationLabel({ lease })}
                </Text>
              </Col>
            </Row>
            <Row pl-quarter pb>
              <Col lg={12} left>
                <Text weight={300} grey pb-quarter>
                  {upperCase(t('leases:RENT_PRICE'))}
                </Text>
                <Text size="md" grey={400} weight={200}>
                  {formatPrice(rentAmount)} {currencySymbol}
                </Text>
              </Col>
            </Row>
            <Row>
              <Text weight={300} grey pb-quarter mr-quarter ml-quarter>
                {upperCase(t('TITLE'))}
              </Text>
              <DiscountForm
                timezone={timezone}
                lease={lease}
                onSubmit={handleSubmit}
                onClose={closeDiscountModal}
                initialValues={{
                  duration: 1,
                  amount: 1,
                  type: 'fixed',
                  timezone
                }}
              />
            </Row>
          </Container>
        )}
        onCancel={closeDiscountModal}
      />
    </Col>
  );
};

LeaseDiscountModal.propTypes = {
  t: func,
  lease: object,
  upsertDiscount: func,
  deleteDiscount: func,
  addDiscountToExistingLease: func
};

export default compose(
  translate(['discounts', 'leases']),
  connect(null, {
    addDiscountToExistingLease: addDiscountToExistingLease.request
  })
)(LeaseDiscountModal);
