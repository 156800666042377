import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { func, bool, string } from 'prop-types';
import { reduxForm, formValues, Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import {
  BottomContainer,
  Button,
  Row,
  Col,
  Block,
  TextInput,
  MDEAreaForm,
  Upload,
  SelectorFeedsCategoryForm,
  ToggleForm,
  AsyncSelectorForm
} from '../components';
import StyledForm from '../components/form.styles';
import { upsertContent } from './cms.actions';
import { required } from '../utils';
import { StyledLabel, InputContainer } from '../components/form/TextInput.styles';
import AudienceForm from './cms.audience.form';
import { formatMember, loadMemberOptions } from './cms.utils';

const FeedForm = (props) => {
  // Props
  const {
    t,
    isNew,
    duplicate,
    onCancel,
    submitting,
    handleSubmit,
    submitSucceeded,
    countryId,
    cityId,
    hubId,
    houseId,
    category
  } = props;

  if (submitSucceeded && (isNew || duplicate)) {
    return <Redirect to="/cms/feeds" />;
  }

  const renderMemberSelector = (filters) =>
    category === 'community' ? (
      <Row>
        <Col lg={12} pr-half pl-half>
          <Field
            component={AsyncSelectorForm}
            label={t('FORM.SELECT_MEMBER')}
            name="member"
            format={formatMember}
            loadOptions={loadMemberOptions(filters)}
          />
        </Col>
      </Row>
    ) : null;

  const renderUrlInput = () =>
    !['community', 'daily_life'].includes(category) ? (
      <Row>
        <Col lg={12} pr-half pl-half>
          <Field component={TextInput} label={t('FORM.REDIRECT_URL')} type="url" name="url" />
        </Col>
      </Row>
    ) : null;

  // Render
  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit(upsertContent)}>
        <Block>
          <Row>
            <Col xl={9} lg={9} md={9} sm={12}>
              <Row mb>
                <Col lg={12} pr-half pl-half>
                  <Field
                    component={ToggleForm}
                    label={t('FORM.TOGGLE_NOTIFICATION')}
                    name="toggleNotification"
                    type="checkbox"
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={3} lg={3} md={6} sm={6} pr-half pl-half>
                  <Field
                    component={SelectorFeedsCategoryForm}
                    validate={required}
                    label={t('FORM.CATEGORY')}
                    name="category"
                  />
                </Col>
              </Row>

              <AudienceForm
                t={t}
                countryId={countryId}
                cityId={cityId}
                hubId={hubId}
                houseId={houseId}
                includeHouse
                isNew={isNew}
              />

              <Row>
                <Col lg={12} pr-half pl-half>
                  <Field component={TextInput} validate={required} label={t('FORM.TITLE')} name="title" />
                </Col>
              </Row>

              {renderMemberSelector({ countryId, cityId, hubId, houseId })}

              {renderUrlInput()}

              <Row>
                <Col lg={12} pr-half pl-half>
                  <Field component={MDEAreaForm} label={t('FORM.CONTENT')} name="descriptionFull" />
                </Col>
              </Row>

              <Row>
                <Col lg={12} pr-half pl-half>
                  <InputContainer>
                    <StyledLabel>{t('FORM.PICTURE')}</StyledLabel>
                    <Field component={Upload} name="picture" accept={['image/png', 'image/jpeg']} size={128} />
                  </InputContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Block>

        <BottomContainer negativeMargin={true}>
          <Row right>
            <Button name="cancel" disabled={submitting} onClick={() => onCancel()} clear size="md">
              {t('FORM.CANCEL')}
            </Button>
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('FORM.SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </BottomContainer>
      </StyledForm>
    </Fragment>
  );
};

// eslint-disable-next-line complexity
const validate = (values = {}) => {
  const errors = {};

  // Check audience
  if (!values.countryId && !values.cityId && !values.hubId && !values.houseId) {
    errors.countryId = 'ERR_FEED_MISSING_AUDIENCE';
  }
  if (values.description) {
    if (values.description.length > 1024) errors.description = 'MAX_LENGTH_EXCEEDED';
  }

  if (values.url) {
    if (values.url.length > 256) errors.url = 'MAX_LENGTH_EXCEEDED';
  }

  return errors;
};

FeedForm.propTypes = {
  t: func,
  change: func,
  isNew: bool,
  duplicate: bool,
  countryId: string,
  cityId: string,
  hubId: string,
  houseId: string,
  ...reduxFormPropTypes
};

export default compose(
  translate('cms'),
  reduxForm({ form: 'FeedForm', validate, enableReinitialize: true, destroyOnUnmount: false }),
  formValues({ countryId: 'countryId', cityId: 'cityId', hubId: 'hubId', houseId: 'houseId', category: 'category' })
)(FeedForm);
