import React, { useState } from 'react';
import { func, object, number, string } from 'prop-types';
import { compose } from 'redux';
import { translate } from '../../../translate/i18n';
import { Block, Button, Row } from '../../../components';
import DiscountPromiseForm from './Form.edit';
import { isEmptyOrNil } from '../../../utils';

import { upsertDiscountPromise, deleteDiscountPromise } from '../discountPromise.actions';
import { connect } from 'react-redux';

const DiscountPromiseHandler = ({
  discountablePromiseType,
  discountablePromiseId,
  discount,
  upsertDiscountPromise,
  deleteDiscountPromise,
  currency,
  timezone,
  incomingPrice,
  t
}) => {
  const isNew = isEmptyOrNil(discount);
  const [showForm, setShowForm] = useState(false);

  const handleSubmit = (val) => {
    upsertDiscountPromise(val);
  };

  const removeDiscountPromise = (id) => {
    if (!id) {
      setShowForm(false);
      return;
    }
    deleteDiscountPromise({ id, discountablePromiseId, discountablePromiseType });
  };

  return (
    <Block>
      {isNew && !showForm && (
        <Row left>
          <Button name="addDiscount" size="md" bg-primary onClick={() => setShowForm(!showForm)}>
            {t('ADD_DISCOUNT')}
          </Button>
        </Row>
      )}

      {(discount || showForm) && (
        <DiscountPromiseForm
          timezone={timezone}
          incomingPrice={incomingPrice}
          onSubmit={handleSubmit}
          deleteDiscountPromiseRequest={removeDiscountPromise}
          initialValues={{
            discountablePromiseType,
            discountablePromiseId,
            redeemBy: new Date().toString(),
            duration: 1,
            amount: 1,
            type: 'fixed',
            ...discount,
            currency,
            timezone
          }}
        />
      )}
    </Block>
  );
};

DiscountPromiseHandler.propTypes = {
  t: func,
  discount: object,
  incomingPrice: number,
  currency: object,
  timezone: string,
  discountablePromiseId: string,
  discountablePromiseType: string,
  upsertDiscountPromise: func,
  deleteDiscountPromise: func
};

export default compose(
  translate(['discounts', 'default']),
  connect(null, {
    upsertDiscountPromise: upsertDiscountPromise.request,
    deleteDiscountPromise
  })
)(DiscountPromiseHandler);
