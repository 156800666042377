import React, { useEffect, Fragment } from 'react';
import { Block, Text, Row, Back } from '../components';
import { Link, Route, withRouter } from 'react-router-dom';
import { object, func, shape, bool, string } from 'prop-types';
import { translate } from '../translate/i18n';
import HouseDetails from './House.details';
import { compose, pathOr, prop, path } from 'ramda';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { fetchHouseDetails } from './houses.actions';
import { clearHouseLocalStorage } from './houses.service';
import { show } from '../components';
import { IsLogged } from '../utils/authorizations';
import { emptyObject, isNotEmptyOrNil } from '../utils';
import Sections from './House.sections';
import Trashes from './House.trashes';
import Services from './House.services';
import Units from './House.units';
import SpecificInfo from './House.specificInfo';
import CommonAreas from './House.commonAreas';
import AdditionalInfo from './House.additionalInfo';

const TabsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.colors.lightGrey[400]}`,
  borderWidth: '2px',
  margin: theme.spacing.normal,
  paddingBottom: theme.spacing.half
}));

const TabContainer = styled('div')(({ theme, active }) => ({
  position: 'relative',
  marginRight: theme.spacing.pacman,
  '&:after': {
    content: "''",
    position: 'absolute',
    height: '2px',
    width: '100%',
    bottom: '-12px',
    opacity: active ? 1 : 0,
    background: theme.colors.black[500]
  }
}));

const Tab = compose(
  withRouter,
  show((props) => !props.hide)
)(({ label, to, location, disabled }) => {
  const isDisabled = disabled ? { onClick: (e) => e.preventDefault() } : null;

  return (
    <TabContainer disabled={disabled} active={location.pathname === to}>
      <Link to={to} disabled={isDisabled}>
        <Row middle>
          <Text lightGrey pr-quarter>
            {label}
          </Text>
        </Row>
      </Link>
    </TabContainer>
  );
});

Tab.defaultProps = {
  hide: false
};

Tab.propTypes = {
  to: string,
  label: string,
  hasError: bool,
  theme: object,
  hide: bool
};

const House = (props) => {
  const { match, t, dispatch, house } = props;
  const id = path(['params', 'id'], match);
  const url = prop('url', match);
  const isNew = id === 'create';
  const showForm = isNew || isNotEmptyOrNil(house);

  useEffect(() => {
    if (!isNew) {
      dispatch(fetchHouseDetails(id));

      clearHouseLocalStorage();
    }
  }, []);

  return (
    <Block>
      <Row p>
        <Back to="/houses" label={t('BACK_TO_HOUSES_LIST')} />
      </Row>

      <Row pl>
        <Text size="lg" weight={200}>
          {isNew ? t('NEW_HOUSE') : t('EDIT_HOUSE_TITLE', { houseName: house.name })}
        </Text>
      </Row>

      <TabsContainer>
        <Tab exact to={`${url}`} label={t('HOUSE_INFO')} />
        {pathOr(null, ['show', 'specificInfo'], house) && (
          <Fragment>
            <Tab hide={isNew} to={`${url}/specificInfo`} label={t('SPECIFIC_INFO')} disabled={isNew} />
            <Tab hide={isNew} to={`${url}/additionalInfo`} label={t('ADDITIONAL_INFO')} disabled={isNew} />
            <Tab hide={isNew} to={`${url}/commonAreas`} label={t('COMMON_AREAS')} disabled={isNew} />
          </Fragment>
        )}
        <Tab hide={isNew} to={`${url}/units`} label={t('UNITS')} disabled={isNew} />
        <Tab hide={isNew} to={`${url}/sections`} label={t('SECTIONS')} disabled={isNew} />
        <Tab hide={isNew} to={`${url}/trashes`} label={t('TRASHES')} disabled={isNew} />
        <Tab hide={isNew} to={`${url}/services`} label={t('SERVICES')} disabled={isNew} />
      </TabsContainer>

      {showForm && (
        <Fragment>
          <Route exact path={match.url} render={(props) => <HouseDetails {...props} id={id} />} />
          <Route exact path={`${match.url}/sections`} component={Sections} />
          <Route exact path={`${match.url}/trashes`} component={Trashes} />
          <Route exact path={`${match.url}/services`} component={Services} />
          <Route exact path={`${match.url}/units`} component={Units} />
          {pathOr(null, ['show', 'specificInfo'], house) && (
            <Fragment>
              <Route
                exact
                path={`${match.url}/specificInfo`}
                render={() => <SpecificInfo initialValues={{ info: house }} />}
              />
              <Route
                exact
                path={`${match.url}/additionalInfo`}
                render={() => <AdditionalInfo initialValues={{ info: house }} />}
              />
              <Route exact path={`${match.url}/commonAreas`} render={() => <CommonAreas house={house} />} />
            </Fragment>
          )}
        </Fragment>
      )}
    </Block>
  );
};

House.propTypes = {
  history: object,
  match: object,
  t: func,
  location: object,
  dispatch: func.isRequired,
  house: shape({
    isFetching: bool,
    data: object
  }).isRequired
};

export default compose(
  IsLogged,
  connect((state) => ({
    house: pathOr(emptyObject, ['houses', 'current', 'data'], state)
  })),
  translate('house')
)(House);
