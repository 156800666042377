import { takeLatest, call, all, put } from 'redux-saga/effects';
import { prop, propOr, omit, is, equals, flatten, values, compose, pathOr } from 'ramda';
import * as service from './cms.service';
import { GET_CONTENTS, GET_CONTENT, APPEND_CONTENTS, DELETE_CONTENTS } from './cms.actionTypes';
import {
  setContents,
  getContentsFailure,
  appendContentsSuccess,
  appendContentsFailure,
  deleteContentsFailure,
  deleteContentsSuccess,
  getContentFailure,
  setContent,
  upsertContent
} from './cms.actions';
import { uploadFile } from '../service';
import { paramsFromPayload, types, defActionType } from '../utils/sagas';
import { handleFormErrors, upperCase } from '../utils';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';

const fetch = {
  tips: service.getTips,
  feeds: service.getFeeds,
  faq: service.getFaqs,
  helpvideos: service.getVideos
};

const fetchById = {
  tips: service.getTip,
  feeds: service.getFeed,
  faq: service.getFaq,
  helpvideos: service.getHelpVideo
};

const deleteById = {
  tips: service.deleteTips,
  feeds: service.deleteFeeds,
  faq: service.deleteFaqs,
  helpvideos: service.deleteVideos
};

const upsert = {
  tips: service.upsertTip,
  feeds: service.upsertFeed,
  faq: service.upsertFaq,
  helpvideos: service.upsertVideo
};

const handleVideos = (videosByCategory) => compose(flatten, values)(videosByCategory);

export function* getContents(action) {
  const payload = propOr({}, 'payload', action);
  const contentType = prop('contentType', payload);

  try {
    let contents = yield call(fetch[contentType], paramsFromPayload(payload, ['contentType']));
    if (equals(contentType, 'helpvideos')) {
      contents = handleVideos(contents);
    }
    yield put(setContents(contents));
  } catch (err) {
    yield put(getContentsFailure(err));
    yield put(
      handleErrorMessage('GET.FAILURE', { type: types.CMS[upperCase(contentType)], error: err, data: payload })
    );
  }
}

export function* getContent(action) {
  const payload = propOr({}, 'payload', action);
  const contentType = prop('contentType', payload);
  const id = prop('id', payload);

  try {
    const content = yield call(fetchById[contentType], id);

    yield put(setContent(content));
  } catch (err) {
    yield put(getContentFailure(err));
    yield put(
      handleErrorMessage('GET.FAILURE', { type: types.CMS[upperCase(contentType)], error: err, data: payload })
    );
  }
}

export function* appendContents(action) {
  const payload = propOr({}, 'payload', action);
  const contentType = prop('contentType', payload);

  try {
    const contents = yield call(fetch[contentType], paramsFromPayload(payload, ['contentType']));

    yield put(appendContentsSuccess(contents));
  } catch (err) {
    yield put(appendContentsFailure(err));
    yield put(
      handleErrorMessage('GET.FAILURE', { type: types.CMS[upperCase(contentType)], error: err, data: payload })
    );
  }
}

export function* deleteContents(action) {
  const payload = propOr({}, 'payload', action);
  const contentType = prop('contentType', payload);

  try {
    yield call(deleteById[contentType], payload.list);
    yield put(deleteContentsSuccess(payload));
    yield put(handleSuccessMessage('DELETE.SUCCESS', { type: types.CMS[upperCase(contentType)] }));
  } catch (err) {
    yield put(deleteContentsFailure(err));
    yield put(
      handleErrorMessage('DELETE.FAILURE', { type: types.CMS[upperCase(contentType)], error: err, data: payload })
    );
  }
}

export function* _upsertContent(action) {
  const payload = prop('payload', action);
  const contentType = prop('contentType', payload);
  const key = defActionType(payload);

  try {
    const cover = propOr(null, 'cover', payload);
    const picture = propOr(null, 'picture', payload);

    switch (contentType) {
      case 'tips':
        if (is(Blob, cover)) {
          payload.cover = yield uploadFile(cover);
        }
        payload.active = Boolean(payload.active);
        break;
      case 'feeds':
        if (is(Blob, picture)) {
          payload.picture = yield uploadFile(picture);
        }
        payload.memberId = pathOr(null, ['member', 'id'], payload);
        payload.toggleNotification = Boolean(payload.toggleNotification);
        payload.description = payload.descriptionFull;
        break;
      case 'faq':
        break;
      case 'helpvideos':
        break;
      default:
        throw new Error('ERR_CONTENT_TYPE');
    }

    const savedContent = yield call(upsert[contentType], omit(['contentType', 'deleted', 'member'], payload));

    yield put(upsertContent.success(savedContent));
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.CMS[upperCase(contentType)] }));
  } catch (err) {
    yield put(upsertContent.failure(handleFormErrors(err)));
    yield errorHandler(err, payload, key, contentType);
  }
}

function* errorHandler(err, payload, key, contentType) {
  if (err.data) {
    yield all(
      values(err.data).map((item) =>
        put(
          handleErrorMessage(`${key}.FAILURE`, {
            type: types.CMS[upperCase(contentType)],
            error: {
              message: item
            },
            data: payload
          })
        )
      )
    );
    return;
  }

  yield put(
    handleErrorMessage(`${key}.FAILURE`, { type: types.CMS[upperCase(contentType)], error: err, data: payload })
  );
}

export default function* cmsSaga() {
  yield all([
    takeLatest(GET_CONTENTS, getContents),
    takeLatest(GET_CONTENT, getContent),
    takeLatest(APPEND_CONTENTS, appendContents),
    takeLatest(DELETE_CONTENTS, deleteContents),
    takeLatest(upsertContent.REQUEST, _upsertContent)
  ]);
}
