import i18n from '../translate/i18n';
import { toast } from '../utils';
import { createFormAction } from 'redux-form-saga';

export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL';

export const APPEND_MEMBERS = 'APPEND_MEMBERS';
export const APPEND_MEMBERS_SUCCESS = 'APPEND_MEMBERS_SUCCESS';
export const APPEND_MEMBERS_FAIL = 'APPEND_MEMBERS_FAIL';

export const GET_MEMBER = 'GET_MEMBER';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_FAIL = 'GET_MEMBER_FAIL';
export const RESET_CURRENT_MEMBER = 'RESET_CURRENT_MEMBER';

export const GET_USER_LEASES = 'GET_USER_LEASES';
export const GET_USER_LEASES_SUCCESS = 'GET_USER_LEASES_SUCCESS';
export const GET_USER_LEASES_FAIL = 'GET_USER_LEASES_FAIL';

export const GET_USER_INCIDENTS = 'GET_USER_INCIDENTS';
export const GET_USER_INCIDENTS_SUCCESS = 'GET_USER_INCIDENTS_SUCCESS';
export const GET_USER_INCIDENTS_FAIL = 'GET_USER_INCIDENTS_FAIL';

export const GET_STAY_INFO = 'GET_STAY_INFO';
export const GET_STAY_INFO_SUCCESS = 'GET_STAY_INFO_SUCCESS';
export const GET_STAY_INFO_FAIL = 'GET_STAY_INFO_FAIL';

export const getMembers = (params = {}) => ({
  type: GET_MEMBERS,
  payload: params
});

export const getMembersSuccess = (members) => ({
  type: GET_MEMBERS_SUCCESS,
  payload: members
});

export const getMembersFail = (err) => ({
  type: GET_MEMBERS_FAIL,
  payload: err
});

export const appendMembers = (params = {}) => ({
  type: APPEND_MEMBERS,
  payload: params
});

export const appendMembersSuccess = (members) => ({
  type: APPEND_MEMBERS_SUCCESS,
  payload: members
});

export const appendMembersFail = (err) => ({
  type: APPEND_MEMBERS_FAIL,
  payload: err
});

export const getMember = (id) => ({
  type: GET_MEMBER,
  payload: id
});

export const getMemberSucess = (member) => ({
  type: GET_MEMBER_SUCCESS,
  payload: member
});

export const getMemberFail = (err) => {
  const error = i18n.getResource(i18n.language, 'member', 'GET_MEMBER_ERROR');
  toast.error(error);

  return {
    type: GET_MEMBER_FAIL,
    payload: err
  };
};

export const resetCurrentMember = () => ({
  type: RESET_CURRENT_MEMBER
});

export const getLeases = (memberId) => ({
  type: GET_USER_LEASES,
  payload: memberId
});

export const getLeaseSucess = (list) => ({
  type: GET_USER_LEASES_SUCCESS,
  payload: list
});

export const getLeaseFail = (err) => {
  const error = i18n.getResource(i18n.language, 'member', 'GET_USER_LEASES_ERROR');
  toast.error(error);

  return {
    type: GET_USER_LEASES_FAIL,
    payload: err
  };
};

export const getIncidents = (memberId) => ({
  type: GET_USER_INCIDENTS,
  payload: memberId
});

export const getIncidentsSucess = (list) => ({
  type: GET_USER_INCIDENTS_SUCCESS,
  payload: list
});

export const getIncidentsFail = (err) => {
  const error = i18n.getResource(i18n.language, 'member', 'GET_USER_INCIDENTS_ERROR');
  toast.error(error);

  return {
    type: GET_USER_INCIDENTS_FAIL,
    payload: err
  };
};

export const getStayInfo = (userId) => ({
  type: GET_STAY_INFO,
  payload: userId
});

export const getStayInfoSuccess = (stayInfo) => ({
  type: GET_STAY_INFO_SUCCESS,
  payload: stayInfo
});

export const getStayInfoFail = (err) => {
  const error = i18n.getResource(i18n.language, 'member', 'GET_USER_STAYINFO_ERROR');
  toast.error(error);

  return {
    type: GET_STAY_INFO_FAIL,
    payload: err
  };
};

export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const updateMember = createFormAction(UPDATE_MEMBER);

export const UPDATE_MEMBER_ACCESS = 'UPDATE_MEMBER_ACCESS';
export const UPDATE_MEMBER_ACCESS_SUCCESS = 'UPDATE_MEMBER_ACCESS_SUCCESS';
export const UPDATE_MEMBER_ACCESS_FAILURE = 'UPDATE_MEMBER_ACCESS_FAILURE';

export const updateMemberAccess = (params = {}) => ({
  type: UPDATE_MEMBER_ACCESS,
  payload: params
});

export const updateMemberAccessSuccess = (member) => ({
  type: UPDATE_MEMBER_ACCESS_SUCCESS,
  payload: member
});

export const updateMemberAccessFailure = (err) => ({
  type: UPDATE_MEMBER_ACCESS_FAILURE,
  payload: err
});

export const UPDATE_MEMBER_INFO = 'UPDATE_MEMBER_INFO';

export const updateMemberInfo = (member) => {
  return {
    type: UPDATE_MEMBER_INFO,
    payload: member
  };
};
