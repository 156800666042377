import React from 'react';
import { string, shape, bool } from 'prop-types';
import styled from '@emotion/styled';
import { compose } from 'ramda';
import { translate } from '../../translate/i18n';
import { Row } from '../index';
import { formatInputProps, inputPropTypes } from './decorators';
import { InputContainer, StyledLabel, StyledTextInput, ErrorText } from './TextInput.styles';

const ColoredSquare = styled('div')(({ theme, color }) => ({
  backgroundColor: color,
  borderRadius: '4px',
  border: `1px solid ${theme.colors.lightGrey[300]}`,
  height: '38px',
  width: '38px',
  marginRight: '5px'
}));

const ColorInput = (props) => {
  const { meta, t, label, input, inputProps, clear } = props;
  const { touched, error, active } = meta;

  return (
    <InputContainer>
      <StyledLabel active={active}>{label}</StyledLabel>
      <Row flexWrap={false}>
        <ColoredSquare color={input.value} />
        <StyledTextInput clear={clear} {...inputProps} />
      </Row>

      {touched && error && <ErrorText>{t(error)}</ErrorText>}
    </InputContainer>
  );
};
ColorInput.propTypes = {
  label: string,
  meta: shape({
    touched: bool,
    error: string,
    action: bool
  }).isRequired,
  inputProps: inputPropTypes
};

export default compose(formatInputProps(), translate('validation'))(ColorInput);
