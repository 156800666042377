import React from 'react';
import Selector from './Selector';
import { func, string, array, bool } from 'prop-types';
import { isEmpty } from 'ramda';
import { getTimezoneList } from '../../utils';

const SelectorTimezone = (props) => {
  const { name, onChange, isMulti, label, value, isInForm, isDisabled, placeholder } = props;

  const handleChange = (val) => {
    if (isEmpty(val)) return onChange();
    return onChange(val);
  };

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={getTimezoneList()}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

SelectorTimezone.propTypes = {
  isInForm: bool,
  name: string.isRequired,
  list: array,
  getCurrencies: func,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  placeholder: string,
  isFetching: bool,
  t: func
};

SelectorTimezone.defaultValues = {
  isInForm: false
};

export default SelectorTimezone;
