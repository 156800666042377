import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { capitalize, required, positive, formatCurrency } from '../../../utils';
import { translate } from '../../../translate/i18n';
import {
  Button,
  Row,
  Col,
  Container,
  DatePicker,
  TextInput,
  SelectorDiscountUnitTypeForm,
  Text,
  ConfirmModal,
  StyledForm
} from '../../../components';
import { discountCalculator } from '../../discountCalculator';
import { func, bool, number } from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import { Info } from 'react-feather';
import { discountBounds, limit100Percent, limitMonthCount } from '../../validation';

const DiscountPromiseForm = ({
  t,
  handleSubmit,
  submitting,
  deleteDiscountPromiseRequest,
  initialValues,
  incomingPrice,
  amount,
  type
}) => {
  const [discountValidate, setDiscountValidate] = useState([required]);
  const [showConfirmModal, toggleConfirmModal] = useState(false);
  const { timezone, currency } = initialValues;
  const limitPercentage = limit100Percent(type);

  useEffect(() => {
    setDiscountValidate([required, positive, discountBounds({ incomingPrice })]);
  }, [incomingPrice]);

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <Row mb>
          <Col xl={9} lg={9} md={9} sm={9}>
            <Row>
              <Col xl={3} lg={3} md={6} sm={12} pr-half pl-half>
                <Field
                  validate={required}
                  name="redeemBy"
                  component={DatePicker}
                  minDate={new Date()}
                  label={t('REDEEM_BY')}
                  timezone={timezone}
                  Icon={() => (
                    <div
                      style={{
                        height: '14px',
                        marginBottom: '4px',
                        paddingLeft: '4px'
                      }}>
                      <Tooltip content={t('TOOLTIP_REDEEM_BY')} direction="up">
                        <Info size={14} />
                      </Tooltip>
                    </div>
                  )}
                />
              </Col>

              <Col xl={3} lg={3} md={6} sm={12} pr-half pl-half>
                <Field
                  validate={[required, positive]}
                  name="duration"
                  type="number"
                  label={t('DURATION')}
                  component={TextInput}
                  normalize={limitMonthCount}
                />
              </Col>

              <Col xl={3} lg={3} md={6} sm={12} pr-half pl-half>
                <Field
                  validate={discountValidate}
                  name="amount"
                  type="number"
                  label={t('AMOUNT')}
                  component={TextInput}
                  normalize={limitPercentage}
                />
              </Col>

              <Col xl={3} lg={3} md={6} sm={12} pr-half pl-half>
                <Field name="type" component={SelectorDiscountUnitTypeForm} label={t('UNIT_TYPE')} />
              </Col>
            </Row>
          </Col>

          <Col xl={3} lg={3} md={3} sm={3} pl pt>
            <Text left lightGrey weight={200} size="md" pt-half>
              {discountCalculator({
                priceValue: +incomingPrice,
                discountValue: +amount,
                discountType: type
              })}
              {formatCurrency(currency)}
            </Text>
          </Col>
        </Row>

        <Row mb right pr>
          <div style={{ textAlign: 'right' }}>
            <Button
              clear
              size="md"
              name="cancel"
              disabled={submitting}
              onClick={() => {
                if (!initialValues.id) {
                  deleteDiscountPromiseRequest(initialValues.id);
                  return;
                }
                toggleConfirmModal(true);
              }}>
              {initialValues.id ? capitalize(t('DELETE')) : capitalize(t('CANCEL'))}
            </Button>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button bg-primary size="md" name="submit" type="submit" disabled={submitting}>
              {capitalize(t('SAVE'))}
            </Button>
          </div>
        </Row>
      </StyledForm>

      <ConfirmModal
        title={t('WARNING')}
        show={showConfirmModal}
        onClose={() => toggleConfirmModal(false)}
        onConfirm={() => {
          deleteDiscountPromiseRequest(initialValues.id);
          toggleConfirmModal(false);
        }}
        content={() => (
          <Container column>
            <Text>{t('DELETE_DISCOUNT')}</Text>
          </Container>
        )}
      />
    </>
  );
};

DiscountPromiseForm.propTypes = {
  incomingPrice: number,
  handleSubmit: func,
  deleteDiscountPromiseRequest: func,
  submitting: bool,
  t: func
};

const selector = formValueSelector('DiscountPromiseForm');
export default compose(
  connect((state) => {
    const { type, amount } = selector(state, 'type', 'amount');
    return {
      type,
      amount
    };
  }),
  reduxForm({
    form: 'DiscountPromiseForm',
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  translate('discounts')
)(DiscountPromiseForm);
