import { StreamChat } from 'stream-chat';
import { initNotifications, notify } from 'browser-notification';

import { store } from '../store/configureStore';
import {
  setStreamConnected,
  setStreamDisconnected,
  setStreamUnreadCount,
  setCurrentChannel,
  toggleStreamMini
} from './chat.actions';

let client;
const defaultChannelType = 'messaging';

class Stream {
  constructor() {
    if (!client) {
      client = StreamChat.getInstance(process.env.REACT_APP_STREAM_KEY);
    }
  }

  async connect({ id, name, image }, token) {
    this.id = id;

    client.connectUser({ id, name, image }, token);

    client.on('connection.changed', async (event) => {
      if (event.online) {
        store.dispatch(setStreamConnected());
        client.queryChannels({ members: { $in: [id] } }, {}, {});
        this.confirmNotifications = await initNotifications({ ignoreFocused: true });
      } else {
        store.dispatch(setStreamDisconnected());
      }
    });

    client.on('message.new', (event) => {
      store.dispatch(setStreamUnreadCount(event.total_unread_count));

      const notification = notify(`New message from ${event.message.user.name}`, {
        body: event.message.text
      });

      if (notification) {
        notification.onclick = () => {
          window.focus();
          notification.close();

          store.dispatch(
            setCurrentChannel({
              id: event.channel_id,
              type: event.channel_type
            })
          );
          store.dispatch(toggleStreamMini({ block: true }));
        };
      }
    });

    client.on('notification.mark_read', (event) => {
      store.dispatch(setStreamUnreadCount(event.total_unread_count));
    });

    client.on('user.watching.start', () => {
      store.dispatch(setStreamUnreadCount(client.user.total_unread_count));
    });
  }

  disconnect() {
    client.disconnectUser();
    store.dispatch(setStreamDisconnected());
  }

  get client() {
    return client;
  }

  getChannel({ id, type }) {
    return client.channel(type, id);
  }

  async createChannel(memberId) {
    try {
      const channels = await this.client.queryChannels(
        {
          members: [memberId, this.id],
          type: 'communityManager'
        },
        {},
        {}
      );

      if (channels.length) {
        store.dispatch(
          setCurrentChannel({
            id: channels[0].id,
            type: channels[0].type
          })
        );
        return;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    const channel = client.channel(defaultChannelType, {
      members: [this.id, memberId]
    });

    await channel.create();

    store.dispatch(setCurrentChannel(channel));
  }
}

export default new Stream();
