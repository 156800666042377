import { emptyArray, emptyObject } from '../utils';

export const initHub = {
  isFetching: false,
  list: emptyArray,
  current: {
    item: emptyObject,
    isFetching: false
  }
};

export const GET_HUBS = 'GET_HUBS';
export const SET_HUBS = 'SET_HUBS';

export const GET_HUB = 'GET_HUB';
export const SET_HUB = 'SET_HUB';
