/* eslint-disable no-dupe-class-members */
import React, { PureComponent } from 'react';
import { translate } from '../translate/i18n';
import { compose, pathOr, prop } from 'ramda';
import { connect } from 'react-redux';
import { emptyArray, capitalize } from '../utils';
import { bool, array, object, func } from 'prop-types';
import { Loader, Text } from './';
import {
  PhoneContainer,
  PhoneInputContainer,
  ButtonSelectCountries,
  StyledInput,
  CountriesContainer,
  StyledOption
} from './Phone.styles';
import onClickOutside from 'react-onclickoutside';
import AsyncSelect from 'react-select/lib/Async';
import { getPhonePrefixes } from '../enum/enum.service';
import { customStyles } from './selector/Selector';
import { withTheme } from '@emotion/react';
import { getCountries } from '../enum/enum.actions';

class Phone extends PureComponent {
  static propTypes = {
    isFetching: bool,
    list: array,
    value: object,
    theme: object,
    onChange: func,
    onfocus: func,
    active: bool
  };

  state = { showCountries: false };

  componentDidMount() {
    const { list, getCountries } = this.props;
    if (!list.length) getCountries();
  }

  handleClick = this.handleClick.bind(this);
  handleClick(e) {
    e.preventDefault();
    const { showCountries } = this.state;
    this.setState({ showCountries: !showCountries });
  }

  formatSelectOptions(props) {
    return (
      <StyledOption {...props.innerProps}>
        <div style={{ width: '25px', marginRight: '10px' }}>
          <img alt="flag" src={props.data.flag} style={{ width: '100%' }} />
        </div>
        <Text pl-quarter pr-quarter weight={300}>{`(+${props.data.prefix}) `}</Text>
        <Text weight={200}>{capitalize(props.data.country)}</Text>
      </StyledOption>
    );
  }

  handleClickOutside = this.handleClickOutside.bind(this);
  handleClickOutside() {
    this.setState({ showCountries: false });
  }

  componentWillUnmount() {
    if (this.debounce) clearTimeout(this.debounce);
  }

  handleOptions(search) {
    return getPhonePrefixes({
      page: 1,
      limit: 10,
      criteria: {
        search,
        attributes: ['country', 'prefix']
      }
    }).then((list) =>
      list.map((option) => ({
        label: capitalize(option.country),
        value: option.code,
        prefix: option.prefix,
        flag: option.flag,
        country: option.country
      }))
    );
  }

  renderPrefix(prefix) {
    if (prop('prefix', prefix)) return <Text>{`+${prefix.prefix}`}</Text>;

    return (
      <Text grey={200} size="md" pr-quarter>
        +XX
      </Text>
    );
  }

  handleChange(newValue) {
    const { onChange, value } = this.props;
    onChange({ ...value, ...newValue });
    this.setState({ showCountries: false });
  }

  render() {
    const { isFetching, value, t, theme, active, onFocus } = this.props;
    const { showCountries } = this.state;
    if (isFetching) return <Loader />;

    const number = prop('number', value);
    const prefix = prop('prefix', value);

    return (
      <PhoneContainer>
        <PhoneInputContainer active={active}>
          <ButtonSelectCountries onClick={this.handleClick}>{this.renderPrefix(prefix)}</ButtonSelectCountries>
          <StyledInput
            ref={(ref) => (this.inputRef = ref)}
            value={number || ''}
            onFocus={onFocus}
            onChange={(event) => this.handleChange({ number: event.target.value })}
          />
        </PhoneInputContainer>
        {showCountries && (
          <CountriesContainer>
            <AsyncSelect
              autoFocus
              styles={customStyles(theme)}
              placeholder={t('SELECT_COUNTRY')}
              cacheOptions
              defaultOptions
              openMenuOnFocus
              loadOptions={this.handleOptions}
              components={{ Option: this.formatSelectOptions }}
              onChange={(value) => this.handleChange({ prefix: value })}
            />
          </CountriesContainer>
        )}
      </PhoneContainer>
    );
  }
}

export default compose(
  translate('form'),
  connect(
    (state) => ({
      isFetching: pathOr(emptyArray, ['enums', 'geo', 'isFetching'], state),
      list: pathOr(emptyArray, ['enums', 'geo', 'list'], state)
    }),
    { getCountries }
  ),
  withTheme,
  onClickOutside
)(Phone);
