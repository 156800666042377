import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import hash from 'object-hash';
import { compose, values, all, reduce, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { capitalize, getTimeMask } from '../utils';
import { handleFilters } from '../utils';
import { Row, Col, SelectorHub, SelectorCreated, SelectorInventoryCreator, collapsible } from '../components';
import SelectorHouse from '../components/selector/Selector.house';

const InventoriesFilter = ({ t, onFilterSelect }) => {
  const [createdMask, setCreatedMask] = useState();
  let [hashCode, setHashCode] = useState('');

  let [filters, setFilters] = useState({
    creator: null,
    createdAt: null,
    hubId: [],
    houseId: []
  });

  let [loaded, setLoaded] = useState({
    creator: false,
    createdAt: false,
    hubId: false,
    houseId: false
  });

  const handleChange = (filter) => (value) => {
    filters[filter] = value;
    setFilters({
      ...filters
    });
  };

  const onLoad =
    (filter) =>
    (val = true) => {
      if (loaded[filter] !== val) {
        loaded[filter] = val;
        setLoaded({
          ...loaded
        });
      }
    };

  useEffect(() => {
    setCreatedMask(getTimeMask(t));
  }, []);

  useEffect(() => {
    if (all((item) => item)(values(loaded))) {
      const newHashCode = hash(filters);
      if (newHashCode !== hashCode) {
        hashCode = newHashCode;
        setHashCode(newHashCode);
        onFilterSelect(
          reduce(
            (all, filter) => {
              if (filter === 'createdAt') {
                if (filters[filter]) {
                  return {
                    ...all,
                    [createdMask[filters[filter]].filter]: createdMask[filters[filter]].date
                  };
                }

                return all;
              }
              return handleFilters(all, filter, filters[filter]);
            },
            {},
            keys(filters)
          )
        );
      }
    }
  }, [filters, loaded]);

  return (
    <Row middle mb>
      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorCreated
          name="createdAt"
          onChange={handleChange('createdAt')}
          label={t('CREATED_AT')}
          placeholder={t('form:CHOOSE_A_DATE')}
          value={filters.createdAt}
          onLoad={onLoad('createdAt')}
          storeName="Inventories"
          ready={!!createdMask}
          options={values(createdMask)}
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorHub
          name="hub"
          onChange={handleChange('hubId')}
          isMulti
          label={t('HUB')}
          placeholder={t('form:TYPE_A_HUB_NAME')}
          value={filters.hubId}
          onLoad={onLoad('hubId')}
          storeName="Inventories"
          storeType="const"
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorHouse
          name="house"
          label={t('HOUSE')}
          isMulti
          hubId={filters.hubId}
          onChange={handleChange('houseId')}
          ready={loaded.hubId}
          value={filters.houseId}
          onLoad={onLoad('houseId')}
          placeholder={t('form:CHOOSE_A_HOUSE')}
          storeName="Inventories"
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorInventoryCreator
          name="creator"
          onChange={handleChange('creator')}
          label={capitalize(t('CREATED_BY'))}
          placeholder={t('default:ALL')}
          value={filters.creator}
          onLoad={onLoad('creator')}
          storeName="Inventories"
        />
      </Col>
    </Row>
  );
};

InventoriesFilter.propTypes = {
  onFilterSelect: func,
  t: func
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_FILTER',
    labelShow: 'default:SHOW_FILTER'
  }),
  translate(['inventories', 'default'])
)(InventoriesFilter);
