import React, { useEffect, useState } from 'react';
import Selector from './Selector';
import { func, string, array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { emptyArray, makeOptions, defaultSelectLimit, isEmptyOrNil } from '../../utils';
import { getRooms } from '../../rooms/room.actions';

const SelectorRoom = (props) => {
  const {
    getRooms,
    houseId,
    name,
    list,
    onChange,
    isMulti,
    label,
    value,
    isInForm,
    isDisabled,
    placeholder,
    autoSelect,
    getValue
  } = props;

  const [options, setOptions] = useState(emptyArray);

  const handleChange = (val) => {
    if (isEmptyOrNil(val)) {
      return onChange(null);
    }
    return onChange(val);
  };

  const onInputChange = (val) => {
    const filteredOptions = makeOptions(list, 'id', 'number')
      .filter((option) => option.label.startsWith(val))
      .sort((a, b) => parseInt(a.label, 10) - parseInt(b.label, 10));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    getRooms({ limit: defaultSelectLimit, houseId });
  }, [houseId]);

  useEffect(() => {
    const sortedOptions = makeOptions(list, 'id', 'number').sort(
      (a, b) => parseInt(a.label, 10) - parseInt(b.label, 10)
    );

    setOptions(sortedOptions);
  }, [list]);

  useEffect(() => {
    if (autoSelect && houseId) {
      handleChange(pathOr(null, [0, 'value'], options));
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
      getValue={getValue}
      onInputChange={onInputChange}
    />
  );
};

SelectorRoom.propTypes = {
  isInForm: bool,
  name: string.isRequired,
  list: array,
  getRooms: func,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  placeholder: string,
  houseId: string,
  autoSelect: bool,
  getValue: func
};

SelectorRoom.defaultValues = {
  isInForm: false,
  autoSelect: true,
  getValue: undefined
};

export default connect((state) => ({ list: pathOr(emptyArray, ['room', 'list'], state) }), { getRooms })(SelectorRoom);
