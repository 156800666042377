import moment from 'moment-timezone';
import i18n from '../translate/i18n';
import { isEmptyOrNil } from './index';
import { is, propOr, prop, pathOr, isEmpty } from 'ramda';

export const capitalize = (string) => {
  if (!is(String, string)) return string;
  return `${string.charAt(0).toUpperCase()}${string.substring(1, string.length).toLowerCase()}`;
};

export const upperCase = (string) => (is(String, string) ? string.toUpperCase() : string);
export const lowerCase = (string) => (is(String, string) ? string.toLowerCase() : string);

export const twoDigit = (value) => (value.toString().length < 2 ? `0${value}` : value);
const defaultTimezone = 'local';

export const getTimezoneDate = (date, timezone = defaultTimezone) =>
  timezone === 'local' ? moment(date).local() : moment.parseZone(date).tz(timezone);

export const formatDate = (unformatedDate, timezone = defaultTimezone, separator = '/') => {
  if (!unformatedDate) return undefined;
  const date = getTimezoneDate(unformatedDate, timezone);

  return date.format(`DD[${separator}]MM[${separator}]YYYY`);
};

export const formatDateToStandarizedUTC = (date) => moment(date).utc().format('YYYY-MM-DD');

export const formatDatetime = (unformatedDate, timezone = defaultTimezone) => {
  if (!unformatedDate) return undefined;
  const date = getTimezoneDate(unformatedDate, timezone);

  return date.format('DD/MM/YYYY HH:mm');
};

export const formatMonth = (unformatedDate, timezone) => {
  if (!unformatedDate) return undefined;
  const date = getTimezoneDate(unformatedDate, timezone);

  return date.locale(i18n.language).format('MMMM YYYY');
};

export const formatAge = (unformatedDate) => {
  if (!unformatedDate || isEmpty(unformatedDate)) return undefined;
  return moment().diff(unformatedDate, 'years', false);
};

export const formatEmptyValue = (value) => (value ? value : '');

export const formatAddress = (address) => {
  const number = propOr('', 'streetNumber', address);
  const street = propOr('', 'streetName', address);
  const town = prop('town', address) ? prop('town', address) : pathOr('', ['hub', 'name'], address);
  return `${formatEmptyValue(number)} ${formatEmptyValue(street)} ${formatEmptyValue(town)} `;
};

export const formatPhone = (phoneNumber, prefix) => {
  if (!phoneNumber || isEmpty(prefix)) return undefined;

  const formatedNumber = phoneNumber.startsWith('0') ? phoneNumber.substring(1) : phoneNumber;

  return `+${prefix}${formatedNumber}`;
};

export const formatRole = (user) => {
  const role = prop('role', user);

  return role ? i18n.getResource(i18n.language, 'role', role) : '';
};

export const formatFullname = (user) => `${capitalize(prop('firstName', user))} ${capitalize(prop('lastName', user))}`;

export const formatFullnameAndRole = (user) => `${formatFullname(user)} (${formatRole(user)})`;

export const formatNameTruncated = (user) => {
  const firstName = prop('firstName', user);
  const lastName = prop('lastName', user);

  return `${capitalize(lastName)} ${capitalize(firstName).substring(0, 1)}.`;
};

export const formatNameTruncatedAndRole = (user) => `${formatNameTruncated(user)} (${formatRole(user)})`;

export const formatCurrency = (currency) => `${prop('symbol', currency)} (${prop('code', currency)})`;

export const formatGpsCoordinates = (gpsArray = []) => {
  if (gpsArray.length < 2) return '';
  return `${gpsArray[1]}° N, ${gpsArray[0]}° E`;
};

export const formatLocations = (col) => {
  if (isEmptyOrNil(col.value)) {
    return '--';
  }

  return col.value.map((location) => location.name).join(', ');
};

export const formatPrice = (price) =>
  price && Number.isInteger(+price) ? parseInt(price) : parseFloat(price).toFixed(2);

export const formatLeaseLocationLabel = ({ lease } = {}) => {
  let locationLabel = `${capitalize(i18n.getResource(i18n.language, 'member', 'LOCATION'))}: `;
  const periodLabel = capitalize(i18n.getResource(i18n.language, 'default', 'PERIOD'));
  const timezone = propOr(pathOr(undefined, ['room', 'house', 'hub', 'timezone'], lease), 'timezone', lease);

  if (isEmptyOrNil(lease)) {
    locationLabel += '--';
    return locationLabel;
  }

  const house = pathOr(null, ['room', 'house', 'name'], lease);

  if (house) {
    locationLabel += `${house} - `;
  }

  const room = pathOr(null, ['room', 'number'], lease);

  if (room) {
    locationLabel += `${room}, `;
  }

  const start = pathOr(null, ['startDate'], lease);

  if (start) {
    locationLabel += `${periodLabel}: ${formatDate(start, timezone)} - `;
  }

  const moveout = pathOr(null, ['moveoutDate'], lease);

  if (moveout) {
    locationLabel += formatDate(moveout, timezone);
  }

  return locationLabel;
};

export const toUTC00 = (date, timezone) => {
  if (!date) date = moment.utc();
  if (!timezone) timezone = moment.tz.guess();
  return moment.utc(moment.utc(date).tz(timezone).format('YYYY-MM-DD'));
};
