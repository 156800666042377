import { all, takeEvery } from 'redux-saga/effects';
import { path, prop, pathOr } from 'ramda';
import { HANDLE_ERROR_MESSAGE, HANDLE_SUCCESS_MESSAGE } from './toast.actions';
import { toast } from '../utils';
import i18n from '../translate/i18n';

const generateToastSaga = (method) =>
  function* (action) {
    const key = path(['payload', 'key'], action);
    const vars = path(['payload', 'vars'], action);
    const type = i18n.t(`types:${prop('type', vars)}`);

    if (pathOr(null, ['error', 'message'], vars)) {
      vars.error.message = i18n.t(vars.error.message, {
        ns: ['validation']
      });
    }

    yield toast[method](i18n.t(`toast:${key}`, { ...vars, type }));
  };

export const toastSuccess = generateToastSaga('success');
export const toastError = generateToastSaga('error');

export default function* toastSaga() {
  yield all([takeEvery(HANDLE_ERROR_MESSAGE, toastError), takeEvery(HANDLE_SUCCESS_MESSAGE, toastSuccess)]);
}
