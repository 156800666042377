import React, { Fragment } from 'react';
import { compose, path, pathOr, propOr } from 'ramda';
import { connect } from 'react-redux';
import { X, ChevronDown, ChevronUp, Maximize2, Minimize2 } from 'react-feather';
import { func, object, number } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Modal, show, Row, sizing } from '../components';
import Chat from './Chat';
import { toggleCreateChatChannel, toggleStreamMini } from './chat.actions';
import NewChannel from './newChannel';
import { translate } from '../translate/i18n';
import { ROLES } from '../utils/authorizations';
import { spacing } from '../theme/spacing';

// eslint-disable-next-line complexity
const ChatMini = ({ t, chat, toggleStreamMini, toggleCreateChatChannel, windowWidth }) => {
  const Arrow = chat.toggleMini.chat ? ChevronDown : ChevronUp;
  const Size = chat.toggleMini.maximise ? Minimize2 : Maximize2;

  const headerSet = windowWidth <= spacing.breakpoints.lg;

  return (
    <Fragment>
      <div
        style={{ display: chat.toggleMini.block ? 'block' : 'none' }}
        className={`chat-bar ${
          (chat.toggleMini.maximise || headerSet) && chat.toggleMini.chat ? 'full-page' : 'corner'
        }`}>
        <div className="chat-bar-header">
          <Row right>
            <Arrow
              size={24}
              color="#fff"
              onClick={() =>
                toggleStreamMini({
                  chat: !chat.toggleMini.chat
                })
              }
            />

            {!headerSet && (
              <Size
                size={24}
                color="#fff"
                onClick={() =>
                  toggleStreamMini({
                    chat: true,
                    maximise: !chat.toggleMini.maximise
                  })
                }
              />
            )}

            <X
              size={24}
              color="#fff"
              onClick={() =>
                toggleStreamMini({
                  block: false
                })
              }
            />
          </Row>
        </div>

        <div className="chat-mini-holder" style={{ display: chat.toggleMini.chat ? 'block' : 'none' }}>
          <Chat maximised={chat.toggleMini.maximise} />
        </div>
      </div>

      <Modal
        title={t('NEW_CONVERSATION')}
        show={chat.showCreateChannelModal}
        onClose={() => toggleCreateChatChannel(false)}
        content={() => <NewChannel />}
      />
    </Fragment>
  );
};

ChatMini.propTypes = {
  t: func.isRequired,
  toggleStreamMini: func.isRequired,
  toggleCreateChatChannel: func.isRequired,
  windowWidth: number.isRequired,
  chat: object.isRequired
};

export default compose(
  sizing(),
  withRouter,
  translate('chat'),
  connect(
    (state) => ({
      userRole: path(['user', 'data', 'role'], state),
      chat: propOr({}, ['chat'], state)
    }),
    { toggleStreamMini, toggleCreateChatChannel }
  ),
  show(
    (props) =>
      pathOr(false, ['chat', 'connectStatus'], props) &&
      [ROLES.ADMIN, ROLES.CM, ROLES.PM].includes(props.userRole) &&
      pathOr('', ['location', 'pathname'], props) !== '/chat'
  )
)(ChatMini);
