import React from 'react';
import { func } from 'prop-types';
import { Field, FormSection } from 'redux-form';
import { Text, Row, Col, SectionTitle, TextInput } from '../components';
import { translate } from '../translate/i18n';
import { required } from '../utils';

const LandlordFormSection = (props) => {
  const { t } = props;

  return (
    <FormSection name="landlord">
      <SectionTitle translationKey="form:LANDLORD" />

      <Row>
        <Col lg={4} pr-half pl-half>
          <Field
            name="name"
            component={TextInput}
            type="text"
            label={`${t('NAME')}*`}
            placeholder={t('NAME')}
            validate={required}
          />
        </Col>
        <Col lg={8} pr-half pl-half>
          <Field
            name="address"
            component={TextInput}
            type="text"
            label={`${t('ADDRESS')}*`}
            placeholder={t('ADDRESS')}
            validate={required}
          />
        </Col>
      </Row>

      <Text size="md" weight={300} pb pr-half pl-half>
        {t('house:CONTACT_PERSON')}
      </Text>

      <Row>
        <Col lg={4} pr-half pl-half>
          <Field
            name="contactName"
            component={TextInput}
            type="text"
            label={`${t('CONTACT_NAME')}*`}
            placeholder={t('CONTACT_NAME')}
            validate={required}
          />
        </Col>
        <Col lg={4} pr-half pl-half>
          <Field
            name="email"
            component={TextInput}
            type="text"
            label={`${t('EMAIL')}*`}
            placeholder={t('EMAIL')}
            validate={required}
          />
        </Col>
        <Col lg={4} pr-half pl-half>
          <Field
            name="idNumber"
            component={TextInput}
            type="text"
            validate={required}
            label={`${t('ID_NUMBER')}*`}
            placeholder={t('ID_NUMBER')}
          />
        </Col>
      </Row>
    </FormSection>
  );
};

LandlordFormSection.propTypes = {
  t: func
};

export default translate('form')(LandlordFormSection);
