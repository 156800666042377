import React, { useState, useEffect, Fragment } from 'react';
import { func, object } from 'prop-types';
import { formValues, FieldArray, Field, change } from 'redux-form';
import { compose, propOr, isEmpty } from 'ramda';
import { connect } from 'react-redux';

import { translate } from '../../translate/i18n';
import { Button, Col, Row, Text, DirectUpload, Modal, TextAreaForm, Selector } from '../../components';
import { capitalize, toast } from '../../utils';
import { deleteRemoteFile } from '../../service';
import { ImageContainer, StyledImage } from '../Inventory.style';
import { ErrorText, InputContainer } from '../../components/form/TextInput.styles';

const Thumbnails = ({ fields, onPictureClick }) => {
  const existingFiles = fields.map((doc, i) => (
    <Field
      key={doc}
      afterDelete={() => {
        fields.move(i, fields.length - 1);
        fields.pop();
      }}
      name={`${doc}.link`}
      size={240}
      component={DirectUpload}
      onPictureClick={onPictureClick}
      accept={['image/png', 'image/jpeg']}
    />
  ));

  return (
    <Col xl={3} lg={5} md={5} sm={12}>
      <Row>
        {existingFiles}
        <Field
          component={DirectUpload}
          name={`${fields.name}[${fields.length}].link`}
          key={`${fields.name}[${fields.length}].link`}
          size={240}
          accept={['image/png', 'image/jpeg']}
        />
      </Row>
    </Col>
  );
};

Thumbnails.propTypes = {
  fields: object,
  onPictureClick: func
};

export const locations = [
  {
    label: 'OTHER',
    value: 'Other'
  },
  {
    label: 'BATHROOM',
    value: 'Bathroom'
  },
  {
    label: 'KITCHEN',
    value: 'Kitchen'
  },
  {
    label: 'LIVING',
    value: 'Living'
  },
  {
    label: 'LAUNDRY',
    value: 'Laundry'
  },
  {
    label: 'GARDEN',
    value: 'Garden'
  },
  {
    label: 'BASEMENT',
    value: 'Basement'
  },
  {
    label: 'BEDROOM',
    value: 'Bedroom'
  },
  {
    label: 'CINEMA',
    value: 'Cinema'
  },
  {
    label: 'DINING',
    value: 'Dining'
  },
  {
    label: 'ENTRANCE',
    value: 'Entrance'
  },
  {
    label: 'GYM',
    value: 'Gym'
  },
  {
    label: 'TOILET',
    value: 'Toilet'
  },
  {
    label: 'ZEN',
    value: 'Zen'
  }
];

const IssueList = compose(
  translate('inventories'),
  formValues({ issues: 'issues' })
)(({ fields, onPictureClick, t }) => {
  const remove = (index) => {
    const uploads = propOr([], 'uploads', fields.get(index));

    uploads.map((item) =>
      deleteRemoteFile(item.link).catch(() => {
        toast.error(t('form:ERR_DELETE_FILE'), { autoClose: 1600 });
      })
    );

    fields.remove(index);
  };

  return fields.map((issue, i) => (
    <Row pt key={issue} bb-lightGrey>
      <FieldArray name={`${issue}.uploads`} component={Thumbnails} onPictureClick={onPictureClick} />

      <Col xl={6} lg={7} md={7} sm={12}>
        <Row>
          <Col lg={12}>
            <Field
              component={({ input, meta: { touched, error } = {}, ...props }) => (
                <InputContainer>
                  <Selector
                    options={locations.map((item) => ({
                      ...item,
                      label: t(`ISSUE_LOCATION.${item.label}`)
                    }))}
                    isInForm
                    {...props}
                    {...input}
                  />
                  {touched && error && <ErrorText>{t(`validation:${error}`)}</ErrorText>}
                </InputContainer>
              )}
              label={`${t('FORM:LOCATION')}*`}
              placeholder={t('SELECT_LOCATION')}
              name={`${issue}.location`}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Field component={TextAreaForm} label={`${t('FORM:DESCRIPTION')}*`} name={`${issue}.description`} />
          </Col>
        </Row>
      </Col>

      <Col xl={3} lg={12} md={12} sm={12}>
        {(i || !isEmpty(fields.get(i))) && (
          <Row right mb>
            <Button name="addIssue" onClick={() => remove(i)} bg-primary size="md">
              {t('REMOVE_ISSUE')}
            </Button>
          </Row>
        )}
      </Col>
    </Row>
  ));
});

const Issues = ({ t, issues, formName, dispatch, notEmpty } = {}) => {
  const [picture, selectPicture] = useState(null);

  const addIssue = () => {
    const list = !issues || !issues.length ? [] : issues;
    dispatch(change(formName, 'issues', [...list, notEmpty ? { description: '', location: undefined } : {}]));
  };

  useEffect(() => {
    if (!issues || !issues.length) {
      addIssue();
    }
  }, [issues]);

  return (
    <Fragment>
      <Row>
        <Text size="lg" weight={300}>
          {capitalize(t('ISSUES'))}
        </Text>
      </Row>

      <FieldArray onPictureClick={selectPicture} component={IssueList} name="issues" />

      <Row pt>
        <Button name="addIssue" onClick={addIssue} bg-primary size="md">
          {t('ADD_ISSUE')}
        </Button>
      </Row>

      <Modal
        full
        title={t('PICTURE')}
        isPicturePreview
        show={!!picture}
        onClose={() => selectPicture(null)}
        content={() => (
          <ImageContainer>
            <StyledImage src={picture} alt="picture" />
          </ImageContainer>
        )}
      />
    </Fragment>
  );
};

Issues.defaultProps = {
  notEmpty: false
};

export default compose(translate('inventories'), connect(), formValues({ issues: 'issues' }))(Issues);
