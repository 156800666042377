import { formatPrice } from '../utils';

export const discountCalculator = ({ priceValue, discountValue, discountType, leaseStartDate, discountStartDate }) => {
  if (discountType === 'fixed') {
    return formatPrice(discountValue);
  }
  if (
    leaseStartDate &&
    discountStartDate &&
    leaseStartDate.getFullYear() === discountStartDate.getFullYear() &&
    leaseStartDate.getMonth() === discountStartDate.getMonth() &&
    leaseStartDate.getDate() > 1
  ) {
    const daysInMonth = new Date(leaseStartDate.getFullYear(), leaseStartDate.getMonth() + 1, 0).getDate();
    const nonStayedDays = leaseStartDate.getDate() - 1;
    const stayedDays = daysInMonth - nonStayedDays;
    return formatPrice((((stayedDays * priceValue) / daysInMonth) * discountValue) / 100);
  }
  return formatPrice((+priceValue * +discountValue) / 100);
};
