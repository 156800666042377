import React, { useState } from 'react';
import { shape, object, func } from 'prop-types';
import { formTypesDef, formTypes } from './geo.reducer';
import { reduxForm, Field, change, getFormValues } from 'redux-form';
import { compose, prop, propOr, pathOr } from 'ramda';
import { translate } from '../translate/i18n';
import {
  Modal,
  TextInput,
  AsyncSelectorForm,
  Container,
  Text,
  Button,
  Row,
  ToggleForm,
  AddressAutocomplete,
  SelectorCurrencyForm,
  SelectorTimezoneForm
} from '../components';
import { required, name, maxLength, positive, positiveOrNull } from '../utils';
import { getCM } from '../user/user.service';
import { getPaymentGatewayList } from '../paymentGateway/paymentGateway.service';
import { connect } from 'react-redux';
import { upsertCountry, upsertCity, upsertHub } from './geo.actions';
import { StyledLabel } from '../components/form/TextInput.styles';
import HubPaymentMethodsForm from './Geo.HubPaymentMethodsForm';

const max4 = maxLength(4);

const CountryForm = compose(
  translate('form'),
  reduxForm({ form: 'CountryForm' })
)((props) => {
  const { handleSubmit, t, submitting, pristine, form, dispatch, reset } = props;

  return (
    <Container full column>
      <form onSubmit={handleSubmit(upsertCountry)}>
        <Row right mb-double>
          <Field component={ToggleForm} name="active" label={t('ACTIVATED')} type="checkbox" />
        </Row>
        <Text weight={300} grey={500} size="lg" pb-double>
          {t('COUNTRY_DETAILS')}
        </Text>
        <Field
          autoFocus
          component={TextInput}
          disabled={submitting}
          validate={[required, name]}
          name="name"
          label={t('NAME')}
          clear
        />
        <Field
          disabled={submitting}
          component={TextInput}
          validate={[required, max4, name]}
          maxlength={4}
          name="abbreviation"
          label={t('ABBREVIATION')}
          clear
        />
        {!pristine && (
          <Row right>
            <Button size="md" name="btn-cancel" onClick={() => dispatch(reset(form))} clear disabled={submitting}>
              {t('default:CANCEL')}
            </Button>
            <Button size="md" name="btn-save" bg-primary type="submit" disabled={submitting}>
              {t('default:SAVE')}
            </Button>
          </Row>
        )}
      </form>
    </Container>
  );
});

const CityForm = compose(
  translate('form'),
  reduxForm({ form: 'CityForm', enableReinitialize: true }),
  connect((state) => ({ values: getFormValues('CityForm')(state) }))
)((props) => {
  const { handleSubmit, t, submitting, pristine, dispatch, form, reset, values } = props;

  return (
    <Container full column>
      <form onSubmit={handleSubmit(upsertCity)}>
        <Row right mb-double>
          <Field component={ToggleForm} name="active" label={t('ACTIVATED')} type="checkbox" />
        </Row>
        <Text weight={300} grey={500} size="lg" pb-double>
          {t('CITY_DETAILS')}
        </Text>
        <Field component={TextInput} disabled name="country.name" label={t('COUNTRY')} />
        <Field
          disabled={submitting}
          component={TextInput}
          validate={[required, name]}
          name="name"
          label={t('NAME')}
          clear
        />
        <Field
          disabled={submitting}
          component={TextInput}
          validate={[required, max4, name]}
          maxlength={4}
          name="abbreviation"
          label={t('ABBREVIATION')}
          clear
        />

        <AddressAutocomplete
          label={`${t('LOCATION')}*`}
          onChange={(addr) => dispatch(change(form, 'gpsCoordinates', prop('gpsCoordinates', addr)))}
          value={{ gpsCoordinates: prop('gpsCoordinates', values) }}
          types={['(cities)']}
        />

        <Row mt-half>
          <Field component={TextInput} disabled name="cmsId" label={t('CMS_ID')} />
        </Row>

        {!pristine && (
          <Row right>
            <Button size="md" name="btn-cancel" clear disabled={submitting} onClick={() => dispatch(reset(form))}>
              {t('default:CANCEL')}
            </Button>
            <Button size="md" name="btn-save" bg-primary type="submit" disabled={submitting}>
              {t('default:SAVE')}
            </Button>
          </Row>
        )}
      </form>
    </Container>
  );
});

const formatManager = (manager) => {
  if (manager)
    return {
      value: manager,
      label: `${prop('firstName', manager)} ${prop('lastName', manager)}`
    };

  return undefined;
};

const formatPaymentGateway = (item) => {
  if (item)
    return {
      value: item,
      label: item.name
    };

  return undefined;
};

const HubForm = compose(
  translate('form'),
  reduxForm({ form: 'HubForm' }),
  connect((state) => ({ values: getFormValues('HubForm')(state) }))
)(({ handleSubmit, t, submitting, pristine, dispatch, reset, form, initialValues, values }) => {
  return (
    <form onSubmit={handleSubmit(upsertHub)}>
      <Row right mb-double>
        {initialValues.active ||
        (initialValues.paymentGatewayId && pathOr(null, ['paymentMethods', 'length'], initialValues)) ? (
          <Field component={ToggleForm} name="active" label={t('ACTIVATED')} type="checkbox" />
        ) : (
          <StyledLabel>{t('ACTIVATE_RULE')}</StyledLabel>
        )}
      </Row>
      <Text weight={300} grey={500} size="lg" pb-double>
        {t('HUB_DETAILS')}
      </Text>
      <Field component={TextInput} disabled name="city.name" label={t('CITY')} />
      <Field
        disabled={submitting}
        component={TextInput}
        validate={[required, name]}
        name="name"
        label={t('NAME')}
        clear
      />

      <Field
        disabled={submitting}
        component={AsyncSelectorForm}
        validate={required}
        loadOptions={(search) => getCM(search).then(({ rows }) => rows.map(formatManager))}
        name="manager"
        format={formatManager}
        label={t('COMMUNITY_MANAGER')}
      />

      <Field
        disabled={submitting}
        component={AsyncSelectorForm}
        validate={required}
        loadOptions={(search) => getCM(search).then(({ rows }) => rows.map(formatManager))}
        name="propertyManager"
        format={formatManager}
        label={t('PROPERTY_MANAGER')}
      />

      <Field
        disabled={submitting}
        component={SelectorTimezoneForm}
        validate={required}
        name="timezone"
        label={t('TIMEZONE')}
      />

      <Field
        disabled={submitting}
        component={AsyncSelectorForm}
        validate={required}
        loadOptions={(search) => getCM(search).then(({ rows }) => rows.map(formatManager))}
        name="hubManager"
        format={formatManager}
        label={t('HUB_MANAGER')}
      />

      <Field
        disabled={submitting}
        clear
        label={`${t('MIN_DEPOSIT')}`}
        name="minDeposit"
        component={TextInput}
        validate={[required, positiveOrNull]}
        type="number"
      />

      <Field
        disabled={submitting}
        clear
        label={`${t('DEPOSIT_MULTIPLIER')}`}
        name="depositMultiplier"
        component={TextInput}
        validate={[required, positive]}
        type="number"
      />

      <Field
        disabled={submitting}
        clear
        label={`${t('APPLICATION_FEE_AMOUNT')}`}
        name="applicationFeeAmount"
        component={TextInput}
        validate={[required, positiveOrNull]}
        type="number"
      />

      <Field
        disabled={submitting}
        clear
        label={`${t('PREBOOKING_FEE_AMOUNT')}`}
        name="prebookingFeeAmount"
        component={TextInput}
        validate={[required, positiveOrNull]}
        type="number"
      />

      <Field
        disabled={submitting}
        clear
        label={`${t('EMERGENCY_PHONE')}`}
        placeholder={'+x(xxx)xxx-xx-xx'}
        name="emergencyPhone"
        component={TextInput}
        validate={[required]}
      />

      <Field
        disabled={submitting}
        clear
        label={`${t('HOTLINE_PHONE')}`}
        placeholder={'+x(xxx)xxx-xx-xx'}
        name="hotlinePhone"
        component={TextInput}
        validate={[required]}
      />

      {propOr(null, 'paymentGateway', values) && (
        <Field
          disabled={submitting}
          component={SelectorCurrencyForm}
          paymentGatewayId={values.paymentGateway.id}
          notEmpty
          validate={required}
          label={t('CURRENCY')}
          name="currencyCode"
        />
      )}

      <Field
        disabled={submitting || propOr(null, 'paymentGatewayId', initialValues)}
        component={AsyncSelectorForm}
        validate={required}
        loadOptions={(search) => getPaymentGatewayList(search).then(({ rows }) => rows.map(formatPaymentGateway))}
        name="paymentGateway"
        format={formatPaymentGateway}
        label={t('PAYMENT_GATEWAY')}
      />

      {!pristine && (
        <Row right mb md>
          <Button size="md" name="btn-cancel" onClick={() => dispatch(reset(form))} clear disabled={submitting}>
            {t('default:CANCEL')}
          </Button>
          <Button size="md" name="btn-save" bg-primary type="submit" disabled={submitting}>
            {t('default:SAVE')}
          </Button>
        </Row>
      )}
    </form>
  );
});

const HubPaymentMethods = compose(translate())(({ data, t }) => {
  const [show, setModal] = useState(false);

  return (
    <Row>
      <Row md>
        <StyledLabel active={false}>{t('form:HUB_PAYMENT_METHODS')}</StyledLabel>
      </Row>
      <Row md>
        {data.paymentMethods && data.paymentMethods.length ? (
          <ul>
            {data.paymentMethods.map((item) => (
              <li key={item.payment_method.id}>
                <Text mb>{`${item.payment_method.name}${item.default ? ` ${t('form:DEFAULT')}` : ''}`}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Text mb size="sm">
            {t('geo:NO_PAYMENT_METHODS')}
          </Text>
        )}
      </Row>

      <Row right>
        <Button size="md" name="btn-cancel" bg-primary onClick={() => setModal(true)}>
          {t('geo:EDIT_PAYMENT_METHODS')}
        </Button>
      </Row>

      <Modal
        title={t('form:HUB_PAYMENT_METHODS')}
        show={show}
        onClose={() => setModal(false)}
        content={() => <HubPaymentMethodsForm data={data} onClose={() => setModal(false)} />}
      />
    </Row>
  );
});

const FormHandler = ({ form }) => {
  switch (form.type) {
    case formTypes.COUNTRY: {
      return <CountryForm initialValues={form.data} />;
    }
    case formTypes.CITY: {
      return <CityForm initialValues={form.data} />;
    }
    case formTypes.HUB: {
      return (
        <Container full column>
          <HubForm initialValues={form.data} />

          {form.data && form.data.paymentGatewayId && form.data.paymentMethods && (
            <HubPaymentMethods data={form.data} />
          )}
        </Container>
      );
    }
    default: {
      return null;
    }
  }
};

FormHandler.propTypes = {
  form: shape({
    type: formTypesDef,
    data: object
  }).isRequired,
  t: func,
  dispatch: func
};

export default translate('geo')(FormHandler);
