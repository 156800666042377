import { emptyArray, emptyObject } from '../utils';

export const initialHouses = {
  list: emptyArray,
  count: 0,
  isFetching: false,
  isCreating: false,
  isUpdating: false,
  current: {
    isFetching: true,
    data: emptyObject
  },
  areas: {
    list: emptyArray,
    count: 0,
    isFetching: false
  }
};

export const GET_HOUSES = 'GET_HOUSES';
export const GET_HOUSES_SUCCESS = 'GET_HOUSES_SUCCESS';
export const APPEND_HOUSES = 'APPEND_HOUSES';
export const APPEND_HOUSES_SUCCESS = 'APPEND_HOUSES_SUCCESS';
export const APPEND_HOUSES_FAIL = 'APPEND_HOUSES_FAIL';
export const HOUSE_CREATION = 'HOUSE_CREATION';
export const HOUSE_CREATION_SUCCESS = 'HOUSE_CREATION_SUCCESS';
export const HOUSE_CREATION_FAILURE = 'HOUSE_CREATION_FAILURE';
export const HOUSE_UPDATE = 'HOUSE_UPDATE';
export const HOUSE_UPDATE_SUCCESS = 'HOUSE_UPDATE_SUCCESS';

export const GET_HOUSE_TRASHES = 'GET_HOUSE_TRASHES';
export const GET_HOUSE_TRASHES_SUCCESS = 'GET_HOUSE_TRASHES_SUCCESS';
export const GET_HOUSE_TRASHES_FAIL = 'GET_HOUSE_TRASHES_FAIL';
export const UPSERT_TRASH = 'UPSERT_TRASH';
export const DELETE_TRASH = 'DELETE_TRASH';
export const DELETE_TRASH_SUCCESS = 'DELETE_TRASH_SUCCESS';
export const DELETE_TRASH_FAILURE = 'DELETE_TRASH_FAILURE';

export const DELETE_HOUSES = 'DELETE_HOUSES';
export const DELETE_HOUSES_SUCCESS = 'DELETE_HOUSES_SUCCESS';
export const DELETE_HOUSES_FAILURE = 'DELETE_HOUSES_FAILURE';

export const GET_HOUSE_SERVICES = 'GET_HOUSE_SERVICES';
export const GET_HOUSE_SERVICES_SUCCESS = 'GET_HOUSE_SERVICES_SUCCESS';
export const GET_HOUSE_SERVICES_FAILURE = 'GET_HOUSE_SERVICES_FAILURE';

export const UPSERT_SERVICE = 'UPSERT_SERVICE';
export const DELETE_SERVICES = 'DELETE_SERVICES';
export const DELETE_SERVICES_SUCCESS = 'DELETE_SERVICES_SUCCESS';
export const DELETE_SERVICES_FAILURE = 'DELETE_SERVICES_FAILURE';

export const UPSERT_UNIT = 'UPSERT_UNIT';
export const DELETE_UNIT = 'DELETE_UNIT';
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS';
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE';

export const UPSERT_HOUSE_LOCK = 'UPSERT_HOUSE_LOCK';
export const DELETE_HOUSE_LOCK = 'DELETE_HOUSE_LOCK';
export const DELETE_HOUSE_LOCK_SUCCESS = 'DELETE_HOUSE_LOCK_SUCCESS';

export const GET_HOUSE_AREAS = 'GET_HOUSE_AREAS';
export const SET_HOUSE_AREAS = 'SET_HOUSE_AREAS';
export const ADD_HOUSE_AREAS = 'ADD_HOUSE_AREAS';
export const ADD_HOUSE_AREAS_SUCCESS = 'ADD_HOUSE_AREAS_SUCCESS';
export const UPDATE_HOUSE_AREAS = 'UPDATE_HOUSE_AREAS';
export const UPDATE_HOUSE_AREAS_SUCCESS = 'UPDATE_HOUSE_AREAS_SUCCESS';
export const REMOVE_HOUSE_AREAS = 'REMOVE_HOUSE_AREAS';
export const REMOVE_HOUSE_AREAS_SUCCESS = 'REMOVE_HOUSE_AREAS_SUCCESS';
