import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { translate } from '../../translate/i18n';
import { capitalize } from '../../utils';
import { func, bool, oneOfType, string, object } from 'prop-types';
import { Button, TextInput, Text, Row } from '../../components';
import { isEmail } from '../../utils';

const swapBeLink = (t) => {
  if (!process.env.REACT_APP_RELEASE) {
    return (
      <Link to="/changeBackend" className="Button">
        <Text lightGrey>{t('SWAP_LINKED_BACKEND')}</Text>
      </Link>
    );
  }
};
const LoginForm = ({ handleSubmit, submitting, t, error }) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="email"
      component={TextInput}
      type="text"
      placeholder="example@example.com"
      label={`${capitalize(t('EMAIL'))}*`}
    />
    <Field
      name="password"
      component={TextInput}
      type="password"
      placeholder={t('PASSWORD')}
      label={`${capitalize(t('PASSWORD'))}*`}
    />
    {!!error && <Text red={200}>{t(`validation:${error}`)}</Text>}
    <div style={{ textAlign: 'center' }}>
      <Row middle between>
        <Button bg-primary size="md" name="login" type="submit" disabled={submitting}>
          {capitalize(t('LOGIN'))}
        </Button>
        {swapBeLink(t)}
        <Link to="/forgotPassword">
          <Text lightGrey>{t('PASS_QUESTION')}</Text>
        </Link>
      </Row>
    </div>
  </form>
);

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'REQUIRED';
  } else if (!isEmail(values.email)) {
    errors.email = 'INVALID_EMAIL_ADDRESS';
  }

  return errors;
};

LoginForm.propTypes = {
  handleSubmit: func.isRequired,
  submitting: bool,
  t: func,
  error: oneOfType([string, object])
};

export default compose(
  reduxForm({
    form: 'LoginForm',
    validate
  }),
  translate(['auth', 'validation'])
)(LoginForm);
