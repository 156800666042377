import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Text } from './';
import { translate } from '../translate/i18n';
import { compose } from 'ramda';
import { withTheme } from '@emotion/react';
import { func, object, string } from 'prop-types';
import { ArrowLeft } from 'react-feather';

const Back = ({ theme, t, to, label }) => (
  <Link to={to}>
    <Row middle>
      <ArrowLeft size={18} color={theme.colors.grey[500]} />
      {label ? <Text pl-quarter>{label}</Text> : <Text pl-quarter>{t('default:GO_BACK_TO_LIST')}</Text>}
    </Row>
  </Link>
);
Back.propTypes = {
  theme: object,
  t: func,
  to: string.isRequired,
  label: string
};

export default compose(translate(), withTheme)(Back);
