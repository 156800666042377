import React, { useState, useEffect } from 'react';
import { translate } from '../translate/i18n';
import { reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose } from 'ramda';
import { func, object } from 'prop-types';
import { upsertRoomLock, deleteRoomLockRequest } from './room.actions';
import StyledForm from '../components/form.styles';
import { Text, Container, Block, Button, Row, ConfirmModal } from '../components';
import LockFormSection from '../lock/lock.form';
import { connect } from 'react-redux';

const RoomLockForm = (props) => {
  const { t, deleteRoomLockRequest, handleSubmit, submitting, initialValues, onCancel, submitSucceeded } = props;

  useEffect(() => {
    if (submitSucceeded && onCancel) {
      onCancel();
    }
  }, [submitSucceeded, onCancel]);

  const [showConfirmModal, toggleConfirmModal] = useState(false);

  return (
    <Block>
      <StyledForm onSubmit={handleSubmit(upsertRoomLock)}>
        <LockFormSection lock={initialValues.lock} forRoom />

        <div>
          <Row right>
            {onCancel ? (
              <Button name="button" disabled={submitting} clear size="md" onClick={onCancel}>
                {t('form:CANCEL')}
              </Button>
            ) : (
              <Button name="button" disabled={submitting} clear size="md" onClick={() => toggleConfirmModal(true)}>
                {t('form:DELETE')}
              </Button>
            )}
            <Button name="submit" disabled={submitting} type="submit" size="md" bg-primary>
              {t('form:SAVE')} {submitting ? '...' : ''}
            </Button>
          </Row>
        </div>
      </StyledForm>

      <ConfirmModal
        title={t('WARNING')}
        show={showConfirmModal}
        onClose={() => toggleConfirmModal(false)}
        onConfirm={() => {
          deleteRoomLockRequest(initialValues.lock);
        }}
        content={() => (
          <Container column>
            <Text>{t('DELETE_LOCK')}</Text>
          </Container>
        )}
      />
    </Block>
  );
};

RoomLockForm.propTypes = {
  t: func,
  deleteRoomLockRequest: func,
  onCancel: func,
  match: object,
  ...reduxFormPropTypes
};

export default compose(
  reduxForm({ enableReinitialize: true, destroyOnUnmount: true }),
  connect(null, { deleteRoomLockRequest }),
  translate('lock')
)(RoomLockForm);
