import React, { Fragment, useEffect, useRef, useState } from 'react';
import { number, func, array, object, bool } from 'prop-types';
import { prop, isNil } from 'ramda';
import { Table, Loader, Container } from '../';

let resizeTimeout = null;

/**
 * Search for nearest scrollable parent
 * @param {*} ref
 */
const findScrollableParent = (ref) => {
  if (isNil(ref)) return null;

  if (ref.parentElement.className.search('scrollable') > -1) {
    return ref.parentElement;
  }

  return findScrollableParent(ref.parentElement);
};

/**
 * Check if we have fetched the last data row
 * @param {*} data
 */
const hasReachedLastRow = (data) => prop('responseLength', data) === 0;

/**
 * Create new scroll handler
 * @param {Object} data
 * @param {Function} appendMethod
 * @param {Array} state
 * @param {Object} filters
 * @param {Function} checkHasReachedBottom
 */
const createScrollHandler =
  (data, appendMethod, state, { filters, order }, checkHasReachedBottom) =>
  () => {
    const [page, setPage, itemPerScroll] = state;
    const newPageNumber = page + 1;

    if (checkHasReachedBottom() && !hasReachedLastRow(data)) {
      appendMethod({ page: newPageNumber, limit: itemPerScroll, order, ...filters });
      setPage(newPageNumber);
    }
  };

/**
 * Create new resize handler
 * @param {Function} computeItemPerScroll
 * @param {Function} setPreviousItemPerScroll
 * @param {Number} itemPerScroll
 * @param {Function} setItemPerScroll
 */
const createResizeHandler =
  (scrollableParent, computeItemPerScroll, setPreviousItemPerScroll, itemPerScroll, setItemPerScroll) => () => {
    scrollableParent.onScroll = null;

    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        setPreviousItemPerScroll(itemPerScroll);
        setItemPerScroll(computeItemPerScroll());
      }, 600);
    }
  };

/**
 * React component that loads data dynamically depending on the size of its scrollable container
 * @param {*} props
 */
const ScrollableDataTable = (props) => {
  // Props
  const {
    pageSize,
    onRowSelected,
    appendMethod,
    getMethod,
    rowHeight,
    columnDefs,
    defaultColDef,
    rows,
    rowCount,
    isFetching,
    filters,
    onSelectionChanged,
    isRowSelectable,
    onRowClicked,
    onCellClicked,
    frameworkComponents = {},
    ready,
    responseLength
  } = props;

  // Refs
  const container = useRef(null);

  // State
  const [itemPerScroll, setItemPerScroll] = useState(0);
  const [previousItemPerScroll, setPreviousItemPerScroll] = useState(0);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState([]);

  // Other variables
  let resizeHandler = null;
  let scrollableParent = null;
  const datatable = {
    columnDefs,
    frameworkComponents,
    defaultColDef: {
      sortable: true,
      comparator: () => 0,
      icons: {
        sortAscending: function () {
          return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
          <title>asc</title>
          <path d="M15 10.621l-4.292 4.294-1.416-1.416 6.708-6.706c2.236 2.236 4.472 4.472 6.708 6.706l-1.416 1.416-4.292-4.294v14.586h-2v-14.586z"></path>
          </svg>`;
        },
        sortDescending: function () {
          return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
          <title>desc</title>
          <path d="M17 21.379l4.292-4.294c0.472 0.472 0.944 0.944 1.416 1.416l-6.708 6.706-6.708-6.706 1.416-1.416 4.292 4.294v-14.586h2v14.586z"></path>
          </svg>`;
        }
      },
      ...defaultColDef
    },
    rowData: rows
  };

  // Functions
  const computeItemPerScroll = () => {
    const scrollableParent = findScrollableParent(container.current);

    if (isNil(scrollableParent)) return 0;
    if (pageSize) {
      return pageSize;
    }

    const containerRect = container.current.getBoundingClientRect();
    const parentRect = scrollableParent.getBoundingClientRect();
    const computedHeight = Math.round((scrollableParent.clientHeight - containerRect.top + parentRect.top) / rowHeight);

    return computedHeight < 5 ? 5 : computedHeight;
  };

  const hasReachedBottom = () => {
    const scrollableParent = findScrollableParent(container.current);
    const parentContainer = container.current.parentElement;

    return (
      Math.ceil(parentContainer.getBoundingClientRect().height) <=
      Math.ceil(scrollableParent.clientHeight + scrollableParent.scrollTop)
    );
  };

  const bindEventHandlers = () => {
    const data = { list: rows, count: rowCount, responseLength };
    const state = [page, setPage, itemPerScroll];

    window.removeEventListener('resize', resizeHandler);
    resizeHandler = createResizeHandler(
      scrollableParent,
      computeItemPerScroll,
      setPreviousItemPerScroll,
      itemPerScroll,
      setItemPerScroll
    );
    window.addEventListener('resize', resizeHandler);

    scrollableParent.onscroll = createScrollHandler(data, appendMethod, state, { filters, order }, hasReachedBottom);
  };

  const unbindEventHandlers = () => {
    window.removeEventListener('resize', resizeHandler);
    scrollableParent.onscroll = null;
  };

  // Hooks
  useEffect(() => {
    if (pageSize) {
      setItemPerScroll(pageSize);
      return unbindEventHandlers;
    }

    const computedItemPerScroll = computeItemPerScroll();

    setItemPerScroll(computedItemPerScroll);
    return unbindEventHandlers;
  }, [pageSize]);

  useEffect(() => {
    if (isNil(scrollableParent)) {
      scrollableParent = findScrollableParent(container.current);
    }

    if (rowCount !== 0 && !isNil(scrollableParent)) {
      bindEventHandlers();
    }

    return unbindEventHandlers;
  }, [rows, rowCount, responseLength]);

  useEffect(() => {
    if (itemPerScroll > 0 && ready) {
      setPage(1);
      getMethod({ page: 1, limit: itemPerScroll, order, ...filters });
    }
  }, [filters, order, ready]);

  useEffect(() => {
    const data = { list: rows, count: rowCount };
    if (itemPerScroll > previousItemPerScroll && !hasReachedLastRow(data) && ready) {
      getMethod({ page: 1, limit: itemPerScroll, order, ...filters });
    }
  }, [itemPerScroll, previousItemPerScroll]);

  return (
    <Fragment>
      <div ref={container}>
        <Table
          data={datatable}
          rowHeight={rowHeight}
          onRowSelected={onRowSelected}
          onSelectionChanged={onSelectionChanged}
          isRowSelectable={isRowSelectable}
          onRowClicked={onRowClicked}
          onCellClicked={onCellClicked}
          onSortChanged={(event) =>
            setOrder(
              event.api.getSortModel().map(({ colId, sort }) =>
                colId
                  .split('.')
                  .concat(colId.endsWith('user') ? ['lastName'] : [])
                  .concat([sort.toUpperCase()])
              )
            )
          }
        />
      </div>

      {(isFetching || !ready) && (
        <Container center>
          <Loader />
        </Container>
      )}
    </Fragment>
  );
};

ScrollableDataTable.defaultProps = {
  rowHeight: 40,
  pageSize: 0,
  ready: true,
  onRowSelected: () => null,
  onRowClicked: () => null
};

ScrollableDataTable.propTypes = {
  appendMethod: func.isRequired,
  onSelectionChanged: func,
  filters: object,
  getMethod: func.isRequired,
  rowHeight: number,
  pageSize: number,
  onRowSelected: func,
  rowCount: number,
  rows: array,
  columnDefs: array,
  defaultColDef: object,
  isFetching: bool,
  ready: bool,
  isRowSelectable: func,
  onRowClicked: func,
  responseLength: number
};

export default ScrollableDataTable;
