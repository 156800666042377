import React, { useRef, useState, useEffect } from 'react';
import { string, func, object, number, bool, array, oneOfType } from 'prop-types';
import { StyledLabel } from './TextInput.styles';
import { PlusCircle } from 'react-feather';
import { translate } from '../../translate/i18n';
import { path, prop, propOr, isEmpty, is } from 'ramda';
import { Loader, ActionSecondaryButton, Button, Text, Row } from '..';
import { isNotEmptyOrNil, checkFileType, isEmptyOrNil } from '../../utils';
import { UploadContainer, ImageContainer, DeleteContainer } from './Upload.styles';

const mimeTypes = {
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/svg+xml': 'svg',
  'image/jpeg': 'jpeg',
  'video/mp4': 'mp4'
};

const isVideo = (url) => {
  return is(String, url) && url.includes('.mp4');
};

const ButtonContent = ({ file, size, loading, name, t, placeholder, accept, handleClick }) => {
  if (loading) {
    return (
      <Button m-none p-none disabled={loading} name={`view-${name}`} onClick={handleClick} clear>
        <Loader />
      </Button>
    );
  }

  if (isNotEmptyOrNil(file)) {
    if (isVideo(file)) {
      return (
        <Button m-none p-none disabled={loading} name={`view-${name}`} onClick={handleClick} clear>
          <video controls width={size}>
            <source src={file} type="video/mp4" />
            {t('BROWSER_DOESNT_SUPPORT_VIDEOS')}
          </video>
        </Button>
      );
    }

    return (
      <Button m-none p-none disabled={loading} name={`view-${name}`} onClick={handleClick} clear>
        <ImageContainer size={size}>
          <img alt={`upload-${name}`} style={{ minHeight: '100%', maxWidth: '100%' }} src={file} />
        </ImageContainer>
      </Button>
    );
  }

  const extensions = accept.map((mimeType) => mimeTypes[mimeType]);

  return (
    <Button m-none p-none disabled={loading} name={`view-${name}`} onClick={handleClick} clear>
      <PlusCircle size={20} />
      <Text weight={300}>{placeholder || t('ADD_FILE')}</Text>
      <Text weight={300} size="xs">{`(${extensions.join(', ')})`}</Text>
    </Button>
  );
};

ButtonContent.propTypes = {
  file: oneOfType([object, string]),
  size: number,
  loading: bool,
  name: string,
  handleClick: func,
  t: func,
  placeholder: string,
  accept: array
};

export const SubmitError = ({ t, touched, error }) => {
  if (touched && error) {
    return (
      <Text red={500} weight={400} size="sm">
        {t(`validation:${error}`)}
      </Text>
    );
  }

  return null;
};

SubmitError.propTypes = {
  t: func,
  touched: bool,
  error: string
};

export const UploadInput = translate('default')((props) => {
  const {
    t,
    input,
    file,
    size,
    loading,
    preview,
    label,
    accept,
    placeholder,
    meta,
    onChange,
    onDelete,
    typeError,
    isDisabled,
    showDeleteButton,
    onMouseOver,
    onMouseLeave,
    onPictureClick,
    backgroundColor,
    replaceable,
    ContentView = ButtonContent
  } = props;

  const { touched, error } = meta;

  const inputEl = useRef(null);

  const handleClick = () => {
    if (isEmptyOrNil(file) || replaceable) {
      inputEl.current.click();
    } else if (is(Function, onPictureClick)) {
      onPictureClick(file);
    }
  };

  const handleDelete = () => {
    if (inputEl.current) {
      inputEl.current.value = null;
    }

    onDelete();
  };

  return (
    <UploadContainer
      size={size}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      backgroundColor={backgroundColor}>
      {showDeleteButton && (
        <DeleteContainer>
          <ActionSecondaryButton name="delete" danger={true} onClick={handleDelete} icon="Trash2" />
        </DeleteContainer>
      )}

      {label && <StyledLabel>{label}</StyledLabel>}

      {!isDisabled ? (
        <Row middle center full>
          <input
            multiple={false}
            accept={accept.join(',')}
            ref={inputEl}
            onChange={onChange}
            style={{ display: 'none' }}
            type="file"
          />

          <ContentView
            loading={loading}
            file={isNotEmptyOrNil(preview) ? preview : file}
            name={propOr('uploadFile', 'name', input)}
            t={t}
            placeholder={placeholder}
            size={size}
            accept={accept}
            handleClick={handleClick}
          />
        </Row>
      ) : (
        <Row middle center full>
          <ContentView
            loading={loading}
            file={file}
            name={prop('name', input)}
            t={t}
            placeholder={placeholder}
            size={size}
            onClick={() => onPictureClick(file)}
          />
        </Row>
      )}

      <SubmitError error={error} touched={touched} t={t} />
      <SubmitError error={typeError} touched t={t} />
    </UploadContainer>
  );
});

UploadInput.propTypes = {
  label: string,
  file: oneOfType([object, string]),
  t: func,
  placeholder: string,
  input: object,
  meta: object,
  size: number,
  accept: array.isRequired,
  preview: string,
  isDisabled: bool,
  onPictureClick: func
};

const Upload = (props) => {
  // Props
  const { input, accept, isDisabled, ContentView = ButtonContent, backgroundColor, replaceable } = props;

  // State
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [typeError, setTypeError] = useState(null);
  const [hover, setHover] = useState(false);

  // Vars
  const file = path(['input', 'value'], props);
  const hasPreview = isNotEmptyOrNil(preview);
  const showDeleteButton = (hasPreview || isNotEmptyOrNil(file)) && hover && !isDisabled;

  // Functions
  const handleChange = (event) => {
    const onChange = prop('onChange', input);
    const tmpFile = path(['target', 'files', 0], event);
    const typeError = checkFileType(tmpFile, accept);

    if (!typeError) {
      onChange(tmpFile);
      setLoading(true);
      setTypeError(null);

      if (!isEmpty(tmpFile)) {
        previewFile(tmpFile);
      }
    } else {
      setTypeError(typeError);
    }
  };

  const previewFile = (tmpFile) => {
    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      setLoading(false);
      setPreview(path(['target', 'result'], loadEvent));
    };

    reader.readAsDataURL(tmpFile);
  };

  const handleDelete = () => {
    setPreview(null);
    prop('onChange', input)(null);
  };

  const handleMouseOver = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  // Hooks
  useEffect(() => {
    if (file && is(Blob, file)) {
      previewFile(file);
    }
  }, []);

  // Render
  return (
    <UploadInput
      file={file}
      typeError={typeError}
      loading={loading}
      preview={preview}
      onChange={handleChange}
      onDelete={handleDelete}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      ContentView={ContentView}
      showDeleteButton={showDeleteButton}
      backgroundColor={backgroundColor}
      replaceable={replaceable}
      {...props}
    />
  );
};

Upload.defaultProps = {
  isDisabled: false,
  replaceable: false
};

Upload.propTypes = {
  label: string,
  file: oneOfType([object, string]),
  placeholder: string,
  backgroundColor: string,
  input: object,
  meta: object,
  size: number,
  accept: array.isRequired,
  replaceable: bool,
  isDisabled: bool
};

export default Upload;
