import React from 'react';
import { reduxForm, Field, formValues } from 'redux-form';
import { func, bool, string } from 'prop-types';
import { TextAreaForm, Container, Row, Col, DatePicker, ActionsContainer, Button, TextInput } from '../components';
import { translate } from '../translate/i18n';
import { compose } from 'ramda';
import { capitalize, required, maxLength } from '../utils';

const max512 = maxLength(512);

const ApplicantForm = ({ handleSubmit, t, submitting, onClose, moveinDate, timezone }) => {
  // Minimum duration of stay is 1 month
  const minDate = moveinDate ? new Date(moveinDate.replace('/', '-')) : null;
  if (minDate) {
    minDate.setMonth(minDate.getMonth() + 1);
  }
  return (
    <Container column>
      <form onSubmit={handleSubmit}>
        <Row center pl pb>
          <Col lg={12} mr>
            <Field
              validate={required}
              label={t('DATE_MOVE_IN')}
              name="moveinDate"
              component={DatePicker}
              timezone={timezone}
            />
            <Field
              validate={required}
              label={t('DATE_MOVE_OUT')}
              name="moveoutDate"
              disabled={!moveinDate}
              minDate={minDate}
              component={DatePicker}
              timezone={timezone}
            />
            <Field label={t('NUMBER_OF_PEOPLE')} name="peopleNumber" component={TextInput} type="number" />
            <Field
              validate={max512}
              label={t('COMMENTS')}
              name="comment"
              component={TextAreaForm}
              placeholder={t('Type your comment')}
            />
          </Col>
        </Row>
        <ActionsContainer>
          <Row middle p-half right>
            <Button
              bg-primary
              size="md"
              mr-half
              clear
              type="button"
              onClick={onClose}
              name="cancel"
              disabled={submitting}>
              {capitalize(t('default:CANCEL'))}
            </Button>
            <Button bg-primary size="md" name="save" type="submit" disabled={submitting}>
              {capitalize(t('default:SAVE'))}
            </Button>
          </Row>
        </ActionsContainer>
      </form>
    </Container>
  );
};

ApplicantForm.propTypes = {
  handleSubmit: func,
  t: func,
  submitting: bool,
  onClose: func,
  moveinDate: string,
  timezone: string
};

export default compose(
  reduxForm({
    form: 'ApplicantForm'
  }),
  formValues({ timezone: 'timezone' }),
  translate('applications')
)(ApplicantForm);
