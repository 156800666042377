import { isEmpty } from 'ramda';
import { isNotEmptyOrNil } from '../../utils';

const validate = (issues = []) => {
  const errors = {};

  issues.map((item, index) => {
    if (isEmpty(item)) {
      return null;
    }

    if (!isNotEmptyOrNil(item.location)) {
      errors.issues = errors.issues || [];
      errors.issues[index] = errors.issues[index] || {};
      errors.issues[index].location = 'REQUIRED';
    }

    if (!isNotEmptyOrNil(item.description)) {
      errors.issues = errors.issues || [];
      errors.issues[index] = errors.issues[index] || {};
      errors.issues[index].description = 'REQUIRED';
    }

    return null;
  });

  return errors.issues;
};

export default validate;
