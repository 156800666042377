import React from 'react';
import { func, object, bool } from 'prop-types';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, pathOr, prop } from 'ramda';
import { ToastContainer } from 'react-toastify';

import { translate } from './translate/i18n';
import { IsLogged, NotLogged, isAdmin, hasRole, ROLES } from './utils/authorizations';
import Login from './user/auth/Login';
import ForgotPassword from './user/auth/ForgotPassword';
import ChangeBackend from './user/auth/ChangeBackend';
import ResetPassword, { ConnectedResetPasswordGate } from './user/auth/ResetPassword';
import { show } from './components/decorators';
import { Row, Container } from './components';
import Menu from './menu';
import Rooms from './rooms';
import Room from './rooms/Room';
import Applications from './applications';
import Application from './applications/Application';
import Houses from './houses';
import HouseStripe from './houses/House.stripe';
import Members from './members';
import Chat from './chat';
import ChatDeepLink from './chat/ChatDeepLink';
import Member from './members/Member';
import Geo from './geo';
import Leases from './leases';
import Lease from './leases/Lease';
import LeaseSignature from './leases/LeaseSignature';
import House from './houses/House';
import Users from './user';
import Inventories from './inventories';
import Inventory from './inventories/Inventory';
import InventoryCreate from './inventories/InventoryCreate';
import InventoryCreateIssue from './inventories/issues/InventoryCreateIssue';
import CompareInventories from './inventories/Compare';
import Packs from './packs';
import Pack from './packs/Pack';
import PackLocation from './packs/packLocations/PackLocation';
import Cms from './cms';
import Content from './cms/Content';
import Payments from './payments';
import Payment from './payments/Payment';
import { emptyObject } from './utils';
import ChatMini from './chat/chatMini';

const PageWrapper = compose(
  connect((state) => ({
    menu: pathOr(emptyObject, ['menu'], state)
  }))
)(({ children, menu }) => (
  <div className={menu.collapse ? 'collapse_main_menu page-wrap' : ' page-wrap'}>
    <Row>
      <Menu />

      <div className="main-container">
        <ChatMini />
        <Container column scrollable main={true}>
          {children}
        </Container>
      </div>
    </Row>
  </div>
));

const OfflineLayout = () => (
  <Container scrollable main={true}>
    <Switch>
      <Route path="/login" component={NotLogged(Login)} />
      <Route path="/forgotPassword" component={NotLogged(ForgotPassword)} />
      {!process.env.REACT_APP_RELEASE && <Route path="/changeBackend" component={NotLogged(ChangeBackend)} />}
      <Route path="/resetPassword" component={NotLogged(ConnectedResetPasswordGate)} />
      <Route path="/resetPasswordForm" component={NotLogged(ResetPassword)} />
      <Route path="/leases/sign/:id/:token" exact component={NotLogged(LeaseSignature)} />
      <Route
        path="/"
        component={({ history }) => {
          if (localStorage.getItem('IS_INTERNAL')) {
            window.location.replace('/login');
            return null;
          }

          history.push('/login');
          return null;
        }}
      />
    </Switch>
  </Container>
);

const MainLayout = () => (
  <PageWrapper>
    <Switch>
      <Route path="/users" exact component={isAdmin(Users)} />
      <Route path="/members" exact component={IsLogged(Members)} />
      <Route path="/members/:id" component={IsLogged(Member)} />
      <Route path="/rooms" exact component={IsLogged(Rooms)} />
      <Route path="/rooms/:id" component={IsLogged(Room)} />
      <Route path="/leases" exact component={IsLogged(Leases)} />
      <Route path="/leases/:id" component={IsLogged(Lease)} />
      <Route path="/inventories" exact component={IsLogged(Inventories)} />
      <Route path="/inventories/:id/compare" component={IsLogged(CompareInventories)} />
      <Route path="/inventories/create" component={IsLogged(InventoryCreate)} />
      <Route path="/house-issue/create" component={IsLogged(InventoryCreateIssue)} />
      <Route path="/inventories/:id" component={IsLogged(Inventory)} />
      <Route path="/applications" exact component={IsLogged(Applications)} />
      <Route path="/applications/:id" component={IsLogged(Application)} />
      <Route path="/houses" exact component={IsLogged(Houses)} />
      <Route path="/houses/stripe" component={IsLogged(HouseStripe)} />
      <Route path="/houses/:id" render={(props) => <House {...props} />} />
      <Route path="/payments" exact component={IsLogged(Payments)} />
      <Route path="/payments/:userId/hub/:hubId" component={IsLogged(Payment)} />
      <Route path="/packs" exact component={IsLogged(Packs)} />
      <Route path="/packs/:id" component={IsLogged(Pack)} />
      <Route path="/pack/:packId/location/:hubId" component={IsLogged(PackLocation)} />
      <Route path="/cms/:type/:id/:action?" component={IsLogged(Content)} />
      <Route path="/cms" component={IsLogged(Cms)} />
      <Route path="/geo" component={isAdmin(Geo)} />
      <Route path="/chat/:type/id/:id" exact component={IsLogged(ChatDeepLink)} />
      <Route path="/chat" exact component={IsLogged(Chat)} />
      <Redirect to="/applications" />
    </Switch>
  </PageWrapper>
);

const RestrictedLayout = () => (
  <PageWrapper>
    <Switch>
      <Route path="/inventories/create" component={IsLogged(InventoryCreate)} />
      <Route path="/house-issue/create" component={IsLogged(InventoryCreateIssue)} />
      <Route path="/inventories/:id" component={IsLogged(Inventory)} />
      <Redirect to="/inventories/create" />
    </Switch>
  </PageWrapper>
);

const App = ({ history, isLogged, user }) => {
  const Offline = NotLogged(OfflineLayout);
  const Online = hasRole([ROLES.CLEANER], user) ? RestrictedLayout : MainLayout;

  return (
    <Container>
      {!isLogged && <Offline history={history} />}

      {isLogged && <Online history={history} />}

      <ToastContainer />
    </Container>
  );
};

App.propTypes = {
  t: func,
  isLogged: bool,
  user: object,
  history: object
};

export default compose(
  translate(),
  withRouter,
  connect((state) => ({
    user: pathOr(emptyObject, ['user', 'data'], state),
    isInit: pathOr(false, ['user', 'isInit'], state),
    isLogged: pathOr(false, ['user', 'isLogged'], state)
  })),
  show((props) => prop('isInit', props))
)(App);
