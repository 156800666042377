import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';
import hash from 'object-hash';
import { compose, values, all, reduce, keys } from 'ramda';
import { translate } from '../translate/i18n';
import { capitalize, handleFilters } from '../utils';
import { Row, Col, SelectorHub, SearchTextInput, SelectorHouse, collapsible } from '../components';

const MemberFilter = ({ t, onFilterSelect, activeOnly }) => {
  let [hashCode, setHashCode] = useState('');

  let [filters, setFilters] = useState({
    active: activeOnly,
    search: '',
    hubId: [],
    houseId: []
  });

  let [loaded, setLoaded] = useState({
    search: false,
    hubId: false,
    houseId: false
  });

  const handleChange = (filter) => (value) => {
    filters[filter] = value;
    if (filter === 'hubId') {
      onLoad('houseId')(false);
    }

    setFilters({
      ...filters
    });
  };

  const onLoad =
    (filter) =>
    (val = true) => {
      if (loaded[filter] !== val) {
        loaded[filter] = val;
        setLoaded({
          ...loaded
        });
      }
    };

  useEffect(() => {
    if (all((item) => item)(values(loaded))) {
      const newHashCode = hash(filters);
      if (newHashCode !== hashCode) {
        hashCode = newHashCode;
        setHashCode(newHashCode);

        onFilterSelect(reduce((all, filter) => handleFilters(all, filter, filters[filter]), filters, keys(filters)));
      }
    }
  }, [filters, loaded]);

  return (
    <Row middle mb>
      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SearchTextInput
          label={capitalize(t('default:SEARCH'))}
          name="search"
          placeholder={t('default:SEARCH')}
          value={filters.search}
          onChange={handleChange('search')}
          onLoad={onLoad('search')}
          storeName="Members"
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorHub
          name="hub"
          onChange={handleChange('hubId')}
          isMulti
          label={t('house:HUB')}
          placeholder={t('TYPE_A_HUB_NAME')}
          value={filters.hubId}
          onLoad={onLoad('hubId')}
          storeName="Members"
          storeType="const"
        />
      </Col>

      <Col xl={3} lg={4} md={4} sm={6} pr-half mb>
        <SelectorHouse
          name="house"
          label={t('HOUSE')}
          isMulti
          hubId={filters.hubId}
          onChange={handleChange('houseId')}
          isClearable
          ready={loaded.hubId}
          value={filters.houseId}
          onLoad={onLoad('houseId')}
          placeholder={t('CHOOSE_A_HOUSE')}
          storeName="Members"
        />
      </Col>
    </Row>
  );
};

MemberFilter.propTypes = {
  onFilterSelect: func,
  activeOnly: bool,
  t: func
};

export default compose(
  collapsible({
    labelHide: 'default:HIDE_FILTER',
    labelShow: 'default:SHOW_FILTER'
  }),
  translate(['member', 'default'])
)(MemberFilter);
