import { emptyArray, emptyObject } from '../utils';
import {
  genState,
  setFetching,
  appendList,
  setList,
  setListFailure,
  setFetchingCurrent,
  setCurrent,
  appendListFailure
} from '../utils/reducer';
import {
  APPEND_MEMBERS,
  APPEND_MEMBERS_FAIL,
  APPEND_MEMBERS_SUCCESS,
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  GET_MEMBER,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_FAIL,
  RESET_CURRENT_MEMBER,
  GET_USER_LEASES,
  GET_USER_LEASES_SUCCESS,
  GET_USER_LEASES_FAIL,
  GET_USER_INCIDENTS,
  GET_USER_INCIDENTS_SUCCESS,
  GET_USER_INCIDENTS_FAIL,
  GET_STAY_INFO,
  GET_STAY_INFO_SUCCESS,
  GET_STAY_INFO_FAIL,
  updateMember,
  UPDATE_MEMBER_ACCESS,
  UPDATE_MEMBER_ACCESS_SUCCESS,
  UPDATE_MEMBER_ACCESS_FAILURE,
  UPDATE_MEMBER_INFO
} from './member.actions';
import { propOr } from 'ramda';

export const initMember = {
  list: emptyArray,
  isFetching: false,
  count: 0,
  current: {
    data: emptyObject,
    isFetching: false,
    isUpdating: false,
    leases: {
      list: emptyArray,
      isFetching: false
    },
    stayInfo: {
      isFetching: false,
      data: emptyObject
    },
    incidents: {
      isFetching: false,
      data: emptyObject
    }
  },
  errors: emptyObject
};

const members = (state) => ({
  [APPEND_MEMBERS]: setFetching(state, true),
  [APPEND_MEMBERS_SUCCESS]: appendList(state),
  [APPEND_MEMBERS_FAIL]: appendListFailure(state),
  [GET_MEMBERS]: setFetching(state, true),
  [GET_MEMBERS_SUCCESS]: setList(state),
  [GET_MEMBERS_FAIL]: setListFailure(state),
  [GET_MEMBER]: setFetchingCurrent(state, true),
  [GET_MEMBER_SUCCESS]: setCurrent(state),
  [GET_MEMBER_FAIL]: setFetchingCurrent(state, false),
  [RESET_CURRENT_MEMBER]: () => ({ ...state, current: initMember.current }),
  [GET_USER_LEASES]: () => ({
    ...state,
    current: { ...state.current, leases: { ...state.current.leases, isFetching: true } }
  }),
  [GET_USER_INCIDENTS]: () => ({
    ...state,
    current: { ...state.current, incidents: { ...state.current.incidents, isFetching: true } }
  }),
  [GET_USER_LEASES_SUCCESS]: (payload) => ({
    ...state,
    current: {
      ...state.current,
      leases: { ...state.current.leases, isFetching: false, list: propOr(emptyArray, 'rows', payload) }
    }
  }),
  [GET_USER_LEASES_FAIL]: () => ({
    ...state,
    current: {
      ...state.current,
      leases: { ...state.current.leases, isFetching: false }
    }
  }),

  [GET_USER_INCIDENTS_SUCCESS]: (payload) => {
    const list = payload;
    return {
      ...state,
      current: {
        ...state.current,
        incidents: { ...state.current.incidents, isFetching: false, list }
      }
    };
  },
  [GET_USER_INCIDENTS_FAIL]: () => ({
    ...state,
    current: {
      ...state.current,
      incidents: { ...state.current.incidents, isFetching: false }
    }
  }),

  [GET_STAY_INFO]: () => ({
    ...state,
    current: { ...state.current, stayInfo: { ...state.current.stayInfo, isFetching: true } }
  }),
  [GET_STAY_INFO_SUCCESS]: (payload) => ({
    ...state,
    current: { ...state.current, stayInfo: { ...state.current.stayInfo, data: payload, isFetching: false } }
  }),
  [GET_STAY_INFO_FAIL]: () => ({
    ...state,
    current: { ...state.current, stayInfo: { ...state.current.stayInfo, data: emptyObject, isFetching: false } }
  }),
  [updateMember.REQUEST]: () => ({ ...state, current: { ...state.current, isUpdating: true } }),
  [updateMember.SUCCESS]: (payload) => {
    const members = state.list.map((member) => {
      if (member.id === payload.id) return payload;
      return member;
    });

    return { ...state, list: members, current: { ...state.current, data: payload, isUpdating: false } };
  },
  [updateMember.FAILURE]: () => ({
    ...state,
    list: emptyArray,
    current: { ...state.current, isUpdating: false }
  }),
  [UPDATE_MEMBER_ACCESS]: () => ({
    ...state,
    current: { ...state.current, isUpdating: true }
  }),
  [UPDATE_MEMBER_ACCESS_SUCCESS]: (member) => ({
    ...state,
    current: { ...state.current, isUpdating: false, data: { ...state.current.data, banned: member.banned } }
  }),
  [UPDATE_MEMBER_ACCESS_FAILURE]: () => ({
    ...state,
    current: { ...state.current, isUpdating: false }
  }),
  [UPDATE_MEMBER_INFO]: () => ({
    ...state,
    current: { ...state.current, isUpdating: true }
  })
});

export function memberReducer(state = initMember, action) {
  const { type, payload } = action;
  return genState({ state, payload, stateDef: members, type });
}
