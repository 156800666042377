import React from 'react';
import { array, bool, object, string, oneOfType, number, func } from 'prop-types';
import styled from '@emotion/styled';
import { formatStyleProps, spacing, alignement, border } from './decorators';
import { availableColors } from '../utils/styles';
import { is, path, propOr, compose } from 'ramda';

/**
 * @function
 * @param {*} TextInput props
 * @returns
 */
const hasColor = (props) => Object.keys(props).find((prop) => availableColors.includes(prop));

const getColor = (props) => {
  const { theme } = props;
  const { colors } = theme;
  const color = hasColor(props);
  if (color) return is(Number, props[color]) ? path([color, props[color]], colors) : path([color, '500'], colors);

  return colors.grey[500];
};

const StyledText = styled('div')((props) => {
  const {
    border,
    theme,
    size,
    weight,
    spacing,
    alignement,
    underline,
    noWrap,
    labelSpacing,
    cursor,
    type,
    aditionalStyle
  } = props;

  const color = getColor(props);

  const sizes = theme.font.size;

  const result = {
    ...alignement,
    ...spacing,
    ...border,
    textDecoration: 'none',
    color,
    fontSize: propOr(sizes.md, size, sizes),
    whiteSpace: noWrap ? 'nowrap' : 'normal',
    fontFamily: theme.font.family,
    fontWeight: weight || 200,
    textDecorationLine: underline ? 'underline' : 'none',
    textUnderlinePosition: 'under',
    letterSpacing: labelSpacing ? '1px' : '0',
    cursor: cursor || 'auto'
  };

  switch (type) {
    case 'container': {
      result.maxWidth = '100%';
      break;
    }

    default: {
      result.overflow = 'hidden';
      result.textOverflow = 'ellipsis';
    }
  }

  return {
    ...result,
    ...aditionalStyle
  };
});

const Text = ({
  styleProps,
  children,
  html,
  type,
  aditionalStyle,
  weight,
  spacing,
  alignement,
  underline,
  size,
  noWrap,
  border,
  labelSpacing,
  onClick,
  cursor
}) => {
  if (html) {
    children = (
      <div
        className="text-container"
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
    );
  }

  return children ? (
    <StyledText
      weight={weight}
      size={size}
      {...styleProps}
      spacing={spacing}
      noWrap={noWrap}
      border={border}
      labelSpacing={labelSpacing}
      alignement={alignement}
      underline={underline}
      onClick={onClick}
      type={type}
      cursor={cursor}
      aditionalStyle={aditionalStyle}>
      {children}
    </StyledText>
  ) : null;
};

Text.defaultProps = {
  noWrap: true
};

Text.propTypes = {
  children: oneOfType([array, object, string, bool, number, func]),
  aditionalStyle: object,
  spacing: object,
  noWrap: bool,
  labelSpacing: bool,
  onClick: func,
  html: string,
  type: string,
  cursor: string
};

export default compose(formatStyleProps(), spacing(), alignement(), border())(Text);
