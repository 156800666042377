import React, { useState, Fragment } from 'react';
import { Field } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { Col, Row, Text, SelectorHouse, SelectorRoomForm } from '../components';
import { capitalize } from '../utils';

const HouseRoomSelection = ({ t } = {}) => {
  const [selectedHouse, selectHouse] = useState(null);

  return (
    <Fragment>
      <Row mb-quarter>
        <Text size="lg" weight={300}>
          {capitalize(t('LOCATION'))}
        </Text>
      </Row>

      <Row mb>
        <Text>{t('FORM.HOUSE_SECTION_MESSAGE')}</Text>
      </Row>

      <Row>
        <Col xl={3} lg={4} md={6} sm={12} pr-half mb>
          <SelectorHouse
            name="house"
            label={`${capitalize(t('HOUSE'))}*`}
            placeholder={t('FORM.SELECT_A_HOUSE')}
            onChange={selectHouse}
            value={selectedHouse}
          />
        </Col>

        <Col xl={3} lg={4} md={6} sm={12} pr-half>
          <Field
            name="roomId"
            label={`${capitalize(t('ROOM'))}*`}
            placeholder={t('FORM.SELECT_A_ROOM')}
            houseId={selectedHouse}
            component={SelectorRoomForm}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default compose(translate('inventories'))(HouseRoomSelection);
