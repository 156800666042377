import React from 'react';
import styled from '@emotion/styled';
import { translate } from '../translate/i18n';
import { func, array } from 'prop-types';

const StyledSwitcherView = styled('ul')(() => {
  return {
    listStyle: 'none',
    display: 'inline-flex',
    marginTop: 0
  };
});

const StyledSwitcherItem = styled('li')(({ theme }) => {
  return {
    border: theme.border,
    padding: theme.spacing.quarter
  };
});

const ViewSwitcher = ({ t, views }) => {
  return (
    <StyledSwitcherView>
      {views.map((view, id) => (
        <StyledSwitcherItem key={id}>{t(view)}</StyledSwitcherItem>
      ))}
    </StyledSwitcherView>
  );
};

ViewSwitcher.propTypes = {
  t: func,
  views: array
};

export default translate('applications')(ViewSwitcher);
