import * as api from '@cohabs/bo-api';

export const getPaymentProfileMethod = ({ hubId, userId }) =>
  api.get(`/payment-profile/${userId}/hub/${hubId}/all-methods`);

export const setPaymentProfileMethod = ({ hubId, userId, type, iban, token, stripePaymentMethodId }) =>
  api.post(`/payment-profile/${userId}/hub/${hubId}/method/${type}`, { iban, token, stripePaymentMethodId });

export const updatePaymentProfileMethod = ({ hubId, userId, type, iban, token, stripePaymentMethodId }) =>
  api.patch(`/payment-profile/${userId}/hub/${hubId}/method/${type}`, { iban, token, stripePaymentMethodId });

export const deletePaymentProfileMethod = ({ hubId, userId, type }) =>
  api.del(`/payment-profile/${userId}/hub/${hubId}/method/${type}`, {});

export const getSetupIntents = ({ hubId, userId, type = 'card' }) =>
  api.get(`/payment-profile/${userId}/hub/${hubId}/method/${type}/setupIntents`, {});
