import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { onSubmitActions } from 'redux-form-submit-saga';
import { translate } from '../../translate/i18n';
import { func, bool } from 'prop-types';
import { Button, TextInput, Text, Row } from '../../components';
import { capitalize, isEmail } from '../../utils';
import { Link } from 'react-router-dom';

const RenderSuccessMessage = translate('auth')(({ t }) => <Text align="right">{capitalize(t('LINK_SENT'))}</Text>);
RenderSuccessMessage.propTypes = {
  t: func
};

const ForgotPasswordForm = (props) => {
  const { handleSubmit, submitting, t, submitSucceeded } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        component={TextInput}
        type="text"
        placeholder="example@example.com"
        label={`${capitalize(t('EMAIL'))}*`}
      />
      <div style={{ textAlign: 'center' }}>
        <Row middle between>
          <Button bg-primary size="md" type="submit" disabled={submitting} name="forgotPassword">
            {capitalize(t('SEND_LINK'))}
          </Button>
          <Link to="/login">
            <Text lightGrey>Back</Text>
          </Link>
        </Row>
      </div>
      {!!submitSucceeded && <RenderSuccessMessage />}
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  handleSubmit: func.isRequired,
  submitting: bool,
  t: func,
  submitSucceeded: bool
};

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'REQUIRED';
  } else if (!isEmail(values.email)) {
    errors.email = 'INVALID_EMAIL_ADDRESS';
  }

  return errors;
};

export default compose(
  reduxForm({
    form: 'ForgotPasswordForm',
    onSubmit: onSubmitActions('FORGOT_PASSWORD_FORM'),
    validate
  }),
  translate(['auth', 'validation'])
)(ForgotPasswordForm);
