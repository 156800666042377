import { GET_PACK_LOCATION, GET_PACK_LOCATION_SUCCESS, GET_PACK_LOCATION_FAIL } from './packLocations.actionTypes';
import { upsertPackLocation } from './packLocations.actions';
import { genState, setFetching, setCurrent, setCurrentFailure, setFetchingCurrent } from '../../utils/reducer';

const packLocations = (state) => ({
  [GET_PACK_LOCATION]: setFetchingCurrent(state, true),
  [GET_PACK_LOCATION_SUCCESS]: setCurrent(state),
  [GET_PACK_LOCATION_FAIL]: setCurrentFailure(state),
  [upsertPackLocation.SUCCESS]: setCurrent(state),
  [upsertPackLocation.FAILURE]: setFetching(state, false)
});

export const packLocationsReducer = (state = {}, action) => {
  const { type, payload } = action;

  return genState({ state, payload, stateDef: packLocations, type });
};
