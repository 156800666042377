import React from 'react';
import { AsyncSelector } from '../';
import { InputContainer, StyledLabel, ErrorText } from '../form/TextInput.styles';
import { func, bool, any } from 'prop-types';
import { translate } from '../../translate/i18n';

const AsyncSelectorForm = (props) => {
  const { label, meta, loadOptions, t, input, clear, disabled } = props;
  const { error, touched } = meta;

  return (
    <InputContainer>
      <StyledLabel active={input.active}>{label}</StyledLabel>
      <AsyncSelector disabled={disabled} clear={clear} {...input} isClearable={false} loadOptions={loadOptions} />
      {touched && error && <ErrorText>{t(error)}</ErrorText>}
    </InputContainer>
  );
};

AsyncSelectorForm.defaultProps = {
  clear: false,
  meta: { error: '', touched: false }
};

AsyncSelectorForm.propTypes = {
  loadOptions: func.isRequired,
  clear: bool,
  t: func,
  initValue: func,
  value: any
};

export default translate('validation')(AsyncSelectorForm);
