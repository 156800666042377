import { pipe, ifElse, has, always, propOr, is, prop, differenceWith } from 'ramda';
import { emptyArray, emptyObject } from './index';

const execStateDef = (type, payload) => (func) => func[type](payload);

export const genState = ({ state, type, payload, stateDef }) =>
  pipe(stateDef, ifElse(has(type), execStateDef(type, payload), always(state)))(state);

export const setFetching = (state, isFetching) => () => ({ ...state, isFetching });

export const setList =
  (state, defaultCount = 0) =>
  (payload) => {
    if (is(Array, payload)) {
      return { ...state, list: payload, responseLength: payload.length, isFetching: false, count: defaultCount };
    }

    const list = propOr(emptyArray, 'rows', payload);
    const count = propOr(defaultCount, 'count', payload);
    const deposit = propOr(emptyObject, 'deposit', payload);

    return { ...state, list, count, deposit, responseLength: list.length, isFetching: false };
  };

export const setListFailure = (state) => () => ({ ...state, list: [], count: 0, responseLength: 0, isFetching: false });

export const appendList =
  (state, defaultCount = 0) =>
  (payload) => {
    if (is(Array, payload)) {
      return {
        ...state,
        list: [...state.list, ...payload],
        responseLength: payload.length,
        isFetching: false,
        count: defaultCount
      };
    }

    const count = propOr(defaultCount, 'count', payload);
    const rows = propOr(emptyArray, 'rows', payload);
    const list = [...state.list, ...rows];

    return {
      ...state,
      isFetching: false,
      responseLength: rows.length,
      count,
      list
    };
  };

export const appendListFailure = (state) => () => ({ ...state, responseLength: 0, isFetching: false });

export const setFetchingCurrent = (state, isFetching) => () => ({
  ...state,
  current: { ...state.current, isFetching }
});

export const setCurrent = (state) => (payload) => ({
  ...state,
  current: { ...state.current, data: payload, isFetching: false }
});

export const setCurrentFailure = (state) => () => ({
  ...state,
  current: { ...state.current, data: null, isFetching: false }
});

export const excludeList = (state) => (payload) => {
  const comparator = (a, b) => prop('id', a) === prop('id', b);

  const list = differenceWith(comparator, prop('list', state), payload);
  const count = list.length;

  return {
    ...state,
    list,
    count
  };
};
