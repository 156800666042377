import { string } from 'prop-types';
import styled from '@emotion/styled';
import { User } from 'react-feather';
import { Text } from '../components';
import { translate } from '../translate/i18n';
import React from 'react';

const defaultContainerProps = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  width: '90%',
  border: '1px solid',
  borderRadius: '30px',
  cursor: 'move',
  marginTop: '5px',
  padding: '2px 10px 2px 10px'
};

const ApprovedKycContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.green[200],
  fontFamily: theme.font.family
}));

const PartiallyApprovedKycContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.yellow[200],
  fontFamily: theme.font.family
}));

const AwaitingKycContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.lightGrey[50],
  fontFamily: theme.font.family
}));

const ReviewingKycContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.lightOrange[500],
  fontFamily: theme.font.family
}));

const RejectedKycContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.red[200],
  fontFamily: theme.font.family
}));

const DefaultKycContainer = styled('div')(({ theme }) => ({
  ...defaultContainerProps,
  borderColor: theme.colors.lightGrey[300],
  backgroundColor: theme.colors.white[500],
  fontFamily: theme.font.family
}));

const STATUSES_TO_HIDE = ['validated', 'nurture', 'booked', 'won', 'lost'];

const formatStatus = (kycStatus) => {
  const statusWithDefault = kycStatus || 'unknown';
  const formattedStatus = statusWithDefault.slice(0, 1).toUpperCase() + statusWithDefault.slice(1).replace(/_/g, ' ');

  return `KYC: ${formattedStatus}`;
};

const getKycContainer = (status) => {
  switch (status) {
    case 'awaiting':
      return AwaitingKycContainer;
    case 'reviewing':
      return ReviewingKycContainer;
    case 'rejected':
      return RejectedKycContainer;
    case 'approved':
      return ApprovedKycContainer;
    case 'partially_approved':
      return PartiallyApprovedKycContainer;
    default:
      return DefaultKycContainer;
  }
};

const KanbanKycItem = ({ status, kycStatus }) => {
  if (STATUSES_TO_HIDE.includes(status)) {
    return null;
  }

  const formattedStatus = formatStatus(kycStatus);
  const KycContainer = getKycContainer(kycStatus);

  return (
    <KycContainer>
      <User size={16} />
      <Text left pt-quarter pb-quarter size="sm" weight={200}>
        {formattedStatus}
      </Text>
    </KycContainer>
  );
};

KanbanKycItem.propTypes = {
  status: string,
  kycStatus: string
};

export default translate('applications')(KanbanKycItem);
