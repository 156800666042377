import React, { useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { Field, reduxForm, propTypes as reduxFormPropTypes, change } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import { upsertDamage, updateDamage } from './payments.actions';
import StyledForm from '../components/form.styles';
import { Block, Col, TextAreaForm, Row, Button, Upload, TextInput } from '../components';
import { StyledLabel } from '../components/form/TextInput.styles';
import FileUpload from '../components/form/FileUpload';
import { UserInfo } from './followup.actions';
import { required, positive } from '../utils';

const DamageForm = (props) => {
  // Props
  const { t, handleSubmit, submitting, onCancel, submitSucceeded, lease, damage, isUpdate, dispatch } = props;

  useEffect(() => {
    if (damage) {
      dispatch(change('DamageForm', 'amount', damage.amount));
      dispatch(change('DamageForm', 'description', damage.description));
      dispatch(change('DamageForm', 'id', damage.id));
    }
  }, []);

  if (submitSucceeded) {
    onCancel();
  }

  // Render
  return (
    <StyledForm onSubmit={handleSubmit(isUpdate ? updateDamage : upsertDamage)}>
      <Block p>
        <UserInfo t={t} member={lease.user} room={lease.room} />

        {isUpdate && <Field name="id" component={TextInput} type="hidden" />}

        <Field name="amount" validate={[required, positive]} component={TextInput} label={t('AMOUNT')} />

        <Field name="description" validate={required} component={TextAreaForm} label={t('DAMAGE')} />

        <Row>
          <Col lg={2}>
            <StyledLabel>{'Picture'}</StyledLabel>
            <Field name="picture" component={Upload} size={96} accept={['image/png', 'image/jpeg']} />
          </Col>

          <Col lg={2}>
            <Field
              name="bill"
              accept={['application/pdf', 'image/png', 'image/svg+xml', 'image/jpeg', 'image/jpg']}
              component={FileUpload}
              label={t('BILL_ATTACHMENT')}
            />
          </Col>
        </Row>

        <Row mb right>
          <Button clear size="md" name="cancel" disabled={submitting} onClick={onCancel}>
            {t('CANCEL')}
          </Button>
          <Button bg-primary size="md" name="submit" type="submit" disabled={submitting}>
            {t('SAVE')}
          </Button>
        </Row>
      </Block>
    </StyledForm>
  );
};

DamageForm.propTypes = {
  t: func,
  handleSubmit: func,
  onCancel: func,
  submitting: bool,
  payment: object,
  lease: object,
  ...reduxFormPropTypes
};

export default compose(translate('payments'), reduxForm({ form: 'DamageForm' }))(DamageForm);
