import React from 'react';
import Selector from './Selector';
import { translate } from '../../translate/i18n';
import { func, string, array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose, pathOr, isEmpty } from 'ramda';
import { emptyArray } from '../../utils';
import { getCountries } from '../../enum/enum.actions';
import { Loader } from '../';

const handleChange = (onChange) => {
  return (value) => {
    if (!onChange) return;
    if (isEmpty(value)) return onChange();
    return onChange(value);
  };
};

const SelectorNationality = (props) => {
  const { name, isFetching, list, onChange, label, value, isInForm, isDisabled, getCountries } = props;

  if (isEmpty(list)) {
    getCountries();
    return <Loader />;
  }

  if (isFetching) return <Loader />;

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={list}
      label={label}
      value={value}
      isMulit={false}
      onChange={handleChange(onChange)}
      isDisabled={isDisabled}
    />
  );
};
SelectorNationality.propTypes = {
  isInForm: bool,
  t: func,
  name: string.isRequired,
  list: array,
  getCountries: func,
  onChange: func,
  isMulti: bool,
  label: string,
  value: string,
  isDisabled: bool,
  isFetching: bool
};

Selector.defaultValues = {
  isInForm: false
};

export default compose(
  translate('form'),
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'geo', 'list'], state),
      isFetching: pathOr(true, ['enums', 'geo', 'isFetching'], state)
    }),
    { getCountries }
  )
)(SelectorNationality);
