import React from 'react';
import { Container, Text } from '../';
import { formatDate, formatDatetime } from '../../utils';
import { string, number, bool } from 'prop-types';
import ReactTooltip from 'react-tooltip';

const DateItem = ({ date, timezone, weight, showTime, useReactTooltip = true, ...styleProps }) => {
  const tooltipMessage = timezone;
  const tooltipId = `${date}-${Date.now()}`;

  return (
    <Container column mb-half>
      <Text weight={weight} {...styleProps}>
        <span title={(!useReactTooltip && tooltipMessage) || undefined} data-tip={tooltipMessage} data-for={tooltipId}>
          {showTime ? formatDatetime(date, timezone) : formatDate(date, timezone)}
        </span>
        {useReactTooltip && (
          <ReactTooltip id={tooltipId} effect="solid" place="bottom" wrapper={'span'} bodyMode={false}>
            {tooltipMessage}
          </ReactTooltip>
        )}
      </Text>
    </Container>
  );
};

DateItem.defaultProps = {
  weight: 300,
  showTime: false,
  timezone: 'local'
};

DateItem.propTypes = {
  date: string.isRequired,
  timezone: string,
  weight: number,
  showTime: bool,
  useReactTooltip: bool
};

export default DateItem;
