import React from 'react';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';
import SelectorHouse from '../selector/Selector.house';

const SelectHouseForm = ({
  t,
  hubId,
  isClearable,
  input,
  label,
  isDisabled,
  placeholder,
  meta: { touched, error }
}) => (
  <InputContainer>
    <SelectorHouse
      label={label}
      placeholder={placeholder}
      hubId={hubId}
      {...input}
      isInForm={true}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectHouseForm);
