import React, { useState } from 'react';
import { compose, pathOr } from 'ramda';
import { connect } from 'react-redux';
import { PlusCircle } from 'react-feather';

import streamChat from '../stream';
import { Button, Col, Row, Text } from '../../components';
import { translate } from '../../translate/i18n';
import { setChatFilter, toggleCreateChatChannel } from '../chat.actions';
import { Throttle } from '../../utils';
import { object, func } from 'prop-types';
import { handleErrorMessage } from '../../toast/toast.actions';
import { types } from '../../utils/sagas';

/**
 * This file is a copy of Stream Component ChannelSearch adapted to our requirement.
 * Keep custom modifications to the minimum for future compatibility!
 * See more here: https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelSearch/ChannelSearch.tsx
 */

const Search = ({ t, chat, toggleCreateChatChannel, setChatFilter, handleErrorMessage }) => {
  const [query, setQuery] = useState(chat.search.query);
  const [searching, setSearching] = useState(false);

  const getChannels = Throttle(async (text, chatSearch, searching, setSearching) => {
    if (searching) {
      return;
    }

    if (!text) {
      setChatFilter({
        query: '',
        filter: {}
      });

      return;
    }

    setSearching(true);

    try {
      const { users } = await streamChat.client.queryUsers(
        {
          $or: [{ id: { $autocomplete: text } }, { name: { $autocomplete: text } }],
          id: { $ne: streamChat.client.userID }
        },
        {
          id: 1
        },
        {
          limit: 100
        }
      );

      const res = [{ name: { $autocomplete: text } }];

      if (users.length) {
        res.push({ members: { $in: users.map((item) => item.id) } });
      }

      setChatFilter({
        ...chatSearch,
        query: text,
        filter: {
          $or: res
        }
      });
    } catch (error) {
      handleErrorMessage('GET.FAILURE', { type: types.CHAT.SEARCH, error });

      setChatFilter({
        ...chatSearch,
        query: '',
        filter: {}
      });
    }

    setSearching(false);
  }, 300);

  const onSearch = (event) => {
    event.preventDefault();
    setQuery(event.target.value);
    getChannels(event.target.value, chat.search, searching, setSearching);
  };

  return (
    <Row>
      <Col lg={12}>
        <Row middle center>
          <div className="new-conversation">
            <Button name="add" onClick={() => toggleCreateChatChannel(!chat.showCreateChannelModal)} clear size="md">
              <Row middle center>
                <Text weight={400} size="md" cursor="pointer">
                  {t('NEW_CONVERSATION')}
                </Text>
                <PlusCircle style={{ paddingLeft: '10px' }} size={32} />
              </Row>
            </Button>
          </div>
        </Row>
      </Col>

      <Col lg={12}>
        <div className="str-chat__channel-search">
          <Row>
            <Col lg={12} pb-half>
              <input name="query" type="text" onChange={onSearch} value={query} placeholder={t('default:SEARCH')} />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

Search.propTypes = {
  t: func.isRequired,
  handleErrorMessage: func.isRequired,
  toggleCreateChatChannel: func.isRequired,
  setChatFilter: func.isRequired,
  chat: object.isRequired
};

export default compose(
  translate('chat'),
  connect(
    (state) => ({
      chat: pathOr(null, ['chat'], state)
    }),
    {
      setChatFilter,
      toggleCreateChatChannel,
      handleErrorMessage
    }
  )
)(Search);
