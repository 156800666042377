import React, { useState, Fragment } from 'react';

import ScrollableDataTable from './';
import ColSelector from './ColSelector';
import { array } from 'prop-types';

const WithColSelector = ({ columnDefs, ...props }) => {
  const [selectedColumnDefs, setSelectedColumnDefs] = useState([]);

  return (
    <Fragment>
      <ColSelector columnDefs={columnDefs} setSelectedColumnDefs={setSelectedColumnDefs} />
      <ScrollableDataTable {...props} columnDefs={selectedColumnDefs} />
    </Fragment>
  );
};

WithColSelector.propTypes = {
  columnDefs: array
};

export default WithColSelector;
