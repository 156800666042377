import React, { useEffect, useState } from 'react';
import { func, string, array, bool, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr, compose, map, isEmpty } from 'ramda';

import Selector from './Selector';
import { emptyArray, makeOptions, upperCase } from '../../utils';
import { translate } from '../../translate/i18n';
import { getRoomCategories } from '../../enum/enum.actions';
import { storableSelector } from '../storableDecorator';

const SelectorFaqLocation = ({
  t,
  list,
  name,
  getRoomCategories,
  isMulti,
  label,
  value,
  isInForm,
  isDisabled,
  placeholder,
  onChange,
  valueControl
}) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    if (isEmpty(list)) {
      getRoomCategories();
    }
  }, []);

  useEffect(() => {
    const enums = map((option) => ({ value: option, name: t(`ROOM_CATEGORIES.${upperCase(option)}`) }), list);
    setOptions(makeOptions(enums, 'value', 'name', 'label'));
  }, [list]);

  useEffect(() => {
    if (options && !isEmpty(options)) {
      valueControl(options, onChange);
    }
  }, [options]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

SelectorFaqLocation.propTypes = {
  isInForm: bool,
  isClearable: bool,
  isMulti: bool,
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  label: string.isRequired,
  value: oneOfType([string, array]),

  onChange: func.isRequired,
  valueControl: func.isRequired,

  list: array.isRequired,
  isFetching: bool.isRequired,
  getRoomCategories: func.isRequired,
  t: func
};

SelectorFaqLocation.defaultProps = {
  isInForm: false,
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  placeholder: '',
  value: null
};

export default compose(
  storableSelector,
  translate('cms'),
  connect(
    (state) => ({
      list: pathOr(emptyArray, ['enums', 'roomCategories', 'list'], state),
      isFetching: pathOr(false, ['enums', 'roomCategories', 'isFetching'], state)
    }),
    { getRoomCategories }
  )
)(SelectorFaqLocation);
