import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as service from './hub.service';
import { GET_HUBS, GET_HUB } from './hub.actionTypes';
import { setHubs, setHub } from './hub.actions';

export function* getHubs() {
  try {
    const hubs = yield call(service.getHubs, {});
    yield put(setHubs(hubs));
  } catch (err) {
    yield err;
  }
}

export function* getHub({ payload } = {}) {
  try {
    const hub = yield call(service.getHub, payload);
    yield put(setHub(hub));
  } catch (err) {
    yield err;
  }
}

export default function* hubSaga() {
  yield all([takeLatest(GET_HUBS, getHubs), takeLatest(GET_HUB, getHub)]);
}
