import React, { useState, Fragment } from 'react';
import { object, string, func, oneOfType, bool, number } from 'prop-types';
import { User, UserCheck, UserX, Facebook, Instagram, Twitter, Tag, Award, Unlock, Lock } from 'react-feather';
import { Text, Container, Button, Modal, Row, Col, sizing, loading } from '../components';
import { isEmpty, compose, pathOr, propOr, prop } from 'ramda';
import { withTheme } from '@emotion/react';
import { connect } from 'react-redux';

import { translate } from '../translate/i18n';
import { formatDate, upperCase, emptyObject, formatPhone, capitalize, isNotEmptyOrNil } from '../utils';
import { AvatarContainer, Avatar, EditProfileContainer, StyledStatus } from './Member.profile.style';
import MemberForm from './Member.profile.form';
import PaymentProfile from '../paymentProfile';
import { updateMember, getStayInfo } from './member.actions';
import { spacing } from '../theme/spacing';

const ProfileItem = translate('member.profile')(({ label, value, t }) => {
  if (!value) return null;

  return (
    <Container column pb-half>
      <Text lightGrey={500} size="xs" weight={200} pb-quarter labelSpacing>
        {upperCase(t(label))}
      </Text>
      <Text weight={300}>{value}</Text>
    </Container>
  );
});

ProfileItem.propTypes = {
  label: string,
  value: oneOfType([string, object]),
  t: func
};

const initFormValues = (values) => ({
  ...values,
  nationalityId: pathOr('', ['nationality', 'code'], values),
  phone: {
    number: prop('phoneNumber', values) || '',
    prefix: {
      value: pathOr('', ['phonePrefix', 'code'], values),
      flag: pathOr('', ['phonePrefix', 'flag'], values),
      prefix: pathOr('', ['phonePrefix', 'prefix'], values)
    }
  }
});

const renderHouseManager = (member, t) =>
  member.houseManager ? (
    <Row pt-half pb-half>
      <Award size={16} />
      <Text size="md" pl-half>
        {t('stayInfo.HOUSE_MANAGER')}
      </Text>
    </Row>
  ) : null;

const renderServiceVouchers = (member, t) =>
  member.serviceVouchers ? (
    <Row pt-half pb-half>
      <Tag size={16} />
      <Text size="md" pl-half>
        {t('stayInfo.SERVICE_VOUCHERS')}
      </Text>
    </Row>
  ) : null;

const renderActive = (stayInfo, t, theme) =>
  isEmpty(stayInfo) ? (
    <Row pt-half pb-half>
      <UserX color={theme.colors.grey[300]} size={18} />
      <Text pl-half grey={300}>
        {t('INACTIVE_MEMBER')}
      </Text>
    </Row>
  ) : (
    <Row pt-half pb-half>
      <UserCheck color={theme.colors.grey[500]} size={18} />
      <Text pl-half grey={500}>
        {t('ACTIVE_MEMBER')}
      </Text>
    </Row>
  );

const renderEmergencyContactDetails = (member, t) => {
  const contact = member.emergency_contact;
  if (!contact) {
    return <Text>{t('NO_EMERGENCY_CONTACT')}</Text>;
  }
  return (
    <>
      <ProfileItem label={t('NAME')} value={`${propOr('', 'firstName', contact)} ${propOr('', 'lastName', contact)}`} />
      <ProfileItem
        label={t('PHONE')}
        value={formatPhone(propOr('', 'phoneNumber', contact), pathOr('', ['phonePrefix', 'prefix'], contact))}
      />
      <ProfileItem label={t('EMAIL')} value={propOr('', 'email', contact)} />
    </>
  );
};

const MemberProfile = (props) => {
  const [showModal, setModal] = useState(false);
  const [showPaymentProfileModal, setPaymentProfileModal] = useState(false);
  const { member, t, theme, stayInfo, windowWidth } = props;

  const handleClose = () => setModal(false);
  const handlePaymentProfileClose = () => setPaymentProfileModal(false);
  const colView = windowWidth > spacing.breakpoints.lg;

  return (
    <Fragment>
      <Container column bgColor scrollable main={colView} p>
        <Row right mb>
          <EditProfileContainer>
            <Button bg-secondary size="md" name="editProfile" mr-half onClick={() => setModal(true)}>
              {t('default:EDIT')}
            </Button>
            <Button bg-secondary size="md" name="editProfile" onClick={() => setPaymentProfileModal(true)}>
              {t('PAYMENT_PROFILE')}
            </Button>
          </EditProfileContainer>
        </Row>

        <Container column center relative>
          <AvatarContainer>
            <Avatar>
              {!member.picture && <User size={50} color="white" />}
              {member.picture && (
                <img
                  style={{ minWidth: '100%', minHeight: '100%', objectFit: 'cover' }}
                  alt="profile"
                  src={member.picture}
                />
              )}
            </Avatar>

            <StyledStatus banned={member.banned}>
              {member.banned ? <Lock size={16} /> : <Unlock size={16} />}
            </StyledStatus>
          </AvatarContainer>

          <Container center pt-half pb column>
            <Text size="lg" weight={300} black>{`${member.firstName} ${member.lastName} `}</Text>
          </Container>
        </Container>

        <Container mb column>
          {renderActive(stayInfo, t, theme)}
          {renderHouseManager(member, t)}
          {renderServiceVouchers(member, t)}
        </Container>

        <ProfileItem
          label={t('EMAIL')}
          value={propOr('', 'email', member)}
          show={propOr(true, 'emailVisibility', member)}
        />
        <ProfileItem
          label={t('NICKNAME')}
          value={propOr('', 'nickName', member)}
          show={propOr(true, 'nickName', member)}
        />
        <ProfileItem
          label={t('phone')}
          value={formatPhone(propOr('', 'phoneNumber', member), pathOr('', ['phonePrefix', 'prefix'], member))}
          show={propOr(true, 'phoneNumberVisibility', member)}
        />
        <ProfileItem
          label={t('bankAccount')}
          value={propOr('', 'bankAccount', member)}
          show={propOr(true, 'emailVisibility', member)}
        />
        <ProfileItem label={t('job')} value={propOr('', 'job', member)} />
        <ProfileItem label={t('nationality')} value={capitalize(pathOr('', ['nationality', 'nationality'], member))} />
        <ProfileItem
          label={t('birthdate')}
          value={formatDate(propOr('', 'birthdate', member))}
          show={propOr(true, 'birthdateVisibility', member)}
        />

        <Row mt>
          {member.facebook && (
            <Col lg={4} center>
              <a href={member.facebook} target="_blank" rel="noopener noreferrer">
                <Facebook color={theme.colors.grey[300]} />
              </a>
            </Col>
          )}
          {member.instagram && (
            <Col lg={4} center>
              <a href={member.instagram} target="_blank" rel="noopener noreferrer">
                <Instagram color={theme.colors.grey[300]} />
              </a>
            </Col>
          )}
          {member.twitter && (
            <Col lg={4} center>
              <a href={member.twitter} target="_blank" rel="noopener noreferrer">
                <Twitter color={theme.colors.grey[300]} />
              </a>
            </Col>
          )}
        </Row>

        <Container pt-half pb-half column>
          <Text size="lg" black>
            {t('EMERGENCY_CONTACT')}
          </Text>
        </Container>
        {renderEmergencyContactDetails(member, t)}
      </Container>
      <Modal
        hasActions
        title={t('EDIT_MEMBER', { name: member.firstName })}
        show={showModal}
        onClose={handleClose}
        onConfirm={handleClose}
        content={() => (
          <MemberForm
            initialValues={initFormValues(member)}
            onClose={handleClose}
            showToggleManager={isNotEmptyOrNil(stayInfo) || prop('houseManager', member)}
          />
        )}
      />
      <Modal
        hasActions
        title={t('PAYMENT_PROFILE', { name: member.firstName })}
        show={showPaymentProfileModal}
        onClose={handlePaymentProfileClose}
        onConfirm={handlePaymentProfileClose}
        content={() => <PaymentProfile member={member} />}
      />
    </Fragment>
  );
};

MemberProfile.propTypes = {
  member: object.isRequired,
  stayInfo: object.isRequired,
  t: func,
  updateMember: func,
  theme: object,
  labelSpacing: bool,
  windowWidth: number,
  bgColor: bool
};

export default compose(
  sizing(),
  connect(
    (state) => ({
      stayInfo: pathOr(emptyObject, ['member', 'current', 'stayInfo', 'data'], state),
      isUpdating: pathOr(false, ['member', 'current', 'isUpdating'], state)
    }),
    { updateMember, getStayInfo }
  ),
  loading(({ member }) => isEmpty(member)),
  translate('member'),
  withTheme
)(MemberProfile);
