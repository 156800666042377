import React from 'react';
import Toggle from '../Toggle';
import { change } from 'redux-form';

const ToggleForm = (props) => {
  const {
    input: { name },
    meta: { form, dispatch }
  } = props;
  return <Toggle {...props} onCheck={(value) => dispatch(change(form, name, value))} />;
};

export default ToggleForm;
