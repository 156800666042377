import React from 'react';
import styled from '@emotion/styled';
import { compose } from 'ramda';
import { Link, withRouter } from 'react-router-dom';
import { Row, Text } from './index';

export const TabsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.colors.lightGrey[400]}`,
  borderWidth: '2px',
  marginTop: theme.spacing.normal,
  marginBottom: theme.spacing.normal,
  paddingBottom: theme.spacing.half
}));

export const TabContainer = styled('div')(({ theme, active }) => ({
  position: 'relative',
  marginRight: theme.spacing.pacman,
  cursor: 'pointer',
  '&:after': {
    content: "''",
    position: 'absolute',
    height: '2px',
    width: '100%',
    bottom: '-12px',
    opacity: active ? 1 : 0,
    background: theme.colors.black[500]
  }
}));

export const Tab = compose(withRouter)(({ label, to, location, disabled }) => (
  <TabContainer active={location.pathname === to}>
    {disabled ? (
      <Row middle>
        <Text lightGrey pr-quarter>
          {label}
        </Text>
      </Row>
    ) : (
      <Link to={to}>
        <Row middle>
          <Text lightGrey pr-quarter>
            {label}
          </Text>
        </Row>
      </Link>
    )}
  </TabContainer>
));

export const TabButton = compose(withRouter)(({ label, active, disabled, onSelect }) => (
  <TabContainer active={active} onClick={() => !disabled && onSelect && onSelect()}>
    <Row middle>
      <Text lightGrey pr-quarter>
        {label}
      </Text>
    </Row>
  </TabContainer>
));
