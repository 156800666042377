import React, { useState } from 'react';
import Selector from './Selector';
import { translate } from '../../translate/i18n';
import { func, string, array, oneOfType, bool } from 'prop-types';
import { compose } from 'ramda';

const SelectorChatType = ({
  name,
  onChange,
  isMulti,
  label,
  value,
  isInForm,
  isDisabled,
  isClearable,
  placeholder,
  t
}) => {
  const [options] = useState(() => [
    // this is a placeholder. in case if we will have some of this channel types in BO
    // {
    //   value: 'bot',
    //   label: t('BOT')
    // },
    // {
    //   value: 'house',
    //   label: t('HOUSE')
    // },
    {
      value: 'messaging',
      label: t('MESSAGING')
    },
    {
      value: 'communityManager',
      label: t('COMMUNITY_MANAGER')
    }
  ]);

  return (
    <Selector
      isInForm={isInForm}
      name={name}
      options={options}
      label={label}
      value={value}
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

SelectorChatType.propTypes = {
  t: func.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  label: string,
  isClearable: bool,
  isDisabled: bool,
  isInForm: bool,
  isMulti: bool,
  value: oneOfType([string, array]),
  placeholder: string
};

SelectorChatType.defaultProps = {
  label: '',
  placeholder: '',
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  isInForm: false
};

export default compose(translate(['chat']))(SelectorChatType);
