import React from 'react';
import { pick, compose, merge } from 'ramda';
import { shape, string, oneOfType, bool, number, func } from 'prop-types';

export const availableInputAttributs = [
  'type',
  'name',
  'value',
  'onBlur',
  'checked',
  'disabled',
  'onChange',
  'onBlur',
  'onKeyUp',
  'onFocus',
  'readOnly',
  'autofocus',
  'autocomplete',
  'defaultValue',
  'placeholder',
  'onEnter',
  'autoFocus'
];

export const inputPropTypes = shape({
  type: string,
  name: string.isRequired,
  value: oneOfType([string, bool, number]),
  checked: bool,
  disabled: bool,
  onChange: func,
  onFocus: func,
  onBlur: func,
  readOnly: bool,
  autofocus: bool,
  autocomplete: bool,
  defaulValue: oneOfType([string, bool, number]),
  placeholder: string,
  onEnter: func
});

export const getInputProps = (props) =>
  compose(merge({ ...props.input, 'data-cy': `input-${props.input.name}` }), pick(availableInputAttributs))(props);

export const formatInputProps = () => (InputComponent) => (props) => (
  <InputComponent inputProps={getInputProps(props)} {...props} />
);
