import React, { Fragment, useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose, path, pathOr, map, propOr, prop } from 'ramda';
import { withRouter } from 'react-router-dom';
import { translate } from '../translate/i18n';
import { Container, Block, Col, Back, Row, Text, Loader } from '../components';
import { emptyObject, emptyArray, isNotEmptyOrNil } from '../utils';
import { getPack } from './packs.actions';
import PackForm from './Pack.form';
import PackLocations from './packLocations';

/**
 * Populate initial form values
 * @param {*} pack
 */
const initialValuesFrom = (pack) => {
  return {
    ...pack,
    active: !!prop('active', pack),
    featured: !!prop('featured', pack),
    location: !pack.location ? map(prop('id'), propOr(emptyArray, 'cities', pack)) : prop('location', pack),
    content: !pack.content ? map(prop('point'), propOr(emptyArray, 'pack_contents', pack)) : prop('content', pack)
  };
};

/**
 * Pack page details or new
 * @param {*} props
 */
const Pack = (props) => {
  // Props
  const { history, match, t, pack, getPack, isFetching } = props;

  // variables
  const id = path(['params', 'id'], match);
  const isNew = id === 'create';
  const initialValues = isNew ? {} : initialValuesFrom(pack);
  const showForm = isNew || isNotEmptyOrNil(pack);
  const locations = pathOr([], ['packLocations'], pack);

  // Hooks
  useEffect(() => {
    if (!isNew) {
      getPack(id);
    }
  }, []);

  // Render
  return (
    <Fragment>
      {isFetching ? (
        <Container full center middle>
          <Loader />
        </Container>
      ) : (
        <Block pt pl pr>
          <Row mb-double>
            <Col lg={6} left>
              <Back to="/packs" label={t('BACK_TO_PACK_LIST')} />
            </Col>
          </Row>

          <Row mb-double>
            <Text size="xl" weight={200}>
              {isNew ? t('NEW_PACK') : t('PACK_DETAILS')}
            </Text>
          </Row>

          {showForm && <PackForm isNew={isNew} onCancel={() => history.goBack()} initialValues={initialValues} />}

          {!isNew && (
            <Row mb-double>
              <PackLocations pack={pack} packLocations={locations} />
            </Row>
          )}
        </Block>
      )}
    </Fragment>
  );
};

Pack.propTypes = {
  t: func,
  match: object,
  pack: object,
  isFetching: bool,
  getPack: func,
  history: object
};

export default compose(
  withRouter,
  translate('packs'),
  connect(
    (state) => ({
      isFetching: pathOr(false, ['packs', 'current', 'isFetching'], state),
      pack: pathOr(emptyObject, ['packs', 'current', 'data'], state),
      user: pathOr(null, ['user', 'data'], state)
    }),
    { getPack }
  )
)(Pack);
