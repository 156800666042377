import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as memberService from './member.service';
import * as leaseService from '../leases/leases.service';
import * as incidentService from '../incidents/incidents.service';
import * as userService from '../user/user.service';
import { propOr, prop, compose, pathOr, omit } from 'ramda';

import {
  APPEND_MEMBERS,
  appendMembersSuccess,
  appendMembersFail,
  GET_MEMBERS,
  getMembersSuccess,
  getMembersFail,
  GET_MEMBER,
  getMemberSucess,
  getMemberFail,
  GET_USER_INCIDENTS,
  getIncidentsSucess,
  getIncidentsFail,
  GET_USER_LEASES,
  getLeaseSucess,
  getLeaseFail,
  GET_STAY_INFO,
  getStayInfoSuccess,
  getStayInfoFail,
  updateMember,
  UPDATE_MEMBER_INFO,
  UPDATE_MEMBER_ACCESS,
  updateMemberAccessSuccess,
  updateMemberAccessFailure
} from './member.actions';

import { SubmissionError } from 'redux-form';
import { types, defActionType } from '../utils/sagas';
import { handleErrorMessage, handleSuccessMessage } from '../toast/toast.actions';
import { localDateToHubInUtcFormat } from '../utils/date';

export function* getMembers(action) {
  const payload = propOr({}, 'payload', action);
  try {
    const members = yield call(memberService.getMembers, {
      criteria: { ...payload },
      order: payload.order,
      page: payload.page,
      limit: payload.limit
    });
    yield put(getMembersSuccess(members));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.MEMBER, error: err, data: payload }));
    yield put(getMembersFail(err));
  }
}

export function* appendMembers(action) {
  try {
    const payload = propOr({}, 'payload', action);
    const members = yield call(memberService.getMembers, {
      criteria: { ...payload },
      order: payload.order,
      page: payload.page,
      limit: payload.limit
    });
    yield put(appendMembersSuccess({ ...members, limit: payload.limit }));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.MEMBER, error: err, data: action.payload }));
    yield put(appendMembersFail(err));
  }
}

export function* getMember(action) {
  try {
    const member = yield call(memberService.getMember, prop('payload', action));
    yield put(getMemberSucess(member));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.MEMBER, error: err, data: action.payload }));
    yield put(getMemberFail(err));
  }
}

export function* getMemberLeases(action) {
  try {
    const leases = yield call(leaseService.getUserLeases, { criteria: { userId: prop('payload', action) } });
    yield put(getLeaseSucess(leases));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.LEASE, error: err, data: action.payload }));
    yield put(getLeaseFail(err));
  }
}

export function* getMemberIncidents(action) {
  try {
    const incidents = yield call(incidentService.getUserIncidents, { criteria: { userId: prop('payload', action) } });
    yield put(getIncidentsSucess(incidents));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.INCIDENT, error: err, data: action.payload }));
    yield put(getIncidentsFail(err));
  }
}

export function* getMemberStayInfo(action) {
  try {
    const stayInfo = yield call(userService.getStayInfo, prop('payload', action));
    yield put(getStayInfoSuccess(stayInfo));
  } catch (err) {
    yield put(handleErrorMessage('GET.FAILURE', { type: types.MEMBER, error: err, data: action.payload }));
    yield put(getStayInfoFail(err));
  }
}

export const handleNewMember = compose(
  omit(['phone']),
  (member) => ({
    ...member,
    phoneNumber: pathOr('', ['phone', 'number'], member),
    prefixId: pathOr('', ['phone', 'prefix', 'value'], member)
  }),
  prop('payload')
);

export function* _updateMember(action) {
  const handledMember = handleNewMember(action);
  const key = defActionType(handledMember);

  try {
    const member = yield call(memberService.updateMember, {
      ...handledMember,
      birthdate: localDateToHubInUtcFormat(handledMember.birthdate, 'UTC')
    });
    yield put(handleSuccessMessage(`${key}.SUCCESS`, { type: types.MEMBER }));
    yield put(updateMember.success(member));
  } catch (err) {
    yield put(handleErrorMessage(`${key}.FAILURE`, { type: types.MEMBER, error: err, data: handledMember }));

    if (prop('data', err)) {
      const error = Object.keys(err.data).reduce((acc, key) => {
        if (key === 'phoneNumber' || key === 'prefixId') return { ...acc, phone: err.data[key] };
        return { ...acc, [key]: err.data[key] };
      }, {});
      const formError = new SubmissionError(error);
      yield put(updateMember.failure(formError));
    }
  }
}

export function* _updateMemberAccess(action) {
  try {
    const member = prop('payload', action);
    yield call(memberService.patchMember, member);
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.MEMBER }));
    yield put(updateMemberAccessSuccess(member));
  } catch (err) {
    yield put(handleErrorMessage('UPDATE.FAILURE', { type: types.MEMBER, data: action.payload }));
    yield put(updateMemberAccessFailure(err));
  }
}
export function* _updateMemberInfo(action) {
  try {
    const member = prop('payload', action);
    yield call(memberService.patchMember, member);
    yield put(handleSuccessMessage('UPDATE.SUCCESS', { type: types.MEMBER }));
    yield put(updateMember.success(member));
  } catch (err) {
    yield put(handleErrorMessage('UPDATE.FAILURE', { type: types.MEMBER, data: action.payload }));
    yield put(updateMemberAccessFailure(err));
  }
}

export default function* membersSaga() {
  yield all([
    takeLatest(GET_MEMBERS, getMembers),
    takeLatest(APPEND_MEMBERS, appendMembers),
    takeLatest(GET_MEMBER, getMember),
    takeLatest(GET_USER_LEASES, getMemberLeases),
    takeLatest(GET_USER_INCIDENTS, getMemberIncidents),
    takeLatest(GET_STAY_INFO, getMemberStayInfo),
    takeLatest(updateMember.REQUEST, _updateMember),
    takeLatest(UPDATE_MEMBER_INFO, _updateMemberInfo),
    takeLatest(UPDATE_MEMBER_ACCESS, _updateMemberAccess)
  ]);
}
