import { prop, differenceWith } from 'ramda';
import {
  GET_CONTENT,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_FAILURE,
  GET_CONTENTS,
  GET_CONTENTS_SUCCESS,
  GET_CONTENTS_FAILURE,
  APPEND_CONTENTS,
  APPEND_CONTENTS_FAILURE,
  APPEND_CONTENTS_SUCCESS,
  DELETE_CONTENTS_SUCCESS
} from './cms.actionTypes';
import { upsertContent } from './cms.actions';
import {
  genState,
  setFetching,
  setList,
  setListFailure,
  setCurrent,
  setCurrentFailure,
  appendList,
  appendListFailure
} from '../utils/reducer';

const comparator = (a, b) => prop('id', a) === prop('id', b);

const cms = (state) => ({
  [GET_CONTENTS]: setFetching(state, true),
  [GET_CONTENTS_SUCCESS]: setList(state),
  [GET_CONTENTS_FAILURE]: setListFailure(state),
  [GET_CONTENT]: setFetching(state, true),
  [GET_CONTENT_SUCCESS]: setCurrent(state),
  [GET_CONTENT_FAILURE]: setCurrentFailure(state),
  [APPEND_CONTENTS]: setFetching(state, true),
  [APPEND_CONTENTS_SUCCESS]: appendList(state),
  [APPEND_CONTENTS_FAILURE]: appendListFailure(state),
  [DELETE_CONTENTS_SUCCESS]: (payload) => {
    const list = differenceWith(comparator, prop('list', state), prop('list', payload));
    const count = list.length;

    return {
      ...state,
      list,
      count
    };
  },
  [upsertContent.SUCCESS]: setCurrent(state)
});

export const cmsReducer = (state = {}, action) => {
  const { type, payload } = action;

  return genState({ state, payload, stateDef: cms, type });
};
