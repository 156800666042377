import React from 'react';
import { func } from 'prop-types';
import { formValues, reduxForm, Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { compose } from 'ramda';
import { translate } from '../translate/i18n';
import {
  Block,
  TextInput,
  TextAreaForm,
  Row,
  Button,
  ActionsContainer,
  ActionSecondaryButton,
  Text,
  AddressAutocompleteForm,
  RecurrenceInput
} from '../components';
import { upsertTrash } from './houses.actions';
import StyledForm from '../components/form.styles';
import ColorInput from '../components/form/ColorInput';
import { hexColor, required } from '../utils';

const TrashForm = (props) => {
  // Props
  const { t, submitting, handleSubmit, submitSucceeded, onClose, change, recurrences, timezone } = props;

  if (submitSucceeded) {
    onClose();
  }

  // Functions
  const clear =
    (names = []) =>
    () => {
      names.forEach((name) => change(name, null));
    };

  // Render
  return (
    <StyledForm onSubmit={handleSubmit(upsertTrash)}>
      <Block pl pr pb>
        <Text size="md" mb-half weight={400}>
          {t('INFORMATION')}
        </Text>

        <Field component={TextInput} validate={required} label={t('TYPE_OF_TRASH')} name="title" />

        <Field component={TextAreaForm} validate={required} label={t('DESCRIPTION')} name="description" />

        <Field
          placeholder="#ffffff"
          component={ColorInput}
          label={t('COLOR_CODE')}
          name="hexa"
          validate={[hexColor, required]}
        />

        <Field component={TextInput} name="color" validate={required} label={t('COLOR_NAME')} />

        <Row mb-half between>
          <Text size="md" weight={400}>
            {t('COLLECT_ON')}
          </Text>
        </Row>

        <RecurrenceInput change={change} recurrences={recurrences} timezone={timezone} />

        <Row mb-half between>
          <Text size="md" weight={400}>
            {t('COLLECT_POINT')}
          </Text>
          <ActionSecondaryButton name="clear-deposit" icon="Trash2" onClick={clear(['gpsCoordinates'])} />
        </Row>

        <Field
          name="gpsCoordinates"
          component={AddressAutocompleteForm}
          label={`${t('ADDRESS')}*`}
          types={['address']}
          gpsCoordinatesOnly
        />

        <ActionsContainer>
          <Row middle right mb mt pr>
            <Button clear mr-half size="md" onClick={onClose} name="cancel" disabled={submitting}>
              {t('default:CANCEL')}
            </Button>

            <Button bg-primary size="md" name="save" type="submit" disabled={submitting}>
              {t('default:SAVE')}
            </Button>
          </Row>
        </ActionsContainer>
      </Block>
    </StyledForm>
  );
};

TrashForm.propTypes = {
  t: func,
  ...reduxFormPropTypes
};

export default compose(
  translate('house'),
  reduxForm({ form: 'TrashForm', enableReinitialize: true, destroyOnUnmount: true }),
  formValues({ recurrences: 'recurrences', timezone: 'timezone' })
)(TrashForm);
