import { array, bool, func, object, string } from 'prop-types';
import { pathOr, propOr } from 'ramda';
import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Col, Text } from '../components';
import { translate } from '../translate/i18n';
import { setCurrentDraggable } from './applications.actions';
import KanbanListItem from './KanbanListItem';

const KanbanContainer = styled('div')(({ theme, bgColor }) => ({
  minHeight: '75vh',
  marginRight: theme.spacing.half,
  marginTop: theme.spacing.quarter,
  backgroundColor: bgColor ? theme.colors.lightGrey[400] : 'none',
  borderRadius: bgColor ? '2px' : '0',
  border: bgColor ? theme.colors.lightGrey[400] : 'none',
  padding: theme.spacing.quarter,
  flex: 1
}));

const HeaderKanban = styled('div')(({ theme }) => ({
  minHeight: '30px',
  backgroundColor: theme.colors.lightGrey[400],
  borderRadius: '2px',
  border: theme.colors.white[500],
  padding: theme.spacing.quarter,
  marginRight: '10px'
}));

const handleRedirectApplication =
  (history = {}) =>
  (application = {}) => {
    if (application.id) history.push(`/applications/${application.id}`);
  };

const KanbanList = (props) => {
  const { t, title, label, data, currentDraggable, setCurrentDraggable, onItemDrop } = props;

  const history = propOr({}, 'history', props);
  const redirectApp = handleRedirectApplication(history);

  return (
    <Col lg={2}>
      <HeaderKanban column pt-half pb-half>
        <Text pl-half size="md" pr-half weight={200} brown={200}>
          {t(title)}
        </Text>
      </HeaderKanban>

      <KanbanContainer
        bgColor
        onDragOver={(e) => e.preventDefault()}
        onDrop={() => onItemDrop(currentDraggable, label)}>
        {data.map((item, id) => (
          <KanbanListItem
            onDoubleClick={() => redirectApp(item)}
            item={item}
            key={id}
            onDragStart={() => setCurrentDraggable(item)}
          />
        ))}
      </KanbanContainer>
    </Col>
  );
};

KanbanList.propTypes = {
  t: func,
  title: string,
  label: string,
  data: array,
  onItemDrop: func.isRequired,
  currentDraggable: object,
  setCurrentDraggable: func,
  bgColor: bool
};

KanbanList.defaultProps = {
  data: []
};

export default compose(
  connect(
    (state) => ({
      currentDraggable: pathOr({}, ['applications', 'currentDraggable'], state)
    }),
    { setCurrentDraggable }
  ),
  translate('applications'),
  withRouter
)(KanbanList);
