import React, { useState, Fragment } from 'react';
import { func, object, number, bool, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pathOr, prop, path } from 'ramda';
import { translate } from '../translate/i18n';
import { getContents, appendContents, deleteContents } from './cms.actions';
import { valueOrPlaceholder, isNotEmptyOrNil, formatDatetime, emptyArray, upperCase } from '../utils';
import {
  Row,
  Text,
  Button,
  ActionSecondaryButton,
  ScrollableDataTableWithColSelector,
  ActionButton
} from '../components';
import Filter from './Feeds.filter';

const getColumnDefs = (t) => [
  {
    headerName: '',
    label: t('default:CHECKBOX'),
    width: 25,
    valueFormatter: () => null,

    headerComponentFramework: ({ api, column }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(api.getSelectedRows() === api.getDisplayedRowCount());

      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            isChecked ? api.deselectAll() : api.selectAll();
            setIsChecked(!isChecked);
            api.refreshCells({ columns: [column], force: true });
          }}
        />
      );
    },
    onCellClicked: () => null,

    cellRendererFramework: ({ node, api }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isChecked, setIsChecked] = useState(node.isSelected());
      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!node.isSelected());
            node.setSelected(!node.isSelected());
            api.refreshHeader();
          }}
        />
      );
    }
  },
  {
    headerName: t('CATEGORY'),
    defaultActive: true,
    field: 'category',
    width: 125,
    valueFormatter: (col) => t(`FEEDS_CATEGORIES.${upperCase(prop('value', col))}`)
  },
  {
    headerName: t('TITLE'),
    defaultActive: true,
    field: 'title',
    width: 125
  },
  {
    headerName: t('CONTENT'),
    field: 'description',
    width: 500
  },
  {
    headerName: t('CREATED_AT'),
    field: 'createdAt',
    valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil, formatDatetime)(prop('value', col))
  },
  {
    headerName: t('NOTIFICATION'),
    defaultActive: true,
    field: 'toggleNotification',
    width: 50,
    valueFormatter: (col) => (col.value ? t('YES') : t('NO'))
  }
];

const Feeds = ({
  history,
  t,
  feeds,
  feedCount,
  isFetching,
  appendContents,
  getContents,
  deleteContents,
  responseLength
}) => {
  // State
  const [selectedContents, selectContents] = useState(emptyArray);
  const [filters, setFilters] = useState();

  // Functions
  const getFeeds = (params) => getContents({ ...params, contentType: 'feeds' });
  const appendFeeds = (params) => appendContents({ ...params, contentType: 'feeds' });
  const deleteFeeds = (contents) => deleteContents({ list: contents, contentType: 'feeds' });
  const goToFeed = (event) => {
    history.push(`/cms/feeds/${path(['data', 'id'], event)}`);
  };
  const duplicateFeed = () => {
    if (selectedContents.length === 1) {
      history.push(`/cms/feeds/${prop('id', selectedContents[0])}/duplicate`);
    }
  };

  const onRowSelected = (event) => selectContents(event.api.getSelectedRows());
  const deleteSelection = () => {
    deleteFeeds(selectedContents);
    selectContents(emptyArray);
  };

  // Render
  return (
    <Fragment>
      <Row mb middle>
        <Text weight={200} size="xl" mr-half>
          {t('DAILY_LIFE_FEED')}
        </Text>

        {!selectedContents.length ? (
          <ActionButton icon="Plus" name="addFeed" onClick={() => history.push('/cms/feeds/create')} />
        ) : (
          <ActionSecondaryButton name="deleteFeeds" icon="Trash2" onClick={deleteSelection} danger />
        )}

        {selectedContents.length === 1 ? (
          <Button name="duplicateFeed" size="md" bg-secondary onClick={duplicateFeed}>
            {t('DUPLICATE')}
          </Button>
        ) : null}
      </Row>

      <Filter onFilterSelect={(filter) => setFilters(filter)} />

      <ScrollableDataTableWithColSelector
        filters={filters}
        ready={!!filters}
        columnDefs={getColumnDefs(t)}
        defaultColDef={{
          valueFormatter: (col) => valueOrPlaceholder(isNotEmptyOrNil)(prop('value', col)),
          onCellClicked: goToFeed
        }}
        getMethod={getFeeds}
        appendMethod={appendFeeds}
        rows={feeds}
        rowCount={feedCount}
        isFetching={isFetching}
        onRowSelected={onRowSelected}
        responseLength={responseLength}
      />
    </Fragment>
  );
};

Feeds.propTypes = {
  history: object,
  t: func,
  feeds: array,
  feedCount: number,
  isFetching: bool,
  appendContents: func,
  getContents: func,
  deleteContents: func,
  responseLength: number
};

export default compose(
  withRouter,
  translate(['cms']),
  connect(
    (state) => ({
      feeds: pathOr(emptyArray, ['cms', 'list'], state),
      feedCount: pathOr(0, ['cms', 'count'], state),
      responseLength: pathOr(0, ['cms', 'responseLength'], state),
      isFetching: pathOr(false, ['cms', 'isFetching'], state)
    }),
    { getContents, appendContents, deleteContents }
  )
)(Feeds);
