import { createFormAction } from 'redux-form-saga';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAIL = 'FETCH_COUNTRIES_FAIL';

export const fetchCountries = () => ({
  type: FETCH_COUNTRIES
});

export const fetchCountriesSuccess = (response) => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload: response
});

export const fetchCountriesFail = (err) => ({
  type: FETCH_COUNTRIES_FAIL,
  payload: err
});

export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAIL = 'FETCH_CITIES_FAIL';
export const FETCH_CITIES_ONLY = 'FETCH_CITIES_ONLY';

export const fetchCitiesOnly = (countryId) => ({
  type: FETCH_CITIES_ONLY,
  payload: countryId
});

export const fetchCities = (countryId) => ({
  type: FETCH_CITIES,
  payload: countryId
});

export const fetchCitiesSuccess = (response) => ({
  type: FETCH_CITIES_SUCCESS,
  payload: response
});

export const fetchCitiesFail = (err) => ({
  type: FETCH_CITIES_FAIL,
  payload: err
});

export const FETCH_HUBS = 'FETCH_HUBS';
export const FETCH_HUBS_SUCCESS = 'FETCH_HUBS_SUCCESS';
export const FETCH_HUBS_FAIL = 'FETCH_HUBS_FAIL';
export const FETCH_HUBS_ONLY = 'FETCH_HUBS_ONLY';

export const fetchHubsOnly = (cityId) => ({
  type: FETCH_HUBS_ONLY,
  payload: cityId
});

export const fetchHubs = (countryId) => ({
  type: FETCH_HUBS,
  payload: countryId
});

export const fetchHubsSuccess = (response) => ({
  type: FETCH_HUBS_SUCCESS,
  payload: response
});

export const fetchHubsFail = (err) => ({
  type: FETCH_HUBS_FAIL,
  payload: err
});

export const SELECT_HUB = 'SELECT_HUB';
export const selectHub = (hub) => ({
  type: SELECT_HUB,
  payload: hub
});

export const UPSERT_COUNTRY = 'UPSERT_COUNTRY';
export const upsertCountry = createFormAction(UPSERT_COUNTRY);

export const UPSERT_CITY = 'UPSERT_CITY';
export const upsertCity = createFormAction(UPSERT_CITY);

export const UPSERT_HUB = 'UPSERT_HUB';
export const upsertHub = createFormAction(UPSERT_HUB);

export const INIT_COUNTRY_FORM = 'INIT_COUNTRY_FORM';
export const initCountryForm = () => ({
  type: INIT_COUNTRY_FORM
});

export const INIT_CITY_FORM = 'INIT_CITY_FORM';
export const initCityForm = (city) => ({
  type: INIT_CITY_FORM,
  payload: city
});

export const INIT_HUB_FORM = 'INIT_HUB_FORM';
export const initHubForm = (hub) => ({
  type: INIT_HUB_FORM,
  payload: hub
});

/*------------------------------------PAYMENT_METHODS-----------------------------------------*/

export const FETCH_PAYMENT_METHODS_REQUEST = 'FETCH_PAYMENT_METHODS_REQUEST';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAIL = 'FETCH_PAYMENT_METHODS_FAIL';

export const fetchPaymentMethods = () => ({
  type: FETCH_PAYMENT_METHODS_REQUEST
});

export const fetchPaymentMethodsSuccess = (response) => ({
  type: FETCH_PAYMENT_METHODS_SUCCESS,
  payload: response
});

export const fetchPaymentMethodsFail = (err) => ({
  type: FETCH_PAYMENT_METHODS_FAIL,
  payload: err
});

export const SET_PAYMENT_METHODS_REQUEST = 'SET_PAYMENT_METHODS_REQUEST';
export const SET_PAYMENT_METHODS_SUCCESS = 'SET_PAYMENT_METHODS_SUCCESS';

export const setPaymentMethods = (id, list) => ({
  type: SET_PAYMENT_METHODS_REQUEST,
  payload: { id, list }
});

export const setPaymentMethodsSuccess = (response) => ({
  type: SET_PAYMENT_METHODS_SUCCESS,
  payload: response
});
