import { injectGlobal } from 'emotion';
import { colors } from './colors';
import { font } from './font';

injectGlobal`

    .react-datepicker-wrapper {
        width: 100%;

         .react-datepicker__input-container {
            width: 100%;

             input {
                width: 100%;
                 border: 1px solid ${colors.lightGrey[300]}!important;
                 font-family: ${font.family};
                 font-weight: 300;
                 border-radius: 2px;
                 min-height: 38px;
                 height: 38px;
                 padding-left:10px;
                 color: ${colors.grey[500]};
                 font-size:${font.size.sm}px;

                 &:focus {
                     outline: none!important;
                     border: 1px solid ${colors.brown[500]}!important;
                 }

                 &:disabled {
                    background: ${colors.grey[100]};
                  }
             }
         } 
    }
    
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
        margin:10px;
    }
`;
