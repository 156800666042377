export const getStatusList = (t) => [
  {
    value: 'call',
    next: [
      { value: 'awaiting virtual tour', label: t('MOVE_TO_AWAITING_VIRTUAL_TOUR') },
      { value: 'lost', label: t('MOVE_TO_LOST') }
    ],
    label: t('CALL')
  },
  {
    value: 'sent',
    next: [
      { value: 'booked', label: t('MOVE_TO_BOOKED') },
      { value: 'won', label: t('MOVE_TO_WON') },
      { value: 'lost', label: t('MOVE_TO_LOST') }
    ],
    label: t('SENT')
  },
  {
    value: 'booked',
    next: [
      { value: 'won', label: t('MOVE_TO_WON') },
      { value: 'lost', label: t('MOVE_TO_LOST') }
    ],
    label: t('BOOKED')
  },
  {
    value: 'nurture',
    next: [
      { value: 'call', label: t('MOVE_TO_CALL') },
      { value: 'lost', label: t('MOVE_TO_LOST') }
    ],
    label: t('NURTURE')
  },
  {
    value: 'won',
    next: [],
    label: t('WON')
  },
  {
    value: 'lost',
    next: [{ value: 'awaiting virtual tour', label: t('MOVE_TO_AWAITING_VIRTUAL_TOUR') }],
    label: t('LOST')
  },
  {
    value: 'pending',
    next: [
      { value: 'validated', label: t('MOVE_TO_VALIDATED') },
      { value: 'nurture', label: t('MOVE_TO_NURTURE') },
      { value: 'rejected', label: t('MOVE_TO_REJECTED') }
    ],
    label: t('PENDING')
  },
  {
    value: 'validated',
    next: [
      { value: 'call', label: t('MOVE_TO_CALL') },
      { value: 'lost', label: t('MOVE_TO_LOST') }
    ],
    label: t('VALIDATED')
  },
  {
    value: 'rejected',
    next: [],
    label: t('REJECTED')
  },
  {
    value: 'awaiting virtual tour',
    next: [
      {
        value: 'virtual tour sent',
        label: t('MOVE_TO_VIRTUAL_TOUR_SENT')
      },
      { value: 'lost', label: t('MOVE_TO_LOST') }
    ],
    label: t('AWAITING_VIRTUAL_TOUR')
  },
  {
    value: 'virtual tour sent',
    next: [
      //{ value: 'sent', label: t('MOVE_TO_SENT'), withModal: true },
      { value: 'lost', label: t('MOVE_TO_LOST'), withModal: true }
    ],
    label: t('VIRTUAL_TOUR_SENT')
  },
  {
    value: 'won',
    next: [],
    label: t('WON')
  }
];

export const getKYCStatuses = (t) => [
  {
    value: 'reviewing',
    next: [
      { value: 'approved', label: t('KYC_STATUS_APPROVED') },
      { value: 'partially_approved', label: t('KYC_STATUS_PARTIALLY_APPROVED') },
      { value: 'rejected', label: t('KYC_STATUS_REJECTED') }
    ],
    label: t('KYC_STATUS_REVIEWING')
  },
  {
    value: 'approved',
    next: [
      { value: 'partially_approved', label: t('KYC_STATUS_PARTIALLY_APPROVED') },
      { value: 'rejected', label: t('KYC_STATUS_REJECTED') }
    ],
    label: t('KYC_STATUS_APPROVED')
  },
  {
    value: 'partially_approved',
    next: [
      { value: 'approved', label: t('KYC_STATUS_APPROVED') },
      { value: 'rejected', label: t('KYC_STATUS_REJECTED') }
    ],
    label: t('KYC_STATUS_PARTIALLY_APPROVED')
  },
  {
    value: 'rejected',
    next: [
      { value: 'approved', label: t('KYC_STATUS_APPROVED') },
      { value: 'partially_approved', label: t('KYC_STATUS_PARTIALLY_APPROVED') }
    ],
    label: t('KYC_STATUS_REJECTED')
  }
];

// scheduled, missed, completed, skipped
export const getCallStatuses = (t) => [
  {
    value: 'scheduled',
    next: [
      { value: 'missed', label: t('CALL_STATUS_MISSED') },
      { value: 'completed', label: t('CALL_STATUS_COMPLETED') },
      { value: 'skipped', label: t('CALL_STATUS_SKIPPED') }
    ],
    label: t('CALL_STATUS_SCHEDULED')
  },
  {
    value: 'missed',
    next: [
      { value: 'completed', label: t('CALL_STATUS_COMPLETED') },
      { value: 'skipped', label: t('CALL_STATUS_SKIPPED') }
    ],
    label: t('CALL_STATUS_MISSED')
  },
  {
    value: 'completed',
    next: [
      { value: 'scheduled', label: t('CALL_STATUS_SCHEDULED') },
      { value: 'missed', label: t('CALL_STATUS_MISSED') },
      { value: 'skipped', label: t('CALL_STATUS_SKIPPED') }
    ],
    label: t('CALL_STATUS_COMPLETED')
  },
  {
    value: 'skipped',
    next: [
      { value: 'scheduled', label: t('CALL_STATUS_SCHEDULED') },
      { value: 'missed', label: t('CALL_STATUS_MISSED') },
      { value: 'completed', label: t('CALL_STATUS_COMPLETED') }
    ],
    label: t('KYC_STATUS_REJECTED')
  }
];
