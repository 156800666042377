import React, { Fragment, useState, useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose, pathOr, path } from 'ramda';
import { translate } from '../translate/i18n';
import { upperCase, isEmptyOrNil } from '../utils';
import { onItemDrop } from './applications.actions';
import { getLastApplicationFee, getApplicationsRooms } from './applications.service';
import { Container, Row, ConfirmModal, Text, Modal } from '../components';
import KanbanList from './KanbanList';
import Filter from './Application.filter';
import { getFilteredApplications } from './applications.actions';
import { Loader } from '../components';
import styled from '@emotion/styled';

const LoaderContainer = styled('div')(() => ({
  margin: 'auto'
}));

const ApplicationKanban = (props) => {
  // Props
  const { t, onItemDrop, getFilteredApplications, sections, isFetching } = props;

  // State
  const [showConfirm, toggleConfirm] = useState(false);
  const [applicationSet, toggleAddApplicationFee] = useState(false);
  const [card, setCard] = useState({});
  const [tooManyRooms, toggleTooManyRooms] = useState(false);

  const handleDropItem = async (item, label) => {
    if (label === 'awaiting kyc' && item.status === 'reviewing kyc') {
      let last;
      try {
        last = await getLastApplicationFee(item.id);
        // eslint-disable-next-line no-empty
      } catch (e) {}
      if (last && +pathOr(0, ['payment', 'paid'], last)) {
        toggleAddApplicationFee(item);
        return;
      }
    } else if (label === 'sent') {
      const list = await getApplicationsRooms(item.id);
      if (list.length !== 1) {
        toggleTooManyRooms(true);
        return;
      }
    }

    setCard({ item, label });
  };

  useEffect(() => {
    if (isEmptyOrNil(card)) {
      return;
    }

    if (card.label === 'won') {
      toggleConfirm(true);
    } else {
      onItemDrop(card.item, card.label);
    }
  }, [card]);

  // Render
  return (
    <Fragment>
      <Filter onFilterSelect={(filter) => getFilteredApplications(filter)} />

      {(isFetching && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )) || (
        <Container>
          {Object.keys(sections).map((item) => (
            <KanbanList
              onItemDrop={handleDropItem}
              title={t(upperCase(item))}
              label={item}
              key={item}
              data={sections[item]}
            />
          ))}
        </Container>
      )}

      <ConfirmModal
        title={t('APPLICATIONS')}
        show={showConfirm}
        onClose={() => toggleConfirm(false)}
        onConfirm={() => {
          toggleConfirm(false);
          onItemDrop(card.item, card.label);
        }}
        content={() => (
          <Container p>
            <Text>{t('CONFIRM_WON', { user: path(['item', 'user'], card) })}</Text>
          </Container>
        )}
      />

      <ConfirmModal
        title={t('payments:APPLICATION_FEE')}
        show={!!applicationSet}
        onClose={() => {
          toggleAddApplicationFee(null);
          onItemDrop(applicationSet, 'awaiting kyc');
        }}
        onConfirm={() => {
          toggleAddApplicationFee(null);
          onItemDrop(
            {
              ...applicationSet,
              makeNewFee: true
            },
            'awaiting kyc'
          );
        }}
        content={() => (
          <Container p>
            <Text>{t('SHOULD_ADDITIONAL_APPLICATION_FEE_BE_CREATED')}</Text>
          </Container>
        )}
      />

      <Modal
        title={t('CANNOT_MOVE_TO_SENT')}
        show={tooManyRooms}
        onClose={() => toggleTooManyRooms(false)}
        content={() => (
          <Container p>
            <Row center>
              <Text weight={300} grey={500} size="lg" pb-double>
                {t('TOO_MANY_ROOMS_ERROR')}
              </Text>
            </Row>
          </Container>
        )}
      />
    </Fragment>
  );
};

ApplicationKanban.propTypes = {
  t: func,
  onItemDrop: func,
  getFilteredApplications: func,
  sections: object,
  isFetching: bool
};

export default compose(
  translate(['applications', 'default']),
  connect(
    (state) => ({
      sections: pathOr({}, ['applications', 'sections'], state),
      isFetching: pathOr({}, ['applications', 'isFetching'], state)
    }),
    { onItemDrop, getFilteredApplications }
  )
)(ApplicationKanban);
