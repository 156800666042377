export const font = {
  size: {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 20,
    xl: 24
  },
  weight: {
    sm: 100,
    md: 200,
    lg: 300,
    xl: 400
  },
  family: 'Montserat'
};
