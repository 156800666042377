import React from 'react';
import { object, func } from 'prop-types';
import { path, prop } from 'ramda';
import { Phone, Mail } from 'react-feather';
import { Container, Row, Col, RenderElement, Button, CustomLink } from '../components';
import { translate } from '../translate/i18n';
import { formatFullname, formatPhone } from '../utils';

export const UserInfo = ({ t, member, room }) => (
  <Container column mb>
    <Row mb>
      <Col lg={4}>
        <RenderElement value={formatFullname(member)} label={t('MEMBER')} />
      </Col>

      <Col lg={4}>
        <RenderElement value={prop('email', member)} label={t('EMAIL')} />
      </Col>

      <Col lg={4}>
        <RenderElement
          label={t('PHONE')}
          value={formatPhone(prop('phoneNumber', member), path(['phonePrefix', 'prefix'], member))}
        />
      </Col>
    </Row>

    {room && (
      <Row>
        <Col lg={4}>
          <RenderElement value={path(['house', 'hub', 'city', 'name'], room)} label={t('CITY')} />
        </Col>

        <Col lg={4}>
          <RenderElement value={path(['house', 'name'], room)} label={t('HOUSE')} />
        </Col>

        <Col lg={4}>
          <RenderElement value={prop('number', room)} label={t('ROOM')} />
        </Col>
      </Row>
    )}
  </Container>
);

UserInfo.propTypes = { member: object, room: object, t: func };

const FollowUpActions = ({ selectedPayment, t, closeModal, goToDetails }) => (
  <Container column p>
    <UserInfo t={t} member={path(['lease', 'user'], selectedPayment)} room={path(['lease', 'room'], selectedPayment)} />

    <Row mb>
      <CustomLink
        mr-half
        size="md"
        name="callMember"
        bg-secondary
        href={`tel:${formatPhone(
          path(['lease', 'user', 'phoneNumber'], selectedPayment),
          path(['lease', 'user', 'phonePrefix', 'prefix'], selectedPayment)
        )}`}>
        <Phone size={14} /> {t('CALL_MEMBER')}
      </CustomLink>

      <CustomLink
        mr-half
        size="md"
        name="emailMember"
        bg-secondary
        href={`mailto:${path(['lease', 'user', 'email'], selectedPayment)}`}>
        <Mail size={14} /> {t('EMAIL_MEMBER')}
      </CustomLink>
    </Row>

    <Row mb right>
      <Button clear size="md" name="cancel" onClick={closeModal}>
        {t('CANCEL')}
      </Button>
      <Button bg-primary size="md" name="view" onClick={goToDetails}>
        {t('VIEW_DETAILS')}
      </Button>
    </Row>
  </Container>
);

FollowUpActions.propTypes = {
  selectedPayment: object,
  t: func,
  closeModal: func,
  goToDetails: func
};

export default translate('payments')(FollowUpActions);
