import React from 'react';
import SelectorGeoHub from '../selector/Selector.geo.hub';
import { translate } from '../../translate/i18n';
import { InputContainer, ErrorText } from './TextInput.styles';

const SelectGeoHubForm = ({ t, cityId, isClearable, input, label, isDisabled, meta: { touched, error }, isMulti }) => (
  <InputContainer>
    <SelectorGeoHub
      label={label}
      isClearable={isClearable}
      cityId={cityId}
      {...input}
      isMulti={isMulti}
      isInForm={true}
      isDisabled={isDisabled}
    />
    {touched && error && <ErrorText>{t(error)}</ErrorText>}
  </InputContainer>
);

export default translate('validation')(SelectGeoHubForm);
