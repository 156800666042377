import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { func, string, object, number } from 'prop-types';
import { translate } from '../../translate/i18n';
import { InputContainer, StyledLabel, ErrorText } from './TextInput.styles';
import ReactTooltip from 'react-tooltip';
import { hubInUtcFormatToLocalDate } from '../../utils/date';
import { Row } from '../../components';
import { pathOr } from 'ramda';
import moment from 'moment-timezone';

export const WrappedDatePicker = (props) => {
  const { input, placeholder, minDate, dayOnly, timezone, time = null } = props;
  const tooltipMessage = timezone;
  const tooltipId = `${input.value}-${Date.now()}`;

  const [selection, select] = useState(null);
  const handleChange = (date) => {
    if (!input.value && selection === null && time) {
      const hh = pathOr(0, ['hours'], time);
      const mm = pathOr(0, ['minutes'], time);
      date.setHours(hh);
      date.setMinutes(mm);
    }
    const timezoneAgnosticDate = moment(date).utcOffset(0, true).toDate();
    select(timezoneAgnosticDate);
    input.onChange(timezoneAgnosticDate);
  };

  const handleBlur = () => input.onBlur(selection);
  useEffect(() => {
    if (input.value && selection === null) {
      timezone &&
        (typeof input.value === 'string' || input.value instanceof String) &&
        handleChange(hubInUtcFormatToLocalDate(input.value, timezone));
    } else if (selection) {
      select(moment(input.value).toDate());
    }
  }, [input.value, timezone]);

  return (
    <span data-tip={tooltipMessage} data-for={tooltipId}>
      <ReactDatePicker
        onChange={handleChange}
        selected={selection}
        onFocus={input.onFocus}
        onBlur={handleBlur}
        dateFormat="dd/MM/yyyy"
        placeholder={placeholder}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode="select"
        minDate={minDate}
        filterDate={dayOnly !== undefined ? (date) => date.getDay() === dayOnly : undefined}
      />
      {tooltipMessage && (
        <ReactTooltip id={tooltipId} effect="solid" place="bottom">
          {tooltipMessage}
        </ReactTooltip>
      )}
    </span>
  );
};

WrappedDatePicker.propTypes = {
  input: object,
  placeholder: string,
  dayOnly: number,
  minDate: object,
  timezone: string,
  time: object // {hours, minutes}
};

const DatePicker = (props) => {
  const { meta, t, label, Icon } = props;
  const { touched, error, active } = meta;

  return (
    <InputContainer>
      <Row middle>
        <StyledLabel active={active}>{label}</StyledLabel>
        {Icon && <Icon />}
      </Row>
      <WrappedDatePicker {...props} />
      {touched && error && <ErrorText>{t(error)}</ErrorText>}
    </InputContainer>
  );
};

DatePicker.propTypes = {
  label: string,
  onChange: func,
  Icon: func,
  selected: object,
  meta: object,
  t: func,
  input: object,
  placeholder: string,
  timezone: string,
  time: object // {hours, minutes}
};

export default translate('validation')(DatePicker);
