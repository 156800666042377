import styled from '@emotion/styled';

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 0,
  width: '100%'
}));

export default StyledForm;
