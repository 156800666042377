import styled from '@emotion/styled';

export const AddressContainer = styled('div')(() => ({
  position: 'relative'
}));

export const SuggestionAddressContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 10000,
  background: 'white',
  bottom: 0,
  transform: 'translateY(100%)',
  width: '100%',
  border: '1px solid',
  borderColor: theme.colors.grey['100']
}));

export const SuggestionAddress = styled('div')(({ theme }) => ({
  padding: '10px',
  borderBottom: '1px solid',
  borderColor: theme.colors.grey['100'],
  cursor: 'pointer',
  '&:hover': {
    background: theme.colors.grey['100']
  }
}));
