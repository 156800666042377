import React, { Fragment, useState } from 'react';
import { withTheme } from '@emotion/react';
import { object, func, string, bool, any } from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import { is } from 'ramda';
import { customStyles } from './Selector';
import { StyledLabel } from '../form/TextInput.styles';

const AsyncSelector = (props) => {
  const { theme, onChange, loadOptions, placeholder, label, autoFocus, value, isClearable, clear, isMulti, disabled } =
    props;

  const [isActive, setActive] = useState(false);

  const handleChange = (option) => {
    if (is(Array, option)) {
      onChange(option.map((item) => item.value));
    } else if (is(Object, option)) {
      onChange(option.value);
    } else {
      onChange(option);
    }
  };

  return (
    <Fragment>
      {!!label && <StyledLabel active={isActive}>{label}</StyledLabel>}
      <AsyncSelect
        isDisabled={disabled}
        value={value}
        autoFocus={autoFocus}
        isClearable={isClearable}
        styles={customStyles(theme, clear)}
        placeholder={placeholder}
        cacheOptions
        defaultOptions
        isMulti={isMulti}
        openMenuOnFocus
        loadOptions={loadOptions}
        onChange={handleChange}
        clear={clear}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
      />
    </Fragment>
  );
};

AsyncSelect.defaultProps = {
  isClearable: true,
  isMulti: false
};

AsyncSelector.propTypes = {
  theme: object,
  onChange: func,
  onInputValue: func,
  loadOptions: func,
  placeholder: string,
  label: string,
  autoFocus: bool,
  value: any,
  isClearable: bool,
  isMulti: bool,
  clear: bool
};

export default withTheme(AsyncSelector);
