import React from 'react';
import { func, object, bool, string } from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { compose, prop, isNil, pathOr } from 'ramda';
import ResetPasswordForm from './ResetPassword.form';
import { translate } from '../../translate/i18n';
import { Container, Card, Col, Text } from '../../components';
import { Link } from 'react-router-dom';
import { capitalize, emptyObject } from '../../utils';
import qs from 'qs';
import { connect } from 'react-redux';

const RenderSuccessMessage = translate('auth')(({ t }) => (
  <Container column pt>
    <Text align="right">{capitalize(t('PASSWORD_CHANGED'))}</Text>
    <Link to="/login">
      <Text align="right" blue={500}>
        {t('GO_LOGIN')}
      </Text>
    </Link>
  </Container>
));
RenderSuccessMessage.propTypes = {
  t: func
};

const ResetPasswordGate = (props) => {
  const { location } = props;

  const { token, firstConnection } = qs.parse(prop('search', location), {
    ignoreQueryPrefix: true
  });

  return <Redirect to={{ pathname: '/resetPasswordForm', state: { token, firstConnection } }} />;
};

const ResetPassword = ({
  t,
  location: {
    state: { token, firstConnection }
  },
  form
}) => {
  const title = firstConnection === 'true' ? t('SET_PASSWORD_TITLE') : t('RESET_PASSWORD_TITLE');

  return (
    <Container center pt-double>
      <Col lg={5}>
        <Card title={title} textCenter>
          <ResetPasswordForm initialValues={{ token }} formError={isNil(token) || isNil(firstConnection)} />
        </Card>
        {!!prop('submitSucceeded', form) && <RenderSuccessMessage />}
      </Col>
    </Container>
  );
};

ResetPassword.propTypes = {
  dispatch: func,
  t: func,
  match: object,
  history: object,
  location: object,
  form: object,
  firstConnection: bool,
  token: string
};

ResetPasswordGate.propTypes = {
  dispatch: func,
  t: func,
  match: object,
  history: object,
  location: object
};
export default compose(
  translate('auth'),
  connect((state) => ({ form: pathOr(emptyObject, ['form', 'ResetPasswordForm'], state) })),
  withRouter
)(ResetPassword);

export const ConnectedResetPasswordGate = compose(translate('auth'), withRouter)(ResetPasswordGate);
